import React, { useState, useEffect } from 'react';
import Layout from 'components/common/DashboardLayout';
import { navigate } from '@reach/router';
import { FaArrowLeftLong } from 'react-icons/fa6';
import './uscis.scss';
import icons from 'assets/icons/usa.svg';
import uscisimg from 'assets/icons/uscisLogo.svg';
import { Platformbutton } from 'components/common/button/button';
import api from 'appRedux/api';
import { useSelector } from 'react-redux';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { ShareMessage } from 'components/referal/referal';
import pathwayconfig from 'config.js';

export const CaseStatus = () => {
  const [history, setHistory] = useState(null);
  const [value, setValues] = useState('');

  const userD = useSelector(state => state.auth?.userData);

  const handleHistoryFetch = async () => {
    try {
      const res = await api.get(
        `${pathwayconfig.baseURL}/pathway/get-foia-case-history?userId=${userD.id}`,
      );
      // console.log(res);

      setHistory(res.data?.caseHistory);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleHistoryFetch();
  }, []);

  return (
    <Layout>
      <RenderStep
        handleHistoryFetch={handleHistoryFetch}
        history={history}
        setValues={setValues}
        value={value}
      />
    </Layout>
  );
};

const RenderStep = props => {
  const [step, setStep] = useState(0);
  switch (step) {
    case 0:
      return (
        <div style={{ height: '85vh' }}>
          <div
            onClick={() => {
              navigate('/services');
            }}
            style={{
              display: 'flex',
              color: '#67A948',
              alignItems: 'center',
              gap: '5px',
              fontWeight: '600',
              cursor: 'pointer',
            }}
            className="caseStatusPage"
          >
            <FaArrowLeftLong /> Case Status History
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              marginTop: '4rem',
            }}
          >
            {props.history?.map(
              ({
                actionCodeDesc,
                countryFlag,
                actionCodeText,
                country,
                date,
                empty,
                formNum,
                formTitle,
                status,
              }) => {
                return (
                  <StatusContainer
                    setStep={setStep}
                    countryFlag={countryFlag}
                    actionCodeDesc={actionCodeDesc}
                    actionCodeText={actionCodeText}
                    country={country}
                    date={date}
                    empty={empty}
                    formNum={formNum}
                    formTitle={formTitle}
                    status={status}
                    setValues={props.setValues}
                    value={props.value}
                  />
                );
              },
            )}

            {/* <StatusContainer setStep={setStep} />
            <StatusContainer setStep={setStep} /> */}
          </div>
        </div>
      );

    case 1:
      return <CaseStatusC setStep={setStep} desc={props.value} />;

    default:
      return <div></div>;
  }
};

const StatusContainer = props => {
  const dateString = props.date;
  const date = new Date(dateString);

  // Extract the date components
  const day = date
    .getUTCDate()
    .toString()
    .padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so we add 1
  const year = date.getUTCFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return (
    <div
      className="statusBox"
      onClick={() => {
        props.setValues(props.actionCodeDesc);
        props.setStep(1);

        // value={props.value}
      }}
    >
      <div className="statusBox__article">
        <img src={props.countryFlag} alt="" />

        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <p
            style={{
              color: '#1D2939',
              fontSize: '16px',
              fontWeight: '600',
            }}
          >
            {props.status}
          </p>
          <p style={{ color: '#475467', fontSize: '13px' }}>
            {props.formTitle}
          </p>
        </div>
      </div>

      <div style={{ color: '#475467', fontSize: '14px' }}>{formattedDate}</div>
    </div>
  );
};

const CaseStatusC = props => {
  const responseText = props.desc;

  const [openShare, setOpenShare] = useState(false);

  // Function to dangerously set inner HTML while applying custom styles
  const createMarkup = () => {
    // Wrap the entire link with a span with the green color style
    const formattedText = responseText.replace(
      /<a(.*?)>(.*?)<\/a>/g,
      (match, p1, p2) => {
        return `<span style="color: green;"><a${p1}>${p2}</a></span>`;
      },
    );
    return { __html: formattedText };
  };
  return (
    <div style={{ height: '85vh' }}>
      <Simplemodal
        onClick={() => {
          setOpenShare(false);
        }}
        open={openShare}
      >
        <ShareMessage
          title="Share Case Status"
          link={responseText}
          id={'#'}
          shares={'#'}
          shareNum={'#'}
        />
      </Simplemodal>
      <div
        onClick={() => {
          props.setStep(0);
        }}
        style={{
          display: 'flex',
          color: '#67A948',
          alignItems: 'center',
          gap: '5px',
          fontWeight: '600',
          cursor: 'pointer',
        }}
        className="caseStatusPage"
      >
        <FaArrowLeftLong /> Case Status History
      </div>

      <div className="caseStudies">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <img src={uscisimg} alt="" style={{ width: '16rem' }} />
          <h1 style={{ fontSize: '20px', fontWeight: '600' }}>Case Status</h1>

          <div
            dangerouslySetInnerHTML={createMarkup()}
            style={{ fontSize: '14px', color: '#07000D', lineHeight: '24px' }}
          />
          {/* </p> */}

          <p
            style={{
              fontSize: '14px',
              color: '#07000D',
              lineHeight: '24px',
            }}
          >
            If you have any questions about your petition or the approval
            notice, please contact USCIS customer service at 1-800-375-5283
          </p>
        </div>

        <div className="mb-10"></div>

        <Platformbutton
          type="normal"
          name="Share status"
          click={() => {
            setOpenShare(true);
          }}
          disabled={false}
        />
        <div className="mb-4"></div>
        <Platformbutton
          type="secondary"
          name="Check history"
          click={() => {
            props.setStep(0);
          }}
          disabled={false}
        />
      </div>
    </div>
  );
};
