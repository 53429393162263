import React, { useState, useEffect } from 'react';
import { Platformbutton } from 'components/common/button/button';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Success } from 'components/common/success/success';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
// eslint-disable-next-line
import {
  Passwordcheck,
  Registerleft,
  VerifyReg,
} from 'components/auth/register/registerfeatures';
import { Formik } from 'formik';
import { useStep } from 'helpers/hooks';
import { SignUpSchema } from 'helpers/schema';
import { navigate } from '@reach/router';
import Toggle from 'react-toggle';
import { Phonenumber } from 'components/common/inputs/phoneinput';
import { fetchAllCountries } from 'appRedux/actions/Common';
import { connect, useDispatch } from 'react-redux';
import { Singleselect } from 'components/common/inputs/singleselect';
import {
  signUpUserV2,
  signUpUserP2V2,
  verifyNewUserAuthCode,
  resendUserMail,
} from 'appRedux/actions/auth';
import { AlertError } from 'components/common/alertboxes/alertboxes';
import { Shortinfo } from 'components/common/shortinfo/shortinfo';
// eslint-disable-next-line
import { getCurrencyCode, getCurrencyImg, getPhoneCode } from 'helpers/utils';
import { AUTH_LOAD } from 'appRedux/constants';
import VestiLogo from 'assets/Vesti_logo.svg';
import { Simplemodal } from 'components/common/simplifiedmodal';
import './register.scss';
import { IoIosArrowDown } from 'react-icons/io';
import { SmallRadioOption } from 'components/common/radiobutton/radiobutton';
import ModernDatepicker from 'react-modern-datepicker';
import { styled } from '@material-ui/core';
import { ImCheckboxChecked } from 'react-icons/im';

// eslint-disable-next-line
// import api from 'appRedux/api';
const MiaiSignup = props => {
  // eslint-disable-next-line
  const { step, nextStep, previousStep, setStep } = useStep(0);
  const [open, setOpen] = useState(false);

  const [requestCard, setRequestCard] = useState(false);
  const [data, setData] = useState({
    email: '',
    phoneNumber: '',
    // dob:''
  });
  const [option, setOption] = useState(null);
  const setMyOption = value => {
    setOption(value);
  };

  const [isChecked, setIsChecked] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    // Update the state variable
    setIsChecked(!isChecked);

    // Log the current state (you can replace this with your logic)
  };

  // console.log(props.countries);

  useEffect(() => {
    props?.authMessage === 'Email verified successfully'
      ? setOpen(true)
      : setOpen(false);
  }, [props?.authMessage]);

  // eslint-disable-next-line
  const [verify, setVerify] = useState('');
  var renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <FirstReg
            setVerify={setVerify}
            cb={setStep}
            setData={setData}
            data={data}
            signUpUserV2={props.signUpUserV2}
            authMessage={props.authMessage}
            authErr={props.authErr}
            loading={props.authLoad}
            countries={props.countries}
            requestCard={requestCard}
            setRequestCard={setRequestCard}
            option={option}
            setMyOption={setMyOption}
            isChecked={isChecked}
            handleCheckboxChange={handleCheckboxChange}
          />
        );
      case 1:
        return (
          <VerifyReg
            loading={props.authLoad}
            title={data.email}
            resend={() => props.resendUserMail()}
            // resend={() =>
            //   props.resendUserMail({ userId: localStorage.getItem('userId') })
            // }
            verify={code =>
              props.verifyNewUserAuthCode(
                { userId: localStorage.getItem('userId'), code: code },
                setStep,
              )
            }
            authMessage={props.authMessage}
            authErr={props.authErr}
          />
        );
      case 2:
        return (
          // <div className="signupsuccesscontainer">
          //   <Success
          //     title="Successful"
          //     subtitle={props.authMessage}
          //     onClick={() => navigate('/auth')}
          //     button={'Continue to log in'}
          //   />
          // </div>
          <Simplemodal open={open} closable={false}>
            <Success
              title="Successful"
              subtitle={props.authMessage}
              onClick={() => props.setStep(2)}
              button={'Continue to log in'}
            />
          </Simplemodal>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    props.fetchAllCountries();
  }, []);
  return (
    // lg:gap-[100px]
    <div className=" " style={{ overflowY: 'scroll' }}>
      {/* lg:w-[550px] */}
      <div className="" style={{ height: '100%', overflow: 'auto' }}>
        {renderSteps()}
      </div>
    </div>
  );
};

const FirstReg = props => {
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();

  // console.log(props)
  return (
    <div className="w-full  md:w-[500px] flex flex-col flex-start">
      <Formik
        initialValues={{
          fullName: '',
          email: '',
          password: '',
          country: '',
          countryCode: '',
          phoneNumber: '',
          referalCode: '',
          otherName: '',
          state: '',
          city: '',
          // dob: '',
          address: '',
          createCard: false,
        }}
        validationSchema={SignUpSchema}
        onSubmit={values => {
          props.signUpUserV2(
            {
              ...values,
              countryCode: getCurrencyCode(values.countryCode.value),
              currencyFlag: values.countryCode.image,
              country: values.countryCode.label,
              phoneNumber: {
                code: getPhoneCode(values.countryCode.label),
                number: values.phoneNumber.slice(
                  getPhoneCode(values.countryCode.label).length,
                ),
              },
              dob: props.data.dob?.value,
            },
            // console.log(values),
            props.cb,
          );
          props.setData({ ...props.data, email: values.email });
        }}
      >
        {formik => {
          // eslint-disable-next-line
          const {
            errors,
            touched,
            // isValid,
            values,
            handleChange,
            setFieldValue,
            handleSubmit,
          } = formik;
          return (
            <form
              onSubmit={handleSubmit}
              className="w-full flex flex-col gap-[50px] lg:w-[450px]"
            >
              <div className="w-full flex flex-col ">
                <div className="signInTitleSubtitle">
                  <h1 className="signInTitleSubtitle__title">Register</h1>
                  <p className="signInTitleSubtitle__subtile">
                    Please fill the form below to start interacting with Miai ™
                  </p>
                </div>

                <Inputfloat
                  label="Full Name"
                  type="text"
                  name="fullName"
                  value={values.fullName}
                  disabled={false}
                  placeholder="Daniel Ajibola"
                  onChange={handleChange}
                  invalid={errors.fullName && touched.fullName && 'true'}
                  error={
                    errors.fullName && touched.fullName ? errors.fullName : ''
                  }
                >
                  <Shortinfo subject="Full Name should be exactly how it is written on your ID" />
                </Inputfloat>
                <Inputfloat
                  label="email"
                  type="text"
                  name="email"
                  value={values.email}
                  disabled={false}
                  placeholder="johndoe@gmail.com"
                  onChange={handleChange}
                  invalid={errors.email && touched.email && 'true'}
                  error={errors.email && touched.email ? errors.email : ''}
                />
                <span className="mt-4 flex flex-col gap-[35px] lg:flex-row lg:gap-[10px]">
                  <Singleselect
                    label="Country"
                    value={values.countryCode}
                    options={props.countries}
                    onChange={value => setFieldValue('countryCode', value)}
                    placeholder="Select Country"
                    // components={{SingleValue: IconSingleValue, Option: IconOption}}
                  />

                  <Phonenumber
                    value={values.phoneNumber}
                    country={values.countryCode?.value}
                    setValue={value => setFieldValue('phoneNumber', value)}
                  />
                </span>
                <span className="flex flex-col gap-[10px]">
                  <Inputfloat
                    label="Password"
                    type="password"
                    name="password"
                    value={values.password}
                    disabled={false}
                    placeholder="Enter your password"
                    onChange={handleChange}
                    invalid={errors.password && touched.password && 'true'}
                    error={
                      errors.password && touched.password ? errors.password : ''
                    }
                  >
                    <div className="mb-[10px]"></div>
                  </Inputfloat>
                  {errors.password && touched.password && (
                    <div className="mb-[8px]"></div>
                  )}
                  <span className="flex flex-wrap gap-[10px]">
                    <Passwordcheck
                      name="8 Characters"
                      check={values.password.match(/^.{8,}$/)}
                    />
                    <Passwordcheck
                      name="A number"
                      check={values.password.match(/\d/)}
                    />
                    <Passwordcheck
                      name="A lowercase letter"
                      check={values.password.match(/[a-z]/)}
                    />
                    <Passwordcheck
                      name="An uppercase letter"
                      check={values.password.match(/[A-Z]/)}
                    />
                    <Passwordcheck
                      name="A Special character"
                      check={values.password.match(/[^\w]/)}
                    />
                  </span>
                </span>

                <div className="mt-2 flex flex-col gap-[10px]">
                  {toggle && (
                    <Inputfloat
                      label="Referral Code"
                      type="text"
                      name="referalCode"
                      value={values.referalCode}
                      disabled={false}
                      placeholder="Referral Code"
                      onChange={handleChange}
                    />
                  )}
                </div>
              </div>

              {props.authErr && <AlertError body={props.authErr} />}
              <div
                className="flex flex-col gap-[10px]  w-full"
                style={{ marginTop: '-20px' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  <div style={{}}>
                    <ImCheckboxChecked
                      style={{
                        background: 'white',
                        color: props.isChecked ? '#518C36' : 'white',
                        cursor: 'pointer',
                        border: '1px solid #518C36',
                        width: '18px',
                        height: '18px',
                      }}
                      onClick={() => props.handleCheckboxChange()}
                    />
                  </div>
                  <p style={{ color: '#2B5219' }}>
                    I agree to Vesti’s{' '}
                    <span
                      style={{ color: '#67A948', cursor: 'pointer' }}
                      onClick={() => {
                        window.open(' https://wevesti.com/tos', '_blank');
                      }}
                    >
                      Terms & Conditions.
                    </span>
                  </p>
                </div>
                <div className="mb-2"></div>
                <Platformbutton
                  name="Create Account"
                  type="submit"
                  disabled={
                    !values.fullName ||
                    !values.email ||
                    !values.password ||
                    !values.phoneNumber ||
                    !values.countryCode ||
                    // props.option === null ||
                    // (props.option && !values.dob) ||
                    // (props.option && !values.city) ||
                    // (props.option === 'Yes' &&
                    //   !values.values.stateOfResidence) ||
                    // !props.isChecked ||
                    // (props.option === 'Yes' && !values.otherName) ||
                    // (props.option && !values.address) ||
                    props.loading
                      ? true
                      : false
                  }
                />
                <div style={{ margin: '0px auto' }}>
                  <Platformbutton
                    type="link"
                    text="Already have an account?"
                    title="Signin"
                    click={() => {
                      dispatch({
                        type: AUTH_LOAD,
                        payload: { authErr: '', authMessage: '' },
                      });
                      navigate('/auth');
                    }}
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export const SecReg = props => {
  const [state, setState] = useState({
    fullName: '',
    country: '',
    phoneNumber: '',
    address: '',
  });
  return (
    <div className="lg:w-[500px] flex flex-col flex-start">
      <Titlesubtitle
        title="Create Account"
        subtitle="Join more than 100,000 Users finding new homes abroad, raising
                the money they need to move and making difficult international
                payments with Vesti."
      />

      <form
        className="w-full flex flex-col gap-[50px] lg:w-[450px]"
        onSubmit={e => {
          e.preventDefault();
          props.signUpUserP2V2(state, () => props.cb(3));
        }}
      >
        <div className="w-full flex flex-col gap-[30px]">
          {/* {state.country?.value}
                    {state.phoneNumber} */}
          {props.userdata?.firstName.toLowerCase() === 'user' && (
            <Inputfloat
              label="Full Name"
              type="text"
              name="fullName"
              value={state.fullName}
              disabled={false}
              placeholder="Ajibola Daniels"
              onChange={value => setState({ ...state, fullName: value })}
            >
              <Shortinfo subject="Full Name should be exactly how it is written on your ID" />
            </Inputfloat>
          )}
          <Inputfloat
            label="Billing Address"
            type="text"
            name="address"
            value={state.address}
            disabled={false}
            placeholder="Billing Address"
            onChange={e => setState({ ...state, address: e.target.value })}
          >
            <Shortinfo subject="Format should be e.g 30, Admiralty Road Lekki" />
          </Inputfloat>
        </div>
        {props.authErr && <AlertError body={props.authErr} />}
        <div className="flex flex-col gap-[10px] items-center w-full">
          <Platformbutton
            name="Submit"
            type="submit"
            disabled={
              !state.address ||
              !state.phoneNumber ||
              !state.country ||
              props.loading
                ? true
                : false
            }
          />
          <Platformbutton
            type="link"
            text="I’ll do this later ?  "
            title="Skip this"
            click={() => (props.type ? navigate('/auth') : props.close())}
          />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = ({ common, auth }) => {
  const { countries } = common;
  const { authMessage, authLoad, authErr } = auth;
  return {
    countries,
    authMessage,
    authLoad,
    authErr,
  };
};

const mapDispatchToProps = {
  fetchAllCountries,
  signUpUserV2,
  verifyNewUserAuthCode,
  signUpUserP2V2,
  resendUserMail,
};

export default connect(mapStateToProps, mapDispatchToProps)(MiaiSignup);
