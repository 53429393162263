import React, { useEffect } from 'react';
import classes from 'classnames';
import SideBar from 'components/common/SideBar';
import Header from 'components/common/Header';
import { useSelector, useDispatch } from 'react-redux';
import { collapseMenu } from 'appRedux/actions/menu';
import sorrysvg from 'assets/sorrysvg.svg';
// import edufair from 'assets/edufair.jpeg';
import kycsvg from 'assets/kycsvg.svg';
import { connect } from 'react-redux';
import '../../index.css';
import { closeUpdateBox, closeUpgradeBox } from 'appRedux/actions/update';
import {
  closeReward,
  closeLoan,
  closeBvn,
  // handleFees,
  closeFees,
  // closeFair,
  handleShuttlers,
  closeVirtual,
  closeAction,
} from 'appRedux/actions/domore';
import { closeShare } from 'appRedux/actions/Alertbox';
import { navigate } from '@reach/router';
import { Membership } from 'components/membership/membership';
import { useRates, useUserData } from 'helpers/hooks';
import { Referal } from 'components/referal/referal';
import { Simplemodal } from './simplifiedmodal';
import { Comingsoon } from './comingsoon/comingsoon';
import api from 'appRedux/api';
import {
  editStripeAccountModal,
  handleAnnouncement,
  openEventInvite,
  openNotificationWithIconErr,
  openReminder,
  claimModal,
} from 'appRedux/actions/Common';
import { errorMessage } from 'helpers/utils';
import { Onboardthree } from 'components/auth/onboard/onboardaction';
import { Titlesubtitle } from './titlesubtitle/titlesubtitle';
// import { Bvnverification } from 'components/bank/identitypass/bvnidentity';
import vcardprompt from 'assets/vcardprompt.svg';
// import Modal from 'components/common/Modal';
// import { Proofoffund } from 'components/bank/proofoffund/proofoffund';
import { Newprompt } from './prompt/prompt';
import { Feespayment } from 'components/bank/feespayment/feespayment';
import { closePause } from 'appRedux/actions/transactions';
import { StripeAccountinfo } from 'components/bank/stripe/stripedetails';
import { Platformbutton } from './button/button';
import eventig from 'assets/igevent.png';
// import shutt from "assets/shuttlersimg.png"
import { Vestireward } from './vestireward/vestireward';
// import { redirectLogin } from 'routes/RedirectRoutes';
// import { clearSession } from 'appRedux/store/cookies';
import {
  resendUserMail,
  setAuthAction,
  userSignOut,
  verifyNewUserAuthCode,
} from 'appRedux/actions/auth';
import Shuttlersprompt from 'components/bank/domore/domoreprompts';
import ErrorBoundary from './errorboundary';
import Ordercard from 'components/vesticards/selectcard';
import { Oeligibility } from 'components/website/ovabout/aboutov';
import { openO1Quiz } from 'appRedux/actions/pathway';
import Loader from 'components/Loader/Loader';
import { fetchUserData } from 'appRedux/actions/profile';
import { VerifyReg } from 'components/auth/register/registerfeatures';
import NgnClaim from 'components/bank/ngnwithdrawal/ngnClaim';
// import FacebookShareButton from './sharepostLinkOnSocialMedia/FacebookShare';

function Layout(props) {
  const dispatch = useDispatch();
  const { menuState } = useSelector(state => state.menu);
  const { userData, refetch } = useUserData();
  const userD = useSelector(state => state.auth?.userData);
  // console.log(userD)
  const { pricesData } = useRates();
  useEffect(() => {
    dispatch(collapseMenu());
  }, [dispatch]);

  // eslint-disable-next-line

  var goToProfile = () => {
    navigate('/myprofile?tab=kyc');
    dispatch(closeUpdateBox());
  };

  const shareNum = (postId, shares) => {
    var numShares = parseInt(shares);
    api
      .post('/editPost', {
        postId: postId,
        hasLiked: 'true',
        shares: numShares + 1,
      })
      .then(res => {})
      .catch(err => {
        openNotificationWithIconErr(
          errorMessage(err),
          'Error sharing a Post',
          'error',
        );
      });
  };

  const closeOnbprompt = () => {
    var data = localStorage.getItem('userData');
    data = data ? JSON.parse(data) : [];
    // setRefresh('refresh')

    data['takeaction'] = 'closed';

    localStorage.setItem('userData', JSON.stringify(data));
    userData.firstName &&
      userData.firstName !== 'vesti' &&
      userData.kycLevel === 'Level0' &&
      dispatch(openReminder(true));

    // dispatch(closeAction());
    dispatch(closeAction());
    // dispatch(openEventInvite(true))
    // setTimeout(()=> {
    //   // (userData.country === "NIGERIA" || userData.location === "NIGERIA" || userData.location === 'NG') &&  dispatch(openBvn())
    //   dispatch(openBvn())
    // }, 1000)
  };

  const openMail = () => {
    window.location.href = `mailto:miragtionloans@wevesti.com`;
  };

  const openLink = () => {
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSeoP9U6LWal0ZSYP_dlmsmyvHjQyN24gzoNMk1nHk2geyYJ1A/viewform',
    );
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      //  Broadcast that you're opening a page.

      localStorage.setItem('openpages', Date.now());
      var onLocalStorageEvent = function(e) {
        if (e.key === 'openpages') {
          //  Listen if anybody else is opening the same page!
          localStorage.setItem('page_available', Date.now());
        }
        if (
          e.key === 'page_available' &&
          window.location.pathname !== '/multipleTabs'
        ) {
          // router.push (" /multipleTabs");
          navigate('/multiple-tabs');
        }
      };
      window.addEventListener('storage', onLocalStorageEvent, false);
    }
  }, []);

  // eslint-disable-next-line
  const contentUrl = 'app.wevesti.com/fullpost/' + props.sharePost.id; // The URL of the content you want to share
  // eslint-disable-next-line
  const contentQuote = 'Check out this awesome content!'; // The text to be shared

  return (
    <ErrorBoundary>
      <div className={classes('layout', { toggled: menuState })}>
        <Simplemodal
          onClick={() => dispatch(editStripeAccountModal(false))}
          open={props.stripeaccount}
        >
          <StripeAccountinfo userdata={userData} />
        </Simplemodal>
        <Simplemodal
          onClick={() => dispatch(closeFees())}
          open={props.openFees}
        >
          <Feespayment />
        </Simplemodal>
        <Simplemodal
          onClick={() => dispatch(closeUpgradeBox())}
          open={props.openUpgrade}
        >
          <Membership />
        </Simplemodal>

        {/* event */}
        <Simplemodal
          onClick={() => dispatch(openEventInvite(false))}
          open={props.event}
        >
          <Newprompt img={eventig}>
            <div className="eventbox">
              <Titlesubtitle
                title="Need clarity on our products & services?🤔"
                subtitle="We have prepared a super insightful session with our amazing Product Manager to come onboard and provide answers to all your pressing questions. "
              />
              <p className="information">
                Join us on <strong>Friday, 28th of October</strong> as she
                shares answers and helpful tips to help you have a seamless use
                of our app
              </p>
              <div className="mb-4"></div>
              <Referal
                title=""
                link={`https://www.instagram.com/
                  vestiofficialupcoming_
                  event_id=1803
                  94287283884061234567890`}
              />
            </div>
          </Newprompt>
        </Simplemodal>
        {/* new announcement */}
        {/* <Simplemodal 
          onClick={()=> dispatch(handleAnnouncement(false))}
          open={props.announcement}
        >
          <Newprompt img={shutt} >
            <div className='eventbox'>
              <Titlesubtitle
                title="Vesti x Shuttlers"
                subtitle="Get  50% off  your first Shuttlers ride on Vesti,
                click the button below to apply discount. "
              />
                <div className='mb-4'></div>
                <Platformbutton type="normal" name="Continue" click =  {()=> {
                  dispatch(handleAnnouncement(false))
                  setTimeout(()=> {
                    dispatch(handleShuttlers(true))
                  },100)
                }} />
            </div>
            
          
          </Newprompt>
        </Simplemodal> */}

        {/* kyc reminder */}
        <Simplemodal
          onClick={() => dispatch(openReminder(false))}
          open={props.reminder}
        >
          <Newprompt img={kycsvg}>
            <div className="reminder">
              <Titlesubtitle
                title="KYC Verification Reminder"
                subtitle={`Hello ${(userData?.firstName ?? 'Vesti ')
                  .charAt(0)
                  .toUpperCase() +
                  (userData?.firstName ?? 'Vesti ').slice(
                    1,
                  )}, You are yet to be verified as a Vesti user. Our regulators require that you upload an identity document,
                click the button below to go start the verification process.`}
              />

              <div className="mb-4"></div>
              <Platformbutton
                name="Start Verification"
                type="normal"
                click={() => {
                  dispatch(openReminder(false));
                  goToProfile();
                }}
              />
            </div>
          </Newprompt>
        </Simplemodal>

        {/* eligibility modals */}
        <Simplemodal
          open={props.o1Quiz}
          onClick={() => dispatch(openO1Quiz(false))}
        >
          <Oeligibility
            countries={props.countries}
            close={() => dispatch(openO1Quiz(false))}
          />
        </Simplemodal>

        {/* KYC reminder */}
        <Simplemodal
          onClick={() => dispatch(closeUpdateBox())}
          open={props.openBox}
        >
          <Newprompt img={sorrysvg}>
            <Titlesubtitle
              title="KYC Verification"
              subtitle="You are yet to be verified."
            />
            <p className="information">
              Sorry : This is not available for users who are yet to upload kyc.
              You will have to update your profile details either (Upload KYC,
              firstName, lastName or phone number), only users with full profile
              details can reserve cards.
            </p>
            <div className="mb-4"></div>
            <Platformbutton
              name="Upload KYC"
              type="normal"
              click={() => goToProfile()}
            />
          </Newprompt>
        </Simplemodal>

        {/* share referal link modal */}
        <Simplemodal
          onClick={() => dispatch(closeShare())}
          open={props.sharePost.value}
        >
          <Referal
            title="Copy Post Link"
            link={'app.wevesti.com/fullpost/' + props.sharePost.id}
            id={props.sharePost.id}
            shares={userData?.shares}
            shareNum={shareNum}
            // children={
            //   <>
            //     <FacebookShareButton url={contentUrl} quote={contentQuote} />
            //     <p>Share on Linkedln</p>
            //     <p>Share on X share</p>
            //     <p>Share on Threads</p>
            //   </>
            // }
          />
        </Simplemodal>
        <Simplemodal
          onClick={() => dispatch(closePause())}
          open={props.updateOntrans}
        >
          <Comingsoon
            title="Under Maintenance"
            subtitle={props.updateOntransMessage}
            button="Close"
            onClick={() => dispatch(closePause())}
          />
        </Simplemodal>
        <Simplemodal
          onClick={() => dispatch(closeReward())}
          open={props.openReward}
        >
          <Vestireward>
            <Titlesubtitle
              title="Introducing Referral Reward"
              subtitle="Share your referral code and get upto ₦500 when whoever you refer signs up and transacts."
            />
          </Vestireward>
        </Simplemodal>
        <Simplemodal
          onClick={() => dispatch(closeLoan())}
          open={props.openLoan}
        >
          <Comingsoon
            title="Vesti Migration Loan (coming soon)"
            subtitle="need proof of  funds for visa application, school application etc? Click on the request POF via mail below to get started"
            onClick={openMail}
            button="Request POF Via Email"
          >
            <p
              style={{ color: '#67A948', cursor: 'pointer', marginTop: '10px' }}
              onClick={() => openLink()}
            >
              {' '}
              Take A Survey{' '}
            </p>
          </Comingsoon>
          {/* <Proofoffund  refetch ={refetch} loanPaid = {userData.isLoanInterestPaid} closePof={()=>dispatch(closeLoan())} userData={userData} /> */}
        </Simplemodal>
        <Simplemodal
          open={props.openVirtual}
          // open={false}
          onClick={() => dispatch(closeVirtual())}
        >
          <Ordercard
            refetch={refetch}
            cb={refetch}
            openWait={props.openWait}
            approved={userData?.virtualCardWaitlistStatus}
            monoCardHolderId={userData?.monoCardHolderId}
            country={userData?.country}
            userData={userData}
            rate={pricesData}
            closeModal={() => dispatch(closeVirtual())}
          />
        </Simplemodal>
        <Simplemodal
          onClick={() => {
            closeOnbprompt();
            setTimeout(() => {
              dispatch(handleAnnouncement(true));
            }, 100);
          }}
          open={
            localStorage.getItem('userData') &&
            localStorage.getItem('userData').takeaction
              ? localStorage.getItem('userData').takeaction === 'closed'
                ? false
                : props.openAction
              : props.openAction
          }
          // open={true}
        >
          <>
            <Titlesubtitle
              title={`What would you like to do ${
                localStorage.getItem('myuserData')
                  ? localStorage.getItem('myuserData').firstName
                  : ''
              } ?`}
              subtitle="Select what you want to do on Vesti."
            />
            <Onboardthree
              closeModal={() => {
                closeOnbprompt();
                setTimeout(() => {
                  dispatch(handleAnnouncement(true));
                }, 100);
              }}
            />
          </>
        </Simplemodal>
        <Simplemodal onClick={() => dispatch(closeBvn())} open={props.openBvn}>
          {/* <Bvnverification/> */}
          <Newprompt img={vcardprompt}>
            <Titlesubtitle
              title="Vesti Virtual cards"
              subtitle="Update on our virtual card offerings."
            />
            <p className="information">
              Our Virtual Card service is under maintenance please check back
              later (please email support if you have questions){' '}
              <strong> help@wevesti.com </strong>
            </p>
            <button
              className="prompt__btn"
              onClick={() => dispatch(closeBvn())}
            >
              Close
            </button>
          </Newprompt>
        </Simplemodal>
        <Simplemodal
          open={props.openVerify}
          onClick={() => dispatch(setAuthAction({ openVerify: false }))}
        >
          <VerifyReg
            inner={true}
            loading={props.authLoad}
            title={userD?.email}
            resend={() => props.resendUserMail({ userId: userD?.id })}
            id={userD?.id}
            verify={code =>
              props.verifyNewUserAuthCode(
                { userId: userD?.id, code: code },
                '',
                () => dispatch(setAuthAction({ openVerify: false })),
              )
            }
            authMessage={props.authMessage}
            authErr={props.authErr}
          />
        </Simplemodal>

        <Simplemodal
          onClick={() => dispatch(handleShuttlers(false))}
          open={props.openShuttlers}
        >
          <Shuttlersprompt />
        </Simplemodal>
        <Simplemodal
          onClick={() => dispatch(claimModal(false))}
          open={props.claim}
        >
          <NgnClaim
            refetch={refetch}
            userData={userData}
            onClick={() => dispatch(claimModal(false))}
          />
        </Simplemodal>

        <SideBar />

        <Header
          info={props.info}
          link={props.link}
          info2={props.info2}
          link2={props.link2}
        />
        <div className="content my-test">
          {userD?.firstName ? props.children : <Loader />}
        </div>
      </div>
    </ErrorBoundary>
  );
}

const mapStateToProps = ({
  kycupdate,
  domore,
  alertbox,
  transactions,
  common,
  pathway,
  auth,
}) => {
  const { openBox, openUpgrade } = kycupdate;
  const {
    openReward,
    openLoan,
    openAction,
    openBvn,
    openFees,
    openShuttlers,
    openVirtual,
    openFair,
  } = domore;
  const { sharePost } = alertbox;
  const { updateOntrans, updateOntransMessage } = transactions;
  const { stripeaccount, event, reminder, announcement, claim } = common;
  const { o1Quiz } = pathway;
  const { authMessage, authLoad, authErr, openVerify } = auth;
  return {
    openBox,
    openUpgrade,
    openReward,
    openLoan,
    sharePost,
    openAction,
    openBvn,
    openFees,
    openShuttlers,
    updateOntrans,
    updateOntransMessage,
    stripeaccount,
    openVirtual,
    event,
    reminder,
    announcement,
    o1Quiz,
    openFair,
    authMessage,
    authLoad,
    authErr,
    openVerify,
    claim,
  };
};

export default connect(mapStateToProps, {
  closeUpdateBox,
  userSignOut,
  fetchUserData,
  verifyNewUserAuthCode,
  resendUserMail,
})(Layout);

// export default Layout;
