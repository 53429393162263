import React, { useEffect, useState } from 'react';
import './cardsTransaction.scss';
import { Empty } from 'components/common/empty/empty';
import Loader from 'components/Loader/Loader';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
// import Newpagination from 'components/bank/pagination/newpagination';
import {
  formatterUSD,
  getCurrency,
  transColor,
  transSign,
  transStatus,
} from 'helpers/utils';
import moment from 'moment';
// import { Simplemodal } from 'components/common/simplifiedmodal';
// import { fetchCardTransactions } from 'appRedux/actions/cards';
// import { Clipboard } from 'components/common/clipboard/clipboard';
// import api from 'appRedux/api';

// import { connect } from 'react-redux';

export const PhysicalCardTransHistory = props => {
  // const [page, setPage] = useState(1);
  const [active, setActive] = useState(0);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  var handleOpen = value => {
    setActive(value);
    dimensions?.width < 1050 && setOpen(true);
  };

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);
  if (props.loading) {
    return (
      <div className="mytransactions">
        <Titlesubtitle
          title="Transactions"
          // subtitle="See all transactions you've carried out on this Card."
        />
        <div className="mytransactions__centercont">
          <Loader />
        </div>
      </div>
    );
    // } else if (props.transactions?.data?.data?.transactions.length > 0) {
  } else if (props.transactions?.length > 0) {
    return (
      <div className="">
        <Titlesubtitle
          title="Transactions"
          // subtitle="See all transactions you've carried out on this Card."
        />

        <div
          className=""
          style={{
            background: '#fff',
            borderRadius: '20px',
            overflowX: 'scroll',
          }}
        >
          <div className="">
            <div className="mytransactions__tabbox">
              <table className="mytransactions__table">
                <thead>
                  <tr>
                    <th
                      style={{
                        color: '#3E6F26',
                        fontSize: '16px',
                        fontWeight: '600',
                      }}
                    >
                      S/N
                    </th>
                    <th
                      style={{
                        color: '#3E6F26',
                        fontSize: '16px',
                        fontWeight: '600',
                      }}
                    >
                      Type
                    </th>
                    <th
                      style={{
                        color: '#3E6F26',
                        fontSize: '16px',
                        fontWeight: '600',
                      }}
                    >
                      Currency
                    </th>
                    <th
                      style={{
                        color: '#3E6F26',
                        fontSize: '16px',
                        fontWeight: '600',
                      }}
                    >
                      Amount
                    </th>
                    <th
                      style={{
                        color: '#3E6F26',
                        fontSize: '16px',
                        fontWeight: '600',
                      }}
                    >
                      description
                    </th>
                    <th
                      style={{
                        color: '#3E6F26',
                        fontSize: '16px',
                        fontWeight: '600',
                      }}
                    >
                      Date & Time
                    </th>
                    <th
                      style={{
                        color: '#3E6F26',
                        fontSize: '16px',
                        fontWeight: '600',
                      }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(
                    props.transactions?.slice(props.item, props.index) ?? []
                  ).map((item, index) => (
                    <Trow
                      key={index}
                      index={index}
                      type={item.type}
                      currency={item.currency.split('_')[0]}
                      date={item.createdAt}
                      status={item.status}
                      description={item.description}
                      amount={item.amount}
                      click={handleOpen}
                    />
                  ))}
                  {/* {console.log(props.transactions.data.transactions)} */}
                </tbody>
              </table>
            </div>
          </div>

          {/* <Newpagination
              className="pagination-bar"
              currentPage={page}
              pageSize={5}
              totalCount={props.transactions?.data?.transactions.length/5}
              onPageChange={page => setPage(page)}
            /> */}
        </div>
      </div>
    );
  } else {
    return (
      <div className="mytransactions">
        <Titlesubtitle
          title="Transactions"
          // subtitle="See all transactions you've carried out on this Card."
        />
        <div className="mytransactions__centercont">
          <Empty
            title="No Transactions"
            subtitle="You are yet to carry out any transaction on this card, when you do they'll appear here, click the buttons above to carry out transactions."
          />
        </div>
      </div>
    );
  }
};

const Trow = props => {
  const newDate = moment(props.date, 'YYYY-MM-DD HH:mm:ss').format(
    'YYYY-MM-DD HH:mm:ss',
  );
  var tr_type = props.type.split('_')[0];

  return (
    <tr onClick={() => props.click(props.index)}>
      <td>{props.index + 1}</td>
      <td>
        {tr_type === 'TRANSFER'
          ? tr_type.toLowerCase() + ' to ' + props.transaction?.receiverName
          : // .
            // toLowerCase()
            tr_type}
      </td>
      <td>{props.currency.split('_')[0]}</td>
      <td className={`amount${transColor(tr_type)}`}>
        {transSign(tr_type)}

        {(props.amount / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </td>
      <td>{props.description}</td>
      <td>{newDate}</td>
      <td>
        <div className={`status`}>
          <p className={`statusIcon --${transStatus(props.status)} `}></p>{' '}
          {transStatus(props.status)}
        </div>
      </td>
    </tr>
  );
};

// const Transdetail = props => {
//   return (
//     <section className="transdetail">
//       <span className="transdetail__top">
//         <p>{props.data.type.split('_')[0]}</p>
//         <p>
//           {' '}
//           {getCurrency(props.data.currency)}{' '}
//           {(props.data.amount / 100).toLocaleString('en-us')}
//         </p>
//         <p>
//           {moment(props.data.createdAt, 'YYYY-MM-DD HH:mm:ss').format(
//             'YYYY-MM-DD HH:mm:ss',
//           )}
//         </p>
//       </span>
//       <div className="transdetail__list">
//         {[
//           { title: 'status', value: props.data.status },
//           { title: 'Currency', value: props.data.currency.split('_')[0] },
//           {
//             title: 'date',
//             value: moment(props.data.createdAt, 'YYYY-MM-DD HH:mm:ss').format(
//               'YYYY-MM-DD HH:mm:ss',
//             ),
//           },
//           { title: 'Type', value: props.data.type.split('_')[0] },
//           { title: 'Fee', value: props.data.charges },
//         ].map((item, index) => (
//           <span key={index} className="transdetail__item">
//             <p>{item.title}</p>
//             <p className={item.title === 'status' ? `status` : ''}>
//               {item.title === 'status' && (
//                 <p className={`statusIcon --${transStatus(item.value)} `}></p>
//               )}{' '}
//               {item.title === 'status' ? transStatus(item.value) : item.value}
//             </p>
//           </span>
//         ))}
//         <span className="transdetail__item --id">
//           <p>Transaction ID</p>
//           <Clipboard link={props.data.id} />
//         </span>
//       </div>
//     </section>
//   );
// };
