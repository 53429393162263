import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { token } from 'appRedux/api';
import { navigate } from '@reach/router';
import paulImg from '../../../../assets/newFeedsImg/secondSecImg/Paul.png';
import { BsThreeDots } from 'react-icons/bs';
import { GoDotFill } from 'react-icons/go';
import { GoArrowLeft } from 'react-icons/go';
import { BiCheckDouble } from 'react-icons/bi';
import debImg from '../../../../assets/newFeedsImg/secondSecImg/debs.png';
import { IoImageOutline } from 'react-icons/io5';
import { VscSend } from 'react-icons/vsc';
import FeedsChat from '../feedsChat';
import EmojiPicker from 'containers/Admin/NewDashboard/EmojiPicker';
import { RiCheckDoubleLine } from 'react-icons/ri';
import { BsFillReplyFill } from 'react-icons/bs';
import { MdCancel } from 'react-icons/md';
import dummyAvatar from '../../../../assets/dummy-avatar.png';
import './chatArea.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchChatData } from 'appRedux/actions/Feedschat';
import { addNewFeedsChats } from 'appRedux/actions/Feedschat';
import { useParams } from '@reach/router';
import './chatArea.scss';
import { chatHistoryData } from 'appRedux/actions/Feedschat';
import { SimpleCancelmodal } from 'components/common/simplifiedmodal';

const url = 'http://syca-app-backend.eba-pe3ubbbvcbi8ujmm.com/';

export const InChat = props => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [newmsg, setNewMsg] = useState([]);
  const { friendId } = useParams();

  const chatH = useSelector(state => state.Feedschat?.chats);
  const userData = useSelector(state => state.auth?.userData);

  const myfriendName = useSelector(state => state.Feedschat.friendName);
  const myfriendPic = useSelector(state => state.Feedschat.friendImg);
  // const messageEndRef = useRef(null)

  // online status
  const [isOnline, setIsOnline] = useState(false);

  // friendName:'',
  // friendImg:''

  console.log('mychats', chatH);
  // console.log("userData", userData);

  const [friendnewId, setFriendnewId] = useState('');

  useEffect(() => {
    setFriendnewId(friendId);
  }, [friendId]);

  useEffect(() => {
    dispatch(fetchChatData(friendId));
  }, [friendnewId, friendId]);

  // useEffect(() => {
  //     dispatch(fetchChatData(friendId));
  // }, [isConnected, friendId]);

  const dispatchMyChat = () => {
    dispatch(fetchChatData(friendId));
  };

  useEffect(() => {
    const newSocket = io(
      'http://syca-app-backend.eba-pe3mzmfm.us-east-1.elasticbeanstalk.com/chats',
      {
        transports: ['websocket'],
        withCredentials: true,
        auth: {
          token: `Bearer ${token}`, // Pass the token in the auth object
        },
        // reconnectionDelay: 5000,
        // reconnectionDelayMax: 10000,
        reconnectionAttempts: 5,
      },
    );

    newSocket.on('connect', () => {
      console.log('Connected to WebSocket server');

      newSocket.emit('auth', {
        token: `Bearer ${token}`,
      });
      setIsConnected(true);
    });

    newSocket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
      setIsConnected(false);
    });

    newSocket.on('err', error => {
      console.error('WebSocket error:', error);
    });

    newSocket.on('auth', auth => {
      console.error('Auth:', auth);
    });

    newSocket.on('message', message => {
      console.log('hello');
      console.log(message);
      console.log('new');
      //   setMessages(prevMessages => [...prevMessages, message]);
    });

    newSocket.on('newMessage', async message => {
      console.log('newMessage', JSON.parse(message));
      // console.log('new');
      // dispatch(addNewFeedsChats(JSON.parse(message).newChat));
      dispatchMyChat();
      dispatch(chatHistoryData());
      //   setNewMsg(JSON.parse(message).newChat);
      //   setMessages(prevMessages => [...prevMessages, message]);
    });

    newSocket.on('userOnline', userId => {
      if (userId === friendId) {
        setIsOnline(true);
      }
    });

    newSocket.on('userOffline', userId => {
      if (userId === friendId) {
        setIsOnline(false);
      }
    });

    setSocket(newSocket);

    // Cleanup function to disconnect on unmount
    return () => {
      newSocket.disconnect();
    };
  }, [token, url, friendId]);

  // image file upload
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileBinary, setSelectedFileBinary] = useState([]);
  const fileInputRef = useRef(null);

  console.log('selectedBinary', selectedFileBinary);
  // remove selected file
  const removeFile = index => {
    setSelectedFile(prevFiles => prevFiles.filter((_, i) => i !== index));
    setSelectedFileBinary(prevBinaries =>
      prevBinaries.filter((_, i) => i !== index),
    );
  };

  const handleSendMessage = async (newimgUrl, fileType) => {
    if (!message.trim()) return;
    console.log(newimgUrl);

    const img =
      'https://res.cloudinary.com/wevesti/image/upload/v1721809264/verificationDocument/sgainztvi0hvzpw74kd1.png';
    if (socket) {
      const newMessage = {
        message: message,
        createdAt: new Date().toISOString(),
        recipientId: friendId,
        // senderId: userData.id,
        replyTo: replyTo ? replyTo.message : null,
        // groupId: "44f9d141-03d3-42de-8911-9ab8007f9b78",
      };

      const msgWithFile = {
        message: message,
        createdAt: new Date().toISOString(),
        recipientId: friendId,
        // senderId: userData.id,  //newupdate
        images: [
          {
            url: newimgUrl,
            type: fileType, //doc
            // type: "picture" //doc
          },
        ],
        replyTo: replyTo ? replyTo.message : null,
      };

      socket.emit('message', newimgUrl ? msgWithFile : newMessage);
      dispatch(chatHistoryData());
      // dispatch(addNewFeedsChats(newMessage));

      // Clear the input field
      setInputValue('');
      setMessage('');
      setReplyTo(null);
      setSelectedFile([]);
      setSelectedFileBinary([]);
    }
  };

  const ImgUpload = async () => {
    const apiUrl = `
        https://api.wevesti.com/api/v1/customer/image-upload`;
    const formData = new FormData();

    console.log(selectedFileBinary);
    selectedFileBinary.forEach((file, index) => {
      formData.append(`images`, file);
    });

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      const data = await response.json();
      const imgUrl = await data?.data[0];
      const fileType = selectedFileBinary[0].type.includes('image')
        ? 'picture'
        : 'document';

      if (imgUrl) {
        handleSendMessage(imgUrl, fileType);
      }

      return data;
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  };

  const handleFileChange = event => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      setSelectedFile(files.map(file => URL.createObjectURL(file)));
      setSelectedFileBinary(files);
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  //
  const [message, setMessage] = useState('');
  const messageEndRef = useRef(null);
  const [replyTo, setReplyTo] = useState(null);

  const insertEmoji = emoji => {
    setMessage(prevMessage => prevMessage + emoji);
  };

  const simulateReceiveMessage = () => {
    setTimeout(() => {
      const receivedMessage = {
        text: 'Hi! This is a received message.',
        sender: 'other',
        time: new Date().toLocaleTimeString(),
      };
      setMessages(prevMessages => [...prevMessages, receivedMessage]);
    }, 1000);
  };

  const messageRefs = useRef([]);

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatH]);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isBlocked, setisBlocked] = useState(false);

  const toggleDropDown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleBlockUnblock = () => {
    setisBlocked(!isBlocked);
  };

  // date format
  const formatDate = dateString => {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${strMinutes}${ampm}`;
  };

  // date label for chat
  const formatDateLabel = dateString => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return 'Today';
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    } else if (today - date < 7 * 24 * 60 * 60 * 1000) {
      return date.toLocaleDateString(undefined, { weekday: 'long' });
    } else {
      return date.toLocaleDateString(undefined, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
    }
  };

  const newFriendName = localStorage.getItem('FriendUserName');
  const newFriendPic = localStorage.getItem('FriendProfilepic');
  // localStorage.setItem('FriendProfilepic', img)

  // view picture
  const [openModal, setOpenModal] = useState(false);
  const [viewImage, setViewImage] = useState(null);

  const handleViewImage = imageUrl => {
    setViewImage(imageUrl);
    setOpenModal(true);
  };

  // keuboard send
  const handleKeyPress = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      // Check if 'Enter' key is pressed without 'Shift'
      e.preventDefault(); // Prevent the default action (new line)
      if (selectedFileBinary[0]?.name) {
        ImgUpload(); // Call ImgUpload if there's a selected file
      } else {
        handleSendMessage(); // Otherwise, call handleSendMessage
      }
    }
  };

  // media 1300px
  const [isScreenSmall, setIsScreenSmall] = useState(
    window.matchMedia('(max-width: 1300px)').matches,
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1300px)');
    const handleMediaQueryChange = event => setIsScreenSmall(event.matches);

    mediaQuery.addEventListener('change', handleMediaQueryChange);

    // Cleanup function to remove event listener
    return () =>
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
  }, []);

  return (
    <>
      <div
        className="backIconDiv"
        onClick={() => navigate(isScreenSmall ? '/chatList' : '/feeds')}
      >
        <GoArrowLeft className="backIcon" />
        <h4 className="backP">Go Back</h4>
      </div>
      <div className="inChatContainer">
        {/* <div className='inChatDisplay'> */}

        <SimpleCancelmodal onClick={() => setOpenModal(false)} open={openModal}>
          <div className="immgViewDiv">
            <img
              src={viewImage}
              alt="Full-size preview"
              className="imgViewModal"
            />
          </div>
        </SimpleCancelmodal>
        <div className="inChatMain">
          <div className="nameContainerDiv">
            <div className="nameContainer">
              <div className="nameImgDiv">
                <div className="ImgDotDiv">
                  <img
                    src={newFriendPic ? newFriendPic : dummyAvatar}
                    alt=""
                    className="profileImg"
                  />
                  {isOnline && <GoDotFill className="onlineDotIcon" />}
                </div>
                <div className="profileNameDiv">
                  <p className="profileName">{newFriendName}</p>
                  {/* <p className='onlineStatus'>Online</p> */}
                  {isOnline && <p className="onlineStatus">Online</p>}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="chatBodyDiv">
              <div>
                {chatH?.map((msg, index) => {
                  const showDateLabel =
                    index === 0 ||
                    new Date(msg?.createdAt).toDateString() !==
                      new Date(chatH[index - 1]?.createdAt).toDateString();
                  return (
                    <div
                      key={index}
                      ref={el => (messageRefs.current[index] = el)}
                    >
                      {showDateLabel && (
                        <div className="dateLabelDiv">
                          <div className="dateLabel">
                            {formatDateLabel(msg?.createdAt)}
                          </div>
                        </div>
                      )}
                      <div
                        className="chatMesStylingDiv"
                        style={{
                          justifyContent:
                            msg?.senderId === userData.id
                              ? 'flex-end'
                              : 'flex-start',
                        }}
                      >
                        <div
                          style={{
                            border:
                              msg?.senderId === userData.id
                                ? '1px solid #C7FFAD'
                                : '1px solid #ddd',
                            backgroundColor:
                              msg?.senderId === userData.id
                                ? '#C7FFAD'
                                : '#FFFFFF',
                          }}
                          className={`${
                            msg?.senderId === userData.id
                              ? 'sender-chat-border'
                              : 'reciever-chat-border'
                          } message-container`}
                        >
                          {msg?.files[0]?.url ? (
                            <img
                              src={msg?.files[0]?.url}
                              alt=""
                              className="inChatImage"
                              onClick={() =>
                                handleViewImage(msg?.files[0]?.url)
                              }
                            />
                          ) : (
                            <></>
                          )}
                          {/* {msg?.files[0]?.type ?  <img src={msg?.files[0]?.type} alt='' style={{width:'400px', marginBottom:'0.5rem'}}/> : <></>} */}

                          <p className="chatMessage">{msg?.message}</p>
                          <p className="replyText">{msg?.text}</p>
                          <div
                            className="TimeAndCheck"
                            style={{
                              justifyContent:
                                msg?.senderId === userData.id
                                  ? 'flex-end'
                                  : 'flex-start',
                            }}
                          >
                            <span className="createdAtStyle">
                              {formatDate(msg?.createdAt)}
                            </span>
                            {msg?.senderId === userData.id && (
                              <RiCheckDoubleLine
                                style={{
                                  color:
                                    msg?.status === 'unread'
                                      ? '#999'
                                      : '#007bff',
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div ref={messageEndRef} />
              </div>
            </div>

            <div className="textDivContainer">
              <div className="textDivField">
                {replyTo && (
                  <div className="replyToDiv">
                    Replying to: {replyTo.message}
                    <span
                      style={{ cursor: 'pointer', color: '#67A948' }}
                      onClick={() => setReplyTo(null)}
                    >
                      <MdCancel className="replyCancelIcon" />
                    </span>
                  </div>
                )}

                <div className="imgPreviewContainer">
                  {selectedFile.map((file, index) => (
                    <div className="imgPreviewDiv" key={index}>
                      {/* {file && file.type ? (
                                                file.type.includes('image') ? (
                                                    <img className='imgPreview' src={URL.createObjectURL(file)} alt={`preview-${index}`} onClick={() => handleViewImage(URL.createObjectURL(file))} />
                                                ) : file.type.includes('pdf') || file.type.includes('msword') || file.type.includes('wordprocessingml') ? (
                                                    <div onClick={() => handleViewImage(URL.createObjectURL(file))}>
                                                        <span className='docPreview'>PDF/DOC: {file.name}</span>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <span className='docPreview'>Invalid file</span>
                                                    </div>
                                                )
                                            ) : (
                                                <div>
                                                    <span className='docPreview'>Invalid file</span>
                                                </div>
                                            )}
                                            <span className='imgPreviewSpan' onClick={() => removeFile(index)}>&times;</span> */}
                      <img
                        className="imgPreview"
                        src={file}
                        alt={`preview-${index}`}
                        onClick={() => handleViewImage(file)}
                      />
                      <span
                        className="imgPreviewSpan"
                        onClick={() => removeFile(index)}
                      >
                        &times;
                      </span>
                    </div>
                  ))}
                </div>

                <textarea
                  className="textAreaStyle"
                  type="text"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  placeholder="Type a message"
                  onKeyPress={e => handleKeyPress(e)}
                ></textarea>
                <IoImageOutline
                  className="textAreaImgIcon"
                  onClick={handleIconClick}
                />
                <input
                  type="file"
                  accept=".png, .jpg, .svg, .jpeg, .pdf, .doc"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  multiple
                />
                <div className="emojiPickerDiv">
                  <EmojiPicker onSelect={insertEmoji} />
                </div>
              </div>

              <div
                onClick={() => {
                  selectedFileBinary[0]?.name
                    ? ImgUpload()
                    : handleSendMessage();
                }}
                // onClick={handleSendMessage}
              >
                {message === '' ? (
                  <VscSend
                    className="VcsendSend"
                    style={{ background: '#4C5366', cursor: 'not-allowed' }}
                  />
                ) : (
                  <VscSend
                    className="VcsendSend"
                    style={{ background: '#67A948', cursor: 'pointer' }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}

        <div className="feedsChatInChat">
          <FeedsChat />
        </div>
      </div>
    </>
  );
};
