import React, { useState } from 'react';
import '../Admin.css';
import { Form } from 'antd';
// import Loader from 'components/Loader';
import api from 'appRedux/api';
import { openNotificationWithIcon } from 'appRedux/actions/Common';
import { objectValuesStringify } from 'helpers/utils';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Platformbutton } from 'components/common/button/button';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Success } from 'components/common/success/success';
import errorsvg from '../../../assets/errorsvg.svg';

const Withdraw = ({ close, user, savings }) => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    reason: '',
  });
  const [loading, setLoading] = useState(false);
  const [transMessage, setTransMessage] = useState('');

  // console.log(savings);

  var setInput = e => {
    var name = e.target.name;
    var value = e.target.value;

    setFormData({ ...formData, [name]: value });
    // console.log(formData);
  };

  const handleCompleteTransaction = async () => {
    const url = '/safe-lock/break-safe';
    const data = {
      safeLockId: savings?.id,
      reason: formData.reason,
    };
    console.log(data);
    setLoading(true);
    try {
      const res = await api.patch(url, data);
      // console.log(res);
      openNotificationWithIcon(
        res.data?.message,
        'Savings Withdrawal',
        'success',
      );
      setLoading(false);
      setTransMessage(res.data?.message);
      setStep(1);
    } catch (error) {
      // console.log('err', error);
      if (error.data?.errors) {
        openNotificationWithIcon(
          objectValuesStringify(error.data?.errors),
          'Savings Withdrawal',
          'error',
        );
        // console.log('err1', error.data.message);
        setTransMessage(error.data?.message);
        setStep(2);
      } else {
        console.log('err2', error.response?.data?.message);
        openNotificationWithIcon(error.response?.data?.message, 'Savings Withdrawal', 'error');
        setTransMessage(error.response?.data?.message);
        setStep(2);
      }
      // console.log({ ...error });
      setLoading(false);
    }
  };

  var __renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <StepOne
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            handleCompleteTransaction={handleCompleteTransaction}
            setInput={setInput}
            loading={loading}
            setLoading={setLoading}
            close={close}
          />
        );
      case 1:
        return (
          <Success
            title="Request Sent"
            // subtitle={transMessage}
            subtitle={transMessage}
            onClick={() => close()}
            button="Okay! Thank you."
          />
        );
      case 2:
        return (
          <Success
            image={errorsvg}
            title={`Failed`}
            subtitle={transMessage}
            onClick={() => setStep(0)}
            button="Try Again"
            type="error"
          />
        );
      default:
        return <></>;
    }
  };

  return <div className="create-savings-box">{__renderSteps()}</div>;
};
const StepOne = props => {
  // console.log('StepOne: ', props);
  return (
    <Form className="create-savings-form w-100">
      <Titlesubtitle
        title="Break Safe?"
        subtitle="Are you sure that you want to break your safe? You will lose all the interest you have earned if you proceed"
      />
      <div className="w-100">
        <div className="mt-4">
          <Inputfloat
            type="text"
            label="Reason for breaking safe"
            name="reason"
            placeholder="Enter Reason"
            value={props.formData.reason}
            disabled={false}
            onChange={props.setInput}
          />
        </div>
        <Backcontinue goBack={() => props.close()}>
          <Platformbutton
            name="Submit Request"
            type="normal"
            click={() => props.handleCompleteTransaction()}
            disabled={!props.formData.reason || props.loading}
          />
        </Backcontinue>
      </div>
    </Form>
  );
};

export default Withdraw;
