import { fetchAllCountries } from 'appRedux/actions/Common';
import { openO1Quiz } from 'appRedux/actions/pathway';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { NavbarBlue } from 'components/website/navbarblue/NavbarBlue';
import { Aboutov, Oeligibility } from 'components/website/ovabout/aboutov';
// import Ovadvice from 'components/website/ovadvice/Ovadvice';
import { Ovbanner } from 'components/website/ovbanner/Ovbanner';
import Ovbenefits from 'components/website/ovbenefits/Ovbenefits';
import Ovcount from 'components/website/ovcount/Ovcount';
// import Ovfaq from 'components/website/ovfaq/Ovfaq';
import Ovfeature from 'components/website/ovfeatured/Ovfeature';
import Ovfooter from 'components/website/ovfooter/Ovfooter';
import Ovtestimony from 'components/website/ovtestimony/Ovtestimony';
import Ovwhatis from 'components/website/ovwhatis/Ovwhatis';
// import OvwhatisReverse from 'components/website/ovwhatisreverse/OvwhatisReverse';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

const Ovisa = props => {
  useEffect(
    () =>
      props.fetchAllCountries(),
      // eslint-disable-next-line
    [],
  );
  var dispatch = useDispatch();
  return (
    <>
      <Simplemodal
        open={props.o1Quiz}
        onClick={() => dispatch(openO1Quiz(false))}
      >
        <Oeligibility
          countries={props.countries}
          close={() => dispatch(openO1Quiz(false))}
        />
      </Simplemodal>
      <NavbarBlue />
      <Ovbanner click={() => dispatch(openO1Quiz(true))} />
      <Aboutov click={() => dispatch(openO1Quiz(true))} />
      <Ovbenefits />
      <Ovwhatis />
      {/* <OvwhatisReverse /> */}
      <Ovcount click={() => dispatch(openO1Quiz(true))} />
      <div>
        <Ovfeature />
        <Ovtestimony />
        {/* <Ovfaq /> */}
        <Ovfooter />
      </div>
    </>
  );
};

const mapStateToProps = ({ waitlist, common, pathway }) => {
  const { loading } = waitlist;
  const { countries } = common;
  const { o1Quiz } = pathway;
  return {
    loading,
    countries,
    o1Quiz,
  };
};
const mapDispatchToProps = {
  fetchAllCountries,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ovisa);
