import React, { useState } from 'react';
import peopleflags from '../../../assets/peopleflags.png';
import './Ovabout.scss';
import Inputfloat from 'components/common/inputs/inputfloat';
import {
  IconOption,
  IconSingleValue,
  Singleselect,
} from 'components/common/inputs/singleselect';
import { Phonenumber } from 'components/common/inputs/phoneinput';
import { RadioOption } from 'components/common/radiobutton/radiobutton';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Platformbutton } from 'components/common/button/button';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { useStep } from 'helpers/hooks';
import axios from 'axios';
import errorsvg from 'assets/oops.svg';
import { Success } from 'components/common/success/success';
import api from 'appRedux/api';

const config = {
  cors: 'https://cors-anywhere.herokuapp.com/',
  formUrl:
    'https://docs.google.com/forms/d/1wAjKgN-gPU3T9LK0JVNZc5k4-u6QRtK3t4PuOsBqaBM/formResponse',
};

export const Aboutov = props => {
  return (
    <div className="about-container">
      <div className="about-inner">
        <div className="__left">
          <img className="img-fluid" src={peopleflags} alt="" />
        </div>
        <div className="__right">
          <div className="__title">
            <p>
              Why use Vesti for your <span>O-1 Visa</span>?
            </p>
          </div>
          <div className="__body">
            <p>
              We have built an Intuitive platform with real-time progress
              tracking. Easily track your O-1 visa application progress and stay
              up-to-date in real-time. No more wondering about the status of
              your visa. Get personalized support from our team of experienced
              O-1 visa specialists. We will be there to help you every step of
              the way.
            </p>
          </div>
          <div className="__button">
            {/* <button>See if you Qualify </button> */}
            <Platformbutton
              name="See if you Qualify"
              type="normal"
              click={() => props.click()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const Oeligibility = props => {
  const [state, setState] = useState({
    fullName: '',
    gender: '',
    phoneNumber: '',
    email: '',
    country: '',
    expertise: '',
    national: '',
    prizes: '',
    books: '',
    judge: '',
    leader: '',
    speaker: '',
  });
  var ids = [
    '275674196',
    '500981336',
    '364319452',
    '850618021',
    '491281489',
    '1376928382',
    '2110116561',
    '543109535',
    '713045706',
    '540324483',
    '1139714467',
    '39273814',
    '574620756',
  ];
  const { step, setStep, nextStep, previousStep } = useStep(0);
  const [score, setScore] = useState(7);
  var data = [
    {
      name: 'national',
      title:
        'Have you received national or international recognition for your achievements in your field?',
      value: 'yes',
    },
    {
      name: 'prizes',
      title: 'Have you received any major prizes or awards in your field?',
      value: 'yes',
    },
    {
      name: 'books',
      title:
        'Have you authored any books, articles, or other scholarly work about your field?',
      value: 'yes',
    },
    {
      name: 'judge',
      title:
        'Have you previously served as a judge in your field or related fields?',
      value: 'yes',
    },
    {
      name: 'leader',
      title:
        'Have you previously held a leadership role in a prominent organization in your field?',
      value: 'yes',
    },
    {
      name: 'speaker',
      title:
        'Have you been asked to serve as a speaker or guest at events in your field?',
      value: 'yes',
    },
    {
      name: 'experience',
      title:
        'Can you provide evidence of at least three years of experience in your field?',
      value: 'yes',
    },
  ];
  var setInput = e => {
    var name = e.target.name;
    var value = e.target.value;
    setState({ ...state, [name]: value });
  };
  var handleSelect = (name, value) => {
    setState({ ...state, [name]: value });
  };
  var handleOption = (name, value) => {
    if (state[name] === 'Yes' || !state[name]) {
      value === 'No' && setScore(score - 1);
    } else if (state[name] === 'No') {
      value === 'Yes' && setScore(score + 1);
    }
    setState({ ...state, [name]: value });
  };
  var __renderStep = () => {
    switch (step) {
      case 0:
        return (
          <Stepone
            data={state}
            setInput={setInput}
            handleSelect={handleSelect}
            countries={props.countries}
            click={nextStep}
            setState={setState}
            state={state}
          />
        );
      case 1:
        return (
          <Steptwo
            disabled={
              Object.entries(state)
                .slice(4, 10)
                .every(value => value[1] !== '')
                ? false
                : true
            }
            score={score}
            data={data.slice(0, 4)}
            name="Continue"
            click={nextStep}
            back={previousStep}
            handleOption={handleOption}
            setState={setState}
            state={state}
          />
        );
      case 2:
        return (
          <Stepthree
            disabled={
              Object.entries(state)
                .slice(9)
                .every(value => value[1] !== '')
                ? false
                : true
            }
            score={score}
            data={data.slice(4)}
            name="Submit"
            click={() => submit()}
            back={previousStep}
            handleOption={handleOption}
            setState={setState}
            state={state}
          />
        );
      case 3:
        return (
          <Success
            title="Congratulations, You are a Fit for the O-1"
            subtitle="Congratulations, you are fit for the O-1 VIsa."
            button="Book a call now!"
            onClick={() => {
              window.open('https://calendly.com/vesti/founderpaid');
              props.close();
            }}
          />
        );
      case 4:
        return (
          <Success
            image={errorsvg}
            type="error"
            title="Bummer, We can help you get ready for the O-1"
            subtitle="Unfortunately you are not a Fit for the O-1 Visa at the moment. However, we believe we can help you to decide which other Visa is better, also we can help prepare you for the O-1 visa in 10-20weeks. A member of our team will reach out in 24-48 hours with additional information."
            button="Close"
            onClick={() => {
              props.close();
            }}
          />
        );
      default:
        return <></>;
    }
  };

  var submit = async () => {
    const formData = new FormData();
    var list = Object.keys(state);
    for (var key in state) {
      const value = Array.isArray(state[key])
        ? JSON.stringify(state[key])
        : state[key];
      // eslint-disable-next-line
      value.label
        ? formData.append(
            // eslint-disable-next-line
            `entry.${
              ids[
                // eslint-disable-next-line
                list.findIndex(function(item) {
                  return item === key;
                })
              ]
            }`,
            value.label,
          )
        : formData.append(
            `entry.${
              ids[
                // eslint-disable-next-line
                list.findIndex(function(item) {
                  return item === key;
                })
              ]
            }`,
            value,
          );
    }

    await axios({
      url: `${config.formUrl}`,
      method: 'post',
      data: formData,
      responseType: 'json',
    })
      .then(res => {
        api
          .post('/user/o_one ', {
            email: state.email,
            score: score,
          })
          .the(res => {})
          .catch(err => (() => {})());
      })
      .catch(error => {
        //console.log(score, 'score value')
        score >= 4 ? setStep(3) : setStep(4);
        api
          .post('/user/o_one ', {
            email: state.email,
            score: score,
          })
          .the(res => {});
      });
  };
  return (
    <div>
      {step < 3 && (
        <Titlesubtitle
          steps={` ${step + 1} of 3 `}
          title="See if an O-1 Visa is a fit for you"
          subtitle="Take this quiz to see if the O-1 Visa is a fit for you to move to America."
        />
      )}
      <div className="mb-4"></div>
      {__renderStep()}
    </div>
  );
};

const Stepone = props => {
  return (
    <div className="ostepone">
      <form className="ostepone__col">
        <div className="ostepone__row">
          <Inputfloat
            type="text"
            label="Full Name"
            name="fullName"
            placeholder="John Doe"
            value={props.data.fullname}
            disabled={false}
            onChange={props.setInput}
          />
          <Phonenumber
            value={props.data.phoneNumber}
            country={props.data.country?.vlaue}
            setValue={value =>
              props.setState({ ...props.state, phoneNumber: value })
            }
          />
        </div>
        <Inputfloat
          type="email"
          label="Email Address"
          name="email"
          placeholder="Johndoe@gmail.com"
          value={props.data.email}
          disabled={false}
          onChange={props.setInput}
        />
        <Singleselect
          label="Gender"
          value={props.data.gender}
          options={[
            { label: 'Male', value: 'Male' },
            { label: 'Female', value: 'Female' },
            { label: 'Prefer not to say', value: 'Prefer not to say' },
          ]}
          onChange={value => {
            //console.log(value.value);
            props.handleSelect('gender', value);
          }}
          placeholder="Select your gender "
        />

        <Singleselect
          label="Country of residence"
          value={props.data.country}
          options={props.countries}
          onChange={value => {
            props.handleSelect('country', value);
          }}
          placeholder="Select your country"
          components={{ SingleValue: IconSingleValue, Option: IconOption }}
        />
        <Singleselect
          label="expertise"
          value={props.data.expertise}
          options={[
            { label: 'Arts', value: 'Arts' },
            {
              label: 'Sciences, Education, Business or Athletics',
              value: 'Sciences, Education, Business or Athletics',
            },
            {
              label: 'Motion pictures or Television',
              value: 'Motion pictures or Television',
            },
          ]}
          onChange={value => {
            props.handleSelect('expertise', value);
          }}
          placeholder="Select your expertise"
        />
        {/* <div className='ostepone__row'>
          
          
        </div> */}

        <div className="ostepone__btns">
          <Platformbutton
            name="Continue"
            type="normal"
            disabled={
              Object.entries(props.data)
                .slice(0, 6)
                .every(value => value[1] !== '')
                ? false
                : true
            }
            click={() => props.click()}
          />
        </div>
      </form>
    </div>
  );
};

const Steptwo = props => {
  return (
    <div className="ostepone">
      <div className="ostepone__form">
        <div className="ostepone__bcol">
          {props.data.map((item, index) => (
            <div className="ostepone__col">
              <p>{item.title}</p>
              <div className="ostepone__scol">
                <RadioOption
                  changed={value => props.handleOption(item.name, value)}
                  id={`0 + ${item.name}`}
                  isSelected={props.state[item.name] === 'Yes'}
                  label="Yes"
                  value={'Yes'}
                />
                <RadioOption
                  changed={value => props.handleOption(item.name, value)}
                  id={`1 + ${item.name}`}
                  isSelected={props.state[item.name] === 'No'}
                  label="No"
                  value={'No'}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="ostepone__btns">
        <Backcontinue goBack={() => props.back()}>
          <Platformbutton
            name={props.name}
            type="normal"
            disabled={props.disabled}
            click={() => props.click()}
          />
        </Backcontinue>
      </div>
    </div>
  );
};

const Stepthree = props => {
  return (
    <div className="ostepone">
      <div className="ostepone__form">
        <div className="ostepone__bcol">
          {props.data.map((item, index) => (
            <div className="ostepone__col">
              <p>{item.title}</p>
              <div className="ostepone__scol">
                <RadioOption
                  changed={value => props.handleOption(item.name, value)}
                  id={`0 + ${item.name}`}
                  isSelected={props.state[item.name] === 'Yes'}
                  label="Yes"
                  value={'Yes'}
                />
                <RadioOption
                  changed={value => props.handleOption(item.name, value)}
                  id={`1 + ${item.name}`}
                  isSelected={props.state[item.name] === 'No'}
                  label="No"
                  value={'No'}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="ostepone__btns">
        <Backcontinue goBack={() => props.back()}>
          <Platformbutton
            name={props.name}
            type="normal"
            disabled={props.disabled}
            click={() => props.click()}
          />
        </Backcontinue>
      </div>
    </div>
  );
};
