import React, { useState, useEffect } from 'react';
import './index.css';
import { Modal } from 'antd';
import Layout from 'components/common/DashboardLayout';
import NewSavings from './NewSavings';
import TopUp from './TopUp';
import ListSavings from './ListSavings';
import { useUserData } from 'helpers/hooks';
// import { navigate } from '@reach/router';
import { connect, useDispatch } from 'react-redux';
import { openUpdateBox } from 'appRedux/actions/update';
// import { SavingsCard, SavingsYieldCard } from 'components/savings/singlesavings';
import { Empty } from 'components/common/empty/empty';
import api from 'appRedux/api';
import { openNotificationWithIcon } from 'appRedux/actions/Common';
import { objectValuesStringify } from 'helpers/utils';
import Loader from 'components/Loader';
import Newpagination from 'components/bank/pagination/newpagination';
import Back from 'components/common/back/back';

const Savings = () => {
  const { userData } = useUserData();
  const [modalVisible, setModalVisible] = useState(false);
  const [showNewSavings, setShowNewSavings] = useState(false);
  const [showTopUp, setShowTopUp] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [savingsPlans, setSavingsPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [savingsTotal, setSavingsTotal] = useState(0);

  const handleShowTopUp = saving => {
    setSelectedPlan(saving);
    setShowTopUp(true);
    showModal();
    // console.log('Passed saving: ', saving)
  };

  const handleShowSavings = () => {
    setShowNewSavings(true);
    showModal();
  };

  const dispatch = useDispatch();

  var openUpdateModal = () => {
    dispatch(openUpdateBox());
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setShowNewSavings(false);
    setShowTopUp(false);
    getSavings();
    getTotalSavings();
  };

  const getSavings = async () => {
    const url = '/safe-lock/list';
    try {
      const res = await api.get(url);
      // console.log(res.data);
      const { data } = res.data;
      setSavingsPlans(data);
      setTotal(res.pagination?.total);
      setLoading(false);
    } catch (error) {
      if (error.data?.errors) {
        openNotificationWithIcon(
          objectValuesStringify(error.data?.errors),
          'Savings',
          'error',
        );
      } else {
        //console.log('err', error.data.message);
        openNotificationWithIcon(error.data?.message, 'Savings', 'error');
      }
      //console.log({ ...error });
      setLoading(false);
    }
  };

  const getTotalSavings = async () => {
    const url = '/safe-lock/total-savings-amount?currency=NGN';
    try {
      const res = await api.get(url);
      // console.log(res.data);
      const { data } = res.data;
      setSavingsTotal(data);
      // setLoading(false);
    } catch (error) {
      //console.log({ ...error });
      // setLoading(false);
    }
  };

  useEffect(() => {
    getTotalSavings();
  }, []);
  useEffect(() => {
    getSavings();
  }, []);

  // console.log(userData);

  // console.log('Unsused Methods', handleShowTopUp);

  if (loading) {
    return (
      <div className="d-flex min-vh-100 justify-content-center">
        <Loader />
      </div>
    );
  } else {
    return (
      <Layout>
        <div style={{ marginLeft: '1rem' }}>
          <Back page="Migration Savings Account" link="bank" />
        </div>
        {savingsPlans.length > 0 ? (
          <div
            className=" isw-container"
            style={{ height: '85vh', width: '100%', overflow: 'scroll' }}
          >
            <div className="flex_page_container" id="saving-container">
              <ListSavings
                handleShowSavings={handleShowSavings}
                handleShowTopUp={handleShowTopUp}
                openUpdateModal={openUpdateModal}
                getSavings={getSavings}
                savingsPlans={savingsPlans}
                setSavingsPlans={setSavingsPlans}
                savingsTotal={savingsTotal}
                setSavingsTotal={setSavingsTotal}
              />
              <Newpagination
                className="pagination-bar"
                currentPage={page}
                totalCount={total}
                pageSize={15}
                onPageChange={page => setPage(page)}
              />
            </div>
          </div>
        ) : (
          <div className="empty-box">
            <Empty
              title="No Savings Plan"
              subtitle="Once you create a Savings plan, it will become visible/available here. Click the button below to create a Savings plan."
            />
            <button
              className="create-savings-plan"
              onClick={() => {
                if (
                  userData?.user?.verifiedKyc === true ||
                  userData?.user?.verifiedKyc === 'APPROVED' ||
                  userData?.verifiedKyc === true ||
                  userData?.verifiedKyc === 'APPROVED'
                ) {
                  handleShowSavings();
                } else {
                  openUpdateModal();
                }
              }}
            >
              Create Savings Plan
            </button>
          </div>
        )}
        <Modal
          cancelButtonProps={{ style: { display: 'none' } }}
          open={modalVisible}
          onCancel={closeModal}
          destroyOnClose
          footer=""
          className="new-modal"
          centered={true}
          okButtonProps={{ style: { display: 'none' } }}
          styles={{
            mask: {
              background: 'rgba(103, 169, 72, 0.2)',
              backdropFilter: 'blur(4px)',
            },
          }}
        >
          {showNewSavings && <NewSavings close={closeModal} user={userData} />}
          {showTopUp && (
            <TopUp
              close={closeModal}
              user={userData}
              selectedPlan={selectedPlan}
              getSavings={getSavings}
            />
          )}
        </Modal>
      </Layout>
    );
  }
};

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return {
    authUser,
  };
};

const mapDispatchToProps = {
  openUpdateBox,
};

export default connect(mapStateToProps, mapDispatchToProps)(Savings);
