import React from 'react';
import { useState, useRef } from 'react';
import info from '../../assets/info.svg';
import users from 'assets/usersp.svg';
import amount from 'assets/earned.svg';
import reward from 'assets/reward.svg';
import './referal.scss';
// import { Platformbutton } from 'components/common/button/button';

export const Referal = props => {
  const [copied, setCopied] = useState(false);

  const board = useRef(null);

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(props.link);
    setCopied(true);

    props.shareNum && props.shareNum(props.id, props.shares);
  };

  return (
    <div className="referal">
      <div className="referal__inner">
        {props.title && (
          <p className="referal__title" style={{cursor: 'pointer'}}>
            {' '}
            <i className="fas fa-link"></i> {props.title}
          </p>
        )}
        {props.link && (
          <p
            ref={board}
            className={`referal__link ${copied ? ' copied' : ''}`}
            onClick={copyCodeToClipboard}
          >
            {props.link}
          </p>
        )}
        {props.children}

        {copied ? (
          <p className="referal__copied">
            <i className="fas fa-thumbs-up"></i>{' '}
            {props.name ? props.name : 'Link'} has been copied to your clipboard
          </p>
        ) : (
          props.link && (
            <p className="referal-info">
              {' '}
              <img src={info} alt="info svg" />
              Click the box above to copy the link so you can share with your
              friends.
            </p>
          )
        )}
      </div>
    </div>
  );
};

export const Referall = props => {
  const [copied, setCopied] = useState(false);

  const board = useRef(null);

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(props.link);
    setCopied(true);

    props.shareNum && props.shareNum(props.id, props.shares);
  };

  return (
    <div className="referall">
      <div className="referall__inner">
        {props.title && (
          <p className="referalll__title">
            {' '}
            <i className="fas fa-link"></i> {props.title}
          </p>
        )}
        {props.link && (
          <p
            ref={board}
            className={`referall__link ${copied ? ' copied' : ''}`}
            onClick={copyCodeToClipboard}
          >
            {props.link}
          </p>
        )}
        {props.children}

        {copied ? (
          <p className="referall__copied">
            <i className="fas fa-thumbs-up"></i>{' '}
            {props.name ? props.name : 'Link'} has been copied to your clipboard
          </p>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export const ShareMessage = props => {
  const [copied, setCopied] = useState(false);

  const board = useRef(null);

  const copyCodeToClipboard = () => {
    setCopied(true);
  };

  return (
    <div className="referal">
      <div className="referal__inner">
        {props.title && (
          <p className="referal__title">
            {' '}
            <i className="fas fa-link"></i> {props.title}
          </p>
        )}
        {props.link && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            {/* const shareUrl = `https://t.me/share/url?url=$
            {encodeURIComponent(props.link)}&text=$
            {encodeURIComponent(props.text)}`; return ( */}
            <a
              href={`https://t.me/share/url?url=$
            {encodeURIComponent(app.wevesti.com/services)}&text=$
            {encodeURIComponent${props.link}}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <p
                ref={board}
                className={`referal__link ${copied ? ' copied' : ''}`}
                onClick={copyCodeToClipboard}
                style={{ width: '100%' }}
              >
                Click to share on Telegram
              </p>{' '}
            </a>

            <a
              href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                `${props.link}`,
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <p
                ref={board}
                className={`referal__link ${copied ? ' copied' : ''}`}
                onClick={copyCodeToClipboard}
                style={{ width: '100%' }}
              >
                Click to share on X
              </p>
            </a>
            {/* <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                props.link,
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <p
                className={`referal__link ${copied ? ' copied' : ''}`}
                style={{ width: '100%' }}
              >
                Click to share on Facebook
              </p>
            </a> */}
          </div>
        )}
        {props.children}

        {props.link && (
          <p className="referal-info">
            {' '}
            <img src={info} alt="info svg" />
            Click the box above to share with your friends.
          </p>
        )}
      </div>
    </div>
  );
};

export const Referralbox = props => {
  // eslint-disable-next-line
  const data = [
    {
      title: 'Total users referred',
      value: props.totalReferred ? props.totalReferred : 0,
      img: users,
    },
    {
      title: 'Total referrals earned',
      value: props.ReferralAmount ? props.ReferralAmount : 0,
      img: amount,
    },
  ];

  return (
    <div className="refbox">
      <div className="refbox__inner">
        <div className="refbox__data">
          {data.map((item, index) => (
            <div className="refbox__rdata" key={index}>
              {/* <img className="refbox__dimg" alt={item.title} src={item.img} /> */}
              <span className="refbox__right">
                <p className="refbox__title">{item.title}</p>
                <p className="refbox__subtitle">
                  {item.title.includes('earned') && '₦'} {item.value}
                </p>
              </span>
            </div>
          ))}
        </div>
        <img className="refbox__img" src={reward} alt="reward" />
      </div>
    </div>
  );
};

export const ExtraReferralbox = props => {
  // eslint-disable-next-line
  const data = [
    {
      title: 'Total users referred',
      value: props.totalReferred ? props.totalReferred : 0,
      img: users,
    },
    {
      title: 'Earnings',
      value: props.ReferralAmount ? props.ReferralAmount : 0,
      img: amount,
    },
  ];

  return (
    <div className="extrarefbox">
      <div className="extrarefbox__inner">
        <div className="extrarefbox__data">
          {data.map((item, index) => (
            <div className="extrarefbox__rdata" key={index}>
              <img
                className="extrarefbox__dimg"
                alt={item.title}
                src={item.img}
              />
              <span className="extrarefbox__right">
                <p className="extrarefbox__title">{item.title}</p>
                <p className="extrarefbox__exsubtitle">
                  {item.title.includes('Earnings') && '$'} {item.value}
                </p>
              </span>
            </div>
          ))}
        </div>
        <p className="extrarefbox__claimbutton" onClick={() => props.onClick()}>
          Claim reward
        </p>
      </div>
    </div>
  );
};

export const Referralboxx = props => {
  // eslint-disable-next-line
  const data = [
    {
      title: 'Total users referred',
      value: props.totalReferred ? props.totalReferred : 0,
      img: users,
    },
    {
      title: 'Total referrals earned',
      value: props.ReferralAmount ? props.ReferralAmount : 0,
      // img: amount,
    },
  ];

  return (
    <div className="refboxx">
      <div className="refboxx__inner">
        <div className="refboxx__data">
          {data.map((item, index) => (
            <div className="refbox__rdata" key={index}>
              {item.img ? (
                <img className="refbox__dimg" alt={item.title} src={item.img} />
              ) : (
                ''
              )}
              <span className="refbox__right">
                <p className="refbox__title">{item.title}</p>
                <p className="refbox__subtitle">
                  {item.title.includes('earned') && '$'} {item.value}
                </p>
              </span>
            </div>
          ))}
        </div>
        <div className="refboxx__link" onClick={() => props.onClick()}>
          <p>View list of people referred</p>
          <span>
            <svg
              width="18"
              height="15"
              viewBox="0 0 18 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.2894 0.793753C10.3822 0.700637 10.4926 0.62676 10.6141 0.576353C10.7355 0.525946 10.8658 0.5 10.9973 0.5C11.1288 0.5 11.259 0.525946 11.3805 0.576353C11.502 0.62676 11.6123 0.700637 11.7052 0.793753L17.7045 6.79306C17.7976 6.88594 17.8715 6.99628 17.9219 7.11775C17.9723 7.23923 17.9983 7.36946 17.9983 7.50098C17.9983 7.6325 17.9723 7.76272 17.9219 7.8842C17.8715 8.00568 17.7976 8.11601 17.7045 8.2089L11.7052 14.2082C11.5174 14.396 11.2628 14.5014 10.9973 14.5014C10.7318 14.5014 10.4771 14.396 10.2894 14.2082C10.1016 14.0204 9.99613 13.7658 9.99613 13.5003C9.99613 13.2348 10.1016 12.9801 10.2894 12.7924L15.5827 7.50098L10.2894 2.20959C10.1962 2.11671 10.1224 2.00637 10.072 1.88489C10.0216 1.76342 9.99561 1.63319 9.99561 1.50167C9.99561 1.37015 10.0216 1.23992 10.072 1.11845C10.1224 0.996972 10.1962 0.886634 10.2894 0.793753Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 7.49988C0 7.2347 0.0732919 6.98037 0.203752 6.79286C0.334212 6.60534 0.511154 6.5 0.695652 6.5H15.3043C15.4888 6.5 15.6658 6.60534 15.7962 6.79286C15.9267 6.98037 16 7.2347 16 7.49988C16 7.76507 15.9267 8.0194 15.7962 8.20691C15.6658 8.39442 15.4888 8.49977 15.3043 8.49977H0.695652C0.511154 8.49977 0.334212 8.39442 0.203752 8.20691C0.0732919 8.0194 0 7.76507 0 7.49988Z"
                fill="white"
              />
            </svg>
          </span>
        </div>
        <img className="refboxx__img" src={reward} alt="reward" />
      </div>
    </div>
  );
};
