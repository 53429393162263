import React from 'react'
import './newfeeds.scss'
import { Chatsv2 } from './chats'

export const SecondSec = () => {
  return (
    <div className='right-section'>
      <Chatsv2/>
      </div>
  )
}
