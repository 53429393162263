import {
  CREATE_MONO_CARD,
  CARD_ACTION,
  FETCH_CARD,
  FETCH_CARDS,
  FETCH_CARDTRANS,
} from 'appRedux/constants';

const INIT_STATE = {
  loading: true,
  cardLoading: false,
  message: null,
  error: null,
  isSuccessful: false,
  allCards: [],
  transactions: '',
  currCard: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_MONO_CARD:
      return {
        ...state,
        loading: false,
        error: null,
        ...action.payload,
      };
    case CARD_ACTION:
    case FETCH_CARDS:
    case FETCH_CARDTRANS:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_CARD:
      return {
        ...state,
        // loading: false,
        error: null,
        ...action.payload,
      };
    default:
      return state;
  }
};
