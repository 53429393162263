

import { SET_OPTION } from "appRedux/constants";


const initialState = {
   applyFor: '',
   email: '',
   fullname:'',
   phoneNumber:'',
};






export default (state = initialState, action) => {
   switch (action.type) {
     case SET_OPTION:
       return {
           ...state,
           applyFor: action.payload.applyFor,
           email: action.payload.email,
           fullname: action.payload.fullname,
           phoneNumber: action.payload.phoneNumber,


         };
     default:
       return state;
   }
 };

