import React, {useState, useEffect} from 'react';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import '../Admin.css';
import { VscSend } from "react-icons/vsc";
import dummyAvatar from '../../../assets/dummy-avatar.png'
import EmojiPicker from 'containers/Admin/NewDashboard/EmojiPicker';

const validationSchema = Yup.object({
  message: Yup.string()
    .required('Message is required!')
});

console.log(validationSchema);

const ErrorMessageWithTimeout = ({ name }) => {
  const [visible, setVisible] = useState(false);
  const { errors, touched } = useFormikContext();

  useEffect(() => {
    if (errors[name] && touched[name]) {
      setVisible(true);
      const timer = setTimeout(() => setVisible(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [errors, touched, name]);

  return (
    visible && (
      <div style={{ color: 'red', marginTop: '5px', fontSize: '12px' }}>
        {errors[name]}
      </div>
    )
  );
};



export const Postcomment = (props) => {

  const [message, setMessage] = useState('');

  return (
    <Formik
      initialValues={{ message: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        props.postComment(values, props.id);
        resetForm();
        setMessage(''); // Reset the message state
      }}
    >

      {({ isValid, dirty, setFieldValue }) => (
        <Form className="w-100">
          <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
          <img src={props.userData?.profilePictureURL ? props.userData?.profilePictureURL : dummyAvatar } alt="" className='postCommentImage'/>
            <Field
              as="textarea"
              name="message"
              className="message-input"
              placeholder="Type your message"
              cols="30"
              rows="2"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
                setFieldValue('message', e.target.value);
              }}
            />
            <div style={{right: '-60px', top: '10%'}}>
            <EmojiPicker onSelect={(emoji) => {
                const updatedMessage = message + emoji;
                setMessage(updatedMessage);
                setFieldValue('message', updatedMessage); // Update formik field value
              }} />
            {/* <EmojiPicker onSelect={props.insertEmoji} /> */}
            </div>
            <button
            className='sendIconForComment'
              type="submit"
              disabled={!(isValid && dirty)}
              style={{
                cursor: !(isValid && dirty) ? 'not-allowed' : 'pointer',
              }}
            >
              <VscSend className='commentSend'/>
            </button>
          </div>
          <ErrorMessageWithTimeout name="message" />
        </Form>
      )}
    </Formik>
  );
};
