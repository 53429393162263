import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { H1bStage2 } from './stage2/h1bStage2';
import { H1bStage1 } from './stage1Success.js/h1bStage1';
import { useSelector, useDispatch } from 'react-redux';
import { fetchViasForms } from 'appRedux/actions/visaForm';
import { fetchUserData } from 'appRedux/actions/profile';

ReactGA.initialize('G-CY10JKD1J3');

export const H1bFormFill = () => {
  const dispatch = useDispatch();
  const [stage, setsStage] = useState(0);
  const userD = useSelector(state => state.auth?.userData);
  const Forms = useSelector(state => state.visaForm.submittedForms);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.pathname]);

  useEffect(() => {
    dispatch(fetchViasForms(userD?.id, userD?.email, 'H1B', userD?.firstName));
    setLoading(true);
  }, []);

  // useEffect(() => {
  //   setsStage(Forms[0]?.formStage);
  // }, [loading]);

  return (
    <>
      <H1bStage1 userD={userD} />
      {/* {Forms[0]?.formStage == 2 ? <H1bStage2 /> : <H1bStage1 userD={userD} />} */}
    </>
  );
};
