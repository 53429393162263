import React, { useState, useEffect, useRef } from 'react'
import './newfeeds.scss'
import canstudyImg from '../../../../assets/newFeedsImg/firstSecImg/canadaStudyImg.png'
import canadaFlag from '../../../../assets/newFeedsImg/firstSecImg/canadaFlag.png'
import dotImg from '../../../../assets/newFeedsImg/firstSecImg/dotImg.png'
import avatarImg from '../../../../assets/newFeedsImg/firstSecImg/usersAvatar.png'
import woymImg from '../../../../assets/newFeedsImg/firstSecImg/woymImg.png'
import { Icons } from './data'
import { IG } from './IG'
import { Largemodal } from 'components/common/simplifiedmodal';
// import { IGData } from './data'
import { useSelector, useDispatch } from 'react-redux'
import { fetchVestiPosts } from 'appRedux/actions/posts'
import { About } from './About'
// import imgIcon from '../../../../assets/newFeedsImg/firstSecImg/galleryIcon.png'
import { IoImageOutline } from "react-icons/io5";
import EmojiSec from './EmojiSec'
import { BiMoviePlay } from "react-icons/bi"
import { LiaPollSolid } from "react-icons/lia"
import { Events, Members } from '../generalgroupIndex'


export const FirstSec = () => {
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(fetchVestiPosts())
  }, []) 

  // links
  const [about, setAbout] = useState(false)
  const [feeds, setFeeds] = useState(true)
  const [members, setMembers] = useState(false)
  const [events, setEvents] = useState(false)
  // const [dontDisplay, setDontDisplay] = useState (false)

  const handleClickAbout = () => {
    setAbout(true)
    setFeeds(false)
    setMembers(false)
    setEvents(false)
    // setDontDisplay(true)
  }
  const handleClickFeeds = () => {
    setAbout(false)
    setFeeds(true)
    setMembers(false)
    setEvents(false)
    // setDontDisplay(false)
  }
  const handleClickMembers = () => {
    setAbout(false)
    setFeeds(false)
    setMembers(true)
    setEvents(false)
    // setDontDisplay(false)
  }
  const handleClickEvents = () => {
    setAbout(false)
    setFeeds(false)
    setMembers(false)
    setEvents(true)
    // setDontDisplay(false)
  }
  

  // what's on your mind
  const [inputModal, setInputModal] = useState(false)

  
  
  // const [seeImgAndName, setSeeImgAndName] = useState(null)

  const handleInputClick = () => {
    setInputModal(true)
  }

  // image file upload
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      setSelectedFile(URL.createObjectURL(file)) 
  }
}

  const handleIconClick = () => {
    fileInputRef.current.click()
  }

  // const createPost = e => {
  //   e.preventDefault();
  //   setPost({ ...post, button: 'Creating Post...' });
  //   var formData = new FormData();

  //   // formData.append('title', post.title);
  //   formData.append('action', 'message');
  //   // formData.append('actionId', props.defaultGroupId);
  //   formData.append('message', post.description);
  //   formData.append('images', post.image);
  //   if (
  //     userData.firstName === null ||
  //     userData.firstName === '' ||
  //     userData.lastName === null ||
  //     userData.lastName === '' ||
  //     userData.username.includes('null')
  //   ) {
  //     // console.log(userData.verifiedKyc);
  //     openNotificationWithIconErr(
  //       'Post',
  //       'Only Users with full profile details can post, go to your profile and update your profile.',
  //       'error',
  //     );
  //     setPost({ ...post, button: 'Post' });
  //   } else {
  //     api
  //       .post(`/group/posts-and-comments`, formData)
  //       .then(res => {
  //         openNotificationWithIcon(
  //           'You have successfully created a post',
  //           'Success',
  //           'success',
  //         );

  //         dispatch(fetchVestiPosts());
  //         setPost({ description: '', image: '', button: 'Create Post' });
  //       })
  //       .catch(err => {
  //         openNotificationWithIconErr(errorMessage(err), 'Post', 'error');
  //         setPost({ description: '', image: '', button: 'Create Post' });
  //       });
  //   }
  // };

  return (
    <div className='left-section'>
      <div className='contentV2'>
          <img src={canstudyImg} alt='' className='relative' />
          <img src={canadaFlag} alt='' className='absolute' />
          <div className='all-details'>
            <h4>Canada Study Permit Cohort 5</h4>
            <div className='details'>
              <p>Growth Community</p>
              <img src={dotImg} alt='' />
              <p>Member since November 2022</p>
            </div>
            <div className='members'>
              <img src={avatarImg} alt='' />
              <p>5K+ members</p>
            </div>
          </div>
          <div className='tabs'>
            <div className='links'>
              <p className={`${ about ? 'bg-[#FFFFFF] opacity-100 cursor-pointer flex flex-col' : 'opacity-50 cursor-pointer'}`} onClick={handleClickAbout} >
              About
                {about ? (<div className='activeFeedsNav  '></div>) : (<div className=''></div>)}
              </p>

              <p className={`${ feeds ? 'bg-[#FFFFFF] opacity-100 cursor-pointer flex flex-col' : 'opacity-50 cursor-pointer'}`} onClick={handleClickFeeds} >
              Feeds 
                {feeds ? (<div className='activeFeedsNav'></div>) : (<div className=''></div>)}
              </p>

              <p className={`${ members ? 'bg-[#FFFFFF] opacity-100 cursor-pointer flex flex-col' : 'opacity-50 cursor-pointer'}`} onClick={handleClickMembers}>
              Members
              {members ? (<div className='activeFeedsNav'></div>) : (<div className=''></div>)}
              </p>

              <p className={`${ events ? 'bg-[#FFFFFF] opacity-100 cursor-pointer flex flex-col' : 'opacity-50 cursor-pointer '}`} onClick={handleClickEvents}>
                Events
              {events ? (<div className='activeFeedsNav'></div>) : (<div className=''></div>)}
              </p>
            </div>
          </div>
        </div>

        <div className='sec-part'>
          <Largemodal onClick={() => setInputModal(false)} open={inputModal}>
            <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', width:'600px', height: '350px'}}>
              <div className='modalImgName'>
                <img src={woymImg} alt=""/>
                <h4>Austin Emenike</h4>
              </div>
            <textarea name="" className='largeModalText' placeholder='What do you want to talk about?'></textarea>

            <div className='iconsFlex'>
              {/* img upload */}
              <div className='icons'>
                  <IoImageOutline 
                  className='imgIcon'
                  onClick={handleIconClick} 
                  style={{ cursor: 'pointer', width: '22px', height: '22px' }}/>
                  <input 
                    type="file"
                    accept='.png, .jpg, .svg, .jpeg' 
                    ref={fileInputRef} 
                    style={{ display: 'none' }} 
                    onChange={handleFileChange} 
                  />
                  {selectedFile && (
                    <div>
                      <p>Selected file:</p>
                      <img 
                        src={selectedFile} 
                        alt="Selected" 
                        style={{ width: '200px', height: 'auto' }}
                      />
                    </div>
                  )}
                    <p>Image</p>
                </div>

                {/* videoUpload */}
                  <div className='icons'>
                  <BiMoviePlay 
                  style={{ cursor: 'pointer', width: '22px', height: '22px' }}/>
                  <p>Video/Gif</p>
                  </div>

                  {/* Activity/Poll */}
                  <div className='icons'>
                  <LiaPollSolid 
                  style={{ cursor: 'pointer', width: '22px', height: '22px' }}/>
                  <p>Activity/Poll</p>
                  </div>

                {/* emojiPicker */}
                <div className='icons'>
                  <div
                    className="emojiPickerContainer"
                    style={{ position: '', right: '-60px' }}
                  >
                    <EmojiSec  /> 
                  </div>
                  <p>Feelings</p>
                </div>


              {/* <PostItems/> */}
            </div>

            <span className='largeModalPost'>Make a post</span>
            </div>
            </Largemodal>
            
            {feeds === true ? (<>
            <img src={woymImg} alt=""/>
            <div className='onYourMind'>
                  <input type="text" placeholder="What's on your mind?"  className='onYourMindP' onClick={handleInputClick} style={{outline: 'none'}}/>
                  <div className='iconsFlex'>
                    {/* img upload */}
                  {/* <div>
                  <IoImageOutline 
                  onClick={handleIconClick} 
                  style={{ cursor: 'pointer', width: '50px', height: '50px' }}/>
                  <input 
                    type="file" 
                    ref={fileInputRef} 
                    style={{ display: 'none' }} 
                    onChange={handleFileChange} 
                  />
                  {selectedFile && (
                    <div>
                      <p>Selected file:</p>
                      <img 
                        src={selectedFile} 
                        alt="Selected" 
                        style={{ width: '200px', height: 'auto' }}
                      />
                    </div>
                  )}
                </div> */}
                    {/* emojiPicker */}
                  {/* <div
                    className="emojiPickerContainer"
                    style={{ position: 'absolute', right: '-60px' }}
                  >
                    <EmojiPicker  />
                  </div> */}
                  </div>

                  {/* <PostItems/> */}
                  
            </div>
            </>) : (<div style={{display: 'none'}}></div>)}

              {/* <div>
              <img src={woymImg} alt=""/>
              <div className='onYourMind'>
                  <input type="text" placeholder="What's on your mind?"  className='onYourMindP' onClick={handleInputClick}/>
                  <PostItems/>
              </div>
              </div> */}
        </div>
        <div className='third-part'>
            {feeds && <IG /> }
            {about && <About />}
            {members && <Members/>}
            {events && <Events/>}
        </div>
        
        
      </div>
  )
}

const PostItems =()=>{
  return <div className='iconsFlex'>
  {Icons.map(({img, name}, index) => {
      return <div key={index} className='icons'>
      <img src={img} alt=""/>
      <p>{name}</p>
      </div>
  })}</div>
} 