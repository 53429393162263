import React, { useEffect, useState } from 'react';
import '../../../containers/Admin/Admin.css';
import intropathway from 'assets/TourGuide/Pathways/pathwayInfoBanner.svg';
import tooltip from 'assets/TourGuide/Tooltip.svg';
import { pathwayTourFeatures } from 'TourGuide/data';
import './Intro.scss';
import { LiaTimesSolid } from 'react-icons/lia';
import { navigate } from '@reach/router';
import { JoinPathays } from '../JoinPathway/JoinPathway';
import { MigrationFees } from '../MigrationFees/MigrationFees';
import { closeCardTourModal } from 'appRedux/actions/Tourguide';
import { useDispatch } from 'react-redux';

export const IntroPathwayGuide = props => {
  const [step, setStep] = useState(0);

  const dispatch = useDispatch();

  const handleCloseTourGuide = () => {
    dispatch(closeCardTourModal());
  };

  const handleFeatureClick = link => {
    if (link === '4') {
      navigate('/merchants');
    }
    if (link === '2') {
      navigate('/bank');
    } else {
      setStep(parseInt(link, 10) - 1);
    }
    // Set the step based on the link
  };

  switch (step) {
    case 0:
      return (
        <Intro
          setStep={setStep}
          handleFeatureClick={handleFeatureClick}
          handleCloseTourGuide={handleCloseTourGuide}
        />
      );
    case 1:
      return <p>All Features</p>;
    case 2:
      return (
        <JoinPathays
          setStep={setStep}
          handleCloseTourGuide={handleCloseTourGuide}
        />
      );
    case 3:
      return <p></p>;
    default:
  }
};

const Intro = props => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '20%', height: '100%' }}></div>

        <div className="  w-full h-full">
          <div className="myWalletTour" style={{ marginTop: '4rem' }}>
            {/* <div className="tourWalletBg"></div> */}
            <div className="PathwayTourArticleCContainer">
              <img
                className="PathwayTourArticleCContainer__tooltip"
                src={tooltip}
                alt=""
              />

              <div className="TourArticle">
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                        Pathways to move abroad!{' '}
                      </h1>
                      <p style={{ color: '#2B5219', fontSize: '14px' }}>
                        Select the wallet feature you want to learn about
                      </p>
                    </div>
                    <p
                      style={{
                        background: '#E2E2EA',
                        padding: '6px',
                        borderRadius: '6px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        props.handleCloseTourGuide();
                      }}
                    >
                      <LiaTimesSolid />
                    </p>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop: '30px',
                    }}
                  >
                    {pathwayTourFeatures.map(feature => {
                      return (
                        <div
                          key={feature.link}
                          className=""
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '5px',
                            cursor: 'pointer',
                          }}
                          onClick={() => props.handleFeatureClick(feature.link)}
                        >
                          <img className="tourIcons" src={feature.img} alt="" />
                          <p className="BankTourFeaturetitle">
                            {feature.title}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <img
              src={intropathway}
              alt=""
              style={{ width: '100%', marginTop: '4rem' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
