import React, { useState } from 'react';
import '../../../components/vesticards/selectcard.scss';
import { Platformbutton } from 'components/common/button/button';
import DividedPinInput from 'components/common/DividedPinInput';
import './allcards.scss';

const ActivateandDeactivate = props => {
  const [code, setCode] = useState(null);
  return (
    <div className="TemporaryPin">
      <div className="TemporaryPin__Header">
        <h1>Enter Card PIN</h1>
        <p>Enter your Physical Card PIN to temporarily disable your card.</p>
      </div>

      <div>
        <DividedPinInput onChange={setCode} len={4} />
      </div>

      <div className="flexButton">
        <div className="flexButton__cancel">
          <Platformbutton
            type="secondary"
            name={'Go Back'}
            click={() => {
              // console.log(code);
              //   props.setGGTpinModal(false);
            }}
          />
        </div>

        <div style={{ width: '100%' }}>
          <Platformbutton
            type="normal"
            name={'Disable Card'}
            click={
              () => {
                props.handleActivateDeactivate();
              }
              // console.log(props.GGTpin);
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ActivateandDeactivate;
