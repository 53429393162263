import {
    FETCH_GROUP_CHATS,
    FETCH_FEEDS_CHATS,
    ADD_NEW_FEEDS_CHATS,
    GET_CHAT_FRIENDID,
 
    // FETCH_IMAGE_CHATS,
    FETCH_CHAT_LIST,
} from 'appRedux/constants';

const INITIAL_STATE = {
    chats: [],
    friendId:'',
    friendName:'',
    friendImg:'',
    selectedImg: null,
    chatList: [],
}

export default ( state = INITIAL_STATE, action ) => {
    const { type, payload } = action;

    switch (type) {
        case FETCH_GROUP_CHATS: {

            // const newChats = payload.filter(
            //     newChat => !state.chats.some(chat => chat.id === newChat.id)
            // );
            // const mergedChats = [...state.chats, ...newChats];
            // mergedChats.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            // return { ...state, chats: mergedChats };

            // const newChats = payload.filter(
            //     newChat => !state.chats.some(chat => chat.id === newChat.id)
            // );
            // return { ...state, chats: [payload, ...state.chats] };
            // return { ...state, chats:payload };
            const filteredData = payload.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

            return { ...state, chats: filteredData};
        }

        case ADD_NEW_FEEDS_CHATS: {
            return { ...state, chats: [...state.chats, payload] };
        }

        case FETCH_FEEDS_CHATS: {
            return { ...state, chats: [...state.chats, payload] };
        }

        case  GET_CHAT_FRIENDID: {
            return { ...state, friendName: payload.name , friendImg:payload.img };
        }

        case FETCH_CHAT_LIST:{
            return { ...state, chatList: payload}
        }

        default:
            return { ...state };
    }
}