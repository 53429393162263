import React, { useEffect, useState } from 'react';
import '../billPayment.scss';
import '../../Admin.css';
import './ElectPayment.scss';
import { Success } from 'components/common/success/success';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import {
  IconOption,
  IconSingleValue,
  Singleselect,
} from 'components/common/inputs/singleselect';
import Loader from 'components/Loader';
import { Simplemodal } from 'components/common/simplifiedmodal';
import api from 'appRedux/api';
import SingleBiller from '../SingleBiller';
import bulb from '../../../../assets/bulbb.svg';
import newerror from '../../../../assets/newerror.svg';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Transreview } from 'components/common/transactionreview/review';
import { Comptransaction } from 'components/common/completetransaction/comptransaction';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Platformbutton } from 'components/common/button/button';
import { removeCommaAmount } from 'helpers/utils';
import { AlertError } from 'components/common/alertboxes/alertboxes';
import { Amountinputcurrency } from 'components/common/inputs/amountinput';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import { fetchTransactionOTP } from 'appRedux/actions/wallets';

const ElectricityMain = props => {
  const [open, setOpen] = useState(false);
  const userData = useSelector(state => state.auth.userData, _.isEqual);
  return (
    <>
      <Simplemodal onClick={() => setOpen(false)} open={open}>
        <ElectPayment
          onClick={() => setOpen(false)}
          powerData={props.powerData}
          setPowerData={props.setPowerData}
          user={userData}
          refetch={props.refetch}
        />
      </Simplemodal>
      <SingleBiller
        title="Electricity"
        image={bulb}
        color="#FCF8ED"
        onClick={() => setOpen(true)}
      />
    </>
  );
};

export default ElectricityMain;

export const ElectPayment = props => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [powerData, setPowerData] = useState([]);
  const [message, setMessage] = useState('');
  const [token, setToken] = useState(null);
  const [meterDetails, setMeterDetails] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const allWallet = useSelector(state => state.wallets.wallets, _.isEqual);

  const [form, setForm] = useState({
    type: '',
    billingNumber: '',
    amount: '',
    transactionPin: '',
  });

  var setInput = e => {
    var name = e.target.name;
    var value = e.target.value;

    setForm({ ...form, [name]: value });
  };

  const validate = async type => {
    const data = {
      billCode: type.billCode,
      itemCode: type.itemCode,
      billingNumber: form.billingNumber,
    };
    // console.log(data);
    setIsLoading(true);
    await api
      .post(`/bill-payment/validate`, data)
      .then(res => {
        setIsLoading(false);
        // console.log(res);
        res.data?.status === 'error'
          ? openNotificationWithIconErr(
              `Electricity Bill`,
              `${res.data?.message}, Please Input a valid Meter Number`,
              'error',
            )
          : setMeterDetails(res.data?.data);
        // console.log(meterDetails);
      })
      .catch(error => {
        setIsLoading(false);
        // console.log(error);
        setMessage(error.data?.message);
        // setMessage(error.response.data.message);
        openNotificationWithIconErr(
          `Bills Payment`,
          `${error.data?.message}`,
          'error',
        );
        // setStep(4);
      });
  };

  const getPowerData = async () => {
    setIsLoading(true);
    await api
      .get(`/bill-payment/categories?country=${selectedCurrency.value}`)
      .then(res => {
        setIsLoading(false);
        // console.log('Parent Array: ', res.data.data.data);
        const filteredData = res.data?.data;
        setPowerData(filteredData);
        // console.log('Nigerian Array: ', nig);
      })
      .catch(error => {
        setIsLoading(false);
        setMessage(error.response?.data?.message);
        openNotificationWithIconErr(
          `Bills Payment`,
          `${error.response?.data?.message}`,
          'error',
        );
        setStep(4);
        // console.log(error.response.data.message);
        // console.log('err', error.message);
        // console.log(error);
      });
  };

  useEffect(() => {
    if (selectedCurrency) {
      getPowerData();
    }
  }, [selectedCurrency]);

  useEffect(() => {
    let isMounted = true;
    if (form.billingNumber.length === 11 && isMounted) {
      validate(form.type);
    }
    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.billingNumber.length, form.type]);

  var Options =
    powerData?.length > 0
      ? (
          powerData.filter(item => item.label_name.includes('Meter Number')) ??
          []
        ).map(item => ({
          value: item.biller_name,
          label: item.biller_name,
          itemCode: item.item_code,
          billCode: item.biller_code,
          labelName: item.label_name,
          fee: item.fee === 0 ? 100 : item.fee,
          country: item.country,
          // fee: item.fee,
        }))
      : [];
  // console.log(Options);

  var ghOptions =
    powerData.length > 0
      ? (
          powerData.filter(item => item.label_name.includes('Meter Number')) ??
          []
        ).map(item => ({
          value: item.biller_name,
          label: item.biller_name,
          itemCode: item.item_code,
          billCode: item.biller_code,
          labelName: item.label_name,
          fee: item.fee === 0 ? 100 : item.fee,
          country: item.country,
          // fee: item.fee,
        }))
      : [];
  // console.log(Options);

  var handleChange = value => {
    // console.log(value);
    setForm({ ...form, type: value });
  };

  const payBill = async () => {
    setIsLoading(true);
    const data = {
      country: form.type.country,
      // amountInKoboCent: form.amount * 100,
      currency:
        form.type.country === 'NG'
          ? 'NGN'
          : form.type.country === 'GH'
          ? 'GHS'
          : '',
      recurrence: 'ONCE',
      // chargesInKobo: form.type.fee * 100,
      type: form.type.label,
      billingNumber: form.billingNumber,
      transactionOtp: form.transactionPin,
      category: 'electricity',
    };
    // console.log(data);
    await api
      .post(`/bill-payment/initiate`, data)
      .then(res => {
        setIsLoading(false);
        setMessage(res?.data?.message?.toLowerCase());
        setToken(res?.data?.data?.extra);
        // console.log(res.data.data.extra);
        openNotificationWithIcon(
          `Electricity Bill`,
          `Electricity purchase ${res.data.message.toLowerCase()}`,
          'success',
        );
        setStep(3);
        // console.log(res);
        // console.log(res.data.data);
      })
      .catch(error => {
        setIsLoading(false);
        // console.log(error)
        if (error.data) {
          // Handle specific error from the server
          setMessage(
            'Service temporarily not available on web, please try mobile',
          );
          openNotificationWithIconErr(
            `Electricity Bill`,
            `Service temporarily not available on web, please try mobile`,
            'error',
          );
          // setMessage(error.data?.message);
          // openNotificationWithIconErr(
          //   `Electricity Bill`,
          //   `${error.data?.message}`,
          //   'error',
          // );
          setStep(4);
        } else {
          // Handle generic network error
          setMessage('Network Error');
          openNotificationWithIconErr(
            `Electricity Bill`,
            `Network Error`,
            'error',
          );
        }
      });
  };

  switch (step) {
    case 0:
      return (
        <section className="w-full md:w-[400px] lg:w-[450px]">
          <Titlesubtitle
            title="Electricity •1 of 3"
            subtitle="Choose a wallet to debit from"
          />
          <span className="my-[20px]"></span>
          <Singleselect
            value={selectedCurrency}
            options={allWallet
              .filter(item => item.currency !== 'USD')
              .map(item => ({
                value: item.currency,
                label: item.name.toLocaleUpperCase(),
                image: item.currencyFlag,
                balance: item.balance,
              }))}
            placeholder="Select Wallet to debit"
            onChange={value => setSelectedCurrency(value)}
            components={{ SingleValue: IconSingleValue, Option: IconOption }}
          />
          <p className="my-12 h-[20px]"></p>
          <Platformbutton
            name={
              selectedCurrency
                ? ` Proceed with ${selectedCurrency.label}  `
                : 'Select'
            }
            type="normal"
            disabled={!selectedCurrency ? true : false}
            click={() => setStep(1)}
          />
        </section>
      );
    case 1:
      return (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="elect-payment-cont">
              <Titlesubtitle
                title="Electricity •2 of 3"
                subtitle="Fill in the field below to pay for your electricity without hassles."
              />

              <Myform
                form={form}
                step={step}
                meterDetails={meterDetails}
                powerData={powerData}
                handleChange={handleChange}
                validate={validate}
                Options={selectedCurrency === 'NGN' ? Options : ghOptions}
                setInput={setInput}
                setStep={setStep}
                setForm={setForm}
                setMeterDetails={setMeterDetails}
                setPowerData={setPowerData}
                balance={props.balance}
                setSelectedCurrency={setSelectedCurrency}
                selectedCurrency={selectedCurrency}
              />
            </div>
          )}
        </>
      );

    case 2:
      return (
        <div className="elect-payment-cont">
          <Titlesubtitle
            title="Electricity •3 of 3"
            subtitle="Fill in the field below to pay for your electricity without hassles."
          />
          <Finalize
            form={form}
            setStep={setStep}
            step={step}
            setForm={setForm}
            setMeterDetails={setMeterDetails}
            meterDetails={meterDetails}
            transactionpin={form.transactionPin}
            payBill={payBill}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setSelectedCurrency={setSelectedCurrency}
            selectedCurrency={selectedCurrency}
            dispatch={dispatch}
          />
        </div>
      );

    case 3:
      return (
        <Success
          title="Success"
          subtitle={`Electricity purchase ${message}`}
          text={
            !token ? 'Please check your mail for the token' : `Token : ${token}`
          }
          button="Close!"
          onClick={props.onClick}
        />
      );

    case 4:
      return (
        <Success
          image={newerror}
          type="error"
          title="Error"
          subtitle={message}
          button="Try again!"
          // onClick={props.onClick}
          onClick={() => setStep(2)}
        />
      );

    default:
      return <p>Error</p>;
  }
};

const Myform = props => {
  return (
    <>
      <form className="elect-payment">
        <Singleselect
          placeholder="Select a biller"
          value={props.form.type}
          options={props.Options}
          onChange={e => props.handleChange(e)}
        />

        {props.form.type && (
          <Inputfloat
            type="number"
            label="Meter Number"
            name="billingNumber"
            value={props.form.billingNumber}
            placeholder="Meter Number"
            onChange={props.setInput}
          />
        )}

        {props.meterDetails.name ? (
          <>
            <Inputfloat
              type="text"
              label="Name"
              name="name"
              value={props.meterDetails.name}
              disabled
            />
            <Amountinputcurrency
              type="text"
              currency={props.selectedCurrency.value === 'NGN' ? '₦' : 'GH¢'}
              name="amount"
              label="Amount"
              value={
                props.form.amount === 'NaN'
                  ? 0
                  : props.form.amount.toLocaleString('en-US')
              }
              disabled={false}
              placeholder="Enter amount"
              onChange={props.setInput}
              pattern="[0-9,.]*"
            />
            {removeCommaAmount(props.form.amount) > 0 &&
              (removeCommaAmount(props.form.amount) >
                props.selectedCurrency.balance / 100 ||
                removeCommaAmount(props.form.amount) < 1000) && (
                <AlertError
                  body={
                    removeCommaAmount(props.form.amount) < 1000
                      ? `Amount should be atleast ₦1,000 .`
                      : `You do not have upto ${
                          props.selectedCurrency.value === 'NGN' ? '₦' : 'GH¢'
                        }${props.form.amount} in your Vesti ${
                          props.selectedCurrency.value
                        } wallet, please deposit into your account.`
                  }
                />
              )}
            {removeCommaAmount(props.form.amount) > 0 &&
              removeCommaAmount(props.form.amount) > 50000 && (
                <AlertError
                  body={
                    removeCommaAmount(props.form.amount) >
                    removeCommaAmount(50000)
                      ? `Maximum amount allowed per transaction is ₦50,000`
                      : ''
                  }
                />
              )}
            <button
              type="submit"
              className="default-btn w-100 py-3"
              disabled={
                (props.form.type !== '' &&
                props.form.billingNumber !== '' &&
                props.form.amount !== ''
                  ? false
                  : true) ||
                props.form.amount < 1000 ||
                props.form.amount > 50000
              }
              onClick={() => props.setStep(2)}
            >
              Proceed to Enter your Pin
            </button>
          </>
        ) : (
          <></>
        )}
      </form>
    </>
  );
};

const Finalize = props => {
  const transactionPin = value => {
    props.setForm({ ...props.form, transactionPin: value });
  };

  return (
    <div className="ngnwithdrawal__review">
      <Transreview
        data={[
          { title: 'Transaction Type', value: 'Electricity' },
          {
            title: 'Disco',
            value: props.form.type.value,
          },
          {
            title: 'Amount',
            value: `${
              props.selectedCurrency.value === 'NGN' ? '₦' : 'GH¢'
            }${removeCommaAmount(props.form.amount).toLocaleString('en-US')}`,
          },
          {
            title: 'Fee',
            value: ` ${
              props.selectedCurrency.value === 'NGN' ? '₦' : 'GH¢'
            } 100`,
          },
          {
            title: 'Meter Number',
            value: props.form.billingNumber,
          },
          {
            title: 'Total',
            value: `${
              props.selectedCurrency.value === 'NGN' ? '₦' : 'GH¢'
            }${removeCommaAmount(
              parseInt(props.form.type.fee) + parseInt(props.form.amount),
            ).toLocaleString('en-US')}`,
          },
        ]}
      />
      <div className="mb-2"></div>
      <Comptransaction
        fetcher={props?.fetchTransactionOTP}
        setPin={transactionPin}
        loading={props.isLoading}
        goBack={() => props.setStep(0)}
        btn={`Finalize Transaction`}
        onFinish={Finalize}
        len={4}
        lower={true}
      >
        <Backcontinue goBack={() => props.setStep(0)}>
          <Platformbutton
            name="Finalize Transaction"
            type="normal"
            click={() => props.payBill()}
            disabled={
              props.form.transactionPin.length !== 4 || props.transLoading
                ? true
                : false
            }
          />
        </Backcontinue>
      </Comptransaction>
      <p style={{ textAlign: 'center' }}>
        Didn't get the code?{' '}
        <span
          style={{ cursor: 'pointer', color: 'green' }}
          onClick={() => props?.dispatch(fetchTransactionOTP())}
        >
          Resend
        </span>
      </p>
    </div>
  );
};
