import React, { useEffect } from 'react';
import '../sharedpost/sharedpost.scss';

import api from 'appRedux/api';
import Loader from 'components/Loader';
import { fetchVestiPosts } from 'appRedux/actions/posts';
import Singlepost from './singlepost';
import { useState } from 'react';
import { Empty } from 'components/common/empty/empty';
import { useParams } from '@reach/router';
import Layout from 'components/common/DashboardLayout';
import { useUserData } from 'helpers/hooks';
import { Stepback } from 'components/common/stepback/stepback';
import { navigate } from '@reach/router';
export const Fullpost = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const { userData } = useUserData();

  const { postId } = useParams();

  var goBack = () => {
    navigate('/feeds');
  };
  useEffect(() => {
    setLoading(true);
    api
      .get(`/group/posts-and-comments?limit=20&postId=${postId}`)
      .then(res => {
        setData(res.data.data.data[0]);

        setLoading(false);
      })
      .catch(err => {
        setData({
          error: 'No Such ID',
        });
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  } else {
    return (
      <Layout>
        {data && data.error !== 'No Such ID' ? (
          <>
            <div className="sharedpost-container">
              <div className="sharedpost-inner">
                <Stepback onClick={goBack} />

                <Singlepost
                  key={data.creator?.id}
                  id={data?.id}
                  username={data?.creator?.username}
                  firstname={data?.creator?.firstName}
                  lastname={data?.creator?.lastName}
                  fullname={
                    data?.creator?.firstName + ' ' + data?.creator?.lastName
                  }
                  email={data?.creator?.email}
                  userId={data.creator?.id}
                  userData={userData}
                  title={data.title}
                  description={data.description}
                  userComments={data?.replies}
                  profileUrl={data?.creator?.profilePictureURL}
                  createdAt={data?.createdAt}
                  date={data.date}
                  fetchVestiPosts={fetchVestiPosts}
                  hasLiked={data.hasLiked}
                  likes={data.reactions}
                />
              </div>
            </div>{' '}
          </>
        ) : (
          <>
            <div className="sharedpost-container">
              <div className="sharedpost-inner">
                <Stepback onClick={goBack} />
                <Empty
                  title="No Post"
                  subtitle="We are very sorry, the post with that ID does not exist"
                />
              </div>
            </div>
          </>
        )}
      </Layout>
    );
  }
};
