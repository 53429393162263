import React from 'react';
import covenLogo from 'assets/viasForm/covenlabsFullLogo.svg';
import InfraNovaLogo from 'assets/viasForm/infracomLogo.png';
import { FaArrowRight } from 'react-icons/fa';

const data = [
  {
    logo: covenLogo,
    companyName: 'Coven Work Inc',
    text:
      'Coven works is a leading data science and AI company dedicated to helping organizations recruit and build their staff with cutting-edge technology and expertise.',
    link: 'https://joincovenworks.com',
    id: '1',
  },
  {
    logo: InfraNovaLogo,
    companyName: 'Infra Nova Inc',
    text:
      'InfraNova uses its proprietary technology to drive growth for other technology and non-technology companies . Consulting for a variety of business and government clients in Africa, United Kingdom and the US.',
    link: 'https://infranova.us/',
    id: '2',
  },
];

export const FillingCompanies = () => {
  return (
    <div style={{}}>
      <p style={{ color: '#62943B', fontWeight: '600', fontSize: '16px' }}>
        About our Filing Companies
      </p>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '10px',
          gap: '20px',
        }}
      >
        {data.map(({ logo, id, text, companyName, link }) => {
          return (
            <div
              style={{
                background: `${
                  companyName === 'Coven Work Inc' ? '#ECF8FF' : '#FFFCF5'
                }`,
                color: `${
                  companyName === 'Coven Work Inc' ? ' #1481BA' : '#0E141C'
                }`,
                padding: '10px',
              }}
            >
              <img
                src={logo}
                alt="Coven Works Inc"
                style={{
                  // width: '10rem',
                  width: `${
                    companyName === 'Coven Work Inc' ? '10rem' : '6rem'
                  }`,
                }}
              />
              <p
                style={{
                  color: `${
                    companyName === 'Coven Work Inc' ? ' #1481BA' : '#0E141C'
                  }`,
                  fontWeight: '300',
                  fontSize: '14px',
                  padding: '10px 0px',
                }}
              >
                {text}
              </p>

              <p
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  color: `${
                    companyName === 'Coven Work Inc' ? ' #1481BA' : '#0E141C'
                  }`,
                  fontWeight: '600',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  window.open(link, '_blank');
                }}
              >
                Learn more <FaArrowRight />
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
