import React from "react";
import { Success } from "components/common/success/success";
import mtabs from "assets/mtabs.svg"
import { connect } from "react-redux";
import { stripeTreasury } from "appRedux/actions/transactions";
import VestiLogo from 'assets/Vesti_logo.svg';
import { navigate } from "@reach/router";
import "../Admin.css"

export const Multipletabs = ()=> {

    return (
        <section className="mainsuccess">

            <div className="mainsuccess__box">
                <img className="mainsuccess__logo" src={VestiLogo} alt="Vesti logo" />
                <Success
                    image={mtabs}
                    type="error"
                    title="Multiple Tabs"
                    subtitle={ "You have multiple tabs open for this application. Please close one tab and refresh to avoid conflicts and ensure optimal performance."}
                    button="Back to dashboard"
                    onClick ={()=>{
                        navigate('/bank');
                        window.location.reload();
                    }}

                />
            </div>
           
        </section>
        
    )
}

const mapStateToProps = ({ transactions}) => {
    const {message, loading} = transactions;
  
    return {
      message,
      loading
    };
  };
const mapDispatchToProps = {
    stripeTreasury
  };
  
export default connect( mapStateToProps,mapDispatchToProps)(Multipletabs);