import React, { useState } from 'react';
import dummyAvatar from 'assets/dummy-avatar.png';
import EmojiPicker from 'containers/Admin/NewDashboard/EmojiPicker';
// import EmojiPicker from '../EmojiPicker';
import '../../../../components/feeds/createpost.scss';
import '../../../../components/feeds/postInput.scss';
import { useSelector } from 'react-redux';
import { Largemodal } from 'components/common/simplifiedmodal';
import { NewUpload } from 'components/feeds/newUpload';

const CreateOtherGroupsPost = props => {
  const userData = useSelector(state => state.auth?.userData);
  const [inputModal, setInputModal] = useState(false)

  const handleInputClick = () => {
    setInputModal(true)
  }

console.log(props.post);
  return (
    <div>
       <Largemodal onClick={() => {
        setInputModal(false);
        
      }} open={inputModal}>
        <NewUpload 
        createPost = {props.createPost} 
        post = {props.post}
        setInput = {props.setInput}
        insertEmoji = {props.insertEmoji}
        setInputModal = {setInputModal}
        firstname={userData?.firstName}
        profilpicture={userData?.profilePictureURL}
        lastname={userData?.lastName}
        open={inputModal} 
        />
      </Largemodal> 

      <div style={{ display: 'flex' }}>
        <img
          src={!props.picture ? dummyAvatar : props.picture}
          alt="profilePic"
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '50vw',
            objectFit: 'cover',
          }}
        />

        {/* <div className="w-full pl-2">
          <div className=" flex relative mypostInput justify-between items-center w-full pr-0">
            <div className=" " style={{ width: '100%' }}>
              <input
                className="mypostInput__input"
                type="text"
                label=""
                name="post"
                placeholder={`What's on your mind ${props.name} ?`}
                value={props.post.message}
                onChange={props.setInput}
              />
            </div>

            <div
              className="emojiPickerContainer"
              style={{ position: 'absolute', right: '-60px' }}
            >
              <EmojiPicker onSelect={props.insertEmoji} />
            </div>
          </div>
        </div> */}

        <div className="w-full pl-2">
          <div className=" flex relative mypostInput justify-between items-center w-full pr-0">
            <div
              className=" "
              style={{ width: '90%', marginLeft: '4px', paddingTop: '10px' }}
            >
              <textarea
                className="mypostInput__input"
                type="text"
                label=""
                name="post"
                placeholder={`What's on your mind ${userData?.firstName} ?`}
                onClick={handleInputClick}
                // value={props.post.message}
                // onChange={props.setInput}
              />
            </div>

            <div
              className="emojiPickerContainer"
              style={{ position: 'absolute', right: '-60px' }}
            >
              <EmojiPicker onSelect={props.insertEmoji} />
            </div>
          </div>
        </div>
      </div>

      {/* <button
        className="createpost__right__bottom__button"
        onClick={e => props.createPost(e)}
        disabled={props.post.button === 'Posting' ? true : false}
      >
        {props.post.button}
      </button> */}
    </div>
  );
};

export default CreateOtherGroupsPost;
