import React from 'react';
import memberIcon from '../../../../assets/membership/clock.svg';
import detailIcon from '../../../../assets/membership/seeDetails.svg';
import CancelSubscription from '../cancelSubscription';
import TransactionHistory from '../transactionHistory';
import 'react-loading-skeleton/dist/skeleton.css';

const PremiumPlan = props => {
  return (
    <div className="">
      {' '}
      <div className="Profilemembership__plan">
        <div className="Profilemembership__top">
          <div className="Profilemembership__top__col">
            <p className="Profilemembership__top__title">
              First Class <span>${props.price}</span>
            </p>
            <p
              className="Profilemembership__top__planDetails"
              onClick={() => {
                props.setOpnSubFeature(true);
              }}
            >
              See plan details <img src={detailIcon} alt="" />
            </p>
          </div>
          <p
            className="Profilemembership__subscribe"
            onClick={() => {
              props.setOpnSub(true);
            }}
          >
            Change Plan
          </p>
        </div>

        <div className="Profilemembership__bottom">
          <img src={memberIcon} alt="" />

          <article>
            {props.DaysLeft < 0 || props.active === false ? (
              <p className="Profilemembership__bottom__title">
                Your Subscription has expired
              </p>
            ) : props.subStatus === 'TRIAL' ? (
              <>
                {props.DaysLeft < 31 ? (
                  <p className="Profilemembership__bottom__title">
                    Your trial will end in {props.DaysLeft} day(s)
                  </p>
                ) : (
                  <></>
                )}

                <p className="Profilemembership__bottom__subtitle">
                  Your subscription will automatically renew on{' '}
                  <span>{props.renewDate}.</span> You'll be notified beforehand.
                </p>
              </>
            ) : (
              <>
                {props.DaysLeft < 31 ? (
                  <p className="Profilemembership__bottom__title">
                    Your trial will end in {props.DaysLeft} day(s)
                  </p>
                ) : (
                  <></>
                )}

                <p className="Profilemembership__bottom__subtitle">
                  Your subscription will automatically renew on{' '}
                  <span>{props.renewDate}.</span> You'll be notified beforehand.
                </p>
              </>
            )}
          </article>
        </div>
      </div>
      {/* {!props.cancelledTrial || props.subStatus !== 'TRIAl' ? (
        <></>
      ) : (
        <CancelSubscription setCancelSubModal={props.setCancelSubModal} />
      )} */}
      <>
        {' '}
        {props.subStatus.toUpperCase() === 'TRIAL' ? (
          <></>
        ) : (
          <TransactionHistory />
        )}
      </>
    </div>
  );
};

export default PremiumPlan;
