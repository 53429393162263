import React, { useState, useEffect } from 'react';
import Layout from 'components/common/DashboardLayout';
import { navigate, useLocation } from '@reach/router';
import { updatePrevLocation } from 'appRedux/actions/location';
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6';
import { Note } from '../../note';
import { O1form } from '../../Forms/o1form';
import { useSelector, useDispatch } from 'react-redux';
import {
  SimpleCancelmodal,
  LargemodalCustomCancel,
} from 'components/common/simplifiedmodal';
import { updateUploadedFiles } from 'appRedux/actions/visaForm';
import { openNotificationWithIcon } from 'appRedux/actions/Common';
import '../../visatype.scss';
import icon from 'assets/viasForm/formNotificationIcon.svg';
import { Platformbutton } from 'components/common/button/button';
import { Payment } from '../../payment';
import { ReportForm } from '../../Forms/reportForm/reportForm';
import api from 'appRedux/api';
import { fetchViasForms, handleSections } from 'appRedux/actions/visaForm';
import { UploadSuccess } from '../../Forms/uploadSuccess';
import { Info } from '../Right-Info-Section/info';
import Vloader from 'assets/vestiLoader/vestiLoader.gif';
import { SkeletonFormLoader } from '../SkeletonLoader';
import FloatingButton from 'components/common/floatingbutton/FloatingButton';
import eligibleBanner from 'assets/viasForm/EligibilityTest/newEligibleBox.png';
import { TestFlow } from '../../EligibilityTest/testFlow';
import pathwayconfig from 'config.js';

export const Oonevisa = () => {
  const dispatch = useDispatch();

  const [reportModal, setReportModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const title = '0-1 Visa';
  const uploadedFiles = useSelector(state => state.visaForm.uploadedFiles);
  const checkVisaType = useSelector(state => state.visaForm.visaType);

  const [openWarning, setOpenWarning] = useState(false);

  const [opensubmitWarning, setOpenSubmitWarning] = useState(false);

  const pathname = window.location.pathname;
  const splitLocation = pathname.split('/');

  const [myvisaType, setMyVisaType] = useState('');
  const [visaValue, setVisaValue] = useState('');

  const Forms = useSelector(state => state.visaForm.submittedForms);
  const userD = useSelector(state => state.auth?.userData);

  const [countNonNullFilesValues, setCountNonNullFilesValues] = useState(0);
  const [totalFileCount, setTotalFileCount] = useState(0);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [paymentProgress, setPaymentProgress] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [amount, setAmount] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isloading, setIsloading] = useState(false);
  const [delayedLoader, setDeLayedLoader] = useState(false);

  const [openTestModal, setOpenTestModal] = useState(false);

  useEffect(() => {
    if (splitLocation[2] === ':visaType') {
      navigate('/pathways/visa');
    } else if (splitLocation[2] === '0-1visa') {
      setMyVisaType('0-1 Visa');
      setVisaValue('0-1');
    } else if (splitLocation[2] === '0-1A') {
      setMyVisaType('0-1 Visa');
      setVisaValue('0-1');
    } else if (splitLocation[2] === '0-1B') {
      setMyVisaType('0-1B Visa');
      setVisaValue('0-1B');
    } else if (splitLocation[2] === 'EB-1visa') {
      setMyVisaType('EB-1 Visa');
      setVisaValue('EB-1');
    } else if (splitLocation[2] === 'EB-2NIWvisa') {
      setMyVisaType('EB-2 NIW Visa');
      setVisaValue('EB-2 NIW');
    } else if (splitLocation[2] === 'H1Bvisa') {
      setMyVisaType('H1B  visa');
      setVisaValue('H1B');
    } else if (splitLocation[2] === 'B-1B-2visa') {
      setMyVisaType('B-1 / B-2  Visa');
      setVisaValue('B-1/B-2');
    } else if (splitLocation[2] === 'F1visa') {
      setMyVisaType('F1 Visa');
      setVisaValue('F1');
    }
  }, [paymentProgress]);

  useEffect(() => {
    const url = `${pathwayconfig.baseURL}/pathway/fetch-visa-prices?visaType=${visaValue}`;

    try {
      api.get(url).then(res => {
        if (res.data.priceLists[0]) {
          setAmount(res.data.priceLists[0].amount);
        }

        // cb();
      });
    } catch (error) {
      console.log(error);
    }
  }, [myvisaType]);

  useEffect(() => {
    dispatch(updateUploadedFiles());
  }, [loading]);

  useEffect(() => {
    // Check if Forms is not empty and Forms[0] is not null or undefined
    if (Forms.length > 0 && Forms[0] !== null && Forms[0] !== undefined) {
      // Count the number of non-null values in Forms[0]
      const count = Object.keys(Forms[0]).filter(key => Forms[0][key] !== null)
        .length;
      const TotalCount = Object.keys(Forms[0]).length;
      setCountNonNullFilesValues(count);
      setTotalFileCount(TotalCount);
      setUploadPercentage(
        Forms[0].fileUploadPercentage === null
          ? 0
          : Forms[0].fileUploadPercentage,
      );
      setAmountPaid(Forms[0].amountPaid);
      setPaymentProgress(
        Forms[0].paymentPercent === null ? 0 : Forms[0].paymentPercent,
      );
      setIsSubmitted(Forms[0]?.submitted);
    } else {
      setCountNonNullFilesValues(0);
      setUploadPercentage(0);
      setPaymentProgress(0);
      setIsSubmitted(false);
    }
  }, [Forms]);

  const updateApplication = async () => {
    setLoading(true);
    var url = `${pathwayconfig.baseURL}/pathway/update-visa-documents`;

    const formData = new FormData();
    for (const [fieldName, value] of Object.entries(uploadedFiles)) {
      formData.append(fieldName, value);
    }
    formData.append('email', userD?.email);
    formData.append('firstName', userD?.firstName);
    formData.append('id', Forms[0]?.id);
    formData.append('userId', userD?.id);
    formData.append('submitted', true);

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        body: formData,
      });
      const data = await response.json();
      dispatch(
        fetchViasForms(userD?.id, userD?.email, visaValue, userD?.firstName),
      );

      dispatch(updateUploadedFiles({}));
      setIsloading(true);
      // navigate('/pathways/visa');
      setIsSubmitted(true);
      openNotificationWithIcon('Visa Form', 'Submitted Successfully');
      setLoading(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setDeLayedLoader(true); // Set delayedLoader to true immediately
    setTimeout(() => {
      setDeLayedLoader(false);
      setIsloading(false); // Set delayedLoader back to false after 4 seconds
    }, 4000);
  }, [isloading]);

  const numberofUnsavedFile = Object.keys(uploadedFiles).length;
  const [paymentModal, setPaymentModal] = useState(false);

  const optionalSection = useSelector(
    state => state.visaForm.mysections.optional,
  );
  const requiredSection = useSelector(
    state => state.visaForm.mysections.required,
  );

  const requiredLength = Object.keys(requiredSection)?.length;

  const optionalLength = Object.keys(optionalSection)?.length;

  // console.log(requiredLength, optionalLength);
  const location = useLocation();
  const [previousPath, setPreviousPath] = useState();

  useEffect(() => {
    setPreviousPath(location.pathname); // Store current pathname on mount.
  }, [location]);

  const handleSetPreviousPath = id => {
    dispatch(updatePrevLocation(previousPath));
    navigate(`/chat`);
    localStorage.setItem('petitionId', Forms[0]?.id);
    localStorage.setItem('petitionAdminId', Forms[0]?.adminId[0]);
  };

  const [showSubmittedForm, setShowSubmmittedForm] = useState(false);

  const visaType0_1 = userD?.visaEligibility?.filter(
    visa => visa.visaType === '0-1',
  );

  return (
    <Layout>
      <SimpleCancelmodal
        open={paymentModal}
        onClick={() => setPaymentModal(false)}
      >
        <div style={{ marginTop: '20px' }}></div>
        <Payment
          visatitle={`${myvisaType} Visa`}
          visaLink={splitLocation[2]}
          vType={visaValue}
          formId={Forms[0]?.id}
          amountPaid={!Forms[0]?.amountPaid ? 0 : Forms[0]?.amountPaid}
          paymentPercent={paymentProgress}
          setPaymentModal={setPaymentModal}
          firstName={userD?.firstName}
        />
      </SimpleCancelmodal>
      <SimpleCancelmodal
        open={openWarning}
        onClick={() => setOpenWarning(false)}
      >
        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={icon} alt="" style={{ width: '120px', height: '120px' }} />
          <p style={{ color: '#67A948', fontWeight: '600', fontSize: '20px' }}>
            You have {numberofUnsavedFile} file(s) unsaved
          </p>
          <p style={{ color: '#2B5219', fontSize: '13px' }}>
            If you leave this page, your changes may be lost. Do you want to
            save your changes?
          </p>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
              marginTop: '14px',
              width: '100%',
            }}
          >
            <div
              style={{
                // background: 'red',
                color: 'red',
                fontWeight: '600',
                fontSize: '13px',
                padding: '12px 0px',
                width: '100%',
                textAlign: 'center',
                borderRadius: '10px',
                cursor: 'pointer',
                border: '1px solid red',
              }}
              onClick={() => {
                setLoading(true);
                dispatch(updateUploadedFiles({}));
                setOpenWarning(false);
                navigate('/pathways/visa');
                setLoading(false);
              }}
            >
              Don't Save
            </div>

            <div
              style={{
                background: '#67A948',
                color: 'white',
                fontWeight: '600',
                fontSize: '13px',
                padding: '12px 0px',
                width: '100%',
                textAlign: 'center',
                borderRadius: '10px',
                cursor: 'pointer',
              }}
              onClick={() => {
                updateApplication();
              }}
            >
              Save Changes
            </div>
          </div>
        </div>
      </SimpleCancelmodal>

      <SimpleCancelmodal
        open={opensubmitWarning}
        onClick={() => setOpenSubmitWarning(false)}
      >
        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={icon} alt="" style={{ width: '120px', height: '120px' }} />
          <p style={{ color: '#67A948', fontWeight: '600', fontSize: '20px' }}>
            {totalFileCount === 0
              ? '48'
              : totalFileCount -
                (numberofUnsavedFile + countNonNullFilesValues)}{' '}
            file(s) yet to be uploaded
          </p>
          <p style={{ color: '#2B5219', fontSize: '13px' }}>
            Ready to submit? Ensure that you have uploaded all files in each
            section.
          </p>

          {/* <div className="mb-4"></div> */}
          <Platformbutton
            type="normal"
            name={'Okay, thank you'}
            click={() => {
              setOpenSubmitWarning(false);
            }}
          />
        </div>
      </SimpleCancelmodal>

      <SimpleCancelmodal
        open={reportModal}
        onClick={() => setReportModal(false)}
      >
        <div
          style={{
            marginTop: '20px',
          }}
        >
          <ReportForm formId={Forms[0]?.id} setReportModal={setReportModal} />
        </div>
      </SimpleCancelmodal>

      <LargemodalCustomCancel
        open={openTestModal}
        onClick={() => setOpenTestModal(false)}
      >
        <TestFlow setOpenTestModal={setOpenTestModal} visaType={'0-1'} />
      </LargemodalCustomCancel>
      {/* {delayedLoader ? (
        <>Loading</>
      ) : ( */}
      <div className="visaMainFromSection">
        <p
          // onClick={() => {
          //   navigate('/pathways/visa');
          // }}
          style={{
            display: 'flex',
            color: '#67A948',
            alignItems: 'center',
            gap: '5px',
            fontWeight: '600',
            cursor: 'pointer',
            fontSize: '16px',
          }}
          onClick={() => {
            // Object.keys(uploadedFiles).length === 0
            //   ?
            // dispatch(handleSections({}));

            navigate('/pathways/visa');
            // : setOpenWarning(true);
          }}
        >
          <FaArrowLeftLong /> Go Back
        </p>

        <FloatingButton
          shape="circle"
          type="default"
          title="Miai (beta)"
          onClick={() => {
            handleSetPreviousPath();
          }}
        />

        <div
          style={{ marginTop: '2rem', marginBottom: '3rem' }}
          className="eligibleContainer"
        >
          <div>
            <h1
              style={{ color: '#14290A', fontSize: '20px', fontWeight: '600' }}
            >
              U.S. 0-1A{' '}
              {Forms[0]?.submitted ? <>Requirements</> : <>Requirements</>}
            </h1>

            {Forms[0]?.submitted ? (
              <p
                style={{
                  color: '#2B5219',
                  fontSize: '13px',
                  fontWeight: '600',
                }}
              ></p>
            ) : (
              <p
                style={{
                  color: '#2B5219',
                  fontSize: '13px',
                  fontWeight: '600',
                }}
              >
                Upload all necessary documents as listed out in the form below
              </p>
            )}
          </div>
        </div>

        <div
          style={{
            display: `${delayedLoader ? 'flex' : 'none'}`,
            justifyContent: 'space-between',
            width: '90vw',
          }}
        >
          <div
            className=""
            style={{
              display: `${delayedLoader ? 'block' : 'none'}`,
              width: '100%',
            }}
          >
            <SkeletonFormLoader />
          </div>

          <div
            className=""
            style={{
              display: `${delayedLoader ? 'block' : 'none'}`,
              width: '100%',
            }}
          >
            <SkeletonFormLoader />
          </div>
        </div>

        <div
          className={`${delayedLoader ? '' : 'VisaFormMainPage'}`}
          style={{
            display: `${delayedLoader ? 'none' : 'flex'}`,
          }}
        >
          {/* <div className="VisaFormMainPage__quickreadsectionMobile">
            <Note title={title} />
          </div> */}
          {/* if the form has not been finally submitted, display this  */}
          {/* && showSubmittedForm */}

          <div className="VisaFormMainPage__formsection">
            <>
              <div
                style={{
                  display: `${
                    (isSubmitted && showSubmittedForm) ||
                    (!isSubmitted && !showSubmittedForm)
                      ? 'block'
                      : 'none'
                  }`,
                }}
              >
                <O1form />
              </div>

              <div
                style={{
                  display: `${
                    isSubmitted && !showSubmittedForm ? 'block' : 'none'
                  }`,
                  height: '100%',
                }}
              >
                {' '}
                <UploadSuccess
                  setShowSubmmittedForm={setShowSubmmittedForm}
                  paymentProgress={paymentProgress}
                />
              </div>
            </>

            <div
              style={{
                margin: '2rem 0px 0px 0px',

                display: `${isSubmitted ? 'none' : 'flex'}`,
                justifyContent: 'space-between',
              }}
            >
              {paymentProgress < 50 ? (
                <p
                  onClick={() => {
                    requiredLength === 4 && optionalLength > 2
                      ? setPaymentModal(true)
                      : setPaymentModal(false);
                  }}
                  style={{
                    background: `${
                      requiredLength === 4 && optionalLength > 2
                        ? 'green'
                        : '#4C5366'
                    }`,
                    padding: '15px 20px',
                    color: 'white',
                    fontSize: '14px',
                    fontWeight: '600',
                    marginTop: '2rem',
                    borderRadius: '10px',
                    cursor: `${
                      requiredLength === 4 && optionalLength > 2
                        ? 'pointer'
                        : 'not-allowed'
                    }`,
                  }}
                >
                  Submit for review
                </p>
              ) : (
                <p
                  onClick={() => {
                    requiredLength === 4 && optionalLength > 2
                      ? updateApplication()
                      : setPaymentModal(false);
                  }}
                  style={{
                    background: `${
                      requiredLength === 4 && optionalLength > 2
                        ? 'green'
                        : '#4C5366'
                    }`,
                    padding: '15px 20px',
                    color: 'white',
                    fontSize: '14px',
                    fontWeight: '600',
                    marginTop: '2rem',
                    borderRadius: '10px',
                    cursor: `${
                      requiredLength === 4 && optionalLength > 2
                        ? 'pointer'
                        : 'not-allowed'
                    }`,
                  }}
                >
                  Submit for review
                </p>
              )}
            </div>
          </div>

          {/* if the form has not been finally submitted, display ends here */}
          <div className="VisaFormMainPage__quickreadSubsection">
            <Info
              setReportModal={setReportModal}
              uploadPercentage={uploadPercentage}
              paymentProgress={paymentProgress}
              amount={amount}
              setPaymentModal={setPaymentModal}
              checkVisaType={checkVisaType}
              submitted={isSubmitted}
              reviewStatus={Forms[0]?.reviewStatus}
              pid={Forms[0]?.pathway?.id}
              paymentModal={paymentModal}
              userD={userD}
              visaTypetest={visaType0_1}
              setOpenTestModal={setOpenTestModal}
              myvisaType={myvisaType}
              visaCopyLink={'O1visa'}
              // loading={loading}
            />
          </div>
        </div>
      </div>
      {/* )} */}
    </Layout>
  );
};
