import React from 'react';
import techstars from '../../../assets/techstars-grey.svg';
import techcity from '../../../assets/TechCity-grey.svg';
import bloomberg from '../../../assets/Bloomberg-grey.svg';
import './Ovfeature.scss';

const Ovfeature = () => {
  return (
    <div className="ovfeature-container">
      <div className="ovfeature-container-inner">
        <div className="left">
          <p>As featured in</p>
          <div className="logos">
            <img className='img-fluid' src={techstars} alt="" />
            <img src={techcity} alt="" />
            <img src={bloomberg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ovfeature;
