import { STUDENT_LOAN_DATA } from "appRedux/constants";




const initialState = {
 appliedBySelf: '',
 appliedByName: '',
 appliedByEmail:'',
 appliedByPhoneNumber:'',
};






export default (state = initialState, action) => {
 switch (action.type) {
   case STUDENT_LOAN_DATA:
     return {
         ...state,
         appliedBySelf: action.payload.appliedBySelf,
         appliedByName: action.payload.appliedByName,
         appliedByEmail: action.payload.appliedByEmail,
         appliedByPhoneNumber: action.payload.appliedByPhoneNumber,


       };
   default:
     return state;
 }
};

