import React from 'react';
import './prompt.scss';

export const Newprompt = props => {
  return (
    <section className="prompt">
      <div className="prompt__inner">
        <img className="prompt__inner__img" src={props.img} alt="digital svg" />
        <div className="prompt__inner__bottom">{props.children}</div>
      </div>
    </section>
  );
};

export const Newprompts = props => {
  return (
    <section className="prompts">
      <div className="prompts__inner">
        <img
          className="prompts__inner__img"
          src={props.img}
          alt="digital svg"
        />
        <div className="prompts__inner__bottom">{props.children}</div>
      </div>
    </section>
  );
};

export const ExtraNewprompt = props => {
  return (
    <section className="extraprompt">
      <div className="extraprompt__inner">
        <img
          className="extraprompt__inner__img"
          src={props.img}
          alt="digital svg"
        />
        <div className="extraprompt__inner__bottom">{props.children}</div>
      </div>
    </section>
  );
};
