import React, { useEffect, useState } from 'react';
import '../../../containers/Admin/Admin.css';
import walletTour from 'assets/TourGuide/tourwallet.svg';
import tooltip from 'assets/TourGuide/Tooltip.svg';
import { bankTourFeatures } from 'TourGuide/data';
import './intro.scss';
import { navigate } from '@reach/router';
import { LiaTimesSolid } from 'react-icons/lia';
import { Addmoney } from '../AddMoney/Addmoneysteps';
import { SendMoney } from '../SendMoney/Sendmoney';
import { AddWallet } from '../Addwallet/Addwallet';
import { closeCardTourModal } from 'appRedux/actions/Tourguide';
import { useDispatch } from 'react-redux';

export const IntroBankGuide = props => {
  const [step, setStep] = useState(0);

  const dispatch = useDispatch();

  const storedCurrency = localStorage.getItem('currency');
  const smallcase = storedCurrency.toLocaleLowerCase();

  const handleFeatureClick = link => {
    if (link === '2') {
      props.setIsModalOpen(false);
      props.setTourGuide(true);
      props.FlightTourGuide(false);
    }
    if (link === '3') {
      navigate(`/bank?wallet=${smallcase}`);
      setStep(parseInt(link, 10) - 1);
    } else {
      // Set the step based on the link
      setStep(parseInt(link, 10) - 1);
    }
  };

  const handleCloseBankTour = () => {
    props.setIsModalOpen(false);
    props.setTourGuide(true);
    props.setFlightTour(false);
  };

  const handleCloseTourGuide = () => {
    dispatch(closeCardTourModal());
  };

  switch (step) {
    case 0:
      return (
        <Intro
          setStep={setStep}
          handleFeatureClick={handleFeatureClick}
          handleCloseBankTour={handleCloseBankTour}
        />
      );
    case 1:
      return <p></p>;
    case 2:
      return (
        <Addmoney
          setStep={setStep}
          handleCloseTourGuide={handleCloseBankTour}
        />
      );
    case 3:
      return (
        <SendMoney
          setStep={setStep}
          handleCloseTourGuide={handleCloseBankTour}
        />
      );
    case 4:
      return (
        <AddWallet
          setStep={setStep}
          handleCloseTourGuide={handleCloseBankTour}
        />
      );
    default:
  }
};

const Intro = props => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '20%', height: '100%' }}></div>

        <div className="  w-full h-full">
          <div className="myWalletTour">
            {/* <img className="myWalletTour__wallet" src={walletTour} alt="" /> */}
            <div className="tourWalletBg"></div>
            <div className="TourArticleCContainer">
              <img
                className="TourArticleCContainer__tooltip"
                src={tooltip}
                alt=""
              />

              <div className="TourArticle">
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                        Wallets that are powerful
                      </h1>
                      <p className="TourArticleSubtitle">
                        Select the wallet feature you want to learn about
                      </p>
                    </div>
                    <p
                      style={{
                        background: '#E2E2EA',
                        padding: '6px',
                        borderRadius: '6px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        props.handleCloseBankTour();
                      }}
                    >
                      <LiaTimesSolid />
                    </p>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop: '30px',
                    }}
                  >
                    {bankTourFeatures.map(feature => {
                      return (
                        <div
                          key={feature.link}
                          className=""
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '5px',
                            cursor: 'pointer',
                          }}
                          onClick={() => props.handleFeatureClick(feature.link)}
                        >
                          <img className="tourIcons" src={feature.img} alt="" />
                          <p className="BankTourFeaturetitle">
                            {feature.title}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
