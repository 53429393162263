import React from 'react';
import "./TrustedPartners.scss"
import aws from "../../../assets/aws.svg"
import mainstack from "../../../assets/mainstack.png"
import microsoft from "../../../assets/microsoft.svg"
import westernunion from "../../../assets/westernunion.png"
import Tech4dev from "../../../assets/Tech4dev.png"
import nova from "../../../assets/novalogo.svg"
function TrustedPatners() {
  return (
    <div>
        <section className="trusted-partners">
            <div className='trusted-partners top'>
                <p>Our Trusted Partners</p>
                <p>Digitizing the Immigration market in Africa with cutting edge technology</p>
            </div>   

            <div className="companies">
                <div className="aws">
                    <img className=".img-fluid mw-100 h-auto" src={aws} alt="" style={{width: '50px'}}/>
                </div>
                <div className="microsoft">
                    <img className=".img-fluid mw-100 h-auto" src={microsoft} alt=""/>
                </div>
                <div className="facebook">
                    <img className=".img-fluid mw-100 h-auto" src={Tech4dev} alt=""/>
                </div>
                <div className="mainstack">
                    <img className=".img-fluid mw-100 h-auto" src={mainstack} alt=""/>
                </div>
                <div className="mainstack">
                    <img className=".img-fluid mw-100 h-auto" src={nova} alt=""/>
                </div>
                <div className="westernunion">
                    <img className=".img-fluid mw-100 h-auto" src={westernunion} alt=""/>
                </div>
                
            </div>                   
                    
        </section>
    </div>
  )
}

export default TrustedPatners