import React from 'react';
import './FloatingButton.scss';
import sendChat from 'assets/viasForm/chats/Send.svg';

const FloatingButton = ({ onClick, title }) => {
  return (
    <div className="floating-button-container">
      <b onClick={onClick} className="floating-button">
        <img src={sendChat} alt="" />
      </b>
    </div>
  );
};

export default FloatingButton;
