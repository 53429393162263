import React from 'react';
import { Singlecard } from './singlecard';
import './cards.scss';

// import { navigate } from "@reach/router";
// import pendingcard from 'assets/info-yell.svg'
import Loader from 'components/Loader';
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const Cards = props => {
  var goTo = () => {
    // navigate(`/mycard`, {state:{cdata:props.cdata[active],  rate:props.rate, balance:props.userData.walletInNGNKobo}})
  };

  var fundCard = () => {
    // props.setFundmodal ({value:true, id:props.cdata[active].cardId, name :props.cdata[active].providerName })
  };
  if (props.loading && props.cdata.length === 0) {
    return <Loader />;
  } else {
    return (
      <div className="" style={{ width: '27rem' }}>
        <div className="">
          {props.cdata.length > 0 ? (
            <Singlecard
              userdata={props.userData}
              data={props.cdata[props.active]}
              goTo={goTo}
              // active={active}
              from="true"
              fundCard={fundCard}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
};
