import React from 'react';

const USDPartnerIframeWidget = () => {
  var travelstartIframeId =
    'travelstartIframe-39daee2d-b6c4-4404-bbbc-efabeb5045db';

  var iframeUrl = 'https://www.travelstart.com/';
  var logMessages = false;
  var showBanners = false;
  var affId = '4769793';
  var affCampaign = '';
  var affCurrency = 'USD'; // ZAR / USD / NAD / ...
  // eslint-disable-next-line
  var height = '0px';
  // eslint-disable-next-line
  var width = '100%';
  var language = 'en'; // ar / en / leave empty for user preference

  // do not change these
  // eslint-disable-next-line
  var iframe = '#' + travelstartIframeId;
  var iframeVersion = '11';
  var autoSearch = false;
  var affiliateIdExist = false;
  var urlParams = {};
  var alreadyExist = [];
  var iframeParams = [];
  var cpySource = '';

  var match,
    pl = /\+/g,
    search = /([^&=]+)=?([^&]*)/g,
    decode = function(s) {
      return decodeURIComponent(s.replace(pl, ' '));
    },
    query = window.location.search.substring(1);
  while ((match = search.exec(query))) {
    urlParams[decode(match[1])] = decode(match[2]);
  }
  for (var key in urlParams) {
    if (urlParams.hasOwnProperty(key)) {
      if (key == 'search' && urlParams[key] == 'true') {
        autoSearch = true;
      }
      if (key == 'affId' || key == 'affid' || key == 'aff_id') {
        affiliateIdExist = true;
      }
      iframeParams.push(key + '=' + urlParams[key]);
      alreadyExist.push(key);
    }
  }

  if (!('show_banners' in alreadyExist)) {
    iframeParams.push('show_banners=' + showBanners);
  }
  if (!('log' in alreadyExist)) {
    iframeParams.push('log=' + logMessages);
  }
  if (!affiliateIdExist) {
    iframeParams.push('affId=' + affId);
  }
  if (!affiliateIdExist) {
    iframeParams.push('language=' + language);
  }
  if (!('affCampaign' in alreadyExist)) {
    iframeParams.push('affCampaign=' + affCampaign);
  }
  if (cpySource !== '' && !('cpySource' in alreadyExist)) {
    iframeParams.push('cpy_source=' + cpySource);
  }
  if (!('utm_source' in alreadyExist)) {
    iframeParams.push('utm_source=affiliate');
  }
  if (!('utm_medium' in alreadyExist)) {
    iframeParams.push('utm_medium=' + affId);
  }
  if (!('isiframe' in alreadyExist)) {
    iframeParams.push('isiframe=true');
  }
  if (!('landing_page' in alreadyExist)) {
    iframeParams.push('landing_page=false');
  }
  if (affCurrency.length == 3) {
    iframeParams.push('currency=' + affCurrency);
  }
  if (!('iframeVersion' in alreadyExist)) {
    iframeParams.push('iframeVersion=' + iframeVersion);
  }
  if (!('host' in alreadyExist)) {
    iframeParams.push('host=' + window.location.href.split('?')[0]);
  }
  var newIframeUrl =
    iframeUrl + '/?search=false' + '&' + iframeParams.join('&');
  //   iframe.attr('src', newIframeUrl);

  return (
    <div>
      {/* <div id="logs"></div>
      <iframe
        id={travelstartIframeId}
        frameBorder="0"
        scrolling="auto"
        style={{
          margin: 0,
          padding: 0,
          border: 0,
          height: '0px',
          backgroundColor: '#fafafa',
        }}
      ></iframe>
      <div className="spin"></div> */}
      <div className="partnership --frame">
        <iframe
          width="853"
          height="240"
          src={newIframeUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="myiframe"
          title="Travelstart"
        />
      </div>
    </div>
  );
};

export default USDPartnerIframeWidget;
