import paul from '../../../assets/newFeedsImg/secondSecImg/Paul.png'
import debs from '../../../assets/newFeedsImg/secondSecImg/debs.png'
import IntSch from '../../../assets/newFeedsImg/secondSecImg/intStudChatImg.png'
import olakunle from '../../../assets/newFeedsImg/secondSecImg/olakunle.png'
import tobi from '../../../assets/newFeedsImg/secondSecImg/tobi.png'
import greenImgIcon from '../../../assets/newFeedsImg/secondSecImg/imageGreenIcon.png'
import purpleVideoIcon from '../../../assets/newFeedsImg/secondSecImg/videoPurpleIcon.png'
import headPhone from '../../../assets/newFeedsImg/secondSecImg/headphones.png'
import doc from '../../../assets/newFeedsImg/secondSecImg/documentRedIcon.png'
import links from '../../../assets/newFeedsImg/secondSecImg/linkIcon.png'
import others from '../../../assets/newFeedsImg/secondSecImg/othersIcon.png'
import arrowImg from '../../../assets/newFeedsImg/secondSecImg/angle-right-circle.png'


export const users = [
    {id: 1, profileImg: paul, Username: 'Paul Adegbokan', lastMessage: 'Omo! The Go Global webinar was', time: '00.00', emoji: ''},
    {id: 2, profileImg: debs, Username: 'Debs', lastMessage: 'Yes sure', time: '', emoji: ''},
    {id: 3, profileImg: IntSch, Username: 'Int’l Student Loan Cohort 5', lastMessage: 'Eligibility criteria', time: '00.00', emoji: ''},
    {id: 4, profileImg: olakunle, Username: 'Olakunle from Vesti', lastMessage: 'typing...', time: '00.00', emoji: ''},
    {id: 5, profileImg: tobi, Username: 'Tobi OYEFESO', lastMessage: 'yes, sure!', time: '00.00', emoji: ''}
]

export const SharedData = [
    {img: greenImgIcon, file: 'Photos', number: '128 Files - 1.3GB', arrowImg: arrowImg},
    {img: purpleVideoIcon, file: 'Videos', number: '43 Files - 7.4GB', arrowImg: arrowImg},
    // {img: headPhone, file: 'Audios', number: '10 Files - 80KB', arrowImg: arrowImg},
    {img: doc, file: 'Documents', number: '74 Files - 649MB', arrowImg: arrowImg},
    {img: links, file: 'Links', number: '1,092 Files - 356MB', arrowImg: arrowImg},
    {img: others, file: 'Others', number: '77 Files - 289MB', arrowImg: arrowImg}
]