import paulImg from '../../../../assets/newFeedsImg/secondSecImg/Paul.png'
import fire from '../../../../assets/newFeedsImg/secondSecImg/fireImg.png'
import pin from '../../../../assets/newFeedsImg/secondSecImg/pin.png'
import doubleTick from '../../../../assets/newFeedsImg/secondSecImg/check-double.png'
import emoji from '../../../../assets/newFeedsImg/secondSecImg/emoji.png'
import debs from '../../../../assets/newFeedsImg/secondSecImg/debs.png'
import intStudent from '../../../../assets/newFeedsImg/secondSecImg/intStudChatImg.png'
import olakunle from '../../../../assets/newFeedsImg/secondSecImg/olakunle.png'
import Tobi from '../../../../assets/newFeedsImg/secondSecImg/tobi.png'
import galleryIcon from '../../../../assets/newFeedsImg/firstSecImg/galleryIcon.png'
import videoIcon from '../../../../assets/newFeedsImg/firstSecImg/videoIcon.png'
import activityIcon from '../../../../assets/newFeedsImg/firstSecImg/activityIcon.png'
import timiImg from '../../../../assets/newFeedsImg/firstSecImg/timiImg.png'
import timiBacImg from '../../../../assets/newFeedsImg/firstSecImg/timiImgBac.png'
import likesIcon from '../../../../assets/newFeedsImg/firstSecImg/likesIcon.png'
import commentIcon from '../../../../assets/newFeedsImg/firstSecImg/commentIcon.png'
import shareIcon from '../../../../assets/newFeedsImg/firstSecImg/shareIcon.png'
import saveIcon from '../../../../assets/newFeedsImg/firstSecImg/saveIcon.png'
import addComImg from '../../../../assets/newFeedsImg/firstSecImg/addComImg.png'
import linksquare from '../../../../assets/newFeedsImg/firstSecImg/linksquare.png'
import happyEmoji from '../../../../assets/newFeedsImg/firstSecImg/emojihappy.png'
import yelimg from '../../../../assets/newFeedsImg/firstSecImg/yelImg.png'
import selfieImg from '../../../../assets/newFeedsImg/firstSecImg/selfieImg.png'
import confImg from '../../../../assets/newFeedsImg/firstSecImg/confImg.png'
import video from '../../../../assets/newFeedsImg/firstSecImg/video.png'

export const chatData = [
    { id:1, img: paulImg, name: 'Paul Adegbokan', message: "Omo! The Go Global webinar was", emoj: fire, pin: pin, doubleTick: doubleTick },
    { id:2, img: debs, name: 'Debs', message: "", emoj: emoji, pin: '', doubleTick: '' },
    { id:3, img: intStudent, name: "Int'l Student Loan Cohort 5", message: "Adefisola: Eligibility criteria", emoj: '', pin: '', doubleTick: ''},
    { id:4, img: olakunle, name: "Olakunle from Vesti", message: "typing...", emoj: '', pin: '', doubleTick: ''},
    { id:5, img: Tobi, name: "Tobi OYEFESO", message: "Yes, sure", emoj: '', pin: '', doubleTick: ''}
] 

// What's on your mind 
export const Icons = [
    {id:1, img: galleryIcon, name: 'Image'},
    {id:2, img: videoIcon, name: 'Video/Gif'},
    {id:3, img: activityIcon, name: 'Activity/Poll'},
    {id:4, img: galleryIcon, name: 'Feelings'}
]

// IG 
export const IGData = [
    {id: 1, 

        img : timiImg,
   
    name: "Timi Babalola", 
    time: "12 hours ago", 
    backgroundImg:   [ 
        {img : timiBacImg},  
       
], 
    likesIcon: likesIcon, 
    likes: "2k Likes", 
    commentIcon: commentIcon, 
    comments: "19 Comments", 
    shareIcon: shareIcon, shares: 
    "54 Shares", saveIcon: saveIcon, 
    save: "71 Save", 
    details: "Application is now open for the fully-funded Chevening scholarship to study in the UK. Make sure to apply on time. You can learn more here: https://bit.ly/chevening", 
    addComImg: addComImg, 
    linksquare: linksquare, 
    happyEmoji: happyEmoji, 
    galleryIcon: galleryIcon },

    {id: 2, 
    img: timiImg,
    
  

    name: "Timi Babalola", 
    time: "12 hours ago", 
    backgroundImg:   [ 
        {img : yelimg},  
        {img : selfieImg},  
        {img : confImg}
], 
    likesIcon: likesIcon, 
    likes: "2k Likes", 
    commentIcon: commentIcon, 
    comments: "19 Comments", 
    shareIcon: shareIcon, 
    shares: "54 Shares", 
    saveIcon: saveIcon, 
    save: "71 Save", 
    details: "I had a very insightful and interesting day at the Meet and Greet event with Vesti CEO. It was nice to see Sunday Abeng, Bunmi Opadoyin, Olalekan Engine, and some other members of the community. We learned a lot, took a lot of pictures, and bonded very well. You really missed by not attending.", 
    addComImg: addComImg, 
    linksquare: linksquare, 
    happyEmoji: happyEmoji, 
    galleryIcon: galleryIcon },

    {id: 3, 
    img: timiImg,
    name: "Timi Babalola", 
    time: "12 hours ago", 
    backgroundImg: [{img:video}], 
    likesIcon: likesIcon, 
    likes: "2k Likes", 
    commentIcon: commentIcon, 
    comments: "19 Comments", 
    shareIcon: shareIcon, 
    shares: "54 Shares", 
    saveIcon: saveIcon, 
    save: "71 Save", 
    details: "If you need information or guidance on how to secure assistantships in the US, you really should watch this video. You'll learn a lot. ", 
    addComImg: addComImg, 
    linksquare: linksquare, 
    happyEmoji: happyEmoji, 
    galleryIcon: galleryIcon },
]