import React, { useState, useRef } from 'react';
import '../styles.scss';
import copyIcon from 'assets/miai/copy.svg';

export const MiaiClipboard = props => {
  const [copied, setCopied] = useState(false);

  const board = useRef(null);

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(props.link);
    setCopied(true);
    props.shareNum && props.shareNum(props.id, props.shares);
    props.click && props.click();
  };

  return (
    <div>
      {props.link && (
        <div ref={board} onClick={copyCodeToClipboard}>
          <img
            style={{
              width: '20px',
              height: '20px',
              cursor: 'pointer',
            }}
            src={copyIcon}
            alt=""
          />

          {/* <p>{copied ? 'Copied' : ' - Click to Copy - '}</p> */}
        </div>
      )}
    </div>
  );
};
