import React, { useEffect, useState } from 'react';
import tooltip from 'assets/TourGuide/Tooltip.svg';
import { bankTourFeatures } from 'TourGuide/data';
import '../BankTourGuide/Intro/intro.scss';
import { Wallets } from 'components/bank/balancecard/wallets';
import { LiaTimesSolid } from 'react-icons/lia';
import addwalletBtn from 'assets/TourGuide/addWallet/addwallet.png';
import FlightQuickAction from 'assets/TourGuide/FlightTour/flightQuickAction.svg';
import { FaCircle } from 'react-icons/fa6';

export const FlightTourGuide = props => {
  const [step, setStep] = useState(0);

  const handleBackToTour = () => {
    props.setIsModalOpen(false);
    props.setTourGuide(true);
    props.setFlightTour(false);
  };

  const handleCloseBankTour = () => {
    props.setIsModalOpen(false);
    props.setTourGuide(false);
    props.setFlightTour(false);
  };

  switch (step) {
    case 0:
      return (
        <div style={{ height: '100%', width: '100%' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div className="  w-full h-full">
              <div className="myWalletTour">
                <img
                  src={FlightQuickAction}
                  alt=""
                  style={{
                    marginLeft: '80%',
                    marginTop: '16rem',
                    borderRadius: '20px',
                    width: '10rem',
                  }}
                />

                <div
                  className="TourArticleCContainer"
                  style={{ marginLeft: '40%' }}
                >
                  <img
                    className="TourArticleCContainer__addWallettooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Book Flight{' '}
                          </h1>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            handleCloseBankTour();
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <ul
                        style={{
                          marginTop: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                        }}
                      >
                        <li
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                            color: '#2B5219',
                          }}
                        >
                          <FaCircle style={{ fontSize: '6px' }} />
                          Click on the{' '}
                          <span
                            style={{
                              color: '#67A948',
                              fontWeight: '500',
                            }}
                          >
                            “Book flight”
                          </span>{' '}
                          card on the home page.
                        </li>{' '}
                        <li
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                            color: '#2B5219',
                          }}
                        >
                          <FaCircle style={{ fontSize: '6px' }} />
                          Select what currency you would like to pay in.
                        </li>{' '}
                        <li
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                            color: '#2B5219',
                          }}
                        >
                          <FaCircle style={{ fontSize: '6px' }} />
                          Select your destination from the list that will
                          provided.
                        </li>{' '}
                        <li
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                            color: '#2B5219',
                          }}
                        >
                          <FaCircle style={{ fontSize: '6px' }} />
                          Go ahead to select ticket type (Return/one way)
                        </li>{' '}
                        <li
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                            color: '#2B5219',
                          }}
                        >
                          <FaCircle style={{ fontSize: '6px' }} />
                          Select your take off location and destination
                        </li>{' '}
                        <li
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                            color: '#2B5219',
                          }}
                        >
                          <FaCircle style={{ fontSize: '6px' }} />
                          You can select your departure date and search for your
                          flight.
                        </li>{' '}
                        <li
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                            color: '#2B5219',
                          }}
                        >
                          <FaCircle style={{ fontSize: '6px' }} />
                          Select your choice of flight and proceed to make
                          payment.
                        </li>{' '}
                        <li
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                            color: '#2B5219',
                          }}
                        >
                          <FaCircle style={{ fontSize: '6px' }} />
                          Once you have successfully made payment, your ticket
                          will be displayed on the screen.
                        </li>{' '}
                      </ul>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 1} of 1`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__active"></li>
                        </ul>

                        <div
                          onClick={() => {
                            handleBackToTour();
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Back{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    default:
  }
};
