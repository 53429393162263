import React, { useState, useEffect } from 'react';
import { RadioOption } from 'components/common/radiobutton/radiobutton';
import { Platformbutton } from 'components/common/button/button';
import { radio_card, radio_ngn } from 'assets/assets';
import DividedPinInput from 'components/common/DividedPinInput';
import '../../../components/common/completetransaction/comptransaction.scss';
import { Form } from 'antd';
import Loader from 'components/Loader';
import './subindex.scss';
import { Success } from 'components/common/success/success';
import errorsvg from 'assets/newerror.svg';
import api from 'appRedux/api';
import { useUserData } from 'helpers/hooks';
import { objectValuesStringify } from 'helpers/utils';
import _ from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Getpricefromdollar } from './getpricefromdollar';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import gbp from 'assets/currency/unitedkingdomFlag.png';

export const SubcriptionPaymentOption = props => {
  const [option, setOption] = useState('wallet');
  const [walletoption, setWalletOption] = useState('USD');
  const [step, setStep] = useState(1);
  const [transactionPin, setTransactionPin] = useState('');
  const [formdata, setformdata] = useState({});
  const { userData } = useUserData();
  const [errormsg, setErrorMsg] = useState('');
  const allWallet = useSelector(state => state.wallets.wallets, _.isEqual);
  const storedCurrency = localStorage.getItem('currency');

  const id = userData?.id;
  // console.log(userData.address.countryCode);

  const setPaymentOption = value => {
    setOption(value);
  };

  const setWalletPaymentOption = value => {
    setWalletOption(value);
  };

  var goBack = () => {
    step <= 1 ? setStep(0) : setStep(step - 1);
  };

  // if (!props.planType) {
  //   console.log('true plantype is empty');
  // } else {
  //   console.log('false');
  // }

  const handleCompleteTransaction = async () => {
    const payload = {
      userId: id,
      subscriptionStatus: 'TRIAL',
      description: 'Membership subscription',
      method: 'wallet',
      duration: props.subDuration.toUpperCase(),
      currency: walletoption,
      type: props.Plan,
      transactionOtp: transactionPin,
      trial: props.planType === null || !props.planType ? true : false,
    };

    const url = `/pathway/membership/subscription`;
    // setLoading(true);

    try {
      const res = await api.post(url, payload);
      const { data } = res;
      // console.log(data);
      openNotificationWithIcon(data.message, 'Subscription Payment', 'success');
      // setLoading(false);
      // setShowModal(false);
      setStep(4);
    } catch (error) {
      // console.log(error);
      if (error?.data?.errors) {
        openNotificationWithIconErr(
          objectValuesStringify(error?.data?.errors),
          'Subscription Payment',
          'error',
          setErrorMsg(error?.data?.errors),
        );
      } else {
        const err = error?.data?.message || error.message;
        openNotificationWithIconErr(err, 'Subscription Payment', 'error');
        setErrorMsg(err);
      }
      setStep(5);
      // setLoading(false);
    }
  };

  const handleCompleteTransactionwithcard = async () => {
    const payload = {
      userId: id,
      subscriptionStatus: '',
      description: 'Membership subscription',
      method: 'card',
      duration: props.subDuration.toUpperCase(),
      currency: 'USD',
      type: props.Plan,
      transactionOtp: transactionPin,
      trial: props.planType === null || !props.planType ? true : false,
    };

    const url = `/pathway/membership/subscription`;
    // setLoading(true);

    try {
      const res = await api.post(url, payload);
      const { data } = res;

      setTimeout(() => {
        window.open(data.data.trxUrl);
      }, 100);

      props.setOpenModal(false);
    } catch (error) {
      // console.log(error);
      if (error?.data?.errors) {
        openNotificationWithIconErr(
          objectValuesStringify(error?.data?.errors),
          'Subscription Payment',
          'error',
          setErrorMsg(error?.data?.errors),
        );
      } else {
        const err = error?.data?.message || error.message;
        openNotificationWithIconErr(err, 'Subscription Payment', 'error');
        setErrorMsg(err);
      }
      setStep(5);
      // setLoading(false);
    }
  };

  // Calculate the expiration date by adding 14 days to the current date
  const currentDate = new Date();

  // Calculate the expiration date by adding 14 days to the current date
  const expirationDate = new Date();
  expirationDate.setDate(currentDate.getDate() + 14);

  // Format the current date and expiration date as 'DD/MM/YYYY' strings
  const formatDate = date => {
    const day = date
      .getDate()
      .toString()
      .padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // const currentDateString = formatDate(currentDate);
  const expirationDateString = formatDate(expirationDate);

  switch (step) {
    case 1:
      return (
        <>
          <div className="paymentoption-container">
            <div className="paymentoption-inner">
              <div style={{ marginBottom: '1rem' }}>
                {/* <h2
                  style={{
                    color: '#67A948',
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >{` Step 1 of 2`}</h2> */}
                <h2 className="font-[600] text-[24px]  text-[#14290A]">
                  Make payment
                </h2>
                <p style={{ color: '#2B5219' }}>
                  Select how you want to pay for your membership
                </p>
              </div>

              <div className="paymentoption-inner mt-8 center mb-8">
                <RadioOption
                  image={radio_ngn}
                  changed={setPaymentOption}
                  id="1"
                  isSelected={option === 'wallet'}
                  label="Pay via your Vesti wallet"
                  sublabel="Add money using your debit card"
                  value="wallet"
                />
                <div className="mb-4"></div>
                <RadioOption
                  image={radio_card}
                  changed={setPaymentOption}
                  id="2"
                  isSelected={option === 'card'}
                  label="Pay via your debit card"
                  value="card"
                  sublabel="Add money using your debit card"
                />

                <div className="mb-4"></div>
              </div>
              {option === 'wallet' ? (
                <Platformbutton
                  type="normal"
                  name="Continue"
                  click={() => setStep(2)}
                  classname="fas fa-arrow-right"
                />
              ) : (
                <Platformbutton
                  type="normal"
                  name="Continue"
                  click={() => handleCompleteTransactionwithcard()}
                  classname="fas fa-arrow-right"
                />
              )}
            </div>
          </div>
        </>
      );
    case 2:
      return (
        <>
          <div className="paymentoption-container">
            <div className="paymentoption-inner">
              <div style={{ marginBottom: '1rem' }}>
                <h2
                  style={{
                    color: '#67A948',
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >{` Step 1 of 2`}</h2>
                <h2 className="font-[600] text-[24px]  text-[#14290A]">
                  Select Wallet
                </h2>
                <p style={{ color: '#2B5219' }}>
                  Select a mode from our options.
                </p>
              </div>

              <div
                className="paymentoption-inner mt-8 center mb-8"
                style={{ maxHeight: '300px', overflowY: 'scroll' }}
              >
                {allWallet.map(({ id, currencyFlag, currency }) => {
                  // const selectedCurrency = currency.find(() => {});
                  return (
                    <div key={id} className="w-full">
                      {/* {console.log(currency)} */}
                      <RadioOption
                        // {currency === 'GBP'?image={currencyFlag}:image={currencyFlag} }
                        image={currency === 'GBP' ? gbp : currencyFlag}
                        changed={setWalletPaymentOption}
                        id={id}
                        isSelected={walletoption === currency}
                        label={`${currency} Wallet`}
                        sublabel={`Send money with ${currency} Wallet`}
                        value={currency}
                      />
                      <div className="mb-4"></div>
                    </div>
                  );
                })}
              </div>

              {/* {option === 'wallet' ? ( */}
              <Platformbutton
                type="normal"
                name="Continue"
                click={() => setStep(3)}
                classname="fas fa-arrow-right"
              />
            </div>
          </div>
        </>
      );
    case 3:
      return (
        <>
          {' '}
          <div className="paymentoption-container">
            <div className="paymentoption-inner">
              <div style={{ marginBottom: '1rem' }}>
                <h2
                  style={{
                    color: '#67A948',
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >{` Step 2 of 2`}</h2>
                <h2 className="font-[600] text-[24px]  text-[#14290A]">
                  Transaction PIN
                </h2>
                <p style={{ color: '#2B5219', fontWeight: '600' }}>
                  Enter your 4 digit transaction PIN to finalize this
                  transaction.
                </p>
              </div>

              <div
                style={{
                  background: '#F7FFF3',
                  width: '100%',
                  padding: '30px',
                  border: '0.5px solid #97E373',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  borderRadius: '8px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <p className="leftsideSubPayload">Transaction Type</p>
                  <p className="rightsideSubPayload">{props.TransactionType}</p>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="2"
                  viewBox="0 0 460 2"
                  fill="none"
                >
                  <path
                    d="M0 1H460"
                    stroke="#67A948"
                    stroke-width="0.8"
                    stroke-dasharray="4 8"
                  />
                </svg>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <p className="leftsideSubPayload">Package</p>
                  <p className="rightsideSubPayload">{props.Plan}</p>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="2"
                  viewBox="0 0 460 2"
                  fill="none"
                >
                  <path
                    d="M0 1H460"
                    stroke="#67A948"
                    stroke-width="0.8"
                    stroke-dasharray="4 8"
                  />
                </svg>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <p className="leftsideSubPayload">Duration</p>
                  <p className="rightsideSubPayload">
                    {props.subDuration === 'monthly' ? '1 Month' : '1 Year'}
                  </p>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="2"
                  viewBox="0 0 460 2"
                  fill="none"
                >
                  <path
                    d="M0 1H460"
                    stroke="#67A948"
                    stroke-width="0.8"
                    stroke-dasharray="4 8"
                  />
                </svg>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <p className="leftsideSubPayload">Amount</p>
                  <p className="rightsideSubPayload">
                    <Getpricefromdollar
                      amount={props.amount * 100}
                      currency={walletoption}
                    />
                    {/* {walletoption} {props.amount} */}
                  </p>
                </div>
              </div>

              <div style={{ marginTop: '1rem', color: '#2B5219' }}>
                <p style={{ color: '#2B5219', fontSize: '16px' }}>
                  The Vesti Platform Fee is charged once daily for all
                  transactions performed in a single day.
                </p>
              </div>

              <div className="paymentoption-inner mt-8 center mb-8">
                <CompSubtransaction
                  setPin={setTransactionPin}
                  //   loading={true}
                  goBack={() => goBack()}
                  btn="Initiate Payment"
                  onFinish={() => handleCompleteTransaction()}
                  len={4}
                />
                {/* <div className="mb-4"></div> */}
              </div>
            </div>
          </div>
        </>
      );
    case 4:
      return (
        <>
          {' '}
          <Success
            title={`Membership Subscription Successful`}
            subtitle={`Congrats! you have successfully subscribed to the ${
              props.subDuration === 'monthly' ? '1 Month' : '1 Year'
            } ${
              props.Plan
            } membership and your subscription ends on ${expirationDateString}`}
            button="Okay, Thank You!"
            onClick={() => props.setOpenModal(false)}
          />
        </>
      );

    case 5:
      return (
        <>
          <Success
            image={errorsvg}
            title={'Payment Failed!'}
            subtitle={errormsg}
            onClick={() => setStep(1)}
            button="Try Again"
            type="error"
          />
        </>
      );

    default:
      return <>Not Found</>;
  }
};

export const CompSubtransaction = props => {
  return (
    <Form onFinish={props.onFinish} style={{ width: '100%' }}>
      {props.lower ? <></> : props.children}
      <div className="w-100 flex-fill pt-4" style={{ width: '100%' }}>
        {/* <p className="mb-2">
          {props.title ? props.title : 'Enter Your Transaction PIN'}{' '}
        </p> */}
        <DividedPinInput
          onChange={props.setPin}
          len={props.len}
          open={props.open}
        />
      </div>
      {props.loading ? (
        <Loader />
      ) : props.children ? (
        props.children
      ) : (
        <div className="btn-container">
          <Platformbutton
            name="Back"
            type="secondary"
            click={() => props.goBack()}
          />

          <Platformbutton name={props.btn} type="submit" />
        </div>
      )}
    </Form>
  );
};
