import React, { useEffect, useState } from 'react';
import '../Admin.css';
import Layout from 'components/common/DashboardLayout';
import { Link } from '@reach/router';
import 'components/pathway/becomeprovider.scss';
import pathwayusers from 'assets/pathways/pathwayusers.svg';
import CanadaStudyPermit from 'assets/pathways/video/CanadaStudyPermit.mp4';
import KenyaVisitingVisa from 'assets/pathways/video/KenyaVisitingVisa.mp4';
import CanadaPermanentResidencyVisa from 'assets/pathways/video/CanadaPermanentResidencyVisa.mp4';
import NetherlandHighlyskilledMigrantVisa from 'assets/pathways/video/NetherlandHighlyskilledMigrantVisa.mp4';

import { connect } from 'react-redux';

import 'components/bank/domore/domore.scss';

import { useDispatch } from 'react-redux';
import { openReward } from 'appRedux/actions/domore';
import api from 'appRedux/api';
import Loader from 'components/Loader';
import { navigate } from '@reach/router';
import './pathways.scss';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { MainLazyloader } from '../allcards/lazyloader';
import Vloader from 'assets/vestiLoader/vestiLoader.gif';

const localUserData = localStorage.getItem('userData');

export let userId;
if (localUserData) {
  userId = localStorage.getItem('userData').id;

  //Only business class user can access this group
}

export const videoDataPathay = [
  {
    country: 'canada',
    link: { CanadaStudyPermit },
  },

  {
    country: 'kenya',
    link: { KenyaVisitingVisa },
  },

  {
    country: 'netherland',
    link: { CanadaPermanentResidencyVisa },
  },

  {
    country: 'usa',
    link: { NetherlandHighlyskilledMigrantVisa },
  },
];

export const userData = {
  message: 'User data retrieved successfully',
  data: {
    user: {
      id: 'faaf0170-ff07-40db-8108-4199a7cc9313',
      firstName: 'Vesti',
      lastName: 'Test ',
      email: 'test@wevesti.com',
      username: 'vesti Test',
      emailVerified: true,
      verifiedKyc: true,
      factorAuth: false,
      KycPictureURL:
        'https://res.cloudinary.com/wevesti/image/upload/v1664809127/gpenyqlbdqevm4lizhsj.jpg',
      kycLevel: 'Level3',
      passedKyc: true,
      kycDocumentStatus: 'APPROVED',
      hasTransactionPin: true,
      country: 'USA',
      address: null,
      totalRefferralBonus: 0,
      phoneNumber: '+14697347949',
      totalReffered: '0',
      referalCode: '6hanJJker',
      planType: null,
      profilePictureURL: null,
      creditData: 'updated',
      stripeVirtualAccountNumber: '9670004059',
      stripeVirtualAccountRoutingNumber: '084106768',
      stripeVirtualBankName: 'Evolve Bank and Trust',
      stripeVirtualSwiftCode: '084106768',
      userType: 'PROVIDER',
    },
    wallets: [
      {
        id: '99c30c2e-b05e-4400-a56d-181d3867a6f7',
        userId: 'faaf0170-ff07-40db-8108-4199a7cc9313',
        currencyFlag:
          'https://res.cloudinary.com/wevesti/image/upload/v1659440605/wso14k3q6zedqdbjmaeo.jpg',
        balance: '0',
        currency: 'NGN',
        name: 'Naira wallet',
        active: true,
        bank: {
          number: '9977696809',
          bankName: 'Providus Bank',
          accountHolderName: 'Vesti Test ',
        },
      },
      {
        id: '16a2c794-04ff-4839-88b6-9ba8f2adc438',
        userId: 'faaf0170-ff07-40db-8108-4199a7cc9313',
        currencyFlag:
          'https://res.cloudinary.com/wevesti/image/upload/v1659440605/wso14k3q6zedqdbjmaeo.jpg',
        balance: '0',
        currency: 'USD',
        name: 'USD wallet',
        active: true,
        bank: {
          number: '9977696809',
          bankName: 'Providus Bank',
          accountHolderName: 'Vesti Test ',
        },
      },
    ],
    pendingTransfer: [],
  },
};

export const userDatas = {
  message: 'User data retrieved successfully',
  data: {
    user: {
      id: 'faaf0170-ff07-40db-8108-4199a7cc9313',
      firstName: 'Vesti',
      lastName: 'Test ',
      email: 'test@wevesti.com',
      username: 'vesti Test',
      emailVerified: true,
      verifiedKyc: true,
      factorAuth: false,
      KycPictureURL:
        'https://res.cloudinary.com/wevesti/image/upload/v1664809127/gpenyqlbdqevm4lizhsj.jpg',
      kycLevel: 'Level3',
      passedKyc: true,
      kycDocumentStatus: 'APPROVED',
      hasTransactionPin: true,
      country: 'USA',
      address: null,
      totalRefferralBonus: 0,
      phoneNumber: '+14697347949',
      totalReffered: '0',
      referalCode: '6hanJJker',
      planType: null,
      profilePictureURL: null,
      creditData: 'updated',
      stripeVirtualAccountNumber: '9670004059',
      stripeVirtualAccountRoutingNumber: '084106768',
      stripeVirtualBankName: 'Evolve Bank and Trust',
      stripeVirtualSwiftCode: '084106768',
      userType: 'PROVIDER',
    },
    wallets: [
      {
        id: '99c30c2e-b05e-4400-a56d-181d3867a6f7',
        userId: 'faaf0170-ff07-40db-8108-4199a7cc9313',
        currencyFlag:
          'https://res.cloudinary.com/wevesti/image/upload/v1659440605/wso14k3q6zedqdbjmaeo.jpg',
        balance: '0',
        currency: 'NGN',
        name: 'Naira wallet',
        active: true,
        bank: {
          number: '9977696809',
          bankName: 'Providus Bank',
          accountHolderName: 'Vesti Test ',
        },
      },
      {
        id: '16a2c794-04ff-4839-88b6-9ba8f2adc438',
        userId: 'faaf0170-ff07-40db-8108-4199a7cc9313',
        currencyFlag:
          'https://res.cloudinary.com/wevesti/image/upload/v1659440605/wso14k3q6zedqdbjmaeo.jpg',
        balance: '0',
        currency: 'USD',
        name: 'USD wallet',
        active: true,
        bank: {
          number: '9977696809',
          bankName: 'Providus Bank',
          accountHolderName: 'Vesti Test ',
        },
      },
    ],
    pendingTransfer: [],
  },
};

export const PathwaysCard = ({ description, amount, about }) => (
  <div className="col-12 col-sm-6 col-md-4 mb-3">
    <div className="p-4 dashboard-card">
      <div className="d-flex align-items-center">
        <span
          className="d-inline-block mr-3"
          style={{
            height: '50px',
            width: '50px',
            borderRadius: '50vw',
            backgroundColor: '#DFFFD0',
          }}
        ></span>
        <div
          className="text-uppercase font-light long-text-description"
          style={{ fontSize: '1rem', fontWeight: 300, maxWidth: '150px' }}
        >
          {description}
        </div>
      </div>
      <div style={{ fontWeight: 600, fontSize: '4rem' }}>{amount}</div>
      <div>
        <Link
          style={{ color: '#67A948' }}
          className="bg-transparent px-0 text-left"
          to="/dashboard"
        >
          See My {about}
          <span className="ml-2 d-inline-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="11"
              viewBox="0 0 12 11"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.219 10.7797C5.28867 10.8495 5.37143 10.9049 5.46255 10.9427C5.55367 10.9805 5.65135 11 5.75 11C5.84865 11 5.94633 10.9805 6.03745 10.9427C6.12857 10.9049 6.21133 10.8495 6.281 10.7797L10.781 6.27966C10.8508 6.20999 10.9063 6.12723 10.9441 6.03611C10.9819 5.94499 11.0013 5.84731 11.0013 5.74866C11.0013 5.65001 10.9819 5.55233 10.9441 5.46121C10.9063 5.37009 10.8508 5.28733 10.781 5.21766L6.281 0.717661C6.14017 0.576831 5.94916 0.497714 5.75 0.497714C5.55083 0.497714 5.35983 0.576831 5.219 0.717661C5.07817 0.858491 4.99905 1.0495 4.99905 1.24866C4.99905 1.44782 5.07817 1.63883 5.219 1.77966L9.1895 5.74866L5.219 9.71766C5.14915 9.78733 5.09374 9.87009 5.05593 9.96121C5.01812 10.0523 4.99866 10.15 4.99866 10.2487C4.99866 10.3473 5.01812 10.445 5.05593 10.5361C5.09374 10.6272 5.14915 10.71 5.219 10.7797Z"
                fill="#67A948"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 5.74854C0 5.94745 0.0458074 6.13821 0.127345 6.27887C0.208883 6.41952 0.319471 6.49854 0.434783 6.49854L9.56522 6.49854C9.68053 6.49854 9.79112 6.41952 9.87265 6.27887C9.95419 6.13821 10 5.94745 10 5.74854C10 5.54962 9.95419 5.35886 9.87265 5.2182C9.79112 5.07755 9.68053 4.99854 9.56522 4.99854L0.434783 4.99854C0.319471 4.99854 0.208883 5.07755 0.127345 5.2182C0.0458074 5.35886 0 5.54962 0 5.74854Z"
                fill="#67A948"
              />
            </svg>
          </span>
        </Link>
      </div>
    </div>
  </div>
);

export const PathwayCard = ({
  id,
  title,
  logo,
  description,
  stagesObject,
  creator,
  pathwayCost,
  country,
  month,
  pathwayVideo,
  duration,
  unit,
}) => {
  const [monthsRemaining, setMonthsRemaining] = useState(0);
  const createdAt = '2023-06-01T00:00:00.000Z';

  useEffect(() => {
    const startDate = new Date(createdAt);
    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 8); // Add 8 months to the start date

    const updateRemainingMonths = () => {
      const currentDate = new Date();
      const timeDiff = Math.abs(currentDate.getTime() - endDate.getTime());
      const months = Math.ceil(timeDiff / (1000 * 60 * 60 * 24 * 30));
      const remainingMonths = months > 0 ? months : 0;
      setMonthsRemaining(remainingMonths);
    };

    updateRemainingMonths();

    const interval = setInterval(() => {
      updateRemainingMonths();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [createdAt]);

  const [subPathwayObject, setSubPathwayObject] = useState([]);

  const MyData = async () => {
    useEffect(() => {
      api
        .get(`pathway/plan-and-join`)
        .then(res => {
          const myObject =
            res.data.data.plan === null || res.data.data.plan.active === false
              ? null
              : res.data.data.subscriptions;
          setSubPathwayObject(myObject);
        })
        .catch(err => err);

      MyData();
    }, []);
  };
  return (
    <div
      className="pathway-card"
      onClick={() =>
        navigate(`/pathwaydesc/${id}`, {
          state: {
            id,
            title,
            logo,
            description,
            stagesObject,
            creator,
            pathwayCost,
            country,
            month,
            pathwayVideo,
          },
        })
      }
    >
      <div className="card-body p-2">
        <div className="rounded bg-dark mb-3" style={{ height: '120px' }}>
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <img
              src={logo}
              alt="pathway logo"
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
            />
            <div
              className="duration-pathway"
              style={{ position: 'absolute', bottom: 0 }}
            >
              {subPathwayObject === null
                ? ''
                : subPathwayObject.some(item => item.pathwayTemplateId === id)
                ? 'Joined . '
                : ''}
              {`${duration}`}
              Month(s) duration
              {/*remaining */}
            </div>
          </div>
        </div>
        <div className="px-2">
          <div
            className="mb-4 pathway-title"
            style={{ fontSize: '18px', fontWeight: 600 }}
          >
            {title}
          </div>
          <p className="mb-3 long-text-description" style={{ fontWeight: 300 }}>
            {description}
          </p>

          <img
            src={pathwayusers}
            alt="pathway user"
            style={{
              height: '100%',
              width: '90%',
              objectFit: 'cover',
            }}
          />

          <div className="d-flex justify-content-between"></div>
        </div>
      </div>
    </div>
  );
};

export const AllPathways = props => {
  // const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [myPathways, setMyPathways] = useState({});

  const [videoUrl, setVideoUrl] = useState(null);

  const [isPathwayPopupOpen, setPathwayPopupOpen] = useState(false);

  const getAllItemsmyPathways = () => {
    const shuffled = myPathways.sort(() => 0.5 - Math.random());
    return shuffled;
  };

  const videoData = [
    {
      hashtag: 'Canada',
      description1: 'This Pathway is designed to help ',
      description2: 'individuals who wish to visit Canada',
      link: { CanadaStudyPermit },
      className: 'pathway-signup-background pathway-white',
    },

    {
      hashtag: 'KENYA',
      description1: 'This Pathway is designed to help ',
      description2: 'individuals who wish to visit Kenya',
      link: { KenyaVisitingVisa },
      className: 'pathway-signup-background pathway-white',
    },

    {
      hashtag: 'CANADA VISA',
      description1: 'How to Apply for Canada',
      description2: 'Permanent residency Visa.',
      link: { CanadaPermanentResidencyVisa },
      className: 'pathway-signup-background pathway-white',
    },

    {
      hashtag: 'NETHERLAND',
      description1: 'How to Apply for Netherland',
      description2: 'Highly Skilled Migrant Visa',
      link: { NetherlandHighlyskilledMigrantVisa },
      className: 'pathway-signup-background pathway-white',
    },
  ];

  const getRandomItemsVideoData = () => {
    const shuffled = videoData.sort(() => 0.5 - Math.random());
    return shuffled;
  };

  useEffect(() => {
    api
      // .get(`https://elementapi.wevesti.com/api`)
      .get(`/pathway/active/search`, null, 'pathway')
      .then(res => {
        setLoading(false);
        setMyPathways(res.data.data.data);
        setSuccess(true);
      })
      .catch(err => err);
  }, []);

  const goToNova = () => {
    // window.open('https://hello.novacredit.com/cards?country=NGA');
    navigate('/credithistory');
  };

  const dispatch = useDispatch();
  var Reward = () => {
    dispatch(openReward());
  };

  return (
    <>
      <Layout>
        <div>
          <h1
            style={{
              color: '#67A948',
              fontSize: '16px',
              fontWeight: '600',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              cursor: 'pointer',
              width: '6rem',
            }}
            onClick={() => {
              navigate('/pathways');
            }}
          >
            <FaArrowLeftLong />
            Go Back
          </h1>

          <div
            className=" isw-container"
            style={{ height: '85vh', width: '100%', overflow: 'scroll' }}
          >
            <div className=" flex_page_container d-flex justify-content-center">
              <div className=" px-3 px-md-4 px-lg-5 w-100">
                <section className={'services'}>
                  <div
                    className="d-flex justify-content-center  "
                    style={{
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginBottom: '4rem',
                    }}
                  >
                    <h1
                      style={{
                        color: '#303030',
                        fontSize: '26px',
                        fontWeight: '500',
                      }}
                    >
                      Explore Countries You Can Move to
                    </h1>
                    <p
                      style={{
                        maxWidth: '500px',
                        textAlign: 'center',
                        color: '#7D7D7D',
                        fontWeight: '300',
                      }}
                    >
                      Vesti has a vast selection of countries you can move to.
                      All you have to do is choose your country of choice and
                      let us do the heavy lifting
                    </p>
                  </div>

                  <div
                    className="services-grid"
                    style={{ gap: '30px', marginBottom: '50px' }}
                  >
                    {loading && <Loader />}
                    {success && (
                      <>
                        {getAllItemsmyPathways().map(
                          ({
                            id,
                            logo,
                            title,
                            description,
                            stagesObject,
                            creator,
                            pathwayCost,
                            country,
                            month,
                            pathwayVideo,
                            duration,
                            unit,
                          }) => (
                            <PathwayCard
                              key={id}
                              {...{
                                id,
                                logo,
                                title,
                                description,
                                stagesObject,
                                creator,
                                pathwayCost,
                                country,
                                month,
                                pathwayVideo,
                                duration,
                                unit,
                              }}
                            />
                          ),
                        )}
                      </>
                    )}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = ({
  //   kycupdate,
  domore,
}) => {
  //   const { openBox, openUpgrade } = kycupdate;
  const { openFees } = domore;

  return {
    openFees,
  };
};

export default connect(mapStateToProps, {})(AllPathways);

export const SinglePathways = props => {
  // const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [myPathways, setMyPathways] = useState([]);

  useEffect(() => {
    api
      .get(`/pathway/active/search`, null, 'pathway')
      .then(res => {
        setLoading(false);
        setMyPathways(res.data.data.data);
        setSuccess(true);
      })
      .catch(err => err);
  }, []);

  // const getAllItemsmyPathways = () => {
  const filterPathwaybyId = myPathways?.filter(p => p.id === props.pid);
  //   return filterPathwaybyId;
  // };

  return (
    <>
      <div
      //  className="services-grid"
      //  style={{ gap: '30px', marginBottom: '50px' }}
      >
        {loading && <MainLazyloader />}
        {success && (
          <>
            {filterPathwaybyId.map(
              ({
                id,
                logo,
                title,
                description,
                stagesObject,
                creator,
                pathwayCost,
                country,
                month,
                pathwayVideo,
                duration,
                unit,
              }) => (
                <PathwayCard
                  key={id}
                  {...{
                    id,
                    logo,
                    title,
                    description,
                    stagesObject,
                    creator,
                    pathwayCost,
                    country,
                    month,
                    pathwayVideo,
                    duration,
                    unit,
                  }}
                />
              ),
            )}
          </>
        )}
      </div>
    </>
  );
};

export const AllSinglePathways = props => {
  // const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [myPathways, setMyPathways] = useState([]);

  useEffect(() => {
    api
      .get(`/pathway/active/search`, null, 'pathway')
      .then(res => {
        setLoading(false);
        setMyPathways(res.data.data.data);
        setSuccess(true);
      })
      .catch(err => err);
  }, []);

  // const getAllItemsmyPathways = () => {
  // const filterPathwaybyId = myPathways?.filter(p => p.id === pid);
  //   return filterPathwaybyId;
  // };
  return (
    <>
      <div
        className=" isw-container"
        style={{ height: '100%', width: '100%', overflow: 'scroll' }}
      >
        <div className=" flex_page_container d-flex justify-content-center">
          <div className=" px-3 px-md-4 px-lg-5 w-100">
            <section className={'services'}>
              <div
                className="services-grid"
                style={{ gap: '30px', marginBottom: '50px' }}
              >
                {loading && (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={Vloader}
                      alt=""
                      style={{
                        width: '5rem',
                        height: '5rem',
                        marginTop: '5rem',
                      }}
                    />
                  </div>
                )}
                {success && (
                  <>
                    {myPathways.map(
                      ({
                        id,
                        logo,
                        title,
                        description,
                        stagesObject,
                        creator,
                        pathwayCost,
                        country,
                        month,
                        pathwayVideo,
                        duration,
                        unit,
                      }) => (
                        <PathwayCard
                          key={id}
                          {...{
                            id,
                            logo,
                            title,
                            description,
                            stagesObject,
                            creator,
                            pathwayCost,
                            country,
                            month,
                            pathwayVideo,
                            duration,
                            unit,
                          }}
                        />
                      ),
                    )}
                  </>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
