import React, { useState, useEffect } from 'react';
import './index.css';
import { Form, Modal, Switch } from 'antd';
import Layout from 'components/common/DashboardLayout';
import api from 'appRedux/api';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import {
  objectValuesStringify,
  formatAmount,
  getCurrency,
  toWholeCurrency,
} from 'helpers/utils';
import { Progress } from 'antd';
import Withdraw from './Withdraw';
import TopUp from './TopUp';
import moment from 'moment';
import { useUserData } from 'helpers/hooks';
import Back from 'components/common/back/back';
import Loader from 'components/Loader';
import { navigate } from '@reach/router';
import './newsavings.scss';
import { Platformbutton } from 'components/common/button/button';
import target from 'assets/targetsavings.svg';
import lock from 'assets/savingssafelock.svg';
import frequency from 'assets/freqsavings.svg';
import rates from 'assets/rates.svg';
import calender from 'assets/maturity.svg';
import clock from 'assets/daysleft.svg';
import bg from 'assets/zebrapattern.svg';
import Mysavingstransactions from 'components/bank/mysavingstransactions';

const Savings = props => {
  const id = props.location.state.id;
  // console.log('Savings ID: ', id);
  const { userData } = useUserData();
  const [modalVisible, setModalVisible] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [showWithdrawal, setShowWithdrawal] = useState(false);
  const [showTopUp, setShowTopUp] = useState(false);
  const [autoTopUp, setAutoTopUp] = useState(null);

  const [savings, setSavings] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (id === null || id === undefined) {
      navigate('/savings');
    }
  }, [id]);

  useEffect(() => {
    const getSavings = async () => {
      setFetchLoading(true);
      const url = `/safe-lock/list?savingsId=${id}`;
      try {
        const res = await api.get(url);
        // console.log(res);
        const data = res.data?.data[0];
        setSavings(data);
        setAutoTopUp(data?.autoTopUp);
        setFetchLoading(false);
      } catch (error) {
        setFetchLoading(false);
        if (error?.data?.errors) {
          openNotificationWithIcon(
            objectValuesStringify(error?.data?.errors),
            'Savings',
            'error',
          );
        } else {
          const msg = error?.data?.message || error.message;
          openNotificationWithIcon(msg, 'Savings', 'error');
        }
      }
    };
    getSavings();
  }, [id]);

  const handleSwitch = async checked => {
    const newValue = checked ? 'auto' : 'default';
    const url = `/safe-lock/update`;
    const data = {
      safeLockId: savings?.id,
      autoTopUp: newValue,
    };
    setAutoTopUp(newValue);
    try {
      const res = await api.patch(url, data);
      // console.log(res);
      openNotificationWithIcon(res?.data?.message, 'Auto TopUp', 'success');
    } catch (error) {
      if (error?.data?.errors) {
        openNotificationWithIcon(
          objectValuesStringify(error?.data?.errors),
          'Auto TopUp',
          'error',
        );
      } else {
        const msg = error?.data?.message || error.message;
        openNotificationWithIcon(msg, 'Auto TopUp', 'error');
      }
    }
  };

  const handleShowTopUp = () => {
    setShowTopUp(true);
    showModal();
  };

  const handleEdit = () => {
    navigate('/edit-savings', { state: { savings: savings } });
  };

  const getPercentage = () => {
    const remainder =
      (savings?.targetAmount - savings?.lockedAmount) / savings?.targetAmount;
    const dt = remainder * 100;
    return (100 - dt).toFixed(2);
  };

  function getNumberOfDays(endDate) {
    // Get the current date
    const currentDate = moment();

    if (!endDate.isValid()) {
      // Handle invalid date if needed
      return 0;
    }

    // Calculate the difference between the end date and the current date in days
    const differenceInDays = endDate.diff(currentDate, 'days');

    return differenceInDays;
  }

  const daysLeft = getNumberOfDays(moment(savings?.endDate));
  // console.log(`${daysLeft} days`);

  const handleShowWithdrawal = () => {
    setShowWithdrawal(true);

    showModal();
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setShowWithdrawal(false);
    setShowTopUp(false);
  };

  // console.log(autoTopUp);
  // console.log('savings:', savings?.planCategory.title);

  if (fetchLoading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }
  return (
    <Layout>
      <div className=" isw-container saving--container">
        <Back page="Savings" link="savings" />
        <div
          className="flex_page_container pt-4"
          id="savings"
          style={{ paddingLeft: '0' }}
        >
          <div className="row">
            <div className="col-sm-9 col-md-6 col-lg-7">
              <div
                className="savings-box"
                style={{
                  background: `url(${bg})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                <div className="savings-box-inner">
                  <div>
                    <p className="pb-0 mb-3 title">Total Expected Yield</p>
                    <span className="d-flex gap-3 align-content-center">
                      <h2>
                        {show
                          ? `${getCurrency(
                              savings?.currency,
                            )}${savings?.totalExpectedYield &&
                              formatAmount(
                                savings?.totalExpectedYield / 100,
                                2,
                              )}`
                          : 'XXX'}
                      </h2>
                      <i
                        className={`fas fa-${show ? 'eye' : 'eye-slash'}`}
                        onClick={() => setShow(!show)}
                      />
                    </span>
                  </div>
                  {savings?.status === 'active' && (
                    <div>
                      <p className="edit-button" onClick={() => handleEdit()}>
                        Edit Plan
                      </p>
                    </div>
                  )}
                </div>
                {/* progress bar */}
                <div className="px-4 d-flex align-items-center justify-content-between mt-5">
                  <div className="w-full">
                    <p>savings progress</p>
                    <Progress
                      style={{
                        width: '95%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                      className="savings-progress"
                      strokeColor="#67A948"
                      percent={getPercentage()}
                      showInfo={true}
                      status="active"
                    />
                  </div>
                </div>
              </div>

              {/* action   buttons */}
              {savings?.status === 'active' && (
                <div className="savings-button-container">
                  <Platformbutton
                    type="normal"
                    name="Top Up Savings"
                    click={() => handleShowTopUp()}
                  />
                  {(savings?.status === 'active' ||
                    savings?.disbursementStatus === 'default') &&
                    savings?.planCategory?.title === 'Flexible' && (
                      <Platformbutton
                        type="secondary"
                        name="Break Safe"
                        click={() => handleShowWithdrawal()}
                      />
                    )}
                </div>
              )}
            </div>
            <div className="col-sm-3 col-md-6 col-lg-5">
              <div className="savings-info-box">
                <p className="savings-info">Savings Info</p>
                {/* first card */}
                <div className="savings-info-inner ">
                  <div
                    className="savingsaction__vesticard"
                    style={{
                      background: ' #F3FBF9',
                      border: '0.5px solid #9FDFCA',
                      width: '100%',
                    }}
                  >
                    <p>Total Saved</p>
                    <p>{`${getCurrency(
                      savings?.currency,
                    )}${savings?.lockedAmount &&
                      formatAmount(savings?.lockedAmount / 100, 2)}`}</p>

                    <img
                      src={lock}
                      alt=""
                      className="savingsaction__vesticard__bgcards"
                    />
                  </div>
                  <div
                    className="savingsaction__vesticard"
                    style={{
                      background: ' #f7fff3',
                      border: '0.5px solid #97e373',
                      width: '100%',
                    }}
                  >
                    <p>Savings Target</p>
                    <p>{`${getCurrency(
                      savings?.currency,
                    )}${savings?.targetAmount &&
                      formatAmount(savings?.targetAmount / 100, 2)}`}</p>

                    <img
                      src={target}
                      alt=""
                      className="savingsaction__vesticard__bgcards"
                    />
                  </div>
                </div>
                {/* second set card */}
                <div className="savings-info-inner ">
                  <div
                    className="savingsaction__vesticard"
                    style={{
                      background: '#FFFAEB',
                      border: '0.5px solid #FEC84B',
                      width: '100%',
                    }}
                  >
                    <p style={{ color: '#7A2E0E' }}>Savings Amount</p>
                    <p>{`${getCurrency(savings?.currency)}${savings?.amount &&
                      formatAmount(savings?.amount / 100, 2)}`}</p>
                    <img
                      src={frequency}
                      alt=""
                      className="savingsaction__vesticard__bgcards"
                    />
                  </div>
                  <div
                    className="savingsaction__vesticard"
                    style={{
                      background: '#FBF3FF',
                      border: '0.5px solid #E5AEFF',
                      width: '100%',
                    }}
                  >
                    <p style={{ color: '#57266E' }}>Interest Rates</p>
                    <p>{savings?.interest && savings?.interest}% P.A</p>

                    <img
                      src={rates}
                      alt=""
                      className="savingsaction__vesticard__bgcards"
                    />
                  </div>
                </div>
                {/* third set card */}
                <div className="savings-info-inner ">
                  <div
                    className="savingsaction__vesticard"
                    style={{
                      background: '#F6F8FF',
                      border: '0.5px solid #BAC3FF',
                      width: '100%',
                    }}
                  >
                    <p style={{ color: '#16216B' }}>Maturity Date</p>
                    <p>{moment(savings?.endDate).format('LL')}</p>
                    <img
                      src={calender}
                      alt=""
                      className="savingsaction__vesticard__bgcards"
                    />
                  </div>
                  <div
                    className="savingsaction__vesticard"
                    style={{
                      background: '#F8FEFF',
                      border: '0.5px solid #D1F6FF',
                      width: '100%',
                    }}
                  >
                    <p style={{ color: '#26606E' }}>Days Left</p>
                    <p>{daysLeft}</p>

                    <img
                      src={clock}
                      alt=""
                      className="savingsaction__vesticard__bgcards"
                    />
                  </div>
                </div>
                {/* last card */}
                {(savings?.status === 'active' ||
                  savings?.disbursementStatus === 'default') && (
                  <div className="savingsaction__autosavecard">
                    <div>
                      <p>
                        Turn {autoTopUp === 'default' ? 'off' : 'on'} Auto-save
                      </p>
                      <p>
                        Click on the toggle to turn off autosave for your
                        savings plan
                      </p>
                    </div>
                    <div className="autoswitch">
                      <Switch
                        checked={autoTopUp === 'default'}
                        onChange={handleSwitch}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="savings-btn my-5 d-flex flex-wrap justify-content-between align-items-center">
            <Mysavingstransactions savingsId={savings?.id} />
          </div>
        </div>
      </div>
      <Modal
        cancelButtonProps={{ style: { display: 'none' } }}
        open={modalVisible}
        onCancel={closeModal}
        destroyOnClose
        footer=""
        className="new-modal"
        centered={true}
        okButtonProps={{ style: { display: 'none' } }}
        styles={{
          mask: {
            background: 'rgba(103, 169, 72, 0.2)',
            backdropFilter: 'blur(4px)',
          },
        }}
      >
        {showWithdrawal && (
          <Withdraw close={closeModal} user={userData} savings={savings} />
        )}
        {showTopUp && (
          <TopUp close={closeModal} user={userData} selectedPlan={savings} />
        )}
      </Modal>
    </Layout>
  );
};

export default Savings;
