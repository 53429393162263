import React, { useEffect, useState } from 'react';
import { useStep } from 'helpers/hooks';
import { connect, useDispatch, useSelector } from 'react-redux';
import { fetchAllCountries } from 'appRedux/actions/Common';
import Kyc from './Kyc';
import {
  IconOption,
  IconSingleValue,
  Singleselect,
} from 'components/common/inputs/singleselect';
import { Platformbutton } from 'components/common/button/button';
import './Kyc.scss';
import Kycothers from './kycothers';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Success } from 'components/common/success/success';
import sorry from 'assets/oops.svg';
import { opennReg } from 'appRedux/actions/auth';
import Inputfloat from 'components/common/inputs/inputfloat';
import _ from 'lodash';
const Kycustomer = props => {
  // console.log('Kyc props :', props);
  const { step, setStep } = useStep(0);
  // eslint-disable-next-line
  const [country, setCountry] = useState();
  const dispatch = useDispatch();

  const userData = useSelector(state => state.auth.userData, _.isEqual);

  var __renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <>
            <Titlesubtitle
              title="KYC Verification"
              subtitle="What country do you want to KYC from ?"
            />
            <div className="mb-4"></div>
            {userData?.country ? (
              <SelectcountryPrefill
                country={
                  props.singleProfile.country || props.singleProfile.location
                }
                setCountry={setCountry}
                continue={setStep}
                userData={userData}
              />
            ) : (
              <Selectcountry
                countries={props.countries}
                country={country}
                setCountry={setCountry}
                continue={setStep}
              />
            )}
          </>
        );
      case 1:
        return (
          <Kyc
            state={props.state}
            refetch={props.refetch}
            userdata={props.userdata}
            verifiedKyc={props.userdata.verifiedKyc}
            back={() => setStep(0)}
          />
        );
      case 2:
        return <Kycothers userdata={props.userdata} />;
      case 3:
        return (
          <Success
            // type="error"
            image={sorry}
            title="Sorry :("
            subtitle="You have to update your profile information before you start your KYC verification process."
            button="Update Profile"
            onClick={() => {
              props.refetch();
              dispatch(opennReg(true));
            }}
          />
        );
      default:
        return <>Hello There</>;
    }
  };

  useEffect(() => {
    props.fetchAllCountries();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    props.userdata.lastName === null
      ? setStep(3)
      : props.userdata.stripeVerificationToken &&
        (props.userdata.country === 'Zambia' ||
          props.userdata.country === 'ZAMBIA' ||
          props.userdata.country === 'United Kingdom' ||
          props.userdata.country === 'UK')
      ? setStep(2)
      : ((props.userdata.verifiedKyc === 'DEFAULT' ||
          props.userdata.verifiedKyc === false ||
          props.userdata.stripeVerificationToken ||
          props.userdata.kycLevel === 'Level0') &&
          (props.userdata.country === 'Nigeria' ||
            props.userdata.country === 'NIGERIA')) ||
        ((props.userdata.verifiedKyc === 'DEFAULT' ||
          props.userdata.verifiedKyc === false ||
          props.userdata.stripeVerificationToken ||
          props.userdata.kycLevel === 'Level0') &&
          (props.userdata.country === 'Ghana' ||
            props.userdata.country === 'GHANA'))
      ? setStep(0)
      : props.userdata.kycLevel !== 'Level0' &&
        !props.userdata.stripeVerificationToken
      ? setStep(1)
      : setStep(0);

    // eslint-disable-next-line
  }, [userData]);

  return (
    <section className="kycustomer">
      {!userData.firstName ? <></> : __renderSteps()}
    </section>
  );
};

const SelectcountryPrefill = props => {
  const userData = useSelector(state => state.auth.userData, _.isEqual);
  return (
    <div className="kycustomer__country">
      {/* {props.country && props.country.label} */}

      <span>
        <span>
          <p className="mb-4">What country do you want to KYC from ?</p>
        </span>
        <Inputfloat
          type="text"
          label="Location"
          name="location"
          placeholder="Country"
          value={userData.country || props.location}
          disabled={true}

          // countries={props.countries}

          // setCountry={setCountry}
        />
      </span>

      <Platformbutton
        name="Continue Process"
        type="normal"
        //disabled={props.country ? false : true}
        click={() =>
          userData.country === 'Nigeria' ||
          userData.country === 'Ghana' ||
          userData.country === 'Kenya' ||
          (userData.country !== 'Nigeria' &&
            props.userData.kycLevel === 'Level1') ||
          (userData.country !== 'Kenya' &&
            props.userData.kycLevel === 'Level1') ||
          (userData.country !== 'Ghana' && props.userData.kycLevel === 'Level1')
            ? props.continue(1)
            : props.continue(2)
        }

        // click={() =>
        //   props.userData.kycLevel === 'Level1' ||
        //   props.userData.kycLevel === 'Level2' ||
        //   props.userData.kycLevel === 'Level3' ||
        //   props.country.label !== 'Nigeria' ||
        //   props.country.label !== 'Ghana' ||
        //   props.country.label !== 'Kenya'
        //     ? props.continue(1)
        //     : props.continue(2)
        // }
      />
    </div>
  );
};

const Selectcountry = props => {
  return (
    <div className="kycustomer__country">
      {/* {props.country && props.country.label} */}
      <span>
        <p>What country do you want to KYC from ?</p>
        <Singleselect
          value={props.country}
          options={props.countries}
          onChange={value => props.setCountry(value)}
          placeholder="Select Your Country"
          components={{ SingleValue: IconSingleValue, Option: IconOption }}
        />
      </span>

      <Platformbutton
        name="Continue Process"
        type="normal"
        disabled={props.country ? false : true}
        click={() =>
          props.country.label === 'Nigeria' ||
          props.country.label === 'Ghana' ||
          props.country.label === 'Kenya'
            ? props.continue(1)
            : props.continue(2)
        }
      />
    </div>
  );
};

const mapStateToProps = ({ common, profile }) => {
  const { countries, states } = common;

  const { singleProfile, loading } = profile;

  return {
    countries,
    states,
    singleProfile,
    profile,
    loading,
  };
};

const mapDispatchToProps = {
  fetchAllCountries,
};

export default connect(mapStateToProps, mapDispatchToProps)(Kycustomer);
