import {
  cash,
  merchant,
  airtime,
  profile,
  cards,
  member,
  feed,
  advice,
  pathway,
  cc,
  naira,
  kwacha,
  cedis,
  pesos,
  pounds,
  shillings,
} from 'assets/assets';
import { radio_cardusd, radio_ngnusd } from 'assets/assets';
import regimg from 'assets/regimg/wallet.png';
import regimgc from 'assets/regimg/cardsAuth.png';
import regimgf from 'assets/regimg/regimg-fees.png';
import regimgp from 'assets/regimg/pathwayAuth.png';





import studentloan from 'assets/studentloans/student-loann.svg';
import pof from 'assets/studentloans/pof.svg';
import supportloan from 'assets/studentloans/support-loan.svg';
import creditcard from 'assets/studentloans/credit-card.svg';


import okoro from '../assets/studentloans/okoro.svg';
import ebube from 'assets/studentloans/ebube.svg';
import waheed from 'assets/studentloans/waheed.svg';



export const lgaList = {
  Abia: [
    'Aba North',
    'Aba South',
    'Arochukwu',
    'Bende',
    'Ikwuano',
    'Isiala Ngwa North',
    'Isiala Ngwa South',
    'Isuikwuato',
    'Obi Ngwa',
    'Ohafia',
    'Osisioma',
    'Ugwunagbo',
    'Ukwa East',
    'Ukwa West',
    'Umuahia North',
    'muahia South',
    'Umu Nneochi',
  ],
  Adamawa: [
    'Demsa',
    'Fufure',
    'Ganye',
    'Gayuk',
    'Gombi',
    'Grie',
    'Hong',
    'Jada',
    'Larmurde',
    'Madagali',
    'Maiha',
    'Mayo Belwa',
    'Michika',
    'Mubi North',
    'Mubi South',
    'Numan',
    'Shelleng',
    'Song',
    'Toungo',
    'Yola North',
    'Yola South',
  ],
  AkwaIbom: [
    'Abak',
    'Eastern Obolo',
    'Eket',
    'Esit Eket',
    'Essien Udim',
    'Etim Ekpo',
    'Etinan',
    'Ibeno',
    'Ibesikpo Asutan',
    'Ibiono-Ibom',
    'Ika',
    'Ikono',
    'Ikot Abasi',
    'Ikot Ekpene',
    'Ini',
    'Itu',
    'Mbo',
    'Mkpat-Enin',
    'Nsit-Atai',
    'Nsit-Ibom',
    'Nsit-Ubium',
    'Obot Akara',
    'Okobo',
    'Onna',
    'Oron',
    'Oruk Anam',
    'Udung-Uko',
    'Ukanafun',
    'Uruan',
    'Urue-Offong Oruko',
    'Uyo',
  ],
  Anambra: [
    'Aguata',
    'Anambra East',
    'Anambra West',
    'Anaocha',
    'Awka North',
    'Awka South',
    'Ayamelum',
    'Dunukofia',
    'Ekwusigo',
    'Idemili North',
    'Idemili South',
    'Ihiala',
    'Njikoka',
    'Nnewi North',
    'Nnewi South',
    'Ogbaru',
    'Onitsha North',
    'Onitsha South',
    'Orumba North',
    'Orumba South',
    'Oyi',
  ],
  // eslint-disable-next-line
  Anambra: [
    'Aguata',
    'Anambra East',
    'Anambra West',
    'Anaocha',
    'Awka North',
    'Awka South',
    'Ayamelum',
    'Dunukofia',
    'Ekwusigo',
    'Idemili North',
    'Idemili South',
    'Ihiala',
    'Njikoka',
    'Nnewi North',
    'Nnewi South',
    'Ogbaru',
    'Onitsha North',
    'Onitsha South',
    'Orumba North',
    'Orumba South',
    'Oyi',
  ],
  Bauchi: [
    'Alkaleri',
    'Bauchi',
    'Bogoro',
    'Damban',
    'Darazo',
    'Dass',
    'Gamawa',
    'Ganjuwa',
    'Giade',
    'Itas-Gadau',
    'Jama are',
    'Katagum',
    'Kirfi',
    'Misau',
    'Ningi',
    'Shira',
    'Tafawa Balewa',
    ' Toro',
    ' Warji',
    ' Zaki',
  ],

  Bayelsa: [
    'Brass',
    'Ekeremor',
    'Kolokuma Opokuma',
    'Nembe',
    'Ogbia',
    'Sagbama',
    'Southern Ijaw',
    'Yenagoa',
  ],
  Benue: [
    'Agatu',
    'Apa',
    'Ado',
    'Buruku',
    'Gboko',
    'Guma',
    'Gwer East',
    'Gwer West',
    'Katsina-Ala',
    'Konshisha',
    'Kwande',
    'Logo',
    'Makurdi',
    'Obi',
    'Ogbadibo',
    'Ohimini',
    'Oju',
    'Okpokwu',
    'Oturkpo',
    'Tarka',
    'Ukum',
    'Ushongo',
    'Vandeikya',
  ],
  Borno: [
    'Abadam',
    'Askira-Uba',
    'Bama',
    'Bayo',
    'Biu',
    'Chibok',
    'Damboa',
    'Dikwa',
    'Gubio',
    'Guzamala',
    'Gwoza',
    'Hawul',
    'Jere',
    'Kaga',
    'Kala-Balge',
    'Konduga',
    'Kukawa',
    'Kwaya Kusar',
    'Mafa',
    'Magumeri',
    'Maiduguri',
    'Marte',
    'Mobbar',
    'Monguno',
    'Ngala',
    'Nganzai',
    'Shani',
  ],
  'Cross River': [
    'Abi',
    'Akamkpa',
    'Akpabuyo',
    'Bakassi',
    'Bekwarra',
    'Biase',
    'Boki',
    'Calabar Municipal',
    'Calabar South',
    'Etung',
    'Ikom',
    'Obanliku',
    'Obubra',
    'Obudu',
    'Odukpani',
    'Ogoja',
    'Yakuur',
    'Yala',
  ],

  Delta: [
    'Aniocha North',
    'Aniocha South',
    'Bomadi',
    'Burutu',
    'Ethiope East',
    'Ethiope West',
    'Ika North East',
    'Ika South',
    'Isoko North',
    'Isoko South',
    'Ndokwa East',
    'Ndokwa West',
    'Okpe',
    'Oshimili North',
    'Oshimili South',
    'Patani',
    'Sapele',
    'Udu',
    'Ughelli North',
    'Ughelli South',
    'Ukwuani',
    'Uvwie',
    'Warri North',
    'Warri South',
    'Warri South West',
  ],

  Ebonyi: [
    'Abakaliki',
    'Afikpo North',
    'Afikpo South',
    'Ebonyi',
    'Ezza North',
    'Ezza South',
    'Ikwo',
    'Ishielu',
    'Ivo',
    'Izzi',
    'Ohaozara',
    'Ohaukwu',
    'Onicha',
  ],
  Edo: [
    'Akoko-Edo',
    'Egor',
    'Esan Central',
    'Esan North-East',
    'Esan South-East',
    'Esan West',
    'Etsako Central',
    'Etsako East',
    'Etsako West',
    'Igueben',
    'Ikpoba Okha',
    'Orhionmwon',
    'Oredo',
    'Ovia North-East',
    'Ovia South-West',
    'Owan East',
    'Owan West',
    'Uhunmwonde',
  ],

  Ekiti: [
    'Ado Ekiti',
    'Efon',
    'Ekiti East',
    'Ekiti South-West',
    'Ekiti West',
    'Emure',
    'Gbonyin',
    'Ido Osi',
    'Ijero',
    'Ikere',
    'Ikole',
    'Ilejemeje',
    'Irepodun-Ifelodun',
    'Ise-Orun',
    'Moba',
    'Oye',
  ],
  Rivers: [
    'Port Harcourt',
    'Obio-Akpor',
    'Okrika',
    'Ogu–Bolo',
    'Eleme',
    'Tai',
    'Gokana',
    'Khana',
    'Oyigbo',
    'Opobo–Nkoro',
    'Andoni',
    'Bonny',
    'Degema',
    'Asari-Toru',
    'Akuku-Toru',
    'Abua–Odual',
    'Ahoada West',
    'Ahoada East',
    'Ogba–Egbema–Ndoni',
    'Emohua',
    'Ikwerre',
    'Etche',
    'Omuma',
  ],
  Enugu: [
    'Aninri',
    'Awgu',
    'Enugu East',
    'Enugu North',
    'Enugu South',
    'Ezeagu',
    'Igbo Etiti',
    'Igbo Eze North',
    'Igbo Eze South',
    'Isi Uzo',
    'Nkanu East',
    'Nkanu West',
    'Nsukka',
    'Oji River',
    'Udenu',
    'Udi',
    'Uzo Uwani',
  ],
  'Federal Capital Territory': [
    'Abaji',
    'Bwari',
    'Gwagwalada',
    'Kuje',
    'Kwali',
    'Municipal Area Council',
  ],
  FCT: [
    'Abaji',
    'Bwari',
    'Gwagwalada',
    'Kuje',
    'Kwali',
    'Municipal Area Council',
  ],
  Gombe: [
    'Akko',
    'Balanga',
    'Billiri',
    'Dukku',
    'Funakaye',
    'Gombe',
    'Kaltungo',
    'Kwami',
    'Nafada',
    'Shongom',
    'Yamaltu-Deba',
  ],
  Imo: [
    'Aboh Mbaise',
    'Ahiazu Mbaise',
    'Ehime Mbano',
    'Ezinihitte',
    'Ideato North',
    'Ideato South',
    'Ihitte-Uboma',
    'Ikeduru',
    'Isiala Mbano',
    'Isu',
    'Mbaitoli',
    'Ngor Okpala',
    'Njaba',
    'Nkwerre',
    'Nwangele',
    'Obowo',
    'Oguta',
    'Ohaji-Egbema',
    'Okigwe',
    'Orlu',
    'Orsu',
    'Oru East',
    'Oru West',
    'Owerri Municipal',
    'Owerri North',
    'Owerri West',
    'Unuimo',
  ],
  Jigawa: [
    'Auyo',
    'Babura',
    'Biriniwa',
    'Birnin Kudu',
    'Buji',
    'Dutse',
    'Gagarawa',
    'Garki',
    'Gumel',
    'Guri',
    'Gwaram',
    'Gwiwa',
    'Hadejia',
    'Jahun',
    'Kafin Hausa',
    'Kazaure',
    'Kiri Kasama',
    'Kiyawa',
    'Kaugama',
    'Maigatari',
    'Malam Madori',
    'Miga',
    'Ringim',
    'Roni',
    'Sule Tankarkar',
    'Taura',
    'Yankwashi',
  ],
  Kaduna: [
    'Birnin Gwari',
    'Chikun',
    'Giwa',
    'Igabi',
    'Ikara',
    'Jaba',
    'Jema a',
    'Kachia',
    'Kaduna North',
    'Kaduna South',
    'Kagarko',
    'Kajuru',
    'Kaura',
    'Kauru',
    'Kubau',
    'Kudan',
    'Lere',
    'Makarfi',
    'Sabon Gari',
    'Sanga',
    'Soba',
    'Zangon Kataf',
    'Zaria',
  ],
  Kano: [
    'Ajingi',
    'Albasu',
    'Bagwai',
    'Bebeji',
    'Bichi',
    'Bunkure',
    'Dala',
    'Dambatta',
    'Dawakin Kudu',
    'Dawakin Tofa',
    'Doguwa',
    'Fagge',
    'Gabasawa',
    'Garko',
    'Garun Mallam',
    'Gaya',
    'Gezawa',
    'Gwale',
    'Gwarzo',
    'Kabo',
    'Kano Municipal',
    'Karaye',
    'Kibiya',
    'Kiru',
    'Kumbotso',
    'Kunchi',
    'Kura',
    'Madobi',
    'Makoda',
    'Minjibir',
    'Nasarawa',
    'Rano',
    'Rimin Gado',
    'Rogo',
    'Shanono',
    'Sumaila',
    'Takai',
    'Tarauni',
    'Tofa',
    'Tsanyawa',
    'Tudun Wada',
    'Ungogo',
    'Warawa',
    'Wudil',
  ],
  Katsina: [
    'Bakori',
    'Batagarawa',
    'Batsari',
    'Baure',
    'Bindawa',
    'Charanchi',
    'Dandume',
    'Danja',
    'Dan Musa',
    'Daura',
    'Dutsi',
    'Dutsin Ma',
    'Faskari',
    'Funtua',
    'Ingawa',
    'Jibia',
    'Kafur',
    'Kaita',
    'Kankara',
    'Kankia',
    'Katsina',
    'Kurfi',
    'Kusada',
    'Mai Adua',
    'Malumfashi',
    'Mani',
    'Mashi',
    'Matazu',
    'Musawa',
    'Rimi',
    'Sabuwa',
    'Safana',
    'Sandamu',
    'Zango',
  ],
  Kebbi: [
    'Aleiro',
    'Arewa Dandi',
    'Argungu',
    'Augie',
    'Bagudo',
    'Birnin Kebbi',
    'Bunza',
    'Dandi',
    'Fakai',
    'Gwandu',
    'Jega',
    'Kalgo',
    'Koko Besse',
    'Maiyama',
    'Ngaski',
    'Sakaba',
    'Shanga',
    'Suru',
    'Wasagu Danko',
    'Yauri',
    'Zuru',
  ],
  Kogi: [
    'Adavi',
    'Ajaokuta',
    'Ankpa',
    'Bassa',
    'Dekina',
    'Ibaji',
    'Idah',
    'Igalamela Odolu',
    'Ijumu',
    'Kabba Bunu',
    'Kogi',
    'Lokoja',
    'Mopa Muro',
    'Ofu',
    'Ogori Magongo',
    'Okehi',
    'Okene',
    'Olamaboro',
    'Omala',
    'Yagba East',
    'Yagba West',
  ],
  Kwara: [
    'Asa',
    'Baruten',
    'Edu',
    'Ekiti',
    'Ifelodun',
    'Ilorin East',
    'Ilorin South',
    'Ilorin West',
    'Irepodun',
    'Isin',
    'Kaiama',
    'Moro',
    'Offa',
    'Oke Ero',
    'Oyun',
    'Pategi',
  ],
  Lagos: [
    'Agege',
    'Ajeromi-Ifelodun',
    'Alimosho',
    'Amuwo-Odofin',
    'Apapa',
    'Badagry',
    'Epe',
    'Eti Osa',
    'Ibeju-Lekki',
    'Ifako-Ijaiye',
    'Ikeja',
    'Ikorodu',
    'Kosofe',
    'Lagos Island',
    'Lagos Mainland',
    'Mushin',
    'Ojo',
    'Oshodi-Isolo',
    'Shomolu',
    'Surulere',
  ],
  Nasarawa: [
    'Akwanga',
    'Awe',
    'Doma',
    'Karu',
    'Keana',
    'Keffi',
    'Kokona',
    'Lafia',
    'Nasarawa',
    'Nasarawa Egon',
    'Obi',
    'Toto',
    'Wamba',
  ],
  Niger: [
    'Agaie',
    'Agwara',
    'Bida',
    'Borgu',
    'Bosso',
    'Chanchaga',
    'Edati',
    'Gbako',
    'Gurara',
    'Katcha',
    'Kontagora',
    'Lapai',
    'Lavun',
    'Magama',
    'Mariga',
    'Mashegu',
    'Mokwa',
    'Moya',
    'Paikoro',
    'Rafi',
    'Rijau',
    'Shiroro',
    'Suleja',
    'Tafa',
    'Wushishi',
  ],
  Ogun: [
    'Abeokuta North',
    'Abeokuta South',
    'Ado-Odo Ota',
    'Egbado North',
    'Egbado South',
    'Ewekoro',
    'Ifo',
    'Ijebu East',
    'Ijebu North',
    'Ijebu North East',
    'Ijebu Ode',
    'Ikenne',
    'Imeko Afon',
    'Ipokia',
    'Obafemi Owode',
    'Odeda',
    'Odogbolu',
    'Ogun Waterside',
    'Remo North',
    'Shagamu',
  ],
  Ondo: [
    'Akoko North-East',
    'Akoko North-West',
    'Akoko South-West',
    'Akoko South-East',
    'Akure North',
    'Akure South',
    'Ese Odo',
    'Idanre',
    'Ifedore',
    'Ilaje',
    'Ile Oluji-Okeigbo',
    'Irele',
    'Odigbo',
    'Okitipupa',
    'Ondo East',
    'Ondo West',
    'Ose',
    'Owo',
  ],
  Osun: [
    'Atakunmosa East',
    'Atakunmosa West',
    'Aiyedaade',
    'Aiyedire',
    'Boluwaduro',
    'Boripe',
    'Ede North',
    'Ede South',
    'Ife Central',
    'Ife East',
    'Ife North',
    'Ife South',
    'Egbedore',
    'Ejigbo',
    'Ifedayo',
    'Ifelodun',
    'Ila',
    'Ilesa East',
    'Ilesa West',
    'Irepodun',
    'Irewole',
    'Isokan',
    'Iwo',
    'Obokun',
    'Odo Otin',
    'Ola Oluwa',
    'Olorunda',
    'Oriade',
    'Orolu',
    'Osogbo',
  ],
  Oyo: [
    'Afijio',
    'Akinyele',
    'Atiba',
    'Atisbo',
    'Egbeda',
    'Ibadan North',
    'Ibadan North-East',
    'Ibadan North-West',
    'Ibadan South-East',
    'Ibadan South-West',
    'Ibarapa Central',
    'Ibarapa East',
    'Ibarapa North',
    'Ido',
    'Irepo',
    'Iseyin',
    'Itesiwaju',
    'Iwajowa',
    'Kajola',
    'Lagelu',
    'Ogbomosho North',
    'Ogbomosho South',
    'Ogo Oluwa',
    'Olorunsogo',
    'Oluyole',
    'Ona Ara',
    'Orelope',
    'Ori Ire',
    'Oyo',
    'Oyo East',
    'Saki East',
    'Saki West',
    'Surulere',
  ],
  Plateau: [
    'Bokkos',
    'Barkin Ladi',
    'Bassa',
    'Jos East',
    'Jos North',
    'Jos South',
    'Kanam',
    'Kanke',
    'Langtang South',
    'Langtang North',
    'Mangu',
    'Mikang',
    'Pankshin',
    'Qua an Pan',
    'Riyom',
    'Shendam',
    'Wase',
  ],
  Sokoto: [
    'Binji',
    'Bodinga',
    'Dange Shuni',
    'Gada',
    'Goronyo',
    'Gudu',
    'Gwadabawa',
    'Illela',
    'Isa',
    'Kebbe',
    'Kware',
    'Rabah',
    'Sabon Birni',
    'Shagari',
    'Silame',
    'Sokoto North',
    'Sokoto South',
    'Tambuwal',
    'Tangaza',
    'Tureta',
    'Wamako',
    'Wurno',
    'Yabo',
  ],
  Taraba: [
    'Ardo Kola',
    'Bali',
    'Donga',
    'Gashaka',
    'Gassol',
    'Ibi',
    'Jalingo',
    'Karim Lamido',
    'Kumi',
    'Lau',
    'Sardauna',
    'Takum',
    'Ussa',
    'Wukari',
    'Yorro',
    'Zing',
  ],
  Yobe: [
    'Bade',
    'Bursari',
    'Damaturu',
    'Fika',
    'Fune',
    'Geidam',
    'Gujba',
    'Gulani',
    'Jakusko',
    'Karasuwa',
    'Machina',
    'Nangere',
    'Nguru',
    'Potiskum',
    'Tarmuwa',
    'Yunusari',
    'Yusufari',
  ],
  Zamfara: [
    'Anka',
    'Bakura',
    'Birnin Magaji Kiyaw',
    'Bukkuyum',
    'Bungudu',
    'Gummi',
    'Gusau',
    'Kaura Namoda',
    'Maradun',
    'Maru',
    'Shinkafi',
    'Talata Mafara',
    'Chafe',
    'Zurmi',
  ],
};

export const accountType = [
  {
    value: 'checking',
    label: 'checking',
  },
  {
    value: 'savings',
    label: 'savings',
  },
];

export const holderType = [
  {
    value: 'individual',
    label: 'individual',
  },
  {
    value: 'business',
    label: 'business',
  },
];

export const usdAccountCodes = [
  {
    value: 'ROUTING_NUMBER',
    title: 'ACH transfer',
    subtitle: 'ACH transfer to a domestic USA Financial Account.',
  },
  {
    value: 'SAME_DAY_ROUTING_NUMBER',
    title: 'Same Day wire',
    subtitle: 'Same day wire to a domestic USA Financial Account.',
  },
  // {
  //   value: 'BIC',
  //   title: 'An International account (BIC Code)',
  //   subtitle: 'No, it’s not an account in the US',
  // },
  // {
  //   value: 'SWIFT',
  //   title: 'An International account (SWIFT Code)',
  //   subtitle: 'No, it’s not an account in the US',
  // },
];

export const usdAccountDeposit = [
  // {
  //     value:'ACCOUNT',
  //     title:'Add money into an account',
  //     subtitle:'Add money by making a bank transfer into an account.'
  // },
  {
    image: radio_cardusd,
    value: 'CARD',
    title: 'Add money using Card',
    subtitle: 'Add money using either your credit or debit card.',
  },
  {
    image: radio_ngnusd,
    value: 'NAIRA',
    title: 'Add money using Naira wallet',
    subtitle: 'Add money using your Vesti naira wallet',
  },
  {
    image: radio_ngnusd,
    value: 'CEDIS',
    title: 'Add money using Cedis wallet',
    subtitle: 'Add money using your Vesti cedis wallet',
  },
];

export const Rewards = [
  'NGN 100  for each signUp',
  'NGN 300 for referred user who creates virtual card',
  'NGN 500 for referred user that successfully  payed for a service',
  '$10 :  USA based customer who created new Vesti account ( $10 added to USD wallet )',
  // `$50 :  USA based customer who created stripe financial account in their founder's  account ( $50 added to Financial Account )`
];

export const authWords = [
  {
    image: regimg,
    title: 'Wallets',
    subtitle:
      'Vesti money is the neobank for immigrants moving to the US/UK/EU.',
  },
  {
    image: regimgc,
    title: 'Cards',
    subtitle:
      'Spend safely online, in-store and abroad choosing from a variety of our cards.',
  },
  {
    image: regimgp,
    title: 'Pathways to move abroad',
    subtitle:
      'Find the ideal pathway and get tailored assistance to migrate to country of your choice.',
  },
  // {
  //   image: regimgf,
  //   title: 'Migration Fees',
  //   subtitle:
  //     'We offer comprehensive support to help you navigate and pay for fees such as WES, SEVIS and other immigration-related fees.',
  // },
];

export const shuttleProcess = [
  `Visit the Shuttlers Web app <a rel='noopener noreferrer' target='_blank' href="https://www.shuttlers.ng"> www.shuttlers.ng </a>  or download the Shuttlers app on 
    <a rel='noopener noreferrer' target='_blank' href="https://play.google.com/store/apps/details?id=com.shuttlers.android&hl=en&gl=US&pli=1"> Google play store </a>   or 
    <a rel='noopener noreferrer' target='_blank' href="https://apps.apple.com/ng/app/shuttlers/id1532662341">IOS</a>. `,
  `Fill your desired routes either through <strong>one-way</strong> or <strong>return trip</strong>  and select your preferred date.`,
  `Click on the <strong>Proceed to payment</strong> button to book your ticket and apply the 50% discount code from Vesti on your first Shuttle.`,
];

export const sidebarMenus = [
  { image: cash, name: 'Bank', route: '/bank', tip: 'My Wallet' },
  {
    image: pathway,
    name: 'Pathways',
    route: '/pathways',
    tip: 'Pathways',
  },
  { image: cards, name: 'Cards', route: '/cards', tip: 'My Cards' },
  {
    image: merchant,
    name: 'Migration Fees',
    route: '/merchants',
    tip: 'Migration Fees',
  },
  // {
  //   image: cc,
  //   name: 'Credit Card',
  //   route: '/credithistory',
  //   tip: 'Credit Card',
  // },
  // {
  //   image: airtime,
  //   name: 'Bills Payment',
  //   route: '/bills-payment',
  //   tip: 'My Wallet',
  // },

  { image: feed, name: 'Feeds', route: '/feeds', tip: 'Feeds' },

  // {
  //   image: member,
  //   name: 'Member',
  //   route: '/webinar',
  //   tip: 'My Memberships & Webinar',
  // },
];

export const sidebarAccountMenus = [
  // { image: feed, name: 'Feeds', route: '/feeds', tip: 'Feeds' },
  // {
  //   image: advice,
  //   name: 'Advice',
  //   route: '/advice',
  //   tip: 'Immigration Advice',
  // },
  {
    image: profile,
    name: 'My Profile',
    route: '/myprofile',
    tip: 'My Profile',
  },
];

export const cardColors = ['Black', 'Yellow', 'Green', 'Purple', 'Blue'];
export const cardCurrencies = ['NGN', 'GHS', 'KES', 'ZMW', 'GBP'];

// currencies

export const currencies = [
  {
    name: 'naira',
    currency: 'ngn',
    icon: naira,
  },
  {
    name: 'cedis',
    currency: 'ghs',
    icon: cedis,
  },
  {
    name: 'kwacha',
    currency: 'zmw',
    icon: kwacha,
  },
  {
    name: 'pesos',
    currency: 'pes',
    icon: pesos,
  },
  {
    name: 'pounds',
    currency: 'gbp',
    icon: pounds,
  },
  {
    name: 'kenya shillings',
    currency: 'kes',
    icon: shillings,
  },
  {
    name: 'Indian Rupees (coming soon)',
    currency: 'inr',
    icon:
      'https://res.cloudinary.com/wevesti/image/upload/v1724943396/verificationDocument/r5udgaytyhxtd9nhg1wm.png',
  },
  {
    name: 'Pakistani Rupees (coming soon)',
    currency: 'pkr',
    icon:
      'https://res.cloudinary.com/wevesti/image/upload/v1724943255/verificationDocument/yk2waypoo0altwcg0npc.png',
  },
  {
    name: 'Bangladeshi Taka (coming soon)',
    currency: 'bdt',
    icon:
      'https://res.cloudinary.com/wevesti/image/upload/v1724943459/verificationDocument/uj8oowjapbq8exd3kc6r.png',
  },
];

export const supportedWallets = [
  'ngn',
  'ghc',
  'ghs',
  'zmw',
  'kwacha',
  'busd',
  'usd',
  'gbp',
  'kes',
  'kenya shillings',
  'inr',
  'pkr',
  'bdt',
];

export const NigerianStates = [
  {
    value: 'Abia',
    label: 'Abia',
  },
  {
    value: 'Abuja',
    label: 'Abuja',
  },
  {
    value: 'Adamawa',
    label: 'Adamawa',
  },
  {
    value: 'Akwa Ibom',
    label: 'Akwa Ibom',
  },
  {
    value: 'Anambra',
    label: 'Anambra',
  },
  {
    value: 'Bauchi',
    label: 'Bauchi',
  },
  {
    value: 'Bayelsa',
    label: 'Bayelsa',
  },
  {
    value: 'Benue',
    label: 'Benue',
  },
  {
    value: 'Borno',
    label: 'Borno',
  },
  {
    value: 'Cross River',
    label: 'Cross River',
  },
  {
    value: 'Delta',
    label: 'Delta',
  },
  {
    value: 'Ebonyi',
    label: 'Ebonyi',
  },
  {
    value: 'Edo',
    label: 'Edo',
  },
  {
    value: 'Ekiti',
    label: 'Ekiti',
  },
  {
    value: 'Enugu',
    label: 'Enugu',
  },
  {
    value: 'Gombe',
    label: 'Gombe',
  },
  {
    value: 'Imo',
    label: 'Imo',
  },
  {
    value: 'Jigawa',
    label: 'Jigawa',
  },
  {
    value: 'Kaduna',
    label: 'Kaduna',
  },
  {
    value: 'Kano',
    label: 'Kano',
  },
  {
    value: 'Katsina',
    label: 'Katsina',
  },
  {
    value: 'Kebbi',
    label: 'Kebbi',
  },
  {
    value: 'Kogi',
    label: 'Kogi',
  },
  {
    value: 'Kwara',
    label: 'Kwara',
  },
  {
    value: 'Lagos',
    label: 'Lagos',
  },
  {
    value: 'Nassarawa',
    label: 'Nassarawa',
  },
  {
    value: 'Niger',
    label: 'Niger',
  },
  {
    value: 'Ogun',
    label: 'Ogun',
  },
  {
    value: 'Ondo',
    label: 'Ondo',
  },
  {
    value: 'Osun',
    label: 'Osun',
  },
  {
    value: 'Oyo',
    label: 'Oyo',
  },
  {
    value: 'Plateau',
    label: 'Plateau',
  },
  {
    value: 'Rivers',
    label: 'Rivers',
  },
  {
    value: 'Sokoto',
    label: 'Sokoto',
  },
  {
    value: 'Taraba',
    label: 'Taraba',
  },
  {
    value: 'Yobe',
    label: 'Yobe',
  },
  {
    value: 'Zamfara',
    label: 'Zamfara',
  },
];




export const LoanData = [
  {
    id: '1',
    imgIcon: studentloan,
    title: 'International Student Loan',
    description: 'Finance your studies',
    imgBgColor: '#F1F3FF',
    link: '/loandetails',
  },
  {
    id: '2',
    imgIcon: pof,
    title: 'Proof of Funds',
    description: 'Show financial capability',
    imgBgColor: '#FBF3FF',
    link: '/proofoffunds',
  },
  {
    id: '3',
    imgIcon: supportloan,
    title: 'Migration Support Loan',
    description: 'Fund your move',
    imgBgColor: '#F0F9FF',
    link: '/supportloan',
  },
  {
    id: '4',
    imgIcon: creditcard,
    title: 'Credit Card',
    description: 'Get your card',
    imgBgColor: '#FFFAEB',
  },
 ];
 
 
 export const CustomerReviewsData = [
  {
    id: '1',
    imgIcon: okoro,
    title: 'Fidelis Okoro',
    time: '1 week ago',
    desc:
      ' Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.',
  },
  {
    id: '2',
    imgIcon: ebube,
    title: 'Ebube Duke',
    time: '2 week ago',
    desc:
      'Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.',
  },
  {
    id: '3',
    imgIcon: waheed,
    title: 'Waheed Olalekan',
    time: '1 month',
    desc:
      ' Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.',
  },
 ];
 
 
 export const sectionsTerms = [
  {
    desc:
      'Vesti is committed to providing financial support to individuals seeking to migrate or further their education abroad. By using our loan services, you agree to abide by the following terms and conditions.',
  },
  {
    desc:
      'Vesti’s International Student Loan is designed to support students pursuing education at one of Vesti’s supported schools. This loan covers tuition fees, living expenses, and health insurance, ensuring that students can focus on their studies without financial stress.',
  },
  {
    title:
      'To be eligible for the International Student Loan, applicants must provide:',
    desc:
      'a. Proof of identity i.e., official documents that establish the individual’s identity.',
    desc1:
      'b. Documentation that confirms the individual’s address or place of residence.',
    desc2: 'c. Admission letter from a Vesti-supported school.',
  },
  {
    desc:
      'Tuition fees will be paid directly to the educational institution, while funds for living expenses and health insurance will be disbursed to the borrower according to an agreed-upon schedule. This disbursement method ensures that the funds are used for their intended purposes and that the borrower receives the necessary support throughout their studies',
  },
  {
    desc:
      "Repayment begins one year after the completion of the borrower's study program, allowing them time to secure employement and establish financial stability. The loan must be repaid within a maximum of 10years from the start of the repayment period, providing borrowers with a manageable timeline to fulfill thier financial obligation. Early repayment is permitted without any penalties, giving borrowers the flexibility to pay off their loan sooner if they are able",
  },
  {
    desc:
      'A late peyment fee of 2% of the overdue amount will be charged for each month  payment is missed. Consistent late payment may result in the borrower being reported to credit bureanus, negatively impacting thier credit score',
  },
  {
    desc: `For any queries or support, please contact us at <span style="color: green;">migrationloans@wevesti.com</span>`,
  },
 ];
 export const sectionDetails = [
  {
    title: 'Loan Type',
    desc: 'Study Loan',
  },
  {
    title: 'Loan Covers',
    desc: 'Tuition, living expenses, and health insurance',
  },
  {
    title: 'Loan Value',
    desc: '$2,000 - $100,000',
  },
  {
    title: 'Available Countries',
    desc: 'United States and Canada',
  },
  {
    title: 'Study Period',
    desc: '2 years',
  },
  {
    title: 'Interest Rate',
    desc: '12% - 14% per annum',
  },
  {
    title: 'Supported Programs',
    desc: 'Master in STEM-related courses and MBA',
  },
  {
    title: 'Moratorium',
    desc: '1 year fter completing the study program',
  },
  {
    title: 'Payback Period',
    desc: 'Up to 10 years',
  },
  {
    title: 'Collateral/Co-signer',
    desc: 'N/A',
  },
  {
    title: 'Support Schools',
    desc: 'Over 400 schools',
  },
 ];
 
 
 export const proofoffundsTerms = [
  {
    desc:
      'Vesti is committed to providing financial support to individuals seeking to migrate or further their education abroad. By using our loan services, you agree to abide by the following terms and conditions.',
  },
  {
    desc:
      'Vesti’s Proof of Funds (POF) Loan provides borrowers with funds to demonstrate financial capacity for visa applications or other migration purposes. This loan is designed to help individuals meet the financial requirements necessary for their migration journey.',
  },
  {
    title:
      'To be eligible for the International Student Loan, applicants must provide:',
    desc:
      'a. Proof of identity i.e., official documents that establish the individual’s identity.',
    desc1:
      'b. Documentation that confirms the individual’s address or place of residence.',
    desc2: 'c. Completed POF application.',
  },
  {
    desc:
      'The approved loan amount will be disbursed into the borrowers bank account within 24-48 hours of loan approval. The borrower will have restricted access to these funds to ensure they are used for the intended purpose.',
  },
  {
    desc:
      'Borrowers must pay an upfronts interest rate of 3.5% for new account and 6% for existing accounts. The loan terms is 30days, renewable upon request before expirations. if not renewed, the POF service will end, and the funds will be withdrawn from the borrower`s account',
  },
  {
    desc: `For any queries or support, please contact us at <span style="color: green;">migrationloans@wevesti.com</span>`,
  },
 ];
 
 
 
 
 export const proofoffundsDetails = [
  {
    title: 'Loan Type',
    desc: 'Proof of Funds',
  },
  {
    title: 'Loan Covers',
    desc: 'Migration expenses',
  },
  {
    title: 'Loan Value',
    desc: 'Required amount',
  },
  {
    title: 'Available Countries',
    desc: 'Nigeria and Ghana',
  },
  {
    title: 'Disbursement Bank',
    desc: `<span style="color: green;">Nigeria:</span> All commercial and micro-finance bank`,
    desc1: `<span style="color: red;">Ghana:</span> UBA`,
  },
  {
    title: 'Interest Rate',
    desc:
      `<span style="color: #67A949;">3.5%</span> for new bank accounts opened through vesti and <span style="color: #67A949;">6%</span> for your existing bank account`,
  },
  {
    title: 'Collateral/Co-signer',
    desc: 'N/A',
  },
  {
    title: 'Payback Period',
    desc: 'Upfront Interest payment made between oan disbursement',
  },
  {
    title: 'Loan Duration',
    desc: '30 days (renewable)',
  },
 ];
 
 
 export const supportloanDetails = [
  {
    title: 'Loan Type',
    desc: 'Migration Support Loan',
  },
  {
    title: 'Loan Covers',
    desc: 'Migration expenses',
  },
  {
    title: 'Loan Value',
    desc: '₦20,000,000 maximum',
  },
  {
    title: 'Available Countries',
    desc: 'Nigeria',
  },
  {
    title: 'Interest Rate',
    desc:
      '8% monthly interest on a reducing balance method and a one-off 3% management fee',
  },
  {
    title: 'Acceptable Collateral',
    desc:
      'Building with government-issued C of O, car (model not later than 10 years)',
  },
 ];
 export const supportloanTerms = [
  {
    desc:
      'Vesti is committed to providing financial support to individuals seeking to migrate or further their education abroad. By using our loan services, you agree to abide by the following terms and conditions.',
  },
  {
    desc:
      'Vesti’s Migration Support Loan is intended to assist individuals with expenses related to the migration process, such as visa fees, travel costs, and initial settlement expenses. This loan aims to alleviate the financial burden associated with migrating to a new country.',
  },
  {
    title:
      'To be eligible for the Migration Support Loan, applicants must provide:',
    desc:
      'a. Proof of identity i.e., official documents that establish the individual’s identity.',
    desc1:
      'b. Documentation that confirms the individual’s address or place of residence.',
    desc2:
      'c. Collateral documents (such as a Certificate of Occupancy or car documents).',
    desc3: 'd. A signed guarantor form.',
    desc4: 'e. Completed Migration Support Loan application form.',
  },
  {
    desc:
      'The loan amount, up to a maximum of ₦20,000,000, will be disbursed after loan approval',
  },
 ];



 export const SchoolsData = [
  {
    id: 1,
    name: "A. T. Still University of Health Sciences",
    address: "Missouri",
    country: "United States"
  },
  {
    id: 2,
    name: "Adelphi University",
    address: "New York",
    country: "United States"
  },
  {
    id: 3,
    name: "Albany College of Pharmacy and Health Sciences",
    address: "New York",
    country: "United States"
  },
  {
    id: 4,
    name: "Albany Medical College",
    address: "North Carolina",
    country: "United States"
  },
  {
    id: 5,
    name: "Allen College",
    address: "Iowa",
    country: "United States"
  },
  {
    id: 6,
    name: "American University",
    address: "DC",
    country: "United States"
  },
  {
    id: 7,
    name: "American University of Antigua (AUA) College of Medicine",
    address: "New York",
    country: "United States"
  },
  {
    id: 8,
    name: "Amherst College",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 9,
    name: "Appalachian State University",
    address: "North Carolina",
    country: "United States"
  },
  {
    id: 10,
    name: "Arcadia University",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 11,
    name: "Arizona State University",
    address: "Arizona",
    country: "United States"
  },
  {
    id: 12,
    name: "Arizona State University- Downtown Phoenix",
    address: "Arizona",
    country: "United States"
  },
  {
    id: 13,
    name: "Arizona State University- Polytechnic",
    address: "Arizona",
    country: "United States"
  },
  {
    id: 14,
    name: "Arizona State University- Skysong",
    address: "Arizona",
    country: "United States"
  },
  {
    id: 15,
    name: "Arizona State University- West",
    address: "Arizona",
    country: "United States"
  },
  {
    id: 16,
    name: "Auburn University",
    address: "Alabama",
    country: "United States"
  },
  {
    id: 17,
    name: "Babson College",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 18,
    name: "Ball State University",
    address: "Indiana",
    country: "United States"
  },
  {
    id: 19,
    name: "Barnard College",
    address: "New York",
    country: "United States"
  },
  {
    id: 20,
    name: "Baruch College of the City University of New York",
    address: "New York",
    country: "United States"
  },
  {
    id: 21,
    name: "Bates College",
    address: "Maine",
    country: "United States"
  },
  {
    id: 22,
    name: "Baylor College of Medicine",
    address: "Texas",
    country: "United States"
  },
  {
    id: 23,
    name: "Baylor University",
    address: "Texas",
    country: "United States"
  },
  {
    id: 24,
    name: "Bellin College",
    address: "Wisconsin",
    country: "United States"
  },
  {
    id: 25,
    name: "Belmont University",
    address: "Tennessee",
    country: "United States"
  },
  {
    id: 26,
    name: "Bentley University",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 27,
    name: "Berry College",
    address: "Georgia",
    country: "United States"
  },
  {
    id: 28,
    name: "Biola University",
    address: "California",
    country: "United States"
  },
  {
    id: 29,
    name: "Bon Secours Memorial College of Nursing",
    address: "Virginia",
    country: "United States"
  },
  {
    id: 30,
    name: "Boston College",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 31,
    name: "Boston University",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 32,
    name: "Bowdoin College",
    address: "Maine",
    country: "United States"
  },
  {
    id: 33,
    name: "Bradley University",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 34,
    name: "Brandeis University",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 35,
    name: "Brigham Young University – Provo | BYU",
    address: "Utah",
    country: "United States"
  },
  {
    id: 36,
    name: "Brock University",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 37,
    name: "Brown University",
    address: "Rhode Island",
    country: "United States"
  },
  {
    id: 38,
    name: "Bryan College of Health Sciences",
    address: "Nebraska",
    country: "United States"
  },
  {
    id: 39,
    name: "Bryant University",
    address: "Rhode Island",
    country: "United States"
  },
  {
    id: 40,
    name: "Bryn Mawr College",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 41,
    name: "Bucknell University",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 42,
    name: "Butler University",
    address: "Indiana",
    country: "United States"
  },
  {
    id: 43,
    name: "CUNY City College",
    address: "New York",
    country: "United States"
  },
  {
    id: 44,
    name: "California Institute of Technology",
    address: "California",
    country: "United States"
  },
  {
    id: 45,
    name: "California Polytechnic State University-San Luis Obispo - Cal Poly",
    address: "California",
    country: "United States"
  },
  {
    id: 46,
    name: "California State University - Fullerton",
    address: "California",
    country: "United States"
  },
  {
    id: 47,
    name: "California State University - Long Beach",
    address: "California",
    country: "United States"
  },
  {
    id: 48,
    name: "California State University - Los Angeles",
    address: "California",
    country: "United States"
  },
  {
    id: 49,
    name: "California State University Maritime Academy",
    address: "California",
    country: "United States"
  },
  {
    id: 50,
    name: "California State University-Northridge",
    address: "California",
    country: "United States"
  },
  {
    id: 51,
    name: "California State University-San Bernardino",
    address: "California",
    country: "United States"
  },
  {
    id: 52,
    name: "Calvin College",
    address: "Michigan",
    country: "United States"
  },
  {
    id: 53,
    name: "Carleton College",
    address: "Minnesota",
    country: "United States"
  },
  {
    id: 54,
    name: "Carleton University",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 55,
    name: "Carnegie Mellon University",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 56,
    name: "Case Western Reserve University",
    address: "Ohio",
    country: "United States"
  },
  {
    id: 57,
    name: "Catholic University of America",
    address: "DC",
    country: "United States"
  },
  {
    id: 58,
    name: "Centra College of Nursing",
    address: "Colorado",
    country: "United States"
  },
  {
    id: 59,
    name: "Central Michigan University",
    address: "Michigan",
    country: "United States"
  },
  {
    id: 60,
    name: "Centre College",
    address: "Kentucky",
    country: "United States"
  },
  {
    id: 61,
    name: "Chapman University",
    address: "California",
    country: "United States"
  },
  {
    id: 62,
    name: "Chatham University",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 63,
    name: "Claremont Graduate University",
    address: "California",
    country: "United States"
  },
  {
    id: 64,
    name: "Claremont McKenna College",
    address: "California",
    country: "United States"
  },
  {
    id: 65,
    name: "Clark University",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 66,
    name: "Clarkson College",
    address: "Nebraska",
    country: "United States"
  },
  {
    id: 67,
    name: "Clarkson University",
    address: "New York",
    country: "United States"
  },
  {
    id: 68,
    name: "Clemson University",
    address: "South Carolina",
    country: "United States"
  },
  {
    id: 69,
    name: "Coastal Carolina University",
    address: "South Carolina",
    country: "United States"
  },
  {
    id: 70,
    name: "Colby College",
    address: "Maine",
    country: "United States"
  },
  {
    id: 71,
    name: "Colgate University",
    address: "New York",
    country: "United States"
  },
  {
    id: 72,
    name: "College for Creative Studies",
    address: "Michigan",
    country: "United States"
  },
  {
    id: 73,
    name: "College of Charleston",
    address: "South Carolina",
    country: "United States"
  },
  {
    id: 74,
    name: "College of Saint Benedict",
    address: "Minnesota",
    country: "United States"
  },
  {
    id: 75,
    name: "College of Saint Scholastica",
    address: "Minnesota",
    country: "United States"
  },
  {
    id: 76,
    name: "College of the Holy Cross",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 77,
    name: "Colorado College",
    address: "Colorado",
    country: "United States"
  },
  {
    id: 78,
    name: "Colorado School of Mines",
    address: "Colorado",
    country: "United States"
  },
  {
    id: 79,
    name: "Colorado State University",
    address: "Colorado",
    country: "United States"
  },
  {
    id: 80,
    name: "Columbia College Chicago",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 81,
    name: "Columbia University",
    address: "New York",
    country: "United States"
  },
  {
    id: 82,
    name: "Concordia University",
    address: "Québec",
    country: "Canada"
  },
  {
    id: 83,
    name: "Connecticut College",
    address: "Connecticut",
    country: "United States"
  },
  {
    id: 84,
    name: "Cornell University",
    address: "New York",
    country: "United States"
  },
  {
    id: 85,
    name: "Creighton University",
    address: "Nebraska",
    country: "United States"
  },
  {
    id: 86,
    name: "D’Youville College",
    address: "New York",
    country: "United States"
  },
  {
    id: 87,
    name: "Daemen College",
    address: "New York",
    country: "United States"
  },
  {
    id: 88,
    name: "Dartmouth College",
    address: "New Hampshire",
    country: "United States"
  },
  {
    id: 89,
    name: "Davidson College",
    address: "North Carolina",
    country: "United States"
  },
  {
    id: 90,
    name: "DePaul University",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 91,
    name: "DePauw University",
    address: "Indiana",
    country: "United States"
  },
  {
    id: 92,
    name: "Des Moines University-Osteopathic Medical Center",
    address: "Iowa",
    country: "United States"
  },
  {
    id: 93,
    name: "Dominican University",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 94,
    name: "Dominican University of California",
    address: "California",
    country: "United States"
  },
  {
    id: 95,
    name: "Drew University",
    address: "New Jersey",
    country: "United States"
  },
  {
    id: 96,
    name: "Drexel University",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 97,
    name: "Duke University",
    address: "North Carolina",
    country: "United States"
  },
  {
    id: 98,
    name: "Duquesne University",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 99,
    name: "East Carolina University",
    address: "North Carolina",
    country: "United States"
  },
  {
    id: 100,
    name: "East Tennessee State University",
    address: "Tennessee",
    country: "United States"
  },
  
  {
    id: 101,
    name: "Emerson College",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 102,
    name: "Emory University",
    address: "Georgia",
    country: "United States"
  },
  {
    id: 103,
    name: "Fairfield University",
    address: "Connecticut",
    country: "United States"
  },
  {
    id: 104,
    name: "Florida Atlantic University",
    address: "Florida",
    country: "United States"
  },
  {
    id: 105,
    name: "Florida International University",
    address: "Florida",
    country: "United States"
  },
  {
    id: 106,
    name: "Florida State University - FSU",
    address: "Florida",
    country: "United States"
  },
  {
    id: 107,
    name: "Fordham University",
    address: "New York",
    country: "United States"
  },
  {
    id: 108,
    name: "Franciscan Missionaries of Our Lady University",
    address: "Louisiana",
    country: "United States"
  },
  {
    id: 109,
    name: "Franklin and Marshall College",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 110,
    name: "Frontier Nursing University",
    address: "Kentucky",
    country: "United States"
  },
  {
    id: 111,
    name: "Furman",
    address: "South Carolina",
    country: "United States"
  },
  {
    id: 112,
    name: "Gannon University",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 113,
    name: "George Mason University",
    address: "Virginia",
    country: "United States"
  },
  {
    id: 114,
    name: "George Washington University - GWU",
    address: "DC",
    country: "United States"
  },
  {
    id: 115,
    name: "Georgetown University",
    address: "DC",
    country: "United States"
  },
  {
    id: 116,
    name: "Georgia Institute of Technology - Georgia Tech",
    address: "Georgia",
    country: "United States"
  },
  {
    id: 117,
    name: "Gettysburg College",
    address: "Pennsylvania",
    country: "United States"
  },
  {
  i: 118,
  nam: "Goldfarb School of Nursing at Barnes-Jewish College",
  addres: "Missouri",
  countr: "United States"
  },
  {
    id: 119,
    name: "Gonzaga University",
    address: "Washington",
    country: "United States"
  },
  {
    id: 120,
    name: "Grand Valley State University",
    address: "Michigan",
    country: "United States"
  },
  {
    id: 121,
    name: "Grinnell College",
    address: "Iowa",
    country: "United States"
  },
  {
    id: 122,
    name: "Hamilton College",
    address: "New York",
    country: "United States"
  },
  {
    id: 123,
    name: "Harvard University",
    address: "Massachusetts",
    country: "United States"
  },
  {
  id: 124,
  name: "Harvey Mudd College",
  address: "California",
  country: "United States"
  },
  {
    id: 125,
    name: "Haverford College",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 126,
    name: "High Point University",
    address: "North Carolina",
    country: "United States"
  },
  {
    id: 127,
    name: "Hofstra University",
    address: "New York",
    country: "United States"
  },
  {
    id: 128,
    name: "Howard University",
    address: "DC",
    country: "United States"
  },
  {
    id: 129,
    name: "Hult International Business School",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 130,
    name: "Hunter College of the City - University of New York",
    address: "New York",
    country: "United States"
  },
  {
    id: 131,
    name: "INSEAD - France",
    address: "Paris",
    country: "France"
  },
  {
    id: 132,
    name: "INSEAD - Singapore",
    address: "1 Ayer Rajah Ave",
    country: "Singapore"
  },
  {
    id: 133,
    name: "Illinois Institute of Technology",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 134,
    name: "Illinois State University",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 135,
    name: "Indiana University - Bloomington",
    address: "Indiana",
    country: "United States"
  },
  {
    id: 136,
    name: "Indiana University-Purdue University-Indianapolis",
    address: "Indiana",
    country: "United States"
  },
  {
    id: 137,
    name: "Iowa State University",
    address: "Iowa",
    country: "United States"
  },
  {
    id: 138,
    name: "James Madison University - JMU",
    address: "Virginia",
    country: "United States"
  },
  {
    id: 139,
    name: "Jefferson College of Health Sciences",
    address: "Virginia",
    country: "United States"
  },
  {
    id: 140,
    name: "John Carroll University",
    address: "Ohio",
    country: "United States"
  },
  {
    id: 141,
    name: "Johns Hopkins University",
    address: "Maryland",
    country: "United States"
  },
  {
    id: 142,
    name: "Jones Graduate School of Business (Rice University)",
    address: "Texas",
    country: "United States"
  },
  {
    id: 143,
    name: "Kansas State University",
    address: "Kansas",
    country: "United States"
  },
  {
    id: 144,
    name: "Keck Graduate Institute",
    address: "California",
    country: "United States"
  },
  {
    id: 145,
    name: "Kenyon College",
    address: "Ohio",
    country: "United States"
  },
  {
    id: 146,
    name: "Kettering College",
    address: "Ohio",
    country: "United States"
  },
  {
    id: 147,
    name: "La Salle University",
    address: "Philadelphia",
    country: "United States"
  },
  {
    id: 148,
    name: "Lafayette College",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 149,
    name: "Lakehead University",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 150,
    name: "Lakeview College of Nursing",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 151,
    name: "Lancaster General College of Nursing & Health Sciences",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 152,
    name: "Lehigh University",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 153,
    name: "Lesley University",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 154,
    name: "Lipscomb University",
    address: "Tennessee",
    country: "United States"
  },
  {
    id: 155,
    name: "Loma Linda University",
    address: "California",
    country: "United States"
  },
  {
    id: 156,
    name: "Louisiana State University - LSU",
    address: "Louisiana",
    country: "United States"
  },
  {
    id: 157,
    name: "Louisiana State University Health Sciences Center-New Orleans",
    address: "Louisiana",
    country: "United States"
  },
  {
    id: 158,
    name: "Loyola Marymount University",
    address: "California",
    country: "United States"
  },
  {
    id: 159,
    name: "Loyola University Maryland",
    address: "Maryland",
    country: "United States"
  },
  {
    id: 160,
    name: "Loyola University New Orleans",
    address: "Louisiana",
    country: "United States"
  },
  {
    id: 161,
    name: "Loyola University of Chicago",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 162,
    name: "MGH Institute of Health Professions",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 163,
    name: "Macalester College",
    address: "Michigan",
    country: "United States"
  },
  {
    id: 164,
    name: "Maine Maritime Academy",
    address: "Maine",
    country: "United States"
  },
  {
    id: 165,
    name: "Manhattan College",
    address: "New York",
    country: "United States"
  },
  {
    id: 166,
    name: "Marquette University",
    address: "Wisconsin",
    country: "United States"
  },
  {
    id: 167,
    name: "Massachusetts College of Pharmacy & Health Sciences",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 168,
    name: "Massachusetts Institute of Technology - MIT",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 169,
    name: "Massachusetts Maritime Academy",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 170,
    name: "McMaster University",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 171,
    name: "Medical University of South Carolina",
    address: "South Carolina",
    country: "United States"
  },
  {
    id: 172,
    name: "Mercer University",
    address: "Georgia",
    country: "United States"
  },
  {
    id: 173,
    name: "Miami University",
    address: "Ohio",
    country: "United States"
  },
  {
    id: 174,
    name: "Michigan State University",
    address: "Michigan",
    country: "United States"
  },
  {
    id: 175,
    name: "Michigan Technological University",
    address: "Michigan",
    country: "United States"
  },
  {
    id: 176,
    name: "Middlebury College",
    address: "Vermont",
    country: "United States"
  },
  {
    id: 177,
    name: "Midwestern University",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 178,
    name: "Missouri University of Science and Technology",
    address: "Missouri",
    country: "United States"
  },
  {
    id: 179,
    name: "Montclair State University",
    address: "New Jersey",
    country: "United States"
  },
  {
    id: 180,
    name: "Mount Carmel College of Nursing",
    address: "Ohio",
    country: "United States"
  },
  {
    id: 181,
    name: "Mount Holyoke College",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 182,
    name: "Mount Sinai School of Medicine",
    address: "New York",
    country: "United States"
  },
  {
    id: 183,
    name: "Nebraska Methodist College of Nursing and Allied Health",
    address: "Nebraska",
    country: "United States"
  },
  {
    id: 184,
    name: "New Jersey Institute of Technology",
    address: "New Jersey",
    country: "United States"
  },
  {
    id: 185,
    name: "New York Institute of Technology - Old Westbury",
    address: "New York",
    country: "United States"
  },
  {
    id: 186,
    name: "New York Law School",
    address: "New York",
    country: "United States"
  },
  {
    id: 187,
    name: "New York Medical College",
    address: "New York",
    country: "United States"
  },
  {
    id: 188,
    name: "New York University - NYU",
    address: "New York",
    country: "United States"
  },
  {
    id: 189,
    name: "North Carolina State University",
    address: "North Carolina",
    country: "United States"
  },
  {
    id: 190,
    name: "Northeastern University",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 191,
    name: "Northeastern University Canada",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 192,
    name: "Northwestern College",
    address: "Iowa",
    country: "United States"
  },
  {
    id: 193,
    name: "Northwestern University",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 194,
    name: "Nova Southeastern University",
    address: "Florida",
    country: "United States"
  },
  {
    id: 195,
    name: "Oberlin College",
    address: "Ohio",
    country: "United States"
  },
  {
    id: 196,
    name: "Occidental College",
    address: "California",
    country: "United States"
  },
  {
    id: 197,
    name: "Ohio Northern University",
    address: "Ohio",
    country: "United States"
  },
  {
    id: 198,
    name: "Ohio State University - OSU",
    address: "Ohio",
    country: "United States"
  },
  {
    id: 199,
    name: "Oklahoma City University (OCU)",
    address: "Oklahoma",
    country: "United States"
  },
  {
    id: 200,
    name: "Oklahoma State University-Main Campus",
    address: "Oklahoma",
    country: "United States"
  },
  {
    id: 201,
    name: "Oregon Health & Science University",
    address: "Oregon",
    country: "United States"
  },
  {
    id: 202,
    name: "Oregon State University",
    address: "Oregon",
    country: "United States"
  },
  {
    id: 203,
    name: "Pace University - New York",
    address: "New York",
    country: "United States"
  },
  {
    id: 204,
    name: "Pacific University",
    address: "Oregon",
    country: "United States"
  },
  {
    id: 205,
    name: "Pennsylvania State University",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 206,
    name: "Pepperdine University",
    address: "California",
    country: "United States"
  },
  {
    id: 207,
    name: "Philadelphia College of Osteopathic Medicine",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 208,
    name: "Philadelphia University",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 209,
    name: "Phillips Beth Israel School of Nursing",
    address: "New York",
    country: "United States"
  },
  {
    id: 210,
    name: "Pitzer College",
    address: "California",
    country: "United States"
  },
  {
    id: 211,
    name: "Pomona College",
    address: "California",
    country: "United States"
  },
  {
    id: 212,
    name: "Princeton University",
    address: "New Jersey",
    country: "United States"
  },
  {
    id: 213,
    name: "Providence College",
    address: "Rhode Island",
    country: "United States"
  },
  {
    id: 214,
    name: "Purdue University",
    address: "Indiana",
    country: "United States"
  },
  {
    id: 215,
    name: "Queens University at Kingston",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 216,
    name: "Quinnipiac University",
    address: "Connecticut",
    country: "United States"
  },
  {
    id: 217,
    name: "Rensselaer Polytechnic Institute",
    address: "New York",
    country: "United States"
  },
  {
    id: 218,
    name: "Research College of Nursing",
    address: "Missouri",
    country: "United States"
  },
  {
    id: 219,
    name: "Resurrection University",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 220,
    name: "Rhode Island School of Design",
    address: "Rhode Island",
    country: "United States"
  },
  {
    id: 221,
    name: "Rhodes College",
    address: "Tennessee",
    country: "United States"
  },
  {
    id: 222,
    name: "Rice University",
    address: "Texas",
    country: "United States"
  },
  {
    id: 223,
    name: "Rider University",
    address: "New Jersey",
    country: "United States"
  },
  {
    id: 224,
    name: "Robert Morris University",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 225,
    name: "Rochester Institute of Technology",
    address: "New York",
    country: "United States"
  },
  {
    id: 226,
    name: "Rollins College",
    address: "Florida",
    country: "United States"
  },
  {
    id: 227,
    name: "Rosalind Franklin University of Medicine and Science",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 228,
    name: "Roseman University of Health Sciences",
    address: "Nevada",
    country: "United States"
  },
  {
    id: 229,
    name: "Rowan University",
    address: "New Jersey",
    country: "United States"
  },
  {
    id: 230,
    name: "Rush University",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 231,
    name: "Rutgers University",
    address: "New Jersey",
    country: "United States"
  },
  {
    id: 232,
    name: "SUNY College of Environmental Science and Forestry",
    address: "New York",
    country: "United States"
  },
  {
    id: 233,
    name: "SUNY Downstate Medical Center",
    address: "New York",
    country: "United States"
  },
  {
    id: 234,
    name: "SUNY at Albany",
    address: "New York",
    country: "United States"
  },
  {
    id: 235,
    name: "SUNY at Binghamton",
    address: "New York",
    country: "United States"
  },
  {
    id: 236,
    name: "SUNY at Stony Brook",
    address: "New York",
    country: "United States"
  },
  {
    id: 237,
    name: "Saint Francis Medical Center College of Nursing",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 238,
    name: "Saint Francis University",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 239,
    name: "Saint John Fisher College",
    address: "New York",
    country: "United States"
  },
  {
    id: 240,
    name: "Saint Louis University-Main Campus",
    address: "Missouri",
    country: "United States"
  },
  {
    id: 241,
    name: "Saint Luke's College of Health Sciences",
    address: "Missouri",
    country: "United States"
  },
  {
    id: 242,
    name: "Saint Mary's College of California",
    address: "California",
    country: "United States"
  },
  {
    id: 243,
    name: "Samford University",
    address: "Alabama",
    country: "United States"
  },
  {
    id: 244,
    name: "Samuel Merritt University",
    address: "California",
    country: "United States"
  },
  {
    id: 245,
    name: "San Diego State University - SDSU",
    address: "California",
    country: "United States"
  },
  {
    id: 246,
    name: "Santa Clara University",
    address: "California",
    country: "United States"
  },
  {
    id: 247,
    name: "Scripps College",
    address: "California",
    country: "United States"
  },
  {
    id: 248,
    name: "Seattle Pacific University",
    address: "Washington",
    country: "United States"
  },
  {
    id: 249,
    name: "Seattle University",
    address: "Washington",
    country: "United States"
  },
  {
    id: 250,
    name: "Sentara College of Health Sciences",
    address: "Virginia",
    country: "United States"
  },
  {
    id: 251,
    name: "Seton Hall University",
    address: "New Jersey",
    country: "United States"
  },
  {
    id: 252,
    name: "Seton Hill University",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 253,
    name: "Sewanee - The University of the South",
    address: "Tennessee",
    country: "United States"
  },
  {
    id: 254,
    name: "Shenandoah University",
    address: "Virginia",
    country: "United States"
  },
  {
    id: 255,
    name: "Simmons College",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 256,
    name: "Skidmore College",
    address: "New York",
    country: "United States"
  },
  {
    id: 257,
    name: "Smith College",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 258,
    name: "Soka University of America",
    address: "California",
    country: "United States"
  },
  {
    id: 259,
    name: "South Dakota School of Mines and Technology",
    address: "South Dakota",
    country: "United States"
  },
  {
    id: 260,
    name: "South Dakota State University",
    address: "South Dakota",
    country: "United States"
  },
  {
    id: 261,
    name: "Southern Methodist University - SMU",
    address: "Texas",
    country: "United States"
  },
  {
    id: 262,
    name: "St John's University-New York",
    address: "New York",
    country: "United States"
  },
  {
    id: 263,
    name: "St Louis College of Pharmacy",
    address: "Missouri",
    country: "United States"
  },
  {
    id: 264,
    name: "St Luke's College",
    address: "Iowa",
    country: "United States"
  },
  {
    id: 265,
    name: "St. James School of Medicine",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 266,
    name: "Stanford University",
    address: "California",
    country: "United States"
  },
  {
    id: 267,
    name: "Stetson University",
    address: "Florida",
    country: "United States"
  },
  {
    id: 268,
    name: "Stevens Institute of Technology",
    address: "New Jersey",
    country: "United States"
  },
  {
    id: 269,
    name: "Stonehill College",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 270,
    name: "Suffolk University",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 271,
    name: "Swarthmore College",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 272,
    name: "Syracuse University",
    address: "New York",
    country: "United States"
  },
  {
    id: 273,
    name: "Taylor University",
    address: "Indiana",
    country: "United States"
  },
  {
    id: 274,
    name: "Teachers College at Columbia University",
    address: "New York",
    country: "United States"
  },
  {
    id: 275,
    name: "Temple University",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 276,
    name: "Texas A&M University",
    address: "Texas",
    country: "United States"
  },
  {
    id: 277,
    name: "Texas Christian University - TCU",
    address: "Texas",
    country: "United States"
  },
  {
    id: 278,
    name: "Texas Tech University",
    address: "Texas",
    country: "United States"
  },
  {
    id: 279,
    name: "Texas Tech University Health Sciences Center",
    address: "Texas",
    country: "United States"
  },
  {
    id: 280,
    name: "The Citadel",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 281,
    name: "The College of New Jersey",
    address: "New Jersey",
    country: "United States"
  },
  {
    id: 282,
    name: "The New School",
    address: "New York",
    country: "United States"
  },
  {
    id: 283,
    name: "The University of Alabama",
    address: "Alabama",
    country: "United States"
  },
  {
    id: 284,
    name: "The University of Texas Health Science - San Antonio",
    address: "Texas",
    country: "United States"
  },
  {
    id: 285,
    name: "The University of Texas Health Science Center at Houston",
    address: "Texas",
    country: "United States"
  },
  {
    id: 286,
    name: "The University of Texas MD Anderson Cancer Center",
    address: "Texas",
    country: "United States"
  },
  {
    id: 287,
    name: "The University of Texas Medical Branch",
    address: "Texas",
    country: "United States"
  },
  {
    id: 288,
    name: "The University of Texas Rio Grande Valley",
    address: "Texas",
    country: "United States"
  },
  {
    id: 289,
    name: "The University of Texas at Dallas",
    address: "Texas",
    country: "United States"
  },
  {
    id: 290,
    name: "The University of Virginia's College at Wise",
    address: "Virginia",
    country: "United States"
  },
  {
    id: 291,
    name: "Thomas Aquinas College",
    address: "California",
    country: "United States"
  },
  {
    id: 292,
    name: "Thomas Jefferson University",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 293,
    name: "Thunderbird School of Global Management",
    address: "Arizona",
    country: "United States"
  },
  {
    id: 294,
    name: "Toronto Metropolitan University",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 295,
    name: "Touro University California",
    address: "California",
    country: "United States"
  },
  {
    id: 296,
    name: "Touro University New York (TUNY)",
    address: "New York",
    country: "United States"
  },
  {
    id: 297,
    name: "Towson University",
    address: "Maryland",
    country: "United States"
  },
  {
    id: 298,
    name: "Trent University",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 299,
    name: "Trinity College",
    address: "Connecticut",
    country: "United States"
  },
  {
    id: 300,
    name: "Trinity University",
    address: "Texas",
    country: "United States"
  },
  {
    id: 301,
    name: "Truman State University",
    address: "Missouri",
    country: "United States"
  },
  {
    id: 302,
    name: "Tufts University",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 303,
    name: "Tulane University",
    address: "Louisiana",
    country: "United States"
  },
  {
    id: 304,
    name: "Union College (New York)",
    address: "New York",
    country: "United States"
  },
  {
    id: 305,
    name: "Union University",
    address: "Tennessee",
    country: "United States"
  },
  {
    id: 306,
    name: "United States Merchant Marine Academy",
    address: "New York",
    country: "United States"
  },
  {
    id: 307,
    name: "University at Buffalo",
    address: "New York",
    country: "United States"
  },
  {
    id: 308,
    name: "University of Alabama at Birmingham",
    address: "Alabama",
    country: "United States"
  },
  {
    id: 309,
    name: "University of Alberta",
    address: "Alberta",
    country: "Canada"
  },
  {
    id: 310,
    name: "University of Arizona",
    address: "Arizona",
    country: "United States"
  },
  {
    id: 311,
    name: "University of Arkansas",
    address: "Arkansas",
    country: "United States"
  },
  {
    id: 312,
    name: "University of Arkansas for Medical Sciences",
    address: "Arkansas",
    country: "United States"
  },
  {
    id: 313,
    name: "University of Calgary",
    address: "Alberta",
    country: "Canada"
  },
  {
    id: 314,
    name: "University of California - Berkeley",
    address: "California",
    country: "United States"
  },
  {
    id: 315,
    name: "University of California - Davis",
    address: "California",
    country: "United States"
  },
  {
    id: 316,
    name: "University of California - Irvine",
    address: "California",
    country: "United States"
  },
  {
    id: 317,
    name: "University of California - Los Angeles - UCLA",
    address: "California",
    country: "United States"
  },
  {
    id: 318,
    name: "University of California - Merced",
    address: "California",
    country: "United States"
  },
  {
    id: 319,
    name: "University of California - Riverside",
    address: "California",
    country: "United States"
  },
  {
    id: 320,
    name: "University of California - San Diego - UCSD",
    address: "California",
    country: "United States"
  },
  {
    id: 321,
    name: "University of California - San Francisco - UCSF",
    address: "California",
    country: "United States"
  },
  {
    id: 322,
    name: "University of California - Santa Barbara - UCSB",
    address: "California",
    country: "United States"
  },
  {
    id: 323,
    name: "University of California - Santa Cruz",
    address: "California",
    country: "United States"
  },
  {
    id: 324,
    name: "University of California College of Law San Francisco",
    address: "California",
    country: "United States"
  },
  {
    id: 325,
    name: "University of Central Florida",
    address: "Florida",
    country: "United States"
  },
  {
    id: 326,
    name: "University of Chicago (incl. Booth)",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 327,
    name: "University of Cincinnati-Main Campus",
    address: "Ohio",
    country: "United States"
  },
  {
    id: 328,
    name: "University of Colorado Denver",
    address: "Colorado",
    country: "United States"
  },
  {
    id: 329,
    name: "University of Colorado at Boulder",
    address: "Colorado",
    country: "United States"
  },
  {
    id: 330,
    name: "University of Colorado at Colorado Springs",
    address: "Colorado",
    country: "United States"
  },
  {
    id: 331,
    name: "University of Connecticut - UConn",
    address: "Connecticut",
    country: "United States"
  },
  {
    id: 332,
    name: "University of Dayton",
    address: "Ohio",
    country: "United States"
  },
  {
    id: 333,
    name: "University of Delaware",
    address: "Delaware",
    country: "United States"
  },
  {
    id: 334,
    name: "University of Denver",
    address: "Colorado",
    country: "United States"
  },
  {
    id: 335,
    name: "University of Detroit Mercy",
    address: "Michigan",
    country: "United States"
  },
  {
    id: 336,
    name: "University of Florida",
    address: "Florida",
    country: "United States"
  },
  {
    id: 337,
    name: "University of Georgia",
    address: "Georgia",
    country: "United States"
  },
  {
    id: 338,
    name: "University of Guelph",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 339,
    name: "University of Hartford",
    address: "Connecticut",
    country: "United States"
  },
  {
    id: 340,
    name: "University of Hawaii at Manoa",
    address: "Hawaii",
    country: "United States"
  },
  {
    id: 341,
    name: "University of Houston",
    address: "Texas",
    country: "United States"
  },
  {
    id: 342,
    name: "University of Idaho",
    address: "Idaho",
    country: "United States"
  },
  {
    id: 343,
    name: "University of Illinois at Chicago",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 344,
    name: "University of Illinois at Urbana-Champaign",
    address: "Illinois",
    country: "United States"
  },
  {
    id: 345,
    name: "University of Iowa",
    address: "Iowa",
    country: "United States"
  },
  {
    id: 346,
    name: "University of Kansas",
    address: "Kansas",
    country: "United States"
  },
  {
    id: 347,
    name: "University of Kentucky",
    address: "Kentucky",
    country: "United States"
  },
  {
    id: 348,
    name: "University of La Verne",
    address: "California",
    country: "United States"
  },
  {
    id: 349,
    name: "University of Lethbridge",
    address: "Alberta",
    country: "Canada"
  },
  {
    id: 350,
    name: "University of Louisville",
    address: "Kentucky",
    country: "United States"
  },
  {
    id: 351,
    name: "University of Maine",
    address: "Maine",
    country: "United States"
  },
  {
    id: 352,
    name: "University of Manitoba",
    address: "Manitoba",
    country: "Canada"
  },
  {
    id: 353,
    name: "University of Maryland - Baltimore",
    address: "Maryland",
    country: "United States"
  },
  {
    id: 354,
    name: "University of Maryland - Baltimore County",
    address: "Maryland",
    country: "United States"
  },
  {
    id: 355,
    name: "University of Maryland - College Park",
    address: "Maryland",
    country: "United States"
  },
  {
  id: 356,
  name: "University of Massachusetts Amherst - UMass Amherst",
  address: "Massachusetts",
  country: "United States"
  },
  {
  id: 357,
  name: "University of Massachusetts Medical School (UMMS)",
  address: "Massachusetts",
  country: "United States"
  },
  {
  id: 358,
  name: "University of Massachusetts-Boston",
  address: "Massachusetts",
  country: "United States"
  },
  {
  id: 359,
  name: "University of Massachusetts-Lowell",
  address: "Massachusetts",
  country: "United States"
  },
  {
  id: 360,
  name: "University of Miami",
  address: "Florida",
  country: "United States"
  },
  {
    id: 361,
    name: "University of Michigan - Ann Arbor",
    address: "Michigan",
    country: "United States"
  },
  {
    id: 362,
    name: "University of Minnesota - Twin Cities",
    address: "Minnesota",
    country: "United States"
  },
  {
    id: 363,
    name: "University of Missouri - Columbia",
    address: "Missouri",
    country: "United States"
  },
  {
    id: 364,
    name: "University of Missouri-Kansas City",
    address: "Missouri",
    country: "United States"
  },
  {
    id: 365,
    name: "University of Mount Union",
    address: "Ohio",
    country: "United States"
  },
  {
    id: 366,
    name: "University of Nebraska - Lincoln",
    address: "Nebraska",
    country: "United States"
  },
  {
    id: 367,
    name: "University of Nebraska Medical Center",
    address: "Nebraska",
    country: "United States"
  },
  {
    id: 368,
    name: "University of New Brunswick",
    address: "New Brunswick",
    country: "Canada"
  },
  {
    id: 369,
    name: "University of New England",
    address: "Maine",
    country: "United States"
  },
  {
    id: 370,
    name: "University of New Hampshire",
    address: "New Hampshire",
    country: "United States"
  },
  {
    id: 371,
    name: "University of New Haven",
    address: "Connecticut",
    country: "United States"
  },
  {
    id: 372,
    name: "University of North Carolina Wilmington",
    address: "North Carolina",
    country: "United States"
  },
  {
    id: 373,
    name: "University of North Carolina at Chapel Hill - UNC",
    address: "North Carolina",
    country: "United States"
  },
  {
    id: 374,
    name: "University of North Dakota",
    address: "North Dakota",
    country: "United States"
  },
  {
    id: 375,
    name: "University of North Texas Health Science Center at Fort Worth",
    address: "Texas",
    country: "United States"
  },
  {
    id: 376,
    name: "University of Northern Iowa",
    address: "Iowa",
    country: "United States"
  },
  {
    id: 377,
    name: "University of Notre Dame",
    address: "Indiana",
    country: "United States"
  },
  {
    id: 378,
    name: "University of Oklahoma",
    address: "Oklahoma",
    country: "United States"
  },
  {
    id: 379,
    name: "University of Oregon",
    address: "Oregon",
    country: "United States"
  },
  {
    id: 380,
    name: "University of Ottawa",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 381,
    name: "University of Pennsylvania (including Wharton)",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 382,
    name: "University of Pittsburgh",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 383,
    name: "University of Portland",
    address: "Oregon",
    country: "United States"
  },
  {
    id: 384,
    name: "University of Puerto Rico-Medical Sciences",
    address: "Puerto Rico",
    country: "United States"
  },
  {
    id: 385,
    name: "University of Rhode Island",
    address: "Rhode Island",
    country: "United States"
  },
  {
    id: 386,
    name: "University of Richmond",
    address: "Virginia",
    country: "United States"
  },
  {
    id: 387,
    name: "University of Rochester",
    address: "New York",
    country: "United States"
  },
  {
    id: 388,
    name: "University of Saint Joseph",
    address: "Connecticut",
    country: "United States"
  },
  {
    id: 389,
    name: "University of San Diego - USD",
    address: "California",
    country: "United States"
  },
  {
    id: 390,
    name: "University of San Francisco",
    address: "California",
    country: "United States"
  },
  {
    id: 391,
    name: "University of Saskatchewan",
    address: "Saskatoon",
    country: "Canada"
  },
  {
    id: 392,
    name: "University of Scranton",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 393,
    name: "University of South Carolina - Columbia",
    address: "South Carolina",
    country: "United States"
  },
  {
    id: 394,
    name: "University of South Dakota",
    address: "South Dakota",
    country: "United States"
  },
  {
    id: 395,
    name: "University of South Florida",
    address: "Florida",
    country: "United States"
  },
  {
    id: 396,
    name: "University of Southern California - USC",
    address: "California",
    country: "United States"
  },
  {
    id: 397,
    name: "University of St. Thomas",
    address: "Minnesota",
    country: "United States"
  },
  {
    id: 398,
    name: "University of Tennessee - Knoxville",
    address: "Tennessee",
    country: "United States"
  },
  {
    id: 399,
    name: "University of Texas Southwestern Medical Center at Dallas",
    address: "Texas",
    country: "United States"
  },
  {
    id: 400,
    name: "University of Texas at Austin - UT Austin",
    address: "Texas",
    country: "United States"
  },
  {
    id: 401,
    name: "University of Toronto",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 402,
    name: "University of Tulsa",
    address: "Oklahoma",
    country: "United States"
  },
  {
    id: 403,
    name: "University of Utah",
    address: "Utah",
    country: "United States"
  },
  {
    id: 404,
    name: "University of Vermont",
    address: "Vermont",
    country: "United States"
  },
  {
    id: 405,
    name: "University of Victoria",
    address: "British Columbia",
    country: "Canada"
  },
  {
    id: 406,
    name: "University of Virginia",
    address: "Virginia",
    country: "United States"
  },
  {
    id: 407,
    name: "University of Washington- Seattle",
    address: "Washington",
    country: "United States"
  },
  {
    id: 408,
    name: "University of Waterloo",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 409,
    name: "University of Windsor",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 410,
    name: "University of Wisconsin - Madison",
    address: "Wisconsin",
    country: "United States"
  },
  {
    id: 411,
    name: "University of Wyoming",
    address: "Wyoming",
    country: "United States"
  },
  {
    id: 412,
    name: "University of the Pacific",
    address: "California",
    country: "United States"
  },
  {
    id: 413,
    name: "University of the Potomac",
    address: "DC",
    country: "United States"
  },
  {
    id: 414,
    name: "University of the Sciences",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 415,
    name: "Utah College of Dental Hygiene",
    address: "Utah",
    country: "United States"
  },
  {
    id: 416,
    name: "Valparaiso University",
    address: "Indiana",
    country: "United States"
  },
  {
    id: 417,
    name: "Vanderbilt University",
    address: "Tennessee",
    country: "United States"
  },
  {
    id: 418,
    name: "Vassar College",
    address: "New York",
    country: "United States"
  },
  {
    id: 419,
    name: "Villanova University",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 420,
    name: "Virginia Commonwealth University",
    address: "Virginia",
    country: "United States"
  },
  {
    id: 421,
    name: "Virginia Polytechnic Institute and State University",
    address: "Virginia",
    country: "United States"
  },
  {
    id: 422,
    name: "Wake Forest University",
    address: "North Carolina",
    country: "United States"
  },
  {
    id: 423,
    name: "Washington State University",
    address: "Washington",
    country: "United States"
  },
  {
    id: 424,
    name: "Washington University in St Louis",
    address: "Missouri",
    country: "United States"
  },
  {
    id: 425,
    name: "Washington and Lee University",
    address: "Virginia",
    country: "United States"
  },
  {
    id: 426,
    name: "Wellesley College",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 427,
    name: "Wesleyan University",
    address: "Connecticut",
    country: "United States"
  },
  {
    id: 428,
    name: "Western University - University of Western Ontario",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 429,
    name: "Western University of Health Sciences",
    address: "California",
    country: "United States"
  },
  {
    id: 430,
    name: "Western Washington University",
    address: "Washington",
    country: "United States"
  },
  {
    id: 431,
    name: "Whitman College",
    address: "Washington",
    country: "United States"
  },
  {
    id: 432,
    name: "Whitworth University",
    address: "Washington",
    country: "United States"
  },
  {
    id: 433,
    name: "Widener University - Main Campus",
    address: "Pennsylvania",
    country: "United States"
  },
  {
    id: 434,
    name: "Wilfrid Laurier University",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 435,
    name: "William Jessup University",
    address: "California",
    country: "United States"
  },
  {
    id: 436,
    name: "Williams College",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 437,
    name: "Worcester Polytechnic Institute",
    address: "Massachusetts",
    country: "United States"
  },
  {
    id: 438,
    name: "Yale University",
    address: "Connecticut",
    country: "United States"
  },
  {
    id: 439,
    name: "Yeshiva University",
    address: "New York",
    country: "United States"
  },
  {
    id: 440,
    name: "York University",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 441,
    name: "Ontario Tech University",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 442,
    name: "Queen's University",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 443,
    name: "Simon Fraser University",
    address: "British Columbia",
    country: "Canada"
  },
  {
    id: 444,
    name: "Toronto Metropolitan University",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 445,
    name: "Trent University",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 446,
    name: "University of British Columbia",
    address: "British Columbia",
    country: "Canada"
  },
  {
    id: 447,
    name: "University of Calgary",
    address: "Alberta",
    country: "Canada"
  },
  {
    id: 448,
    name: "University of Guelph",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 449,
    name: "University of Lethbridge",
    address: "Alberta",
    country: "Canada"
  },
  {
    id: 450,
    name: "University of Montreal",
    address: "Quebec",
    country: "Canada"
  },
  {
    id: 451,
    name: "University of Sherbrooke",
    address: "Quebec",
    country: "Canada"
  },
  {
    id: 452,
    name: "University of Toronto",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 453,
    name: "University of Victoria",
    address: "British Columbia",
    country: "Canada"
  },
  {
    id: 454,
    name: "University of Waterloo",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 455,
    name: "University of Windsor",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 456,
    name: "Western University",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 457,
    name: "York University",
    address: "Ontario",
    country: "Canada"
  },
  {
    id: 458,
    name: "IMD Business School",
    address: "Lausanne",
    country: "Switzerland"
  },
  {
    id: 459,
    name: "China Europe International Business School",
    address: "Shanghai",
    country: "China"
  },
  {
    id: 460,
    name: "Fudan University",
    address: "Shanghai",
    country: "China"
  },
  {
    id: 461,
    name: "Shanghai Jiao Tong University",
    address: "Shanghai",
    country: "China"
  },
  {
    id: 462,
    name: "Carl von Ossietzky Universität Oldenburg",
    address: "Oldenburg",
    country: "Germany"
  },
  {
    id: 463,
    name: "Constructor University",
    address: "Bremen",
    country: "Germany"
  },
  {
    id: 464,
    name: "ESMT Berlin",
    address: "Berlin",
    country: "Germany"
  },
  {
    id: 465,
    name: "Frankfurt School of Finance and Management",
    address: "Frankfurt",
    country: "Germany"
  },
  {
    id: 466,
    name: "Hamburg University of Technology",
    address: "Hamburg",
    country: "Germany"
  },
  {
    id: 467,
    name: "HHL Leipzig",
    address: "Leipzig",
    country: "Germany"
  },
  {
    id: 468,
    name: "Mannheim Business School",
    address: "Mannheim",
    country: "Germany"
  },
  {
    id: 469,
    name: "Technical University of Munich",
    address: "Munich",
    country: "Germany"
  },
  {
    id: 470,
    name: "University of Duisburg-Essen",
    address: "Duisburg and Essen",
    country: "Germany"
  },
  {
    id: 471,
    name: "WHU - Otto Beisheim",
    address: "Vallendar",
    country: "Germany"
  },
  {
    id: 472,
    name: "Copenhagen Business School",
    address: "Copenhagen",
    country: "Denmark"
  },
  {
    id: 473,
    name: "EADA Business School",
    address: "Barcelona",
    country: "Spain"
  },
  {
    id: 474,
    name: "Ramon Llull University",
    address: "Barcelona",
    country: "Spain"
  },
  {
    id: 475,
    name: "University of Navarra",
    address: "Pamplona",
    country: "Spain"
  },
  {
    id: 476,
    name: "Audencia Business School",
    address: "Nantes",
    country: "France"
  },
  {
    id: 477,
    name: "Burgundy School of Business",
    address: "Dijon",
    country: "France"
  },
  {
    id: 478,
    name: "Ecole Polytechnique",
    address: "Palaiseau",
    country: "France"
  },
  {
    id: 479,
    name: "EDHEC Business School",
    address: "Lille",
    country: "France"
  },
  {
    id: 480,
    name: "EM Normandie Business School",
    address: "Caen",
    country: "France"
  },
  {
    id: 481,
    name: "Emlyon",
    address: "Écully",
    country: "France"
  },
  {
    id: 482,
    name: "ESCP Europe Business School",
    address: "Paris",
    country: "France"
  },











  {
    id: 483,
    name: "University of Wollongong",
    address: "Australia",
    country: "Australia",
  },
  {
    id: 484,
    name: "University of Wisconsin-Madison",
    address: "United States",
    country: "United States",
  },
  {
    id: 485,
    name: "University of York",
    address: "United Kingdom",
    country: "United Kingdom",
  },

  {
    id: 486,
    name: "Utah College of Dental Hygiene",
    address: "Utah",
    country: "United States",
  },
  {
    id: 487,
    name: "Valparaiso University",
    address: "Indiana",
    country: "United States",
  },
  {
    id: 488,
    name: "Vanderbilt University",
    address: "Tennessee",
    country: "United States",
  },
  {
    id: 489,
    name: "Vassar College",
    address: "New York",
    country: "United States",
  },
  {
    id: 490,
    name: "Villanova University",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 491,
    name: "Virginia Commonwealth University",
    address: "Virginia",
    country: "United States",
  },
  {
    id: 492,
    name: "Virginia Polytechnic Institute and State University",
    address: "Virginia",
    country: "United States",
  },

  {
    id: 493,
    name: "Vlerick Business School",
    address: "Belgium",
    country: "Belgium",
  },
  {
    id: 494,
    name: "Wake Forest University",
    address: "North Carolina",
    country: "United States",
  },
  {
    id: 495,
    name: "Washington and Lee University",
    address: "Virginia",
    country: "United States",
  },
  {
    id: 496,
    name: "Washington State University",
    address: "Washington",
    country: "United States",
  },
  {
    id: 497,
    name: "Washington University in St Louis",
    address: "Missouri",
    country: "United States",
  },
  {
    id: 498,
    name: "Wayne State University",
    address: "United States",
    country: "United States",
  },
  {
    id: 499,
    name: "Wellesley College",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 500,
    name: "Wesleyan University",
    address: "Connecticut",
    country: "United States",
  },
  {
    id: 501,
    name: "Western Sydney University",
    address: "Australia",
    country: "Australia",
  },
  {
    id: 502,
    name: "Western University - University of Western Ontario",
    address: "Ontario",
    country: "Canada",
  },
  {
    id: 503,
    name: "Western University of Health Sciences",
    address: "California",
    country: "United States",
  },
  {
    id: 504,
    name: "Western Washington University",
    address: "Washington",
    country: "United States",
  },
  {
    id: 505,
    name: "Whitman College",
    address: "Washington",
    country: "United States",
  },
  {
    id: 506,
    name: "Whitworth University",
    address: "Washington",
    country: "United States",
  },

  {
    id: 507,
    name: "WHU - Otto Beisheim",
    address: "Germany",
    country: "Germany",
  },
  {
    id: 508,
    name: "Wichita State University",
    address: "United States",
    country: "United States",
  },
  {
    id: 509,
    name: "Widener University - Main Campus",
    address: "Pennsylvania",
    country: "United States",
  },
  {
    id: 510,
    name: "Wilfrid Laurier University",
    address: "Ontario",
    country: "Canada",
  },
  {
    id: 511,
    name: "William Jessup University",
    address: "California",
    country: "United States",
  },
  {
    id: 512,
    name: "Williams College",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 513,
    name: "Worcester Polytechnic Institute",
    address: "Massachusetts",
    country: "United States",
  },
  {
    id: 514,
    name: "Yale University",
    address: "Connecticut",
    country: "United States",
  },
  {
    id: 515,
    name: "Yeshiva University",
    address: "New York",
    country: "United States",
  },
  {
    id: 516,
    name: "York University",
    address: "Ontario",
    country: "Canada",
  },
  {
    id: 517,
    name: "Leeds Beckett University",
    address: "Leeds",
    country: "United Kingdom",
  },
  {
    id: 518,
    name: "Liverpool John Moores University",
    address: "Leeds",
    country: "United Kingdom",
  },
  {
    id: 519,
    name: "Liverpool School of Medicine",
    address: "Liverpool",
    country: "United Kingdom",
  },
  {
    id: 520,
    name: "London Business School",
    address: "London",
    country: "United Kingdom",
  },
  {
    id: 521,
    name: "Liverpool School of Economics",
    address: "London",
    country: "United Kingdom",
  },
  {
    id: 522,
    name: "Liverpool School of Hygiene & Tropical Medicine",
    address: "London",
    country: "United Kingdom",
  },
  {
    id: 523,
    name: "Loughborough University",
    address: "Loughborough",
    country: "United Kingdom",
  },
  {
    id: 524,
    name: "Newcastle University",
    address: "Newcastle upon Tyne",
    country: "United Kingdom",
  },
  {
    id: 525,
    name: "Northumbria University",
    address: "Newcastle upon Tyne",
    country: "United Kingdom",
  },
  {
    id: 526,
    name: "Nottingham Trent University",
    address: "Nottingham",
    country: "United Kingdom",
  },
  {
    id: 527,
    name: "Oxford Brookes University",
    address: "Oxford",
    country: "United Kingdom",
  },
  {
    id: 528,
    name: "Queen Mary University of London",
    address: "London",
    country: "United Kingdom",
  },
  {
    id: 529,
    name: "Queen's University of Belfast",
    address: "Belfast",
    country: "United Kingdom",
  },
  {
    id: 530,
    name: "Roehampton University",
    address: "London",
    country: "United Kingdom",
  },
  {
    id: 531,
    name: "Royal Holloway University of London",
    address: "London",
    country: "United Kingdom",
  },
  {
    id: 532,
    name: "Royal Veterinary College",
    address: "London",
    country: "United Kingdom",
  },
  {
    id: 533,
    name: "Sheffield Hallam University",
    address: "Sheffield",
    country: "United Kingdom",
  },
  {
    id: 534,
    name: "St George's, University of London",
    address: "London",
    country: "United Kingdom",
  },
  {
    id: 535,
    name: "Swansea University",
    address: "Swansea",
    country: "United Kingdom",
  },
  {
    id: 536,
    name: "The University of Edinburgh",
    address: "Edinburgh",
    country: "United Kingdom",
  },
  {
    id: 537,
    name: "University College London (UCL)",
    address: "London",
    country: "United Kingdom"
  },
  {
    id: 538,
    name: "University of Aberdeen",
    address: "Aberdeen",
    country: "United Kingdom"
  },
  {
    id: 539,
    name: "University of Bath",
    address: "Bath",
    country: "United Kingdom"
  },
  {
    id: 540,
    name: "University of Birmingham",
    address: "Birmingham",
    country: "United Kingdom"
  },
  {
    id: 541,
    name: "University of Bradford",
    address: "Bradford",
    country: "United Kingdom"
  },
  {
    id: 542,
    name: "University of Brighton",
    address: "Brighton",
    country: "United Kingdom"
  },
  {
    id: 543,
    name: "University of Bristol",
    address: "Bristol",
    country: "United Kingdom"
  },
  {
    id: 544,
    name: "University of Cambridge",
    address: "Cambridge",
    country: "United Kingdom"
  },
  {
    id: 545,
    name: "University of Central Lancashire",
    address: "Preston",
    country: "United Kingdom"
  },
  {
    id: 546,
    name: "University of Derby",
    address: "Derby",
    country: "United Kingdom"
  },
  {
    id: 547,
    name: "University of Dundee",
    address: "Dundee",
    country: "United Kingdom"
  },
  {
    id: 548,
    name: "University of East Anglia",
    address: "Norwich",
    country: "United Kingdom"
  },
  {
    id: 549,
    name: "University of Essex",
    address: "Colchester",
    country: "United Kingdom"
  },
  {
    id: 550,
    name: "University of Exeter",
    address: "Exeter",
    country: "United Kingdom"
  },
  {
    id: 551,
    name: "University of Glasgow",
    address: "Glasgow",
    country: "United Kingdom"
  },
  {
    id: 552,
    name: "University of Greenwich",
    address: "London",
    country: "United Kingdom"
  },
  {
    id: 553,
    name: "University of Hertfordshire",
    address: "Hatfield",
    country: "United Kingdom"
  },
  {
    id: 554,
    name: "University of Huddersfield",
    address: "Huddersfield",
    country: "United Kingdom"
  },
  {
    id: 555,
    name: "University of Hull",
    address: "Hull",
    country: "United Kingdom"
  },
  {
    id: 556,
    name: "University of Kent",
    address: "Canterbury",
    country: "United Kingdom"
  },
  {
    id: 557,
    name: "University of Leeds",
    address: "Leeds",
    country: "United Kingdom"
  },
  {
    id: 558,
    name: "University of Leicester",
    address: "Leicester",
    country: "United Kingdom"
  },
  {
    id: 559,
    name: "University of Lincoln",
    address: "Lincoln",
    country: "United Kingdom"
  },
  {
    id: 560,
    name: "University of Liverpool",
    address: "Liverpool",
    country: "United Kingdom"
  },
  {
    id: 561,
    name: "University of London School of Oriental and African Studies (SOAS)",
    address: "London",
    country: "United Kingdom"
  },
  {
    id: 562,
    name: "University of Nottingham",
    address: "Nottingham",
    country: "United Kingdom"
  },
  {
    id: 563,
    name: "University of Oxford",
    address: "Oxford",
    country: "United Kingdom"
  },
  {
    id: 564,
    name: "University of Portsmouth",
    address: "Portsmouth",
    country: "United Kingdom"
  },
  {
    id: 565,
    name: "University of Reading",
    address: "Reading",
    country: "United Kingdom"
  },
  {
    id: 566,
    name: "University of Sheffield",
    address: "Sheffield",
    country: "United Kingdom"
  },
  {
    id: 567,
    name: "University of Southampton",
    address: "Southampton",
    country: "United Kingdom"
  },
  {
    id: 568,
    name: "University of St Andrews",
    address: "St Andrews",
    country: "United Kingdom"
  },
  {
    id: 569,
    name: "University of Stirling",
    address: "Stirling",
    country: "United Kingdom"
  },
  {
    id: 570,
    name: "University of Strathclyde",
    address: "Glasgow",
    country: "United Kingdom"
  },
  {
    id: 571,
    name: "University of Surrey",
    address: "Guildford",
    country: "United Kingdom"
  },
  {
    id: 572,
    name: "University of Sussex",
    address: "Brighton",
    country: "United Kingdom"
  },
  {
    id: 573,
    name: "University of the West of England",
    address: "Bristol",
    country: "United Kingdom"
  },
  {
    id: 574,
    name: "University of the West of Scotland",
    address: "Paisley",
    country: "United Kingdom"
  },
  {
    id: 575,
    name: "University of Ulster",
    address: "Coleraine",
    country: "United Kingdom"
  },
  {
    id: 576,
    name: "University of Warwick",
    address: "Coventry",
    country: "United Kingdom"
  },
  {
    id: 577,
    name: "University of Westminster",
    address: "London",
    country: "United Kingdom"
  },
  {
    id: 578,
    name: "University of Wolverhampton",
    address: "Wolverhampton",
    country: "United Kingdom"
  },
  {
    id: 579,
    name: "University of York",
    address: "York",
    country: "United Kingdom"
  },
  {
    id: 580,
    name: "Chinese University of Hong Kong",
    address: "Hong Kong",
    country: "China"
  },
  {
    id: 581,
    name: "Hong Kong University of Science and Technology",
    address: "Hong Kong",
    country: "China"
  },
  {
    id: 582,
    name: "University of Hong Kong",
    address: "Hong Kong",
    country: "China"
  },
  {
    id: 583,
    name: "Indian School of Business",
    address: "Hyderabad",
    country: "India"
  },
  {
    id: 584,
    name: "POLIMI Graduate School of Management",
    address: "Milan",
    country: "Italy"
  },
  {
    id: 585,
    name: "SDA Bocconi School of Management",
    address: "Milan",
    country: "Italy"
  },
  {
    id: 586,
    name: "Delft University of Technology",
    address: "Delft",
    country: "Netherlands"
  },
  {
    id: 587,
    name: "Eindhoven University of Technology",
    address: "Eindhoven",
    country: "Netherlands"
  },
  {
    id: 588,
    name: "Erasmus University",
    address: "Rotterdam",
    country: "Netherlands"
  },
  {
    id: 589,
    name: "Leiden University",
    address: "Leiden",
    country: "Netherlands"
  },
  {
    id: 590,
    name: "Maastricht University",
    address: "Maastricht",
    country: "Netherlands"
  },
  {
    id: 591,
    name: "Nyenrode Business Universiteit",
    address: "Breukelen",
    country: "Netherlands"
  },
  {
    id: 592,
    name: "Radboud University Nijmegen",
    address: "Nijmegen",
    country: "Netherlands"
  },
  {
    id: 593,
    name: "Tias Business School",
    address: "Tilburg",
    country: "Netherlands"
  },
  {
    id: 594,
    name: "Tilburg University",
    address: "Tilburg",
    country: "Netherlands"
  },
  {
    id: 595,
    name: "University of Amsterdam",
    address: "Amsterdam",
    country: "Netherlands"
  },
  {
    id: 596,
    name: "University of Groningen",
    address: "Groningen",
    country: "Netherlands"
  },
  {
    id: 597,
    name: "University of Twente",
    address: "Enschede",
    country: "Netherlands"
  },
  {
    id: 598,
    name: "Vrije Universiteit Amsterdam",
    address: "Amsterdam",
    country: "Netherlands"
  },
  {
    id: 599,
    name: "The Lisbon MBA",
    address: "Lisbon",
    country: "Portugal"
  },
  {
    id: 600,
    name: "University of Porto",
    address: "Porto",
    country: "Portugal"
  },
  {
    id: 601,
    name: "Nanyang Technological University",
    address: "Singapore",
    country: "Singapore"
  },
  {
    id: 602,
    name: "National University of Singapore",
    address: "Singapore",
    country: "Singapore"
  },
  {
    id: 603,
    name: "Air Force Institute of Technology",
    address: "Dayton, Ohio",
    country: "United States"
  },
  {
    id: 604,
    name: "Alabama A&M University",
    address: "Huntsville, Alabama",
    country: "United States"
  },
  {
    id: 605,
    name: "Alfred University",
    address: "Alfred, New York",
    country: "United States"
  },
 {
    id: 606,
    name: "American University",
    address: "Washington, D.C.",
    country: "United States"
  },
  {
    id: 607,
    name: "Appalachian State University",
    address: "Boone, North Carolina",
    country: "United States"
  },
  {
    id: 608,
    name: "Arizona State University",
    address: "Tempe, Arizona",
    country: "United States"
  },
  {
    id: 609,
    name: "Auburn University",
    address: "Auburn, Alabama",
    country: "United States"
  },
  {
    id: 610,
    name: "Augusta University",
    address: "Augusta, Georgia",
    country: "United States"
  },
  {
    id: 611,
    name: "Babson College",
    address: "Wellesley, Massachusetts",
    country: "United States"
  },
  {
    id: 612,
    name: "Baylor College of Medicine",
    address: "Houston, Texas",
    country: "United States"
  },
  {
    id: 613,
    name: "Baylor University",
    address: "Waco, Texas",
    country: "United States"
  },
  {
    id: 614,
    name: "Belmont University",
    address: "Nashville, Tennessee",
    country: "United States"
  },
  {
    id: 615,
    name: "Bentley University",
    address: "Waltham, Massachusetts",
    country: "United States"
  },
  {
    id: 616,
    name: "Binghamton University - SUNY",
    address: "Binghamton, New York",
    country: "United States"
  },
  {
    id: 617,
    name: "Boise State University",
    address: "Boise, Idaho",
    country: "United States"
  },
  {
    id: 618,
    name: "Boston College",
    address: "Chestnut Hill, Massachusetts",
    country: "United States"
  },
  {
    id: 619,
    name: "Boston University",
    address: "Boston, Massachusetts",
    country: "United States"
  },
  {
    id: 620,
    name: "Bowie State University",
    address: "Bowie, Maryland",
    country: "United States"
  },
  {
    id: 621,
    name: "Bowling Green State University",
    address: "Bowling Green, Ohio",
    country: "United States"
  },
  {
    id: 622,
    name: "Brandeis University",
    address: "Waltham, Massachusetts",
    country: "United States"
  },
  {
    id: 623,
    name: "Brigham Young University",
    address: "Provo, Utah",
    country: "United States"
  },
  {
    id: 624,
    name: "California Institute of Technology",
    address: "Pasadena, California",
    country: "United States"
  },
  {
    id: 625,
    name: "California State University - Long Beach",
    address: "Long Beach, California",
    country: "United States"
  },
  {
    id: 626,
    name: "California State University - Northridge",
    address: "Northridge, California",
    country: "United States"
  },
  {
    id: 627,
    name: "Canisius University",
    address: "Buffalo, New York",
    country: "United States"
  },
  {
    id: 628,
    name: "Carnegie Mellon University",
    address: "Pittsburgh, Pennsylvania",
    country: "United States"
  },
  {
    id: 629,
    name: "Case Western Reserve University",
    address: "Cleveland, Ohio",
    country: "United States"
  },
  {
    id: 630,
    name: "Central Michigan University",
    address: "Mount Pleasant, Michigan",
    country: "United States"
  },
  {
    id: 631,
    name: "Chapman University",
    address: "Orange, California",
    country: "United States"
  },
  {
    id: 632,
    name: "Claremont Graduate University",
    address: "Claremont, California",
    country: "United States"
  },
  {
    id: 633,
    name: "Clark Atlanta University",
    address: "Atlanta, Georgia",
    country: "United States"
  },
  {
    id: 634,
    name: "Clark University",
    address: "Worcester, Massachusetts",
    country: "United States"
  },
  {
    id: 635,
    name: "Clarkson University",
    address: "Potsdam, New York",
    country: "United States"
  },
  {
    id: 636,
    name: "Clemson University",
    address: "Clemson, South Carolina",
    country: "United States"
  },
  {
    id: 637,
    name: "Cleveland State University",
    address: "Cleveland, Ohio",
    country: "United States"
  },
  {
    id: 638,
    name: "College of Charleston",
    address: "Charleston, South Carolina",
    country: "United States"
  },
  {
    id: 639,
    name: "College of William & Mary",
    address: "Williamsburg, Virginia",
    country: "United States"
  },
  {
    id: 640,
    name: "Colorado School of Mines",
    address: "Golden, Colorado",
    country: "United States"
  },
  {
    id: 641,
    name: "Colorado School of Public Health",
    address: "Aurora, Colorado",
    country: "United States"
  },
  {
    id: 642,
    name: "Colorado State University",
    address: "Fort Collins, Colorado",
    country: "United States"
  },
  {
    id: 643,
    name: "Columbia University",
    address: "New York City, New York",
    country: "United States"
  },
  {
    id: 644,
    name: "Cornell University",
    address: "Ithaca, New York",
    country: "United States"
  },
  {
    id: 645,
    name: "CUNY - Baruch College",
    address: "New York City, New York",
    country: "United States"
  },
  {
    id: 646,
    name: "CUNY - John Jay College of Criminal Justice",
    address: "New York City, New York",
    country: "United States"
  },
  {
    id: 647,
    name: "CUNY - Graduate Center",
    address: "New York City, New York",
    country: "United States"
  },
  {
    id: 648,
    name: "CUNY - SPH",
    address: "New York City, New York",
    country: "United States"
  },
  {
    id: 649,
    name: "Dakota State University",
    address: "Madison, South Dakota",
    country: "United States"
  },
  {
    id: 650,
    name: "Dartmouth College",
    address: "Hanover, New Hampshire",
    country: "United States"
  },
  {
    id: 651,
    name: "Delaware State University",
    address: "Dover, Delaware",
    country: "United States"
  },
  {
    id: 652,
    name: "DePaul University",
    address: "Chicago, Illinois",
    country: "United States"
  },
  {
    id: 653,
    name: "Drexel University",
    address: "Philadelphia, Pennsylvania",
    country: "United States"
  },
  {
    id: 654,
    name: "Duke University",
    address: "Durham, North Carolina",
    country: "United States"
  },
  {
    id: 655,
    name: "Duquesne University",
    address: "Pittsburgh, Pennsylvania",
    country: "United States"
  },
  {
    id: 656,
    name: "East Carolina University",
    address: "Greenville, North Carolina",
    country: "United States"
  },
  {
    id: 657,
    name: "East Tennessee State University",
    address: "Johnson City, Tennessee",
    country: "United States"
  },
  {
    id: 658,
    name: "Eastern Michigan University",
    address: "Ypsilanti, Michigan",
    country: "United States"
  },
  {
    id: 659,
    name: "Eastern Virginia Medical School",
    address: "Norfolk, Virginia",
    country: "United States"
  },
  {
    id: 660,
    name: "Embry-Riddle Aeronautical University",
    address: "Daytona Beach, Florida",
    country: "United States"
  },
  {
    id: 661,
    name: "Emory University",
    address: "Atlanta, Georgia",
    country: "United States"
  },
  {
    id: 662,
    name: "Florida A&M University",
    address: "Tallahassee, Florida",
    country: "United States"
  },
  {
    id: 663,
    name: "Florida Atlantic University",
    address: "Boca Raton, Florida",
    country: "United States"
  },
  {
    id: 664,
    name: "Florida Institute of Technology",
    address: "Melbourne, Florida",
    country: "United States"
  },
  {
    id: 665,
    name: "Florida International University",
    address: "Miami, Florida",
    country: "United States"
  },
  {
    id: 666,
    name: "Florida State University",
    address: "Tallahassee, Florida",
    country: "United States"
  },
  {
    id: 667,
    name: "Fordham University",
    address: "New York City, New York",
    country: "United States"
  },
  {
    id: 668,
    name: "George Mason University",
    address: "Fairfax, Virginia",
    country: "United States"
  },
  {
    id: 669,
    name: "George Washington University",
    address: "Washington, D.C.",
    country: "United States"
  },
  {
    id: 670,
    name: "Georgetown University",
    address: "Washington, D.C.",
    country: "United States"
  },
  {
    id: 671,
    name: "Georgia Institute of Technology",
    address: "Atlanta, Georgia",
    country: "United States"
  },
  {
    id: 672,
    name: "Georgia Southern University",
    address: "Statesboro, Georgia",
    country: "United States"
  },
  {
    id: 673,
    name: "Georgia State University",
    address: "Atlanta, Georgia",
    country: "United States"
  },
  {
    id: 674,
    name: "Governors State University - University Park",
    address: "University Park, Illinois",
    country: "United States"
  },
   {
    id: 675,
    name: "Hampton University",
    address: "Hampton, Virginia",
    country: "United States"
  },
  {
    id: 676,
    name: "Harvard University",
    address: "Cambridge, Massachusetts",
    country: "United States"
},
{
    id: 677,
    name: "Hofstra University",
    address: "Hempstead, New York",
    country: "United States"
},
{
    id: 678,
    name: "Howard University",
    address: "Washington, D.C.",
    country: "United States"
},
{
    id: 679,
    name: "Hult International Business School",
    address: "San Francisco, California",
    country: "United States"
},
{
    id: 680,
    name: "Icahn School of Medicine at Mount Sinai",
    address: "New York City, New York",
    country: "United States"
},
{
  id: 681,
  name: "Illinois Institute of Technology",
  address: "Chicago, Illinois",
  country: "United States"
},
{
  id: 682,
  name: "Indiana State University",
  address: "Terre Haute, Indiana",
  country: "United States"
},
{
  id: 683,
  name: "Indiana University Indianapolis",
  address: "Indianapolis, Indiana",
  country: "United States"
},
{
  id: 684,
  name: "Iowa State University",
  address: "Ames, Iowa",
  country: "United States"
},
{
  id: 685,
  name: "Jackson State University",
  address: "Jackson, Mississippi",
  country: "United States"
},
{
  id: 686,
  name: "John Carroll University",
  address: "University Heights, Ohio",
  country: "United States"
},
{
  id: 687,
  name: "Johns Hopkins University",
  address: "Baltimore, Maryland",
  country: "United States"
},
{
  id: 688,
  name: "Kansas State University",
  address: "Manhattan, Kansas",
  country: "United States"
},
{
  id: 689,
  name: "Keck Graduate Institute",
  address: "Claremont, California",
  country: "United States"
},
{
  id: 690,
  name: "Kent State University",
  address: "Kent, Ohio",
  country: "United States"
},
{
  id: 691,
  name: "La Salle University",
  address: "Philadelphia, Pennsylvania",
  country: "United States"
},
{
  id: 692,
  name: "Lake Erie College of Osteopathic Medicine",
  address: "Erie, Pennsylvania",
  country: "United States"
},
{
  id: 693,
  name: "Lamar University",
  address: "Beaumont, Texas",
  country: "United States"
},
{
  id: 694,
  name: "Lawrence Technological University",
  address: "Southfield, Michigan",
  country: "United States"
},
{
  id: 695,
  name: "Lehigh University",
  address: "Bethlehem, Pennsylvania",
  country: "United States"
},
{
  id: 696,
  name: "Lincoln Memorial University",
  address: "Harrogate, Tennessee",
  country: "United States"
},
{
  id: 697,
  name: "Lipscomb University",
  address: "Nashville, Tennessee",
  country: "United States"
},
{
  id: 698,
  name: "Loma Linda University",
  address: "Loma Linda, California",
  country: "United States"
},
{
  id: 699,
  name: "Long Island University - Brooklyn",
  address: "Brooklyn, New York",
  country: "United States"
},
{
  id: 700,
  name: "Louisiana State University",
  address: "New Orleans, Louisiana",
  country: "United States"
},
{
  id: 701,
  name: "Louisiana State University - Baton Rouge",
  address: "Baton Rouge, Louisiana",
  country: "United States"
},
{
  id: 702,
  name: "Louisiana State University - Shreveport",
  address: "Shreveport, Louisiana",
  country: "United States"
},
{
  id: 703,
  name: "Louisiana Tech University",
  address: "Ruston, Louisiana",
  country: "United States"
},
{
  id: 704,
  name: "Loyola Marymount University",
  address: "Los Angeles, California",
  country: "United States"
},
{
  id: 705,
  name: "Loyola University Chicago",
  address: "Chicago, Illinois",
  country: "United States"
},
{
  id: 706,
  name: "Loyola University Maryland",
  address: "Baltimore, Maryland",
  country: "United States"
},
{
  id: 707,
  name: "Marquette University",
  address: "Milwaukee, Wisconsin",
  country: "United States"
},
{
  id: 708,
  name: "Massachusetts Institute of Technology",
  address: "Cambridge, Massachusetts",
  country: "United States"
},
{
  id: 709,
  name: "Medical College of Wisconsin",
  address: "Milwaukee, Wisconsin",
  country: "United States"
},
{
  id: 710,
  name: "Medical University of South Carolina",
  address: "Charleston, South Carolina",
  country: "United States"
},
{
  id: 711,
  name: "Mercer University",
  address: "Macon, Georgia",
  country: "United States"
},
{
  id: 712,
  name: "Miami University",
  address: "Oxford, Ohio",
  country: "United States"
},
{
  id: 713,
  name: "Michigan State University",
  address: "East Lansing, Michigan",
  country: "United States"
},
{
  id: 714,
  name: "Michigan Technological University",
  address: "Houghton, Michigan",
  country: "United States"
},
{
  id: 715,
  name: "Middle Tennessee State University",
  address: "Murfreesboro, Tennessee",
  country: "United States"
},
{
  id: 716,
  name: "Millsaps College",
  address: "Jackson, Mississippi",
  country: "United States"
},
{
  id: 717,
  name: "Mississippi State University",
  address: "Mississippi State, Mississippi",
  country: "United States"
},
{
  id: 718,
  name: "Missouri University of Science & Technology",
  address: "Rolla, Missouri",
  country: "United States"
},
{
  id: 719,
  name: "Montana State University",
  address: "Bozeman, Montana",
  country: "United States"
},
{
  id: 720,
  name: "Morehouse College",
  address: "Atlanta, Georgia",
  country: "United States"
},
{
  id: 721,
  name: "Morgan State University",
  address: "Baltimore, Maryland",
  country: "United States"
},
{
  id: 722,
  name: "New Jersey Institute of Technology",
  address: "Newark, New Jersey",
  country: "United States"
},
{
  id: 723,
  name: "New Mexico Institute of Mining & Technology",
  address: "Socorro, New Mexico",
  country: "United States"
},
{
  id: 724,
  name: "New Mexico State University",
  address: "Las Cruces, New Mexico",
  country: "United States"
},
{
  id: 725,
  name: "New York Medical College",
  address: "Valhalla, New York",
  country: "United States"
},
{
  id: 726,
  name: "New York University",
  address: "New York City, New York",
  country: "United States"
},
{
  id: 727,
  name: "North Carolina A&T State University",
  address: "Greensboro, North Carolina",
  country: "United States"
},
{
  id: 728,
  name: "North Carolina State University",
  address: "Raleigh, North Carolina",
  country: "United States"
},
{
  id: 729,
  name: "North Dakota State University",
  address: "Fargo, North Dakota",
  country: "United States"
},
{
  id: 730,
  name: "Northeastern University",
  address: "Boston, Massachusetts",
  country: "United States"
},
{
  id: 731,
  name: "Northern Arizona University",
  address: "Flagstaff, Arizona",
  country: "United States"
},
{
  id: 732,
  name: "Northern Illinois University",
  address: "DeKalb, Illinois",
  country: "United States"
},
{
  id: 733,
  name: "Northwestern University",
  address: "Evanston, Illinois",
  country: "United States"
},
{
  id: 734,
  name: "Nova Southeastern University",
  address: "Fort Lauderdale, Florida",
  country: "United States"
},
{
  id: 735,
  name: "Oakland University",
  address: "Rochester, Michigan",
  country: "United States"
},
{
  id: 736,
  name: "Ohio State University",
  address: "Columbus, Ohio",
  country: "United States"
},
{
  id: 737,
  name: "Ohio University",
  address: "Athens, Ohio",
  country: "United States"
},
{
  id: 738,
  name: "Oklahoma State University",
  address: "Stillwater, Oklahoma",
  country: "United States"
},
{
  id: 739,
  name: "Old Dominion University",
  address: "Norfolk, Virginia",
  country: "United States"
},
{
  id: 740,
  name: "Oregon Health and Science University",
  address: "Portland, Oregon",
  country: "United States"
},
{
  id: 741,
  name: "Oregon State University",
  address: "Corvallis, Oregon",
  country: "United States"
},
{
  id: 742,
  name: "Pace University",
  address: "New York City, New York",
  country: "United States"
},
{
  id: 743,
  name: "Pacific Lutheran University",
  address: "Tacoma, Washington",
  country: "United States"
},
{
  id: 744,
  name: "Pennsylvania State University",
  address: "University Park, Pennsylvania",
  country: "United States"
},
{
  id: 745,
  name: "Pepperdine University",
  address: "Malibu, California",
  country: "United States"
},
{
  id: 746,
  name: "Portland State University",
  address: "Portland, Oregon",
  country: "United States"
},
{
  id: 747,
  name: "Prairie View A&M University",
  address: "Prairie View, Texas",
  country: "United States"
},
{
  id: 748,
  name: "Princeton University",
  address: "Princeton, New Jersey",
  country: "United States"
},
{
  id: 749,
  name: "Purdue University",
  address: "West Lafayette, Indiana",
  country: "United States"
},
{
  id: 750,
  name: "Purdue University - Northwest",
  address: "Hammond, Indiana",
  country: "United States"
},
{
  id: 751,
  name: "Quinnipiac University",
  address: "Hamden, Connecticut",
  country: "United States"
},
{
  id: 752,
  name: "Rensselaer Polytechnic Institute",
  address: "Troy, New York",
  country: "United States"
},
{
  id: 753,
  name: "Rice University",
  address: "Houston, Texas",
  country: "United States"
},
{
  id: 754,
  name: "Rochester Institute of Technology",
  address: "Rochester, New York",
  country: "United States"
},
{
  id: 755,
  name: "Rollins College",
  address: "Winter Park, Florida",
  country: "United States"
},
{
  id: 756,
  name: "Rosalind Franklin University of Medicine and Science",
  address: "North Chicago, Illinois",
  country: "United States"
},
{
  id: 757,
  name: "Rush University",
  address: "Chicago, Illinois",
  country: "United States"
},
{
  id: 758,
  name: "Rutgers University/The State University of New Jersey",
  address: "New Brunswick, New Jersey",
  country: "United States"
},
{
  id: 759,
  name: "Saint Louis University",
  address: "St. Louis, Missouri",
  country: "United States"
},
{
  id: 760,
  name: "San Diego State University",
  address: "San Diego, California",
  country: "United States"
},
{
  id: 761,
  name: "San Francisco State University",
  address: "San Francisco, California",
  country: "United States"
},
{
  id: 762,
  name: "Santa Clara University",
  address: "Santa Clara, California",
  country: "United States"
},
{
  id: 763,
  name: "Seton Hall University",
  address: "South Orange, New Jersey",
  country: "United States"
},
{
  id: 764,
  name: "South Dakota School of Mines and Technology",
  address: "Rapid City, South Dakota",
  country: "United States"
},
{
  id: 765,
  name: "South Dakota State University",
  address: "Brookings, South Dakota",
  country: "United States"
},
{
  id: 766,
  name: "Southern Illinois University - Carbondale",
  address: "Carbondale, Illinois",
  country: "United States"
},
{
  id: 767,
  name: "Southern Methodist University",
  address: "Dallas, Texas",
  country: "United States"
},
{
  id: 768,
  name: "St. John's University",
  address: "Queens, New York",
  country: "United States"
},
{
  id: 769,
  name: "Stanford University",
  address: "Stanford, California",
  country: "United States"
},
{
  id: 770,
  name: "Stevens Institute of Technology",
  address: "Hoboken, New Jersey",
  country: "United States"
},
{
  id: 771,
  name: "Stony Brook University",
  address: "Stony Brook, New York",
  country: "United States"
},
{
  id: 772,
  name: "Suffolk University",
  address: "Boston, Massachusetts",
  country: "United States"
},
{
  id: 773,
  name: "SUNY College of Environmental Science and Forestry",
  address: "Syracuse, New York",
  country: "United States"
},
{
  id: 774,
  name: "SUNY Downstate Health Sciences University",
  address: "Brooklyn, New York",
  country: "United States"
},
{
  id: 775,
  name: "SUNY Polytechnic Institute",
  address: "Utica, New York",
  country: "United States"
},
{
  id: 776,
  name: "SUNY Upstate Medical University",
  address: "Syracuse, New York",
  country: "United States"
},
{
  id: 777,
  name: "Syracuse University",
  address: "Syracuse, New York",
  country: "United States"
},
{
  id: 778,
  name: "Temple University",
  address: "Philadelphia, Pennsylvania",
  country: "United States"
},
{
  id: 779,
  name: "Tennessee State University",
  address: "Nashville, Tennessee",
  country: "United States"
},
{
  id: 780,
  name: "Texas A&M University",
  address: "College Station, Texas",
  country: "United States"
},
{
  id: 781,
  name: "Texas A&M University - Corpus Christi",
  address: "Corpus Christi, Texas",
  country: "United States"
},
{
  id: 782,
  name: "Texas A&M University - Kingsville",
  address: "Kingsville, Texas",
  country: "United States"
},
{
  id: 783,
  name: "Texas Christian University",
  address: "Fort Worth, Texas",
  country: "United States"
},
{
  id: 784,
  name: "Texas State University",
  address: "San Marcos, Texas",
  country: "United States"
},
{
  id: 785,
  name: "Texas Tech University",
  address: "Lubbock, Texas",
  country: "United States"
},
{
  id: 786,
  name: "The Catholic University of America",
  address: "Washington, D.C.",
  country: "United States"
},
{
  id: 787,
  name: "The New School",
  address: "New York City, New York",
  country: "United States"
},
{
  id: 788,
  name: "Thomas Jefferson University",
  address: "Philadelphia, Pennsylvania",
  country: "United States"
},
{
  id: 789,
  name: "Touro University California",
  address: "Vallejo, California",
  country: "United States"
},
{
  id: 790,
  name: "Towson University",
  address: "Towson, Maryland",
  country: "United States"
},
{
  id: 791,
  name: "Tufts University",
  address: "Medford, Massachusetts",
  country: "United States"
},
{
  id: 792,
  name: "Tulane University",
  address: "New Orleans, Louisiana",
  country: "United States"
},
{
  id: 793,
  name: "University at Albany (SUNY)",
  address: "Albany, New York",
  country: "United States"
},
{
  id: 794,
  name: "University at Buffalo",
  address: "Buffalo, New York",
  country: "United States"
},
{
  id: 795,
  name: "University of Akron",
  address: "Akron, Ohio",
  country: "United States"
},
{
  id: 796,
  name: "University of Alabama",
  address: "Tuscaloosa, Alabama",
  country: "United States"
},
{
  id: 797,
  name: "University of Alabama at Birmingham",
  address: "Birmingham, Alabama",
  country: "United States"
},
{
  id: 798,
  name: "University of Alabama in Huntsville",
  address: "Huntsville, Alabama",
  country: "United States"
},
{
  id: 799,
  name: "University of Alaska - Fairbanks",
  address: "Fairbanks, Alaska",
  country: "United States"
},
{
  id: 800,
  name: "University of Arizona",
  address: "Tucson, Arizona",
  country: "United States"
},
{
  id: 801,
  name: "University of Arkansas",
  address: "Fayetteville, Arkansas",
  country: "United States"
},
{
  id: 802,
  name: "University of Arkansas - Little Rock",
  address: "Little Rock, Arkansas",
  country: "United States"
},
{
  id: 803,
  name: "University of Bridgeport",
  address: "Bridgeport, Connecticut",
  country: "United States"
},
{
  id: 804,
  name: "University of California - Merced",
  address: "Merced, California",
  country: "United States"
},
{
  id: 805,
  name: "University of California Berkeley",
  address: "Berkeley, California",
  country: "United States"
},
{
  id: 806,
  name: "University of California Davis",
  address: "Davis, California",
  country: "United States"
},
{
  id: 807,
  name: "University of California Irvine",
  address: "Irvine, California",
  country: "United States"
},
{
  id: 808,
  name: "University of California Los Angeles",
  address: "Los Angeles, California",
  country: "United States"
},
{
  id: 809,
  name: "University of California Riverside",
  address: "Riverside, California",
  country: "United States"
},
{
  id: 810,
  name: "University of California San Diego",
  address: "San Diego, California",
  country: "United States"
},
{
  id: 806,
  name: "University of California Davis",
  address: "Davis, California",
  country: "United States"
},
{
  id: 807,
  name: "University of California Irvine",
  address: "Irvine, California",
  country: "United States"
},
{
  id: 808,
  name: "University of California Los Angeles",
  address: "Los Angeles, California",
  country: "United States"
},
{
  id: 809,
  name: "University of California Riverside",
  address: "Riverside, California",
  country: "United States"
},
{
  id: 810,
  name: "University of California San Diego",
  address: "San Diego, California",
  country: "United States"
},
{
  id: 811,
  name: "University of California San Francisco",
  address: "San Francisco, California",
  country: "United States"
},
{
  id: 812,
  name: "University of California Santa Barbara",
  address: "Santa Barbara, California",
  country: "United States"
},
{
  id: 813,
  name: "University of California Santa Cruz",
  address: "Santa Cruz, California",
  country: "United States"
},
{
  id: 814,
  name: "University of Central Florida",
  address: "Orlando, Florida",
  country: "United States"
},
{
  id: 815,
  name: "University of Chicago",
  address: "Chicago, Illinois",
  country: "United States"
},
{
  id: 816,
  name: "University of Cincinnati",
  address: "Cincinnati, Ohio",
  country: "United States"
},
{
  id: 817,
  name: "University of Colorado",
  address: "Boulder, Colorado",
  country: "United States"
},
{
  id: 818,
  name: "University of Colorado - Boulder",
  address: "Boulder, Colorado",
  country: "United States"
},
{
  id: 819,
  name: "University of Colorado - Colorado Springs",
  address: "Colorado Springs, Colorado",
  country: "United States"
},
{
  id: 820,
  name: "University of Colorado - Denver",
  address: "Denver, Colorado",
  country: "United States"
},
{
  id: 821,
  name: "University of Connecticut",
  address: "Storrs, Connecticut",
  country: "United States"
},
{
  id: 822,
  name: "University of Dayton",
  address: "Dayton, Ohio",
  country: "United States"
},
{
  id: 823,
  name: "University of Delaware",
  address: "Newark, Delaware",
  country: "United States"
},
{
  id: 824,
  name: "University of Denver",
  address: "Denver, Colorado",
  country: "United States"
},
{
  id: 825,
  name: "University of Detroit Mercy",
  address: "Detroit, Michigan",
  country: "United States"
},
{
  id: 826,
  name: "University of Florida",
  address: "Gainesville, Florida",
  country: "United States"
},
{
  id: 827,
  name: "University of Georgia",
  address: "Athens, Georgia",
  country: "United States"
},
{
  id: 828,
  name: "University of Hawaii at Manoa",
  address: "Honolulu, Hawaii",
  country: "United States"
},
{
  id: 829,
  name: "University of Houston",
  address: "Houston, Texas",
  country: "United States"
},
{
  id: 830,
  name: "University of Idaho",
  address: "Moscow, Idaho",
  country: "United States"
},
{
  id: 831,
  name: "University of Illinois at Chicago",
  address: "Chicago, Illinois",
  country: "United States"
},
{
  id: 832,
  name: "University of Illinois Springfield",
  address: "Springfield, Illinois",
  country: "United States"
},
{
  id: 833,
  name: "University of Illinois Urbana-Champaign",
  address: "Champaign, Illinois",
  country: "United States"
},
{
  id: 834,
  name: "University of Iowa",
  address: "Iowa City, Iowa",
  country: "United States"
},
{
  id: 835,
  name: "University of Kansas",
  address: "Lawrence, Kansas",
  country: "United States"
},
{
  id: 836,
  name: "University of Kansas Medical Center",
  address: "Kansas City, Kansas",
  country: "United States"
},
{
  id: 837,
  name: "University of Kentucky",
  address: "Lexington, Kentucky",
  country: "United States"
},
{
  id: 838,
  name: "University of Louisiana at Lafayette",
  address: "Lafayette, Louisiana",
  country: "United States"
},
{
  id: 839,
  name: "University of Louisville",
  address: "Louisville, Kentucky",
  country: "United States"
},
{
  id: 840,
  name: "University of Maine",
  address: "Orono, Maine",
  country: "United States"
},
{
  id: 841,
  name: "University of Maryland",
  address: "College Park, Maryland",
  country: "United States"
},
{
  id: 842,
  name: "University of Maryland Baltimore",
  address: "Baltimore, Maryland",
  country: "United States"
},
{
  id: 843,
  name: "University of Maryland Baltimore County",
  address: "Baltimore, Maryland",
  country: "United States"
},
{
  id: 844,
  name: "University of Massachusetts - Amherst",
  address: "Amherst, Massachusetts",
  country: "United States"
},
{
  id: 845,
  name: "University of Massachusetts - Boston",
  address: "Boston, Massachusetts",
  country: "United States"
},
{
  id: 846,
  name: "University of Massachusetts - Dartmouth",
  address: "Dartmouth, Massachusetts",
  country: "United States"
},
{
  id: 847,
  name: "University of Massachusetts - Lowell",
  address: "Lowell, Massachusetts",
  country: "United States"
},
{
  id: 848,
  name: "University of Memphis",
  address: "Memphis, Tennessee",
  country: "United States"
},
{
  id: 849,
  name: "University of Miami",
  address: "Coral Gables, Florida",
  country: "United States"
},
{
  id: 850,
  name: "University of Michigan",
  address: "Ann Arbor, Michigan",
  country: "United States"
},
{
  id: 851,
  name: "University of Michigan - Dearborn",
  address: "Dearborn, Michigan",
  country: "United States"
},
{
  id: 852,
  name: "University of Minnesota",
  address: "Minneapolis, Minnesota",
  country: "United States"
},
{
  id: 853,
  name: "University of Mississippi",
  address: "Oxford, Mississippi",
  country: "United States"
},
{
  id: 854,
  name: "University of Missouri",
  address: "Columbia, Missouri",
  country: "United States"
},
{
  id: 855,
  name: "University of Missouri - Kansas City",
  address: "Kansas City, Missouri",
  country: "United States"
},
{
  id: 856,
  name: "University of Missouri - St. Louis",
  address: "St. Louis, Missouri",
  country: "United States"
},
{
  id: 857,
  name: "University of Montana",
  address: "Missoula, Montana",
  country: "United States"
},
{
  id: 858,
  name: "University of Nebraska",
  address: "Lincoln, Nebraska",
  country: "United States"
},
{
  id: 859,
  name: "University of Nebraska - Lincoln",
  address: "Lincoln, Nebraska",
  country: "United States"
},
{
  id: 860,
  name: "University of Nebraska - Omaha",
  address: "Omaha, Nebraska",
  country: "United States"
},
{
  id: 861,
  name: "University of Nevada - Las Vegas",
  address: "Las Vegas, Nevada",
  country: "United States"
},
{
  id: 862,
  name: "University of Nevada - Reno",
  address: "Reno, Nevada",
  country: "United States"
},
{
  id: 863,
  name: "University of New Hampshire - Durham",
  address: "Durham, New Hampshire",
  country: "United States"
},
{
  id: 864,
  name: "University of New Haven",
  address: "West Haven, Connecticut",
  country: "United States"
},
{
  id: 865,
  name: "University of New Mexico",
  address: "Albuquerque, New Mexico",
  country: "United States"
},
{
  id: 866,
  name: "University of New Orleans",
  address: "New Orleans, Louisiana",
  country: "United States"
},
{
  id: 867,
  name: "University of North Carolina - Chapel Hill",
  address: "Chapel Hill, North Carolina",
  country: "United States"
},
{
  id: 868,
  name: "University of North Carolina - Charlotte",
  address: "Charlotte, North Carolina",
  country: "United States"
},
{
  id: 869,
  name: "University of North Carolina - Greensboro",
  address: "Greensboro, North Carolina",
  country: "United States"
},
{
  id: 870,
  name: "University of North Carolina - Wilmington",
  address: "Wilmington, North Carolina",
  country: "United States"
},
{
  "id": 871,
  "name": "University of North Dakota",
  "address": "Grand Forks, North Dakota",
  "country": "United States"
},
{
  "id": 872,
  "name": "University of North Florida",
  "address": "Jacksonville, Florida",
  "country": "United States"
},
{
  "id": 873,
  "name": "University of North Texas",
  "address": "Denton, Texas",
  "country": "United States"
},
{
  "id": 874,
  "name": "University of North Texas Health Science Center",
  "address": "Fort Worth, Texas",
  "country": "United States"
},
{
  "id": 875,
  "name": "University of Northern Colorado",
  "address": "Greeley, Colorado",
  "country": "United States"
},
{
  id: 876,
  name: "University of Notre Dame",
  address: "Notre Dame, Indiana",
  country: "United States"
},
{
  id: 877,
  name: "University of Oklahoma",
  address: "Norman, Oklahoma",
  country: "United States"
},
{
  id: 878,
  name: "University of Oklahoma Health Sciences Center",
  address: "Oklahoma City, Oklahoma",
  country: "United States"
},
{
  id: 879,
  name: "University of Oregon",
  address: "Eugene, Oregon",
  country: "United States"
},
{
  id: 880,
  name: "University of Pennsylvania",
  address: "Philadelphia, Pennsylvania",
  country: "United States"
},
{
  id: 881,
  name: "University of Pittsburgh",
  address: "Pittsburgh, Pennsylvania",
  country: "United States"
},
 {
    id: 882,
    name: "University of Richmond",
    address: "Richmond, Virginia",
    country: "United States"
},
{
    id: 883,
    name: "University of Rochester",
    address: "Rochester, New York",
    country: "United States"
},
{
    id: 884,
    name: "University of San Diego",
    address: "San Diego, California",
    country: "United States"
},
{
    id: 885,
    name: "University of San Francisco",
    address: "San Francisco, California",
    country: "United States"
},
{
  id: 886,
  name: "University of Scranton",
  address: "Scranton, Pennsylvania",
  country: "United States"
},
{
  id: 887,
  name: "University of South Alabama",
  address: "Mobile, Alabama",
  country: "United States"
},
{
  id: 888,
  name: "University of South Carolina",
  address: "Columbia, South Carolina",
  country: "United States"
},
{
  id: 889,
  name: "University of South Dakota",
  address: "Vermillion, South Dakota",
  country: "United States"
},
{
  id: 890,
  name: "University of South Florida",
  address: "Tampa, Florida",
  country: "United States"
},
{
  id: 891,
  name: "University of Southern California",
  address: "Los Angeles, California",
  country: "United States"
},
{
  id: 892,
  name: "University of Southern Mississippi",
  address: "Hattiesburg, Mississippi",
  country: "United States"
},
{
  id: 893,
  name: "University of Tennessee - Chattanooga",
  address: "Chattanooga, Tennessee",
  country: "United States"
},
{
  id: 894,
  name: "University of Tennessee - Knoxville",
  address: "Knoxville, Tennessee",
  country: "United States"
},
{
  id: 895,
  name: "University of Tennessee Health Science Center",
  address: "Memphis, Tennessee",
  country: "United States"
},
{
  id: 896,
  name: "University of Texas at Arlington",
  address: "Arlington, Texas",
  country: "United States"
},
{
  id: 897,
  name: "University of Texas at Austin",
  address: "Austin, Texas",
  country: "United States"
},
{
  id: 898,
  name: "University of Texas at Dallas",
  address: "Richardson, Texas",
  country: "United States"
},
{
  id: 899,
  name: "University of Texas at El Paso",
  address: "El Paso, Texas",
  country: "United States"
},
{
  id: 900,
  name: "University of Texas at San Antonio",
  address: "San Antonio, Texas",
  country: "United States"
},
{
  id: 901,
  name: "University of Texas Health Science Center at Houston",
  address: "Houston, Texas",
  country: "United States"
},
{
  id: 902,
  name: "University of Texas Health Science Center at San Antonio",
  address: "San Antonio, Texas",
  country: "United States"
},
{
  id: 903,
  name: "University of Texas Medical Branch",
  address: "Galveston, Texas",
  country: "United States"
},
{
  id: 904,
  name: "University of Texas Southwestern Medical Center",
  address: "Dallas, Texas",
  country: "United States"
},
{
  id: 905,
  name: "University of the Incarnate Word",
  address: "San Antonio, Texas",
  country: "United States"
},
{
  id: 906,
  name: "University of Toledo",
  address: "Toledo, Ohio",
  country: "United States"
},
{
  id: 907,
  name: "University of Tulsa",
  address: "Tulsa, Oklahoma",
  country: "United States"
},
{
  id: 908,
  name: "University of Utah",
  address: "Salt Lake City, Utah",
  country: "United States"
},
{
  id: 909,
  name: "University of Virginia",
  address: "Charlottesville, Virginia",
  country: "United States"
},
{
  id: 910,
  name: "University of Washington",
  address: "Seattle, Washington",
  country: "United States"
},
{
  id: 911,
  name: "University of Wisconsin - Milwaukee",
  address: "Milwaukee, Wisconsin",
  country: "United States"
},
{
  id: 912,
  name: "University of Wisconsin-Madison",
  address: "Madison, Wisconsin",
  country: "United States"
},
{
  id: 913,
  name: "University of Wyoming",
  address: "Laramie, Wyoming",
  country: "United States"
},
{
  id: 914,
  name: "Utah State University",
  address: "Logan, Utah",
  country: "United States"
},
{
  id: 915,
  name: "Vanderbilt University",
  address: "Nashville, Tennessee",
  country: "United States"
},
{
  id: 916,
  name: "Villanova University",
  address: "Villanova, Pennsylvania",
  country: "United States"
},
{
  id: 917,
  name: "Virginia Commonwealth University",
  address: "Richmond, Virginia",
  country: "United States"
},
{
  id: 918,
  name: "Virginia Tech",
  address: "Blacksburg, Virginia",
  country: "United States"
},
{
  id: 919,
  name: "Wake Forest University",
  address: "Winston-Salem, North Carolina",
  country: "United States"
},
{
  id: 920,
  name: "Washington & Lee University",
  address: "Lexington, Virginia",
  country: "United States"
},
{
  id: 921,
  name: "Washington State University",
  address: "Pullman, Washington",
  country: "United States"
},
{
  id: 922,
  name: "Washington University in St. Louis",
  address: "St. Louis, Missouri",
  country: "United States"
},
{
  id: 923,
  name: "Wayne State University",
  address: "Detroit, Michigan",
  country: "United States"
},
{
  id: 924,
  name: "Wesleyan University",
  address: "Middletown, Connecticut",
  country: "United States"
},
{
  id: 925,
  name: "West Virginia University",
  address: "Morgantown, West Virginia",
  country: "United States"
},
{
  id: 926,
  name: "Western Michigan University",
  address: "Kalamazoo, Michigan",
  country: "United States"
},
{
  id: 927,
  name: "Western University of Health Sciences",
  address: "Pomona, California",
  country: "United States"
},
{
  id: 928,
  name: "Wichita State University",
  address: "Wichita, Kansas",
  country: "United States"
},
{
  id: 929,
  name: "Willamette University",
  address: "Salem, Oregon",
  country: "United States"
},
{
  id: 930,
  name: "Worcester Polytechnic Institute",
  address: "Worcester, Massachusetts",
  country: "United States"
},
{
  id: 931,
  name: "Wright State University",
  address: "Dayton, Ohio",
  country: "United States"
},
{
  id: 932,
  name: "Yale University",
  address: "New Haven, Connecticut",
  country: "United States"
},
{
  id: 933,
  name: "Yeshiva University",
  address: "New York, New York",
  country: "United States"
},
{
  id: 934,
  name: "University of Cape Town",
  address: "Cape Town, Western Cape",
  country: "South Africa"
}
];




 
 

