import React, { useEffect, useState } from 'react';
import { Platformbutton } from 'components/common/button/button';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Formik } from 'formik';
import { resetpassSchema, resetSchema } from 'helpers/schema';
import { connect, useDispatch } from 'react-redux';
import { AlertError } from 'components/common/alertboxes/alertboxes';
import {
  Passwordcheck,
  Registerleft,
} from 'components/auth/register/registerfeatures';
import { Success } from 'components/common/success/success';
// import checkmail from "assets/check-email.svg"
import { VerifyReg } from 'components/auth/register/registerfeatures';
import { navigate } from '@reach/router';
import { userSendResetPasswordLink } from 'containers/Auth/Login/actions';
import {
  resetPasswordV1,
  verifyUserPasswordAuthCode,
} from 'appRedux/actions/auth';
import { useStep } from 'helpers/hooks';
import { AUTH_LOAD } from 'appRedux/constants';
import VestiLogo from 'assets/Vesti_logo.svg';
import { Simplemodal } from 'components/common/simplifiedmodal';

const Passwordreset = props => {
  // eslint-disable-next-line
  const { step, nextStep, previousStep, setStep } = useStep(0);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    email: '',
  });

  // console.log(props);

  useEffect(() => {
    props?.authMessage === 'Password updated successfully'
      ? setOpen(true)
      : setOpen(false);
  }, [props?.authMessage]);

  var renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <Checkmail
            continue={nextStep}
            cb={setStep}
            setData={setData}
            data={data}
            userSendResetPasswordLink={props.userSendResetPasswordLink}
            authMessage={props.authMessage}
            authErr={props.authErr}
            loading={props.authLoad}
          />
        );
      case 1:
        return (
          <VerifyReg
            loading={props.authLoad}
            title={data.email}
            resend={() =>
              props.userSendResetPasswordLink({ email: data.email })
            }
            verify={code =>
              props.verifyUserPasswordAuthCode(
                { userId: localStorage.getItem('userId'), code: code },
                setStep,
              )
            }
            btn="Verify Code"
            authMessage={props.authMessage}
            authErr={props.authErr}
          />
        );
      case 2:
        return (
          <Resetpasswordmail
            email={data.email}
            continue={nextStep}
            cb={setStep}
            setData={setData}
            data={data}
            resetPasswordV1={props.resetPasswordV1}
            authMessage={props.authMessage}
            authErr={props.authErr}
            loading={props.authLoad}
          />
        );
      case 3:
        return (
          <Simplemodal open={open} closable={false}>
            <Success
              title="Successful"
              subtitle={props.authMessage}
              onClick={() => navigate('/auth')}
              button={'Continue to log in'}
            />
          </Simplemodal>
        );
      default:
        return <></>;
    }
  };
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-[0px]">
      <Registerleft />
      <div className="col-span-2 p-[30px] flex flex-col gap-[15px] items-center justify-center w-[100vw] lg:w-full h-[100vh]">
        <div className="flex flex-col gap-[30px] items-start justify-center ">
          <img
            className="w-[100px] md:w-[100px]"
            src={VestiLogo}
            alt="Vesti logo"
          />
          {renderSteps()}
        </div>
      </div>
    </div>
  );
};
export const Checkmail = props => {
  // eslint-disable-next-line
  const dispatch = useDispatch();
  return (
    <div className="w-full md:w-[500px] flex flex-col flex-start">
      <Titlesubtitle
        title="Reset Password"
        subtitle="Enter your email below to receive your
                    password reset instructions."
      />
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={resetSchema}
        onSubmit={values => {
          props.setData({ email: values.email });
          // console.log(values.email)
          props.userSendResetPasswordLink(values.email, () => props.continue());
        }}
      >
        {formik => {
          // eslint-disable-next-line
          const {
            errors,
            touched,
            // isValid,
            values,
            handleChange,
            handleSubmit,
          } = formik;
          return (
            <form
              onSubmit={handleSubmit}
              className="w-full flex flex-col gap-[50px] lg:w-[450px]"
            >
              <div className="w-full flex flex-col gap-[10px]">
                <Inputfloat
                  label="email"
                  type="text"
                  name="email"
                  value={values.email}
                  disabled={false}
                  placeholder="ajitd@gmail.com"
                  onChange={handleChange}
                  invalid={errors.email && touched.email && 'true'}
                  error={errors.email && touched.email ? errors.email : ''}
                />
              </div>
              <div className="">
                {props.authErr && (
                  <AlertError body="The email you provided does not exist in our system." />
                )}
                <div className="mb-2"></div>
                <div className="flex flex-col gap-[10px] items-center w-full">
                  <Platformbutton
                    name="Verify Mail"
                    type="submit"
                    disabled={
                      errors.email || !values.email || props.authLoad === true
                        ? true
                        : false
                    }
                  />
                  <Platformbutton
                    type="link"
                    text="Already have an account"
                    title="Signin"
                    click={() => {
                      dispatch({
                        type: AUTH_LOAD,
                        payload: { authErr: '', authMessage: '' },
                      });
                      navigate('/auth');
                    }}
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export const Resetpasswordmail = props => {
  // eslint-disable-next-line

  return (
    <div className="w-full lg:w-[500px] flex flex-col flex-start">
      <Titlesubtitle
        title="Reset Password"
        subtitle="Enter and confirm your new password below."
      />
      <Formik
        initialValues={{
          password: '',
          cpassword: '',
        }}
        validationSchema={resetpassSchema}
        onSubmit={values => {
          props.resetPasswordV1(
            {
              email: props.email,
              password: values.password,
              confirmPassword: values.cpassword,
            },
            () => props.cb(3),
          );
        }}
      >
        {formik => {
          // eslint-disable-next-line
          const {
            errors,
            touched,
            // isValid,
            values,
            handleChange,
            handleSubmit,
          } = formik;
          return (
            <form
              onSubmit={handleSubmit}
              className="w-full flex flex-col gap-[50px] lg:w-[450px]"
            >
              <div className="w-full flex flex-col gap-[10px]">
                <span className="flex flex-col gap-[10px]">
                  <Inputfloat
                    label="Password"
                    type="password"
                    name="password"
                    value={values.password}
                    disabled={false}
                    placeholder="Enter password mininum of 8 characters"
                    onChange={handleChange}
                    invalid={errors.password && touched.password && 'true'}
                    error={
                      errors.password && touched.password ? errors.password : ''
                    }
                  />
                  <span className="flex flex-wrap gap-[10px]">
                    <Passwordcheck
                      name="8 Characters"
                      check={values.password.match(/^.{8,}$/)}
                    />
                    <Passwordcheck
                      name="A number"
                      check={values.password.match(/\d/)}
                    />
                    <Passwordcheck
                      name="A lowercase letter"
                      check={values.password.match(/[a-z]/)}
                    />
                    <Passwordcheck
                      name="An uppercase letter"
                      check={values.password.match(/[A-Z]/)}
                    />
                    <Passwordcheck
                      name="An Special character"
                      check={values.password.match(/[^\W_]/)}
                    />
                  </span>
                </span>
                <Inputfloat
                  label="confirm password"
                  type="password"
                  name="cpassword"
                  value={values.cpassword}
                  disabled={false}
                  placeholder="Enter password mininum of 8 characters"
                  onChange={handleChange}
                  invalid={errors.cpassword && touched.cpassword && 'true'}
                  error={
                    errors.cpassword && touched.cpassword
                      ? errors.cpassword
                      : ''
                  }
                />
              </div>
              {props.authErr && <AlertError body={props.authErr} />}
              <div className="flex flex-col gap-[10px] items-center w-full">
                <Platformbutton
                  name="Reset Password"
                  type="submit"
                  disabled={
                    !values.password ||
                    !values.cpassword ||
                    values.password !== values.cpassword ||
                    props.authLoad === true
                  }
                />
                <Platformbutton
                  type="link"
                  text="Already have an account"
                  title="Signin"
                  click={() => navigate('/auth')}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ auth, common }) => {
  const { authMessage, authLoad, authErr, loader } = auth;
  const { countries } = common;
  return {
    countries,
    authMessage,
    authLoad,
    authErr,
    loader,
  };
};
const mapDispatchToProps = {
  userSendResetPasswordLink,
  resetPasswordV1,
  verifyUserPasswordAuthCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Passwordreset);
