import {
  ADMIN_EMAIL,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  ON_SHOW_LOADER,
  SIGNIN_USER_NOT_ACTIVE,
  SIGNIN_USER_SUCCESS,
  SUCCESSFUL_CODE,
  USER_TOKEN_SET,
  RESET_LINK_SUCCESS,
  AUTH_LOAD,

  // FETCH_ERROR_MESSAGE,
} from 'appRedux/constants';
import {
  opennReg,
  showAuthMessage,
  setAuthMessage,
  setAuthLoad,
  setAuthAction,
  getProfile,
} from 'appRedux/actions/auth';
import { saveUserData } from 'appRedux/actions/auth';
import {
  // getSession,
  setSession,
  clearSession,
  setSessionWithValue,
  // getSession,
} from 'appRedux/store/cookies';
// import { savePermissions } from 'appRedux/actions/Permission';
// import { saveRoles } from 'appRedux/actions/Roles';
import api, { setToken } from 'appRedux/api';
import {
  handleError,
  openNotificationWithIcon,
  openNotificationWithIconErr,
  openReminder,
  claimModal,
} from 'appRedux/actions/Common';
import _ from 'lodash';
import {
  // redirectPinSetUp,
  // redirectFeedsDashboard,
  redirectResetPassword,
  redirectLogin,
} from 'routes/RedirectRoutes';

// import { getUserCorporateData } from 'containers/PinSetup/actions';
// import { useLocation, navigate } from '@reach/router';
import { navigate } from '@reach/router';
// import { openFair } from 'appRedux/actions/domore';
// import { openAction} from 'appRedux/actions/domore';
// import { isError } from 'lodash';

export const userSignIn = (user, goto, openModal) =>
  signInUserWithEmailPasswordRequest(user, goto, openModal);
export const userSignInMiai = (user, goto, openModal) =>
  signInUserWithEmailPasswordMiai(user, goto, openModal);
export const userSigninno = user => signInUserNoRedirect(user);
export const userSignInWithToken = user =>
  signInUserWithEmailPasswordRequestWithToken(user);
export const userSignInWithToken2 = (user, cb) =>
  signInUserWithEmailPasswordRequestWithToken2(user, cb);
export const userSendResetPasswordLink = (user, link) =>
  userSendResetPasswordLinkFn(user, link);
export const userResetPassword = data => userResetPasswordFn(data);

export const clearCurrentLoggedInUser = () => dispatch => {
  localStorage.removeItem('user');
  clearSession();
};

export const showAuthLoader = () => ({
  type: ON_SHOW_LOADER,
});

export const userSignInSuccess = authUser => ({
  type: SIGNIN_USER_SUCCESS,
  payload: authUser,
});

export const userResetLinkSentSuccess = userEmail => ({
  type: RESET_LINK_SUCCESS,
  payload: userEmail,
});

export const userSignInNotActive = message => ({
  type: SIGNIN_USER_NOT_ACTIVE,
  payload: message,
});

const signInUserWithEmailPasswordRequest = (
  userData,
  goto,
  openModal,
) => dispatch => {
  const user = JSON.parse(userData);
  const dataLogin = {
    email: user.username,
    password: user.password,
    deviceType: 'Web',
  };
  if (user.remember_me && !_.isEmpty(user.remember_me)) {
    setSessionWithValue(user.username, 'remember_me');
  }
  // const lastLocation = useLocation();
  dispatch({ type: FETCH_START });
  dispatch({ type: AUTH_LOAD, payload: { authErr: '' } });
  const url = '/authentication/login';
  api
    .post(url, dataLogin)
    .then(({ data }) => {
      localStorage.setItem('rData', JSON.stringify(data));
      clearSession();
      if (data.token) {
        const { token, data: user } = data;
        const myuser = data.user;

        localStorage.setItem('myuserData', JSON.stringify(myuser));
        // console.log('loggedInUser: ', loggedInUser.hasPendingTransfer)
        setToken(token);
        setSession(token);
        dispatch({ type: USER_TOKEN_SET, payload: token });
        dispatch({ type: FETCH_SUCCESS, payload: true });
        dispatch(saveUserData(user));
        dispatch(getProfile());
        // dispatch(saveWalletData(loggedInUser.wallet))

        localStorage.setItem('userData', JSON.stringify(user));
        var famodal;
        // if (loggedInUser.factorAuth === true) {
        //   setTimeout(() => {
        //     openModal(loggedInUser, token);
        //     famodal = true;
        //   }, 500);
        if (data.user.factorAuth === true) {
          setTimeout(() => {
            openModal(user, token);
            famodal = true;
          }, 500);
        } else {
          setTimeout(() => {
            openNotificationWithIcon(
              'Continue from where you left off.',
              `Hello ${
                JSON.parse(localStorage.getItem('myuserData')).firstName ===
                'User'
                  ? 'There'
                  : JSON.parse(localStorage.getItem('myuserData')).firstName
              }, Welcome Back 👋🏽.`,
            );
          }, 3000);

          dispatch(userSignInSuccess(data.user));
          dispatch(showAuthMessage(data.message));

          data.user.emailVerified === false &&
            dispatch(setAuthAction({ openVerify: true }));
          dispatch({ type: FETCH_SUCCESS, payload: true });

          if (goto) {
            goto === '/auth' || goto === 'auth' || goto === '/'
              ? navigate('/bank')
              : navigate(goto);
          } else {
            navigate('/bank');
          }
        }
        // alert(goto)
        var localdata = localStorage.getItem('userData');
        //  var localdata = JSON.parse(localStorage.getItem('userData'));

        setTimeout(() => {
          famodal === true
            ? console.log()
            : localdata.firstName === 'User' ||
              localdata.lastName === '' ||
              localdata.lastName === null
            ? dispatch(opennReg(true))
            : localdata.kycLevel !== 'Level0' &&
              localdata.hasPendingTransfer?.status === 'PENDING'
            ? dispatch(claimModal(true))
            : localdata.kycLevel === 'Level0'
            ? // ? dispatch(openFair())
              dispatch(openReminder(true))
            : (() => {})();
        }, 500);
      } else {
        dispatch(handleError(data.message ? data.message : 'Network Error'));
      }
    })
    .catch(error => {
      // console.log(error);
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({
        type: AUTH_LOAD,
        payload: { authErr: error.data ? error.data.message : error.message },
      });
      dispatch({
        type: AUTH_LOAD,
        payload: { authErr: error.data ? error.data.message : 'error.message' },
      });
      openNotificationWithIconErr(
        // `Please contact bube'`,
        `Please contact '${ADMIN_EMAIL}'`,
        error.data ? error.data.message : 'Error Occurred',
      );
    });
};

const signInUserWithEmailPasswordMiai = (
  userData,
  goto,
  openModal,
) => dispatch => {
  const user = JSON.parse(userData);
  const dataLogin = {
    email: user.username,
    password: user.password,
    deviceType: 'Web',
  };
  if (user.remember_me && !_.isEmpty(user.remember_me)) {
    setSessionWithValue(user.username, 'remember_me');
  }
  // const lastLocation = useLocation();
  dispatch({ type: FETCH_START });
  dispatch({ type: AUTH_LOAD, payload: { authErr: '' } });
  const url = '/authentication/login';
  api
    .post(url, dataLogin)
    .then(({ data }) => {
      localStorage.setItem('rData', JSON.stringify(data));
      clearSession();
      if (data.token) {
        const { token, data: user } = data;
        const myuser = data.user;

        localStorage.setItem('myuserData', JSON.stringify(myuser));
        // console.log('loggedInUser: ', loggedInUser.hasPendingTransfer)
        setToken(token);
        setSession(token);
        dispatch({ type: USER_TOKEN_SET, payload: token });
        dispatch({ type: FETCH_SUCCESS, payload: true });
        dispatch(saveUserData(user));
        dispatch(getProfile());
        // dispatch(saveWalletData(loggedInUser.wallet))

        localStorage.setItem('userData', JSON.stringify(user));
        var famodal;
        // if (loggedInUser.factorAuth === true) {
        //   setTimeout(() => {
        //     openModal(loggedInUser, token);
        //     famodal = true;
        //   }, 500);
        if (data.user.factorAuth === true) {
          setTimeout(() => {
            openModal(user, token);
            famodal = true;
          }, 500);
        } else {
          setTimeout(() => {
            openNotificationWithIcon(
              'Continue from where you left off.',
              `Hello ${
                JSON.parse(localStorage.getItem('myuserData')).firstName ===
                'User'
                  ? 'There'
                  : JSON.parse(localStorage.getItem('myuserData')).firstName
              }, Welcome Back 👋🏽.`,
            );
          }, 3000);

          dispatch(userSignInSuccess(data.user));
          dispatch(showAuthMessage(data.message));

          data.user.emailVerified === false &&
            dispatch(setAuthAction({ openVerify: true }));
          dispatch({ type: FETCH_SUCCESS, payload: true });

          if (goto) {
            goto === '/auth' || goto === 'auth' || goto === '/'
              ? navigate('/miai')
              : navigate('/miai');
          } else {
            navigate('/miai');
          }
        }
        // alert(goto)
        var localdata = localStorage.getItem('userData');
        //  var localdata = JSON.parse(localStorage.getItem('userData'));

        setTimeout(() => {
          famodal === true
            ? console.log()
            : localdata.firstName === 'User' ||
              localdata.lastName === '' ||
              localdata.lastName === null
            ? dispatch(opennReg(true))
            : localdata.kycLevel !== 'Level0' &&
              localdata.hasPendingTransfer?.status === 'PENDING'
            ? dispatch(claimModal(true))
            : localdata.kycLevel === 'Level0'
            ? // ? dispatch(openFair())
              dispatch(openReminder(true))
            : (() => {})();
        }, 500);
      } else {
        dispatch(handleError(data.message ? data.message : 'Network Error'));
      }
    })
    .catch(error => {
      // console.log(error);
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({
        type: AUTH_LOAD,
        payload: { authErr: error.data ? error.data.message : error.message },
      });
      dispatch({
        type: AUTH_LOAD,
        payload: { authErr: error.data ? error.data.message : 'error.message' },
      });
      openNotificationWithIconErr(
        // `Please contact bube'`,
        `Please contact '${ADMIN_EMAIL}'`,
        error.data ? error.data.message : 'Error Occurred',
      );
    });
};

const signInUserNoRedirect = userData => dispatch => {
  const user = JSON.parse(userData);
  const dataLogin = {
    email: user.username,
    password: user.password,
  };
  if (user.remember_me && !_.isEmpty(user.remember_me)) {
    setSessionWithValue(user.username, 'remember_me');
  }

  dispatch({ type: FETCH_START });
  const url = '/authentication/login';
  api
    .post(url, dataLogin)
    .then(({ data }) => {
      if (data.token) {
        const { token, data: user } = data;
        setToken(token);
        setSession(token);
        dispatch(saveUserData(user));
        dispatch({ type: USER_TOKEN_SET, payload: token });
        dispatch({ type: FETCH_SUCCESS, payload: true });

        localStorage.setItem('userData', JSON.stringify(user));
        // redirectDashboard();
        // redirectFeedsDashboard();
        openNotificationWithIcon(
          'Welcome to Your Immigration Account',
          'Account logged in successfully',
        );
        dispatch(userSignInSuccess(data.data.user));
        dispatch(showAuthMessage(data.message));
        dispatch({ type: FETCH_SUCCESS, payload: true });
      } else {
        dispatch(handleError(data.message ? data.message : 'Network Error'));
      }
    })
    .catch(error => {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      openNotificationWithIconErr(
        `Please contact '${ADMIN_EMAIL}'`,
        error.data ? error.data.message : 'Error Occurred',
      );
    });
};

const signInUserWithEmailPasswordRequestWithToken = userData => dispatch => {
  const user = JSON.parse(userData);
  if (user.remember_me && !_.isEmpty(user.remember_me)) {
    setSessionWithValue(user.username, 'remember_me');
  }

  dispatch({ type: FETCH_START });
  const url = '/authentication/forgot-password/confirm';
  api
    .post(url, user)
    .then(({ data }) => {
      if ((data.status && data.statusCode) === SUCCESSFUL_CODE || 201) {
        if (data.data.active === true) {
          const { token } = data.data;
          setToken(token);
          setSession(token);
          dispatch({ type: USER_TOKEN_SET, payload: token });
          dispatch({ type: FETCH_SUCCESS, payload: true });

          if (data.data.changedPassword) {
            redirectLogin();
            openNotificationWithIcon(
              'Password Changed',
              'Login with new password',
              'success',
            );
            dispatch(userSignInSuccess(data.data.user));
            dispatch(showAuthMessage(data.message));
          } else {
            redirectResetPassword();
          }
          dispatch({ type: FETCH_SUCCESS, payload: true });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: `Account currently not active. Please contact '${ADMIN_EMAIL}'`,
          });
          dispatch(
            userSignInNotActive(
              `Account currently not active. Please contact '${ADMIN_EMAIL}'`,
            ),
          );
        }
      } else {
        dispatch(
          handleError(_.isEmpty(data.message) ? 'Network Error' : data.message),
        ); // same as "FETCH_ERROR"
      }
    })
    .catch(error => {
      dispatch(handleError(error.message)); // same as "FETCH_ERROR"
    });
};

const signInUserWithEmailPasswordRequestWithToken2 = (
  userData,
  cb,
) => dispatch => {
  const user = JSON.parse(userData);
  if (user.remember_me && !_.isEmpty(user.remember_me)) {
    setSessionWithValue(user.username, 'remember_me');
  }
  dispatch(setAuthLoad(true));
  dispatch({ type: FETCH_START });
  const url = '/authentication/forgot-password/confirm';
  api
    .post(url, user)
    .then(({ data }) => {
      // alert('asdasda')
      const { token } = user.token || data.data;
      setToken(token);
      setSession(token);
      dispatch(setAuthLoad(false));
      dispatch({ type: USER_TOKEN_SET, payload: token });
      dispatch({ type: FETCH_SUCCESS, payload: true });

      openNotificationWithIcon(
        'Password Changed',
        'Login with new password',
        'success',
      );
      cb();
      // setTimeout(()=> {
      //   redirectLogin();
      // }, 1000)
    })
    .catch(error => {
      dispatch(setAuthLoad(false));
      dispatch(handleError(error.message)); // same as "FETCH_ERROR"
    });
};

const userSendResetPasswordLinkFn = (email, cb) => dispatch => {
  // const redirectLinkObj = JSON.parse(link);
  dispatch(setAuthLoad(true));
  dispatch({ type: AUTH_LOAD, payload: { authErr: '' } });
  dispatch({ type: FETCH_START });
  const url = '/authentication/forgot-password';
  api
    .post(url, {
      email,
      redirectURL: 'https://app.wevesti.com/auth/reset-password',
    })
    .then(({ data }) => {
      if ((data.status && data.statusCode) === SUCCESSFUL_CODE || 201) {
        if (data) {
          dispatch(userResetLinkSentSuccess(email));
          dispatch(setAuthLoad(false));
          dispatch(setAuthMessage(data.message));
          dispatch({ type: FETCH_SUCCESS, payload: true });
          localStorage.setItem('userId', data.data.id);
          openNotificationWithIcon(
            `${data.message}`,
            'Please check your mail',
            'success',
          );
          dispatch(showAuthMessage(data.message));
          cb();
          // }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: `Email not found. Please contact '${ADMIN_EMAIL}'`,
          });
          dispatch({
            type: AUTH_LOAD,
            payload: {
              authErr: 'The email you provided does not exist in our system.',
            },
          });
          dispatch(setAuthLoad(false));
          dispatch(
            setAuthMessage(
              'The email you provided does not exist in our system.',
            ),
          );
          dispatch(
            userSignInNotActive(
              `Email not found. Please contact '${ADMIN_EMAIL}'`,
            ),
          );
        }
      } else {
        dispatch(setAuthLoad(false));
        dispatch(
          handleError(_.isEmpty(data.message) ? 'Network Error' : data.message),
        ); // same as "FETCH_ERROR"
      }
    })
    .catch(error => {
      dispatch(setAuthLoad(false));
      // dispatch(setAuthMessage('The email you provided does not exist in our system.'));
      dispatch({
        type: AUTH_LOAD,
        payload: {
          authErr: 'The email you provided does not exist in our system.',
        },
      });
      // dispatch(userResetLinkSentSuccess(`${email}0000`));
      // dispatch(handleError(error.data.message)); // same as "FETCH_ERROR"
      // openNotificationWithIconErr(error.data.message,'Reset Password')
    });
};

const userResetPasswordFn = data => dispatch => {
  const userObj = data;
  // GMdaLpel

  dispatch({ type: FETCH_START });
  const url = '/users/password/change';
  api
    .post(url, userObj)
    .then(({ data }) => {
      if ((data.status && data.statusCode) === SUCCESSFUL_CODE || 201) {
        if (data.data) {
          if (data.data.changedPassword) {
            setTimeout(redirectLogin(), 1000);
            openNotificationWithIcon(
              'Your Password has been Changed',
              'You can Login with your new password now',
            );
            dispatch(showAuthMessage(data.message));
            dispatch(userSignInSuccess(data.data));
            dispatch(showAuthMessage(data.message));

            dispatch({ type: FETCH_SUCCESS, payload: true });

            // }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: `An Error Occured. Please contact '${ADMIN_EMAIL}'`,
            });
            openNotificationWithIconErr(
              'An error occured please try again',
              'Password reset',
              // `You can Login with your new password now`,
            );
            // dispatch(
            //   userSignInNotActive(
            //     `Email not found. Please contact '${ADMIN_EMAIL}'`,
            //   ),
            // );
          }
        } else {
          dispatch(
            handleError(
              _.isEmpty(data.message) ? 'Network Error' : data.message,
            ),
          );
          // same as "FETCH_ERROR"
          openNotificationWithIconErr(
            'An error occured please try again',
            'Password reset',
            'error',
            // `You can Login with your new password now`,
          );
        }
      }
    })
    .catch(error => {
      // dispatch(userResetLinkSentSuccess(email + "3"));
      dispatch(handleError(`${error.message}he`)); // same as "FETCH_ERROR"
    });
};
