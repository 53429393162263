import React from 'react';
import vestiw from '../../assets/vesti-white.png';
import facebook from '../../assets/facebook-logo.svg';
import ig from '../../assets/instagram-logo.svg';
import link from '../../assets/linkedin-logo.svg';
import twitter from '../../assets/twitter-logo.svg';
import soon from '../../assets/soon.svg';
import { Link } from '@reach/router';
import './Styles/Footer.scss';


function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-inner">
        <div className="footer-inner top">
          <div className="footer-top">
            <img className="white-logo" src={vestiw} alt="white logo" />
            <div className="right">
              <ul className="">
                <li>PRODUCTS</li>
                {/* <li>
                  {' '}
                  <Link className="footer-link" to="/vesticoin">
                    Vesti-Coin
                  </Link>
                </li> */}
                <li>
                  {' '}
                  <Link className="footer-link" to="/japa">
                    Japa
                  </Link>
                </li>
                <li>
                  {' '}
                  <Link className="footer-link" to="/vesticard">
                    GlobalGeng card
                  </Link>
                </li>
                <li>
                  {' '}
                  <Link className="footer-link" to="/vesticard">
                    Virtual Dollar card
                  </Link>
                </li>
                <li>
                  {' '}
                  <Link className="footer-link" to="#">
                    Dreamers' card <img src={soon} alt="" />
                  </Link>
                </li>

                <li>
                  MLab (Migration Lab) <img src={soon} alt="" />
                </li>
              </ul>
              <ul className="">
                <li>CONTACT</li>
                <li>help@wevesti.com</li>
              </ul>
              <ul className="">
                <li>COMPANY</li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://open.substack.com/pub/vesti/p/whats-new-with-vesti?r=1vtuzd&utm_campaign=post&utm_medium=web"
                  >
                    Vesti Blog
                  </a>
                </li>
              </ul>
              <ul className="">
                <li>ADDRESS</li>
                <li>
                  <strong>NIGERIA(LAGOS)</strong>
                </li>
                <li>30 Furo Ezimora street, Lekki Phase 1</li>
                <li>
                  {' '}
                  <strong>DALLAS TEXAS, USA</strong>
                </li>
                <li>1301, S Bowen rd, Ste 450, Arlington, Tx 76013.</li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className="footer-inner bottom">
          <div className="social-media-links">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://web.facebook.com/vesticash"
            >
              <img
                className=".img-fluid mw-100 h-auto"
                src={facebook}
                alt="fb"
              />
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.instagram.com/vestiofficial/"
            >
              <img className=".img-fluid mw-100 h-auto" src={ig} alt="insta" />
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.linkedin.com/company/vestiofficial/mycompany/"
            >
              <img
                className=".img-fluid mw-100 h-auto"
                src={link}
                alt="linkedin"
              />
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://twitter.com/vestiofficial"
            >
              <img
                className=".img-fluid mw-100 h-auto"
                src={twitter}
                alt="twitter"
              />
            </a>
            {/* <a href="/">
                <img
                  className=".img-fluid mw-100 h-auto"
                  src={youtube}
                  alt="youtube"
                />
              </a> */}
          </div>

          <div className="doc-links">
            <Link className="footer-link" to="/disclosure">
              TERMS OF SERVICE &nbsp; •
            </Link>
            <Link className="footer-link" to="/privacy">
              PRIVACY
            </Link>
            <Link className="footer-link" to="/aml">
              • &nbsp; AML
            </Link>
            <Link className="footer-link" to="/fees">
              • &nbsp; FEES
            </Link>
            <Link className="footer-link" to="/faq">
              • &nbsp; FAQS
            </Link>
          </div>
          <p className="copyright-text ">
            &copy;2022 VESTI. All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
