import React from "react";
import Myfintransactions  from "./financialaccount/financialtable";
import  Mytransactions  from "./mytransactions";
import  Myfbotransactions  from "./fbo/fbotable";

export const Transtable = (props)=> {
    // console.log(props)
    switch(props.type){
        case "founders":
            return <Myfintransactions id={props.id}/>
        case "naira":
        case "NGN":
        case "ngn":
            return <Mytransactions balance ={props.balance} id={props.id} currency={props.currency} />
        case "usd":
        case "USD":
            return <Myfbotransactions id={props.id}/>
        default :
            return (
              <Mytransactions
                currency={props.type}
                id={props.id}
                // currency={props.currency}
              />
            );
            // return <p>Hello there</p>

    }
}