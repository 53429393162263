import {
  FETCH_SUBMITTED_VISA_FORM,
  UPDATE_UPLOADED_FILES,
  VISA_TYPE_VALUES,
  HANDLE_COMPLETED_SECTION,
  H1B_SECTION_RES,
} from 'appRedux/constants';

const INIT_STATE = {
  loading: false,
  message: null,
  error: null,
  isSuccessful: false,
  submittedForms: '',
  uploadedFiles: {},
  visaType: '',
  visaAbbr: '',
  degree: '',
  fillingCompany: '',
  mysections: {
    required: {},
    optional: {},
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_SUBMITTED_VISA_FORM:
      return {
        ...state,
        loading: false,
        error: null,
        ...action.payload,
      };

    case UPDATE_UPLOADED_FILES:
      // If action.payload is not null or undefined, update uploadedFiles with its content
      if (action.payload !== null && action.payload !== undefined) {
        return {
          ...state,
          uploadedFiles: {
            ...state.uploadedFiles,
            ...action.payload,
          },
        };
      } else {
        return {
          ...state,
          uploadedFiles: {},
        };
      }

    case HANDLE_COMPLETED_SECTION:
      // If action.payload is not null or undefined, update uploadedFiles with its content
      // if (action.payload !== null && action.payload !== undefined) {
      return {
        ...state,
        mysections: {
          required: {
            ...state.mysections.required,
            ...action.payload.required,
          },
          optional: {
            ...state.mysections.optional,
            ...action.payload.optional,
          },
        },
      };

    case VISA_TYPE_VALUES:
      // Update visaType and visaAbbr
      return {
        ...state,
        visaType: action.payload.visaType,
        visaAbbr: action.payload.visaAbbr,
      };

    case H1B_SECTION_RES:
      return {
        ...state,
        degree: action.payload.degree,
        fillingCompany: action.payload.fillingCompany,
      };
    default:
      return state;
  }
};
