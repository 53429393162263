import React from 'react'
import Layout from 'components/common/DashboardLayout';
import { InChat } from './InChat';
import './chatArea.css'
import './chatArea.scss'

export const FeedsChat = () => {
  return (
<Layout>
        <div className='inchatAll' style={{}}>
              {/* </Layout> */}
            <InChat/>
        </div>
        </Layout>
  )
}
