import React, { useState, useEffect } from 'react';
import { Note } from '../../note';
import uploadProgress from 'assets/viasForm/uploadP.svg';
import paymentIconProgress from 'assets/viasForm/visaPaymentProgress.svg';
import patternBg from 'assets/viasForm/Pattern.svg';
import { FaArrowRightLong } from 'react-icons/fa6';
import reviewNotStartedIcon from 'assets/viasForm/notStartedReview.svg';
import {
  SinglePathways,
  AllSinglePathways,
} from 'containers/Admin/Pathways/AllPathways';
import {
  Largemodal,
  LargemodalCustomCancel,
} from 'components/common/simplifiedmodal';
import { fetchGroups } from 'appRedux/actions/feeds';
import { useDispatch, useSelector } from 'react-redux';
import groupmembergrowth from 'assets/pathways/groupusergrowth.svg';
import { NewGrowthcommunity } from 'containers/Admin/Pathways/growthcommunity';
import { navigate } from '@reach/router';
import { useLocation } from '@reach/router';
import { updatePrevLocation } from 'appRedux/actions/location';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { TestShare } from './testShare';

export const Info = props => {
  const dispatch = useDispatch();

  const [myGroups, setMyGroups] = useState();
  const [videoSrc, setVideoSrc] = useState('');
  const [seeAllPathways, setSeeAllPathways] = useState(false);
  const [seeAllGroups, setSeeAllGroups] = useState(false);

  const [openTestShare, setOpenTestShare] = useState(false);

  const myGname = 'F1 Student VISA to the U.S';

  const allGroups = useSelector(state => state.feeds.groups);

  useEffect(() => {
    dispatch(fetchGroups());
  }, []);

  // const fetchVideo = async () => {
  //   try {
  //     const response = await fetch(
  //       'https://storagefileforpathway.s3.amazonaws.com/videos/web/O1+VISA.mp4',
  //     );
  //     console.log('blob');
  //     const blob = await response.blob();
  //     const url = URL.createObjectURL(blob);
  //     setVideoSrc(url);
  //   } catch (error) {
  //     console.error('Error fetching video:', error);
  //   }
  // };

  // useEffect(() => {
  //   fetchVideo();
  // }, [videoSrc]);

  // Ensure videoSrc is properly passed to your video element
  // console.log(videoSrc);
  const toWholeCurrency = num =>
    (num / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  const accessCommunity = allGroups?.find(group => {
    return group.name === myGname;
  });

  const location = useLocation();
  const [previousPath, setPreviousPath] = useState();

  useEffect(() => {
    setPreviousPath(location.pathname); // Store current pathname on mount
  }, [location]);

  const handleSetPreviousPath = id => {
    dispatch(updatePrevLocation(previousPath));
    navigate(`/group/${id}`);
  };

  // export const updatePrevLocation = prevpathname => ({
  //   type: PREV_LOCATION,
  //   payload: prevpathname,
  // });
  //  console.log(previousPath);
  const pid = 'a83e651f-7877-471d-873e-fa568bc11b04';
  // a0e85207-895d-40b5-9d82-c0620da14992
  return (
    <>
      <Largemodal
        open={seeAllPathways}
        onClick={() => setSeeAllPathways(false)}
      >
        <AllSinglePathways />
      </Largemodal>{' '}
      <LargemodalCustomCancel
        open={openTestShare}
        onClick={() => setOpenTestShare(false)}
      >
        <div style={{ width: '450px' }}>
          <TestShare
            visaTypetest={props.visaTypetest}
            myvisaType={props.myvisaType}
            visaCopyLink={props.visaCopyLink}
          />
        </div>
      </LargemodalCustomCancel>
      <Largemodal open={seeAllGroups} onClick={() => setSeeAllGroups(false)}>
        <NewGrowthcommunity
          allGroups={allGroups}
          previousPath={previousPath}
          // username={data.username}
          // type={data.type}
          // isActive={isActive}
          // planType={planType}
        />
      </Largemodal>
      <div className="rightSectionRequirement">
        {props.submitted ? (
          <></>
        ) : (
          <p
            style={{
              fontSize: '14px',
              fontWeight: '600',
              color: '#67A948',
              textAlign: 'center',
              paddingBottom: '0.75rem',
              cursor: 'pointer',
            }}
            onClick={() => {
              props.setReportModal(true);
            }}
          >
            Click here to report an issue with this form
          </p>
        )}

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.75rem',
            marginBottom: '0.75rem',
            flexDirection: 'column',
          }}
        >
          {/* if the form has not been finally submitted, display this  */}

          {props.visaTypetest?.length > 0 ? (
            <>
              {' '}
              {props.visaTypetest.map(({ score }) => {
                return (
                  <div className="eligibleContainer__box w-full">
                    <div
                      style={{
                        width: '50%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <p style={{ color: '#fff', fontSize: '13px' }}>
                        ELIGIBILITY SCORE
                      </p>
                      <p
                        style={{
                          color: '#fff',
                          fontSize: '16px',
                          fontWeight: '600',
                        }}
                      >
                        {score}% {score < 70 ? '' : '(You’re a fit!)'}
                      </p>
                    </div>

                    <div
                      style={{
                        borderLeft: '4px solid #2B5219',
                        width: '50%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {score < 70 ? (
                        <p
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                            paddingTop: '10px',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                          }}
                          onClick={() => {
                            props.setOpenTestModal(true);
                          }}
                        >
                          Retake eligibility test <FaArrowRightLong />
                        </p>
                      ) : (
                        <p
                          onClick={() => {
                            setOpenTestShare(true);
                          }}
                          style={{
                            color: '#fff',
                            fontSize: '12px',
                            paddingTop: '10px',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '4px',
                            cursor: 'pointer',
                            width: '100%',
                          }}
                        >
                          Share your test result <FaArrowRightLong />
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {' '}
              <div className="eligibleContainer__box w-full">
                <div
                  style={{
                    width: '50%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <p style={{ color: '#fff', fontSize: '13px' }}>
                    ELIGIBILITY SCORE
                  </p>
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '20px',
                      fontWeight: '600',
                    }}
                  >
                    Not applicable
                  </p>
                </div>

                <div
                  style={{
                    borderLeft: '4px solid #2B5219',
                    width: '50%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '12px',
                      paddingTop: '10px',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                    onClick={() => {
                      props.setOpenTestModal(true);
                    }}
                  >
                    Retake eligibility test <FaArrowRightLong />
                  </p>
                </div>
              </div>
            </>
          )}

          <>
            <UploadAndReviewBar
              submitted={props.submitted}
              paymentProgress={props.paymentProgress}
              reviewStatus={props.reviewStatus}
              uploadPercentage={props.uploadPercentage}
            />
          </>

          {/* if the form has not been finally submitted, display ends here */}

          <div className="visaSmallBox" style={{ background: '#F6F8FF' }}>
            {' '}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
                marginBottom: '0.75rem',
              }}
            >
              <img src={paymentIconProgress} alt="" />
              <p
                style={{
                  color: '#060E42',
                  fontSize: '13px',
                  marginBottom: '20px',
                  fontWeight: '500',
                }}
              >
                PAYMENT STATUS
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {props.paymentProgress == 100 ? (
                <p
                  style={{
                    color: '#060E42',
                    fontSize: '16px',
                    fontWeight: '700',
                  }}
                >
                  Fully Paid ($ {toWholeCurrency(props.amount)})
                </p>
              ) : (
                <p
                  style={{
                    color: '#060E42',
                    fontSize: '20px',
                    fontWeight: '700',
                  }}
                >
                  {props.paymentProgress}% PAID
                </p>
              )}
              {props.paymentProgress == 100 ? (
                <></>
              ) : (
                <p
                  style={{
                    color: '#303D94',
                    fontSize: '12px',
                    fontWeight: '500',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    props.setPaymentModal(true);
                  }}
                >
                  Complete Payment <FaArrowRightLong />
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      {props.visaTypetest ? (
        <>
          {props.visaTypetest[0]?.hasClarityMiai ? (
            <div
              className="aboutpathwayimgbox"
              style={{ margin: '10px 0px', borderRadius: '10px' }}
            >
              <video
                className="video-wrapper"
                controls
                width="100%"
                height="100%"
                style={{ borderRadius: '10px' }}
              >
                <source
                  src={`https://storagefileforpathway.s3.amazonaws.com/videos/web/EB-1.mp4`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : (
            <div></div>
          )}
        </>
      ) : (
        <></>
      )}
      {props.visaTypetest ? (
        <>
          {props.visaTypetest[0]?.hasClarityPrerecorded ? (
            <div
              className="aboutpathwayimgbox"
              style={{ margin: '10px 0px', borderRadius: '10px' }}
            >
              {/* <p></p> */}
              <video
                className="video-wrapper"
                controls
                width="100%"
                height="100%"
                controlsList="nodownload" // This disables the download button
                style={{ borderRadius: '10px' }}
                onContextMenu={e => e.preventDefault()}
              >
                <source
                  src={
                    props.myvisaType === '0-1B Visa'
                      ? `https://storagefileforpathway.s3.amazonaws.com/videos/web/O1+VISA.mp4`
                      : props.myvisaType === '0-1A Visa'
                      ? `https://storagefileforpathway.s3.amazonaws.com/videos/web/O1+VISA.mp4`
                      : props.myvisaType === '0-1 Visa'
                      ? `https://storagefileforpathway.s3.amazonaws.com/videos/web/O1+VISA.mp4`
                      : props.myvisaType === 'EB-1 Visa'
                      ? `https://storagefileforpathway.s3.amazonaws.com/videos/web/EB1+VIDEO.mp4`
                      : props.myvisaType === 'EB-2 NIW Visa'
                      ? `https://storagefileforpathway.s3.amazonaws.com/videos/web/EB+2+VIDEO.mp4`
                      : `https://storagefileforpathway.s3.amazonaws.com/videos/web/H1B+video.mp4`
                  }
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : (
            <div></div>
          )}
        </>
      ) : (
        <></>
      )}
      {props.paymentProgress == 100 ? (
        <div style={{ width: '100%', padding: '0px 6px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: '0.25rem',
              paddingBottom: '0.25rem',
              width: '100%',
            }}
          >
            <p
              style={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#67A948',
              }}
            >
              Explore pathways
            </p>
            <p
              style={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#67A948',
                cursor: 'pointer',
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
              }}
              onClick={() => setSeeAllPathways(true)}
            >
              See more <FaArrowRightLong />
            </p>
          </div>
          <SinglePathways pid={pid} />

          <div
            style={{
              width: '100%',
              padding: '0px 6px',
              marginTop: '0.75rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: '0.25rem',
                paddingBottom: '0.5rem',
                width: '100%',
              }}
            >
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: '600',
                  color: '#67A948',
                }}
              >
                Growth communities{' '}
              </p>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: '600',
                  color: '#67A948',
                  cursor: 'pointer',
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                }}
                onClick={() => setSeeAllGroups(true)}
              >
                See more <FaArrowRightLong />
              </p>
            </div>

            <div style={{ background: '#ECF8FF', padding: '12px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  marginBottom: '0.75rem',
                }}
              >
                <p
                  style={{
                    color: '#1481BA',
                    fontSize: '15px',
                    fontWeight: '700',
                  }}
                >
                  {accessCommunity?.name}
                </p>
                <p
                  style={{
                    color: '#1481BA',
                    fontSize: '12px',
                    fontWeight: '700',
                    border: '1px solid #1481BA',
                    background: '#C0E9FF',
                    padding: '4px 12px',
                    borderRadius: '6px',
                  }}
                >
                  {accessCommunity?.type}
                </p>
              </div>

              <p style={{ color: '#1481BA', fontSize: '15px' }}>
                {accessCommunity?.description}
              </p>

              <div
                style={{
                  marginTop: '0.75rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    // marginTop: '0.75rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                  }}
                >
                  <img
                    src={groupmembergrowth}
                    alt=""
                    style={{ height: '40%', width: '5rem' }}
                  />
                  <p style={{ fontSize: '13px' }}>5K+ Members</p>
                </div>

                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#1481BA',
                    cursor: 'pointer',
                    display: 'flex',
                    gap: '4px',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    handleSetPreviousPath(accessCommunity?.id);
                  }}
                >
                  Join now <FaArrowRightLong />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="VisaFormMainPage__quickreadSubsection__sub">
          <Note
            title={props.checkVisaType}
            submitted={props.submitted}
            myvisaType={props.myvisaType}
          />
        </div>
      )}
    </>
  );
};

const UploadAndReviewBar = props => {
  if (!props.submitted) {
    return (
      <div className="visaSmallBox" style={{ background: '#FCF7FF' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
            marginBottom: '0.75rem',
          }}
        >
          <img src={uploadProgress} alt="" />
          <p
            style={{
              color: '#3F1951',
              fontSize: '13px',
              marginBottom: '20px',
              fontWeight: '500',
            }}
          >
            UPLOAD PROGRESS
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '100%',
              backgroundColor: '#f0f0f0',
              borderRadius: '4px',
              height: '5px',
            }}
          >
            <div
              style={{
                width: `${
                  props.uploadPercentage ? props.uploadPercentage : 0
                }%`,
                height: '100%',
                backgroundColor: '#3F1951',
                borderRadius: '4px',
                transition: 'width 0.1s ease-in-out',
              }}
            ></div>
          </div>
          <p
            style={{
              color: '#3F1951',
              fontSize: '20px',
              fontWeight: '700',
            }}
          >
            {props.uploadPercentage ? props.uploadPercentage : 0}%
          </p>
        </div>
      </div>
    );
  } else if (
    (props.submitted && props.paymentProgress == 50) ||
    (props.submitted && props.paymentProgress == 75)
  ) {
    return (
      <div className="visaSmallBox" style={{ background: '#F2F4F7' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
            marginBottom: '0.75rem',
          }}
        >
          <img src={reviewNotStartedIcon} alt="" />
          <p
            style={{
              color: '#344054',
              fontSize: '14px',
              marginBottom: '10px',
              fontWeight: '600',
            }}
          >
            REVIEW STATUS
          </p>
        </div>

        <p
          style={{
            color: '#98A2B3',
            fontSize: '20px',
            fontWeight: '700',
          }}
        >
          NOT STARTED
        </p>
      </div>
    );
  } else if (props.submitted && props.paymentProgress == 100) {
    return (
      <div
        className="visaSmallBox"
        style={{
          background: `${
            props.reviewStatus === 'COMPLETED' ? '#F7FFF3' : '#FFFCF5'
          } `,
          border: `${
            props.reviewStatus === 'COMPLETED'
              ? '1px solid #C7FFAD'
              : '1px solid #FEF0C7'
          }`,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
            marginBottom: '0.5rem',
          }}
        >
          <img src={reviewNotStartedIcon} alt="" />
          <p
            style={{
              color: `${
                props.reviewStatus === 'COMPLETED' ? '#2B5219' : '#7A2E0E'
              }`,
              fontSize: '13px',
              fontWeight: '600',
            }}
          >
            REVIEW STATUS
          </p>
        </div>

        {props.reviewStatus === 'COMPLETED' ? (
          <p
            style={{
              color: '#67A948',
              fontSize: '16px',
              fontWeight: '700',
            }}
          >
            COMPLETED{' '}
          </p>
        ) : (
          <p
            style={{
              color: '#B54708',
              fontSize: '16px',
              fontWeight: '700',
            }}
          >
            IN PROGRESS{' '}
          </p>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};
