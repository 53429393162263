import React from 'react';
import './profileside.scss';
import password from '../../assets/password.svg';
import kyc from '../../assets/kyc.svg';
import bank from '../../assets/addbank.svg';
import account from '../../assets/account.svg';
import referral from '../../assets/ref.svg';
import membership from '../../assets/membership.svg';
import { navigate } from '@reach/router';

export const Profileside = props => {
  const sideImages = [account, password, bank, kyc, referral, membership];

  const sides = [
    { name: 'My Account', route: '/myprofile?tab=account', value: 'account' },
    {
      name: 'Password & PIN',
      route: '/myprofile?tab=security',
      value: 'security',
    },
    {
      name: 'Add Beneficiary',
      route: '/myprofile?tab=addbeneficiary',
      value: 'addbeneficiary',
    },
    { name: 'KYC', route: '/myprofile?tab=kyc', value: 'kyc' },
    { name: 'Referral', route: '/myprofile?tab=referral', value: 'referral' },
    {
      name: 'Membership',
      route: '/myprofile?tab=membership',
      value: 'membership',
    },
  ];

  var changeScreen = value => {
    navigate(value);
    props.setShow && props.setShow(false);
  };

  return (
    <div className="profileside">
      <div className="profileside__inner">
        {sides.map((item, index) => (
          <Singleside
            key={index}
            id={index}
            image={sideImages[index]}
            data={item}
            changeScreen={changeScreen}
            tab={props.value}
          />
        ))}
      </div>
    </div>
  );
};

const Singleside = props => {
  return (
    <div
      key={props.key}
      className={`singleside  ${
        props.tab === props.data.value ? ' active' : 'notactive'
      }`}
      onClick={() => props.changeScreen(props.data.route)}
    >
      <div className="singleside__inner">
        <img src={props.image} alt="side pics" />
        <p>{props.data.name}</p>
      </div>
    </div>
  );
};
