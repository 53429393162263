import React, { useState } from 'react';
import { radio_card, radio_ngn } from 'assets/assets';
import { RadioOption } from 'components/common/radiobutton/radiobutton';
import { Platformbutton } from 'components/common/button/button';
import { Simplemodal } from 'components/common/simplifiedmodal';
import '../../containers/Admin/Streaming/subindex.scss';
import { navigate } from '@reach/router';

export const SelectcurrencyFlight = () => {
  //   const [showCurrencyModal, setShowCurrencyModal] = useState(false);
  const [option, setOption] = useState('NGN');

  const setPaymentOption = value => {
    setOption(value);
  };
  return (
    <div>
      <div className="paymentoption-container">
        <div className="paymentoption-inner">
          <div style={{ marginBottom: '1rem' }}>
            <h2 className="font-[600] text-[24px]  text-[#14290A]">
              Select Currency
            </h2>
            <p style={{ color: '#2B5219' }}>
              Select the currency you want to pay with
            </p>
          </div>

          <div className="paymentoption-inner mt-8 center mb-8">
            <RadioOption
              image={radio_ngn}
              changed={setPaymentOption}
              id="1"
              isSelected={option === 'NGN'}
              label="Pay in Naira"
              sublabel="Pay for your flight in Naira"
              value="NGN"
            />
            <div className="mb-4"></div>
            <RadioOption
              image={radio_ngn}
              changed={setPaymentOption}
              id="2"
              isSelected={option === 'USD'}
              label="Pay in USD"
              value="USD"
              sublabel="Pay for your flight in Dollars"
            />

            <div className="mb-4"></div>
          </div>
          {option === 'NGN' && (
            <Platformbutton
              type="normal"
              name="Continue"
              click={() => {
                navigate('/bookflight');
                // window.open(' https://app.wevesti.com/bookflight', '_blank');
                //   setShowCurrencyModal(false);
              }}
              classname="fas fa-arrow-right"
            />
          )}

          {option === 'USD' && (
            <Platformbutton
              type="normal"
              name="Continue"
              click={() => {
                navigate('/bookflight/usd');

                //   setShowCurrencyModal(false);
              }}
              classname="fas fa-arrow-right"
            />
          )}
        </div>
      </div>

      {/* <Simplemodal
        onClick={() => setShowCurrencyModal(false)}
        open={showCurrencyModal}
      >
        

        <div className="paymentoption-container">
          <div className="paymentoption-inner">
            <div style={{ marginBottom: '1rem' }}>
              <h2 className="font-[600] text-[24px]  text-[#14290A]">
                Select Currency
              </h2>
              <p style={{ color: '#2B5219' }}>
                Select the currency you want to pay with
              </p>
            </div>

            <div className="paymentoption-inner mt-8 center mb-8">
              <RadioOption
                image={radio_ngn}
                changed={setPaymentOption}
                id="1"
                isSelected={option === 'NGN'}
                label="Pay in Naira"
                sublabel="Pay for your flight in Naira"
                value="NGN"
              />
              <div className="mb-4"></div>
              <RadioOption
                image={radio_ngn}
                changed={setPaymentOption}
                id="2"
                isSelected={option === 'USD'}
                label="Pay in USD"
                value="USD"
                sublabel="Pay for your flight in Dollars"
              />

              <div className="mb-4"></div>
            </div>
            {option === 'NGN' && (
              <Platformbutton
                type="normal"
                name="Continue"
                click={() => {
                  window.open(' https://app.wevesti.com/bookflight', '_blank');
                  setShowCurrencyModal(false);
                }}
                classname="fas fa-arrow-right"
              />
            )}

            {option === 'USD' && (
              <Platformbutton
                type="normal"
                name="Continue"
                click={() => {
                  window.open(
                    ' https://app.wevesti.com/bookflight/usd',
                    '_blank',
                  );
                  setShowCurrencyModal(false);
                }}
                classname="fas fa-arrow-right"
              />
            )}
          </div>
        </div>
      </Simplemodal> */}
    </div>
  );
};
