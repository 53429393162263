import Layout from 'components/common/DashboardLayout';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import { Link } from '@reach/router';
import { red } from '@material-ui/core/colors';
import { openNotificationWithIcon } from 'appRedux/actions/Common';

import {
  Selfcare,
  Hand,
  Uploadicon,
  // Padlock,
  // Japaimg,
} from '../../../assets/assets';
import tick from '../../../assets/chats/tick-circle.svg';

import api from 'appRedux/api';
import { objectValuesStringify } from 'helpers/utils';
import Loader from 'components/Loader';
import Modal from 'components/common/Modal';
import { Form, Input, Upload } from 'antd';
import { useUserData } from 'helpers/hooks';
import '.././Admin.css';
import { useDispatch } from 'react-redux';
// import { Openpdf } from 'components/common/pdf/openpdf';
import queryString from 'query-string';
import { useLocation } from '@reach/router';
// import { Membership } from 'components/membership/membership';
import { navigate } from '@reach/router';
// import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { subscriptionPlan } from './data';
import { Simplemodal } from 'components/common/simplifiedmodal';
import './subindex.scss';
import { SubcriptionPaymentOption } from './subcriptionPaymentOption';
import { Platformbutton } from 'components/common/button/button';

// eslint-disable-next-line
const SubscriptionPlan = ({ plan, frequency, showModal, setShowModal }) => {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleCompleteTransaction = async values => {
    // console.log('form Data', formData);
    values.walletToCharge = 'NGN_KOBO';
    values.frequency = frequency;
    values.plan = plan;

    // console.log(values);
    setFormData(values);
    const datas = { ...formData, ...values };
    // console.log('form datas', datas);
    const url = `/userPlan/startSubscription`;
    setLoading(true);

    try {
      const res = await api.post(url, values);
      // console.log({ ...res });
      const { data } = res;
      openNotificationWithIcon(data.message, 'Subscription Payment', 'success');
      setLoading(false);
      setShowModal(false);
    } catch (error) {
      if (error?.data?.errors) {
        openNotificationWithIcon(
          objectValuesStringify(error?.data?.errors),
          'Subscription Payment',
          'error',
        );
      } else {
        const err = error?.data?.message || error.message;
        openNotificationWithIcon(err, 'Subscription Payment', 'error');
      }

      setLoading(false);
    }
  };

  return (
    <div className="">
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <div className="d-flex flex-column text-center  justify-content-center">
          <h4>Enter Transaction Otp sent to your mail</h4>
          <Form onFinish={handleCompleteTransaction}>
            <div className="w-100 flex-fill pt-4">
              <Form.Item
                className="mb-4 w-50 mx-auto"
                name="transactionPin"
                rules={[
                  {
                    required: true,
                    message: 'Please input your transaction pin!',
                  },
                ]}
              >
                <Input
                  className=""
                  type="password"
                  style={{
                    padding: '20px',
                    background: '#F8FBF7',
                    borderRadius: '20px',
                    textAlign: 'center',
                    letterSpacing: '16px',

                    border: 'none',
                  }}
                />
              </Form.Item>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <div className="d-flex flex-wrap justify-content-around mt-5">
                <button
                  type="submit"
                  style={{
                    background: '#67A948',
                    color: '#ffffff',
                    marginBottom: '10px',
                  }}
                >
                  Continue
                </button>
                <button
                  type="button"
                  style={{
                    marginBottom: '10px',
                  }}
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            )}
          </Form>
        </div>
      </Modal>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// eslint-disable-next-line
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    primary: '#67A948 !important',
  },
  card: {
    maxWidth: 345,
  },
  membershipCard: {
    maxWidth: 200,
    margin: 5,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  indicator: {
    backgroundColor: '#67A948',
  },
}));

// const theme = createMuiTheme({
//   overrides: {
//     MuiTabs: {
//       indicator: {
//         backgroundColor: orange[700]
//       }
//     },
//     MuiTab: {
//       root: {
//         "&:hover": {
//           backgroundColor: pink[100],
//           color: pink[700]
//         }
//       },
//       selected: {
//         backgroundColor: orange[100],
//         color: orange[700],
//         "&:hover": {
//           backgroundColor: green[100],
//           color: green[700]
//         }
//       }
//     }
//   }
// });

//   render() {
//     if (this.state.isActive) {
//       return (
//         <div>
//           <h1>Hello react</h1>
//           <button onClick={this.handleHide}>Hide</button>
//         </div>
//       );
//     } else {
//       return (
//         <div>
//           <button onClick={this.handleShow}>Show</button>
//         </div>
//       );
//     }
//   }
// }

function ScrollableTabsButtonAuto() {
  // eslint-disable-next-line
  const classes = useStyles();
  // eslint-disable-next-line
  const [value, setValue] = React.useState(0);
  // eslint-disable-next-line
  const [openbook, setBook] = useState(false);
  const [subDuration, setSubDuration] = useState('monthly');
  const [duration, setduration] = useState('');
  // eslint-disable-next-line
  const [active, setActive] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [prices, setPrices] = useState(null);
  const [subpackage, setSubPackage] = useState('Economy');
  const [amount, setAmount] = useState(2000);
  const TransactionType = 'MEMBERSHIP';
  const [Plan, setPlan] = useState('');

  const [getprices, setGetPrices] = useState(null);
  // eslint-disable-next-line
  const [isLoading, settIsLoading] = useState(false);

  // eslint-disable-next-line
  const [mytrialcheck, setTrialcheck] = useState(null);

  const storedCurrency = localStorage.getItem('currency');

  // eslint-disable-next-line
  const closePdf = () => {
    setBook(false);
  };

  // eslint-disable-next-line
  const openPdf = () => {
    setBook(true);
  };

  // eslint-disable-next-line
  const openPdf1 = () => {
    window.open(
      'https://vesti-file-uploads.s3.amazonaws.com/Talent+Visa+2.pdf',
      '_blank',
    );
  };

  // eslint-disable-next-line
  const { userData } = useUserData();

  // const planType = userData.planType;

  const [planType, setPlanType] = useState('');
  const [isactive, setIsActive] = useState(false);
  const { search } = useLocation();
  const values = queryString.parse(search);
  var tab = values.tab;
  // const dispatch = useDispatch();

  // eslint-disable-next-line
  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (tab) {
      navigate('/webinar');
    }
  };
  
  // eslint-disable-next-line
  useEffect(() => {
    const fetchPlanType = async () => {
      setIsSuccess(false);

      try {
        const response = await api.get(`pathway/plan-and-join`);
        // console.log(response);
        const myPlan =
          response.data.data.plan === null
            ? null
            : response.data.data.plan.type;

        const dataDuration =
          response.data.data.plan === null
            ? null
            : response.data.data.plan.duration;
        setduration(dataDuration);

        setIsActive(
          response.data.data.plan === null
            ? false
            : response.data.data.plan.active,
        );
        // console.log('Fetched Plan:', myPlan);
        setPlanType(myPlan);
        setIsSuccess(true);
      } catch (error) {
        // console.log(error);
        setIsSuccess(true);
        // setIsSuccess(false);
      }
    };

    fetchPlanType();
  }, [planType]);

  useEffect(() => {
    const fetchPriceData = async () => {
      // setIsSuccess(false);

      try {
        const response = await api.get(`/app-config/list?source=vesti`);
        const myPrice = response?.data?.data?.data;
        setPrices(myPrice);
        // setIsSuccess(false);
      } catch (error) {
        // console.log(error);
        setIsSuccess(true);
        // setIsSuccess(false);
      }
    };

    fetchPriceData();
  }, [planType]);

  useEffect(() => {
    // setIsSuccess(false);
    const fetchpriceData = async () => {
      try {
        const response = await api.get(
          `/vesti-fx/lists?currency=${storedCurrency ? storedCurrency : 'USD'}`,
        );
        const myprice = response?.data?.data;
        setGetPrices(myprice);
        setIsSuccess(true);
      } catch (error) {
        setIsSuccess(true);
        // setIsSuccess(false);
        // console.log(error);
      }
    };

    fetchpriceData();
  }, [storedCurrency]);

  const exchangeRate = getprices?.EXCHANGE_RATE;

  const toWholeCurrency = num =>
    (num / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  const getPrice = name => {
    return prices && toWholeCurrency(prices[`${name}`]);
  };

  return (
    <>
      <Layout>
        {!isSuccess ? (
          <Loader />
        ) : (
          <section className="mainsubscription__section">
            <div className="mainsubscription__sub">
              <div style={{ textAlign: 'center' }}>
                <p className="mainsubscription__title">
                  Choose a membership plan.
                </p>
                <p style={{ fontSize: '13px', color: '#2B5219' }}>
                  Select what plan best suits your interests.
                </p>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',

                  // gap: '20px',
                  border: '1px solid #67A948',
                  width: '200px',
                  margin: '2rem auto',
                  background: '#F7FFF3',
                  borderRadius: '12px',
                  alignItems: 'center',
                }}
              >
                <p
                  // className="ActiveTogglePlan"
                  className={`${
                    subDuration === 'monthly'
                      ? 'ActiveTogglePlan'
                      : 'inActiveTogglePlan'
                  }`}
                  onClick={() => {
                    setSubDuration('monthly');
                  }}
                >
                  Monthly
                </p>
                <p
                  className={`${
                    subDuration === 'yearly'
                      ? 'ActiveTogglePlan'
                      : 'inActiveTogglePlan'
                  }`}
                  onClick={() => {
                    setSubDuration('yearly');
                  }}
                >
                  Annually
                </p>
              </div>

              <div className="mygrid">
                {subscriptionPlan.map(
                  ({
                    id,
                    plan,
                    type,
                    subtitle,
                    monthlyprice,
                    features,
                    dataplanType,
                    img,
                    yearlyprice,
                  }) => {
                    return (
                      <div
                        key={id}
                        className={`${
                          (planType === null && plan === null) ||
                          (planType === plan &&
                            subDuration.toUpperCase() === duration)
                            ? 'mainsubscription__mainplanbox'
                            : 'mainsubscription__box'
                        }`}
                        style={{
                          position: 'relative',
                          overflow: 'hidden',
                          borderRadius: '12px',
                          position: 'relative',
                        }}
                      >
                        <div
                          className={`${
                            (planType === null && plan === null) ||
                            (planType === plan &&
                              subDuration.toUpperCase() === duration)
                              ? 'mysubbgg'
                              : ''
                          }`}
                        >
                          <div className="mainsubscriptiondiv">
                            <div>
                              <div className="recommendedSubPlan">
                                {type === 'First Class' ? (
                                  <p
                                    style={{
                                      color: '#67A948',
                                      fontSize: '13px',
                                      padding: '5px',
                                      border: '1px solid #67A948',
                                      borderRadius: '12px',
                                      textAlign: 'right',
                                      position: 'absolute',
                                      right: '10px',
                                      top: '`10',
                                    }}
                                  >
                                    Recommended
                                  </p>
                                ) : (
                                  <p></p>
                                )}
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={img}
                                  alt="plan"
                                  style={{
                                    background: '#DFFFD0',
                                    padding: '5px',
                                    width: '40px',
                                    height: '40px',
                                    marginRight: '10px',
                                    borderRadius: '10px',
                                  }}
                                />
                                <div style={{ margin: '0.5rem 0' }}>
                                  <p className="mainsubscription__type">
                                    {type}
                                  </p>
                                  <p
                                    className="mainsubscription__mover"
                                    style={{ fontSize: '12px' }}
                                  >
                                    {subtitle}
                                  </p>
                                </div>
                              </div>
                              <div
                                style={{
                                  marginTop: '1rem',
                                  marginBottom: '1rem',
                                  fontSize: '22px',
                                  fontWeight: '600',
                                }}
                              >
                                {subDuration === 'monthly' ? (
                                  <div style={{ display: 'flex', gap: '6px' }}>
                                    <p>
                                      {type === 'Economy'
                                        ? 'FREE'
                                        : `${storedCurrency} 
                                        
                                          ${toWholeCurrency(
                                            getPrice(monthlyprice) *
                                              exchangeRate,
                                          )}
                                          `}
                                    </p>

                                    <div
                                      style={{
                                        marginBottom: '5px',
                                        fontSize: '12px',
                                        fontWeight: '300',
                                        display: 'flex',
                                        alignItems: 'end',
                                      }}
                                    >
                                      {type === 'Economy' ? (
                                        <></>
                                      ) : (
                                        <p
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            display: 'flex',
                                            alignItems: 'end',
                                            color: '#858CA0',

                                            // marginTop: '1rem',
                                            // flexDirection: 'column',
                                          }}
                                        >
                                          /monthly
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div style={{ display: 'flex', gap: '6px' }}>
                                    {/* <p>
                                      {type === 'Economy'
                                        ? 'FREE'
                                        : `$ ${getPrice(yearlyprice)}`}
                                    </p> */}

                                    <p>
                                      {type === 'Economy'
                                        ? 'FREE'
                                        : `${storedCurrency} 
                                        
                                          ${(getPrice(yearlyprice) *
                                            exchangeRate) /
                                            100}
                                          `}
                                    </p>
                                    <div
                                      style={{
                                        marginBottom: '5px',
                                        fontSize: '12px',
                                        fontWeight: '300',
                                        display: 'flex',
                                        alignItems: 'end',
                                      }}
                                    >
                                      {type === 'Economy' ? (
                                        <></>
                                      ) : (
                                        <p
                                          style={{
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            display: 'flex',
                                            alignItems: 'end',
                                            color: '#858CA0',
                                            // marginTop: '1rem',
                                            // flexDirection: 'column',
                                          }}
                                        >
                                          /yearly
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>

                              <ul
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '10px',
                                  marginBottom: '2rem',
                                }}
                              >
                                {features.map((feature, index) => (
                                  <li
                                    key={index}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: '#858CA0',
                                    }}
                                  >
                                    <img
                                      src={tick}
                                      alt=""
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        marginRight: '5px',
                                      }}
                                    />
                                    {feature}
                                  </li>
                                ))}
                              </ul>
                            </div>

                            {type === 'Economy' ? (
                              <></>
                            ) : (
                              <div>
                                {planType === plan &&
                                subDuration.toUpperCase() === duration ? (
                                  <Platformbutton
                                    type="medium"
                                    name={
                                      planType === null
                                        ? 'Free 14 days trial'
                                        : 'Subscribe Now'
                                    }
                                    click={() => {
                                      setOpenModal(true);
                                      setSubPackage(type);
                                      setAmount(
                                        subDuration === 'monthly'
                                          ? getPrice(monthlyprice)
                                          : getPrice(yearlyprice),
                                      );
                                      setPlan(plan);
                                    }}
                                    classname="fas fa-arrow-right"
                                    disabled
                                  />
                                ) : (
                                  <Platformbutton
                                    type="medium"
                                    name={
                                      planType === null
                                        ? 'Free 14 days trial'
                                        : 'Subscribe Now'
                                    }
                                    click={() => {
                                      setOpenModal(true);
                                      setSubPackage(type);
                                      setAmount(
                                        subDuration === 'monthly'
                                          ? getPrice(monthlyprice)
                                          : getPrice(yearlyprice),
                                      );
                                      setPlan(plan);
                                    }}
                                    classname="fas fa-arrow-right"
                                    disabled={isactive}
                                  />
                                )}
                              </div>

                              //   Subscribe Now
                              // </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  },
                )}
              </div>
            </div>
          </section>
        )}
      </Layout>
      <Simplemodal onClick={() => setOpenModal(false)} open={openModal}>
        <SubcriptionPaymentOption
          subpackage={subpackage}
          amount={amount}
          TransactionType={TransactionType}
          subDuration={subDuration}
          Plan={Plan}
          openModal={openModal}
          setOpenModal={setOpenModal}
          planType={planType}
        />
      </Simplemodal>
    </>
  );
}

function CampaignCreate() {
  const classes = useStyles();

  const { userData } = useUserData();

  return (
    <Layout>
      <div className="grid-container">
        <div className={classes.root}>
          <p className="member__title">Create Campaign • 1 of 3</p>
          <p className="member-card__title">
            Hi {`${userData.firstName}`}, who are you crowdfunding for ?
          </p>

          <p></p>

          <div className="campaign-card">
            <div className="image">
              <img src={Selfcare} width="37" height="40" alt="campaign" />
            </div>

            <div className="main">
              <p className="campaign-card__title">
                Are You Crowdfunding For Yourself ?
              </p>
              <p className="date__title">
                Load your card using VESTI wallet easily.
              </p>
            </div>
          </div>

          <p></p>

          <div className="campaign-card">
            <div className="image">
              <img src={Hand} width="37" height="40" alt="campaign" />
            </div>

            <div className="main">
              <p className="campaign-card__title">
                {' '}
                Are You Crowdfunding For Someone Else ?
              </p>
              <p className="date__title">
                {' '}
                Add money in your card using your bank.
              </p>
            </div>
          </div>

          <p className="marginPP">
            <a href="/campaign/detail" className="default-btn">
              Proceed
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
}

function CampaignDetails() {
  const classes = useStyles();

  return (
    <Layout>
      <div className="grid-container">
        <div className={classes.root}>
          <p className="member__title">Campaign Detail • 2 of 3</p>
          <p className="member-card__title ml-4">
            {' '}
            Select a Vesti Immigration plan that is convenient for you.
          </p>

          <div className="search_form_container flex-grow-1">
            <input
              type="text_box"
              name="text_campaign"
              className="campaign_search_control"
              placeholder="Tell a story about the reason why you are creating the campaign"
            />
            <span />
          </div>

          <p className="black__title"> Add a Cover Photo or Video </p>

          <Upload>
            <img
              src={Uploadicon}
              max-width="620"
              max-height="150"
              alt="campaign"
            />
          </Upload>
          <p className="marginPP">
            <a href="/campaign/create" className="backBtn margin30">
              Back
            </a>

            <a href="/campaign/story" className="default-btn">
              Continue
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
}

function CampaignStory() {
  const classes = useStyles();

  return (
    <Layout>
      <div className="grid-container">
        <div className={classes.root}>
          <p className="member__title">Campaign Story • 3 of 3</p>
          <p className="member-card__title ml-4">
            {' '}
            Select a Vesti Immigration plan that is convenient for you.
          </p>

          <div className="search_form_container flex-grow-1">
            <input
              type="text_box"
              name="text_campaign"
              className="campaign_search_control"
              placeholder="Tell a story about the reason why you are creating the campaign"
            />
            <span />
          </div>

          <p className="black__title"> Add a Cover Photo or Video </p>

          <Upload>
            {' '}
            <img
              src={Uploadicon}
              max-width="620"
              max-height="150"
              alt="campaign"
            />
          </Upload>

          <p className="marginPP">
            <a href="/campaign/detail" className="backBtn margin30">
              Back
            </a>

            <a href="/member/exclusive" className="default-btn">
              Finish
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default ScrollableTabsButtonAuto;
export { CampaignCreate, CampaignDetails, CampaignStory };
