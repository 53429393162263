import {
  OPEN_CARD_TOUR_MODAL,
  CLOSE_CARD_TOUR_MODAL,
} from 'appRedux/constants';

// src/reducers.js
// Tourguide reducer
const INIT_STATE = {
  openCardTourModal: false,
};

export default (state = INIT_STATE, action) => {
  const { type } = action;

  switch (type) {
    case OPEN_CARD_TOUR_MODAL:
      return { ...state, openCardTourModal: true };

    case CLOSE_CARD_TOUR_MODAL:
      return { ...state, openCardTourModal: false };
    // Add other cases for closing other modals
    default:
      return state;
  }
};
