import {
  // FETCH_CHAT_DATA_FAILURE,
  // FETCH_CHAT_DATA_SUCCESS,
  FETCH_GROUP_CHATS,
  FETCH_FEEDS_CHATS,
  GET_CHAT_FRIENDID,
  // FETCH_IMAGE_CHATS,
  FETCH_CHAT_LIST,
} from 'appRedux/constants';
import api from 'appRedux/api';

export const fetchChatData = (id, limit = 1000, page = 1) => async dispatch => {
  try {
    const response = await api.get(
      `/chats/fetch?friendId=${id}&limit=${limit}&page=${page}`,
    );
    const data = response.data.data;

    // dispatch((dispatch, getState) => {
    //     const previousChats = getState().Feedschat.chats;
    //     dispatch({
    //         type: FETCH_GROUP_CHATS,
    //         payload: [...previousChats, ...data],
    //     });
    // });

    dispatch({
      type: FETCH_GROUP_CHATS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    // dispatch({
    // type: FETCH_CHAT_DATA_FAILURE,
    // payload: error.message,
    // });
  }
};

export const getFriendsFeedsDetails = (fullname, profilePic) => ({
  type: GET_CHAT_FRIENDID,
  payload: { name: fullname, img: profilePic },
});

// export const fetchImageChat = () => async dispatch => {
//     try {
//         const response = await api.get('/customer/image-upload');
//         const data = response

//         dispatch({
//             type:FETCH_IMAGE_CHATS,
//             payload: data,
//             });
//         } catch (error) {
//             console.log(error);

//         }
// };

// export const fetchChatList = () => async dispatch => {
//     try {
//         const response = await api.get('http://syca-app-backend.eba-pe3mzmfm.us-east-1.elasticbeanstalk.com/api/v1/chats/members');
//         const data = response.data;

//         dispatch({
//         type: FETCH_CHAT_LIST,
//         payload: data,
//         });
//     } catch (error) {
//         console.log(error);
//     }
//     };

export const chatHistoryData = chatId => async dispatch => {
  try {
    const response = await api.get(`/chats/members`);
    const data = response.data.data;

    console.log(data);

    dispatch({
      type: FETCH_CHAT_LIST,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    //   dispatch({
    //     type: FETCH_CHAT_DATA_FAILURE,
    //     payload: error.message,
    //   });
  }
};

export const addNewFeedsChats = value => ({
  type: FETCH_FEEDS_CHATS,
  payload: value,
});
