import { SET_CALCULATED_INTEREST } from 'appRedux/constants';


const initialState = {
 calculatedInterest: 0,
 interestRatePercentage: 0,
};


export default (state = initialState, action) => {
 switch (action.type) {
   case SET_CALCULATED_INTEREST:
     return {
       ...state,
       calculatedInterest: action.payload.calculatedInterest,
       interestRatePercentage: action.payload.interestRatePercentage,
     };


   default:
     return state;
 }
};



