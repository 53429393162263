import { OPEN_DESC, OPEN_STAGES,HANDLE_O1 } from 'appRedux/constants';

export const openDescBox = () => ({
    type: OPEN_DESC,
});

export const openStagesBox = () => ({
    type: OPEN_STAGES,
});

export const openO1Quiz = (value) => ({
    type: HANDLE_O1,
    payload:{o1Quiz:value}
});