import React, { useEffect, useState } from 'react';
import { Section1 } from './section1';
import { Section2 } from './section2';
import { Section3 } from './section3';
import { Section4 } from './section4';
import { Section5 } from './section5';
import { Section6 } from './section6';
import { Section7 } from './section7';
import { Section8 } from './section8';
import { Section9 } from './section9';
import { Section10 } from './section10';
import { Section11 } from './section11';
import { Section12 } from './section12';
import { Section13 } from './section13';
import { Section14 } from './section14';
import { Section15 } from './section15';
import { useDispatch, useSelector } from 'react-redux';
import { fetchViasForms } from 'appRedux/actions/visaForm';
import { updateUploadedFiles, handleSections } from 'appRedux/actions/visaForm';

export const Fullsection = () => {
  const [visaType, setVisaType] = useState('');
  const storedvisaType = useSelector(state => state.visaForm.visaAbbr);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const Forms = useSelector(state => state.visaForm.submittedForms);
  const userD = useSelector(state => state.auth?.userData);
  const pathname = window.location.pathname;
  const splitLocation = pathname.split('/');

  useEffect(() => {
    if (splitLocation[2] === '0-1visa' && !storedvisaType) {
      setVisaType('0-1');
    } else if (splitLocation[2] === '0-1A' && !storedvisaType) {
      setVisaType('0-1');
    } else if (splitLocation[2] === '0-1B' && !storedvisaType) {
      setVisaType('0-1B');
    } else if (splitLocation[2] === '0-1B') {
      setVisaType('0-1B');
    } else if (splitLocation[2] === 'EB-1visa' && !storedvisaType) {
      setVisaType('EB-1');
    } else if (splitLocation[2] === 'EB-2NIWvisa' && !storedvisaType) {
      setVisaType('EB-2 NIW');
    } else if (splitLocation[2] === 'H1Bvisa' && !storedvisaType) {
      setVisaType('H1B');
    } else if (splitLocation[2] === 'B-1B-2visa' && !storedvisaType) {
      setVisaType('B-1/B-2');
    } else if (splitLocation[2] === 'F1visa' && !storedvisaType) {
      setVisaType('F1');
    } else if (storedvisaType) {
      setVisaType(storedvisaType);
    }
  }, []);

  // const uploadedFiles = useSelector(state => state.visaForm.uploadedFiles);

  //       dispatch(updateUploadedFiles({}));
  useEffect(() => {
    dispatch(
      fetchViasForms(userD?.id, userD?.email, visaType, userD?.firstName),
    );
  }, [visaType]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <div>
        <p style={{ color: '#67A948', fontWeight: '600', fontSize: '18px' }}>
          Required Section
        </p>
        <p style={{ color: '#2B5219', fontSize: '13px', fontWeight: '600' }}>
          All documents in this section are required and must be uploaded to
          proceed.
        </p>
      </div>

      <Section1
        Forms={Forms}
        fetchViasForms={fetchViasForms}
        loading={loading}
        setLoading={setLoading}
        userD={userD}
        visaType={visaType}
        updateUploadedFiles={updateUploadedFiles}
        handleSections={handleSections}
      />
      <Section2
        Forms={Forms}
        fetchViasForms={fetchViasForms}
        loading={loading}
        setLoading={setLoading}
        userD={userD}
        visaType={visaType}
        updateUploadedFiles={updateUploadedFiles}
      />

      <Section4
        Forms={Forms}
        fetchViasForms={fetchViasForms}
        loading={loading}
        setLoading={setLoading}
        userD={userD}
        visaType={visaType}
        updateUploadedFiles={updateUploadedFiles}
      />

      <Section12
        Forms={Forms}
        fetchViasForms={fetchViasForms}
        loading={loading}
        setLoading={setLoading}
        userD={userD}
        visaType={visaType}
        updateUploadedFiles={updateUploadedFiles}
      />
      <Section13
        Forms={Forms}
        fetchViasForms={fetchViasForms}
        loading={loading}
        setLoading={setLoading}
        userD={userD}
        visaType={visaType}
        updateUploadedFiles={updateUploadedFiles}
      />
      <Section14
        Forms={Forms}
        fetchViasForms={fetchViasForms}
        loading={loading}
        setLoading={setLoading}
        userD={userD}
        visaType={visaType}
        updateUploadedFiles={updateUploadedFiles}
      />
      <Section15
        Forms={Forms}
        fetchViasForms={fetchViasForms}
        loading={loading}
        setLoading={setLoading}
        userD={userD}
        visaType={visaType}
        updateUploadedFiles={updateUploadedFiles}
      />

      <Section9
        Forms={Forms}
        fetchViasForms={fetchViasForms}
        loading={loading}
        setLoading={setLoading}
        userD={userD}
        visaType={visaType}
        updateUploadedFiles={updateUploadedFiles}
      />

      <Section11
        Forms={Forms}
        fetchViasForms={fetchViasForms}
        loading={loading}
        setLoading={setLoading}
        userD={userD}
        visaType={visaType}
        updateUploadedFiles={updateUploadedFiles}
      />
      <div style={{ marginTop: '2rem' }}>
        <p
          style={{
            color: '#67A948',
            fontWeight: '600',
            fontSize: '18px',
          }}
        >
          Optional Evidence Section (complete any 3)
        </p>
        <p style={{ color: '#2B5219', fontSize: '13px', fontWeight: '600' }}>
          Strengthen your application by adding documents to at least 3 of these
          optional sections.
        </p>
      </div>

      {/*  */}
      <Section3
        Forms={Forms}
        fetchViasForms={fetchViasForms}
        loading={loading}
        setLoading={setLoading}
        userD={userD}
        visaType={visaType}
        updateUploadedFiles={updateUploadedFiles}
      />

      <Section5
        Forms={Forms}
        fetchViasForms={fetchViasForms}
        loading={loading}
        setLoading={setLoading}
        userD={userD}
        visaType={visaType}
        updateUploadedFiles={updateUploadedFiles}
      />
      <Section6
        Forms={Forms}
        fetchViasForms={fetchViasForms}
        loading={loading}
        setLoading={setLoading}
        userD={userD}
        visaType={visaType}
        updateUploadedFiles={updateUploadedFiles}
      />
      <Section7
        Forms={Forms}
        fetchViasForms={fetchViasForms}
        loading={loading}
        setLoading={setLoading}
        userD={userD}
        visaType={visaType}
        updateUploadedFiles={updateUploadedFiles}
      />
      <Section8
        Forms={Forms}
        fetchViasForms={fetchViasForms}
        loading={loading}
        setLoading={setLoading}
        userD={userD}
        visaType={visaType}
        updateUploadedFiles={updateUploadedFiles}
      />

      <Section10
        Forms={Forms}
        fetchViasForms={fetchViasForms}
        loading={loading}
        setLoading={setLoading}
        userD={userD}
        visaType={visaType}
        updateUploadedFiles={updateUploadedFiles}
      />
    </div>
  );
};
