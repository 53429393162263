import React, { useState } from 'react';
import {
  formatterUSD,
  getCardRate,
  getCurrency,
  getCurrencyName,
  removeCommaAmount,
} from 'helpers/utils';
import { Platformbutton } from 'components/common/button/button';
// import errorsvg from "assets/error-2.svg"
import { Success } from 'components/common/success/success';
import { Amountinputcurrency } from 'components/common/inputs/amountinput';
import { useAmount } from 'helpers/hooks';
import { connect, useSelector } from 'react-redux';
import { Depositmodesv2 } from 'components/deposit/depositmodes';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Equivalence } from '../equivalence';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Currencyconv } from '../currencyconv';
import {
  localDepositViaCard,
  localDepositViaCardOther,
} from 'appRedux/actions/wallets';
import _ from 'lodash';
import { AlertError } from 'components/common/alertboxes/alertboxes';

const Depositfund = props => {
  const [step, setStep] = useState(0);
  const { amount, handleAmount } = useAmount(0);
  const [option, setOption] = useState('');
  const userWallet = useSelector(state => state.wallets.wallets, _.isEqual);
  console.log(props);

  const setDepositOption = value => {
    setOption(value);
  };

  var depositProvidus = () => {
    setStep(2);
  };

  var depositGhBank = () => {
    setStep(2);
  };

  var depositZMBank = () => {
    setStep(2);
  };

  var depositKENBank = () => {
    setStep(2);
  };

  switch (step) {
    case 0:
      return (
        <Depositmodesv2
          option={option}
          depositPaystack={props.depositViaPays}
          depositProvidus={depositProvidus}
          depositGhBank={depositGhBank}
          depositZMBank={depositZMBank}
          depositKENBank={depositKENBank}
          setDepositOption={setDepositOption}
          setStep={setStep}
          amount={amount}
          cb={props.cb}
          currency={props.name}
          userData={props.userData}
          userWallets={userWallet}
          continue={() => setStep(1)}
        />
      );
    case 1:
      return (
        <>
          <Titlesubtitle
            steps={` Step 1`}
            title={`Add Money (${getCurrencyName(props.name)})`}
            subtitle="Enter the amount you want to add."
          />
          <div className="energy">
            <Amountinputcurrency
              type="text"
              currency={getCurrency(getCurrencyName(props.name))}
              name="amount"
              label="Amount"
              value={amount === 'NaN' ? 0 : amount.toLocaleString('en-US')}
              disabled={false}
              placeholder="Enter amount to add"
              onChange={handleAmount}
              pattern="[0-9,.]*"
            />
            <span className="mt-4 mb-8"></span>
            {removeCommaAmount(amount) > 0 && (
              <Equivalence
                first="total amount you get"
                second=" "
                amount={''}
                equal={
                  `${props.name.toLocaleUpperCase()}` +
                  formatterUSD.format(
                    removeCommaAmount(amount) -
                      getCardRate(removeCommaAmount(amount), props.name),
                  )
                }
              />
            )}
          </div>
          {props.name === 'zmw' || props.name === 'ZMW' ? (
            <div>
              {removeCommaAmount(amount) > 0 &&
                removeCommaAmount(amount) < 15 && (
                  <AlertError
                    body={
                      removeCommaAmount(amount) < 15
                        ? `Amount should not be less than 15 Kwacha.`
                        : `You do not have upto ZMW${amount} in your Vesti Kwacha wallet, please deposit into your account.`
                    }
                  />
                )}
              <Backcontinue goBack={() => setStep(0)}>
                <Platformbutton
                  name="Continue"
                  type="normal"
                  disabled={
                    removeCommaAmount(amount) < 15 || amount === 'NaN'
                      ? true
                      : false
                  }
                  click={() =>
                    props.localDepositViaCardOther(
                      {
                        currency: props.name.toLocaleUpperCase(),
                        amount: removeCommaAmount(amount) * 100,
                        charges:
                          removeCommaAmount(
                            formatterUSD.format(
                              +getCardRate(
                                removeCommaAmount(amount),
                                props.name,
                              ),
                            ),
                          ) * 100,
                      },
                      () => props.closeModal(),
                    )
                  }
                />
              </Backcontinue>
            </div>
          ) : props.name === 'kes' || props.name === 'KES' ? (
            <div>
              {removeCommaAmount(amount) > 0 &&
                removeCommaAmount(amount) < 15 && (
                  <AlertError
                    body={
                      removeCommaAmount(amount) < 15
                        ? `Amount should not be less than 15 Kenya Shillings.`
                        : `You do not have upto KES${amount} in your Vesti Kenya Shillings wallet, please deposit into your account.`
                    }
                  />
                )}
              <Backcontinue goBack={() => setStep(0)}>
                <Platformbutton
                  name="Continue"
                  type="normal"
                  disabled={
                    removeCommaAmount(amount) < 15 || amount === 'NaN'
                      ? true
                      : false
                  }
                  click={() =>
                    props.localDepositViaCard(
                      {
                        currency: props.name.toLocaleUpperCase(),
                        amount: removeCommaAmount(amount) * 100,
                        charges:
                          removeCommaAmount(
                            formatterUSD.format(
                              +getCardRate(
                                removeCommaAmount(amount),
                                props.name,
                              ),
                            ),
                          ) * 100,
                      },
                      () => props.closeModal(),
                    )
                  }
                />
              </Backcontinue>
            </div>
          ) : props.name === 'gbp' || props.name === 'GBP' ? (
            <div>
              {removeCommaAmount(amount) > 0 &&
                (removeCommaAmount(amount) > props.gbpbalance ||
                  removeCommaAmount(amount) < 5) && (
                  <AlertError
                    body={
                      removeCommaAmount(props.amount) < 5
                        ? `Amount should be greater than 5 Pounds.`
                        : `You do not have upto £${props.amount} in your Vesti Pounds wallet, please deposit into your account.`
                    }
                  />
                )}
              <Backcontinue goBack={() => setStep(0)}>
                <Platformbutton
                  name="Continue"
                  type="normal"
                  // disabled={removeCommaAmount(amount) < 5 ? true : false}
                  click={() =>
                    props.localDepositViaCardOther(
                      {
                        currency: props.name.toLocaleUpperCase(),
                        amount: removeCommaAmount(amount) * 100,
                        charges:
                          removeCommaAmount(
                            formatterUSD.format(
                              +getCardRate(
                                removeCommaAmount(amount),
                                props.name,
                              ),
                            ),
                          ) * 100,
                      },
                      () => props.closeModal(),
                    )
                  }
                />
              </Backcontinue>
            </div>
          ) : (
            <div>
              {removeCommaAmount(amount) > 0 &&
                removeCommaAmount(amount) < 100 && (
                  <AlertError
                    body={
                      removeCommaAmount(amount) < 100
                        ? `Amount should not be less than 100 ${getCurrencyName(
                            props.name,
                          )}.`
                        : ``
                    }
                  />
                )}
              <Backcontinue goBack={() => setStep(0)}>
                <Platformbutton
                  name="Continue"
                  type="normal"
                  disabled={
                    removeCommaAmount(amount) < 100 || amount === 'NaN'
                      ? true
                      : false
                  }
                  click={() =>
                    props.localDepositViaCard(
                      {
                        currency: props.name.toLocaleUpperCase(),
                        amount: removeCommaAmount(amount) * 100,
                        charges:
                          removeCommaAmount(
                            formatterUSD.format(
                              +getCardRate(
                                removeCommaAmount(amount),
                                props.name,
                              ),
                            ),
                          ) * 100,
                      },
                      () => props.closeModal(),
                    )
                  }
                />
              </Backcontinue>
            </div>
          )}
        </>
      );
    case 2:
      return (
        <>
          <Titlesubtitle
            title={`Add Money (${getCurrencyName(props.name)})`}
            subtitle={`Add money to your ${getCurrencyName(props.name)} wallet`}
          />
          <Currencyconv goBack={() => setStep(0)} continue={() => setStep(3)} />
        </>
      );

    case 3:
      return (
        <Success
          type="Successful"
          title="Transaction Successful"
          subtitle={props.transMessage}
          button="Try Again"
          onClick={() => setStep(1)}
        />
      );
    default:
      return <> </>;
  }
};

const mapStateToProps = ({ transactions }) => {
  const { transLoading, transMessage } = transactions;
  return {
    transLoading,
    transMessage,
  };
};

const mapDispatchToProps = {
  localDepositViaCard,
  localDepositViaCardOther,
};

export default connect(mapStateToProps, mapDispatchToProps)(Depositfund);
