import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { H1bStage2 } from './stage2/h1bStage2';
import { H1bStage1 } from './stage1/h1bStage1';
import { navigate, useLocation } from '@reach/router';
import { updatePrevLocation } from 'appRedux/actions/location';
import { useSelector, useDispatch } from 'react-redux';
import { fetchViasForms } from 'appRedux/actions/visaForm';
import { fetchUserData } from 'appRedux/actions/profile';
import FloatingButton from 'components/common/floatingbutton/FloatingButton';

export const H1b = () => {
  const dispatch = useDispatch();
  const [stage, setsStage] = useState(0);
  const userD = useSelector(state => state.auth?.userData);
  const Forms = useSelector(state => state.visaForm.submittedForms);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.pathname]);

  useEffect(() => {
    // dispatch(fetchViasForms(userD?.id, userD?.email, 'H1B', userD?.firstName));
    dispatch(fetchViasForms(userD?.id, userD?.email, 'H1B', userD?.firstName));
    setLoading(true);
  }, []);

  const EventHandler = event => {
    // Check if the message status indicates payment success
    if (event.data.status === 'mainstack-payment-success') {
      // Your logic here for successful payment
      navigate('/pathways/H1Bvisa/registration');
      // console.log('Payment was successful!');
      // You can perform actions like redirecting the user, updating the UI, etc.
    }
    // else {
    //   Handle other messages or statuses if needed

    //   console.log('');
    // }
  };

  // Add the event listener to listen for messages
  window.addEventListener('message', EventHandler, false);

  // useEffect(() => {
  //   setsStage(Forms[0]?.formStage);
  // }, [loading]);

  const location = useLocation();
  const [previousPath, setPreviousPath] = useState();

  useEffect(() => {
    setPreviousPath(location.pathname); // Store current pathname on mount.
  }, [location]);

  const handleSetPreviousPath = id => {
    dispatch(updatePrevLocation(previousPath));
    navigate(`/chat`);
    localStorage.setItem('petitionId', Forms[0]?.id);
    localStorage.setItem('petitionAdminId', Forms[0]?.adminId[0]);
  };

  return (
    <div>
      {Forms[0]?.formStage == 2 ? (
        <H1bStage2 userD={userD} />
      ) : (
        <H1bStage1 userD={userD} />
      )}

      <FloatingButton
        shape="circle"
        type="default"
        title="Miai (beta)"
        onClick={() => {
          handleSetPreviousPath();
        }}
      />
    </div>
  );
};
