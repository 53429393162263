import React, { useState } from 'react';
import './Edu.scss';
import '../../components/website/ovabout/Ovabout.scss';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Singleselect } from 'components/common/inputs/singleselect';
import { Phonenumber } from 'components/common/inputs/phoneinput';
import { RadioOption } from 'components/common/radiobutton/radiobutton';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Platformbutton } from 'components/common/button/button';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { useStep } from 'helpers/hooks';
import axios from 'axios';
import errorsvg from 'assets/oops.svg';
import { Success } from 'components/common/success/success';
import { navigate } from "@reach/router";

const config = {
  cors: 'https://cors-anywhere.herokuapp.com/',
  formUrl:
    'https://docs.google.com/forms/d/19-pflQSfHd1A3ifErTdC0oep3xmIGZMY5JicwlpIONk/formResponse',
};

const Edu = props => {
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    location: '',
    attendance: '',
    studyCountry: '',
    others: '',
    studyProgram: '',
    visaInterest: '',
    session: '',
  });
  var ids = [
    '1397028257',
    '143527682',
    '1344378505',
    '1516927993',
    '249237474',
    '1955379329',
    '1153621378',
    '52748672',
    '72288764',
    '1249265721',
    '803669653',
  ];
  const { step, setStep, nextStep, previousStep } = useStep(0);

  var setInput = e => {
    var name = e.target.name;
    var value = e.target.value;
    setState({ ...state, [name]: value });
    //console.log(value);
  };
  var handleSelect = (name, value) => {
    setState({ ...state, [name]: value });
  };

  var attend = value => {
    setState({ ...state, attendance: value });
    //console.log(value);
  };
  var program = value => {
    setState({ ...state, studyProgram: value });
    //console.log(value);
  };
  var session = value => {
    setState({ ...state, session: value });
    //console.log(value);
  };
  var interest = value => {
    setState({ ...state, visaInterest: value });
    //console.log(value);
  };

  var __renderStep = () => {
    switch (step) {
      case 0:
        return (
          <Stepone
            data={state}
            setInput={setInput}
            handleSelect={handleSelect}
            countries={props.countries}
            click={nextStep}
            setState={setState}
            state={state}
          />
        );
      case 1:
        return (
          <Steptwo
            name="Continue"
            click={nextStep}
            back={previousStep}
            setState={setState}
            state={state}
            setInput={setInput}
            attend={attend}
            program={program}
          />
        );
      case 2:
        return (
          <Stepthree
            name="Submit"
            click={() => submit()}
            back={previousStep}
            setState={setState}
            state={state}
            interest={interest}
            session={session}
          />
        );
      case 3:
        return (
          <Success
            title="Form submitted successfully"
            subtitle="Congratulations, You are all set for the Vesti international education fair"
            button="Thank you!"
            onClick={() => {
              navigate('/');
            }}
          />
        );
      case 4:
        return (
          <Success
            image={errorsvg}
            type="error"
            title="Bummer, Form submission failed"
            subtitle="Please try again"
            button="Close"
            onClick={() => {
              setStep(0);
            }}
          />
        );
      default:
        return <></>;
    }
  };

  var submit = async () => {
    const formData = new FormData();
    var list = Object.keys(state);
    for (var key in state) {
      const value = Array.isArray(state[key])
        ? JSON.stringify(state[key])
        : state[key];
      // eslint-disable-next-line
      value.label
        ? formData.append(
            // eslint-disable-next-line
            `entry.${
              ids[
                // eslint-disable-next-line
                list.findIndex(function(item) {
                  return item === key;
                })
              ]
            }`,
            value.label,
          )
        : formData.append(
            `entry.${
              ids[
                // eslint-disable-next-line
                list.findIndex(function(item) {
                  return item === key;
                })
              ]
            }`,
            value,
          );
    }

    await axios({
      url: `${config.formUrl}`,
      method: 'post',
      data: formData,
      responseType: 'json',
    })
      .then(res => setStep(3))
      .catch(error => setStep(3));
  };

  return (
    <div className="educontainer">
      <div className="edu-inner">
        <div className="edu-form">
          {step < 3 && (
            <Titlesubtitle
              steps={` ${step + 1} of 3 `}
              title="Vesti International Education Fair"
              subtitle="Register to attend the upcoming Vesti International Education Fair 2023"
            />
          )}
          <div className="mb-4"></div>
          {__renderStep()}
        </div>
      </div>
    </div>
  );
};
export default Edu;

const Stepone = props => {
  return (
    <div className="edustep">
      <form className="edustep__col">
        <div className="edustep__row">
          <Inputfloat
            type="text"
            label="First Name"
            name="firstName"
            placeholder="John"
            value={props.data.firstName}
            disabled={false}
            onChange={props.setInput}
          />
          <Inputfloat
            type="text"
            label="Last Name"
            name="lastName"
            placeholder="Doe"
            value={props.data.lastName}
            disabled={false}
            onChange={props.setInput}
          />
        </div>
        <div className="edustep__row">
          <Inputfloat
            type="email"
            label="Email Address"
            name="email"
            placeholder="Johndoe@gmail.com"
            value={props.data.email}
            disabled={false}
            onChange={props.setInput}
          />
          <Singleselect
            label="Location"
            value={props.data.location}
            options={[
              { label: 'Ajah', value: 'Ajah' },
              { label: 'Lekki', value: 'Lekki' },
              { label: 'Victoria Island', value: 'Victoria Island' },
              { label: 'Ikoyi', value: 'Ikoyi' },
              { label: 'Obalende', value: 'Obalende' },
              { label: 'Yaba', value: 'Yaba' },
              { label: 'Onipanu', value: 'Onipanu' },
              { label: 'Palmgroove', value: 'Palmgroove' },
              { label: 'Ilupeju', value: 'Ilupeju' },
              { label: 'Oshodi-Isolo', value: 'Oshodi-Isolo' },
              { label: 'Ikeja', value: 'Ikeja' },
              { label: 'Ikotun', value: 'Ikotun' },
              { label: 'Festac', value: 'Festac' },
              { label: 'Ojo', value: 'Ojo' },
              { label: 'Ikorodu', value: 'Ikorodu' },
              { label: 'Iyana-Ipaja', value: 'Iyana-Ipaja' },
              { label: 'Berger', value: 'Berger' },
              { label: 'Gbagada', value: 'Gbagada' },
              { label: 'Bariga', value: 'Bariga' },
              { label: 'Somolu', value: 'Somolu' },
              { label: 'Epe', value: 'Epe' },
              { label: 'Apapa', value: 'Apapa' },
              { label: 'Agege', value: 'Agege' },
              { label: 'Surulere', value: 'Surulere' },
              { label: 'Others', value: 'Others' },
            ]}
            onChange={value => {
              //console.log(value.value);
              props.handleSelect('location', value);
            }}
            placeholder="Select your Location "
          />
        </div>
        <Phonenumber
          value={props.data.phoneNumber}
          country={props.data.country?.value}
          setValue={value =>
            props.setState({ ...props.state, phoneNumber: value })
          }
        />
        <div className="edustep__row">
          <Singleselect
            label="Country of Study"
            value={props.data.studyCountry}
            options={[
              { label: 'United States', value: 'United States' },
              { label: 'United Kingdom', value: 'United Kingdom' },
              { label: 'Canada', value: 'Canada' },
              { label: 'Australia', value: 'Australia' },
              { label: 'Mauritius', value: 'Mauritius' },
              { label: 'Others', value: 'Others' },
            ]}
            onChange={value => {
              props.handleSelect('studyCountry', value);
            }}
            placeholder="Country of Study"
          />
          <Inputfloat
            type="text"
            label="Others"
            name="others"
            placeholder="If others, specify"
            value={props.data.others}
            disabled={false}
            onChange={props.setInput}
          />
        </div>

        <div className="edustep__btns">
          <Platformbutton
            name="Continue"
            type="normal"
            disabled={
              props.data.firstName !== '' &&
              props.data.lastName !== '' &&
              props.data.email !== '' &&
              props.data.location !== '' &&
              props.data.phoneNumber !== '' &&
              props.data.studyCountry !== ''
                ? false
                : true
            }
            click={() => props.click()}
          />
        </div>
      </form>
    </div>
  );
};

const Steptwo = props => {
  return (
    <div className="edustep">
      <div className="edustep__form">
        <div className="edustep__bcol">
          <div className="edustep__col">
            <p>Would be willing to come for an Education Fair by Vesti?</p>
            <div className="edustep__scol">
              <RadioOption
                changed={props.attend}
                id="1"
                isSelected={props.state.attendance === 'Yes'}
                label="Yes"
                value={'Yes'}
              />
              <RadioOption
                changed={props.attend}
                id="2"
                isSelected={props.state.attendance === 'No'}
                label="No"
                value={'No'}
              />
            </div>
          </div>
        </div>
        <div className="edustep__bcol">
          <div className="edustep__col">
            <p>What study program?</p>
            <div className="edustep__scol">
              <RadioOption
                changed={props.program}
                id="3"
                isSelected={props.state.studyProgram === 'Undergraduate'}
                label="Undergraduate"
                value={'Undergraduate'}
              />
              <RadioOption
                changed={props.program}
                id="4"
                isSelected={props.state.studyProgram === 'Postgraduate'}
                label="Postgraduate"
                value={'Postgraduate'}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="edustep__btns">
        <Backcontinue goBack={() => props.back()}>
          <Platformbutton
            name={props.name}
            type="normal"
            disabled={
              props.state.studyProgram !== '' && props.state.attendance !== ''
                ? false
                : true
            }
            click={() => props.click()}
          />
        </Backcontinue>
      </div>
    </div>
  );
};

const Stepthree = props => {
  return (
    <div className="edustep">
      <div className="edustep__form">
        <div className="edustep__bcol">
          <div className="edustep__col">
            <p>Asides Study Visa, do you have any other visa of interest?</p>
            <div className="edustep__scol">
              <div className="edustep__scol">
                <RadioOption
                  changed={props.interest}
                  id="5"
                  isSelected={props.state.visaInterest === 'Yes'}
                  label="Yes"
                  value={'Yes'}
                />
                <RadioOption
                  changed={props.interest}
                  id="6"
                  isSelected={props.state.visaInterest === 'No'}
                  label="No"
                  value={'No'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="edustep__bcol">
          <div className="edustep__col">
            <p>What session would you prefer attending?</p>
            <div className="edustep__scol">
              <div className="edustep__scol">
                <RadioOption
                  changed={props.session}
                  id="7"
                  isSelected={
                    props.state.session === 'Morning Session 9am -12noon'
                  }
                  label="Morning Session 9am -12noon"
                  value={'Morning Session 9am -12noon'}
                />
                <RadioOption
                  changed={props.session}
                  id="8"
                  isSelected={
                    props.state.session === 'Afternoon Session 1pm - 4pm'
                  }
                  label="Afternoon Session 1pm - 4pm"
                  value={'Afternoon Session 1pm - 4pm'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="edustep__btns">
        <Backcontinue goBack={() => props.back()}>
          <Platformbutton
            name={props.name}
            type="normal"
            disabled={
              props.state.visaInterest !== '' && props.state.session !== ''
                ? false
                : true
            }
            click={() => props.click()}
          />
        </Backcontinue>
      </div>
    </div>
  );
};
