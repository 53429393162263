import React from 'react';
import './Ovbanner.scss';
import usmap from '../../../assets/usmap.png';
import { Platformbutton } from 'components/common/button/button';
import { openLink } from 'helpers/utils';
export const Ovbanner = props => {
  return (
    <div className="ovbanner-container">
      <div className="ovbanner-inner-container">
        <div className="ovbanner-inner-container left">
          <div className="top">
            <p>
              O-1 Visa: Individuals with <span>Extraordinary Ability</span>  or <span>Achievement</span> 
              
            </p>
            <p>
              Start your O-1 visa journey today with our cutting-edge platform
              and expert support
            </p>
          </div>
          <div className="ov-bottom">
            {/* <button >See if you Qualify</button> */}
            <Platformbutton
              name="Speak to an Attorney"
              type="secondary"
              click={() => openLink('https://calendly.com/vesti/founderpaid')}
            />
            <Platformbutton
              name="See if you Qualify"
              type="normal"
              click={() => props.click()}
            />
          </div>
        </div>
        <div className="ovbanner-inner-container right">
          <img src={usmap} alt="Banner SVG" />
        </div>
      </div>
    </div>
  );
};
