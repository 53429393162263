import React from 'react';
import { useLocation, useParams } from '@reach/router';
import { navigate } from '@reach/router';
import {
  CanadaCohort5,
  socialProof,
  GeneralGroupImg,
  USCohort5,
} from 'assets/feedPageAssets/index';
import '../../Admin.css';
import { useSelector } from 'react-redux';
import api from 'appRedux/api';
import Loader from 'components/Loader';
import { useState, useRef, useEffect } from 'react';
import { About, Feeds, Members, Events } from '../generalgroupIndex.js';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import GroupsPost from './groupsPost';
import OtherGroupsMembers from './otherGroupsMembers';
import '../../../../index.css'
import { BiSolidMessageRoundedDots } from 'react-icons/bi';

const groups = [
  { id: 1, name: 'Group A', details: 'Details for Group A' },
  { id: 2, name: 'Group B', details: 'Details for Group B' },
];

const OthergrouppathwayFeeds = (props) => {
  const [groupData, setGroupData] = useState([]);
  const [about, setAbout] = useState(false);
  const [feeds, setFeeds] = useState(true);
  const [members, setMembers] = useState(false);
  const [events, setEvents] = useState(false);
  const [isloading, setIsloading] = useState(false);

  const userD = useSelector(state => state.auth?.userData);
  // console.log(userD);
  const { id } = useParams();
  useEffect(() => {
    setIsloading(true);
    const fetchData = async () => {
      try {
        const response = await api.get(`/group/all-groups`);
        const data = await response.data.data.data;
        setGroupData(data);
        setIsloading(false);
      } catch (error) {
        // Handle error
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  // console.log(groupData);

  const group = groupData.find(group => group.id === id);
  const groupId = group?.id;
  // console.log(group?.id);
  //  console.log(groupData.map((group)=>{group.id === id ? console.log('yeahh'):console.log('naa');}));

  const handleAbout = () => {
    setAbout(true);
    setEvents(false);
    setMembers(false);
    setFeeds(false);
  };

  const handleFeeds = () => {
    setAbout(false);
    setEvents(false);
    setMembers(false);
    setFeeds(true);
  };

  const handleEvent = () => {
    setAbout(false);
    setEvents(true);
    setMembers(false);
    setFeeds(false);
  };

  const handleMembers = () => {
    setAbout(false);
    setEvents(false);
    setMembers(true);
    setFeeds(false);
  };
  var onScroll = () => {
    // const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
    const scrollTop = scrollRef.current.scrollTop;
    setScroll(scrollTop);
  };
  const [scroll, setScroll] = useState();
  const scrollRef = useRef(null);

  const imageStyle = {
    height: 'auto',
    imageRendering: 'pixelated', // Prevent image blurriness
  };

  if (!group) {
    return <div>Group not found</div>;
  }

  const handleChatIconClick = (userId) => {
    navigate (`/chatList`)
}

  return (
    <section className="pt-2 px-4 w-full" style={{ overflowX: 'hidden' }}>
      {isloading ? (
        <Loader />
      ) : (
        <div
          className=" mb-2 rounded-md bg-white w-full overflow-y-scroll vesti-main-feed"
          ref={scrollRef}
          onScroll={() => onScroll()}
          style={{ position: 'relative', overflowX: 'hidden' }}
        >
          <div className=" bg-white">
            <img
              src={group.image}
              className="w-full"
              style={imageStyle}
              alt=""
            />

            <>
              <article
                className="groupPathwayArticleAstn cohort"
                // style={{ paddingRight: '3rem', paddingLeft: '3rem' }}
              >
                <div className='MoveGrowthSee'>
                  <div className="flex justify-between">
                  <h2 style={{ fontSize: '24px', fontWeight: '600' }}>
                    {group.name}
                  </h2>

                  <p
                      className="flex flex-col justify-center myfeedspageactionmenubtm"
                      style={{
                        width: '40px',
                        height: '40px',
                        fontSize: '20px',
                        background: '#66A848',
                        color: '#fff',
                        // zIndex: '50',
                        borderRadius: '100%',
                      }}
                    >
                      <BiSolidMessageRoundedDots className="mx-auto"  onClick={() => {
                        handleChatIconClick(props.userId);
                      }}/>
                    </p>
                  </div>

                  <p
                    className=""
                    style={{
                      color: '#4C5366',
                      fontWeight: '300',
                      fontSize: '14px',
                    }}
                  >
                    {group.description}
                  </p>
                  <img src={socialProof} alt="" />
                </div>
              </article>

              <div
                className=" border bg-white border-red-600 w-full rounded-md py-6  d-flex justify-between items-center "
                style={{
                  paddingRight: '3rem',
                  paddingLeft: '3rem',
                  fontSize: '18px',
                  fontWeight: '600',
                }}
              >
                <p
                  className={`${
                    about
                      ? 'bg-white flex flex-col cursor-pointer'
                      : 'cursor-pointer'
                  }`}
                  onClick={handleAbout}
                >
                  About{' '}
                  <>
                    {about ? (
                      <span className="bg-vesti-700 px-6 py-1 rounded-lg mt-"></span>
                    ) : (
                      ''
                    )}
                  </>
                </p>

                <p
                  className={`${
                    feeds
                      ? 'bg-white flex flex-col cursor-pointer'
                      : 'cursor-pointer'
                  }`}
                  onClick={handleFeeds}
                >
                  Feeds{' '}
                  <>
                    {feeds ? (
                      <span className="bg-vesti-700 px-6 py-1 rounded-lg mt-1"></span>
                    ) : (
                      ''
                    )}
                  </>
                </p>
                <p
                  className={`${
                    members
                      ? 'bg-white flex flex-col cursor-pointer'
                      : 'cursor-pointer'
                  }`}
                  onClick={handleMembers}
                >
                  Members{' '}
                  <>
                    {members ? (
                      <span className="bg-vesti-700 px-6 py-1 rounded-lg"></span>
                    ) : (
                      ''
                    )}
                  </>
                </p>
                <p
                  className={`${
                    events
                      ? 'bg-white flex flex-col cursor-pointer'
                      : 'cursor-pointer'
                  }`}
                  onClick={handleEvent}
                >
                  Webinars{' '}
                  <>
                    {events ? (
                      <span className="bg-vesti-700 px-6 py-1 rounded-lg"></span>
                    ) : (
                      ''
                    )}
                  </>
                </p>
              </div>
            </>
          </div>

          {about && <About />}
          {feeds && <GroupsPost groupId={groupId} />}
          {members && (
            <div>
              <OtherGroupsMembers groupId={groupId} />
            </div>
          )}
          {events && <Events />}
        </div>
      )}
    </section>
  );
};

export default OthergrouppathwayFeeds;
