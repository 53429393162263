import React from 'react';
import './security.scss';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Referal, Referralbox } from 'components/referal/referal';
import { requestReferral } from 'appRedux/actions/profile';
import { Platformbutton } from 'components/common/button/button';
import { connect } from 'react-redux';
const Referrals = props => {
  return (
    <div className="ref">
      <div className="ref__inner">
        <Titlesubtitle title="Referrals" />

        <div className="ref__bottom">
          <Referal
            title="Copy Referal Link"
            link={props.userdata.ReferalLink}
          />
          <div className="ref__box">
            <Referralbox
              totalReferred={props.userdata?.totalRefferedInNumber}
              ReferralAmount={props.userdata?.totalRefferralBonus / 100}
            />
            <div className="mb-2"></div>
            {/* <Shortinfo subject="You must have up to ₦ 5,000 in referral bonuses to claim your referral bonus."/> */}
          </div>
          <div className="ref__btn">
            <Platformbutton
              disable={''}
              name="Request to Claim Bonus"
              type="normal"
              click={() => props.requestReferral()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ common }) => {
  const { countries, states } = common;
  return {
    countries,
    states,
  };
};

const mapDispatchToProps = {
  requestReferral,
};

export default connect(mapStateToProps, mapDispatchToProps)(Referrals);
