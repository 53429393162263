import React, { useState } from 'react';
import upgradeIcon from '../../../assets/pathways/patthwaysub/pathwayUpgradesub.svg';
import './pathwaysub.scss';
import { navigate } from '@reach/router';
import { Largemodal } from 'components/common/simplifiedmodal';
import MembershipPlan from '../Streaming/memberSubcription';

const PathwaysubSteps = () => {
  const [openSub, setOpenSub] = useState(false);
  return (
    <div>
      <Largemodal onClick={() => setOpenSub(false)} open={openSub}>
        <MembershipPlan />
      </Largemodal>
      <div className="upgradesubmodal">
        <img
          style={{ margin: '0px auto', width: '160px' }}
          src={upgradeIcon}
          alt="Upgrade"
        />

        <div className="my-4 text-center">
          <p style={{ color: '#67A948', fontSize: '24px', fontWeight: '200' }}>
            Upgrade Membership plan
          </p>
          <p style={{ color: '#14290A', fontSize: '14px', fontWeight: '200' }}>
            Upgrade to an higher plan to access this content.
          </p>
        </div>

        <p
          style={{
            background: '#67A948',
            textAlign: 'center',
            padding: '15px 0px',
            borderRadius: '10px',
            color: '#fff',
            cursor: 'pointer',
          }}
          onClick={() => {
            setOpenSub(true);
            // navigate(`/webinar`);
          }}
        >
          Upgrade Membership Plan
        </p>
      </div>
    </div>
  );
};

export default PathwaysubSteps;
