import React, { useState, useEffect } from 'react';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Platformbutton } from 'components/common/button/button';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import {
  RadioOption,
  NewVerySmallRadioOption,
} from 'components/common/radiobutton/radiobutton';
import { Success } from 'components/common/success/success';
import successPng from 'assets/physicalCard/newsuccess.png';
import errorsvg from 'assets/newerror.svg';
import api from 'appRedux/api';
import promptBanner from 'assets/viasForm/visaprompt.svg';
import { useSelector } from 'react-redux';
import pathwayconfig from 'config.js';

export const ReportForm = props => {
  const userD = useSelector(state => state.auth?.userData);

  const [faqs, setFaqs] = useState(false);
  const [userIssue, setUserIssue] = useState('');
  const [otheruserIssue, setOtherUserIssue] = useState('');
  const [userIssueDesc, setUserIssueDesc] = useState('');
  const [step, setStep] = useState(0);

  const handleUserIssues = value => {
    setUserIssue(value);
    setFaqs(false);
  };

  const handleOtherUserIssues = e => {
    setOtherUserIssue(e.target.value);
  };

  const handleUserIssuesDesc = e => {
    setUserIssueDesc(e.target.value);
  };

  const handleReportIssue = async () => {
    const payload = {
      formId: props.formId,
      issueType: otheruserIssue ? otheruserIssue : userIssue,
      issueTitle: otheruserIssue ? otheruserIssue : userIssue,
      issueDescription: userIssueDesc,
      email: userD?.email,
      fullName: userD?.firstName + ' ' + userD?.lastName,
    };

    const url = `${pathwayconfig.baseURL}/pathway/report-document-upload-issues`;

    try {
      const res = await api.post(url, payload);

      console.log(res);
      setStep(1);
    } catch (error) {
      setStep(2);
      console.log(error);
    }
  };

  switch (step) {
    case 0:
      return (
        <div style={{}}>
          <div
            style={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              right: '0px',
              width: '100%',
            }}
          >
            <img src={promptBanner} alt="" />
          </div>
          <div style={{ marginTop: '12rem' }}></div>
          <p
            style={{
              color: '#14290A',
              fontWeight: '600',
              fontSize: '24px',
            }}
          >
            Report Issue
          </p>
          <p style={{ color: '#2B5219', fontWeight: '500' }}>
            Kindly let us know what issue you are experiencing and we will do
            our best to make sure a solution is provided
          </p>
          <div style={{ marginBottom: '20px' }}></div>

          <div
            style={{
              width: '100%',
            }}
          >
            <NewVerySmallRadioOption
              image={''}
              changed={handleUserIssues}
              id="1"
              isSelected={userIssue === 'Unable to upload my file'}
              label="Unable to upload my file"
              sublabel=""
              value="Unable to upload my file"
            />
            <div style={{ marginBottom: '10px' }}></div>
            <NewVerySmallRadioOption
              image={''}
              changed={handleUserIssues}
              id="2"
              isSelected={userIssue === 'Having issue making payment'}
              label="Having issue making payment"
              sublabel=""
              value="Having issue making payment"
            />
            <div style={{ marginBottom: '10px' }}></div>
            <NewVerySmallRadioOption
              image={''}
              changed={handleUserIssues}
              id="3"
              isSelected={userIssue === 'Unable to submit my document'}
              label="Unable to submit my document"
              sublabel=""
              value="Unable to submit my document"
            />
            <div style={{ marginBottom: '10px' }}></div>
            <NewVerySmallRadioOption
              image={''}
              changed={handleUserIssues}
              id="4"
              isSelected={userIssue === 'None'}
              label="Another Issue"
              sublabel=""
              value="None"
            />
          </div>

          <div style={{ marginBottom: '20px' }}></div>

          <div>
            <div style={{ marginBottom: '10px' }}></div>
            <Inputfloat
              label="DESCRIBE THE ISSUE"
              type="text"
              name="DESCRIBE THE ISSUE"
              value={userIssueDesc}
              disabled={false}
              placeholder="Describe the issue you’re experiencing"
              onChange={handleUserIssuesDesc}
              invalid={'true'}
              error={''}
            >
              <div className="mb-[10px]"></div>
            </Inputfloat>

            <div style={{ marginBottom: '10px' }}></div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
              }}
              className="Visaflexbutton"
            >
              <Platformbutton
                type="secondary"
                disabled={false}
                name={'Cancel'}
                click={() => {
                  props.setReportModal(false);
                }}
              />
              <Platformbutton
                type="normal"
                disabled={userIssue && userIssueDesc ? false : true}
                name={'Send report'}
                click={() => {
                  handleReportIssue();
                }}
              />
            </div>
          </div>
        </div>
      );

    case 1:
      return (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <img src={successPng} alt="" />
          </div>

          <div className="">
            <p
              style={{
                color: '#62943B',
                fontSize: '20px',
                fontWeight: '600',
                textAlign: 'center',
              }}
            >
              Submitted Successfully
            </p>
            <p
              style={{
                color: '#2B5219',
                fontSize: '13px',
                textAlign: 'center',
              }}
            >
              Issue has been reported successfully
            </p>
          </div>
          <div style={{ marginBottom: '10px' }}></div>

          <div style={{ width: '100%' }}>
            <Platformbutton
              type="normal"
              name={'Okay, Thank you'}
              click={() => {
                props.setReportModal(false);
              }}
            />
          </div>
        </div>
      );
    case 2:
      return (
        <Success
          image={errorsvg}
          title={'Error'}
          subtitle={'Error occured'}
          onClick={() => setStep(0)}
          button="Try Again"
          type="error"
        />
      );
  }
};
