import React from 'react';
import Layout from 'components/common/DashboardLayout';
import api from 'appRedux/api';
import { useState, useEffect } from 'react';
import './aboutpathway.scss';
import { useLocation } from '@reach/router';
import { TiTick } from 'react-icons/ti';
import { Singlepathway } from 'components/pathway/singlepathway';
import { Empty } from 'components/common/empty/empty';
import Loader from 'components/Loader';
// import { fundUsdViaNgn, stripeUsdViaCard } from 'appRedux/actions/transactions';
import { connect, useDispatch } from 'react-redux';
import { userId } from './index';

import {
  openStagesBox,
  openDescBox,
  openO1Quiz,
} from 'appRedux/actions/pathway';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { Newprompt } from 'components/common/prompt/prompt';
import {
  Smalltitle,
  Titlesubtitle,
} from 'components/common/titlesubtitle/titlesubtitle';
import subs from 'assets/subscription.svg';
import { errorMessage, shuffleArray } from 'helpers/utils';
import sevisserv from 'assets/sevisserv.png';
// import USA from 'assets/USA.png';
import usdepartment from 'assets/usdepartment.png';

import tef from 'assets/tef.jpeg';
import ielts from 'assets/ielts.png';

import wesservice from 'assets/wesservice.png';
import ukvisa from 'assets/ukvisa.png';
// import dummy from 'assets/dummy-avatar.png';
// import pathwayusers from 'assets/pathways/pathwayusers.svg';
// import Slider from 'react-slick';
// import { useQuery } from "react-query";
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import { Success } from 'components/common/success/success';
// import { Smallbox } from "components/common/smallbox/smallbox";
import { useUserData } from 'helpers/hooks';
// import ReactTimeAgo from "react-time-ago";
// import { NotifIco } from 'assets/assets';

// import {
//   Growthprogresscommunity,
//   Growthcommunity,
// } from 'containers/Admin/Pathways/growthcommunity';

import { navigate } from '@reach/router';
import { Platformbutton } from 'components/common/button/button';
import {
  // Pathdays,
  // Pathusers,
  Pathsubscribers,
  GrowthMembers,
  Userusingpath,
} from 'components/pathway/pathwayscomp';

import { PathwayCard } from 'containers/Admin/Pathways/index';

import { fetchProfiles } from 'appRedux/actions/profile';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { GiProgression } from 'react-icons/gi';
import { BiSolidTimeFive } from 'react-icons/bi';
// import { openUpgradeBox } from 'appRedux/actions/update';

// import { videoDataPathay } from 'containers/Admin/Pathways/index';

// import { Tab } from "components/tab/tab";

const PathwaysJourney = props => {
  // const [select , setSelect] =useState({
  //     description:true,
  //     stages: false
  // })

  const { state } = useLocation();
  const {
    // id = '',
    // description = '',
    logo = '',
    title = '',
    // creator = '',
    // pathwayCost = '',
    // stagesObject = '',
    country = '',
    // month = '',
    pathwayVideo = '',
  } = state || {};
  const [loading, setLoading] = useState(true);
  const [myPathway, setMyPathway] = useState({});
  const [myPathways, setMyPathways] = useState({});
  const [subscribe, setSub] = useState({
    value: false,
    step: 0,
  });
  const [active, setActive] = useState(0);
  const [modal, setModal] = useState(false);
  const [profile, setProfile] = useState([]);
  const dispatch = useDispatch();
  const { userData } = useUserData();
  const [success, setSuccess] = useState(false);
  const [groupData, setGroupData] = useState([]);

  const [newPercentage, setNewPercentage] = useState(0);

  const pathwayStages = myPathway.pathwayStages;

  // //console.log("myPathway", myPathway);

  const pathwayProgress = myPathway?.pathwayProgress;

  //console.log('pathwayProgress', pathwayProgress);

  const residenceCountry = userData?.country;

  // const status = pathwayProgress.status;
  // testing
  const [clickedIndex, setClickedIndex] = useState(null);

  const initialPassedStages = new Array(pathwayStages?.length).fill(false);
  const [passedStages, setPassedStages] = useState(initialPassedStages);

  // Function to handle clicking on a specific stage
  const handlePassesStageClick = function(index) {
    // Create a copy of the passedStages array
    const newPassedStages = [...passedStages];
    // Toggle the clicked stage's state
    newPassedStages[index] = !newPassedStages[index];
    // Update the state with the new array
    setPassedStages(newPassedStages);

    setClickedIndex(index);

    const percentage = calculatePercentage(index).toFixed(1);
    //console.log("Clicked Percentage 2", percentage);
    setNewPercentage(percentage);

    // call update progress endpoint
    //{{base-url}}/pathway/updatePathwayProgress

    //    const payloadUpdateProgress =  {

    //     pathwayTemplateId: pathwayTemplateId,

    //     newPathwayProgress :
    //            {
    //                percentage: percentage,
    //                stagesCompleted: index,
    //                month: index,
    //                day: (index) * 10,
    //                pathwayId: pathwayId,
    //                userId: userId,
    //                status: "InProgress"
    //            }

    // };

    //    api
    //    .post(`/pathway/updatePathwayProgress`, payloadUpdateProgress, 'pathway')
    //    .then(res => {
    //      openNotificationWithIcon(
    //        res.message,
    //        'Pathway Subscription',
    //        'success',
    //      );
    //      setSub({ ...subscribe, step: 1 });
    //    })
    //    .catch(err => {
    //      openNotificationWithIconErr(
    //        errorMessage(err),
    //        'Pathway Subscription',
    //        'error',
    //      );

    //      setTimeout(() => {
    //        setSub({ ...subscribe, value: false });
    //      }, 1500);
    //    });
  };

  const calculatePercentage = index => {
    if (clickedIndex === null) return 0;
    return ((index + 1) / pathwayStages.length) * 100;
  };

  const merchantData = [
    {
      name: 'SEVIS FEE',
      description: 'Easily Pay your SEVIS fees without hassle',
      price: 'SEVIS',
      image: sevisserv,
    },

    {
      name: 'WES FEE',
      description: ' Easily Pay your WES fees without hassle',
      price: 'WES',
      image: wesservice,
    },

    {
      name: 'US VISA',
      description: 'Easily Pay your USA VISA without hassle',
      price: 'USVISADS160',
      image: usdepartment,
    },

    {
      name: 'IELTS',
      description: 'Easily Pay your IELTS without hassle',
      price: 'IELTS',
      image: ielts,
    },

    {
      name: 'TEF',
      description: 'Easily Pay your TEF without hassle',
      price: 'TEF',
      image: tef,
    },

    {
      name: 'UK STUDENT',
      description: 'Easily Pay your UK VISA without hassle',
      price: 'UKSTUDENTVISA',
      image: ukvisa,
    },

    // ...
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/group/all-groups`);
        const data = response.data.data.data;
        setGroupData(data);
      } catch (error) {
        // Handle error
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const getRandomItemsgroupData = () => {
    const shuffled = groupData.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 2);
  };

  const getRandomItemsmyPathways = () => {
    const shuffled = myPathways.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 2);
  };

  const getRandomItemsMerchantData = () => {
    const shuffled = merchantData.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 2);
  };

  const dayspent = 2;
  // localStorage.getItem('pathway_days_spent');

  const payload = {
    pathwayTemplateId: props.id,

    userId: userId,
  };

  var subscribeToPathway = () => {
    api
      .post(`/pathway/subscribe`, payload, 'pathway')
      .then(res => {
        openNotificationWithIcon(
          res.message,
          'Pathway Subscription',
          'success',
        );
        setSub({ ...subscribe, step: 1 });
      })
      .catch(err => {
        openNotificationWithIconErr(
          errorMessage(err),
          'Pathway Subscription',
          'error',
        );

        setTimeout(() => {
          setSub({ ...subscribe, value: false });
        }, 1500);
      });
  };

  var closeSub = () => {
    setSub({ step: 0, value: false });
  };
  useEffect(() => {
    props.fetchProfiles();
  }, []);
  useEffect(() => {
    setLoading(true);
    api
      .get(
        `/pathway/active/search?limit=100&pathwayId=${props.id}`,
        null,
        'pathway',
      )
      .then(res => {
        setMyPathway(res.data.data.data[0]);
        console.log(res.data.data.data[0].pathwayStages);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    api
      .get(`/pathway/active/search`, null, 'pathway')
      .then(res => {
        setLoading(false);
        setMyPathways(res.data.data.data);
        setSuccess(true);
      })
      .catch(err => err);
  }, []);

  let number = 1;
  const data = [
    {
      firstName: 'Adewole',
      lastName: 'Philemon',
      email: 'aphilemon.aa@gmail.com',
      username: 'blanconero',
    },
    {
      firstName: 'Seyi',
      lastName: 'Otun',
      email: 'seyifotun@gmail.com',
      username: 'seyiotun',
    },
    {
      firstName: 'Afolayan',
      lastName: 'Samuel ',
      email: 'toluafo67@gmail.com',
      username: 'teetat',
    },
  ];

  const [monthsRemaining, setMonthsRemaining] = useState(0);

  // const [daySpent, setDaySpent] = useState(0);

  const [seePercentage, setPercentage] = useState(0);

  const createdAt = '2023-06-01T00:00:00.000Z';

  var shuffled =
    props.profiles.data && shuffleArray(props.profiles.data).slice(0, 3);

  // //console.log("asdsad" +JSON.stringify(myPathway))
  if (loading || !props.profiles) {
    return (
      <Layout>
        <div className="aboutpathway">
          <div className="aboutpathway__inner">
            <div className="aboutpathway__content">
              <Loader />
            </div>
          </div>
        </div>
      </Layout>
    );
  } else if (props.id === ':id') {
    return (
      <Layout>
        <div className="about-pathway-container">
          <div className="aboutpathway-inner">
            <Empty
              title="Pathway does not exist"
              subtitle="The Pathway you are looking for does not exist."
              link={() => navigate('/pathways')}
              name="Back to Pathways"
            />
          </div>
        </div>
      </Layout>
    );
  } else {
    const percentage = pathwayProgress?.percentage;

    const monthPathway = myPathway?.month;

    const day = pathwayProgress?.day;

    return (
      <Layout>
        <Simplemodal
          onClick={() => setSub({ step: 0, value: false })}
          visible={subscribe.value}
        >
          <Subscription
            title={title}
            cost={myPathway.pathwayCost}
            onClick={subscribeToPathway}
            subscribe={subscribe}
            closeSub={closeSub}
          />
        </Simplemodal>

        <Simplemodal onClick={() => setModal(false)} visible={modal}>
          <Userusingpath
            id={active}
            data={props.profiles.data ? profile : data}
          />
        </Simplemodal>

        <div className="pathwayprogresscontainer">
          <div className="navigation d-flex flex-row">
            <div
              className="back_container_pathway"
              onClick={() => navigate(-1)}
              // onClick={() => navigate(`/pathways`)}
            >
              <div className="icon-and-titles">
                <i className="fas fa-long-arrow-alt-left"></i>
                {/* {title} */}
              </div>
            </div>
            <Titlesubtitle
              title={
                residenceCountry
                  ? `Your Journey from ${residenceCountry} to ${country}`
                  : `Your Journey to ${country}`
              }
              subtitle={``}
            />
          </div>

          <div className="progress-aboutpathway">
            {/* Left div */}
            <div className="leftpathway-container">
              <div
                className="back_container_pathway"
                onClick={() => navigate(`/pathways`)}
              >
                <div className="icon-and-titles">
                  <i className="fas fa-long-arrow-alt-left"></i>
                  {title}
                </div>
              </div>
              <div className="progress-wrapper">
                <p className="stage-header"> Your Progress</p>
                <div className="timespentcontainer">
                  <div className="duration-time">
                    <div>
                      {' '}
                      <b className="circle-white">. </b> DURATION
                    </div>

                    <div>
                      {' '}
                      <b className="circle-white">. </b> TIME SPENT
                    </div>
                  </div>

                  <div className="duration-time-value">
                    <div> {`${myPathway.duration}  ${myPathway.unit}`} </div>

                    <div> {dayspent} Day(s) </div>
                  </div>
                </div>

                <div className="percentagecontainer">
                  <div>
                    <div>
                      {' '}
                      <b className="circle-white">. </b> PROGRESS
                    </div>
                  </div>

                  {/* <div className="progress-percentage">{seePercentage} %</div> */}

                  <div className="progress-percentage"> {newPercentage} %</div>

                  <div>
                    The best time to start your migration journey is now
                  </div>
                </div>
              </div>

              <div className="aboutpathway-inner">
                <div
                  className="back_container_pathway"
                  onClick={() => navigate(`/pathways`)}
                >
                  <div className="icon-and-titles">
                    <i className="fas fa-long-arrow-alt-left"></i>
                    {title}
                  </div>
                </div>

                <div className="aboutpathway-content">
                  {props.desc && (
                    <div
                      className={`aboutpathway-description ${
                        props.desc ? ' active' : ' '
                      }`}
                    >
                      {/* <Titlesubtitle
                        title={
                          residenceCountry
                            ? `Your Journey from ${residenceCountry} to ${country}`
                            : `Your Journey to ${country}`
                        }
                        subtitle={``}
                      /> */}

                      <div className="aboutpathway-imgbox">
                        <video controls width="100%" height="auto">
                          <source src={`${pathwayVideo}`} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>

                      <div className="daysbutton_container">
                        <div className="left_daysbutton">
                          <Pathsubscribers
                            data={props.profiles.data ? shuffled : data}
                            click={value => {
                              setActive(value);
                              setProfile(
                                shuffled.filter(item => item.id === value)[0],
                              );
                              setModal(true);
                            }}
                          />
                        </div>

                        <div className="aboutpathway__claim daysbutton">
                          {title.includes('Alien') && (
                            <div className="seequalify">
                              <Platformbutton
                                type="secondary"
                                name="See if you Qualify"
                                click={() => dispatch(openO1Quiz(true))}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="aboutpathway__bottom">
                        {/* <p className="bottom-title">{title}</p> */}
                        <Smalltitle title={title} />
                        <p className="aboutpathway__bcontent">
                          {myPathway.description}
                        </p>
                        {/* {userSub
                          ? userSub.paymentStatus && (
                              <Smallbox>
                                <img src={NotifIco} alt="Notification" />
                                <p>
                                  You are on a <strong> trial period </strong>{' '}
                                  this will end in{' '}
                                  <strong>
                                    {' '}
                                    <ReactTimeAgo
                                      date={Date.parse(
                                        parseDate(
                                          userSub.expirationDate,
                                          'yyyy/mm/dd',
                                        ),
                                      )}
                                      locale="twitter"
                                    />
                                  </strong>{' '}
                                  time,
                                  <br /> and you will be debited after that.
                                </p>
                              </Smallbox>
                            )
                          : ''} */}

                        <div className="stage-header"> Procedures </div>

                        {pathwayStages && (
                          <div className="content-stages-bottom">
                            {pathwayStages.length < 1 ? (
                              <Empty
                                title="No Stages"
                                subtitle="No Stages for this Pathway, if stages were available, it will be visible here."
                              />
                            ) : (
                              <>
                                {pathwayStages.map(
                                  (
                                    {
                                      title: stageTitle,
                                      description,
                                      action,
                                      id,
                                    },
                                    index,
                                  ) => {
                                    //console.log('newPercentage', newPercentage);
                                    return (
                                      <Singlepathway
                                        pathway={title}
                                        key={id}
                                        id={id}
                                        value={id}
                                        tick={() =>
                                          handlePassesStageClick(index)
                                        } // Pass the index of the clicked stage
                                        passedStage={passedStages[index]} // Use the individual passedStage state for each stage
                                        percentage={newPercentage}
                                        number={
                                          passedStages[index] ? (
                                            <p
                                              style={{
                                                color: '#67a948',
                                                fontSize: '40px',
                                              }}
                                            >
                                              <TiTick />
                                            </p>
                                          ) : (
                                            <p></p>
                                          )
                                        }
                                        title={stageTitle}
                                        description={description}
                                        actions={action}
                                        logo={logo}
                                      />
                                    );
                                  },
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {props.stages && (
                    <div className="content-stages-bottom">
                      {myPathway.pathways.length < 1 ? (
                        <Empty
                          title="No Stages"
                          subtitle="No Stages for this Pathway, if stages were available, it will be visible here."
                        />
                      ) : (
                        <>
                          {/* {userSub
                            ? userSub.paymentStatus && (
                                <Smallbox>
                                  <img src={NotifIco} alt="Notification" />
                                  <p>
                                    You are on a <strong> trial period </strong>{' '}
                                    this will end in{' '}
                                    <strong>
                                      {' '}
                                      <ReactTimeAgo
                                        date={Date.parse(
                                          parseDate(
                                            userSub.expirationDate,
                                            'yyyy/mm/dd',
                                          ),
                                        )}
                                        locale="twitter"
                                      />
                                    </strong>{' '}
                                    time,
                                    <br /> and you will be debited after that.
                                  </p>
                                </Smallbox>
                              )
                            : ''} */}

                          {myPathway.map(
                            ({ title: stageTitle, description, action }) => {
                              return (
                                <Singlepathway
                                  pathway={title}
                                  key={props.id}
                                  id={props.id}
                                  number={null}
                                  // number={number++}
                                  title={stageTitle}
                                  description={description}
                                  actions={action}
                                  logo={logo}
                                />
                              );
                            },
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* right div */}
            <div className="rightpathway-container">
              <div className="progress-wrapper">
                <p className="stage-header"> Your Progress</p>
                <div className="timespentcontainer">
                  <div className="duration-time">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '16px',
                      }}
                    >
                      {' '}
                      {/* <b className="circle-white"> </b> */}
                      <IoMdCheckmarkCircle
                        style={{ fontSize: '16px', marginRight: '2px' }}
                      />
                      DURATION
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '16px',
                      }}
                    >
                      {' '}
                      <BiSolidTimeFive
                        style={{ fontSize: '16px', marginRight: '2px' }}
                      />{' '}
                      TIME SPENT
                    </div>
                  </div>

                  <div
                    className="duration-time-value"
                    // style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div style={{ fontSize: '16px' }}>
                      {`${myPathway.duration}  ${myPathway.unit}`}
                    </div>

                    <div style={{ fontSize: '16px' }}> {dayspent} Day(s) </div>
                  </div>
                </div>

                <div className="percentagecontainer px-3">
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {' '}
                      <GiProgression
                        style={{
                          fontSize: '16px',
                          marginRight: '5px',
                          border: '1px solid white',
                          padding: '2px',
                          borderRadius: '5px',
                        }}
                      />{' '}
                      PROGRESS
                    </div>
                  </div>
                  <div className="progress-percentage"> {newPercentage} %</div>

                  <div>
                    The best time to start your migration journey is now
                  </div>
                </div>

                {/* <div className="percentagecontainer">
                  <div>
                    <div>
                      {' '}
                      <b className="circle-white">. </b> PROGRESS
                    </div>
                  </div>

                  <div className="progress-percentage"> {newPercentage} %</div>

                  <div>
                    The best time to start your migration journey is now
                  </div>
                </div> */}
              </div>

              <div className="growth-community">
                {' '}
                <div className="stage-header"> Growth Communities </div>
                <div> Go fast with others on the journey </div>
                {(getRandomItemsgroupData() ?? []).map((item, index) => (
                  <div className="growth-progress">
                    <div className="growth-progress-inner" key={index}>
                      <div className="growth-title">
                        <div className="growth-title-progress">{item.name}</div>
                        <div className="growth-plan-progress"> PAID </div>
                      </div>

                      <div className="groupmember-percentage">
                        <GrowthMembers
                          data={props.profiles.data ? shuffled : data}
                          click={value => {
                            setActive(value);
                            setProfile(
                              shuffled.filter(item => item.id === value)[0],
                            );
                            setModal(true);
                          }}
                        />
                      </div>

                      <div className="join-nowbtn">
                        <Platformbutton
                          type="normal"
                          name="Join Now"
                          click={() => {
                            navigate('/feeds');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="">
                  <Platformbutton
                    type="seeall"
                    name="See All Communities"
                    click={() => {
                      navigate('/feeds');
                    }}
                  />
                </div>
              </div>

              <div className="">
                {' '}
                <div className="growth-community">
                  {' '}
                  <div className="stage-header"> Migration Payments </div>
                  <div> Go fast with others on the journey </div>
                  <div className="merchant-progress">
                    <div className="">
                      {getRandomItemsMerchantData().map((item, index) => (
                        <div key={index} className="merchant-box">
                          <img
                            src={item.image}
                            alt="profile"
                            style={{
                              height: '60px',
                              width: '60px',
                              borderRadius: '50%',
                            }}
                            className="d-block bg-dark mb-1"
                          />
                          <div className="text-uppercase mb-3">{item.name}</div>
                          <p className="text-center mb-3">{item.description}</p>

                          <div className="pay-nowbtn">
                            <Platformbutton
                              type="normal"
                              name="Pay Now"
                              click={() => {
                                navigate('/merchants');
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="">
                  <Platformbutton
                    type="seeall"
                    name="See All Payments"
                    click={() => {
                      navigate('/merchants');
                    }}
                  />
                </div>
              </div>

              <div className="">
                {' '}
                <div className="growth-community">
                  {' '}
                  <div className="stage-header"> Explore More Pathways </div>
                  <div> Select and start your journey </div>
                  <div
                    className="pathway-roll mt-4"
                    style={{ gap: '30px', marginBottom: '50px' }}
                  >
                    {loading && <Loader />}
                    {success && (
                      <>
                        {getRandomItemsmyPathways().map(
                          ({
                            id,
                            logo,
                            title,
                            description,
                            stagesObject,
                            creator,
                            pathwayCost,
                            country,
                            month,
                          }) => (
                            <PathwayCard
                              key={id}
                              {...{
                                id,
                                logo,
                                title,
                                description,
                                stagesObject,
                                creator,
                                pathwayCost,
                                country,
                                month,
                              }}
                            />
                          ),
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="">
                <Platformbutton
                  type="seeall"
                  name="See All Pathways"
                  click={() => {
                    navigate('/allpathways');
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
};

const Subscription = props => {
  const [check, setCheck] = useState(false);

  switch (props.subscribe.step) {
    case 0:
      return (
        <Newprompt img={subs}>
          <Titlesubtitle
            title="Claim your 30days subscription"
            subtitle={`You are about to subscribe for this ${props.title}, it is free for thirty days after that, you will be debited $${props.cost} from your Vesti wallet, click this check to accept our terms and condition`}
          />

          <div className="pathwaysubscription">
            <label className="form-control">
              <input
                type="checkbox"
                name="checkbox"
                onChange={() => setCheck(!check)}
                checked={check}
              />
              I agree to Vesti’s terms & conditions.
            </label>
            <button
              className="pathwaysubscription__button"
              onClick={() => props.onClick()}
            >
              Yes, I Want to Subscribe
            </button>
          </div>
        </Newprompt>
      );
    case 1:
      return (
        <Success
          title="Subscription Successful"
          subtitle="You just claimed your fee trial subscription for this pathway."
          button="Okay, Thank You."
          onClick={() => props.closeSub()}
        />
      );
    default:
      return <>Nothing to see </>;
  }
};

const mapStateToProps = ({ pathway, profile }) => {
  const { desc, stages } = pathway;
  const { profiles } = profile;
  return {
    desc,
    stages,
    profiles,
  };
};

export default connect(mapStateToProps, {
  openDescBox,
  openStagesBox,
  fetchProfiles,
})(PathwaysJourney);
