import React from 'react';
import playstore from '../../../assets/playstore.svg';
import apple from '../../../assets/apple.svg';
import './downloadvesti.scss';
import free from '../../../assets/free.png';
import { Download } from '../banner/download';
export const Downloadvesti = () => {
  return (
    <section className="download-vesti-container">
      <div className="download-vestii-inner">
        <div className="download-vestii-inner vesti-left">
          <p className="vesti-color2 fs-1">
            Download the Vesti <br /> App and begin your <br />{' '}
            <span>Immigration journey</span>
          </p>
          <div className="downloadlinks">
            <Download
              image={playstore}
              title="Google Play"
              link="https://play.google.com/store/apps/details?id=com.vesti.app"
            />
            <Download
              image={apple}
              title="Apple Store"
              link="https://apps.apple.com/ca/app/vesti-app/id1564444402"
            />
          </div>
        </div>
        <div className="download-vestii-inner right">
          <img src={free} alt="free" />
        </div>
      </div>
    </section>
  );
};
