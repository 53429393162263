import api from 'appRedux/api';

import {
  FETCH_TRANSACTIONS_REQUEST,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_FAILURE,
} from 'appRedux/constants';

// export const fetchTransactionsSuccess = () => dispatch => {
//   dispatch(fetchTransactionsRequest());

//   api
//     .get(`/transactions/user`)
//     .then(res => {
//       const filteredTransactions = res.data?.data.filter(item => {
//         if (
//           item.type === 'WITHDRAWAL_FROM_WALLET' &&
//           item.description === 'Global Geng Physical Naira Card Debit'
//         ) {
//           return true;
//         } else {
//           return !item.status.includes('PENDING');
//         }
//       });

//       dispatch(
//         fetchTransactionsSuccess(
//           filteredTransactions,
//           res.data?.pagination?.totalPages,
//         ),
//       );
//     })
//     .catch(error => {
//       dispatch(fetchTransactionsFailure(error));
//     });
// };

export const fetchGGTransactionsSuccess = () => {
  return dispatch => {
    dispatch({ type: FETCH_TRANSACTIONS_REQUEST });
    const url = '/transactions/user?&limit=300';
    api
      .get(url)
      .then(response => {
        const data = response.data.data;
        const filteredTransactions = data?.filter(item => {
          return (
            (item.description.includes('Global Geng') &&
              !item.status.includes('PENDING')) ||
            item.type.includes('WITHDRAWAL')
          );
        });

        return dispatch({
          type: FETCH_TRANSACTIONS_SUCCESS,
          payload: filteredTransactions,
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_TRANSACTIONS_FAILURE, payload: error });
      });
  };
};
