import {
  MIAI_TEST,
  FETCH_CHAT_DATA_FAILURE,
  FETCH_CHAT_DATA_SUCCESS,
  SET_LAST_CHAT_ID,
  CHAT_HISTORY,
  MIAI_INPUT_FIELD,
} from 'appRedux/constants';
import api from 'appRedux/api';
// import { MIAI_CLOSE } from 'appRedux/constants';

export const miaitest = value => ({
  type: MIAI_TEST,
  payload: { openBox: value },
});

export const setmiaiInputText = text => ({
  type: MIAI_INPUT_FIELD,
  payload: text,
});

export const fetchChatData = () => async dispatch => {
  try {
    const response = await api.get('/bot/history');
    const data = response.data;

    dispatch({
      type: FETCH_CHAT_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_CHAT_DATA_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchChatId = () => async dispatch => {
  try {
    const response = await api.get('/bot/history');
    const data = response.data.data.data.data[0].id;

    dispatch({
      type: SET_LAST_CHAT_ID,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_CHAT_DATA_FAILURE,
      payload: error.message,
    });
  }
};

export const chatHistoryData = chatId => async dispatch => {
  try {
    const response = await api.get(`/bot/provider-history/${chatId}`);
    const data = response.data.data.chat_data;
    dispatch({
      type: CHAT_HISTORY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_CHAT_DATA_FAILURE,
      payload: error.message,
    });
  }
};
