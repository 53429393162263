import React from 'react';
import { Fullsection } from './eb1FormSections/section';

export const Eb1form = () => {
  return (
    <div>
      <Fullsection />
    </div>
  );
};
