import React from 'react';
import './MiaiIndex.scss';
import MiaiAvatar from 'assets/miai/Miaiellipse.svg';
import Interactive from 'assets/miai/interactiveQa.svg';
import DocCheck from 'assets/miai/document-list-check.svg';
import chart from 'assets/miai/presentation-chart-line.svg';
import migrationIcon from 'assets/miai/migrationIcon.svg';

const MiaiIntroduction = props => {
  return (
    <div className="introduction">
      <div className="introduction__introHeader">
        <p
          className={` ${
            props.isDarkMode ? 'darkModecolor' : 'lightModecolor'
          }`}
          style={{ fontSize: '26px', fontWeight: '600' }}
        >
          How may I assist you in achieving your migration goal today?
        </p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: '400',
            color: '#9EA1AE',
            padding: '0.75rem 0px',
          }}
        >
          Select from the provided prompts or generate your own
        </p>
      </div>

      <div className="introcardContainer">
        <IntroCard
          isDarkMode={props.isDarkMode}
          img={Interactive}
          title="Migration Pathways"
          sub="How to decide the ideal country to move to"
        />
        <IntroCard
          isDarkMode={props.isDarkMode}
          img={DocCheck}
          title="Visa Guidance"
          sub="Information on different visa types, eligibility, etc."
        />
        <IntroCard
          isDarkMode={props.isDarkMode}
          img={chart}
          title="Interview Preparation"
          sub="Assistance with interview preparation"
        />
        <IntroCard
          isDarkMode={props.isDarkMode}
          img={DocCheck}
          title="Migration Services"
          sub="Recommendations for migration service providers"
        />
        <IntroCard
          isDarkMode={props.isDarkMode}
          img={Interactive}
          title="Migration Updates"
          sub="Up-to-date migration news, laws, and policies"
        />
        <IntroCard
          isDarkMode={props.isDarkMode}
          img={chart}
          title="Migration Opportunities"
          sub="Verified jobs, scholarships, and more"
        />
      </div>
    </div>
  );
};

export default MiaiIntroduction;

const IntroCard = props => {
  return (
    <div className="introcard">
      <div
        className="introcard__info"
        style={{ border: `1px solid ${props.isDarkMode ? '#9EA1AE' : '#000'}` }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
          <img src={props.img} alt="" />
          <p
            className={` ${
              props.isDarkMode ? 'darkModecolor' : 'lightModecolor'
            }`}
            style={{ fontSize: '16px', fontWeight: '600' }}
          >
            {props.title}
          </p>
        </div>

        <p
          className={` ${
            props.isDarkMode ? 'darkModecolor' : 'lightModecolor'
          }`}
          style={{ fontSize: '14px' }}
        >
          {props.sub}
        </p>
      </div>
    </div>
  );
};
