import React from 'react';
import './FeedBack.scss';
import avi from '../../../assets/avi.png';
import { navigate } from '@reach/router';

export const FeedBack = () => {
  return (
    <>
      <section className="Feedback-section">
        <div className="marquee">
          <div className="marquee-content">
            <div className="userfeedbackbox mb-3 d-flex align-items-start justify-content-between">
              <div className="avatar">
                <img
                  className=".img-fluid mw-100 h-auto"
                  src={avi}
                  alt="dummy avatar"
                />
              </div>
              <div className="userfeedback feedback-text">
                <p className="userName"> Mr Iyoriobhe O.</p>
                <p>Canada • Wednesday, 29 of August 2021 by 21:33pm</p>
                <p>
                  “Before I joined the <strong>Vesti Telegram group</strong>, I
                  had no passport, Today, I have not only gotten my passport but
                  owing to the catalytic effort of the Japa 1.0 , I just got my
                  Canada study Visa approved” I want to thank you very much for
                  all you do.”
                </p>
              </div>
            </div>
            <div className="userfeedbackbox mb-3 d-flex align-items-start justify-content-between">
              <div className="avatar">
                <img
                  className=".img-fluid mw-100 h-auto"
                  src={avi}
                  alt="human"
                />
              </div>
              <div className="userfeedback feedback-text">
                <p className="userName">Olayide Olumeko</p>
                <p>United Kingdom • Wednesday, 29 of August 2021 by 21:33pm</p>

                <p className="">
                  I came about Mr. Amusan's post on Linkedin I adopted those
                  series and became an addicted follower, read all the post,
                  emails, Youtube channel, all Japa series, I actioned on the
                  webinars put together by the Co-Founder. Then came 2021 now a
                  reality, my entire family and myself are already in the UK. I
                  can't wait to see this become the next slogan on CNN{' '}
                  <strong>
                    ''Vesti will become the Bank of the Future for Immigrants
                  </strong>
                  .
                </p>
              </div>
            </div>
            <div className="userfeedbackbox mb-3 d-flex align-items-start justify-content-between">
              <div className="avatar">
                <img
                  className=".img-fluid mw-100 h-auto"
                  src={avi}
                  alt="human"
                />
              </div>
              <div className="userfeedback feedback-text">
                <p className="userName">Damola Oni </p>
                <p>United States • Wednesday, 29 of August 2021 by 21:33pm </p>
                <p className="">
                  After the event of 20-10-20, I knew the country wasn’t for me
                  anymore. Thankfully, the team at <strong>Vesti</strong> was a
                  step ahead of me, I attended a{' '}
                  <strong>
                    2 hour webinar organized by Vesti on Saturday, 24th of
                    October 2020
                  </strong>{' '}
                  and it changed my orientation forever. Today, I am in the
                  United States of America, and none of it would be <br />{' '}
                  possible without <strong>“Japa webinar 1.0”</strong> and the
                  great work being <br /> done by the team at{' '}
                  <strong>Vesti</strong>.
                </p>
              </div>
            </div>

            <div className="userfeedbackbox mb-3 d-flex align-items-start justify-content-between">
              <div className="avatar">
                <img
                  className=".img-fluid mw-100 h-auto"
                  src={avi}
                  alt="human"
                />
              </div>
              <div className="userfeedback feedback-text">
                <p className="userName">Abdur-Rahman Ridwan </p>

                <p>United Kingdom • Wednesday, 29 of August 2021 by 21:33pm</p>
                <p>
                  <strong>
                    "Vesti was instrumental in my educational Immigration
                    journey to the UK"
                  </strong>
                  . It is a reliable platform that empowers individuals to
                  realize different Immigration ambitions through its tailored
                  solutions practically. More than a platform, it is a
                  dependable community that drives you to your Immigration
                  dreams from point A - Z with you still in charge.
                </p>
              </div>
            </div>
            <div className="userfeedbackbox mb-3 d-flex align-items-start justify-content-between">
              <div className="avatar">
                <img
                  className=".img-fluid mw-100 h-auto"
                  src={avi}
                  alt="human"
                />
              </div>
              <div className="userfeedback feedback-text">
                <p className="userName">Tutti Uwanikone</p>
                <p> France • Wednesday, 29 of August 2021 by 21:33pm </p>
                <p>
                  Still feels like a dream that I made it to France for my
                  Masters in Data Science and Artificial Intelligence in
                  Business. Dreams come through with <strong>Vesti</strong>,
                  take action today!
                </p>
              </div>
            </div>
            <div className="userfeedbackbox mb-3 d-flex align-items-start justify-content-between">
              <div className="avatar">
                <img
                  className=".img-fluid mw-100 h-auto"
                  src={avi}
                  alt="human"
                />
              </div>
              <div className="userfeedback feedback-text">
                <p className="userName">Oluwaloni Odunuga </p>
                <p> United Kingdom • Wednesday, 30 of August 2021 by 17:28pm</p>
                <p>
                  Arriving in the UK is a dream come true. Never knew it could
                  be seamless. All thanks to the requirements information and
                  support I got from <strong>Vesti</strong>.
                </p>
              </div>
            </div>
            <div className="userfeedbackbox mb-3 d-flex align-items-start justify-content-between">
              <div className="avatar">
                <img
                  className=".img-fluid mw-100 h-auto"
                  src={avi}
                  alt="human"
                />
              </div>
              <div className="userfeedback feedback-text">
                <p className="userName">Arinze N</p>
                <p> United Kingdom • Wednesday, 29 of August 2021 by 21:33pm</p>
                <p>
                  I remember joining the very first{' '}
                  <strong>japa webinar</strong>, After it I was fired up and
                  determined to find ways possible to join my wife in the uk.
                  Now with my wife in the Uk and life has been amazing ever
                  since.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="feedback">
          <p>What Are Our Users Saying</p>
          <p className="text-left feedbacktext vesti-color2">
            Since launching in 2020, over 70,000 people have used Vesti to find
            new homes in new countries manage their money better, and become
            part of a future that is for everyone.
          </p>
          <button
            className="feedback-btn"
            onClick={() => navigate('/testimonials')}
          >
            Read More Testimonials
          </button>
        </div>
      </section>
    </>
  );
};
