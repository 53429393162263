import React, { useRef, useState } from 'react';
import { toPng } from 'html-to-image';
import { ShareSocial } from 'react-share-social';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
} from 'react-share';
import bg1 from 'assets/shareTest/firstShare.svg';
import bg2 from 'assets/shareTest/secondShare.svg';
import bg3 from 'assets/shareTest/thirdTestShare.svg';
import award from 'assets/shareTest/AwardtestShare.svg';
import codepen from 'assets/shareTest/Codepen.svg';
import { AiOutlineLink } from 'react-icons/ai';
import { MdOutlineFileDownload } from 'react-icons/md';
import copy from 'copy-to-clipboard';
import '../../visatype.scss';

export const TestShare = props => {
  const ref = useRef();
  const [Copystate, setIsCopystate] = useState('Copy');

  //https:wevesti.com/O1visa
  //https:wevesti.com/EB1visa
  //https:wevesti.com/EB2NIWvisa

  const shareUrl = `\n \n Check your own Score here https://wevesti.com/${props.visaCopyLink}`;
  const message = `I'm eligible for the U.S ${props.visaTypetest[0].visaType} Visa! I just scored ${props.visaTypetest[0]?.score}% on my U.S ${props.visaTypetest[0].visaType} Visa Eligibility Test with Vesti! Excited for the next steps toward moving to America!`;

  const [imageUrl, setImageUrl] = useState('');

  const handleShare = () => {
    toPng(ref.current, { skipFonts: true })
      .then(dataUrl => {
        console.log(dataUrl);
        // setImageUrl(dataUrl);
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'visa-eligibility-result.png';
        link.click();

        // Optional: You can use `dataUrl` to share the image via social media using the `react-share-social` library
      })
      .catch(err => {
        console.error('Oops, something went wrong!', err);
      });
  };

  const board = useRef(null);

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(shareUrl);
    setIsCopystate('Copied');
    //  setCopied(true);

    setTimeout(() => {
      setIsCopystate('Copy');
    }, 3000);
  };

  return (
    <div
      className=""
      style={{
        width: '90%',
        margin: '0px auto',
      }}
    >
      <div
        ref={ref}
        style={{
          padding: '8px',
          background: '#DFFFD0',
          height: '14rem',
          borderRadius: '10px',
        }}
      >
        <div
          style={{
            position: 'relative',
            background: '#2B5219',
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            borderRadius: '10px',
            padding: '10px 0px',
          }}
        >
          <img
            src={bg1}
            alt=""
            style={{
              position: 'absolute',
              top: '-10px',
              left: '0',
              zIndex: '1',
            }}
          />
          <img
            src={bg2}
            alt=""
            style={{
              position: 'absolute',
              top: '10px',
              right: '0',
              zIndex: '1',
            }}
          />
          <img
            src={bg3}
            alt=""
            style={{
              position: 'absolute',
              bottom: '-26px',
              left: '0',
              zIndex: '1',
            }}
          />
          <div
            style={{
              position: 'relative',
              zIndex: '2',
              padding: '10px',
              color: '#FFF',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <img src={award} alt="" style={{ width: '12rem' }} />
            <p
              style={{
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: '500',
                color: '#fff',
              }}
            >
              I’m eligible for the U.S {props.visaTypetest[0].visaType} Visa!
            </p>
            <p
              style={{
                textAlign: 'center',
                fontSize: '10px',
                fontWeight: '500',
                color: '#fff',
                paddingTop: '5px',
              }}
            >
              I just scored {props.visaTypetest[0]?.score}% on my U.S{' '}
              {props.visaTypetest[0].visaType} Visa Eligibility Test with Vesti!
              Excited for the next steps toward moving to America!
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          paddingTop: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            color: '#14290A',
            fontSize: '16px',
            fontWeight: '600',
          }}
        >
          Share your result
        </p>

        <p
          onClick={handleShare}
          style={{
            cursor: 'pointer',
            color: '#3B6725',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <MdOutlineFileDownload style={{ color: '#3B6725' }} />
          Download
        </p>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <div
          style={{
            background: '#444444',

            borderRadius: '100%',
            width: '50px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          ref={board}
          onClick={copyCodeToClipboard}
        >
          <AiOutlineLink
            style={{
              color: '#fff',
              fontSize: '16px',
            }}
          />
        </div>

        {/* <img src={codepen} alt="" style={{}} /> */}

        <FacebookShareButton url={shareUrl} quote={message} hashtag="#Vesti">
          <FacebookIcon size={42} round />
        </FacebookShareButton>

        <TwitterShareButton
          url={shareUrl}
          title={message}
          hashtags={['Vesti', 'Visa']}
          // picture="https://res.cloudinary.com/wevesti/image/upload/v1721883286/verificationDocument/wbwgcwwhewl0hxakkrbb.jpg"
        >
          <TwitterIcon size={42} round />
        </TwitterShareButton>

        <LinkedinShareButton
          url={shareUrl}
          title={`I’m eligible for the U.S ${props.visaTypetest[0].visaType} Visa!!`}
          summary={message}
          source="Vesti"
        >
          <LinkedinIcon size={42} round />
        </LinkedinShareButton>

        <TelegramShareButton
          url={shareUrl}
          title={message}
          // picture="https://res.cloudinary.com/wevesti/image/upload/v1721883286/verificationDocument/wbwgcwwhewl0hxakkrbb.jpg"
        >
          <TelegramIcon size={42} round />
        </TelegramShareButton>
      </div>

      {Copystate == 'Copied' ? (
        <p
          style={{
            textAlign: 'center',
            paddingTop: '10px',
          }}
        >
          Test Link Copied
        </p>
      ) : (
        <p></p>
      )}
    </div>
  );
};
