import {
  VISAFORM_CHAT,
  NEW_VISAFORM_CHAT,
  VISAFORM_CHAT_IMG,
  VISAFORM_CHAT_LINK,
  VISAFORM_CHAT_FILE,
} from 'appRedux/constants';

const INIT_STATE = {
  chats: [],
  filesList: [],
  imageList: [],
  linkList: [],
};

export default (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case VISAFORM_CHAT:
      return {
        ...state,
        chats: payload,
        // error: null,
      };
    case NEW_VISAFORM_CHAT:
      return {
        ...state,
        chats: state.chats ? [...state.chats, payload] : [payload],
      };
    case VISAFORM_CHAT_IMG:
      return {
        ...state,
        imageList: payload,
      };
    case VISAFORM_CHAT_LINK:
      return {
        ...state,
        linkList: payload,
      };
    case VISAFORM_CHAT_FILE:
      return {
        ...state,
        filesList: payload,
      };
    default:
      return { ...state };
  }
};
