import React, { useEffect, useState } from 'react';
import '../billPayment.scss';
import '../../Admin.css';
import '../Electricity/ElectPayment.scss';
import { Success } from 'components/common/success/success';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import {
  IconOption,
  IconSingleValue,
  Singleselect,
} from 'components/common/inputs/singleselect';
import Loader from 'components/Loader';
import { Simplemodal } from 'components/common/simplifiedmodal';
import api from 'appRedux/api';
import SingleBiller from '../SingleBiller';
import newerror from '../../../../assets/newerror.svg';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import internet from '../../../../assets/internet.svg';
import { Comptransaction } from 'components/common/completetransaction/comptransaction';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Platformbutton } from 'components/common/button/button';
import { Transreview } from 'components/common/transactionreview/review';
import { removeCommaAmount } from 'helpers/utils';
import { Amountinputcurrency } from 'components/common/inputs/amountinput';
import _ from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import { AlertError } from 'components/common/alertboxes/alertboxes';
import { fetchTransactionOTP } from 'appRedux/actions/wallets';

const Internet = props => {
  const [open, setOpen] = useState(false);
  const userData = useSelector(state => state.auth.userData, _.isEqual);
  const allWallet = useSelector(state => state.wallets.wallets, _.isEqual);
  const balance = userData?.walletInNGNKobo;

  return (
    <>
      <Simplemodal onClick={() => setOpen(false)} open={open}>
        <InternetPayment
          onClick={() => setOpen(false)}
          internetData={props.internetData}
          setInternetData={props.setInternetData}
          allwallet={allWallet}
          balance={balance}
          countries={props.countries}
        />
      </Simplemodal>
      <SingleBiller
        title="Data Bundle"
        image={internet}
        color="#F7FBFE"
        onClick={() => setOpen(true)}
      />
    </>
  );
};

export default Internet;

const InternetPayment = props => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [internetData, setInternetData] = useState([]);
  const [message, setMessage] = useState('');
  const [internetDetails, setInternetDetails] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const allWallet = useSelector(state => state.wallets.wallets, _.isEqual);

  const [form, setForm] = useState({
    type: '',
    amount: '',
    billingNumber: '',
    transactionPin: '',
  });

  var setInput = e => {
    var name = e.target.name;
    var value = e.target.value;

    setForm({ ...form, [name]: value });
  };

  const validate = async type => {
    const data = {
      billCode: type.billCode,
      itemCode: type.itemCode,
      billingNumber: form.billingNumber,
    };
    // console.log(data);
    setIsLoading(true);
    await api
      .post(`/bill-payment/validate`, data)
      .then(res => {
        setIsLoading(false);
        res.data?.status === 'error'
          ? openNotificationWithIconErr(
              `Data Purchase`,
              `${res.data?.message}, Please Input a valid Phone Number`,
              'error',
            )
          : setInternetDetails(res.data?.data);
        // console.log(internetDetails);
      })
      .catch(error => {
        setIsLoading(false);
        // console.log(error);
        setMessage(error.data?.message);
        openNotificationWithIconErr(
          `Data Purchase`,
          `${error.data?.message}`,
          'error',
        );
        // setStep(4);
      });
  };

  const getInternetData = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `/bill-payment/categories?country=${selectedCurrency.value}`,
      );
      setIsLoading(false);

      // console.log('All Data:', res.data?.data);

      const filteredData = res.data?.data?.filter(item => item.amount !== 0);
      setInternetData(filteredData);

      // console.log('Filtered Data for country:', filteredData);
    } catch (error) {
      console.error('API Error:', error);

      setMessage(error.response?.data?.message);

      if (error.response) {
        openNotificationWithIconErr(
          'Data Purchase',
          error.response.data.message,
          'error',
        );
      } else {
        openNotificationWithIconErr(
          'Data Purchase',
          'Network Error, Please try again',
          'error',
        );
      }
    }
  };

  useEffect(() => {
    // Fetch data only if a currency is selected
    if (selectedCurrency) {
      getInternetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrency]);

  useEffect(() => {
    let isMounted = true;
    if (form.billingNumber.length === 11 && isMounted) {
      validate(form.type);
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.billingNumber?.length, form.type]);

  var Options =
    internetData?.length > 0
      ? (
          internetData.filter(
            item =>
              item.amount !== 0 &&
              (item.biller_name.includes('Data') ||
                item.biller_name.includes('data ') ||
                item.biller_name.includes('SMILE ') ||
                item.biller_code.includes('data') ||
                item.name.includes('data') ||
                item.name.includes('GLO') ||
                item.name.includes('GLO DATA') ||
                item.name.includes('Data')),
          ) ?? []
        ).map(item => ({
          value: item.amount,
          label: item.biller_name,
          // label: item.name,
          type: item.biller_name,
          itemCode: item.item_code,
          billCode: item.biller_code,
          labelName: item.label_name,
          fee: item.fee,
          country: item.country,
          // fee: item.fee === 0 ? 100 : item.fee,
        }))
      : [];
  // console.log('Options: ', Options);

  var handleChange = value => {
    // console.log(value);
    setForm({ ...form, type: value });
  };

  const payBill = async () => {
    setIsLoading(true);
    const data = {
      country: form.type.country,
      // amountInKoboCent: form.type.value * 100,
      currency: selectedCurrency.value,
      recurrence: 'ONCE',
      // chargesInKobo: form.type.fee * 100,
      type: form.type.type,
      transactionOtp: form.transactionPin,
      billingNumber: form.billingNumber,
      category: 'internet',
    };
    // console.log(data);
    await api
      .post(`/bill-payment/initiate`, data)
      .then(res => {
        setIsLoading(false);
        setMessage(res.data?.message.toLowerCase());
        openNotificationWithIcon(
          `Data Purchase`,
          `Data bundle purchase ${res.data?.message.toLowerCase()}`,
          'success',
        );
        setStep(3);
        // console.log(res);
      })
      .catch(error => {
        setIsLoading(false);
        // console.log(error)
        if (error.data) {
          // Handle specific error from the server

          // setMessage(error.data?.message);
          // openNotificationWithIconErr(
          //   `Data Purchase`,
          //   `${error.data?.message}`,
          //   'error',
          // );
          setMessage(
            'Service temporarily not available on web, please try mobile',
          );
          openNotificationWithIconErr(
            `Data Purchase`,
            `Service temporarily not available on web, please try mobile`,
            'error',
          );
          setStep(4);
        } else {
          // Handle generic network error
          setMessage('Network Error');
          openNotificationWithIconErr(
            `Data Purchase`,
            `Network Error`,
            'error',
          );
        }
      });
  };

  switch (step) {
    case 0:
      return (
        <section className="w-full md:w-[400px] lg:w-[450px]">
          <Titlesubtitle
            title="Data Bundle •1 of 3"
            subtitle="Choose a wallet to debit from"
          />
          <span className="my-[20px]"></span>
          <Singleselect
            value={selectedCurrency}
            options={allWallet
              .filter(item => item.currency !== 'USD')
              .map(item => ({
                value: item.currency,
                label: item.name.toLocaleUpperCase(),
                image: item.currencyFlag,
              }))}
            placeholder="Select Wallet to debit"
            onChange={value => setSelectedCurrency(value)}
            components={{ SingleValue: IconSingleValue, Option: IconOption }}
          />
          <p className="my-12 h-[20px]"></p>
          <Platformbutton
            name={
              selectedCurrency
                ? ` Proceed with ${selectedCurrency.label}  `
                : 'Select'
            }
            type="normal"
            disabled={!selectedCurrency ? true : false}
            click={() => setStep(1)}
          />
        </section>
      );
    case 1:
      return (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="elect-payment-cont">
              <Titlesubtitle
                title="Data Bundle •1 of 2"
                subtitle="Fill in the fields below to get your data bundle without hassles."
              />

              <Myform
                form={form}
                step={step}
                internetDetails={internetDetails}
                internetData={internetData}
                Options={Options}
                handleChange={handleChange}
                validate={validate}
                setForm={setForm}
                setStep={setStep}
                setInput={setInput}
                setInternetData={setInternetData}
                setInternetDetails={setInternetDetails}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                balance={props.balance}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
              />
            </div>
          )}
        </>
      );
    case 2:
      return (
        <div className="elect-payment-cont">
          <Titlesubtitle
            title="Data Bundle •3 of 3"
            subtitle="Enter your PIN to complete payment"
          />
          <Finalize
            form={form}
            setStep={setStep}
            step={step}
            setForm={setForm}
            transactionpin={form.transactionPin}
            payBill={payBill}
            internetDetails={internetDetails}
            setInternetDetails={setInternetDetails}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            dispatch={dispatch}
          />
        </div>
      );

    case 3:
      return (
        <Success
          title="Payment Received"
          subtitle={`Data bundle purchase ${message}`}
          button="Close!"
          onClick={props.onClick}
        />
      );
    case 4:
      return (
        <Success
          image={newerror}
          title="Error"
          type="error"
          subtitle={message}
          button="Try again!"
          // onClick={props.onClick}
          onClick={() => setStep(2)}
        />
      );
    default:
      return <p>Error</p>;
  }
};

const Myform = props => {
  return (
    <>
      <form className="elect-payment">
        <Singleselect
          placeholder="Select a biller"
          value={props.form.type}
          options={props.Options}
          onChange={e => props.handleChange(e)}
        />

        {props.form.type !== '' && (
          <>
            <Amountinputcurrency
              type="text"
              currency={props.selectedCurrency.value === 'NGN' ? '₦' : 'GH¢'}
              label="Amount"
              name="amount"
              value={
                props.form.type.value === 'NaN'
                  ? 0
                  : props.form.type.value.toLocaleString('en-US') || ''
              }
              disabled={true}
              pattern="[0-9,.]*"
            />
            {removeCommaAmount(props.form.type.value) > 0 &&
              removeCommaAmount(props.form.type.value) >
                props.selectedCurrency.balance / 100 && (
                <AlertError
                  body={
                    removeCommaAmount(props.form.type.value) >
                    props.selectedCurrency.balance / 100
                      ? `You do not have upto ${
                          props.selectedCurrency.value === 'NGN' ? '₦' : 'GH¢'
                        }${props.form.type.value} in your Vesti ${
                          props.selectedCurrency.value
                        }, please deposit into your account.`
                      : ``
                  }
                />
              )}
            <Inputfloat
              type="text"
              label="Phone Number"
              name="billingNumber"
              value={props.form.billingNumber}
              onChange={props.setInput}
              disabled={props.form.type === ''}
            />
          </>
        )}

        {props.internetDetails.response_code === '00' && (
          <button
            type="submit"
            className="default-btn w-100 py-3"
            disabled={
              props.form.type !== '' && props.form.billingNumber !== ''
                ? false
                : true
            }
            onClick={() => props.setStep(2)}
          >
            Proceed to Enter your Pin
          </button>
        )}
      </form>
    </>
  );
};

const Finalize = props => {
  const transactionPin = value => {
    props.setForm({ ...props.form, transactionPin: value });
  };

  return (
    <div className="ngnwithdrawal__review">
      <Transreview
        data={[
          { title: 'Transaction Type', value: 'Data' },
          {
            title: 'Plan',
            value: props.form.type.label,
          },
          {
            title: 'Amount',
            value: `${
              props.selectedCurrency.value === 'NGN' ? '₦' : 'GH¢'
            }${removeCommaAmount(props.form.type.value).toLocaleString(
              'en-US',
            )}`,
          },
          {
            title: 'Fee',
            value: `${
              props.selectedCurrency.value === 'NGN' ? '₦' : 'GH¢'
            }${removeCommaAmount(props.form.type.fee).toLocaleString('en-US')}`,
          },
          {
            title: 'Phone Number',
            value: props.form.billingNumber,
          },
          {
            title: 'Total',
            value: `${
              props.selectedCurrency.value === 'NGN' ? '₦' : 'GH¢'
            }${removeCommaAmount(
              parseInt(props.form.type.fee) + parseInt(props.form.type.value),
            ).toLocaleString('en-US')}`,
          },
        ]}
      />
      <div className="mb-2"></div>
      <Comptransaction
        fetcher={props?.fetchTransactionOTP}
        setPin={transactionPin}
        loading={props.isLoading}
        goBack={() => props.setStep(0)}
        btn={`Finalize Transaction`}
        onFinish={Finalize}
        len={4}
        lower={true}
      >
        <Backcontinue goBack={() => props.setStep(0)}>
          <Platformbutton
            name="Finalize Transaction"
            type="normal"
            click={() => props.payBill()}
            disabled={
              props.form.transactionPin.length !== 4 || props.isLoading
                ? true
                : false
            }
          />
        </Backcontinue>
      </Comptransaction>
      <p style={{ textAlign: 'center' }}>
        Didn't get the code?{' '}
        <span
          style={{ cursor: 'pointer', color: 'green' }}
          onClick={() => props?.dispatch(fetchTransactionOTP())}
        >
          Resend
        </span>
      </p>
    </div>
  );
};
