import React from 'react';
import { connect, useSelector } from 'react-redux';
import Fbodeposit from './deposit';
import Fbowithdrawal from './withdrawal';
import './styles.scss';
import _ from 'lodash';

// import Depositusdwallet from "components/deposit/depositusdwallet";

const Fbotrans = props => {
  const userWallets = useSelector(
    state => state.auth.authUser?.data?.wallets,
    _.isEqual,
  );

  var ngnBal = userWallets?.filter(item => item.currency === 'NGN');
  var usdBal = userWallets?.filter(item => item.currency === 'USD');
  var ghsBal = userWallets?.filter(item => item.currency === 'GHS');
  var __renderAction = () => {
    switch (props.action) {
      case 'deposit':
        return (
          <Fbodeposit
            rate={parseInt(props.rate?.MERCHANT_RATE / 100)}
            userWallets={userWallets}
            fullname={props.userdata?.firstName + ' ' + props.userdata.lastName}
            balance={usdBal / 100}
            ngnbalance={ngnBal / 100}
            ghsbalance={ghsBal / 100}
            phone={props.phone}
            refetch={props.refetch}
            id={props.id}
            closeModal={props.close}
          />
        );
      //     return <Depositusdwallet
      //         closeModal = {props.closeModal}
      //         refetch ={props.refetch}
      //         rate={props.rate}
      //         userdata={props.userdata}
      //         userType= {props.userType}
      //   />
      case 'withdraw':
        return (
          <Fbowithdrawal
            fullname={props.userdata?.firstName + ' ' + props.userdata.lastName}
            balance={usdBal / 100}
            phone={props.phone}
            refetch={props.refetch}
            id={props.id}
            closeModal={props.close}
            currency={'USD'}
            // balance={props.userdata?.walletAmountInUSCents / 100}
          />
        );
      default:
        return <></>;
    }
  };

  return <>{__renderAction()}</>;
};

const mapStateToProps = ({ wallets }) => {
  const { action } = wallets;
  return {
    action,
  };
};

export default connect(mapStateToProps)(Fbotrans);
