import React from 'react';
import './merchant.css';
import users from '../../../assets/users.svg';
import { FiArrowUpRight } from 'react-icons/fi';

const MerchantPayment = ({
  title,
  description,
  dollarPrice,
  abbreviation,
  paymentHandler,
  amount = '102,965.50',
  custom = false,
  setCurrent,
  button,
  setPaymentTitle,
  setShowModal,
  onClickHandler,
  price,
  serviceName,
}) => {
  let dollarUSLocale = Intl.NumberFormat('en-US');

  const storedCurrency = localStorage.getItem('currency');

  // var perc = amount && (dollarUSLocale.format(amount.ngn.replace(/[^0-9.-]+/g,"") / 10));
  return (
    <div className=" mb-3 ">
      <div className="merchant-card px-3 py-3 textleft mb-3">
        <div>
          <div className="merchant-card__title flex items-center">
            <h3 className="merchant-card__abbr py-3 my-0 mr-3">
              {abbreviation}
            </h3>{' '}
            {title}
          </div>
          {!custom && (
            <div className="my-2">
              <div>
                <img src={users} alt="" />
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="flex gap-3 items-center ">
            <div
              className="merchant-card__btn px-0 flex  items-center"
              onClick={() => {
                // console.log(amount);
                // console.log(title);
                // console.log(price);
                // console.log(serviceName);
                setCurrent(amount);
                paymentHandler(custom, amount, price, serviceName);
                setPaymentTitle(title);
                setShowModal(true);
                onClickHandler(amount, title, price, serviceName);
              }}
            >
              {!custom ? (
                <span className="font-weight-bold text-[13px]  flex items-center gap-1">
                  {' '}
                  Click to Pay
                  {/* <i className="fas fa-arrow-right"></i> */}{' '}
                  <FiArrowUpRight />
                </span>
              ) : (
                <span className="font-weight-bold flex items-center gap-1">
                  {' '}
                  {button}
                  {/* <i className="fas fa-arrow-right"></i> */}
                  <FiArrowUpRight />
                </span>
              )}
            </div>
            <p className="merchant-card__amount  border border-[#00000040] px-2 rounded-lg">
              {' '}
              {storedCurrency} {amount}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantPayment;
