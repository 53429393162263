import {
  VISAFORM_CHAT,
  NEW_VISAFORM_CHAT,
  VISAFORM_CHAT_IMG,
  VISAFORM_CHAT_LINK,
  VISAFORM_CHAT_FILE,
} from 'appRedux/constants';
import api from 'appRedux/api';

export const fetchVisaChatData = (userId, appId) => async dispatch => {
  try {
    const response = await api.get(
      `/chats/fetch-messages?applicationId=${appId}`,

      // `/chats/fetch-messages?userId=${userId}&applicationId=${appId}`,
    );
    const data = response.data.data;

    dispatch({
      type: VISAFORM_CHAT,
      payload: data,
    });
  } catch (error) {
    console.log();
    // dispatch({
    //   type: FETCH_CHAT_DATA_FAILURE,
    //   payload: error.message,
    // });
  }
};

export const fetchVisaChatDataByImage = (userId, appId) => async dispatch => {
  try {
    const response = await api.get(
      `/chats/fetch-messages?applicationId=${appId}&image=true`,

      // `/chats/fetch-messages?userId=${userId}&applicationId=${appId}`,
    );
    const data = response.data.data;
    // console.log(data);
    dispatch({
      type: VISAFORM_CHAT_IMG,
      payload: data,
    });
  } catch (error) {
    console.log();
    // dispatch({
    //   type: FETCH_CHAT_DATA_FAILURE,
    //   payload: error.message,
    // });
  }
};

export const fetchVisaChatDataByFile = (userId, appId) => async dispatch => {
  try {
    const response = await api.get(
      `/chats/fetch-messages?applicationId=${appId}&file=true`,

      // `/chats/fetch-messages?userId=${userId}&applicationId=${appId}`,
    );
    const data = response.data.data;

    dispatch({
      type: VISAFORM_CHAT_FILE,
      payload: data,
    });
  } catch (error) {
    console.log();
    // dispatch({
    //   type: FETCH_CHAT_DATA_FAILURE,
    //   payload: error.message,
    // });
  }
};

export const fetchVisaChatDataByLink = (userId, appId) => async dispatch => {
  try {
    const response = await api.get(
      `/chats/fetch-messages?applicationId=${appId}&link=true`,

      // `/chats/fetch-messages?userId=${userId}&applicationId=${appId}`,
    );
    const data = response.data.data;

    dispatch({
      type: VISAFORM_CHAT_LINK,
      payload: data,
    });
  } catch (error) {
    console.log();
    // dispatch({
    //   type: FETCH_CHAT_DATA_FAILURE,
    //   payload: error.message,
    // });
  }
};

export const addNewVisaChat = value => ({
  type: NEW_VISAFORM_CHAT,
  payload: value,
});
