import React, { useEffect, useState } from 'react';
import webinarcalendar from '../../../assets/pathways/webinarcalendar.svg';
import api from 'appRedux/api';
import { useQuery } from 'react-query';
import Loader from 'components/Loader/Loader';
import { useUserData } from 'helpers/hooks';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import './webinars.css';

export const webinarData = [
  {
    id: '1',
    link: 'https://www.youtube.com/embed/kfHSxEanYE0',
    title: 'How to Migrate to Germany',
    featuring: 'Featuring: Olusola Amusan, Yewande Oyebo and Tobi Wole Fasanya',
    date: 'Sat, Jul 30 • 11:00PM CAT',
    subtitle:
      ' How to Migrate to Germany with Olusola Amusan,Yewande Oyebo and Tobi Wole Fasanya.',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '2',
    link: 'https://www.youtube.com/embed/FPD5FbB3uvg',
    title: 'How to Migrate to Ireland ',
    featuring: 'Featuring: Olusola and Dr. Segun Seriki',
    date: 'Sat, Jul 30 • 11:00PM CAT',
    subtitle:
      'How to Migrate to Ireland with Olusola Amusan and Dr. Segun Seriki.',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '3',
    link: 'https://www.youtube.com/embed/Wn9_PrN3eSs',
    title: 'How to Migrate to France',
    featuring: 'Featuring: Olusola Amusan & Tobiloba Ricketts',
    date: 'Sat, Jul 30 • 11:00PM CAT',
    subtitle:
      'VHow to Migrate to France with Olusola Amusan & Tobiloba Ricketts.',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '4',
    link: 'https://youtu.be/Sl7bBw3PBXg',
    title: 'How to Ace Your US Visa Interview',
    featuring:
      'Featuring: Laura Alagbada, Hilda Acheampong, Oyewale Okeyode, Olubunmi Opadoyin, Chinemerem Wilson',
    date: 'Thurs, Nov 02 • 3:00PM WAT',
    subtitle: 'Learn how to ace your visa interview',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },

  {
    id: '5',
    link: 'https://www.youtube.com/embed/ow2UoI8fI6o',
    title: 'Global Scholarships Information Session',
    featuring: 'Featuring: Olusola Amusan & PHD Scholar Isaiah Adelabu',
    date: 'Sat, Jul 30 • 11:00PM CAT',
    subtitle:
      'Global Scholarships Information Session with PHD Scholar Isaiah Adelabu.',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '6',
    link: 'https://www.youtube.com/embed/xK39Pm9bZdA',
    title: 'Accessing Global Scholarship opportunities',
    featuring: 'Featuring: Olusola Amusan Ommoby Awoyemi',
    date: 'Sat, Jul 30 • 11:00PM CAT',
    subtitle:
      'Accessing Global Scholarship opportunities with PHD Scholars Isaiah Adelabu and Maj. Adebayo Adeleke.',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
];

const GroupEvent = () => {
  return (
    <div
      style={{
        paddingLeft: '2rem',
        marginTop: '1rem',
        paddingRight: '3rem',
        width: '100%',
      }}
    >
      <div className="mywebinarPageA ">
        {webinarData.map(
          ({ id, title, featuring, date, type, image, link }) => {
            return (
              <div
                className="mywebinarPageA__div"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ color: 'white' }}>{title}</p>
                  <p
                    className=""
                    style={{
                      color: 'white',
                      background: '#CCCCCC',
                      padding: '2px 5px',
                      borderRadius: '10px',
                      border: '1px solid #fff',
                    }}
                  >
                    {type}
                  </p>
                </div>

                <p
                  style={{
                    background: '#C7FFAD',
                    maxWidth: '90%',
                    padding: '2px 5px',
                    borderRadius: '10px',
                    fontSize: '14px',
                    textAlign: 'center',
                  }}
                >
                  {date}
                </p>
                <p style={{ color: 'white', fontSize: '12px' }}>{featuring}</p>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <a
                    href={link}
                    style={{
                      color: 'white',
                      fontSize: '12px',
                      cursor: 'pointer',
                    }}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Watch Now <i className="fas fa-arrow-right" />
                  </a>
                  <img src={webinarcalendar} alt="do more" />
                </div>
              </div>
            );
          },
        )}
      </div>
      {/* <div className="mywebinarPageA ">
        {webinarData.map(
          ({ id, title, featuring, date, type, image, link }) => {
            return (
              <div className="mywebinarbackground">
                <div
                  className=""
                  style={{
                    width: '100%',
                    display: 'flex',
                    color: 'white',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className="">{title}</div>

                  <div className="">
                    <p className=""> {type} </p>
                  </div>
                </div>

                <div className="webinar-date"> {date} </div>

                <p className="webinar-subtitle">{featuring}</p>

                <div className="" style={{ display: 'flex' }}>
                  <a href={link} rel="noopener noreferrer" target="_blank">
                    <div className="">
                      <p className="webinar-left-title">
                        Watch Now <i className="fas fa-arrow-right" />
                      </p>
                    </div>
                  </a>

                  <div className="webinar-right-down">
                    <img
                      height="100%"
                      width="100%"
                      src={webinarcalendar}
                      alt="do more"
                    />
                  </div>
                </div>
              </div>
            );
          },
        )}
      </div> */}
    </div>
  );
};

export default GroupEvent;
