import biceps from 'assets/TourGuide/flexed-bicep.svg';
import global from 'assets/TourGuide/earth.svg';
import cards from 'assets/TourGuide/credit-cards.svg';
import plane from 'assets/TourGuide/plane.svg';

// bank tour features

import allfeatures from 'assets/TourGuide/gridmenu.svg';
import add from 'assets/TourGuide/addM.svg';
import send from 'assets/TourGuide/sendM.svg';
import Createwallet from 'assets/TourGuide/plus.svg';

// card tour guide
import create from 'assets/TourGuide/Cards/create.svg';
import fund from 'assets/TourGuide/Cards/fund.svg';
import mkTransactioon from 'assets/TourGuide/Cards/makeTransaction.svg';
import request from 'assets/TourGuide/Cards/requestGGcard.svg';

// Pathway Tour Guide

import joinPathway from 'assets/TourGuide/Pathways/JoinPathwayLogo.svg';
import migrationFees from 'assets/TourGuide/Pathways/migrationFeesLogo.svg';

export const myGuides = [
  {
    title: 'Wallets that are powerful',
    img: biceps,

    link: '/bank',
  },
  {
    title: 'Pathways to move abroad',
    img: global,
    link: '/pathways',
  },
  {
    title: 'Cards that work everywhere',
    img: cards,
    link: '/cards',
  },

  {
    title: 'Flights that are affordable',
    img: plane,
    link: '/bookflight',
  },
];

export const bankTourFeatures = [
  {
    title: 'All features ',
    img: allfeatures,

    link: '2',
  },
  {
    title: 'Add money ',
    img: add,
    link: '3',
  },
  {
    title: 'Send money ',
    img: send,
    link: '4',
  },

  {
    title: 'Add Wallet',
    img: Createwallet,
    link: '5',
  },
];

export const cardTourFeatures = [
  {
    title: 'All features ',
    img: allfeatures,

    link: '2',
  },
  {
    title: 'Create Virtual Card',
    img: create,
    link: '3',
  },
  {
    title: 'Fund Virtual Card',
    img: fund,
    link: '4',
  },

  {
    title: 'Make Transaction',
    img: mkTransactioon,
    link: '5',
  },
  {
    title: 'Request Physical Card',
    img: request,
    link: '6',
  },
];

export const pathwayTourFeatures = [
  {
    title: 'All features ',
    img: allfeatures,

    link: '2',
  },
  {
    title: 'Join a Pathway',
    img: joinPathway,
    link: '3',
  },
  {
    title: 'Migration Fees',
    img: migrationFees,
    link: '4',
  },
];
