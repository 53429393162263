import React, {useEffect,useState} from "react";
import "../mytransactions.scss"
import { transColor, transSign, transStatus } from "helpers/utils";
import { Empty } from "components/common/empty/empty";
import Loader from "components/Loader";
import { Titlesubtitle } from "components/common/titlesubtitle/titlesubtitle";
import moment from 'moment';
import { Simplemodal } from "components/common/simplifiedmodal";
import { connect } from "react-redux";
import { fetchUsdTransactions } from "appRedux/actions/usdtrans";
import Newpagination from "../pagination/newpagination";
const Myfbotransactions = (props) => {

    // eslint-disable-next-line
    const [page, setPage] = useState(1)
    const [active, setActive]= useState(0)
    const [open, setOpen]= useState(false)

    const [dimensions, setDimensions] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(()=> {
        props.fetchUsdTransactions()
        // eslint-disable-next-line
    },[props.balance,page])
    var handleOpen = (value)=> {
        setActive(value)
        dimensions.width < 1050 && setOpen(true)
   }
    const handleResize = () => {
        setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        });
    }
    React.useEffect(() => {
        window.addEventListener("resize", handleResize, false);
    }, []);
   
    if(props.transLoading){
        return (
            <div className="mytransactions">
               <Titlesubtitle
                    title="USD Wallet Transactions"
                    subtitle="See all transactions you've carried out on your USD wallet."
                />
                <div className="mytransactions-center-container">
                    <Loader/>
                </div>
            </div>
        )
    }

    else if (props.usdTransactions?.data && props.usdTransactions?.data?.length>0) {
        return (
            <div className="mytransactions">
                <Simplemodal
                    onClick={()=> setOpen(false)}
                    open={open}
                >
                    <FboTransdetail 
                        data = {props.usdTransactions?.data[active]}
                    />
                </Simplemodal>
                <Titlesubtitle
                    title="USD Wallet Transactions"
                    subtitle="See all transactions you've carried out on your USD wallet."
                />

                <div className="mytransactions__inner">
                        <div className="mytransactions__tablecont">
                            <div className="mytransactions__tabbox">
                                <table className="mytransactions__table">
                                    <thead>
                                        <tr>
                                        <th>S/N</th>
                                        <th>Type</th>
                                        <th>Currency</th>
                                        <th>Amount</th>
                                        <th>Date & Time</th>
                                        <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (props.usdTransactions?.data ??[]).map((item,index)=> (
                                                <Trow
                                                    key={index}
                                                    index={index}
                                                    type = {item.trxType}
                                                    currency ={item.currency}
                                                    date={item.createdAt}
                                                    status={item.trxStatus}
                                                    amount ={item.amountInCents}
                                                    click={handleOpen}
                                                />
                                            ))
                                        }
                                    </tbody>
        
                                </table>
                            </div>
                            
                            {dimensions.width > 1200 && <FboTransdetail 
                                data = {props.usdTransactions?.data[active]}
                            />}
                        </div>
                       

                        <Newpagination
                            className="pagination-bar"
                            currentPage={page}
                            totalCount={props.usdTransactions?.paginationMeta?.totalObjects}
                            pageSize={15}
                            onPageChange={page => setPage(page)}
                        />
                </div>
            </div>
        )
    } 
    else {
        return (
            <div className="mytransactions">
                 <Titlesubtitle
                    title="USD Wallet Transactions"
                    subtitle="See all transactions you've carried out on your USD wallet."
                />
                <div className="mytransactions__centercont">
                    <Empty
                        title="No Transactions"
                        subtitle="You are yet to carry out any transactions, when you do they'll appear here, click the buttons above to carry out transactions."
                    />
                </div>
            </div>
        )
    }
    
}

const Trow = (props) => {

    // var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', };
    // const newDate = new Date(props.date).toLocaleDateString("en-US", options);
    const newDate = moment(props.date, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss")
    var tr_type = props.type

    return (
        <tr onClick={()=> props.click(props.index)}>
            <td>
                {props.index}
            </td>
            <td>{tr_type}</td>
            <td>{"USD"}</td>
            <td className={`amount${transColor(tr_type)}`}>
                {transSign(tr_type)}
               
                {  (props.amount / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
            <td>{newDate}</td>
            <td>
            <div className={`status`}>
                   <p className={`statusIcon --${transStatus(props.status)} `}></p> {transStatus(props.status)}
                </div>
            </td>
            
        </tr>
    )
}

const FboTransdetail = (props)=> {

    return (
        <section  className="transdetail">
            <span  className={`transdetail__top ${transColor(props.data.trxType)}`}>
                <p>{props.data.trxType}</p>
                <p> USD {(props.data.amountInCents / 100).toLocaleString('en-us')}</p>
                <p>{moment(props.data.createdAt, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss")}</p>
            </span>
            <div className="transdetail__list">
                {
                    (props.data?.trxType !== 'credit' ?[
                         {title:'status',value:props.data.trxStatus},
                        {title:'Currency', value:"usd"},
                        {title:'date',value:moment(props.data.createdAt, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss")},
                        {title:'Type',value:props.data.trxType},
                        {title:'Receiver',value:props.data?.receiverName},
                        {title:'Account Number',value:props.data?.receipientAccountNumber},
                        {title:'Charges',value:props.data?.charges/100}
                    ]: [
                        {title:'status',value:props.data.trxStatus},
                        {title:'Currency', value:"usd"},
                        {title:'date',value:moment(props.data.createdAt, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss")},
                        {title:'Type',value:props.data.trxType},
                        {title:'Charges',value:props.data?.charges/100}
                    ]).map((item,index)=> (
                        <span key={index} className="transdetail__item">
                            <p>{item.title}</p>
                            <p className={item.title === 'status'? `status`:''} >
                                
                            { item.title === 'status' && <p className={`statusIcon --${transStatus(item.value)} `}></p>}  { item.title === 'status' ? transStatus(item.value): item.value}
                            </p>
                        </span>
                    ))
                }
            </div>
            
        </section>
    )
}

const mapStateToProps = ({ transactions }) => {
    const {usdTransactions,transLoading} = transactions
    return {
        usdTransactions,transLoading
    };
  };

  export default connect(mapStateToProps, {
    fetchUsdTransactions
  })(Myfbotransactions);