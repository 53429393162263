import { Amountinput } from 'components/common/inputs/amountinput';
import React from 'react';
import { Selectcurrency } from './selectcurrency';
import { useAmount } from 'helpers/hooks';
import {
  formatterUSD,
  getCurrency,
  getCurrencyName,
  removeCommaAmount,
} from 'helpers/utils';
import { AlertError } from 'components/common/alertboxes/alertboxes';
import { useSelector } from 'react-redux';
import { Textbal } from './littlebalance/littlebalance';
import bankbal from 'assets/currency/bankbal.svg';
import _ from 'lodash';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Platformbutton } from 'components/common/button/button';

export const Currencyconv = props => {
  const { convFrom, balances } = useSelector(state => state.wallets, _.isEqual);
  const { amount, handleAmount } = useAmount(
    0,
    balances.filter(item => item.currency === convFrom.currency)[0]?.balance,
    755,
  );

  return (
    <section>
      <div className="relative w-[75vw] lg:w-[450px] flex flex-col items-start gap-[0px]">
        <div
          className="w-full h-[130px] bg-vesti-800 px-[30px] py-[25px] rounded-[10px] 
                flex flex-col justify-between border-solid border-1 border-vesti-900"
        >
          <p className="font-sans text-[1em] font-bold text-vesti-400 uppercase">
            YOU ARE FUNDING
          </p>
          <div className="flex flex-row items-end justify-between">
            <span className="flex flex-row items-end text-vesti-400">
              <Amountinput
                type="text"
                name="amount"
                currency="GHC"
                value={amount === 'NaN' ? 0 : amount.toLocaleString('en-US')}
                disabled={false}
                placeholder="Enter amount to deposit"
                onChange={handleAmount}
                pattern="[0-9,.]*"
                color="--light"
              />
            </span>
            <span className="flex flex-col items-end justify-right gap-[5px]">
              <p className="font-sans text-[1em] font-bold text-vesti-400 uppercase px-[5px]">
                {' '}
                ₦ 750 = 1 GHC{' '}
              </p>
              <Selectcurrency />
            </span>
          </div>
        </div>
        <span className="w-full flex flex-row items-center gap-[10px]">
          <img className="h-[80px]" src={bankbal} alt="bank bal" />
          {convFrom && (
            <Textbal
              small="yes"
              wallet={`${getCurrencyName(convFrom?.currency)}`}
              bal={formatterUSD.format(
                balances.filter(item => item.currency === convFrom.currency)[0]
                  .balance,
              )}
              currency={getCurrency(convFrom?.currency)}
            />
          )}
        </span>

        <div
          className="w-[75vw] lg:w-[450px] h-[130px] bg-vesti-100 px-[30px] py-[25px] rounded-[10px]
                flex flex-col justify-between border-solid border-1 border-vesti-900"
        >
          <p className="font-sans text-[1em] font-bold text-vesti-901 uppercase">
            YOU PAY
          </p>
          <p className="font-sans text-[3em] font-bold text-vesti-901 uppercase">
            {getCurrency(convFrom?.currency)}{' '}
            {amount === 'NaN'
              ? 0
              : formatterUSD.format(removeCommaAmount(amount) * 755)}
          </p>
        </div>
      </div>
      {/* {convFrom &&  convFrom?.currency+ ''+ convFrom?.name} */}
      {/* {amount} */}
      {removeCommaAmount(amount) * 755 >
        balances.filter(item => item.currency === convFrom.currency)[0]
          ?.balance && (
        <AlertError
          body={` You do not have more than ${removeCommaAmount(amount) *
            755} in your wallet, please fund your wallet and try again. `}
        />
      )}
      <Backcontinue goBack={props.goBack}>
        <Platformbutton
          name="Continue"
          disabled={amount > 1 ? false : true}
          type="normal"
          click={() => props.continue()}
        />
      </Backcontinue>
    </section>
  );
};
