import React from 'react';

export const Indicators = props => {
  return (
    <div className=" flex flex-row gap-[10px] items-center  ">
      {props.data.map((item, index) => (
        <span
          key={index}
          onClick={() => props.click(index)}
          style={{
            width: `${props.active === index ? '50px' : '10px'}`,
            height: `${props.active === index ? '10px' : '10px'}`,
            background: `${props.active === index ? '#67A948' : ' #C7FFAD'}`,
          }}
          className={
            props.active === index ? 'rounded-[100px]  ' : 'rounded-[100px] '
          }
        ></span>
      ))}
    </div>
  );
};
