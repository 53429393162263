import React, {useEffect} from 'react'
import './newfeeds.scss'
import Layout from 'components/common/DashboardLayout';
import { FirstSec } from './FirstSection';
import { SecondSec } from './SecondSec';



export const NewFeeds = () => {


  return (
      <Layout >
        <section className='feedsV2'>
          <div className="both-container"
          style={{ width: '100%', overflow: 'scroll' }}
          >
          <div className='bothDiv'>
            <div className='first-sec'>
              <FirstSec />
            </div>
            <div className='second-sec'>
              <SecondSec />
            </div>
          </div>
          </div>

        </section>
      </Layout>
  )
}
