import React, { useState, useEffect } from 'react';
import pathwayIcon from 'assets/homeIcon/pathway.svg';
import groups from 'assets/homeIcon/community.svg';
import flight from 'assets/homeIcon/fight.svg';
import bgPathway from 'assets/homeIcon/bgpathway.svg';
import bgGroup from 'assets/homeIcon/bgcommunity.svg';
import bgflight from 'assets/homeIcon/bgflight.svg';
import newIcon from 'assets/homeIcon/newIcon.svg';
import migration from 'assets/homeIcon/migratioon.svg';
import bgmigration from 'assets/homeIcon/bgmigrationfees.svg';
import webinar from 'assets/homeIcon/webinar.svg';
import bgwebinar from 'assets/homeIcon/bgwebinar.svg';
import { navigate } from '@reach/router';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { Largemodal } from 'components/common/simplifiedmodal';
import GroupEvent from 'containers/Admin/NewDashboard/groupEvent';
import { SelectcurrencyFlight } from 'components/selectCurrency/selectcurrency';
import bgtracking from 'assets/homeIcon/Tracking.svg';
import tracking from 'assets/homeIcon/trackingDocIcon.svg';
import { UscisModal } from 'containers/Admin/USCIS/uscis';
import { useLocation } from '@reach/router';

export const MigrationFeatures = props => {
  const [openWebinar, setOpenWebinar] = useState(false);
  const [CurrencyOpt, setCurrencyOpt] = useState(false);

  const [uscisModal, setUscisModal] = useState(false);

  // console.log(props.cameFromCaseStatueRoute);

  // console.log(props.previousRoute);

  return (
    <div>
      <Largemodal onClick={() => setOpenWebinar(false)} open={openWebinar}>
        <div style={{ maxWidth: '800px' }}>
          <GroupEvent />
        </div>
      </Largemodal>{' '}
      <Simplemodal onClick={() => setCurrencyOpt(false)} open={CurrencyOpt}>
        <SelectcurrencyFlight />
      </Simplemodal>{' '}
      <Simplemodal onClick={() => setUscisModal(false)} open={uscisModal}>
        <UscisModal />
      </Simplemodal>
      <div className="quickmigrationaction__top">
        <SingleCard
          bg={' #F6F8FF'}
          title={'Move Abroad'}
          click={() => {
            navigate('/pathways');
          }}
          border={'#7F8CE5'}
          imgBg={'#DFE3FF'}
          icon={pathwayIcon}
          bgImg={bgPathway}
        />
        <SingleCard
          bg={' #ECFDF3'}
          title={'Migration Fees'}
          click={() => {
            navigate('/merchants');
          }}
          border={'#6CE9A6'}
          imgBg={'#D1FADF'}
          icon={migration}
          bgImg={bgmigration}
        />
        <SingleCard
          bg={' #F6F8FF'}
          title={'Book Flights'}
          click={() => {
            setCurrencyOpt(true);
          }}
          border={'#E5AEFF'}
          imgBg={'#F0D1FF'}
          icon={flight}
          bgImg={bgflight}
        />
        <SingleCard
          bg={'#FBF3FF'}
          title={'Tracking'}
          click={() => {
            setUscisModal(true);
          }}
          border={'#E5AEFF'}
          imgBg={'#F0D1FF'}
          icon={tracking}
          bgImg={bgtracking}
        />
        <SingleCard
          bg={'#FFFAEB'}
          title={'Join Groups'}
          click={() => {
            navigate('/feeds');
          }}
          border={'#FEC84B'}
          imgBg={'#FEF0C7'}
          icon={groups}
          bgImg={bgGroup}
        />
        <SingleCard
          bg={'#E9FBFF'}
          title={'Watch Webinars'}
          click={() => {
            setOpenWebinar(true);
          }}
          border={'#D1F6FF'}
          imgBg={'#D1F6FF'}
          icon={webinar}
          bgImg={bgwebinar}
        />
      </div>
    </div>
  );
};

const SingleCard = props => {
  return (
    <div>
      {' '}
      <div
        className="quickmigrationaction__vesticard"
        style={{
          background: `${props.bg}`,
          border: `0.5px solid ${props.border}`,
        }}
        onClick={props.click}
      >
        <img
          src={props.icon}
          alt=""
          className="quickmigrationaction__vesticard__cards"
          style={{
            background: `${props.imgBg}`,
          }}
        />
        <p>{props.title}</p>
        <img
          src={props.bgImg}
          alt=""
          className="quickmigrationaction__vesticard__bgcards"
        />
      </div>
    </div>
  );
};
