import {
  FETCH_POSTS_START,
  FETCH_POSTS,
  FETCH_POSTS_FAIL,
} from 'appRedux/constants';
import api from 'appRedux/api';

// export const fetchVestiPosts = groupId => {
//   return dispatch => {
//     dispatch({ type: FETCH_POSTS_START });
//     const url = `/group/posts-and-comments?limit=650`;
//     api
//       .get(url)
//       .then(response => {
//         console.log(response);
//         const data = response?.data?.data?.data;
//         return dispatch({ type: FETCH_POSTS, payload: data });
//       })
//       .catch(error => {
      
//         dispatch({ type: FETCH_POSTS_FAIL, payload: error });
//       });
//   };
// };

export const fetchVestiPosts = () => async dispatch => {

  dispatch({ type: FETCH_POSTS_START });

  try {
    const response = await api.get('/group/posts-and-comments?limit=650');
    const data = response?.data?.data?.data;
   console.log(response);
    dispatch({
      type: FETCH_POSTS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: FETCH_POSTS_FAIL,
      payload: error,
    });
  }
};

export const fetchVestiGroupPosts = groupId => {
  return dispatch => {
    dispatch({ type: FETCH_POSTS_START });
    const url = `/group/messages-and-comments?groupId=${groupId}`;
    api
      .get(url)
      .then(response => {
        const data = response.data.data.data;
        return dispatch({ type: FETCH_POSTS, payload: data });
      })
      .catch(error => {
        // console.log("errrrrrrrr",error);
        dispatch({ type: FETCH_POSTS_FAIL, payload: error });
      });
  };
};


