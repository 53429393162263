import React, { useEffect, useState } from 'react';
import api from 'appRedux/api';
import Loader from 'components/Loader';
import MiaiLoader from 'components/Loader/MiaiLoader';

export const Getpricefromdollar = props => {
  const [prices, setPrices] = useState(null);
  const [isLoading, settIsLoading] = useState(false);

  useEffect(() => {
    settIsLoading(true);
    const fetchpriceData = async () => {
      try {
        const response = await api.get(
          `/vesti-fx/lists?currency=${props.currency}`,
        );
        const myprice = response?.data;
        setPrices(myprice);
        settIsLoading(false);
      } catch (error) {
        settIsLoading(false);
        // console.log(error);
      }
    };

    fetchpriceData();
  }, [props.currency]);

  const toWholeCurrency = num =>
    (num / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  return (
    <>
      {isLoading ? (
        <MiaiLoader />
      ) : (
        <div>
          {props.currency == 'USD'
            ? '$'
            : props.currency == 'NGN'
            ? '₦'
            : props.currency}
          {toWholeCurrency(props.amount * exchangeRate(prices))}
        </div>
      )}
    </>
  );
};

export const exchangeRate = prices => prices?.usdConversionRate;
