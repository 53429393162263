import React, { useEffect, useState } from 'react';
import '../../../containers/Admin/Admin.css';
import selectVcardT from 'assets/TourGuide/Cards/TourImg/selectCardType.svg';
import VvCardstep from 'assets/TourGuide/Cards/TourImg/VirtualForm.svg';
import viaTransfer from 'assets/TourGuide/addmoney/bankTrf.svg';
import AddC from 'assets/TourGuide/Cards/TourImg/createCardT.svg';
import tooltip from 'assets/TourGuide/Tooltip.svg';
import { bankTourFeatures } from 'TourGuide/data';
import '../Intro/intro.scss';
import { LiaTimesSolid } from 'react-icons/lia';
import VvCardstep4 from 'assets/TourGuide/Cards/TourImg/vvcstep4.svg';
import setVpin from 'assets/TourGuide/Cards/TourImg/setPinVvc.svg';

export const AddVcard = props => {
  const [step, setStep] = useState(0);

  switch (step) {
    case 0:
      return (
        <div style={{ height: '100%', width: '100%' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div className="  w-full h-full">
              <div className="myWalletTour">
                <div className="myWalletTour__wallet">
                  <img src={AddC} alt="" />
                </div>

                <div className="TourArticleCContainer">
                  <img
                    className="TourArticleCContainer__tooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Create a new card{' '}
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Click on the{' '}
                            <span
                              style={{ color: '#67A948', fontWeight: '500' }}
                            >
                              “Add icon”
                            </span>{' '}
                            to create a new virtual or physical card.
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 1} of 5`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__active"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(1);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 1:
      return (
        <div style={{}}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div
              className="  w-full h-full"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="">
                {/* <div
                    className="myWalletTour__wallet"
                    style={{ background: 'white' }}
                  >
                    design a card here
                  </div> */}
                <img
                  src={selectVcardT}
                  alt=""
                  style={{ width: '25rem', height: '20rem' }}
                />

                <div className="TourArticleCContainer">
                  <img
                    className="TourArticleCContainer__tooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Create a new card{' '}
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Click on the “Create Virtual Card” Button to create
                            a virtual card. You can swipe to see more card
                            offerings.
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 1} of 5`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>

                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(2);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    case 2:
      return (
        <div style={{}}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div
              className="  w-full h-full"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="">
                {/* <div
                    className="myWalletTour__wallet"
                    style={{ background: 'white' }}
                  >
                    design a card here
                  </div> */}
                <img
                  src={VvCardstep}
                  alt=""
                  style={{ width: '23rem', height: '25rem' }}
                />

                <div className="TourArticleCContainer">
                  <img
                    className="TourArticleCContainer__tooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Create a new card{' '}
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            FIll in the form below to create a new virtual card
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 1} of 5`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>

                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(3);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 3:
      return (
        <div style={{}}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div
              className="  w-full h-full"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="">
                {/* <div
                    className="myWalletTour__wallet"
                    style={{ background: 'white' }}
                  >
                    design a card here
                  </div> */}
                <img
                  src={VvCardstep4}
                  alt=""
                  style={{ width: '20rem', height: '15rem' }}
                />

                <div className="TourArticleCContainer">
                  <img
                    className="TourArticleCContainer__tooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Create a new card{' '}
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Input your verification details in the form field.
                            Ensure you cross check to make sure there are no
                            mistakes.
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 1} of 5`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>

                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(4);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 4:
      return (
        <div style={{}}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div
              className="  w-full h-full"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="">
                {/* <div
                    className="myWalletTour__wallet"
                    style={{ background: 'white' }}
                  >
                    design a card here
                  </div> */}
                <img
                  src={setVpin}
                  alt=""
                  style={{ width: '20rem', height: '15rem' }}

                  //   style={{ width: '23rem', height: '25rem' }}
                />

                <div className="TourArticleCContainer">
                  <img
                    className="TourArticleCContainer__tooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Create a new card{' '}
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Set up a secure card PIN to confirm your virtual
                            card creation
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 1} of 5`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>
                        </ul>

                        <div
                          onClick={() => {
                            props.setStep(0);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Back
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    default:
  }
};
