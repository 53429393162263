import React, { useEffect, useState } from 'react';
import { Platformbutton } from 'components/common/button/button';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Registerleft } from 'components/auth/register/registerfeatures';
import { Formik } from 'formik';
import { SignInSchema } from 'helpers/schema';
import { navigate } from '@reach/router';
import { connect, useDispatch } from 'react-redux';
import { Simplemodal } from 'components/common/simplifiedmodal';
import Twofalogin from 'components/auth/twofa/twofa';
import { userSignInMiai } from './actions';
import { AlertError } from 'components/common/alertboxes/alertboxes';
import { userSignOut, verify2faSignin } from 'appRedux/actions/auth';
import { AUTH_LOAD } from 'appRedux/constants';
import VestiLogo from 'assets/Vesti_logo.svg';

// import VestiLogo from 'assets/xmasLogo/xmasLogo.svg';

const MiaiLogin = props => {
  const [modal, setModal] = useState(false);
  const [state, setState] = useState({
    data: '',
    token: '',
    code: '',
  });

  var handle2fa = (value, token) => {
    setModal(true);
    setState({ ...state, data: value, token: token });
  };
  // eslint-disable-next-line
  var from = document.referrer
    ? document.referrer.includes('/auth?merchant=')
      ? `\merchants?merchant=${document.referrer.split('=')[1]}`
      : props.location?.state
      ? props.location?.state.from
      : ''
    : '/miai';
  const dispatch = useDispatch();

  //   useEffect(() => {
  //     props.userSignOut();
  //   }, []);

  const storedUserData = localStorage.getItem('myuserData');
  const userData = JSON.parse(storedUserData);

  return (
    <div className="">
      <div className="">
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={SignInSchema}
          onSubmit={values => {
            var data = {
              username: values.email,
              password: values.password,
            };
            props.userSignInMiai(
              JSON.stringify(data, null, 2),
              from,
              (value, token) => handle2fa(value, token),
            );
          }}
        >
          {formik => {
            // eslint-disable-next-line
            const {
              errors,
              touched,
              // isValid,
              values,
              handleChange,
              handleSubmit,
            } = formik;
            return (
              <form
                onSubmit={handleSubmit}
                className="w-full flex flex-col gap-[50px] lg:w-[450px]"
              >
                <div className="w-full flex flex-col gap-[10px]">
                  <div className="signInTitleSubtitle">
                    <h1 className="signInTitleSubtitle__title">Log in</h1>
                    <p className="signInTitleSubtitle__subtile">
                      Log in to start interacting with Miai ™
                    </p>
                  </div>

                  <Inputfloat
                    label="email"
                    type="email"
                    name="email"
                    value={values.email}
                    disabled={false}
                    placeholder="ajitd@gmail.com"
                    onChange={handleChange}
                    invalid={errors.email && touched.email && 'true'}
                    error={errors.email && touched.email ? errors.email : ''}
                  />
                  <div className="flex flex-col gap-[10px]">
                    <Inputfloat
                      label="Password"
                      type="password"
                      name="password"
                      value={values.password}
                      disabled={false}
                      placeholder="Enter your password"
                      onChange={handleChange}
                      invalid={errors.password && touched.password && 'true'}
                      error={
                        errors.password && touched.password
                          ? errors.password
                          : ''
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-[15px]">
                  {props.authErr && (
                    <AlertError
                      body={
                        props.authErr
                          ? props.authErr
                          : 'Invalid login credentials (email address / Password)'
                      }
                    />
                  )}
                  <div className="flex flex-col gap-[10px] items-center w-full">
                    <Platformbutton
                      name="Continue"
                      type="submit"
                      disabled={props.loading ? true : false}
                    />
                    {/* <Platformbutton
                        type="link"
                        text=" New user? "
                        title="  Create account"
                        click={() => {
                          dispatch({
                            type: AUTH_LOAD,
                            payload: { authErr: '', authMessage: '' },
                          });
                          navigate('/auth/register');
                        }}
                      /> */}
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, common }) => {
  const { authMessage, authLoad, authErr } = auth;
  const { loading } = common;
  return {
    loading,
    authMessage,
    authLoad,
    authErr,
  };
};

const mapDispatchToProps = {
  userSignInMiai,
  verify2faSignin,
  userSignOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(MiaiLogin);
