import React from 'react';
import { navigate } from '@reach/router';
import { Comment } from './comment';
import { Postcomment } from './postcomment';
import dummyAvatar from 'assets/dummy-avatar.png';
import { useState, useEffect } from 'react';
// import { Form, Formik, Field } from 'formik';
import ReactTimeAgo from 'react-time-ago';
import { errorMessage } from 'helpers/utils';
import '../Admin.css';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import api from 'appRedux/api';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
// import { post } from "jquery";
import { parseDate } from 'helpers/hooks';
import { openShare } from 'appRedux/actions/Alertbox';
import { FcLike } from 'react-icons/fc';
import { NewFeeds } from './NewFeedv2/Index';
import { LiaCommentDots } from 'react-icons/lia';
import { IoHeartOutline } from 'react-icons/io5';
import { IoMdShare } from 'react-icons/io';
import saveIcon from '../../../assets/newFeedsImg/firstSecImg/saveIcon.png';
import { FaHeart } from 'react-icons/fa6';
import { SimpleCancelmodal } from 'components/common/simplifiedmodal';
import { getFriendsFeedsDetails } from 'appRedux/actions/Feedschat';

const extractUrls = text => {
  // Regular expression to match URLs
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  return text?.replace(urlRegex, url => {
    return `<a href="${url}" target="_blank">${url}</a>`;
  });
};

export const Singlepost = props => {
  const [showModal, setShowModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [myImage, setMyImage] = useState(null);
  const [openComment, setOpenComment] = useState({
    id: null,
    name: 'Open',
  });
  const [comments, setComments] = useState({
    id: null,
    name: 'Open',
  });
  const handleClick = id => {
    if (id === openComment.id) {
      setOpenComment({ id: id, name: 'Close' });
      // setOpenComment({ id: null, name: 'Open' });
    } else {
      setOpenComment({ id: id, name: 'Close' });
    }
  };
  const openClick = id => {
    if (id === comments.id) {
      setComments({ id: null, name: 'Open' });
    } else {
      setComments({ id: id, name: 'Close' });
    }
  };

  const dispatch = useDispatch();
  const postComment = (values, postId) => {
    var image = props.userData.profilePictureURL;

    if (
      props.userData.firstName === null ||
      props.userData.lastName === null ||
      // image === null ||
      props.userData.firstName === undefined
    ) {
      openNotificationWithIconErr(
        'Comment',
        'only Users with full profile details can comment, please update your profile',
        'error',
      );
    } else {
      api
        .post('/group/posts-and-comments', {
          ...values,
          action: 'comment',
          actionId: postId,
          // profilePictureURL: image,
        })
        .then(res => {
          openNotificationWithIcon(
            'You have successfully commented on a post',
            'Success',
            'success',
          );
          dispatch(props.fetchVestiPosts());
          setOpenComment(false);
        })
        .catch(err => {
          openNotificationWithIconErr(errorMessage(err), 'Comment', 'error');
        });
    }
  };

  // const deletePost = postId => {
  //   if (props.group === 'group') {
  //     props.deleteMessage(postId);
  //     dispatch(props.fetchVestiPosts());
  //   } else {
  //     api
  //       .delete(`/deletePostById/${postId}`)
  //       .then(res => {
  //         openNotificationWithIcon(
  //           'You have successfully deleted your post',
  //           'Success',
  //           'success',
  //         );
  //         dispatch(props.fetchVestiPosts());
  //       })
  //       .catch(err => {
  //         openNotificationWithIconErr(errorMessage(err), 'Delete', 'error');
  //       });
  //   }
  // };

  const deletePost = postId => {
    const payload = {
      actionId: postId,
      action: 'message',
    };

    api
      .delete(`/group/delete-post-message-or-comment`, { data: payload })
      .then(res => {
        openNotificationWithIcon(
          'You have successfully deleted your Comment',
          'Success',
          'success',
        );
        dispatch(props.fetchVestiPosts());
      })
      .catch(err => {
        openNotificationWithIconErr(errorMessage(err), 'Delete', 'error');
      });

    console.log(postId);
  };

  const deleteComment = commentId => {
    const payload = {
      actionId: commentId,
      action: 'comment',
    };

    api
      .delete(`/group/delete-post-message-or-comment`, { data: payload })
      .then(res => {
        openNotificationWithIcon(
          'You have successfully deleted your Comment',
          'Success',
          'success',
        );
        dispatch(props.fetchVestiPosts());
      })
      .catch(err => {
        openNotificationWithIconErr(errorMessage(err), 'Delete', 'error');
      });

    // console.log(commentId);
  };

  const reactToPost = (postId, userId) => {
    const url = `/group/react-to-post-and-comments`;
    const payload = {
      actionId: postId,
      action: 'message',
    };
    api
      .post(url, payload)
      .then(res => {
        // openNotificationWithIcon(res.data.message, 'Success', 'success');
        dispatch(props.fetchVestiPosts());
      })
      .catch(err => {
        openNotificationWithIconErr(errorMessage(err), 'Like Post', 'error');
      });
  };

  const reactToCommentPost = (postId, userId) => {
    const url = `/group/react-to-post-and-comments`;
    const payload = {
      actionId: postId,
      action: 'comment',
    };
    api
      .post(url, payload)
      .then(res => {
        // openNotificationWithIcon(res.data.message, 'Success', 'success');
        dispatch(props.fetchVestiPosts());
      })
      .catch(err => {
        openNotificationWithIconErr(errorMessage(err), 'Like Post', 'error');
      });
  };
  //  console.log(props.images);
  const formattedDescription = extractUrls(props.description);

  // drop down for single vesti post
  const [dropDownVisible, setDropDownVisible] = useState(false);

  const toggleDropDown = () => {
    setDropDownVisible(!dropDownVisible);
  };

  //

  const handleProfileClick = (userId, fullname, profilepic) => {
    localStorage.setItem('FriendUserName', fullname);
    localStorage.setItem(
      'FriendProfilepic',
      profilepic ? profilepic : dummyAvatar,
    );
    dispatch(getFriendsFeedsDetails(fullname, profilepic));

    navigate(`/feeds-chat/${userId}`);
  };

  // ImageModal
  // const [imageModalOpen, setImageModalOpen] = useState(false)
  // const [currentImage, setCurrentImage] = useState(null)

  // const openImageModal = (imageSrc) => {
  //   setCurrentImage(imageSrc)
  //   setImageModalOpen(true)
  // }

  // const closeImageModal = () => {
  //   setImageModalOpen(false)
  //   setCurrentImage(null)
  // }
  console.log('here', props.id);

  return (
    <div>
      <Simplemodal onClick={() => setShowModal(false)} open={showModal}>
        <p>You're about to leave this page</p>
      </Simplemodal>

      <SimpleCancelmodal onClick={() => setOpenModal(false)} open={openModal}>
        <img
          src={myImage}
          alt=""
          style={{ height: '65vh', width: '100%', objectFit: 'cover' }}
        />
      </SimpleCancelmodal>

      <div
        key={props.id}
        className="single-vesti-post"
        style={{ marginTop: '-20px', width: '100%', height: 'auto' }}
      >
        {/* Top Content */}
        <div className="single-vesti-post-top">
          <span
            className="mr-3"
            style={{
              width: '60%',
              borderRadius: '50%',
              flexShrink: 0,
              display: 'flex',
              alignItems: 'flex-start',
            }}
            onClick={() => {
              props.setProfile(true);
              props.setDetails({
                image:
                  props.profileUrl !== null ? props.profileUrl : dummyAvatar,
                name: props.fullname ? props.fullname : props.username,
                username: props.username,
                email: props.email,
                userId: props.userId,
              });
            }}
          >
            <img
              src={props.profileUrl !== null ? props.profileUrl : dummyAvatar}
              alt="proflie"
              className="single-vesti-post-img"
              style={{
                width: '70px',
                height: '70px',
                borderRadius: '50%',
                objectFit: 'cover',
                marginRight: '10px',
              }}
            />
            <div>
              <div style={{ fontWeight: 500 }}>
                {' '}
                <div style={{ position: 'relative' }}>
                  {' '}
                  <p
                    style={{ fontWeight: 500, cursor: 'pointer' }}
                    onMouseEnter={toggleDropDown}
                  >
                    {props.fullname}
                  </p>
                  {dropDownVisible && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '-5px',
                        left: '110px',
                        backgroundColor: '#fff',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        borderRadius: '4px',
                        zIndex: 1,
                      }}
                      onMouseLeave={toggleDropDown}
                    >
                      {/* { props.userData?.id === props.userId ? <p></p> :  <p style={{ margin: 0, padding: '8px 12px', cursor: 'pointer', fontSize: '12px' }} onClick={() => handleProfileClick(props.userId, props.fullname, props.profileUrl)}>Message</p>} */}
                    </div>
                  )}
                </div>
                {props.userData.username === 'Olusola Amusan' ||
                props.userData.username === 'Duke' ||
                props.userData.username === 'Opeyemi Balogun' ||
                props.userData.username === 'olusolavesti' ||
                props.userData.username === 'Laurie_harts' ||
                props.userData.email === 'bigduke2045@gamil.com' ||
                props.userData.email === 'philip@wevesti.com' ||
                props.userData.email === 'femi@wevesti.com' ||
                props.userD?.email === 'anita@wevesti.com' ? (
                  <p style={{ color: '#CCCCCC' }}>{props.email}</p>
                ) : (
                  <p></p>
                )}
              </div>
              <div
                style={{ color: '#CCCCCC', fontSize: '12px' }}
                className="mb-3"
              >
                <ReactTimeAgo
                  date={Date.parse(parseDate(props.createdAt, 'yyyy/mm/dd'))}
                  locale="en-US"
                />
              </div>
            </div>
          </span>
        </div>

        {/* Post Content */}
        <div className="w-100" style={{ cursor: 'pointer' }}>
          <p
            className="mb-2"
            onClick={() => props.onClick && props.onClick(props.id)}
            style={{ fontSize: '14px', whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: formattedDescription }}
          ></p>
        </div>

        {/* {props.images?.length === 2 ? (<div style={{display: 'flex', gap: '6px', width: '50%', hweight: 'auto', objectFit: 'cover', borderRadius: '10px', }}></div>) : props.images?.length > 2 ? (<div style={{}}></div>) : (<div></div>) } */}
        {/* start */}
        <div style={{ height: 'auto' }}>
          {props.images &&
            (props.images?.length > 2 ? (
              <div className="two-grid-container ">
                <div className="item1">
                  <img
                    // className="post-image"
                    src={props.images[0]}
                    alt="post ima"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setMyImage(props.images[0]);
                      setOpenModal(true);
                    }}
                  />
                </div>

                <div className="item2">
                  <img
                    // className="post-image"
                    src={props.images[1]}
                    alt="post ima"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setMyImage(props.images[1]);
                      setOpenModal(true);
                    }}
                  />
                </div>

                <div className="item3">
                  <img
                    // className="post-image"
                    src={props.images[2]}
                    alt="post ima"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setMyImage(props.images[2]);
                      setOpenModal(true);
                    }}
                  />
                </div>
              </div>
            ) : props.images?.length === 2 ? (
              <div className="double" style={{ width: '100%' }}>
                <img
                  // className="post-image"
                  src={props.images[0]}
                  alt="post ima"
                  style={{
                    width: '50%',
                    height: '350px',
                    objectFit: 'cover',
                    borderRadius: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setMyImage(props.images[0]);
                    setOpenModal(true);
                  }}
                />

                <img
                  // className="post-image"
                  src={props.images[1]}
                  alt="post ima"
                  style={{
                    width: '50%',
                    height: '350px',
                    objectFit: 'cover',
                    borderRadius: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setMyImage(props.images[1]);
                    setOpenModal(true);
                  }}
                />
              </div>
            ) : (
              <img
                className="single"
                src={props.images}
                alt="post ima"
                style={{
                  width: '100%',
                  height: '350px',
                  objectFit: 'cover',
                  borderRadius: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setMyImage(props.images[0]);
                  setOpenModal(true);
                }}
              />
            ))}
        </div>

        {/* stop */}

        {/* <div > */}
        {/* Likes and Comment */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '3%',
          }}
        >
          <div
            className="like-click"
            onClick={() => reactToPost(props.id, props.userId)}
            style={{
              marginRight: '10px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
            }}
          >
            {props.likes?.includes(props.userData?.id) ? (
              <FaHeart style={{ color: 'red', fontSize: '18px' }} />
            ) : (
              <IoHeartOutline style={{ fontSize: '18px' }} />
            )}

            {props.likes?.length}
            {props.likes?.length > 1 ? (
              <p style={{ fontSize: '14px' }}>likes</p>
            ) : (
              <p style={{ fontSize: '14px' }}>like</p>
            )}
          </div>

          {/* <p
                  className="like-click"
                  onClick={() => reactToPost(props.id, props.userId)}
                  style={{ marginRight: '10px', cursor: 'pointer' }}
                >
                  <i
                    className={
                      props.hasLiked === 'true' ? `fas fa-heart` : `far fa-heart`
                    }
                    style={{ color: '#97E373' }}
                  />{' '}
                  like
                </p> */}
          {/* <p className="like-click" onClick = { () => props.hasLiked === "true" ? props.likes ===0 ? '': unLikePost(props.id) :LikePost(props.id) }  style={{ marginRight: "10px"  }}><i className= { props.hasLiked === "true" ? `fas fa-heart` : `far fa-heart`} style={ { color: '#97E373'} }/> {props.likes < 0 ? 0 : props.likes } like{props.likes > 1 ? 's' :''}</p> */}

          <div
            className={`comments-click ${
              comments.id === props.id ? ' active' : ' disable'
            } `}
            onClick={() => openClick(props.id)}
            style={{
              gap: '8%',
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              color: '#000000',
            }}
          >
            <LiaCommentDots
              className="fas fa-comments"
              style={{ fontSize: '18px' }}
            />
            (
            {props.userComments ? (
              props.userComments.length
            ) : (
              <div style={{ fontSize: '10px' }}>0</div>
            )}
            )<p style={{ fontSize: '14px' }}>Comments</p>
          </div>

          <div style={{ gap: '4px' }}>
            <Postlink
              toolTip="Share Post"
              click={() => dispatch(openShare(props.id))}
              postId={props.id}
            >
              <IoMdShare
                style={{
                  cursor: 'pointer',
                  fontSize: '18px',
                }}
              />
              <p style={{ fontSize: '14px', cursor: 'pointer' }}>Share</p>
            </Postlink>
          </div>

          <div>
            {props.userData.username === 'olusolavesti' ? (
              <Postlink toolTip="Save Post" type="save">
                <img src={saveIcon} alt="" />
              </Postlink>
            ) : null}

            {props.userData.id === props.userId ||
            props.userData.username === 'olusolavesti' ? (
              <Postlink
                toolTip="Delete Post"
                click={deletePost}
                postId={props.id}
              >
                <i
                  className="fas fa-trash-alt"
                  style={{
                    color: '#cccccc',
                  }}
                />
              </Postlink>
            ) : (
              <div style={{ gap: '16px' }}>
                <Postlink toolTip="Save Post" type="save">
                  <img
                    src={saveIcon}
                    alt=""
                    style={{ width: '16px', cursor: 'pointer' }}
                  />
                  <p style={{ fontSize: '14px', cursor: 'pointer' }}>Save</p>
                </Postlink>
              </div>
            )}
          </div>
        </div>
        {/* </div> */}

        {/* <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
            </div> */}

        <div>
          <p
            className={`reply-click  ${
              openComment.id === props.id ? ' active' : ' disable'
            }  `}
            style={{ marginRight: '10px' }}
            onClick={() => handleClick(props.id)}
          >
            {/* <input type="text" placeholder='Add your comment'/> */}
            {/* <i className="fas fa-share" /> Reply  */}
          </p>
        </div>

        <div style={{ marginTop: '4%' }} className="postCommentCom">
          <Postcomment
            id={props.id}
            postComment={postComment}
            openComment={openComment.id}
            userData={props.userData}
            insertEmoji={props.insertEmoji}
          />
        </div>

        <div
          className={`comments-container mt-5 ${
            comments.id === props.id ? ' active' : ' '
          } `}
        >
          {props.userComments?.length > 0 ? (
            (props.userComments > 3
              ? props.userComments?.slice(0, 3)
              : props.userComments
            ).map(comment => {
              return (
                <Comment
                  key={comment.id}
                  id={comment.id}
                  avatar={comment?.creator?.profilePictureURL}
                  username={comment?.creator?.username}
                  firstname={comment?.creator?.firstName}
                  lastname={comment?.creator?.lastName}
                  description={comment.comment}
                  createdAt={comment.createdAt}
                  authUserId={props.userData.id}
                  userId={comment.userId}
                  deleteComment={deleteComment}
                  userData={props.userData}
                  reactToCommentPost={reactToCommentPost}
                  reactions={comment?.reactions}
                  marginLeft="50px"
                />
              );
            })
          ) : (
            <p></p>
          )}
        </div>

        <hr className="single-vesti-hr" />
      </div>
    </div>
  );
};

export const Postlink = props => {
  return (
    <div
      className="d-flex justify-content-center align-items-center single-vesti-post-link"
      style={
        {
          // fontSize: '23px'
          // height: '40px',
          // width: '40px',
          // backgroundColor: '#F8F6F5',
          // borderRadius: '100%',
        }
      }
      onClick={() => (props.type === 'save' ? '' : props.click(props.postId))}
    >
      {props.children}
      <span className="tooltiptext">{props.toolTip}</span>
    </div>
  );
};
const mapStateToProps = ({ auth }) => {
  const { authUser, userEmail } = auth;

  return {
    userEmail,
    authUser,
  };
};

export default connect(mapStateToProps)(Singlepost);
