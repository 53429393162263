import React from 'react';
import triple from '../../../assets/triple.png';
import './Ovwhatis.scss';

const Ovwhatis = () => {
  return (
    <div className="ovwhatis-container">
      <div className="ovwhatis-inner">
        <div className="__left">
          <img className="img-fluid" src={triple} alt="" />
        </div>
        <div className="__right">
          <div className="__top">
            <div className="__title">
              <p>
                Cutting-edge approach to <span>O-1</span>
              </p>
            </div>
            <div className="__body">
              <p>
                Experience the difference with our innovative platform and
                expert support. Say goodbye to the hassle of traditional O-1
                visa services.
              </p>
            </div>
          </div>

          <div className="__top">
            <div className="__title">
              <p>Automated Immigration workflows</p>
            </div>
            <div className="__body">
              <p>
                Our advanced algorithms and team of experts make the O-1 visa
                process easier and more efficient. Get the support you need,
                every step of the way.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ovwhatis;
