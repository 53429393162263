import React, { useState } from 'react';
import '../../../components/vesticards/selectcard.scss';
import { Platformbutton } from 'components/common/button/button';
import DividedPinInput from 'components/common/DividedPinInput';
import './allcards.scss';

const Temporarypin = props => {
  const [code, setCode] = useState(null);
  return (
    <div className="TemporaryPin">
      <div className="TemporaryPin__Header">
        <h1>Temporary PIN</h1>
        <p>
          Enter the 6-digit code that has been sent to your registered phone
          number
        </p>
      </div>

      <div>
        <DividedPinInput onChange={props.setGGTpin} len={6} />
      </div>
      <p
        style={{
          fontSize: '13px',
          fontWeight: '500',
          textAlign: 'right',
        }}
      >
        Didn’t get code ?{' '}
        <span style={{ cursor: 'pointer', color: '#67A948' }}>Resend here</span>
      </p>

      <div className="flexButton">
        <div className="flexButton__cancel">
          <Platformbutton
            type="secondary"
            name={'Go Back'}
            click={() => {
              props.setGGTpinModal(false);
            }}
          />
        </div>

        <div style={{ width: '100%' }}>
          <Platformbutton
            type="normal"
            name={'Get temporary PIN'}
            click={() => {
              // console.log(props.GGTpin);
              props.handleConfirmPin(props.GGTpin);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Temporarypin;
