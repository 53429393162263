import {
  MIAI_TEST,
  FETCH_CHAT_DATA_FAILURE,
  FETCH_CHAT_DATA_SUCCESS,
  SET_LAST_CHAT_ID,
  CHAT_HISTORY,
  MIAI_INPUT_FIELD,
} from 'appRedux/constants';

const INIT_STATE = {
  closeBox: false,
  openBox: false,
  chatData: [],
  lastchatId: '',
  chats: [],
  error: null,
  inputText: '',
};

export default (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case MIAI_TEST: {
      return { ...state, ...payload };
    }

    case FETCH_CHAT_DATA_SUCCESS:
      return {
        ...state,
        chatData: payload,
        error: null,
      };

    case SET_LAST_CHAT_ID:
      return {
        ...state,

        lastchatId: payload,
        error: null,
      };

    case CHAT_HISTORY:
      return {
        ...state,
        chats: payload,
        error: null,
      };

    case FETCH_CHAT_DATA_FAILURE:
      return {
        ...state,
        error: payload,
      };

    case MIAI_INPUT_FIELD:
      return {
        ...state,
        inputText: payload,
      };

    default:
      return { ...state };
  }
};
