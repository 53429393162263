import React, { useState, useEffect } from 'react';
import GuideBanner from 'assets/TourGuide/tourhomeBanner.svg';
import './TourGuide.scss';
import { myGuides } from './data';
import { navigate } from '@reach/router';
// import { OpenCardModal, CloseCardModal } from 'appRedux/actions/Tourguide';
import { openCardTourModal } from 'appRedux/actions/Tourguide';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

const Intro = props => {
  return (
    <div>
      <div className="TourGuideHome">
        <img src={GuideBanner} alt="" />
      </div>

      <div className="TourGuideHome__article">
        <h1>Take a tour</h1>
        <p>
          Hello there, let's take you on a brief tour to ensure you have a
          seamless and enriching experience with Vesti.
        </p>
        <p>Select a section you would like to learn more about:</p>
      </div>

      <div className="TourGuideHome__myguides">
        {myGuides.map(guide => {
          return (
            <div className="TourGuideHome__guide">
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                <p>{guide.title}</p>
                <img src={guide.img} alt="" />
              </div>

              <div
                className="TourGuideHome__startBtn"
                onClick={() => {
                  if (guide.link === '/bank') {
                    props.handleBankTour();
                  }
                  if (guide.link === '/bookflight') {
                    props.handleFlightTour();
                  } else {
                    props.handleClick();
                    navigate(`${guide.link}`); // Log the value of cardTourModal
                  }
                }}
              >
                Start
              </div>
            </div>
          );
        })}
      </div>

      <div
        style={{
          marginTop: '14px',
          color: '#67a948',
          fontSize: '12px',
          fontWeight: '500',
          cursor: 'pointer',
          textAlign: 'center',
        }}
        onClick={() => {
          props.setStep(1);
        }}
      >
        I can find my way around, thanks
      </div>
    </div>
  );
};

const Reference = props => {
  return (
    <div>
      <div className="TourGuideHome">
        <img src={GuideBanner} alt="" />
      </div>

      <div className="TourGuideHome__article">
        <h1>Quick help finding this later</h1>
        <p>To access this guide in the future:</p>

        <ul
          style={{
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
          }}
        >
          <li
            style={{
              color: '#2B5219',
              fontSize: '13px',
              fontWeight: '600',
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                background: '#2B5219',
                width: '6px',
                height: '6px',
                borderRadius: '12px',
              }}
            ></p>
            Navigate to the sidebar menu.
          </li>
          <li
            style={{
              color: '#2B5219',
              fontSize: '13px',
              fontWeight: '600',
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                background: '#2B5219',
                width: '6px',
                height: '6px',
                borderRadius: '12px',
              }}
            ></p>
            Locate and tap on the "Profile" option within the menu.
          </li>
          <li
            style={{
              color: '#2B5219',
              fontSize: '13px',
              fontWeight: '600',
              display: 'flex',
              paddingLeft: '14px',
              position: 'relative',
            }}
          >
            <p
              style={{
                background: '#2B5219',
                width: '6px',
                height: '6px',
                borderRadius: '20px',
                position: 'absolute',
                top: '10px',
                left: '0px',
              }}
            ></p>
            <p>
              Inside the settings menu, you'll find a section labeled "Tour
              Guide." Select "Tour Guide" from this section to access the guide.
            </p>
          </li>
        </ul>

        <div
          style={{
            background: '#67A948',
            color: 'white',
            padding: '8px 10px',
            width: '10rem',
            textAlign: 'center',
            borderRadius: '10px',
            cursor: 'pointer',
          }}
          onClick={() => {
            props.setTourGuide(false);
          }}
        >
          Okay, Got it!
        </div>
      </div>
    </div>
  );
};

const TourGuide = props => {
  const dispatch = useDispatch();

  const IsCardTrue = useSelector(state => state.Tourguide.openCardTourModal);

  const handleClick = () => {
    dispatch(openCardTourModal());
  };
  // useEffect(() => {
  //   dispatch(OpenCardModal());
  // });

  const [step, setStep] = useState(0);

  const handleBankTour = () => {
    props.setTourGuide(false);
    props.setBankTourmodal(true);
    props.setFlightTour(false);
  };

  const handleFlightTour = () => {
    props.setTourGuide(false);
    props.setBankTourmodal(false);
    props.setFlightTour(true);
  };

  switch (step) {
    case 0:
      return (
        <Intro
          step={step}
          setStep={setStep}
          setTourGuide={props.setTourGuide}
          handleBankTour={handleBankTour}
          handleClick={handleClick}
          handleFlightTour={handleFlightTour}
        />
      );
    case 1:
      return (
        <Reference
          step={step}
          setStep={setStep}
          setTourGuide={props.setTourGuide}
        />
      );
  }
};

export default TourGuide;
