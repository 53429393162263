import React from 'react';
import memberIcon from '../../../../assets/membership/memberIcon.svg';
import detailIcon from '../../../../assets/membership/seeDetails.svg';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const EconomyPlan = props => {
  return (
    <div>
      {' '}
      <div className="Profilemembership__plan">
        <div className="Profilemembership__top">
          <div className="Profilemembership__top__col">
            {}
            <p className="Profilemembership__top__title">
              {/* {`${props.isSuccess}` ? (
                <>
                  Economy
                  <span>Free</span>
                </>
              ) : (
                <Skeleton width={100} duration={30} />
              )} */}
              Economy <span>Free</span>
            </p>
            <p
              className="Profilemembership__top__planDetails"
              onClick={() => {
                props.setOpnSubFeature(true);
              }}
            >
              See plan details <img src={detailIcon} alt="" />
            </p>
          </div>
          <p
            className="Profilemembership__subscribe"
            onClick={() => {
              props.setOpnSub(true);
            }}
          >
            Subscribe to a plan
          </p>
        </div>

        <div className="Profilemembership__bottom">
          <img src={memberIcon} alt="" />

          <article>
            <p className="Profilemembership__bottom__title">
              Upgrade your current plan
            </p>
            <p className="Profilemembership__bottom__subtitle">
              Upgrade to <span>First Class</span> or <span>Business Class</span>{' '}
              Plans for a premium migration experience. Enjoy enhanced security,
              faster processing, and expert support for a smoother financial
              transition.
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default EconomyPlan;
