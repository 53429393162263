import React, { useState } from 'react';
import './singlesavings.scss';
import piggy from '../../assets/ashpiggy.svg';
import greenpiggy from '../../assets/greenpiggy.svg';
import yellowpiggy from '../../assets/yellowpiggy.svg';
import info from '../../assets/yellowinfo.svg';
import savingsimage from '../../assets/topsavings.svg';
import plantcoinfull from '../../assets/plantcoinfull.svg';
import plantcoinhalf from '../../assets/plantcoinhalf.svg';
import { navigate } from '@reach/router';
import { formatAmount, getCurrency, getStatusImage } from 'helpers/utils';
// import { FaEye, FaEyeSlash } from 'react-icons/fa';

const SavingsTabComponent = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const handleTabClick = tabId => {
    setActiveTab(tabId);
  };

  return (
    <div className="savingstabcomponent ">
      <div
        className="flex flex-row gap-2 p-1 mb-4 rounded"
        // style={{ backgroundColor: '#e0f2d7' }}
      >
        {tabs.map(tab => (
          <b
            key={tab.id}
            onClick={() => handleTabClick(tab.id)}
            className={'px-4 py-2 mr-2 rounded cursor-pointer tabbutton'}
            style={{
              backgroundColor: activeTab === tab.id ? '#67A948' : '#e0f2d7',
              color: activeTab === tab.id ? 'white' : '#3E6F26',
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            {tab.label}
          </b>
        ))}
      </div>

      <div className="tab-content">
        {tabs.map(tab => (
          <div
            key={tab.id}
            style={{ display: activeTab === tab.id ? 'block' : 'none' }}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SavingsTabComponent;

export const Singlesavings = props => {
  // console.log('Singlesavings : ', props);
  var goTo = () => {
    props.status && navigate(`/savings-details`, { state: { id: props.id } });
  };
  return (
    <div
      className={`singlesaving`}
      style={{
        backgroundColor:
          props.status === 'active'
            ? '#F7FFF3'
            : props.status === 'completed' ||
              props.status === 'cancelled' ||
              props.status === 'terminated'
            ? '#FFFCF5'
            : '',
      }}
    >
      <div className="singlesaving__top">
        <div className="singlesaving__name">
          <p>{props.name}</p>
          <p>Savings Balance</p>
        </div>
        <img src={getStatusImage(props.status)} alt="status svg" />
      </div>
      <div className="singlesaving__inner">
        <div className="singlesaving__left">
          <div className="singlesaving__amount">
            <p>
              {getCurrency(props.currency)}
              {formatAmount(props.amount / 100, 2)}
            </p>
          </div>
        </div>

        {(props.status === 'completed' &&
          props.disbursementStatus === 'completed') ||
          (props.status === 'cancelled' &&
            props.disbursementStatus === 'completed') ||
          (props.status === 'terminated' &&
            props.disbursementStatus === 'completed' && (
              <div className="singlesaving__bottom">
                <div className="singlesaving__enddate">
                  <img src={info} alt="info svg" />
                  <p>{`This saving plan has been ${props.status}`} </p>
                </div>
              </div>
            ))}

        {props.status === 'pending' && (
          <div className="singlesaving__bottom">
            {props.status === 'active' && (
              <p className="singlesaving__seemore" onClick={() => goTo()}>
                See more details <i className="fas fa-arrow-right"></i>
              </p>
            )}
            <div className="button-container">
              {props.status === 'pending' && (
                <p onClick={() => props.onClick()}>Top Up</p>
              )}
            </div>
          </div>
        )}

        {props.status === 'terminated' &&
          props.disbursementStatus === 'default' && (
            <div className="singlesaving__bottom">
              {props.status === 'active' && (
                <p className="singlesaving__seemore" onClick={() => goTo()}>
                  See more details <i className="fas fa-arrow-right"></i>
                </p>
              )}
              <div className="button-container">
                {props.status === 'active' && (
                  <p onClick={() => props.onClick()}>Top Up</p>
                )}
              </div>
            </div>
          )}

        {props.status === 'completed' &&
          props.disbursementStatus === 'pending' && (
            <div className="singlesaving__bottom">
              <p className="singlesaving__seemore" onClick={() => goTo()}>
                See more details <i className="fas fa-arrow-right"></i>
              </p>
              <div
                className="button-container"
                style={{ visibility: 'hidden' }}
              >
                <p onClick={() => props.onClick()}>Top Up</p>
              </div>
            </div>
          )}

        {props.status === 'completed' &&
          props.disbursementStatus === 'default' && (
            <div className="singlesaving__bottom">
              <p className="singlesaving__seemore" onClick={() => goTo()}>
                See more details <i className="fas fa-arrow-right"></i>
              </p>

              <div
                className="button-container"
                style={{ visibility: 'hidden' }}
              >
                <p onClick={() => props.onClick()}>Top Up</p>
              </div>
            </div>
          )}

        {props.status === 'cancelled' &&
          props.disbursementStatus === 'default' && (
            <div className="singlesaving__bottom">
              <p className="singlesaving__seemore" onClick={() => goTo()}>
                See more details <i className="fas fa-arrow-right"></i>
              </p>

              <div
                className="button-container"
                style={{ visibility: 'hidden' }}
              >
                <p onClick={() => props.onClick()}>Top Up</p>
              </div>
            </div>
          )}

        {props.status === 'active' && (
          <div className="singlesaving__bottom">
            {props.status === 'active' && (
              <p className="singlesaving__seemore" onClick={() => goTo()}>
                See more details <i className="fas fa-arrow-right"></i>
              </p>
            )}
            <div className="button-container">
              {props.status === 'active' && (
                <p onClick={() => props.onClick()}>Top Up</p>
              )}
            </div>
          </div>
        )}

        {/* piggy base picture n color */}
        {(props.status === 'completed' ||
          props.status === 'cancelled' ||
          props.status === 'terminated') && (
          <img
            src={yellowpiggy}
            alt="Card img"
            className="absolute bottom-0 right-0 w-1/3 rounded-md"
          />
        )}
        {props.status === 'pending' && (
          <img
            src={piggy}
            alt="Card img"
            className="absolute bottom-0 right-0 w-1/3 rounded-md"
          />
        )}
        {props.status === 'active' && (
          <img
            src={greenpiggy}
            alt="Card img"
            className="absolute bottom-0 right-0 w-1/3 rounded-md"
          />
        )}
      </div>
    </div>
  );
};

export const SavingsCard = props => {
  const [show, setShow] = useState(false);
  return (
    <div className="savingscard text-white p-4 lg:p-8 xl:p-8 w-full md:w-2/3 lg:w-1/2 xl:w-1/3 mx-auto my-8 relative mb-2 lg:mb-5">
      {/* Card Content */}
      <div className="savingscard-inner">
        <p className="mb-2">TOTAL SAVINGS</p>

        {/* <p className="mb-2">₦100,000</p> */}
        <span className="d-flex gap-3 align-content-center">
          <p>
            {' '}
            {show
              ? `${getCurrency(
                  props.savingsTotal?.currency
                    ? props.savingsTotal?.currency
                    : '₦',
                )}${props.savingsTotal?.totalLockedAmount &&
                  formatAmount(props.savingsTotal?.totalLockedAmount / 100, 2)}`
              : 'XXX'}
          </p>
          <i
            className={`fas fa-${show ? 'eye' : 'eye-slash'}`}
            onClick={() => setShow(!show)}
          />
        </span>
        <button
          className="create-new-savings-plan mt-5"
          onClick={props.handleShowSavings}
        >
          Create New Savings Plan
        </button>
      </div>

      {/* Image at Bottom Right */}
      <img
        src={savingsimage}
        alt="Card img"
        className="absolute top-0 right-0 rounded-md"
      />
    </div>
  );
};

export const SavingsYieldCard = props => {
  // console.log('SavingsYieldCard: ', props);
  const [show, setShow] = useState(false);
  return (
    <div className="savingsyieldcard p-4 lg:p-8 xl:p-8 w-full md:w-2/3 lg:w-1/2 xl:w-1/3 mx-auto my-8 relative mb-2 lg:mb-5">
      {/* Card Content */}
      <div className=" yieldinner ">
        <img src={plantcoinfull} alt="" width={100} />
        <div className="yield">
          <p className="">TOTAL EXPECTED YIELD</p>
          <span className="d-flex gap-3 align-content-center">
            <p>
              {' '}
              {show
                ? `${getCurrency(
                    props.savingsTotal?.currency
                      ? props.savingsTotal?.currency
                      : '₦',
                  )}${props.savingsTotal?.totalExpectedYield &&
                    formatAmount(
                      props.savingsTotal?.totalExpectedYield / 100,
                      2,
                    )}`
                : 'XXX'}
            </p>
            <i
              className={`fas fa-${show ? 'eye' : 'eye-slash'}`}
              onClick={() => setShow(!show)}
            />
          </span>
        </div>
      </div>

      {/* Image at Bottom Right */}
      <img
        src={plantcoinhalf}
        alt="Card img"
        className="absolute bottom-0 right-0 rounded-md"
        width={150}
      />
    </div>
  );
};

export const SavingsTypeCard = props => {
  return (
    <div
      className="savingstypecard p-4 lg:p-8 xl:p-8 w-full md:w-2/3 lg:w-1/2 xl:w-1/3 mx-auto my-8 relative mb-2 lg:mb-5"
      style={{
        backgroundColor: props.backgroundColor,
        border: `0.5px solid ${props.borderColor}`,
      }}
    >
      {/* Card Content */}
      <div className=" typeinner ">
        <img src={props.topImage} alt="" width={100} />
        <div className="titles mt-5">
          <p className="mb-3">{props.type}</p>
          <p style={{ color: props.color }}>{props.subtitle}</p>
        </div>
        <span
          className="action-button gap-3"
          style={{ color: props.color }}
          onClick={() => {
            props.onClick();
          }}
        >
          <p style={{ color: props.color }}>Create Savings Plan</p>
          <i className={`fas fa-arrow-right`} />
        </span>
      </div>

      {/* Image at Bottom Right */}
      <img
        src={props.downImage}
        alt="Card img"
        className="absolute bottom-0 right-0 rounded-md"
        width={150}
      />
    </div>
  );
};

export const SavingsTypeCardSmall = props => {
  return (
    <div
      className="savingstypecard p-4 lg:p-8 xl:p-8 w-full md:w-2/3 lg:w-1/2 xl:w-1/3 mx-auto relative mb-2 lg:mb-5"
      style={{
        backgroundColor: props.backgroundColor,
        border: `0.5px solid ${props.borderColor}`,
      }}
    >
      {/* Card Content */}
      <div className=" smalltypeinner ">
        <img src={props.topImage} alt="" width={70} />
        <div className="titles">
          <p>{props.type}</p>
          <p style={{ color: props.color }}>{props.subtitle}</p>
          <span
            className="action-button"
            style={{ color: props.color }}
            onClick={() => {
              props.onClick();
            }}
          >
            <p style={{ color: props.color }}>Create Savings Plan</p>
            <i className={`fas fa-arrow-right`} />
          </span>
        </div>
      </div>

      {/* Image at Bottom Right */}
      <img
        src={props.downImage}
        alt="Card img"
        className="absolute bottom-0 right-0 rounded-md"
        width={70}
      />
    </div>
  );
};
