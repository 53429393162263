import React from 'react';
import { Fullsection } from './o1BForm/section.js';

export const O1Bform = () => {
  return (
    <div>
      <Fullsection />
    </div>
  );
};
