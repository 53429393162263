import React, { useEffect, useRef } from 'react';

export const SelectCurrencyTwo = ({
  options,
  defaultCurrency,
  handleCurrencyChange,
  setSelected,
  selected,
}) => {
  const ref = useRef(null);
  const [currency, setCurrency] = React.useState(defaultCurrency);
  const [active, setActive] = React.useState(false);

  useEffect(() => {
    handleCurrencyChange(currency);
    // eslint-disable-next-line
  }, [currency]);

  const handleActive = () => {
    setActive(!active);
  };

  const handleCurrency = selectedCurrency => {
    setCurrency(selectedCurrency);
    setActive(false);
  };

  useEffect(() => {
    if (!currency) {
      setCurrency(options ? options[0]?.currency : '');
      setSelected(options ? options[0]?.currency : '');
    }
  }, [currency]);

  return (
    <div className="relative sm:w-[100px] w-[120px] z-10" ref={ref}>
      <div
        className="w-full rounded-[30px] bg-white flex items-center flex-row justify-between px-[10px]  gap-1 py-[8px] border-solid border-1 border-vesti-900"
        onClick={handleActive}
      >
        <span className="w-fit flex flex-row gap-[5px] items-center">
          <img
            src={
              options?.find(item => item.currency === selected?.currency)
                ?.currencyFlag
            }
            alt="currency"
            className="relative walletflag"
          />
          <p className="font-sans text-[13px]  text-vesti-902 uppercase">
            <strong>{selected?.currency}</strong>
          </p>
        </span>
        <i
          className={`fas ${
            active ? 'fa-chevron-up' : 'fa-chevron-down'
          } text-[1em] font-600 text-vesti-900`}
        ></i>
      </div>
      {active && (
        <div
          style={{ height: '110px' }}
          className="z-10 flex flex-col gap-[5px] absolute overflow-y-scroll top-[50px] rounded-[15px] w-[120px]  origin-center  bg-white border-solid border-1 border-vesti-900"
        >
          <div className="bg-white ">
            {options
              // .filter(item => item.currency !== currency)
              ?.map((item, index) => (
                <span
                  key={index}
                  className="w-fit cursor-pointer flex flex-row gap-[5px] items-center py-[5px] px-[10px]"
                  onClick={() => {
                    handleCurrency(item.currency);
                    setSelected(item);
                  }}
                >
                  <img
                    // src={item.icon}
                    src={item.currencyFlag}
                    alt="currency"
                    className="walletflag"
                  />
                  <p className="font-sans text-[1em]  text-vesti-902 uppercase">
                    <strong>{item.currency}</strong>
                  </p>
                </span>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export const SelectCurrencyThree = ({
  options,
  defaultCurrency,
  handleCurrencyChange,
  setSelected,
  selected,
}) => {
  const ref = useRef(null);
  const [currency, setCurrency] = React.useState(defaultCurrency);
  const [active, setActive] = React.useState(false);

  useEffect(() => {
    handleCurrencyChange(currency);
    // eslint-disable-next-line
  }, [currency]);

  const handleActive = () => {
    setActive(!active);
  };

  const handleCurrency = selectedCurrency => {
    setCurrency(selectedCurrency);
    setActive(false);
  };

  useEffect(() => {
    if (!currency) {
      setCurrency(options ? options[0]?.currency : '');
      setSelected(options ? options[0]?.currency : '');
    }
  }, [currency]);

  return (
    <div className="relative w-full z-10" ref={ref}>
      <div
        className="w-full  flex items-center flex-row justify-between cursor-pointer  gap-1 py-[4px]"
        onClick={handleActive}
      >
        <span className="w-fit flex flex-row gap-[5px] items-center">
          {/* <img
            src={
              options?.find(item => item.currency === selected?.currency)
                ?.currencyFlag
            }
            alt="currency"
            className="relative walletflag"
          /> */}
          <p className="font-sans text-[13px]  text-vesti-902 uppercase">
            <strong>{selected?.currency}</strong>
          </p>
        </span>
        <i
          className={`fas ${
            active ? 'fa-chevron-up' : 'fa-chevron-down'
          } text-[1em] font-600 text-vesti-900`}
        ></i>
      </div>
      {active && (
        <div
          style={{ height: '90px', left: '-50px' }}
          className="z-10 flex flex-col gap-[5px] absolute overflow-y-scroll top-[30px]  rounded-[15px] w-[100px]  bg-white border-solid border-1 border-vesti-900"
        >
          <div className="bg-white ">
            {options
              // .filter(item => item.currency !== currency)
              ?.map((item, index) => (
                <span
                  key={index}
                  className="w-fit cursor-pointer flex flex-row gap-[5px] items-center py-[5px] px-[10px]"
                  onClick={() => {
                    handleCurrency(item.currency);
                    setSelected(item);
                  }}
                >
                  {item.currencyFlag !== '' ? (
                    <img
                      // src={item.icon}
                      src={item.currencyFlag}
                      alt="currency"
                      className="walletflag"
                    />
                  ) : (
                    <p className="w-1  mr-2"></p>
                  )}
                  <p className="font-sans text-[1em]  text-vesti-902 uppercase">
                    <strong>{item.currency}</strong>
                  </p>
                </span>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export const SelectCurrencyFour = ({
  options,
  defaultCurrency,
  handleCurrencyChange,
  setSelected,
  selected,
}) => {
  const ref = useRef(null);
  const [currency, setCurrency] = React.useState(defaultCurrency);
  const [active, setActive] = React.useState(false);

  useEffect(() => {
    handleCurrencyChange(currency);
    // eslint-disable-next-line
  }, [currency]);

  const handleActive = () => {
    setActive(!active);
  };

  const handleCurrency = selectedCurrency => {
    setCurrency(selectedCurrency);
    setActive(false);
  };

  useEffect(() => {
    if (!currency) {
      setCurrency(options ? options[0]?.currency : '');
      setSelected(options ? options[0]?.currency : '');
    }
  }, [currency]);

  return (
    <div className="relative w-full z-10" ref={ref}>
      <div
        className="w-full  flex items-center flex-row justify-between cursor-pointer  gap-1 py-[4px]"
        onClick={handleActive}
      >
        <span className="w-fit flex flex-row gap-[5px] items-center">
          {/* <img
            src={
              options?.find(item => item.currency === selected?.currency)
                ?.currencyFlag
            }
            alt="currency"
            className="relative walletflag"
          /> */}
          {selected?.currency ? (
            <p className="font-sans text-[13px]  text-vesti-902 uppercase">
              <strong>{selected?.currency}</strong>
            </p>
          ) : (
            <p className="font-sans text-[13px]  text-vesti-902 ">
              <strong>Select Currency</strong>
            </p>
          )}
        </span>
        <i
          className={`fas ${
            active ? 'fa-chevron-up' : 'fa-chevron-down'
          } text-[1em] font-600 text-vesti-900`}
        ></i>
      </div>
      {active && (
        <div
          style={{ height: '90px', left: '-50px' }}
          className="z-10 flex flex-col gap-[5px] absolute overflow-y-scroll top-[30px]  rounded-[15px] w-[100px]  bg-white border-solid border-1 border-vesti-900"
        >
          <div className="bg-white ">
            {options
              // .filter(item => item.currency !== currency)
              ?.map((item, index) => (
                <span
                  key={index}
                  className="w-fit cursor-pointer flex flex-row gap-[5px] items-center py-[5px] px-[10px]"
                  onClick={() => {
                    handleCurrency(item.currency);
                    setSelected(item);
                  }}
                >
                  {item.currencyFlag !== '' ? (
                    <img
                      // src={item.icon}
                      src={item.currencyFlag}
                      alt="currency"
                      className="walletflag"
                    />
                  ) : (
                    <p className="w-1  mr-2"></p>
                  )}
                  <p className="font-sans text-[1em]  text-vesti-902 uppercase">
                    <strong>{item.currency}</strong>
                  </p>
                </span>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
