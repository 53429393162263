import React, { useState, useEffect } from 'react';
import uploadProgress from 'assets/viasForm/uploadP.svg';
import paymentIconProgress from 'assets/viasForm/visaPaymentProgress.svg';
import patternBg from 'assets/viasForm/Pattern.svg';
import { FaArrowRightLong } from 'react-icons/fa6';
import reviewNotStartedIcon from 'assets/viasForm/notStartedReview.svg';
import {
  SinglePathways,
  AllSinglePathways,
} from 'containers/Admin/Pathways/AllPathways';
import { Largemodal } from 'components/common/simplifiedmodal';
import { fetchGroups } from 'appRedux/actions/feeds';
import { useDispatch, useSelector } from 'react-redux';
import groupmembergrowth from 'assets/pathways/groupusergrowth.svg';
import { NewGrowthcommunity } from 'containers/Admin/Pathways/growthcommunity';
import { navigate } from '@reach/router';
import { useLocation } from '@reach/router';
import { updatePrevLocation } from 'appRedux/actions/location';

export const SuccessRight = () => {
  const dispatch = useDispatch();

  const [myGroups, setMyGroups] = useState();

  const [seeAllPathways, setSeeAllPathways] = useState(false);
  const [seeAllGroups, setSeeAllGroups] = useState(false);

  const myGname = 'Business Class';

  const allGroups = useSelector(state => state.feeds.groups);

  useEffect(() => {
    dispatch(fetchGroups());
  }, []);

  const toWholeCurrency = num =>
    (num / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  const accessCommunity = allGroups?.find(group => {
    return group.name === myGname;
  });

  const location = useLocation();
  const [previousPath, setPreviousPath] = useState();

  useEffect(() => {
    setPreviousPath(location.pathname); // Store current pathname on mount.
  }, [location]);

  const handleSetPreviousPath = id => {
    dispatch(updatePrevLocation(previousPath));
    navigate(`/group/${id}`);
  };

  const pid = 'd7c8ee67-1cab-49cc-a757-3bb5466434c5';
  return (
    <div
      style={{
        width: '100%',
        padding: '0px 6px',
        height: '600px',
        overflowY: 'auto',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '0.25rem',
          paddingBottom: '0.25rem',
          width: '100%',
        }}
      >
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            color: '#67A948',
          }}
        >
          Explore pathways
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: '600',
            color: '#67A948',
            cursor: 'pointer',
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
          }}
          onClick={() => setSeeAllPathways(true)}
        >
          See more <FaArrowRightLong />
        </p>
      </div>
      <SinglePathways pid={pid} />

      <div style={{ width: '100%', padding: '0px 6px', marginTop: '0.75rem' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: '0.25rem',
            paddingBottom: '0.5rem',
            width: '100%',
          }}
        >
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              color: '#67A948',
            }}
          >
            Growth communities{' '}
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '600',
              color: '#67A948',
              cursor: 'pointer',
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
            }}
            onClick={() => setSeeAllGroups(true)}
          >
            See more <FaArrowRightLong />
          </p>
        </div>

        <div style={{ background: '#ECF8FF', padding: '12px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              marginBottom: '0.75rem',
            }}
          >
            <p
              style={{
                color: '#1481BA',
                fontSize: '15px',
                fontWeight: '700',
              }}
            >
              {accessCommunity?.name}
            </p>
            <p
              style={{
                color: '#1481BA',
                fontSize: '12px',
                fontWeight: '700',
                border: '1px solid #1481BA',
                background: '#C0E9FF',
                padding: '4px 12px',
                borderRadius: '6px',
              }}
            >
              {accessCommunity?.type}
            </p>
          </div>

          <p
            style={{ color: '#1481BA', fontSize: '15px', padding: '2rem 0rem' }}
          >
            {accessCommunity?.description}
          </p>

          <div
            style={{
              marginTop: '0.75rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                // marginTop: '0.75rem',
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
              }}
            >
              <img
                src={groupmembergrowth}
                alt=""
                style={{ height: '40%', width: '5rem' }}
              />
              <p style={{ fontSize: '13px' }}>5K+ Members</p>
            </div>

            <div
              style={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#1481BA',
                cursor: 'pointer',
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
              }}
              onClick={() => {
                handleSetPreviousPath(accessCommunity?.id);
              }}
            >
              Join now <FaArrowRightLong />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
