import React from 'react';
import icon from 'assets/viasForm/formNotificationIcon.svg';
import './chat.scss';
import { FaEye } from 'react-icons/fa';

export const Usernotification = props => {
  return (
    <div>
      <div
        style={{
          background: '#F7FFF3',
          width: '100%',
          height: '100%',
          border: '1px solid #eaecf0',
          position: 'absolute',
          top: '0px',
          right: '0px',
          left: '0px',
          bottom: '0px',
        }}
      ></div>
      <div className="VisaChatFeature__chatshist">
        {/* {props.myUrl} */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* <img src={icon} alt="" style={{ width: '120px', height: '120px' }} /> */}
          <p
            style={{
              color: '#67A948',
              fontWeight: '600',
              fontSize: '18px',

              textAlign: 'center',
            }}
          >
            You’re about to be redirected to another page
          </p>
          <p
            style={{ color: '#2B5219', fontSize: '13px', textAlign: 'center' }}
          >
            If you leave this page, your conversation will still be saved. Do
            you want to proceed?
          </p>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
              marginTop: '14px',
              width: '100%',
            }}
          >
            <div
              style={{
                color: 'red',
                fontWeight: '600',
                fontSize: '13px',
                padding: '12px 0px',
                width: '100%',
                textAlign: 'center',
                borderRadius: '10px',
                cursor: 'pointer',
                border: '1px solid red',
              }}
              onClick={() => {
                props.setShowUserNot(false);
              }}
            >
              Cancel
            </div>

            <a
              href={
                props.myUrl?.startsWith('http')
                  ? props.myUrl
                  : `https://${props.myUrl}`
              }
              target="_blank"
              rel="noopener noreferrer"
              style={{
                background: '#67A948',
                color: 'white',
                fontWeight: '600',
                fontSize: '13px',
                padding: '12px 0px',
                width: '100%',
                textAlign: 'center',
                borderRadius: '10px',
                cursor: 'pointer',
              }}
            >
              Proceed
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const StvPreviewImg = props => {
  return (
    <div>
      <img
        src={props.myUrl}
        alt="Preview"
        style={{
          maxHeight: '27rem',
          width: '100%',
          objectFit: 'cover',
          paddingTop: '10px',
        }}
      />
      <p
        onClick={() => {
          window.open(props.myUrl, '_blank');
        }}
        style={{
          cursor: 'pointer',
          color: '#3B6725',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          paddingTop: '10px',
          fontWeight: '600',
        }}
      >
        <FaEye style={{ color: '#3B6725', fontSize: '16px' }} />
        View Full Image
      </p>
    </div>
  );
};
