import { Platformbutton } from 'components/common/button/button';
import React from 'react';
import plus from '../../../assets/100plus.svg';
import './Ovcount.scss';

const Ovcount = (props) => {
  return (
    <div className="ovcount-container">
      <div className="ovcount-container-inner">
        <div className="left">
          <img src={plus} alt="svg" />
          <p>
            We have filled over 100+ and counting successful O-1 Visas till
            date.
          </p>
        </div>
        <div className="right">
          {/* <button>See if you Qualify</button> */}
          <Platformbutton
            name="See if you Qualify"
            type="normal"
            click={() => props.click()}
          />
        </div>
      </div>
    </div>
  );
};

export default Ovcount;
