import React from 'react';
import { MigrationFeatures } from './migrationFeatures';
import { FinancialFeatures } from './financialFeatures';
import { Smalltitle } from 'components/common/titlesubtitle/titlesubtitle';
import './quickfeature.scss';
import Layout from 'components/common/DashboardLayout';
import { logout } from 'assets/assets';

const SeeallquickAction = () => {
  return (
    <Layout>
      <div className="seeallFeatures">
        {/* <div className="seeallFeatures__header">
          <p className="seeallFeatures__header__title">Vesti Services</p>
          <p className="seeallFeatures__header__subtitle">
            Select a services from the list provided below
          </p>
        </div> */}

        <div className="seeallFeatures__subsection">
          <>
            <div>
              <Smalltitle title="Move Anywhere" />
              <p>Migration</p>
            </div>
            <MigrationFeatures />
          </>

          <>
            <div>
              <Smalltitle title="Move Money" />
              <p>Financial</p>
            </div>
            <FinancialFeatures />
          </>
        </div>
      </div>
    </Layout>
  );
};

export default SeeallquickAction;
