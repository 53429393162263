import { PREV_LOCATION, FROM_LOCATION_AUTH } from 'appRedux/constants';

export const updatePrevLocation = prevpathname => ({
  type: PREV_LOCATION,
  payload: prevpathname,
});

export const fromLocationfromAuth = pathname => ({
  type: FROM_LOCATION_AUTH,
  payload: pathname,
});
