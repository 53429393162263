// import AdminForgotPassword from 'containers/Auth/AdminForgotPassword';
// import AdminResetPassword from 'containers/Auth/AdminResetPassword';

// import Login from 'containers/Auth/Login';
// import Register from 'containers/Auth/Signup/SignUp';

// import Verification from '../containers/Verification/Verification';
import { useSelector } from 'react-redux';
import Cash from 'containers/Admin/Cash';
import Allcards from 'containers/Admin/allcards/allcards';
// import Account from 'containers/Admin/Accounts';
// import AccountSettings from 'containers/Admin/Accounts/Settings';
import Transfer from 'containers/Admin/Cash/Transfer';
import Merchants from 'containers/Admin/Merchants';
// import Airtime from 'containers/Admin/Airtime';
import Savings from 'containers/Admin/Savings';
import SavingsDetails from 'containers/Admin/Savings/SavingsDetails';
import EditSavings from 'containers/Admin/Savings/EditSavings';
import Dashboard from '../containers/Admin/Dashboard/index';
import Advice from 'containers/Admin/Migration';
import ScrollableTabsButtonAuto from 'containers/Admin/Streaming';
// import { MemberStandard } from 'containers/Admin/Streaming';
// import { MemberExclusive } from 'containers/Admin/Streaming';
// import { MemberPlatinum } from 'containers/Admin/Streaming';
import { CampaignCreate } from 'containers/Admin/Streaming';
import { CampaignDetails } from 'containers/Admin/Streaming';
import { CampaignStory } from 'containers/Admin/Streaming';
import AdviceDetail from 'containers/Admin/Migration/AdviceDetail';
import Pathways from 'containers/Admin/Pathways';

import PathwaysJourney from 'containers/Admin/Pathways/indexPathwayJourney';

import CreatePathway from 'containers/Admin/Pathways/CreatePathway';
import Services from 'containers/Admin/Services';
import CreateService from 'containers/Admin/Services/CreateService';
import Provider from 'containers/Admin/Provider';
import NewDashboard from 'containers/Admin/NewDashboard';
import NewHome from 'containers/website/NewHome';
import FAQ from 'containers/website/FAQ';
import Fries from 'containers/website/MigrationFries';
import Teams from 'containers/website/Team';
import Fees from 'containers/website/Fees';
import ServiceDetail from 'containers/Admin/Merchants/servicedetail';
// import Vestisavings from 'containers/Admin/Savings/vestisavings';
import Mycard from 'containers/Admin/Cash/carddetails/carddetails';
import { Singlewallet } from 'containers/Admin/Cash/singlewallet/singlewallet';
import Disclosure from 'containers/website/Disclosure';
import Coin from 'containers/website/Coin';
import Aboutpathway from 'containers/Admin/Pathways/aboutpathway';
import { Actionpathway } from 'containers/Admin/Pathways/actionpathway';
import { Sharedpost } from 'containers/Admin/sharedpost/sharedpost';
import { Fullpost } from 'containers/Admin/NewDashboard/fullpost';
import CoinVstSuccess from 'components/website/CoinVstSuccess';

// eslint-disable-next-line
import Groupfeeds from 'containers/Admin/group/group';

import { Privacy } from 'containers/website/privacy';
import { AML } from 'containers/website/AML';
import Userprofile from 'containers/Admin/userprofile/userprofile';

import { Advisor } from 'containers/Admin/Advisor/advisor';
import { Verifyuser } from 'containers/Verification/verifyuser';

import { Singlefry } from 'containers/website/singlefry';
import Vesticards from 'containers/website/CardVesti';
import JapaPage from 'containers/website/JapaPage';
import Testimonials from 'containers/website/Testimonials';
import Mainsuccess from 'containers/Admin/success/success';
import { Countrylaunch } from 'containers/website/countrylaunch';
import Credithistory from 'containers/Admin/credits/credithistory';
import VestiCreditCards from 'containers/website/VestiCreditCards';
import Registerv2 from 'containers/Auth/Signup/register';

import Signinv2 from 'containers/Auth/Login/signin';
import Resetpasswordmail from 'containers/Auth/resetpassword/passwordreset';
// import Resetpassword from 'containers/Auth/resetpassword/resetpassword';

import Edu from 'containers/website/Edu';
import Ovisa from 'containers/website/Ovisa';
import BillPaymentHome from 'containers/Admin/BillPayment/BillPaymentHome';
import AirtimePurchase from 'containers/Admin/BillPayment/Airtime/AirtimePurchase';
import { Successtrans } from 'containers/Admin/success/successtrans';
import { Loneview } from 'containers/Admin/lone/loneview';
import { Bookflight } from 'containers/Admin/lone/bookflight';
import { Multipletabs } from 'containers/Admin/success/multipletabs';

import { USDBookflight } from 'containers/Admin/lone/bookflightinUsd';

import MiaiIndex from 'containers/Admin/MIAIWeb/MiaiIndex';

import MiaiSingleQA from 'containers/Admin/MIAIWeb/miaiSingleQA';

// import Layout from 'components/common/DashboardLayout';

import CardsFullDetails from 'containers/Admin/allcards/cardFullDetails';
import OtherGroupIndex from 'containers/Admin/NewDashboard/OtherGroups';
// import GroupDetails from 'containers/Admin/NewDashboard/OtherGroups/GroupDetails';

import AllPathways from 'containers/Admin/Pathways/AllPathways';
import SeeallquickAction from 'components/bank/quickfeature/seeallquickAction';
import { CaseStatus } from 'containers/Admin/USCIS/caseStatus';
import { CaseStatusCheck } from 'containers/Admin/USCIS/caseStatusCheck';
import { Visatypes } from 'containers/Admin/Pathways/VISAFORM/visatypes';
// import MobileAssetDeeplink as '../../apple-app-site-association.json'
// Visa Form requirement
import { EboneNiw } from 'containers/Admin/Pathways/VISAFORM/FormsPages/EB-1Page/eboneNiw';
// import { Oonevisa } from 'containers/Admin/Pathways/VISAFORM/FormsPages/0-1Page/oIVisa';
import { InvoiceComponent } from 'containers/Admin/Pathways/VISAFORM/Invoice/Invoice';
import { MainVisaPage } from 'containers/Admin/Pathways/VISAFORM/FormsPages/Main/main';
import { NewFeeds } from 'containers/Admin/NewDashboard/NewFeedv2/Index';
import { FeedsChat } from 'containers/Admin/NewDashboard/ChatArea/FeedsChat';
import { ChatListSecond } from 'containers/Admin/NewDashboard/ChatListSecond';
import { InChatSecond } from 'containers/Admin/NewDashboard/ChatArea/InChatSecond';

import { Ebone } from 'containers/Admin/Pathways/VISAFORM/FormsPages/EB-1Page/eboneNiw';
import { Oonevisa } from 'containers/Admin/Pathways/VISAFORM/FormsPages/0-1Page/oIVisa';
import { EB2Niw } from 'containers/Admin/Pathways/VISAFORM/FormsPages/EB-2NIWPage/ebtwoniw';
import { H1b } from 'containers/Admin/Pathways/VISAFORM/FormsPages/H1-BPage/h1b';
import { H1bFormFill } from 'containers/Admin/Pathways/VISAFORM/FormsPages/H1-BPage/h1bSuccess';

import { EmbeddedCalendyPage } from 'containers/Admin/Pathways/Embedded';

import { NewVisatypes } from 'containers/Admin/Pathways/VISAFORM/newVisatypes';
import { NewH1bStage2 } from 'containers/Admin/Pathways/VISAFORM/FormsPages/H1-BPage/h1bStage2';

import { ChatFeature } from 'containers/Admin/Pathways/VISAFORM/chat/chatFeature';
import Notification from '../components/notification/Notification';
import LoanSummary from 'containers/Admin/LoanSummary/LoanSummary';
import StudentLoan from 'containers/Admin/LoanSummary/StudentLoan/StudentLoan';
import LoanUnavailable from 'containers/Admin/LoanSummary/StudentLoan/LoanUnavailable';
import LoanDetails from 'containers/Admin/LoanSummary/StudentLoan/LoanDetails';
import CreditScore from 'containers/Admin/LoanSummary/StudentLoan/CreditScore';
import YourCreditScore from 'containers/Admin/LoanSummary/StudentLoan/YourCreditScore';
import ApplyForPof from 'containers/Admin/LoanSummary/ProofOfFunds/ApplyForPof';
import ApplyForMigrationLoan from 'containers/Admin/LoanSummary/SupportLoan/ApplyForMigrationLoan';
import ApplicationDetails from 'containers/Admin/LoanSummary/StudentLoan/ApplicationDetails';
import ProofOfFundsApplication from 'containers/Admin/LoanSummary/ProofOfFunds/ProofOfFundsApplication';
import MigrationLoanApplication from 'containers/Admin/LoanSummary/SupportLoan/MigrationLoanApplication';
import InterestPayment from 'containers/Admin/LoanSummary/ProofOfFunds/InterestPayment';

// const From_Location = useSelector(state => state.location.fromLocation);

// console.log(From_Location);

const Routes = [
  { path: '/', component: NewHome },
  { path: '/faq', component: FAQ },
  { path: '/fees', component: Fees },
  { path: '/team', component: Teams },
  { path: '/vesticoin', component: Coin, auth: false },
  { path: '/vesticard', component: Vesticards },
  { path: '/vesticoinsuccess', component: CoinVstSuccess, auth: false },
  { path: '/disclosure', component: Disclosure },
  { path: '/migrationfries', component: Fries },
  { path: '/privacy', component: Privacy },
  { path: '/aml', component: AML },
  { path: '/japa', component: JapaPage },
  { path: '/testimonials', component: Testimonials },
  { path: '/comingsoon', component: Countrylaunch },
  { path: '/dashboard', component: Dashboard, auth: true },
  { path: '/feeds', component: NewDashboard, auth: true },
  { path: '/feeds-chat/:friendId', component: FeedsChat, auth: true },
  { path: '/chatList', component: ChatListSecond, auth: true },
  { path: '/inChat', component: InChatSecond, auth: true },
  { path: '/new-feeds', component: NewFeeds, auth: true },
  // { path: '/feeds/groups', component: OtherGroupIndex, auth: true },
  { path: '/group/:id', component: OtherGroupIndex, auth: true },
  { path: '/fry/:postId', component: Singlefry },

  // { path: '/group/:id', component: Groupfeeds, auth: true },
  { path: '/creditcard', component: VestiCreditCards },
  { path: '/edu', component: Edu },

  // { path: '/embeddedUrl', component: Embedded },
  { path: '/o1visa', component: Ovisa },
  { path: '/invoice', component: InvoiceComponent },

  // Pathways
  { path: '/pathways', component: Pathways, auth: true },
  { path: '/explore-pathways', component: AllPathways, auth: true },

  // Visa Form
  { path: '/pathways/:visaType', component: MainVisaPage, auth: true },

  // { path: '/pathways/:visaType', component: MainVisaPage, auth: true },

  // Direct link for visa forms
  { path: '/pathways/0-1visa', component: Oonevisa, auth: true },
  { path: '/pathways/EB-1visa', component: Ebone, auth: true },
  { path: '/pathways/EB-2NIWvisa', component: EB2Niw, auth: true },
  { path: '/pathways/H1Bvisa', component: H1b, auth: true },
  { path: '/pathways/H1Bvisa/stage2', component: NewH1bStage2, auth: true },
  {
    path: '/pathways/H1Bvisa/registration',
    component: H1bFormFill,
    auth: true,
  },

  // Calendy
  { path: '/claritybookings', component: EmbeddedCalendyPage, auth: false },

  // { path: '/pathways/eb1visa', component: EboneNiw, auth: true },
  { path: '/pathways/visa', component: Visatypes, auth: true },
  { path: '/pathways/visaform', component: NewVisatypes, auth: true },

  // Visa Form end

  { path: '/pathwaysjourney/:id', component: PathwaysJourney, auth: true },
  { path: '/pathwaydesc/:id', component: Aboutpathway, auth: true },
  { path: '/actions/:id', component: Actionpathway, auth: true },
  { path: '/create-pathway', component: CreatePathway, auth: true },

  // Services
  // { path: '/services', component: Services, auth: true },
  { path: '/create-service', component: CreateService, auth: true },
  { path: '/provider', component: Provider, auth: true },
  { path: '/bank', component: Cash, auth: true },
  { path: '/advice', component: Advice, auth: true },
  { path: '/advice/:countryId', component: AdviceDetail, auth: true },
  { path: '/service/:id', component: ServiceDetail, auth: true },
  { path: '/webinar', component: ScrollableTabsButtonAuto, auth: true },
  // { path: '/accounts', component: Account, auth: true },
  { path: '/myprofile', component: Userprofile, auth: true },
  // { path: '/accounts/settings', component: AccountSettings, auth: true },
  { path: '/merchants', component: Merchants, auth: true },
  // { path: '/buy-airtime', component: Airtime, auth: true },
  { path: '/bills-payment', component: BillPaymentHome, auth: true },
  { path: '/airtime', component: AirtimePurchase, auth: true },

  //shared post
  { path: '/sharedpost/:postId', component: Sharedpost },
  { path: '/fullpost/:postId', component: Fullpost },

  // SAVINGs
  // { path: '/savings', component: Savings, auth: true },
  // { path: '/savings/:savingsId', component: SavingsDetails, auth: true },
  // { path: '/savings-details', component: SavingsDetails, auth: true },
  // { path: '/edit-savings', component: EditSavings, auth: true },

  // SAVINGs
  { path: '/savings', component: Savings, auth: true },
  { path: '/savings-details', component: SavingsDetails, auth: true },
  { path: '/edit-savings', component: EditSavings, auth: true },

  // // no auth routes
  { path: '/register', component: Registerv2, auth: false },
  { path: '/auth', component: Signinv2, auth: false },
  { path: '/auth/forgot-password', component: Resetpasswordmail },
  // { path: '/auth', component: Login, auth: false },

  // token for reset password
  // { path: '/auth/reset-password/:token', component: Resetpassword },
  { path: '/verified/:userId', component: Verifyuser },

  // P2P cash transfer
  { path: '/bank/transfer', component: Transfer },

  // bc763fda-2a2b-4b90-853a-095fe48011b7
  // MEMBER
  // { path: '/member/exclusive', component: MemberExclusive, auth: true },
  // { path: '/member/platinum', component: MemberPlatinum, auth: true },
  { path: '/campaign/create', component: CampaignCreate, auth: true },
  { path: '/campaign/detail', component: CampaignDetails, auth: true },
  { path: '/campaign/story', component: CampaignStory, auth: true },
  { path: '/advice/:countryId', component: CampaignStory, auth: true },

  // Robo
  { path: '/roboadvisor', component: Advisor, auth: true },
  { path: '/mycard', component: Mycard, auth: true },
  { path: '/singlewallet', component: Singlewallet, auth: true },
  { path: '/transactionstatus', component: Mainsuccess, auth: false },
  { path: '/successtransaction', component: Successtrans, auth: false },
  { path: '/multiple-tabs', component: Multipletabs, auth: false },

  // lone view
  // { path: '/student_loans', component: Loneview, auth: false },
  { path: '/loan', component: Loneview, auth: false },
  { path: '/bookflight', component: Bookflight, auth: false },
  { path: '/bookflight/usd', component: USDBookflight, auth: false },

  // credit history and credit cards
  { path: '/credithistory', component: Credithistory, auth: true },

  // cards
  { path: '/cards', component: Allcards, auth: true },

  // { path: '/cards', component: Allcards, auth: true },

  { path: '/cardsdetails', component: CardsFullDetails, auth: true },
  { path: '/miai', component: MiaiIndex, auth: true },
  { path: '/miai/live', component: MiaiIndex, auth: false },

  // Services ... Do more
  { path: '/services', component: SeeallquickAction, auth: true },
  { path: '/casestatus-check', component: CaseStatus, auth: true },
  {
    path: '/casestatus-check/:caseControlNumber',
    component: CaseStatusCheck,
    auth: true,
  },

  // Chat Feature
  { path: '/chat', component: ChatFeature, auth: true },

  // notification
  { path: '/notifications', component: Notification, auth: true },

  //student Loan
  //   { path: '/loansummary', component: LoanSummary, auth: true },
  //  { path: '/loanunavailable', component: LoanUnavailable, auth: true },
  //  { path: '/loandetails/:loanId/:loanName', component: LoanDetails, auth: true },
  //  { path: '/creditscore', component: CreditScore, auth: true },
  //  { path: '/yourcreditscore', component: YourCreditScore, auth: true },
  //  { path: '/studentloan', component: StudentLoan, auth: true },
  //  { path: '/applyforpof', component: ApplyForPof, auth: true },
  //  { path: '/applyformigrationloan', component: ApplyForMigrationLoan, auth: true },
  //  { path: '/applicationdetails', component: ApplicationDetails, auth: true },

  //  {
  //    path: '/proofoffundsapplication',
  //    component: ProofOfFundsApplication,
  //    auth: true,
  //  },
  //  {
  //    path: '/migrationloanapplication',
  //    component: MigrationLoanApplication,
  //    auth: true,
  //  },
  //  {
  //    path: '/interestpayment',
  //    component: InterestPayment,
  //    auth: true,
  //  },
];

export default Routes;
