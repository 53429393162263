import React, { useEffect, useState } from 'react';
import '../../../containers/Admin/Admin.css';
import paymentoption from 'assets/TourGuide/addmoney/deposit.svg';
import viaTransfer from 'assets/TourGuide/addmoney/bankTrf.svg';
import intropathway from 'assets/TourGuide/Pathways/pathwayInfoBanner.svg';
import { navigate } from '@reach/router';

import tooltip from 'assets/TourGuide/Tooltip.svg';
import { bankTourFeatures } from 'TourGuide/data';
import '../Intro/Intro.scss';
import { Wallets } from 'components/bank/balancecard/wallets';
import { LiaTimesSolid } from 'react-icons/lia';

export const JoinPathays = props => {
  const [step, setStep] = useState(0);

  switch (step) {
    case 0:
      return (
        <div style={{ height: '100%', width: '100%' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div className="  w-full h-full">
              <div className="myWalletTour" style={{ marginTop: '4rem' }}>
                {/* <div className="tourWalletBg"></div> */}
                <div className="PathwayTourArticleCContainer">
                  <img
                    className="PathwayTourArticleCContainer__tooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Join a pathway
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Click on any of the cards to see more information
                            about that pathway.{' '}
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            props.handleCloseTourGuide();
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 1} of 7`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__active"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            // setStep(1);
                            navigate(
                              '/pathwaydesc/4476117e-ef4b-4908-8a9c-8c9405fed459',
                            );
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <img
                  src={intropathway}
                  alt=""
                  style={{ width: '100%', marginTop: '4rem' }}
                />
              </div>
            </div>
          </div>
        </div>
      );

    default:
  }
};
