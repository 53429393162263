import React, { useEffect, useState } from 'react';
import '../../../containers/Admin/Admin.css';
import WalletList from 'assets/TourGuide/addWallet/walletList.svg';
import selectWallet from 'assets/TourGuide/addWallet/sellectWallet.svg';
import amountTosend from 'assets/TourGuide/amountTosend.png';
import transPin from 'assets/TourGuide/sendmoney/tpin.svg';

import tooltip from 'assets/TourGuide/Tooltip.svg';
import { bankTourFeatures } from 'TourGuide/data';
import '../Intro/intro.scss';
import { Wallets } from 'components/bank/balancecard/wallets';
import { LiaTimesSolid } from 'react-icons/lia';
import addwalletBtn from 'assets/TourGuide/addWallet/addwallet.png';

export const AddWallet = props => {
  const [step, setStep] = useState(0);

  switch (step) {
    case 0:
      return (
        <div style={{ height: '100%', width: '100%' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div className="  w-full h-full">
              <div className="myWalletTour">
                <img
                  src={addwalletBtn}
                  alt=""
                  style={{
                    marginLeft: '80%',
                    marginTop: '6rem',
                    borderRadius: '20px',
                    width: '10rem',
                    height: '4rem',
                  }}
                />

                <div
                  className="TourArticleCContainer"
                  style={{ marginLeft: '40%' }}
                >
                  <img
                    className="TourArticleCContainer__addWallettooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Add wallet
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Click on the{' '}
                            <span
                              style={{ color: '#67A948', fontWeight: '500' }}
                            >
                              “ Add wallet ”{' '}
                            </span>
                            button to start the process of withdrawing money out
                            of your wallet
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            props.handleCloseTourGuide();
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 1} of 2`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__active"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(1);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    case 1:
      return (
        <div
          style={{}}
          onClick={() => {
            setStep(3);
          }}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div
              className="  w-full h-full"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="">
                <img
                  src={selectWallet}
                  alt=""
                  style={{ width: '23rem', height: '17rem', marginTop: '4rem' }}
                />

                <div className="TourArticleCContainer">
                  <img
                    className="TourArticleCContainer__tooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Add wallet
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Lasty, select which wallet you want to add from the
                            list of options provided.
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            props.handleCloseTourGuide();
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 1} of 2`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>

                          <li className="PathwayTourArticleCContainer__active"></li>
                        </ul>

                        <div
                          onClick={() => {
                            props.setStep(0);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Back
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    default:
  }
};
