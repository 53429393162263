import React, {useEffect, useState} from 'react'
import { navigate } from '@reach/router';
import searchIcon from '../../../assets/newFeedsImg/secondSecImg/searchIcon.png'
import elipsis from '../../../assets/newFeedsImg/secondSecImg/elipsis.png'
import dropDownArrow from '../../../assets/newFeedsImg/secondSecImg/angle-down-circle.png'
import { users } from './FeedsData';
import dummyAvatar from '../../../assets/dummy-avatar.png'
import { InsideChat } from './InsideChat';
import { token } from 'appRedux/api';
import { Flex } from 'antd';
import { GoArrowLeft } from "react-icons/go";
import { useDispatch, useSelector } from 'react-redux';
import { getFriendsFeedsDetails } from 'appRedux/actions/Feedschat';
import './chatList.scss'
import { chatHistoryData, fetchChatData } from 'appRedux/actions/Feedschat';
import { OtherGroupsCom } from './OtherGroupsCom';

export const ChatListSecond = (props) => {
const dispatch = useDispatch();

 useEffect(() => {
       dispatch(chatHistoryData()) 
    },[])

const handleChatClick = (userId, fullname, profilepic) => {
    localStorage.setItem('FriendUserName', fullname)
    localStorage.setItem('FriendProfilepic', profilepic ? profilepic : dummyAvatar )
    // fetchChatData(userId)
        dispatch(getFriendsFeedsDetails(fullname, profilepic))
        navigate (`/feeds-chat/${userId}`)
       
        setShowInput(false); 
        setSearchQuery(''); 
        setSearchResult(null); 
}

    const [showInput, setShowInput] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [searchResult, setSearchResult] = useState(null)

    const toggleInput = () => {
        setShowInput(!showInput) 
        setSearchQuery('')
        setSearchResult([])
    }
    
    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    
        if (query.trim() === '') {
            setSearchResult(null);
            return;
        }
    
        const filteredUsers = chatList.filter((user) => {
            const fullName = `${user.user.firstName} ${user.user.lastName}`.toLowerCase();
            return fullName.startsWith(query.toLowerCase());
        });
    
        setSearchResult(filteredUsers);
    };
    
const chatList = useSelector(state => state.Feedschat?.chatList)

    // date format
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const differenceInMilliseconds = now - date;
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    
        // Time format
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const strMinutes = minutes < 10 ? '0' + minutes : minutes;
        const timeString = `${hours}:${strMinutes}${ampm}`;
    
        // Less than 24 hours
        if (differenceInMilliseconds < oneDayInMilliseconds) {
            return timeString;
        }
    
        // Less than 48 hours (Yesterday)
        if (differenceInMilliseconds < 2 * oneDayInMilliseconds) {
            return 'Yesterday';
        }
    
        // Less than 7 days (Day of the week)
        if (differenceInMilliseconds < 7 * oneDayInMilliseconds) {
            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            return daysOfWeek[date.getDay()];
        }
    
        // More than 7 days (Date/Month/Year)
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero indexed
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    // words limit
    const truncateMessage = (message, charLimit) => {
        if (message.length > charLimit) {
            return message.slice(0, charLimit) + '...';
        }
        return message;
    };

    // const handleChat = (name, img)=>{
       
        // localStorage.setItem('FriendId', )
    // }
    
    return (
        
        <div className='secondChatListContainer'>
            <div className='secondbackIconDiv' onClick={() => navigate('/feeds')}>
                <GoArrowLeft className='secondbackIcon' />
                <h4 className='secondbackP'>Go Back</h4>
            </div>
            <div className='secondChatListHeading'>
            <p className='secondChatP'>Chats</p>

            <div className='secondChatSearchIconDiv'> 
                <img src={searchIcon} 
                className='secondImgSearchIcon'
                alt="search" 
                onClick={toggleInput} 
                />
                {showInput && (
                <div className='secondsearchIconInputDiv'>
                <input 
                    type="text" 
                    value={searchQuery}
                    onChange={handleSearch}
                    placeholder="Search username..." 
                    className='secondsearchIconInput'
                />
                
                </div>
                )}
            </div>
            </div>

            <div className='secondchatListMain'>
    {chatList.length === 0 && !showInput && !searchQuery ? (
        <p className='noChatAva'>No chats available</p>
    ) : showInput && searchQuery && searchResult && searchResult.length === 0 ? (
        <p className='secondNoMatchingUser'>No matching users</p>
    ) : (
        (searchQuery ? searchResult : chatList).map((user) => (
            <div
                key={user.id}
                className='secondchatListUsersProfile'
                onClick={() => handleChatClick(user.user.id, `${user.user.firstName} ${user.user.lastName}`, user.user.profilePictureURL)}
            >
                <div className='secondchatListUsers'>
                    <img src={user.user.profilePictureURL ? user.user.profilePictureURL : dummyAvatar} alt="" className='secondchatListUserImg' />
                    <div className='secondnameAndMessage'>
                        <div className='secondchatListName'>
                            <p className='secondChatListNameP'>{user.user.firstName}</p>
                            <p className='secondChatListNameP'>{user.user.lastName}</p>
                        </div>
                        <p className={`secondtruncateMessage ${window.innerWidth <= 470 ? 'shortMessage' : 'longMessage'}`}>
                    {truncateMessage(user.message, window.innerWidth <= 470 ? 40 : 70)}
                </p>
                        {/* <p className='secondtruncateMessage'>{truncateMessage(user.message, 60)}</p> */}
                    </div>
                </div>
                <div>
                
                    <p className='secondchatListTime'>{formatDate(user.createdAt)}</p>
                </div>
            </div>
        ))
    )}
            </div>

            <div>
                <OtherGroupsCom/>
            </div>
        </div>
    )
}  