import React, { useEffect, useState } from 'react';
import './mytransactions.scss';
import { Empty } from 'components/common/empty/empty';
import Loader from 'components/Loader';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import Newpagination from './pagination/newpagination';
import {
  transColor,
  transSign,
  transStatus,
} from 'helpers/utils';
import moment from 'moment';
// import { Simplemodal } from 'components/common/simplifiedmodal';
import { fetchSavingsTransactions } from 'appRedux/actions/transactions';
import { connect } from 'react-redux';
import api from 'appRedux/api';

const Mysavingstransactions = props => {
  // console.log('Mysavingstransactions props : ', props);
  const [page, setPage] = useState(1);
  const [active, setActive] = useState(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [total, setTotal] = useState(0);

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  var handleOpen = value => {
    setActive(value);
    dimensions.width < 1050 && setOpen(true);
  };
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);

  var fetchTransactions = () => {
    setLoading(true);
    api
      .get(`/safe-lock/history?safeLockId=${props.savingsId}`)
      .then(res => {
        // console.log(res);
        setLoading(false);
        setTransactions(res.data?.data?.data);
        setTotal(res.data?.data?.pagination?.totalPages);
        // console.log(res.data?.pagination.totalPages);
        // console.log('Transactions :', res);
      })
      .catch(error => {
        setLoading(false);
        // console.log(error);
        //  setStep(0);
      });
  };

  useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line
  }, [props.balance, page, props.currency]);

  if (loading) {
    return (
      <div className="mytransactions">
        <Titlesubtitle
          title="Transactions"
          subtitle={`See all transactions you've carried out on your savings plan.`}
        />
        <div className="mytransactions__centercont">
          <Loader />
        </div>
      </div>
    );
  } else if (transactions?.length > 0) {
    return (
      <div className="mytransactions">
        {/* <Simplemodal onClick={() => setOpen(false)} open={open}>
          <Transdetail data={transactions[active]} />
        </Simplemodal> */}
        <Titlesubtitle
          title="Transactions"
          subtitle={`See all transactions you've carried out on your savings plan.`}
        />

        <div className="mytransactions__inner">
          <div className="mytransactions__tablecontsavings">
            <div className="mytransactions__tabbox">
              <table className="mytransactions__savingstable">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Type</th>
                    <th>Currency</th>
                    <th>Amount</th>
                    <th>Date & Time</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {console.log(transactions)} */}
                  {(transactions ?? []).map((item, index) => (
                    <Trow
                      key={index}
                      index={index}
                      type={item.action}
                      currency={item.currency}
                      amount={item.amount}
                      date={item.createdAt}
                      status={item.status}
                      click={handleOpen}
                      // transaction={transactions[active]}
                    />
                  ))}
                </tbody>
              </table>
            </div>

            {/* {dimensions.width > 1200 && (
              <Transdetail data={transactions[active]} />
            )} */}
          </div>

          <Newpagination
            className="pagination-bar"
            currentPage={page}
            // totalCount={props.trans.data.pagination.totalObjects}
            totalCount={total}
            pageSize={15}
            onPageChange={page => setPage(page)}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="mytransactions">
        <Titlesubtitle
          title="Transactions"
          subtitle={`See all transactions you've carried out on your savings plan.`}
        />
        <div className="mytransactions__centercont">
          <Empty
            title="No Transactions"
            subtitle="You are yet to carry out any transactions."
          />
        </div>
      </div>
    );
  }
};

const Trow = props => {
  // eslint-disable-next-line
  // console.log('Trow : ', props);
  const newDate = moment(props.date, 'YYYY-MM-DD HH:mm:ss')
    .add(1, 'hour')
    .format('YYYY-MM-DD HH:mm:ss');

  return (
    <tr onClick={() => props.click(props.index)}>
      <td>{props.index + 1}</td>
      <td>{props.type}</td>
      <td>{props.currency.split('_')[0]}</td>
      <td className={`amount${transColor(props.type)}`}>
        {transSign(props.type)}

        {(props.amount / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </td>

      <td>{newDate}</td>
      <td>
        <div className={`status`}>
          <p className={`statusIcon --${transStatus(props.status)} `}></p>{' '}
          {transStatus(props.status)}
        </div>
      </td>
    </tr>
  );
};

const mapStateToProps = ({ transactions }) => {
  const { transactions: trans, transLoading } = transactions;
  return {
    trans,
    transLoading,
  };
};

export default connect(mapStateToProps, {
  fetchSavingsTransactions,
})(Mysavingstransactions);
