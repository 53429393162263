import React, { useState, useEffect } from 'react';
import MembershipPlan from 'containers/Admin/Streaming/memberSubcription';
import { Largemodal } from 'components/common/simplifiedmodal';
import SubscriptionFeatures from './subscriptionFeatures';
import './membership.scss';
import EconomyPlan from './plans/economyPlan';
import BusinessPlan from './plans/businessPlan';
import PremiumPlan from './plans/premiumPlan';
import CancelModal from './cancelModal';
import api from 'appRedux/api';
import LoadingPlan from './plans/loadingPlan';

const Membership = () => {
  const [opnSub, setOpnSub] = useState(false);
  const [opnSubFeature, setOpnSubFeature] = useState(false);
  const [cancelsubModal, setCancelSubModal] = useState(false);
  const [endDate, setEndDate] = useState('');
  const [membership, setmembership] = useState('');
  const [data, setData] = useState([]);
  const [duration, setDuration] = useState('Monthly');
  const [price, setPrice] = useState(20);
  const [DaysLeft, setDaysLeft] = useState(2);
  const [renewDate, setRenewDate] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [cancelledTrial, setCancelledTrial] = useState(false);
  const [subStatus, setSubStatus] = useState('');

  useEffect(() => {
    const fetchPlanType = async () => {
      try {
        const response = await api.get(`pathway/plan-and-join`);
        if (response.data.data === null) {
          setmembership('ECONOMY');
        } else if (response.data.data.plan.type === 'BUSINESS') {
          setmembership('BUSINESS');
          setDuration(response.data.data.plan.duration);
          setSubStatus(response.data.data.plan.subscriptionStatus);
        } else if (response.data.data.plan.type === 'PREMIUM') {
          setmembership('PREMIUM');
          setDuration(response.data.data.plan.duration);
          setSubStatus(response.data.data.plan.subscriptionStatus);
        } else {
          setmembership('ECONOMY');
        }
        setData(response.data.data.plan);

        const end = await response.data.data.plan.expirationDate;

        const expirationDate = new Date(end);

        const currentDate = new Date();

        // Calculate the number of days left by subtracting the current date from the expiration date
        const daysLeft = Math.floor(
          (expirationDate - currentDate) / (1000 * 60 * 60 * 24),
        );

        setCancelledTrial(response.data.data.plan.cancel);
        // console.log(response.data.data);
        setDaysLeft(daysLeft);
        setEndDate(response.data.data.plan.expirationDate);
        setIsSuccess(true);
        // setCreatedAt(response.data.data.createdAt);
      } catch (error) {
        // console.log(error);
        setIsSuccess(true);
      }
    };

    fetchPlanType();
  }, [isSuccess]);

  useEffect(() => {
    const fetchPriceData = async () => {
      try {
        const response = await api.get(`/app-config/list?source=vesti`);
        const myPrice = response?.data?.data?.data[0];
        if (duration === 'MONTHLY' && membership === 'BUSINESS') {
          setPrice(myPrice.businessMembershipMonthly / 100);
          setIsSuccess(true);
        } else if (duration === 'YEARLY' && membership === 'BUSINESS') {
          setPrice(myPrice.businessMembershipYearly / 100);
          setIsSuccess(true);
        } else if (duration === 'MONTHLY' && membership === 'PREMIUM') {
          setPrice(myPrice.platinumMembershipMonthly / 100);
          setIsSuccess(true);
        } else if (duration === 'YEARLY' && membership === 'PREMIUM') {
          setPrice(myPrice.platinumMembershipYearly / 100);
          setIsSuccess(true);
        } else if (membership === 'ECONOMY') {
          setIsSuccess(true);
        } else {
          setPrice('');
          setIsSuccess(false);
        }
      } catch (error) {
        // console.log(error);
      }
    };
    fetchPriceData();
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    const DateFormatting = async () => {
      const End = endDate ? endDate : '2023-11-02T13:45:53.478Z';
      const expirationDate = new Date(End);
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      const formattedDate =
        expirationDate &&
        new Intl.DateTimeFormat('en-US', options).format(expirationDate);

      setRenewDate(formattedDate);
    };

    DateFormatting();
  }, [endDate]);

  return (
    <>
      <Largemodal onClick={() => setOpnSub(false)} open={opnSub}>
        <MembershipPlan />
      </Largemodal>

      <Largemodal onClick={() => setOpnSubFeature(false)} open={opnSubFeature}>
        <SubscriptionFeatures
          membership={membership}
          price={price}
          setOpnSub={setOpnSub}
          setOpnSubFeature={setOpnSubFeature}
        />
      </Largemodal>

      <Largemodal
        onClick={() => setCancelSubModal(false)}
        open={cancelsubModal}
      >
        <CancelModal
          renewDate={renewDate}
          setCancelSubModal={setCancelSubModal}
          setIsSuccess={setIsSuccess}
        />
      </Largemodal>

      <section className="Profilemembership">
        <>
          <p className="Profilemembership__title">Your Membership</p>
        </>

        {isSuccess ? (
          <>
            {membership === 'ECONOMY' || !membership ? (
              <EconomyPlan
                setOpnSub={setOpnSub}
                setOpnSubFeature={setOpnSubFeature}
                isSuccess={isSuccess}
              />
            ) : membership === 'BUSINESS' ? (
              <BusinessPlan
                setOpnSub={setOpnSub}
                setOpnSubFeature={setOpnSubFeature}
                setCancelSubModal={setCancelSubModal}
                price={price}
                DaysLeft={DaysLeft}
                renewDate={renewDate}
                isSuccess={isSuccess}
                cancelledTrial={cancelledTrial}
                endDate={endDate}
                subStatus={subStatus}
                active={data.active}
              />
            ) : (
              <PremiumPlan
                setOpnSub={setOpnSub}
                setOpnSubFeature={setOpnSubFeature}
                setCancelSubModal={setCancelSubModal}
                price={price}
                DaysLeft={DaysLeft}
                renewDate={renewDate}
                isSuccess={isSuccess}
                cancelledTrial={cancelledTrial}
                endDate={endDate}
                subStatus={subStatus}
                active={data.active}
              />
            )}
          </>
        ) : (
          <LoadingPlan />
        )}
      </section>
    </>
  );
};

export default Membership;
