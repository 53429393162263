import React, { useEffect, useState } from 'react';
import './LoanSummary.scss';
import Layout from 'components/common/DashboardLayout';
import pana from 'assets/studentloans/pana.svg';
import { CustomerReviewsData } from 'helpers/data';
import LoanAvailableCard from './StudentLoan/LoanAvailableCard';
import CustomerReviewsCard from './StudentLoan/CustomerReviewsCard';
import api from 'appRedux/api';

const LoanSummary = () => {
  const [LoanData, setLoanData] = useState([]);
  

  useEffect(() => {
    fetchLoanTypes();
  }, []);

  const fetchLoanTypes = async () => {
    try {
      const response = await api.get(
        `/migration-loan-type/available-loan-types`,
      );
      const data = response.data.data.data;
      setLoanData(data);


      // const combinedData = fetchedData.map((loan, index) => ({
      //   ...loan,
      //   link: LoanDataLinks[index], 
      // }));

    } catch (error) {
      console.error(error);
    }
  };


  return (
    <Layout>
      <div className="student-loan">
        <div className="layout-container">
          <div className="title">Loan Summary</div>

          <div className="loan-section">
            <div className="no-loan">
              <img src={pana} alt="card-image" />
              <p>You haven’t applied for a loan yet.</p>
              <p>Select from the Loans Available below to apply.</p>
            </div>

            <div className="loan-available">
              <div className="title">Loan Available</div>

              <div className="cards-container">
                {LoanData.map((loan, index) => (
                  <LoanAvailableCard {...loan} key={index} />
                ))}
              </div>
            </div>

            <div className="customer-reviews">
              <div className="title">Customer Reviews</div>
              <div className="reviews-container">
                {CustomerReviewsData.concat(CustomerReviewsData).map(
                  (reviews, index) => (
                    <div className="customer-reviews-card" key={index}>
                      <CustomerReviewsCard {...reviews} />
                    </div>
                  ),
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoanSummary;
