import React, { useEffect, useState } from 'react';
import passportImg from 'assets/passportService/kycsvg.svg';
import { Platformbutton } from 'components/common/button/button';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Success } from 'components/common/success/success';
import successPng from 'assets/physicalCard/newsuccess.png';
import errorsvg from 'assets/newerror.svg';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import api from 'appRedux/api';
import { Phonenumber } from 'components/common/inputs/phoneinput';
import { ImCheckboxChecked } from 'react-icons/im';

export const Passportservices = props => {
  const userData = useSelector(state => state.auth.userData, _.isEqual);

  const [step, setStep] = useState(0);
  const [errorMsg, setErrorMsg] = useState('');
  const [submitBtn, setSubmitBtn] = useState('Submit');

  const [formState, setFormState] = useState({
    // dateOfBirth: '',
    fullName: '',
    email: '',
    description: '',
    country: 'NG',
  });

  const handleInputChange = (name, value) => {
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePost = async () => {
    setErrorMsg('');
    setSubmitBtn('Submitting');
    const payload = {
      userId: userData?.id,
      fullName: formState.fullName,
      email: formState.email,
      phoneNumber: formState.phoneNumber,
      description: formState.description,
    };

    const url = `https://pathwayapi.wevesti.com/api/v1/contact/create`;
    // setLoading(true);
    // you already have an existing application
    try {
      const res = await api.post(url, payload);
      const { data } = res;
      localStorage.setItem('passportMessage', 'Successful');
      console.log(data);
      setStep(3);
      setSubmitBtn('Submit');

      //  setStep(4);
    } catch (error) {
      setErrorMsg(error.data.message);
      error.data.message === 'you already have an existing application'
        ? localStorage.setItem('passportMessage', 'Successful')
        : localStorage.setItem('passportMessage', '');

      // setErrorTile('Account Number Request');
      // setErrorsubtitle('Something happened');
      setStep(4);
      setSubmitBtn('Submit');
    }
  };

  switch (step) {
    case 0:
      return (
        <div>
          <img src={passportImg} alt="" />
          <div style={{ padding: '20px ' }}>
            <p
              style={{
                textAlign: 'center',
                color: '#2B5219',
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              Hello there, do you have a valid international passport?
            </p>
          </div>

          <Platformbutton
            type="normal"
            name={'Yes, I do.'}
            click={() => {
              props.handlePassportPopUp();
              navigate('/explore-pathways');
            }}
          />
          <div style={{ marginTop: '12px' }}></div>
          <Platformbutton
            type="secondary"
            name={"No, I don't"}
            click={() => {
              setStep(1);
            }}
          />

          <div
            style={{
              marginTop: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
            }}
          >
            <ImCheckboxChecked
              style={{
                background: 'white',
                color: props.isChecked ? '#518C36' : 'white',
                cursor: 'pointer',
                border: '1px solid #518C36',
                width: '14px',
                height: '14px',
                borderRadius: '2px',
              }}
              onClick={() => props.handlePassportPopUp()}
            />{' '}
            <p style={{ color: '#2B5219' }}>Don't remind me again</p>
          </div>
        </div>
      );
    case 1:
      return (
        <div>
          <img src={passportImg} alt="" />
          <div style={{ padding: '20px ' }}>
            <p
              style={{
                textAlign: 'center',
                color: '#2B5219',
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              Would you like Vesti to help you fast track the process of getting
              one?
            </p>
          </div>

          <Platformbutton
            type="normal"
            name={'Yes, I do.'}
            click={() => {
              setStep(2);
            }}
          />
          <div style={{ marginTop: '12px' }}></div>
          <Platformbutton
            type="secondary"
            name={"No, I don't"}
            click={() => {
              // props.handlePassportPopUp();

              setStep(5);
              // props.setPassportModal(false);
            }}
          />

          <div
            style={{
              marginTop: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
            }}
          >
            <ImCheckboxChecked
              style={{
                background: 'white',
                color: props.isChecked ? '#518C36' : 'white',
                cursor: 'pointer',
                border: '1px solid #518C36',
                width: '14px',
                height: '14px',
                borderRadius: '2px',
              }}
              onClick={() => props.handlePassportPopUp()}
            />{' '}
            <p style={{ color: '#2B5219' }}>Don't remind me again</p>
          </div>
        </div>
      );

    case 2:
      return (
        <div>
          <div
            style={{
              marginBottom: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <h1
              style={{ color: '#14290A', fontSize: '22px', fontWeight: '500' }}
            >
              We will be in touch!
            </h1>
            <p style={{ color: '#2B5219', fontSize: '14px' }}>
              Fill in the form below with your correct details and a
              professional from the Vesti team will reach out to you to assist
              you with your application
            </p>
          </div>

          <div>
            <Inputfloat
              label="Full Name"
              type="text"
              name="fullName"
              value={formState.fullName}
              disabled={false}
              placeholder="Enter your Full Name"
              onChange={e => handleInputChange('fullName', e.target.value)}
              invalid={'true'}
              error={''}
            >
              <div className="mb-[10px]"></div>
            </Inputfloat>
            <div style={{ marginBottom: '10px' }}></div>
            <Inputfloat
              label="Email"
              type="text"
              name="email"
              value={formState.email}
              disabled={false}
              placeholder="Enter your Email"
              onChange={e => handleInputChange('email', e.target.value)}
              invalid={'true'}
              error={''}
            >
              <div className="mb-[10px]"></div>
            </Inputfloat>
            <div style={{ marginBottom: '10px' }}></div>

            <div style={{ marginBottom: '10px' }}></div>

            <Phonenumber
              value={formState.phoneNumber}
              country={formState.country}
              setValue={value =>
                setFormState({
                  ...formState,

                  phoneNumber: value,
                })
              }
            />
            <div style={{ marginBottom: '20px' }}></div>
            <Inputfloat
              label="Anything you would want us to know?"
              type="text"
              name="description"
              value={formState.description}
              disabled={false}
              placeholder="Anything you would want us to know?"
              onChange={e => handleInputChange('description', e.target.value)}
              invalid={'true'}
              error={''}
            >
              <div className="mb-[10px]"></div>
            </Inputfloat>
            <div style={{ marginBottom: '10px' }}></div>
          </div>

          <div>
            <div className="flexButton">
              <div className="flexButton__cancel">
                <Platformbutton
                  type="secondary"
                  name={'Cancel'}
                  click={() => {
                    props.setPassportModal(false);
                  }}
                />
              </div>
              <div style={{ width: '100%' }}>
                <Platformbutton
                  type="normal"
                  name={submitBtn}
                  click={() => {
                    // setStep(3);
                    handlePost();
                  }}
                  disabled={
                    formState.fullName === '' ||
                    formState.email === '' ||
                    formState.description === ''
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          </div>
        </div>
      );
    case 3:
      return <SuccessSubmission setStep={setStep} />;
    case 4:
      return (
        <Success
          image={errorsvg}
          title={'Error'}
          subtitle={errorMsg}
          onClick={() => setStep(0)}
          button="Try Again"
          type="error"
        />
      );
    case 5:
      return (
        <div>
          <img src={passportImg} alt="" />
          <div style={{ padding: '20px ' }}>
            <p
              style={{
                textAlign: 'center',
                color: '#2B5219',
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              Are you sure? <br /> You need an international passport to begin
              your migration journey in earnest
            </p>
          </div>

          <Platformbutton
            type="normal"
            name={'Help me fast track.'}
            click={() => {
              setStep(2);
            }}
          />
          <div style={{ marginTop: '12px' }}></div>
          <Platformbutton
            type="secondary"
            name={'I’m not ready'}
            click={() => {
              setStep(6);
            }}
          />
        </div>
      );

    case 6:
      return (
        <div>
          <img src={passportImg} alt="" />
          <div style={{ padding: '20px ' }}>
            <p
              style={{
                textAlign: 'center',
                color: '#2B5219',
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              Okay, you can always reach out to{' '}
              <span style={{ color: '#67A948' }}>support@wevesti.com</span> if
              you change your mind!
            </p>
          </div>

          <Platformbutton
            type="normal"
            name={'Okay, thank you.'}
            click={() => {
              props.setPassportModal(false);
            }}
          />
        </div>
      );
    default:
  }
};

const SuccessSubmission = props => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <img src={successPng} alt="" />
      </div>

      <div className="">
        <p
          style={{
            color: '#62943B',
            fontSize: '20px',
            fontWeight: '600',
            textAlign: 'center',
          }}
        >
          Submitted Successfully
        </p>
        <p
          style={{
            color: '#2B5219',
            fontSize: '13px',
            textAlign: 'center',
          }}
        >
          Congratulations!!! You’re on the right path to becoming an
          international passport holder!
        </p>
      </div>
      <div style={{ marginBottom: '10px' }}></div>

      <div style={{ width: '100%' }}>
        <Platformbutton
          type="normal"
          name={'Okay, Thank you'}
          click={() => {
            props.setPassportModal(false);

            // props.setStep(5);
            // navigate('/bank');
          }}
        />
      </div>
    </div>
  );
};
