import React, { useEffect, useState } from 'react';
import '../../../containers/Admin/Admin.css';
import sendMoneypaymentoption from 'assets/TourGuide/sendmoney/sendmoOp.svg';
import selectBeneficiary from 'assets/TourGuide/sendmoney/beneficiary.svg';
import amountTosend from 'assets/TourGuide/amountTosend.png';
import transPin from 'assets/TourGuide/sendmoney/tpin.svg';

import tooltip from 'assets/TourGuide/Tooltip.svg';
import { bankTourFeatures } from 'TourGuide/data';
import '../Intro/intro.scss';
import { LiaTimesSolid } from 'react-icons/lia';
import sendMoneyBtn from 'assets/TourGuide/sendmoney/sendMoBtn.svg';

export const SendMoney = props => {
  const [step, setStep] = useState(0);

  switch (step) {
    case 0:
      return (
        <div style={{ height: '100%', width: '100%' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div className="  w-full h-full">
              <div className="myWalletTour">
                <img
                  src={sendMoneyBtn}
                  alt=""
                  style={{
                    width: '10rem',
                    marginTop: '20rem',
                    marginLeft: '10rem',
                  }}
                />

                <div
                  className="TourArticleCContainer"
                  style={{ marginLeft: '7rem' }}
                >
                  <img
                    className="TourArticleCContainer__tooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Send money
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Click on the{' '}
                            <span
                              style={{ color: '#67A948', fontWeight: '500' }}
                            >
                              “Send Money”{' '}
                            </span>
                            button to start the process of withdrawing money out
                            of your wallet
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            props.handleCloseTourGuide();
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 1} of 5`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__active"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(1);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 1:
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div
              className="  w-full h-full"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="">
                <img
                  src={sendMoneypaymentoption}
                  alt=""
                  style={{ width: '25rem', height: '20rem', marginTop: '4rem' }}
                />

                <div className="TourArticleCContainer">
                  <img
                    className="TourArticleCContainer__tooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Add money
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Click on the{' '}
                            <span
                              style={{ color: '#67A948', fontWeight: '500' }}
                            >
                              “Send Money”
                            </span>{' '}
                            button to start the process of adding money to your
                            wallet
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            props.handleCloseTourGuide();
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 1} of 5`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>

                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(2);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    case 2:
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div
              className="  w-full h-full"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="">
                <img
                  src={selectBeneficiary}
                  alt=""
                  style={{ width: '23rem', height: '17rem', marginTop: '4rem' }}
                />

                <div className="TourArticleCContainer">
                  <img
                    className="TourArticleCContainer__tooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Add money
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Click to copy your account details and send money
                            directly via bank transfer
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            props.handleCloseTourGuide();
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 1} of 5`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>

                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>

                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(3);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    case 3:
      return (
        <div style={{}}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div
              className="  w-full h-full"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="">
                {/* <div
                    className="myWalletTour__wallet"
                    style={{ background: 'white' }}
                  >
                    design a card here
                  </div> */}
                <img
                  src={amountTosend}
                  alt=""
                  style={{ width: '20rem', height: '15rem' }}
                />

                <div className="TourArticleCContainer">
                  <img
                    className="TourArticleCContainer__tooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Add money
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Click to copy your account details and send money
                            directly via bank transfer
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            props.handleCloseTourGuide();
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 1} of 5`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(4);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    case 4:
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div
              className="  w-full h-full"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="">
                {/* <div
                    className="myWalletTour__wallet"
                    style={{ background: 'white' }}
                  >
                    design a card here
                  </div> */}
                <img
                  src={transPin}
                  alt=""
                  style={{ width: '23rem', height: '25rem' }}
                />

                <div className="TourArticleCContainer">
                  <img
                    className="TourArticleCContainer__tooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Add money
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Click to copy your account details and send money
                            directly via bank transfer
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            props.handleCloseTourGuide();
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 1} of 5`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>
                        </ul>

                        <div
                          onClick={() => {
                            props.setStep(0);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Back
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    default:
  }
};
