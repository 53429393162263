import React from 'react';
import Footer from '../Footer';

function JapaFooter() {
  return (
    <div>
      <Footer/>
    </div>
  );
}

export default JapaFooter;
