/* eslint-disable */
import * as Yup from 'yup';

export const SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required')
    .test('domain', 'Please check your email address and try again', value => {
      if (value) {
        const domain = value.split('@')[1];
        return (
          domain &&
          (domain.includes('.com') ||
            domain.includes('.co') ||
            domain.includes('.org') ||
            domain.includes('.io'))
        );
      }
      return false;
    }),
  fullName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Fullname is required'),
  password: Yup.string()
    .required('Password is required')
    .min(7, 'Password is too short - should be 8 chars minimum')
    .matches(
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      /^(?=.*\d)(?=.*[!@#$%^&*,?.])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character (_ exclusive)',
    ),
});

export const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export const resetSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email is required'),
  // password: Yup.string()
  //   .required("Password is required")
  //   .min(4, "Password is too short - should be 4 chars minimum"),
});

export const resetpassSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(7, 'Password is too short - should be 8 chars minimum')
    .matches(
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      /^(?=.*\d)(?=.*[!@#$%^&*,?.])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character(_ exclusive)',
    ),
  cpassword: Yup.string()
    .required('Password is required')
    .min(8, 'Password is too short - should be 8 chars minimum')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});


export const InternationalStudentLoanSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Fullname is required'),
  email: Yup.string()
    .email()
    .required('Email is required'),
  phoneNumber: Yup.string()
    .matches(/^\d{11}$/, 'Phone Number must be 10 digits')
    .required(),
 });
 
 
 
 
 
 
 export const BVNSchema = Yup.object().shape({
  bvn: Yup.string()
    .matches(/^\d{11}$/, 'BVN must be exactly 11 digits')
    .required('BVN is required'),
 });
 
 
 
 
 
 
 export const StudentLoanSchema = Yup.object().shape({
  school: Yup.string().required("School is required"),
  courseOfStudy: Yup.string().required("School is required"),
  loanAmountNeeded: Yup.number()
  .required("Loan amount needed is required")
  .typeError("Loan amount needed must be a number")
 })
 
 
 
 
 export const ProofOfFundsApplicationSchema = Yup.object().shape({
  whichBank: Yup.string()
    .required('Which bank account would you like your Proof of Funds to be disbursed into is required'),
 });
 
 
