import React from 'react';
import './Styles/HomePageFooter.scss';
import Bloomberg from '../../assets/Bloomberg.svg';
import techcity from '../../assets/techcity.svg';
// import summitech from "../../assets/summitech.svg";
// import guardian from "../../assets/guardian.svg";
// import youtube from '../../assets/youtube-logo.svg';
import techstars from '../../assets/techstars.svg';
// import disrupt from "../../assets/disrupt.svg";
import disrrupt from '../../assets/disrrupt.svg';
import Footer from './Footer';

function HomePageFooter() {
  return (
    <div className='footer'>
      <section className="pt-5 mt-5 pb-5 featured-container">
        <div className="featured-iner">
          <p>We've Been Featured In</p>
          <div className="featured-iner company-logos">
            <a
              rel="noreferrer noopener"
              target="_blank"
              href="https://www.bloomberg.com/press-releases/2022-08-30/nova-credit-and-vesti-partner-to-alleviate-financial-barriers-for-u-s-bound-nigerians"
            >
              <img className=" bloomberg " src={Bloomberg} alt="Bloomberg" />
            </a>

            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.techcityng.com/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/"
            >
              <img className="" src={techcity} alt="tech" />
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://disrupt-africa.com/2022/04/14/3-nigerian-startups-selected-for-techstars-nyc-accelerator-programme/"
            >
              <img className="" src={disrrupt} alt="disrupt-africa" />
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.techstars.com/newsroom/techstars-nyc-class-of-spring-2022"
            >
              <img className="" src={techstars} alt="Techstars" />
            </a>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
}

export default HomePageFooter;
