import React, { useEffect, useState } from 'react';
import '../Admin.css';
import './newsavings.scss';
// import Loader from 'components/Loader';
import api from 'appRedux/api';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import {
  getCurrency,
  objectValuesStringify,
  removeCommaAmount,
} from 'helpers/utils';
import { Platformbutton } from 'components/common/button/button';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Success } from 'components/common/success/success';
import errorsvg from '../../../assets/errorsvg.svg';
import wallet from '../../../assets/radios/radio_wallets.svg';
import newcard from '../../../assets/radios/radio_newcards.svg';
import { Transreview } from 'components/common/transactionreview/review';
import { Form } from 'antd';
import { RadioOption } from 'components/common/radiobutton/radiobutton';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { AlertError } from 'components/common/alertboxes/alertboxes';
import { Littlebalance } from 'components/bank/littlebalance/littlebalance';
import {
  IconOption,
  IconSingleValue,
  Singleselect,
} from 'components/common/inputs/singleselect';
import Visa from 'assets/visa.svg';
import Mastercard from 'assets/Mastercardsafelock.svg';
import Verve from 'assets/verve.svg';
import { PaystackButton } from 'react-paystack';
import { Comptransaction } from 'components/common/completetransaction/comptransaction';
import { fetchTransactionOTP } from 'appRedux/actions/wallets';

const TopUp = ({ close, selectedPlan, getSavings }) => {
  const allWallet = useSelector(state => state.wallets.wallets, _.isEqual);
  const userData = useSelector(state => state.auth.userData, _.isEqual);
  // const publicKey = 'pk_test_2aa8ac09a6a34eb6c01d1b27b088e9d78bd50426';
  const publicKey = 'pk_live_5ac54092a585eaedb83ec09a1f8896928ffdca87';
  const defaultWallet = allWallet?.find(item => item.default === true);
  const [step, setStep] = useState(1);
  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState(false);
  const [transMessage, setTransMessage] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [reference, setReference] = useState('');
  const [cardOptions, setCardOptions] = useState([]);
  const [cardId, setCardId] = useState('');
  const [formData, setFormData] = useState({
    amount: '',
    paymentChannel: '',
    cardId: '',
  });

  const formatNumberInput = value => {
    // Remove existing commas and convert to string
    const stringValue = value.toString().replace(/,/g, '');

    // Use a regular expression to add commas for thousands separators
    const formattedValue = stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedValue;
  };

  var setInput = e => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'targetAmount' || name === 'amount') {
      value = formatNumberInput(value);
    }

    setFormData({ ...formData, [name]: value });
    // console.log(formData);
  };

  var setTransactionPin = value => {
    setPin(value);
  };

  var handleSelect = (name, value) => {
    setFormData({ ...formData, [name]: value?.value });
    // console.log(formData);
  };

  // console.log('Top Up Savings Plan: ', selectedPlan);

  const config = {
    reference,
    email: userData?.email,
    amount: 10000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: publicKey,
  };

  const chargeCard = async () => {
    setLoading(true);
    try {
      // Make a request to your server to obtain the access code
      const response = await api.post('/card/initialize-transaction');
      // console.log('Response : ', response);
      setAccessCode(response.data?.data?.access_code);
      setReference(response.data?.data?.reference);
      // getCards();
      setLoading(false);

      // Once access code is obtained, initiate the payment process
    } catch (error) {
      setLoading(false);
      console.error('Error fetching access code:', error);
    }
  };

  const getCards = async () => {
    try {
      const res = await api.get('/card/saved-cards');
      // console.log(res);
      setCardOptions(
        res.data?.data?.map(item => ({
          value: item.id,
          label: `***** ${item.cardLast4}`,
          image:
            item.cardBrand === 'visa ' || item.cardBrand === 'Visa'
              ? Visa
              : item.cardBrand === 'Mastercard' ||
                item.cardBrand === 'mastercard'
              ? Mastercard
              : Verve,
        })),
      );
    } catch (error) {
      console.error('Error fetching saved cards:', error);
    }
  };

  const handlePaystackSuccessAction = async reference => {
    // console.log('Reference : ', reference);
    setLoading(true);
    // Implementation for whatever you want to do with reference and after success call.
    try {
      const res = await api.post('/card/verify-reference', reference);
      // console.log(res);
      setCardId(res.data?.data?.cardId);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching card:', error);
    }
  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    setStep(1);
    setAccessCode('');
    setReference('');
    console.log('closed');
  };

  const componentProps = {
    ...config,
    text: 'Proceed to add card',
    onSuccess: reference => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  const handleFundSavingsPlan = async () => {
    const url = '/safe-lock/top-up';
    const data = {
      safeLockId: selectedPlan.id,
      paymentChannel: formData.paymentChannel,
      cardId: cardId ? cardId : formData.cardId.value?.value,
      transactionOtp: pin,
    };
    // console.log(data);
    setLoading(true);
    try {
      const res = await api.post(url, data);
      // console.log({ ...res });
      openNotificationWithIcon(res.data?.message, 'Savings Top Up', 'success');
      setTransMessage(res.data?.message);
      if (res.data?.data?.link) {
        window.open(res.data?.data?.link, '_blank');
      }
      setStep(3);
    } catch (error) {
      if (error?.data?.errors) {
        setTransMessage(error?.data?.errors);
        openNotificationWithIconErr(
          objectValuesStringify(error?.data?.errors),
          'Savings Top Up',
          'error',
        );
        setStep(4);
      } else {
        //console.log('err', error?.data?.message);
        setTransMessage(error?.data?.message || error?.message);
        openNotificationWithIconErr(
          error?.data?.message || error?.message,
          'Savings',
          'error',
        );
        setStep(4);
      }
      //console.log({ ...error });
    }
    setLoading(false);
  };

  const handlePageFocus = () => {
    if (step === 3) {
      close(); // Close the modal
      getSavings(); // Refetch the function
    }
  };

  useEffect(() => {
    getCards();
  }, []);

  useEffect(() => {
    // Add event listener for page focus
    window.addEventListener('focus', handlePageFocus);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('focus', handlePageFocus);
    };
  }, [step]);

  var __renderSteps = () => {
    switch (step) {
      case 0:
        return <></>;
      case 1:
        return (
          <StepTwo
            step={step}
            close={close}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            setInput={setInput}
            handleSelect={handleSelect}
            loading={loading}
            setLoading={setLoading}
            selectedPlan={selectedPlan}
            defaultWallet={defaultWallet}
            chargeCard={chargeCard}
            componentProps={componentProps}
            cardOptions={cardOptions}
            accessCode={accessCode}
          />
        );
      case 2:
        return (
          <Finalize
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            setInput={setInput}
            handleSelect={handleSelect}
            setTransactionPin={setTransactionPin}
            loading={loading}
            selectedPlan={selectedPlan}
            setLoading={setLoading}
            handleFundSavingsPlan={handleFundSavingsPlan}
            defaultWallet={defaultWallet}
          />
        );
      case 3:
        return (
          <Success
            title="Successfully Initiated"
            subtitle={transMessage}
            onClick={() => close()}
            button="Okay, thank you"
          />
        );
      case 4:
        return (
          <Success
            image={errorsvg}
            title={`Failed`}
            subtitle={transMessage}
            onClick={() => setStep(1)}
            button="Try Again"
            type="error"
          />
        );
      default:
        return <></>;
    }
  };

  return <div className="create-savings-box">{__renderSteps()}</div>;
};

const StepTwo = props => {
  return (
    <>
      <Form className="create-savings-form ">
        <div className="title">
          <p>Step 1 of 2</p>
          <p>Select Savings Method</p>
        </div>
        <div className="radios">
          <RadioOption
            image={wallet}
            changed={value =>
              props.setFormData({ ...props.formData, paymentChannel: value })
            }
            id="0"
            isSelected={props.formData.paymentChannel === 'wallet'}
            label="Save with your Vesti Wallet"
            sublabel=""
            value="wallet"
          />
          {props.formData.paymentChannel === 'wallet' && (
            <div className="d-flex justify-content-start w-100">
              <Littlebalance
                type={(props.defaultWallet?.currency).toLowerCase()}
                title={`${props.defaultWallet?.currency} Balance`}
                currency={getCurrency(props.defaultWallet?.currency)}
                amount={props.defaultWallet?.balance / 100}
              />
            </div>
          )}

          {props.formData.paymentChannel === 'wallet' &&
            props.defaultWallet?.balance / 100 <
              props.selectedPlan?.amount / 100 && (
              <div className="d-flex justify-content-start w-100">
                <AlertError
                  body={`You do not have upto ${getCurrency(
                    props.defaultWallet?.currency,
                  )} ${(
                    props.selectedPlan.amount / 100
                  ).toLocaleString()} in your Vesti wallet, please deposit into your account.`}
                />
              </div>
            )}

          <RadioOption
            image={newcard}
            changed={value =>
              props.setFormData({ ...props.formData, paymentChannel: value })
            }
            id="1"
            isSelected={props.formData.paymentChannel === 'card'}
            label="Save with your Debit Card"
            sublabel=""
            value="card"
          />
          {props.cardOptions?.length > 0 &&
            props.formData.paymentChannel === 'card' && (
              <div className=" addcardsafelock" style={{ width: '100%' }}>
                {props.formData.cardId && (
                  <Platformbutton
                    type="withiconnobg"
                    name="Add a new Card"
                    classname="fas fa-plus"
                    click={() => props.chargeCard()}
                  />
                )}
                <Singleselect
                  value={props.formData.cardId.value}
                  placeholder="Choose Card"
                  options={props.cardOptions}
                  onChange={value =>
                    props.setFormData({
                      ...props.formData,
                      cardId: {
                        ...props.formData.cardId,
                        value: value,
                      },
                    })
                  }
                  components={{
                    SingleValue: IconSingleValue,
                    Option: IconOption,
                  }}
                />
              </div>
            )}
        </div>

        <div className="newsavings-backcontinue">
          <Backcontinue goBack={() => props.close()}>
            {!props.accessCode && props.formData?.cardId && (
              <Platformbutton
                name="Proceed"
                type="normal"
                click={() => props.setStep(2)}
                disabled={
                  !props.formData.paymentChannel ||
                  (props.formData.paymentChannel === 'wallet' &&
                    props.defaultWallet?.balance / 100 <
                      props.selectedPlan?.amount / 100)
                }
              />
            )}

            {props.accessCode && props.cardId && (
              <Platformbutton
                name="Continue"
                type="normal"
                click={() => props.setStep(2)}
                disabled={!props.formData.paymentChannel ? true : false}
              />
            )}
            {!props.accessCode && !props.formData?.cardId && (
              <Platformbutton
                name={
                  props.formData.paymentChannel === 'card'
                    ? 'Add a new card'
                    : 'Proceed'
                }
                type="normal"
                click={() =>
                  props.formData.paymentChannel === 'card'
                    ? props.chargeCard()
                    : props.setStep(2)
                }
                disabled={!props.formData.paymentChannel ? true : false}
              />
            )}

            {props.accessCode && !props.cardId && (
              <PaystackButton
                className="paystackbutton"
                {...props.componentProps}
              />
            )}
          </Backcontinue>
        </div>
      </Form>
    </>
  );
};

const Finalize = props => {
  // console.log('Finalize: ',props)
  return (
    <div className="create-savings-form ">
      <div className="title">
        <p>Step 2 of 2</p>
      </div>
      <div className="topup-transreview">
        <Transreview
          data={[
            {
              title: 'Transaction Type',
              value: `Instant Top Up`,
            },
            { title: 'Savings plan', value: props.selectedPlan.name },
            {
              title: 'Amount',
              value: `${removeCommaAmount(
                props.selectedPlan.amount / 100,
              ).toLocaleString('en-US')}`,
            },
            {
              title: 'Payment Method',
              value: props.formData.paymentChannel,
            },
          ]}
        />
      </div>
      <div className="mb-2"></div>

      {props.formData?.paymentChannel === 'wallet' ? (
        <>
          <Comptransaction
            fetcher={fetchTransactionOTP}
            setPin={props.setTransactionPin}
            loading={props.loading}
            goBack={() => props.setStep(1)}
            btn="Finalize transaction"
            onFinish={props.handleFundSavingsPlan}
            len={4}
            lower={true}
          >
            <Backcontinue goBack={() => props.setStep(1)}>
              <Platformbutton
                name="Finalize transaction"
                type="normal"
                click={() => props.handleFundSavingsPlan()}
                disabled={props.loading ? true : false}
              />
            </Backcontinue>
          </Comptransaction>
          <p style={{ textAlign: 'center' }}>
            Didn't get the code?{' '}
            <span
              style={{ cursor: 'pointer', color: 'green' }}
              onClick={() => props?.dispatch(props?.fetchTransactionOTP())}
            >
              Resend
            </span>
          </p>
        </>
      ) : (
        <Backcontinue goBack={() => props.setStep(1)}>
          <Platformbutton
            name="Finalize transaction"
            type="normal"
            click={() => props.handleFundSavingsPlan()}
            disabled={props.loading ? true : false}
          />
        </Backcontinue>
      )}
    </div>
  );
};

export default TopUp;
