const pathwayconfig = {
  development: {
    // baseURL: 'https://pathway-staging.wevesti.com/api/v1',
    baseURL: 'https://pathwayapi.wevesti.com/api/v1',
  },
  production: {
    baseURL: 'https://pathwayapi.wevesti.com/api/v1',
    // baseURL: 'https://pathway-staging.wevesti.com/api/v1',
  },
};

export default pathwayconfig[process.env.NODE_ENV];
