import React, { useState, useEffect } from 'react';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { navigate } from '@reach/router';
import './Notification.scss';
import Layout from '../common/DashboardLayout';
import emptyImage from '../../assets/emptyxx.svg';
import Arrow from '../../assets/arrow-leftxx.svg';
import close from '../../assets/closexx.svg';
import Indicator from '../../assets/Inidcatorxx.svg';
import api from 'appRedux/api';
import {
  fetchNotifications,
  updateNotification,
} from 'appRedux/actions/notification';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/Loader';
import { updatePrevLocation } from 'appRedux/actions/location';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const Notification = () => {
  // const [selectedNotification, setSelectedNotification] = useState(null);
  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const dispatch = useDispatch();
  // const { data: notificationData, loading, error } = useSelector(
  //   state => state.notifications,
  // );

  //   useEffect(() => {
  //     dispatch(fetchNotifications());
  //     dispatch(updatePrevLocation(prevPathname));
  //   }, [dispatch]);

  //   const prevPathname = useSelector(state => state.location.prevpathname);
  // console.log('prevPathname',prevPathname);

  //   const openModal = async notification => {
  //     setSelectedNotification(notification);
  //     setIsModalOpen(true);
  //     dispatch(updateNotification(notification.notificationId));
  //     dispatch(fetchNotifications());
  //   };

  //   const closeModal = () => {
  //     setSelectedNotification(null);
  //     setIsModalOpen(false);
  //     dispatch(fetchNotifications());
  //   };

  const [openNotifications, setOpenNotifications] = useState([]);

  const dispatch = useDispatch();
  const { data: notificationData, loading, error } = useSelector(
    state => state.notifications,
  );

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchNotifications());
    dispatch(updatePrevLocation(prevPathname));
  }, [dispatch]);

  const prevPathname = useSelector(state => state.location.prevpathname);
  console.log('prevPathname', prevPathname);

  const handleClick = () => {
    navigate(prevPathname);
  };

  const toggleNotification = index => {
    setOpenNotifications(prev =>
      prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index],
    );
  };

  const handleRegisterClick = notificationId => {
    dispatch(updateNotification(notificationId));
    dispatch(fetchNotifications());
  };

  return (
    <Layout>
      <div className="notification__container">
        <div className="notification__header" onClick={handleClick}>
          <img src={Arrow} alt="Arrow" className="notification__header-icon" />
          Notifications
        </div>
        <div className="notification__body">
          {loading ? (
            <Loader />
          ) : error ? (
            <p>Error loading notifications</p>
          ) : notificationData.filter(n => n.read !== true).length === 0 ? (
            <div className="notification__body--empty">
              <img
                src={emptyImage}
                alt="No Notifications"
                className="notification__body--empty-image"
              />
              <div className="notification__body--empty-text">
                <h2 className="notification__body--empty-text-title">
                  No notifications yet
                </h2>
                <p className="notification__body--empty-text-subtitle">
                  You have no notifications right now. Come back later.
                </p>
              </div>
            </div>
          ) : (
            notificationData
              .filter(n => n.read !== true)
              .map((notification, index) => {
                const {
                  id,
                  notificationId,
                  notification: notifDetails,
                  createdAt,
                  read,
                } = notification;

                return (
                  <div
                    key={index}
                    className={`notification__notification`}
                    onClick={() => toggleNotification(index)}
                  >
                    <div className="notification__notification-card">
                      <img
                        src={Indicator || emptyImage}
                        alt="Notification Icon"
                        className="notification__notification-card-icon"
                      />
                      <div className="notification__notification-card-text">
                        <h1 className="notification__notification-card-text-title">
                          {notifDetails?.title || 'No Title'}
                        </h1>
                        <p className="notification__notification-card-text-subtitle">
                          {notifDetails?.description.slice(0, 200) ||
                            'No Description'}
                        </p>
                        <p className="notification__notification-card-text-dateu">
                          {new Date(createdAt).toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'long',
                          })}
                        </p>
                      </div>
                      {openNotifications.includes(index) ? (
                        <IoIosArrowUp
                          onClick={e => {
                            e.preventDefault();
                            handleRegisterClick(notificationId);
                          }}
                        />
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </div>

                    {openNotifications.includes(index) && (
                      <div className="p-3 sm:p-4">
                        <img
                          src={notifDetails.image || emptyImage}
                          alt="Notification Detail"
                          className="w-full h-auto object-cover rounded-lg mb-4"
                        />
                        <h2 className="text-base sm:text-lg font-semibold mb-2">
                          {notifDetails.title || 'No Title'}
                        </h2>
                        <p className="text-sm sm:text-base mb-4">
                          {notifDetails.description || 'No Description'}
                        </p>

                        {notifDetails.link && (
                          <div className="notification__modal-content-details-buttonx">
                            <a
                              href={notifDetails.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button className="notification__modal-content-details-button-register text-white">
                                Register
                              </button>
                            </a>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Notification;
