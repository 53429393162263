import React, { useState, useEffect } from 'react';
import { Platformbutton } from 'components/common/button/button';
import Inputfloat from 'components/common/inputs/inputfloat';
import { OnboardingSuccess } from 'components/common/success/success';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
// eslint-disable-next-line
import {
  Passwordcheck,
  Registerleft,
  VerifyReg,
} from 'components/auth/register/registerfeatures';
import { Formik } from 'formik';
import { useStep } from 'helpers/hooks';
import { SignUpSchema } from 'helpers/schema';
import { navigate } from '@reach/router';
import Toggle from 'react-toggle';
import { Phonenumber } from 'components/common/inputs/phoneinput';
import { fetchAllCountries } from 'appRedux/actions/Common';
import { connect, useDispatch } from 'react-redux';
import { Singleselect } from 'components/common/inputs/singleselect';
import {
  signUpUserV2,
  signUpUserP2V2,
  verifyNewUserAuthCode,
  resendUserMail,
} from 'appRedux/actions/auth';
import { useLocation } from '@reach/router';
import { AlertError } from 'components/common/alertboxes/alertboxes';
import { Shortinfo } from 'components/common/shortinfo/shortinfo';
// eslint-disable-next-line
import { getCurrencyCode, getPhoneCode } from 'helpers/utils';
import { AUTH_LOAD } from 'appRedux/constants';
import './register.scss';
import { IoIosArrowDown } from 'react-icons/io';
import { SmallRadioOption } from 'components/common/radiobutton/radiobutton';
import ModernDatepicker from 'react-modern-datepicker';
import { ImCheckboxChecked } from 'react-icons/im';
import arrowPointer from 'assets/newOnboarding/Ornament.svg';
import { TransactionPin } from './transactionPin';
import { OnboardingKYC } from 'components/profile/KycLv1';
// eslint-disable-next-line
// import api from 'appRedux/api';
const Registerv2 = props => {
  // eslint-disable-next-line
  const { step, nextStep, previousStep, setStep } = useStep(0);
  const [open, setOpen] = useState(false);

  const [requestCard, setRequestCard] = useState(false);
  const [data, setData] = useState({
    email: '',
    phoneNumber: '',
    // dob:''
  });
  const [option, setOption] = useState(null);
  const setMyOption = value => {
    setOption(value);
  };

  const [isChecked, setIsChecked] = useState(false);
  const [checkSameAddress, setcheckSameAddress] = useState(false);
  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    // Update the state variable
    setIsChecked(!isChecked);

    // Log the current state (you can replace this with your logic)
  };

  const handleAddCheckboxChange = () => {
    // Update the state variable
    setcheckSameAddress(!checkSameAddress);

    // Log the current state (you can replace this with your logic)
  };

  // console.log(props.countries);

  useEffect(() => {
    props?.authMessage === 'Email verified successfully'
      ? setOpen(true)
      : setOpen(false);
  }, [props?.authMessage]);

  const [refCode, setRefCode] = useState('');
  const location = useLocation();
  //  const pathname = window.location.pathname;
  //  const splitLocation = pathname.split('/');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const referralCode = searchParams.get('referalCode');
    setRefCode(referralCode);

    // setToggle(true);
  }, []);
  const [country, setCountry] = useState('');

  // eslint-disable-next-line
  const [verify, setVerify] = useState('');
  var renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <FirstReg
            setVerify={setVerify}
            cb={setStep}
            setData={setData}
            data={data}
            signUpUserV2={props.signUpUserV2}
            authMessage={props.authMessage}
            authErr={props.authErr}
            loading={props.authLoad}
            countries={props.countries}
            requestCard={requestCard}
            setRequestCard={setRequestCard}
            option={option}
            setMyOption={setMyOption}
            isChecked={isChecked}
            handleCheckboxChange={handleCheckboxChange}
            setCountry={setCountry}
            setStep={setStep}
            checkSameAddress={checkSameAddress}
            setcheckSameAddress={setcheckSameAddress}
            handleAddCheckboxChange={handleAddCheckboxChange}
          />
        );
      case 1:
        return (
          <VerifyReg
            loading={props.authLoad}
            title={data.email}
            resend={() => props.resendUserMail()}
            // resend={() =>
            //   props.resendUserMail({ userId: localStorage.getItem('userId') })
            // }
            verify={code =>
              props.verifyNewUserAuthCode(
                { userId: localStorage.getItem('userId'), code: code },
                setStep,
              )
            }
            authMessage={props.authMessage}
            authErr={props.authErr}
            step={step}
            setStep={setStep}
          />
        );
      case 2:
        return (
          <div className="w-full md:mt-[2rem] relative md:w-[500px] items-center flex flex-col flex-start  justify-center h-full">
            {/* <div className="signupsuccesscontainer">
       <Success
   title="Successful"
       subtitle={props.authMessage}
     onClick={() => navigate('/auth')}
        button={'Continue to log in'}
      />
 </div>
 <Simplemodal open={open} closable={false}>
           </Simplemodal>  */}

            <OnboardingSuccess
              title="Email Verified"
              subtitle={
                'You have successfully verified your email. You can now proceed to verifying your identity.'
              }
              onClick={() => setStep(3)}
              button={'Continue your registration'}
            />

            {/* <p
              onClick={() => {
                props.setStep(2);
              }}
              style={{
                color: '#67A948',
                fontWeight: '600',
                fontSize: '18px',
                cursor: 'pointer',
              }}
            >
              Go back
            </p> */}
          </div>
        );

      case 3:
        const myCountry = country
          ? country
          : localStorage.getItem('myonboarding_selectedCountry');
        return (
          <div className="w-full md:mt-[2rem] relative md:w-[500px] items-center flex flex-col flex-start  justify-center h-full">
            <img
              src={arrowPointer}
              alt=""
              style={{ position: 'absolute', top: '10%', left: '-12%' }}
            />

            <p
              style={{
                fontSize: '22px',
                fontWeight: '700',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '5rem',
              }}
            >
              Verify your identity
            </p>
            <p
              style={{
                color: '#111827',
                fontSize: '14px',
                fontWeight: '300',
              }}
            >
              To give you access to financial services like debit card loans,
              and wallet account, we need your government ID numbers.
            </p>

            <div className="mt-4"></div>
            <OnboardingKYC country={myCountry} setStep={setStep} />
            {/* <IdentityVerification setStep={setStep} step={step} /> */}

            {/* <p
              onClick={() => {
                setStep(2);
              }}
              style={{
                color: '#67A948',
                fontWeight: '600',
                fontSize: '18px',
                cursor: 'pointer',
                paddingTop: '20px',
              }}
            >
              Go back
            </p> */}
          </div>
        );
      case 4:
        return (
          <div className="w-full md:mt-[2rem] relative md:w-[500px] items-center flex flex-col flex-start  justify-center h-full">
            <img
              src={arrowPointer}
              alt=""
              style={{ position: 'absolute', top: '10%', left: '-12%' }}
            />

            <TransactionPin setStep={setStep} />
          </div>
        );
      case 5:
        return (
          <div className="w-full md:mt-[2rem] relative md:w-[500px] items-center flex flex-col flex-start  justify-center h-full">
            <OnboardingSuccess
              title="Onboarding Complete"
              subtitle={
                'You have successfully created a Vesti Account. Click on the button below to login and explore Vesti.'
              }
              onClick={() => navigate('/auth')}
              button={'Proceed to Login'}
            />

            {/* <p
              onClick={() => {
                props.setStep(2);
              }}
              style={{
                color: '#67A948',
                fontWeight: '600',
                fontSize: '18px',
                cursor: 'pointer',
              }}
            >
              Go back
            </p> */}
          </div>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    props.fetchAllCountries();
  }, []);
  return (
    // lg:gap-[100px]
    <div className=" flex" style={{ height: '100vh' }}>
      <Registerleft />
      {/* lg:w-[550px] */}
      <div
        className="col-span-2 p-[30px] flex flex-col gap-[15px] items-center  w-full onBoardLeft"
        style={{
          height: '100%',
          overflow: 'auto',
        }}
      >
        {renderSteps()}
      </div>
    </div>
  );
};

const FirstReg = props => {
  const [toggle, setToggle] = useState(false);
  const [refCode, setrefCode] = useState('');
  const dispatch = useDispatch();
  const [myaddress, setMyAddress] = useState('');
  // console.log(props)

  return (
    <div className="w-full md:mt-[2rem] relative md:w-[500px] items-center flex flex-col flex-start ">
      <img
        src={arrowPointer}
        alt=""
        style={{ position: 'absolute', top: '2%', left: '-12%' }}
      />
      {/* <img
        className="w-[100px] md:w-[100px] my-4"
        src={VestiLogo}
        alt="Vesti logo"
      />
      <Titlesubtitle
        title="Create Account"
        subtitle="Join more than 100,000 Users finding new homes abroad, raising
                the money they need to move and making difficult international
                payments with Vesti."
      /> */}

      <p
        style={{
          fontSize: '22px',
          fontWeight: '700',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '5rem',
        }}
      >
        Let’s Get You Started
      </p>

      <p
        style={{
          color: '#111827',
          fontSize: '14px',
          fontWeight: '300',
          marginBottom: '20px',
        }}
      >
        Input your details and let’s help you unlock your global potential.
      </p>

      <Formik
        initialValues={{
          fullName: '',
          email: '',
          residentialaddress: '',
          password: '',
          confirmPassword: '',
          country: '',
          countryCode: '',
          phoneNumber: '',
          referalCode: '',
          otherName: '',
          state: '',
          city: '',

          // address: props.checkSameAddress ? props.residentialaddress : '',
        }}
        validationSchema={SignUpSchema}
        onSubmit={values => {
          props.signUpUserV2(
            {
              ...values,
              countryCode:
                values.countryCode.label == 'South Africa'
                  ? 'ZAR'
                  : getCurrencyCode(values.countryCode.value),
              currencyFlag: values.countryCode.image,
              country: values.countryCode.label,
              referalCode: props.refCode ? props.refCode : refCode,

              phoneNumber: {
                code: getPhoneCode(values.countryCode.label),
                number: values.phoneNumber.slice(
                  getPhoneCode(values.countryCode.label).length,
                ),
              },
              dob: props.data.dob?.value,
              createCard: props.option,
              address: props.checkSameAddress
                ? localStorage.getItem('myonboarding_address')
                : myaddress,
            },
            // console.log(values),
            props.cb,
          );
          props.setData({
            ...props.data,
            email: values.email,
          });
        }}
      >
        {formik => {
          // eslint-disable-next-line
          const {
            errors,
            touched,
            // isValid,
            values,
            handleChange,
            setFieldValue,
            handleSubmit,
          } = formik;

          localStorage.setItem(
            'myonboarding_selectedCountry',
            values.countryCode.label,
          );
          localStorage.setItem('myonboarding_password', values.password);
          localStorage.setItem(
            'myonboarding_address',
            values.residentialaddress,
          );
          console.log(myaddress);
          console.log(localStorage.getItem('myonboarding_address'));
          // props.setCountry(values.countryCode.label);
          return (
            <form
              onSubmit={handleSubmit}
              className="w-full flex flex-col gap-[50px] lg:w-[450px]"
            >
              <div className="w-full flex flex-col ">
                <Inputfloat
                  label="Full Name"
                  type="text"
                  name="fullName"
                  value={values.fullName}
                  disabled={false}
                  placeholder="Daniel Ajibola"
                  onChange={handleChange}
                  invalid={errors.fullName && touched.fullName && 'true'}
                  error={
                    errors.fullName && touched.fullName ? errors.fullName : ''
                  }
                >
                  <Shortinfo subject="Full Name should be exactly how it is written on your ID" />
                </Inputfloat>

                <div className="" style={{ display: 'none' }}>
                  <Inputfloat
                    label="Address"
                    type="text"
                    name="Address"
                    value={values.residentialaddress}
                    disabled={false}
                    placeholder="Enter your Residential Address"
                    onChange={handleChange}
                  />
                </div>

                <Inputfloat
                  label="email"
                  type="text"
                  name="email"
                  value={values.email}
                  disabled={false}
                  placeholder="johndoe@gmail.com"
                  onChange={handleChange}
                  invalid={errors.email && touched.email && 'true'}
                  error={errors.email && touched.email ? errors.email : ''}
                />

                <Inputfloat
                  label="residential address"
                  type="text"
                  name="residentialaddress"
                  value={values.residentialaddress}
                  disabled={false}
                  placeholder="Enter your Residential Address"
                  onChange={handleChange}
                  // invalid={errors.email && touched.email && 'true'}
                  // error={errors.email && touched.email ? errors.email : ''}
                />

                <span className="mt-4 flex flex-col gap-[35px] lg:flex-row lg:gap-[10px]">
                  <Singleselect
                    label="Country"
                    value={values.countryCode}
                    options={props.countries}
                    onChange={value => {
                      setFieldValue('countryCode', value);
                    }}
                    placeholder="Select Country"
                    // components={{SingleValue: IconSingleValue, Option: IconOption}}
                  />

                  <Phonenumber
                    value={values.phoneNumber}
                    country={values.countryCode?.value}
                    setValue={value => setFieldValue('phoneNumber', value)}
                  />
                </span>
                <span className="flex flex-col gap-[10px]">
                  <Inputfloat
                    label="Password"
                    type="password"
                    name="password"
                    value={values.password}
                    disabled={false}
                    placeholder="Enter your password"
                    onChange={handleChange}
                    invalid={errors.password && touched.password && 'true'}
                    error={
                      errors.password && touched.password ? errors.password : ''
                    }
                  ></Inputfloat>

                  <Inputfloat
                    label="Confirm Password"
                    type="password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    disabled={false}
                    placeholder="Confirm your password"
                    onChange={handleChange}
                    // invalid={errors.password && touched.password && 'true'}
                    // error={
                    //   errors.password && touched.password ? errors.password : ''
                    // }
                  ></Inputfloat>

                  {values.password === values.confirmPassword ||
                  (values.password && !values.confirmPassword) ? (
                    <div></div>
                  ) : (
                    <div style={{ color: 'red', marginTop: '5px' }}>
                      Passwords do not match
                    </div>
                  )}

                  <div className="mb-[10px]"></div>

                  {errors.password && touched.password && (
                    <div className="mb-[8px]"></div>
                  )}
                  <span className="flex flex-wrap gap-[10px]">
                    <Passwordcheck
                      name="8 Characters"
                      check={values.password.match(/^.{8,}$/)}
                    />
                    <Passwordcheck
                      name="A number"
                      check={values.password.match(/\d/)}
                    />
                    <Passwordcheck
                      name="A lowercase letter"
                      check={values.password.match(/[a-z]/)}
                    />
                    <Passwordcheck
                      name="An uppercase letter"
                      check={values.password.match(/[A-Z]/)}
                    />
                    <Passwordcheck
                      name="A Special character"
                      check={values.password.match(/[^\w]/)}
                    />
                    {/* <Passwordcheck
                      name="Confirm password"
                      check={values.password === values.confirmPassword}
                    /> */}
                  </span>
                </span>

                <div className="mt-2 flex flex-col gap-[10px]">
                  {toggle && (
                    <Inputfloat
                      label="Referral Code"
                      type="text"
                      name="referalCode"
                      value={props.refCode ? props.refCode : refCode}
                      disabled={false}
                      placeholder="Referral Code"
                      // onChange={handleChange}
                      onChange={
                        props.refCode
                          ? handleChange
                          : e => setrefCode(e.target.value)
                      }
                    />
                  )}
                  <span className="flex gap-[10px] justify-between items-center">
                    <p className="text-vesti-901 font-[400] text-[1em] m-[0px]">
                      I was referred by someone
                    </p>
                    {/* <Toggle handleToggle={()=> setToggle(!toggle)} /> */}
                    <Toggle
                      defaultChecked={toggle}
                      icons={false}
                      onChange={() => setToggle(!toggle)}
                    />
                  </span>
                </div>

                <div style={{ position: 'relative' }}>
                  <div className="onboardrequestCard">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        props.requestCard
                          ? props.setRequestCard(false)
                          : props.setRequestCard(true);
                      }}
                    >
                      <p
                        style={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#AEB4BE',
                        }}
                      >
                        {props.option === ''
                          ? 'Select Yes/No to get a FREE debit card 💳'
                          : props.option === true
                          ? 'Yes, I would like a free debit card'
                          : props.option === false
                          ? 'No, I wouldn’t like a free debit card'
                          : 'Select Yes/No to get a FREE debit card 💳'}
                      </p>

                      <p
                        onClick={() => {
                          props.requestCard
                            ? props.setRequestCard(false)
                            : props.setRequestCard(true);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <IoIosArrowDown />
                      </p>
                    </div>
                  </div>

                  {props.requestCard && (
                    <div
                      style={{
                        position: 'absolute',
                        width: '100%',
                        zIndex: '10000',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <div
                          style={{
                            width: '90%',
                          }}
                        >
                          <div className="mb-2"></div>

                          <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              props.setRequestCard(false);
                            }}
                          >
                            <SmallRadioOption
                              image={''}
                              changed={props.setMyOption}
                              id="1"
                              isSelected={props.option === true}
                              label=""
                              sublabel="Yes, I would like a free debit card"
                              value={true}
                            />
                          </div>

                          <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              props.setRequestCard(false);
                            }}
                          >
                            <SmallRadioOption
                              image={''}
                              changed={props.setMyOption}
                              id="2"
                              isSelected={props.option === false}
                              label=""
                              sublabel="No, I wouldn’t like a free debit card"
                              value={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {props.option === true ? (
                <div className="YesGG">
                  <p>Enter your card creation and delivery details</p>

                  <div
                    style={{
                      display: 'flex',
                      gap: '5px',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <div style={{ width: '50%' }}>
                        <Inputfloat
                          label="Other Name"
                          type="text"
                          name="otherName"
                          value={values.otherName}
                          disabled={false}
                          placeholder="Daniel"
                          onChange={handleChange}
                          invalid={
                            errors.otherName && touched.otherName && 'true'
                          }
                          error={
                            errors.otherName && touched.otherName
                              ? errors.otherName
                              : ''
                          }
                        ></Inputfloat>
                      </div>

                      <div
                        style={{
                          zIndex: '1000',
                          width: '50%',
                        }}
                      >
                        <ModernDatepicker
                          date={props.data.dob?.value}
                          className="GGdob"
                          format={'YYYY-MM-DD'}
                          showBorder
                          onChange={date =>
                            props.setData({
                              ...values,
                              dob: { ...values.dob, value: date },
                            })
                          }
                          placeholder={'Date of Birth'}
                          primaryColor={'#67A948'}
                        />
                      </div>
                    </div>

                    <div style={{ display: 'flex', gap: '10px', zIndex: '1' }}>
                      <div style={{ width: '50%' }}>
                        <Inputfloat
                          label="State of residence"
                          type="text"
                          name="state"
                          value={values.state}
                          disabled={false}
                          placeholder="State of Residence"
                          onChange={handleChange}
                          invalid={errors.state && touched.state && 'true'}
                          error={
                            errors.state && touched.state ? errors.state : ''
                          }
                        ></Inputfloat>
                      </div>
                      <div style={{ width: '50%' }}>
                        <Inputfloat
                          label="City"
                          type="text"
                          name="city"
                          value={values.city}
                          disabled={false}
                          placeholder="City"
                          onChange={handleChange}
                          invalid={errors.city && touched.city && 'true'}
                          error={errors.city && touched.city ? errors.city : ''}
                        ></Inputfloat>
                      </div>
                    </div>

                    <Inputfloat
                      label="Address"
                      type="text"
                      name="address"
                      value={
                        props.checkSameAddress
                          ? values.residentialaddress
                          : myaddress
                      }
                      disabled={props.checkSameAddress ? true : false}
                      placeholder="Address"
                      // onChange={handleChange}
                      onChange={e => {
                        setMyAddress(
                          props.checkSameAddress
                            ? values.residentialaddress
                            : e.target.value,
                        );
                      }}
                      invalid={errors.address && touched.address && 'true'}
                      error={
                        errors.address && touched.address ? errors.address : ''
                      }
                    ></Inputfloat>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                      marginTop: '10px',
                    }}
                  >
                    {/* checkSameAddress={checkSameAddress}
                    setcheckSameAddress={setcheckSameAddress}
                    handleAddCheckboxChange={handleAddCheckboxChange} */}
                    <div style={{}}>
                      <ImCheckboxChecked
                        style={{
                          background: 'white',
                          color: props.checkSameAddress ? '#518C36' : 'white',
                          cursor: 'pointer',
                          border: '1px solid #518C36',
                          width: '18px',
                          height: '18px',
                        }}
                        onClick={() => props.handleAddCheckboxChange()}
                      />
                    </div>
                    <p style={{ color: '#67A948' }}>
                      Use same address as residential address
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {props.authErr && <AlertError body={props.authErr} />}
              <div
                className="flex flex-col gap-[10px]  w-full"
                style={{ marginTop: '-20px' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  <div style={{}}>
                    <ImCheckboxChecked
                      style={{
                        background: 'white',
                        color: props.isChecked ? '#518C36' : 'white',
                        cursor: 'pointer',
                        border: '1px solid #518C36',
                        width: '18px',
                        height: '18px',
                      }}
                      onClick={() => props.handleCheckboxChange()}
                    />
                  </div>
                  <p style={{ color: '#2B5219' }}>
                    I agree to Vesti’s{' '}
                    <span
                      style={{ color: '#67A948', cursor: 'pointer' }}
                      onClick={() => {
                        window.open(' https://wevesti.com/tos', '_blank');
                      }}
                    >
                      Terms & Conditions.
                    </span>
                  </p>
                </div>
                <div className="mb-2"></div>
                <Platformbutton
                  name="Create Account"
                  type="submit"
                  disabled={
                    !values.fullName ||
                    !values.email ||
                    !values.password ||
                    !values.phoneNumber ||
                    !values.countryCode ||
                    props.option === null ||
                    // (props.option && !values.dob) ||
                    (props.option && !values.city) ||
                    // (props.option === 'Yes' &&
                    //   !values.values.stateOfResidence) ||
                    !props.isChecked ||
                    // (props.option === 'Yes' && !values.otherName) ||
                    (props.option &&
                      !values.address &&
                      !values.residentialaddress) ||
                    props.loading ||
                    !values.residentialaddress ||
                    values.password !== values.confirmPassword
                      ? true
                      : false
                  }
                />
                <div style={{ margin: '0px auto' }}>
                  <Platformbutton
                    type="link"
                    text="Already have an account?"
                    title="Signin"
                    click={() => {
                      dispatch({
                        type: AUTH_LOAD,
                        payload: { authErr: '', authMessage: '' },
                      });
                      navigate('/auth');
                    }}
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export const SecReg = props => {
  const [state, setState] = useState({
    fullName: '',
    country: '',
    phoneNumber: '',
    address: '',
  });
  return (
    <div className="lg:w-[500px] flex flex-col flex-start">
      <Titlesubtitle
        title="Create Account"
        subtitle="Join more than 100,000 Users finding new homes abroad, raising
                the money they need to move and making difficult international
                payments with Vesti."
      />

      <form
        className="w-full flex flex-col gap-[50px] lg:w-[450px]"
        onSubmit={e => {
          e.preventDefault();
          props.signUpUserP2V2(state, () => props.cb(3));
        }}
      >
        <div className="w-full flex flex-col gap-[30px]">
          {/* {state.country?.value}
                    {state.phoneNumber} */}
          {props.userdata?.firstName.toLowerCase() === 'user' && (
            <Inputfloat
              label="Full Name"
              type="text"
              name="fullName"
              value={state.fullName}
              disabled={false}
              placeholder="Ajibola Daniels"
              onChange={value => setState({ ...state, fullName: value })}
            >
              <Shortinfo subject="Full Name should be exactly how it is written on your ID" />
            </Inputfloat>
          )}
          <Inputfloat
            label="Billing Address"
            type="text"
            name="address"
            value={state.address}
            disabled={false}
            placeholder="Billing Address"
            onChange={e => setState({ ...state, address: e.target.value })}
          >
            <Shortinfo subject="Format should be e.g 30, Admiralty Road Lekki" />
          </Inputfloat>
        </div>
        {props.authErr && <AlertError body={props.authErr} />}
        <div className="flex flex-col gap-[10px] items-center w-full">
          <Platformbutton
            name="Submit"
            type="submit"
            disabled={
              !state.address ||
              !state.phoneNumber ||
              !state.country ||
              props.loading
                ? true
                : false
            }
          />
          <Platformbutton
            type="link"
            text="I’ll do this later ?  "
            title="Skip this"
            click={() => (props.type ? navigate('/auth') : props.close())}
          />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = ({ common, auth }) => {
  const { countries } = common;
  const { authMessage, authLoad, authErr } = auth;
  return {
    countries,
    authMessage,
    authLoad,
    authErr,
  };
};

const mapDispatchToProps = {
  fetchAllCountries,
  signUpUserV2,
  verifyNewUserAuthCode,
  signUpUserP2V2,
  resendUserMail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Registerv2);
