import React, { useEffect, useState } from 'react';
import cards from 'assets/homeIcon/cards.svg';
import bgcards from 'assets/homeIcon/bgcards.svg';
import flight from 'assets/homeIcon/fight.svg';
import bgflight from 'assets/homeIcon/bgflight.svg';
import newIcon from 'assets/homeIcon/newIcon.svg';
import airtime from 'assets/homeIcon/airtime.svg';
import bgairtime from 'assets/homeIcon/bgairtime.svg';
import billspay from 'assets/homeIcon/billpay.svg';
import bgbillpay from 'assets/homeIcon/bgbillspay.svg';
import migration from 'assets/homeIcon/migration.svg';
import bgmigration from 'assets/homeIcon/bgmigration.svg';
import electricity from 'assets/homeIcon/electricity.svg';
import bgelectricity from 'assets/homeIcon/bgelectricity.svg';
import savings from 'assets/homeIcon/savings.svg';
import bgsavings from 'assets/homeIcon/bgsavings.svg';
import creditscore from 'assets/homeIcon/credit-score.svg';
import bgcreditscore from 'assets/homeIcon/bgcredit-score.svg';
import { AirtimePayment } from 'containers/Admin/BillPayment/Airtime/Airtime';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import { navigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import { ElectPayment } from 'containers/Admin/BillPayment/Electricity/ElectricityMain';
import { handleFees } from 'appRedux/actions/domore';
import { billspayment } from 'assets/assets';

export const FinancialFeatures = props => {
  const [openairtime, setOpenairtime] = useState(false);
  const [openelectricity, setElectricityOpen] = useState(false);

  const userData = useSelector(state => state.auth.userData, _.isEqual);
  const balance = userData?.walletInNGNKobo;
  var dispatch = useDispatch();

  return (
    <div>
      <Simplemodal onClick={() => setOpenairtime(false)} open={openairtime}>
        <AirtimePayment
          onClick={() => setOpenairtime(false)}
          airtimeData={props.airtimeData}
          setAirtimeData={props.setAirtimeData}
          balance={balance}
        />
      </Simplemodal>{' '}
      <Simplemodal
        onClick={() => setElectricityOpen(false)}
        open={openelectricity}
      >
        <ElectPayment
          onClick={() => setElectricityOpen(false)}
          powerData={props.powerData}
          setPowerData={props.setPowerData}
          user={userData}
          refetch={props.refetch}
        />
      </Simplemodal>
      <div className="quickfinancialaction__top">
        {' '}
        <SingleCard
          bg={'#f7fff3'}
          title={'Vesti Cards'}
          click={() => {
            navigate('/cards');
          }}
          border={'#97e373'}
          imgBg={'#dfffd0'}
          icon={cards}
          bgImg={bgcards}
        />
        <SingleCard
          bg={'#E9FBFF'}
          title={'Buy Airtime'}
          click={() => {
            setOpenairtime(true);
          }}
          border={'#D1F6FF'}
          imgBg={'#D1F6FF'}
          icon={airtime}
          bgImg={bgairtime}
        />
        <SingleCard
          bg={'#FEF3F2'}
          title={'Bills Payment'}
          click={() => {
            navigate('/bills-payment');
          }}
          border={'#FEE4E2'}
          imgBg={'#FEE4E2'}
          icon={billspay}
          bgImg={bgbillpay}
        />
        <SingleCard
          bg={'#F0F9FF'}
          title={'Migration Loans'}
          click={() => {
            dispatch(handleFees());
          }}
          border={'#E0F2FE'}
          imgBg={'#E0F2FE'}
          icon={migration}
          bgImg={bgmigration}
        />
        <SingleCard
          bg={'#F3FBF9'}
          title={'Buy Electricity'}
          click={() => {
            setElectricityOpen(true);
          }}
          border={'#E2F9F1'}
          imgBg={'#E2F9F1'}
          icon={electricity}
          bgImg={bgelectricity}
        />
        <SingleCard
          bg={'#F6F8FF'}
          title={'Savings'}
          click={() => {
            navigate('#');
          }}
          border={'#F1F3FF'}
          imgBg={'#DFE3FF'}
          icon={savings}
          bgImg={bgsavings}
        />
        <SingleCard
          bg={'#FEF3F2'}
          title={'Credit History'}
          click={() => {
            navigate('/credithistory');
          }}
          border={'#FEE4E2'}
          imgBg={'#FEE4E2'}
          icon={creditscore}
          bgImg={bgcreditscore}
        />
      </div>
    </div>
  );
};

const SingleCard = props => {
  return (
    <div>
      {' '}
      <div
        className="quickmigrationaction__vesticard"
        style={{
          background: `${props.bg}`,
          border: `0.5px solid ${props.border}`,
        }}
        onClick={props.click}
      >
        <img
          src={props.icon}
          alt=""
          className="quickmigrationaction__vesticard__cards"
          style={{
            background: `${props.imgBg}`,
          }}
        />
        <p>{props.title}</p>
        <img
          src={props.bgImg}
          alt=""
          className="quickmigrationaction__vesticard__bgcards"
        />
      </div>
    </div>
  );
};
