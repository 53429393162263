import React, { useEffect, useState } from 'react';
import '../../../components/bank/mytransactions.scss';
import { Empty } from 'components/common/empty/empty';
import Loader from 'components/Loader';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import Newpagination from 'components/bank/pagination/newpagination';
import { getCurrency, transColor, transSign, transStatus } from 'helpers/utils';
import moment from 'moment';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { fetchBillPaymentTransactions } from 'appRedux/actions/transactions';
import { connect } from 'react-redux';
const BillPaymentTransactionHistory = props => {
  const [page, setPage] = useState(1);
  const [active, setActive] = useState(0);
  const [open, setOpen] = useState(false);
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // console.log(props);

  var handleOpen = value => {
    setActive(value);
    dimensions.width < 1050 && setOpen(true);
  };
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  React.useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);

  useEffect(() => {
    props.fetchBillPaymentTransactions(`/biller/user-history?page=${page}&page_limit=15`);
    // eslint-disable-next-line
  }, [props.balance, page]);

  if (props.transLoading) {
    return (
      <div className="mytransactions">
        <Titlesubtitle
          title="Bills Payment Transactions"
          subtitle="See all bills payment transactions you've carried out on Vesti."
        />
        <div className="mytransactions__centercont">
          <Loader />
        </div>
      </div>
    );
  } else if (props.trans?.data?.length > 0) {
    return (
      <div className="mytransactions">
        <Simplemodal onClick={() => setOpen(false)} open={open}>
          <Transdetail data={props.trans.data[active]} />
        </Simplemodal>
        <Titlesubtitle
          title="Bills Payment Transactions"
          subtitle="See all bills payment transactions you've carried out on Vesti."
        />

        <div className="mytransactions__inner">
          <div className="mytransactions__tablecont">
            <div className="mytransactions__tabbox">
              <table className="mytransactions__table">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Type</th>
                    <th>Currency</th>
                    <th>Amount</th>
                    <th>Charges</th>
                    <th>Reciepient</th>
                    <th>Date & Time</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {(props.trans.data ?? []).map((item, index) => (
                    <Trow
                      key={index}
                      index={index}
                      type={item.billerType}
                      currency={item.currency}
                      date={item.createdAt}
                      amount={item.amount}
                      charges={item.charges}
                      billingNumber={item.billingNumber}
                      status={item.trxStatus}
                      click={handleOpen}
                    />
                  ))}
                </tbody>
              </table>
            </div>

            {dimensions.width > 1200 && (
              <Transdetail data={props.trans.data[active]} />
            )}
          </div>

          <Newpagination
            className="pagination-bar"
            currentPage={page}
            totalCount={props.trans.paginationMeta.totalObjects}
            pageSize={15}
            onPageChange={page => setPage(page)}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="mytransactions">
        <Titlesubtitle
          title="Bills Payment Transactions"
          subtitle="See all bills payment transactions you've carried out on Vesti."
        />
        <div className="mytransactions__centercont">
          <Empty
            title="No Transactions"
            subtitle="You are yet to carry out any transactions, when you do they'll appear here, click the buttons above to carry out transactions."
          />
        </div>
      </div>
    );
  }
};

const Trow = props => {
  // eslint-disable-next-line
  var options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  const newDate = moment(props.date, 'YYYY-MM-DD HH:mm:ss').format(
    'YYYY-MM-DD HH:mm:ss',
  );
  var tr_type = props.type ;
  // console.log(tr_type)
  // console.log((props?.status).toString().toUpperCase())


  return (
    <tr onClick={() => props.click(props.index)}>
      <td>{props.index + 1}</td>
      <td>{tr_type}</td>
      <td>{props.currency}</td>
      <td className={`amount${transColor(tr_type)}`}>
        {transSign(tr_type)}

        {(props.amount / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </td>
      <td>{ props.charges }</td>
      <td>{ props.billingNumber }</td>
      <td>{newDate}</td>
      <td>
        <div className={`status`}>
          <p className={`statusIcon --${transStatus((props?.status).toString().toUpperCase())} `}></p>{' '}
          {transStatus((props.status).toString().toUpperCase())}
        </div>
      </td>
    </tr>
  );
};

const Transdetail = props => {
  var tr_type = props.data?.billerType;
  // console.log(tr_type);
  // var status = 
  // console.log((props.data.trxStatus).toString().toUpperCase())
  return (
    <section className="transdetail">
      <span className={`transdetail__top ${transColor(tr_type)}`}>
        <p>{tr_type}</p>
        <p>
          {' '}
          {getCurrency(props.data.currency)}{' '}
          {(props.data.amount / 100).toLocaleString('en-us')}
        </p>
        <p>
          {moment(props.data.createdAt, 'YYYY-MM-DD HH:mm:ss').format(
            'YYYY-MM-DD HH:mm:ss',
          )}
        </p>
      </span>
      <div className="transdetail__list">
        {(props.data?.receiverId
          ? [
              { title: 'status', value: props.data.trxStatus },
              { title: 'Currency', value: props.data.currency },
              {
                title: 'date',
                value: moment(
                  props.data.createdAt,
                  'YYYY-MM-DD HH:mm:ss',
                ).format('YYYY-MM-DD HH:mm:ss'),
              },
              { title: 'Type', value: props.data.billerType },
              { title: 'Receiver', value: props.data?.receiver?.firstName },
              // {title:'Fee',value:20.00}
            ]
          : [
              { title: 'status', value: (props.data.trxStatus).toString().toUpperCase() },
              { title: 'Currency', value: props.data.currency },
              {
                title: 'date',
                value: moment(
                  props.data.createdAt,
                  'YYYY-MM-DD HH:mm:ss',
                ).format('YYYY-MM-DD HH:mm:ss'),
              },
              { title: 'Type', value: props.data.billerType },
              // {title:'Fee',value:props.data?.charge}
            ]
        ).map((item, index) => (
          <span key={index} className="transdetail__item">
            <p>{item.title}</p>
            <p className={item.title === 'status' ? `status` : ''}>
              {item.title === 'status' && (
                <p className={`statusIcon --${transStatus(item.value)} `}></p>
              )}{' '}
              {item.title === 'status' ? transStatus(item.value) : item.value}
            </p>
          </span>
        ))}
      </div>
    </section>
  );
};

const mapStateToProps = ({ transactions }) => {
  const { billsTransactions: trans, transLoading } = transactions;
  return {
    trans,
    transLoading,
  };
};

export default connect(mapStateToProps, {
  fetchBillPaymentTransactions,
})(BillPaymentTransactionHistory);


//   var fetchTransactionStatus = () => {
//     // eslint-disable-next-line
//     const data = pendingTransactions?.map(item =>
//       api.get(`/biller/status?trxRef=${item.trxRef}`),
//     );
//     console.log(data);
//   };


//   useEffect(() => {
//     fetchTransactionStatus();
//     // eslint-disable-next-line
//   }, [pendingTransactions]);

