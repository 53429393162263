import React from "react"
import { getInitials } from "helpers/utils"
import "./becomeprovider.scss"

export const Pathusers= (props)=>{
   
//console.log("Pathusers",props.data);


//console.log("Pathusers Props",props);


    return (
        <div className="pathusers">
            <div className="pathusers__content">
                {
                    props.data.map((item,index)=>(
                        <Singlepathuser
                            key={index}
                            index={index}
                            id={item.id}
                            name={item.firstName + ' '+ item.lastName}
                            click={props.click}
                        />
                    ))
                }
                <p className="pathusers__number">+100</p>
            </div>
           
            <p className="pathusers__plus">more users</p>
        </div>
    )
}


export const Pathsubscribers= (props)=>{
   
    //console.log("Pathusers",props.data);
    
    
    //console.log("Pathusers Props",props);
    
    
        return (
            <div className="pathusers">
                <div className="pathusers__content">
                    {
                        props.data.map((item,index)=>(
                            <Singlepathuser
                                key={index}
                                index={index}
                                id={item.id}
                                name={item.firstName + ' '+ item.lastName}
                                click={props.click}
                            />
                        ))
                    }
                    <p className="pathusers__number">+5k</p>
                </div>
               
                <p className="pathusers__plus">subscribers</p>
            </div>
        )
    }

    export const GrowthMembers = (props)=>{
   
        //console.log("Pathusers",props.data);
        
        
        //console.log("Pathusers Props",props);
        
        
            return (
                <div className="pathusers">
                    <div className="pathusers__content">
                        {
                            props.data.map((item,index)=>(
                                <Singlepathuser
                                    key={index}
                                    index={index}
                                    id={item.id}
                                    name={item.firstName + ' '+ item.lastName}
                                    click={props.click}
                                />
                            ))
                        }
                        <p className="pathusers__number">+5k </p>
                    </div>
                   
                    <p className="pathusers__plus">  {' '} Members</p>
                </div>
            )
        }
    



export const Pathdays = (props)=> {
    return (
        <div className="pathdays">
            <div className="pathdays__inner">
                <span className="pathdays__left">
                    <p>Number of Days since you started</p>
                    <p>It has been over  30 days since you started this pathway.</p>
                </span>
                <p className="pathdays__right">{props.days}</p>
            </div>
        </div>
    )
}
export const Singlepathuser = (props)=> {
    const __renderBg =()=> {
        switch(props.index){
            case 0:
                return ' --green'
            case 1:
                return ' --orange'
            case 2:
                return ' --purple'
            default:
                return ' --cyan'
        }
    }
    return (
        <p className={`pathusers__single ${__renderBg()}`} onClick={()=>props.click(props.id)}>
            {getInitials(props.name)}
        </p>
    )
}

export const Userusingpath = (props)=> {
    return (
        <div className="userusing">
            <Singlepathuser id={props.id} name={props.data.firstName + ' '+ props.data.lastName}/>
            <div className="userusing__bottom">
                <p className="userusing__name">{props.data.firstName + ' '+ props.data.lastName}</p>
                <span className="userusing__info">
                    <p>{props.data.email}</p> • <p>@{props.data.username}</p>
                </span>
            </div>
           
        </div>
    )
}