import React from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const UsSkeletonLoader = props => {
  return (
    <div>
      {' '}
      <div className="Profilemembership__plan">
        <div className="Profilemembership__top">
          <div className="Profilemembership__top__col">
            {}
            <p className="Profilemembership__top__title">
              <Skeleton width={100} duration={1} />{' '}
              <span>
                <Skeleton width={100} duration={1} />
              </span>
            </p>
          </div>
        </div>

        <div className="Profilemembership__bottom">
          <article>
            <p className="Profilemembership__bottom__title">
              <Skeleton width={250} height={20} duration={1} />
            </p>
            <p className="Profilemembership__bottom__subtitle">
              <Skeleton width={250} height={30} duration={1} />
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default UsSkeletonLoader;
