import React, { useEffect, useState, useRef } from 'react';
import './MiaiIndex.scss';
import MiaiIntroduction from './introduction';
import PostQuestion from './PostQuestion';
import ChatHistory from './chatHistory';
import api from 'appRedux/api';
import Typing from 'react-typing-animation';
import { useDispatch } from 'react-redux';
import FormattedTextComponent from './FormattedText';
import TypewriterComponent from 'typewriter-effect';
import Typewriter from 'typewriter-effect';

const MiaiMain = props => {
  // useEffect(() => {
  //   if (splitLocation[1] === 'no-auth') {
  //     setSignUpForm(true);
  //   } else {
  //     setSignUpForm(false);
  //   }
  // }, []);

  const [inputText, setInputText] = useState('');

  const dispatch = useDispatch();

  const addMessage = (text, isUser = true) => {
    props.setMessages(prevMessages => [...prevMessages, { text, isUser }]);
  };

  const chatContainerRef = useRef(null);
  const [answer, setAnswer] = useState('');
  const [errorMsg, setErrorMsg] = useState('Error');
  const [regenerateCounter, setRegenerateCounter] = useState(0);
  const [regenBtmClicked, setRenBtmClicked] = useState(false);
  const [newChatId, setnewChatId] = useState(false);

  // console.log(props.userData);

  // localStorage.removeItem('userData');

  // const myData = localStorage.getItem('miaiInputText');

  useEffect(() => {
    scrollToBottom();
  }, [props.messages]);

  const handleCompletePostQA = async () => {
    props.setIsloading(true);

    if (props.splitLocation[2] === 'live' && !props.userData?.id) {
      props.setSignUpForm(true);
      localStorage.setItem('miaiInputText', inputText);
    } else {
      props.setSignUpForm(false);
      props.setTyping(true);

      // dispatch(props.chatHistoryData(props.chatId));
      const storedMiaiinput = localStorage.getItem('miaiInputText');

      setErrorMsg('');

      props.setDisplay('history');

      props.setTypewriter(true);
      addMessage(storedMiaiinput ? storedMiaiinput : inputText, true);

      regenBtmClicked
        ? setRegenerateCounter(regenerateCounter + 1)
        : setRegenerateCounter(regenerateCounter);

      const payload = {
        question: storedMiaiinput ? storedMiaiinput : inputText,
        chatId: props.chatId,
        newChat:
          props.chatId === '' || (props.chatId === null && props.messages == [])
            ? true
            : false,
      };

      const url = `/bot/create`;
      setInputText('');
      localStorage.removeItem('miaiInputText');

      try {
        const res = await api.post(url, payload);
        setAnswer(res.data.data.data.answer);
        props.setIsloading(false);
        setErrorMsg('');
        setRenBtmClicked(false);
        setnewChatId(res.data.data.chatId);
        // console.log(res.data.data);
        const response = generateChatbotResponse(inputText);
        addMessage(response, false);

        // setTimeout(() => {
        // if (res.data.data.chatId ) {
        //   setTimeout(() => {
        //   props.setnewchat(false);

        //   dispatch(
        //     props.chatHistoryData(
        //       res.data.data.chatId ? res.data.data.chatId : props.chatId,
        //     ),
        //   );
        //   props.setMessages([]);
        //   props.setTyping(false);
        //   }, 4000);
        // }
        // }, 500);
      } catch (error) {
        // console.log(error);
        props.setIsloading(false);

        setErrorMsg('Oops, Miai ran into an error and we are working on it');
        props.setTyping(false);

        setRenBtmClicked(false);
      }
    }
  };

  const generateChatbotResponse = userInput => {
    const formattedText = answer
      .replace(/^(\d+\.)/gm, (match, content) => `${content}`)

      .replace(/\s*(.*)/gm, (match, content) => `<p>${content}</p>`)

      .replace(/\.(?=\s+-)/gm, '.</p><p style="margin-top: 20px">');
    if (!answer) {
      return null;
    } else {
      return (
        <Typing
          speed={2}
          onFinishedTyping={() => {
            props.setTyping(false);
          }}
        >
          {answer}
        </Typing>
      );
    }
  };

  useEffect(() => {
    if (props.typing === false) {
      dispatch(props.chatHistoryData(newChatId ? newChatId : props.chatId));
      props.setMessages([]);
    }
  }, [props.typing]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };
  return (
    <>
      <div className="Miaimain">
        <div
          style={{
            background: '#9EA1AE',
            height: '0.25px',
            width: '100%',
            margin: '2rem 0px 1rem 0px',
          }}
        ></div>
        <div
          className={` ${
            props.typing ? 'animated-border' : 'nonanimated-border'
          } ${props.isDarkMode ? 'darkmainBorder' : 'lightmainBorder'}`}
        >
          <div ref={chatContainerRef} className="refContainer">
            {(props.display === 'NewChat' && props.chatId === '') ||
            (props.splitLocation[2] === 'live' &&
              props.display === 'NewChat') ||
            (props.splitLocation[2] === 'live' && props.chatId === '') ? (
              <div
                style={{
                  height: '100%',
                  overflowY: 'scroll',
                }}
              >
                <MiaiIntroduction isDarkMode={props.isDarkMode} />
              </div>
            ) : (
              <ChatHistory
                allchatbyId={props.allchatbyId}
                isDarkMode={props.isDarkMode}
                messages={props.messages}
                chatId={props.chatId}
                generateChatbotResponse={generateChatbotResponse}
                showLoader={props.showLoader}
                setTyping={props.setTyping}
                typing={props.typing}
                typewriter={props.typewriter}
                newchat={props.newchat}
                answer={answer}
                setInput={setInputText}
                errorMsg={errorMsg}
                setRenBtmClicked={setRenBtmClicked}
                setRegenerateCounter={setRegenerateCounter}
                regenerateCounter={regenerateCounter}
                dispatch={dispatch}
                chatHistoryData={props.chatHistoryData}
                isLoading={props.isLoading}
              />
            )}
          </div>
        </div>
        <p
          className={`MydisclaimerbtmText ${
            props.isDarkMode ? 'darkModecolor' : 'lightModecolor'
          }`}
          style={{
            fontSize: '13px',
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '10px',
          }}
        >
          Sorry guys, i am under maintenance and should be back online shortly
        </p>
        {/* hold */}
        <div className="myMiaipostContainer">
          <PostQuestion
            inputText={inputText}
            setInput={e => setInputText(e.target.value)}
            handlePost={handleCompletePostQA}
            isDarkMode={props.isDarkMode}
            typing={props.typing}
            setInputText={setInputText}
            userData={props.userData}
          />

          <p
            className={`MydisclaimerbtmText ${
              props.isDarkMode ? 'darkModecolor' : 'lightModecolor'
            }`}
            style={{
              fontSize: '13px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            Miai ™ is in Beta and may be wrong sometimes, kindly verify response
            gotten from Miai ™
          </p>
        </div>
      </div>
    </>
  );
};

export default MiaiMain;
