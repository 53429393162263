import React from 'react';
import Typewriter from 'typewriter-effect';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';

const aboutGroup = () => {
  return (
    <div
      style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingTop: '1rem' }}
    >
      <p style={{}}>
        Move Abroad in 12 Months is a supportive community of individuals who
        share the dream of starting a new chapter in their lives by moving
        abroad. Whether you're seeking new opportunities, a change of scenery,
        or simply an adventure of a lifetime, our group is here to guide and
        motivate you through the process of making your dream of living abroad a
        reality.
      </p>
    </div>
  );
};

export default aboutGroup;
