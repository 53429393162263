import React, { useState, useEffect } from 'react';
import './MiaiIndex.scss';
import postBtn from 'assets/miai/send-right.svg';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import { FaMicrophone } from 'react-icons/fa6';

const PostQuestion = props => {
  const storedMiaiinput = localStorage.getItem('miaiInputText');

  const [islistening, setisListening] = useState(false);

  const handleAsync = () => {};

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const toggleListening = () => {
    if (transcript) {
      resetTranscript();
      props.setInputText('Listening...');
    }
    setisListening(true);
    SpeechRecognition.startListening({ continuous: true });
  };

  const stopListening = () => {
    // props.setInputText(transcript);

    SpeechRecognition.stopListening({ continuous: true });
    setisListening(false);
  };

  const startListening = () => {
    if (browserSupportsSpeechRecognition) {
      toggleListening();

      setTimeout(() => {
        stopListening(); // Stop listening after 10 seconds
      }, 10000);
    } else {
      console.warn('Browser does not support speech recognition.');
    }
  };

  useEffect(() => {
    if (listening && !transcript) {
      props.setInputText('Listening...');
    } else {
      props.setInputText(transcript);
    }
  }, [listening]);

  const handleKeyPress = event => {
    if (
      (event.key === 'Enter' && storedMiaiinput) ||
      (event.key === 'Enter' && props.inputText)
    ) {
      event.preventDefault();
      props.handlePost(); // Call the function to post the question
    }
  };

  return (
    <div className="PostMiaiInput">
      <div className="PostMiaiInput__input">
        <input
          type="text"
          label=""
          placeholder="Enter your prompt here"
          value={storedMiaiinput ? storedMiaiinput : props.inputText}
          onChange={props.setInput}
          onKeyDown={handleKeyPress}
          style={{
            background: 'white',
            width: '100%',
            border: 'none',
            outline: 'none',
          }}
        />

        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <div className="Miaiwave-container">
            <div
              className="Miaicenter-image"
              style={{
                color: `${islistening ? `red` : `green`}`,
                cursor: 'pointer',
              }}
              onClick={() => startListening()}
            >
              <FaMicrophone />{' '}
            </div>
            <div className={`Miaiwave1 ${islistening ? 'Miaiwave' : ''}`}></div>
            <div className={`Miaiwave2 ${islistening ? 'Miaiwave' : ''}`}></div>
            <div className={`Miaiwave3 ${islistening ? 'Miaiwave' : ''}`}></div>
          </div>

          <div
            className="PostMiaiInput__img"
            onClick={() => {
              props.typing ? handleAsync() : props.handlePost();
            }}
            // disabled={props.typing}
            style={{
              cursor: props.typing ? 'not-allowed' : 'pointer',
            }}
          >
            <img src={postBtn} alt="" />
          </div>
        </div>
      </div>

      {/* <div className="wave-container">
        <div className="center-image" onClick={() => navigate('/miai')}>
          <div
            style={{
              background: '#518C36',
              width: '40px',
              height: '40px',
              borderRadius: '50%',
            }}
          >
            <img
              style={{ width: '100%', height: '100%' }}
              src={MiaiAvatar}
              alt=""
            />
          </div>
        </div>

        <div className="wave wave1"></div>
        <div className="wave wave2"></div>
        <div className="wave wave3"></div>
      </div> */}
    </div>
  );
};

export default PostQuestion;
