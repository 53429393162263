import React, { useState } from 'react';
import './textarea.scss';
import './inputfloat.scss';

export const Textarea = props => {
  return (
    <div className="textarea">
      {props.label && <label>{props.label} </label>}
      <textarea
        className="textarea__input"
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        rows={5}
        cols={5}
        placeholder={props.placeholder ? props.placeholder : ''}
      />
      {props.children}
    </div>
  );
};

export const NewTextarea = props => {
  const [value, setValue] = useState('');

  const calculateRows = value => {
    // Split the value into lines and count the number of lines
    const lineCount = value.split('\n').length;
    // Set a minimum of 4 rows
    return Math.max(lineCount, 4);
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <div className="inputfloat">
      <div
        className="inputfloat__box"
        style={{
          position: 'relative',
          // display: "inline-block",
          width: '100%',
        }}
      >
        <textarea
          type={
            props.type
              ? props.type === 'password'
                ? passwordShown
                  ? 'text'
                  : 'password'
                : props.type
              : props.type
          }
          name={props.name}
          placeholder={props.placeholder}
          style={{
            width: '100%',
            // marginBottom: 10,
            border: props.error ? '1px solid red' : '',
            backgroundColor: props.error ? '#FFEBEB' : '#F2F4F7',
            outline: 'none',
            padding: '10px',
            height: '100px',
          }}
          rows={5}
          cols={5}
          value={props.value}
          onChange={props.onChange}
          min={props.min}
          max={props.max}
          disabled={props.disabled}
          invalid={props.error}
          defaultValue={props.defaultValue}
          step={props.step ? props.step : ''}
          //   inputMode={props.inputmode ? props.inputmode:props.type}
          pattern={props.pattern}
          autoComplete={props.autoComplete}
        />
        <span className="inputfloat__labelVtwo">{props.text} </span>

        {props.children}
      </div>
    </div>
  );
};
