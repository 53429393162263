import React from 'react';
import "./TestimonyTop.scss";

function TestimonyTop() {
  return (
    <div className='testimonial'>
        <div className="testimonial__top">
            <span>
                <p>Don’t Take Our Words For It </p>
                <p>Trust Our Users.</p>
            </span>
            <p>
                Founded by two Brothers who self-migrated to the U.S, Olu and Abimbola, an AI engineer and a Immigration attorney, Vesti provides unique guidance and financial services for people.
            </p>
        </div>
    </div>
  )
}

export default TestimonyTop;