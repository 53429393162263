import React from 'react';
import './MiaiIndex.scss';

const FormattedTextComponent = ({ text }) => {
  // console.log(text);
  const formattedText = text
    ?.replace(/^(\d+\.)/gm, (match, content) => `${content}`)
    // .replace(
    //   /(?<= )-\s*(.*)/gm,
    //   (match, content) => `<p><strong>- </strong> ${content}</p>`,
    // )
    .replace(/\s*(.*)/gm, (match, content) => `<p>${content}</p>`)

    .replace(/\.(?=\s+-)/gm, '.</p><p style="margin-top: 20px">'); // Add margin-top
  return (
    <div
      className="FormattedTextComponent"
      dangerouslySetInnerHTML={{ __html: formattedText }}
      // style={{ fontSize: '15px' }}
    />
  );
};

export default FormattedTextComponent;
