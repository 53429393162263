import React, { useState, useEffect } from 'react';
import './domore.scss';
import { Smalltitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Rewards, Loan, Airtime, Savings } from 'assets/domore/index';
import larrow from 'assets/larrow.svg';
import rarrow from 'assets/rarrow.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'containers/Admin/allcards/slider.css';
import { navigate } from '@reach/router';
import { AirtimePayment } from 'containers/Admin/BillPayment/Airtime/Airtime';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import { useDispatch } from 'react-redux';
import { handleFees } from 'appRedux/actions/domore';
import { BsArrowRight } from 'react-icons/bs';
import MiaiAvatar from 'assets/miai/Miaiellipse.svg';
import api from 'appRedux/api';
import { openNotificationWithIconErr } from 'appRedux/actions/Common';
import { openUpdateBox } from 'appRedux/actions/update';
import NewSavings from 'containers/Admin/Savings/NewSavings';
import { Modal } from 'antd';
import { objectValuesStringify } from 'helpers/utils';

const PrevButton = ({ onClick }) => (
  <button
    className="clickPrev"
    onClick={onClick}
    style={{ position: 'absolute', top: '40%', zIndex: '5', left: '-80px' }}
  >
    <img src={larrow} alt="" />
  </button>
);

const NextButton = ({ onClick }) => (
  <button
    className="clickRight"
    onClick={onClick}
    style={{ position: 'absolute', top: '40%', zIndex: '5', right: '-80px' }}
  >
    <img src={rarrow} alt="" />
  </button>
);

const settings = {
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,

  responsive: [
    {
      breakpoint: 430,
      settings: {
        slidesToShow: 1.025,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1.45,
      },
    },

    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1.55,
      },
    },

    {
      breakpoint: 830,
      settings: {
        slidesToShow: 1.85,
      },
    },

    {
      breakpoint: 980,
      settings: {
        slidesToShow: 2.35,
      },
    },

    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1.85,
      },
    },

    {
      breakpoint: 1290,
      settings: {
        slidesToShow: 2.25,
      },
    },

    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2.85,
      },
    },

    {
      breakpoint: 3000,
      settings: {
        slidesToShow: 3,
        // className: 'center',
        // centerMode: true,

        slidesToScroll: 1,
      },
    },
  ],
};

const DomoreCard = props => {
  return (
    <div
      className="bankDomore__card"
      style={{
        background: props.background,
        border: `1px solid ${props.border}`,
        position: 'relative',
        marginTop: '30px',
      }}
    >
      <p
        style={{
          color: 'white',
          fontWeight: '500',
          fontSize: '12px',
          position: 'absolute',
          top: '10px',
          right: '10px',
        }}
      >
        {props.comingSoon}
      </p>
      <div
        style={{
          maxWidth: '70%',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <p
          style={{
            color: 'white',
            fontWeight: '600',
            fontSize: '18px',
          }}
        >
          {props.title}
        </p>
        <p
          style={{
            fontWeight: '300',
            color: 'white',
            fontSize: '14px',
            zIndex: '10',
          }}
        >
          {props.subtitle}
        </p>
      </div>

      <p
        style={{
          fontWeight: '300',
          color: 'white',
          fontSize: '14px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
        onClick={e => {
          e.stopPropagation();
          props.click();
        }}
      >
        {props.buttontext} <BsArrowRight />
      </p>

      <img
        className="bankDomore__img"
        // {`bankDomore__img ${
        //   props.title === 'Miai (Pronounced Myar)' ? 'bankDomore__miai' : ''
        // }`}
        src={props.img}
        alt=""
      />
    </div>
  );
};

const Domore = props => {
  const [openairtime, setOpenairtime] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [savingsPlans, setSavingsPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showNewSavings, setShowNewSavings] = useState(false);

  const userData = useSelector(state => state.auth.userData, _.isEqual);
  const balance = userData?.walletInNGNKobo;
  var dispatch = useDispatch();

  const storedCurrency = localStorage.getItem('currency');

  const [rewardPrice, setRewardPrice] = useState(4500);
  const [currSymbol, setCurrSymbol] = useState('N');

  const handleShowSavings = () => {
    setShowNewSavings(true);
    showModal();
  };

  var openUpdateModal = () => {
    dispatch(openUpdateBox());
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setShowNewSavings(false);
    getSavings();
  };

  const getSavings = async () => {
    const url = '/safe-lock/list';
    try {
      const res = await api.get(url);
      // console.log(res.data);
      const { data } = res.data;
      setSavingsPlans(data);
      setLoading(false);
    } catch (error) {
      if (error.data?.errors) {
        openNotificationWithIconErr(
          objectValuesStringify(error.data?.errors),
          'Savings',
          'error',
        );
      } else {
        //console.log('err', error.data.message);
        openNotificationWithIconErr(error.data?.message, 'Savings', 'error');
      }
      //console.log({ ...error });
      setLoading(false);
    }
  };

  useEffect(() => {
    getSavings();
  }, []);

  const AccessList = [
    'olu@wevesti.com',
    'ooaanuoluwapo@gmail.com',
    'gbengaoludele@yahoo.com',
    'emenikeaustine36@gmail.com',
    'adefisolabamisile@gmail.com',
    'folarinottunoluwaseyi@gmail.com',
    'Akanbimaryam@gmail.com',
    'deborah@wevesti.com',
    'yemi@wevesti.com',
    'iariyoagbaje@gmail.com',
    'balogunopeyemi67@gmail.com',
    'adegbokanpaul@gmail.com',
    'Johntommokaa@gmail.com',
    'edak.peace@gmail.com',
    'stephen@wevesti.com',
    'adewale@wevesti.com',
    'oea.odunlami@gmail.com',
    'msmatildacruz@gmail.com',
    'taiwoabigailayanfe@gmail.com',
    'ebenezer@wevesti.com',
    'airbiniza94@gmail.com',
    'popoolafaith177@gmail.com',
    'nickyawat@gmail.com',
    'martins@wevesti.com',
    'Blessingsbabatope@gmail.com',
    'comfortaladesuyi@gmail.com',
    'waheedolalekan23@gmail.com',
    'Ogolostella152@gmail.com',
    'nickyawat@yahoo.com',
    'walexdok@yahoo.com',
    'nwosablessing@gmail.com',
    'obasantolu@gmail.com',
    'oea.odunlami@gmail.com',
    'iariyoagbaje@gmail.com',
    'acheamponghilda77@gmail.com',
    'deborahaladekoye@gmail.com',
    'tolulopev205@gmail.com',
    'nkechiv85@gmail.com',
    'aisosaanike243@gmail.com',
    'samuelariyo608@gmail.com',
    'aolatundeselim@gmail.com',
    'mokelzzy@gmail.com',
    'bunmiomotolani6@gmail.com',
    'raphaelnwogha@gmail.com',
    'foyemc@gmail.com',
  ];

  // console.log(props.userdata.email);

  useEffect(() => {
    api
      .get(`/app-config/list?source=vesti`)
      .then(res => {
        if (storedCurrency === 'NGN') {
          setRewardPrice(res.data?.data?.data[0]?.totalNgnReward / 100);
          setCurrSymbol('N');
        } else if (storedCurrency === 'GBP') {
          setRewardPrice(res.data?.data?.data[0]?.totalGbpReward / 100);
          setCurrSymbol('£');
        } else if (storedCurrency === 'GHS') {
          setRewardPrice(res.data?.data?.data[0]?.totalGhsReward / 100);
          setCurrSymbol('GH¢');
        } else if (storedCurrency === 'USD') {
          setRewardPrice(res.data?.data?.data[0]?.totalUsdReward / 100);
          setCurrSymbol('$');
        } else if (storedCurrency === 'ZMW') {
          setRewardPrice(res.data?.data?.data[0]?.totalZmwReward / 100);
          setCurrSymbol('ZMW');
        }
      })
      .catch(err => err);
  }, [storedCurrency]);

  const cardNavigation = () => {
    navigate('/loansummary');
  };

  return (
    <>
      <Simplemodal onClick={() => setOpenairtime(false)} open={openairtime}>
        <AirtimePayment
          onClick={() => setOpenairtime(false)}
          airtimeData={props.airtimeData}
          setAirtimeData={props.setAirtimeData}
          balance={balance}
        />
      </Simplemodal>{' '}
      <div className="bankDomore">
        <Smalltitle title="Do more with Vesti" />
        <div className="bankDomore__card-slider ">
          <Slider {...settings}>
            <div>
              <DomoreCard
                title="Savings"
                background="#2A3147"
                border="#303D94"
                img={Savings}
                subtitle="Save with vesti and earn up to 12%-15% on savings T&C’s apply"
                buttontext={
                  savingsPlans.length > 0 ? 'See Savings plans' : 'Start saving'
                }
                comingSoon="New"
                click={
                  savingsPlans.length > 0
                    ? () => navigate('/savings')
                    : () => {
                        if (
                          props.userdata?.verifiedKyc === true ||
                          props.userdata?.verifiedKyc === 'APPROVED'
                        ) {
                          handleShowSavings();
                        } else {
                          openUpdateModal();
                        }
                      }
                }
              />
            </div>

            <div>
              <DomoreCard
                title="Loans to move abroad"
                background="#BB8211"
                border="#BB8211"
                img={Loan}
                subtitle="We know moving abroad costs money, apply for a low interest loan to make it easy"
                buttontext="Apply For Loan"
                comingSoon=""
                click={() => {
                  dispatch(handleFees());
                }}
              />
            </div>
            <div>
              <DomoreCard
                title="Buy Airtime"
                background="#2C3684"
                border="#4D58B2"
                img={Airtime}
                subtitle="Easily recharge your mobile phone without hassle."
                buttontext="Buy Airtime"
                comingSoon=""
                click={() => {
                  setOpenairtime(true);
                }}
              />
            </div>
            <div>
              <DomoreCard
                title={`${currSymbol}${rewardPrice}`}
                background="#461A64"
                border="#BB8211"
                img={Rewards}
                subtitle={`Get up to ${currSymbol}${rewardPrice} when you refer 5 friends to join Vesti.`}
                buttontext="Start Inviting"
                comingSoon=""
                click={() => {
                  navigate('/myprofile?tab=referral');
                }}
              />
            </div>
            {/* </div> */}
          </Slider>
        </div>
        <Modal
          cancelButtonProps={{ style: { display: 'none' } }}
          open={modalVisible}
          onCancel={closeModal}
          destroyOnClose
          footer=""
          className="new-modal"
          centered={true}
          okButtonProps={{ style: { display: 'none' } }}
          styles={{
            mask: {
              background: 'rgba(103, 169, 72, 0.2)',
              backdropFilter: 'blur(4px)',
            },
          }}
        >
          {showNewSavings && (
            <NewSavings close={closeModal} user={props.userdata} />
          )}
        </Modal>
      </div>
    </>
  );
};

export default Domore;
