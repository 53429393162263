import './comment.scss';
import React from 'react';
import { parseDate } from 'helpers/hooks';
import ReactTimeAgo from 'react-time-ago';
import dummyAvatar from 'assets/dummy-avatar.png';
import { IoHeartOutline } from "react-icons/io5";
import { FaHeart } from "react-icons/fa6";
import { MdOutlineOutlinedFlag } from "react-icons/md";


export const Comment = props => {

  // const [isLiked, setIsLiked] = useState(false);
  // const [reactions, setReactions] = useState(props.reactions);

  // useEffect(() => {
  //   setIsLiked(reactions?.includes(props.userId));
  // }, [reactions, props.userId]);

  // const handleLikeClick = () => {
  //   props.reactToCommentPost(props.id).then(updatedReactions => {
  //     setReactions(updatedReactions);
  //   });
  // };

  return (
    <div
      className="single-comment-container"
      style={{ marginLeft: props.marginLeft }}
    >
      <div className="single-comment-inner">
        <img
          src={props.avatar === null ? dummyAvatar : props.avatar}
          alt="Comment Profile"
          style={{width: '10%', height: '10%'}}
        />
        <div className="single-comment-inner right">
          <div className="top" style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
            <p style={{fontSize: '14px', color: '#000000'}}>
              {props.username ? (
                props.username
              ) : (
                <>
                  {props.firstname} {props.lastname}
                </>
              )}{' '}
            </p>
            {props.createdAt ? (
              <ReactTimeAgo
                date={Date.parse(parseDate(props.createdAt, 'yyyy/mm/dd'))}
                locale="en-US"
                style={{fontSize: '12px'}}
              />
            ) : (
              <p></p>
            )}
          </div>
          <div className="comment-content">
            <p style={{fontSize: '14px'}}>{props.description}</p>
            {!props.remove ? (
              <div className="comment-content bottom" style={{ display: 'flex', alignItems: 'center', gap: '10px'
              }}>
                
                <div
                  onClick={() => {
                    props.reactToCommentPost(props.id);
                  }}
                  // onClick={handleLikeClick}
                  style={{display: 'flex', alignItems: 'center', gap: '2px'}}
                >
                  {/* {isLiked ? (
                    <FaHeart style={{ color: 'red', fontSize: '14px' }} />
                  ) : (
                    <IoHeartOutline style={{ fontSize: '14px' }} />
                  )} */}
                  {props.reactions?.includes(props.userId) ? <FaHeart  style={{color:'red', fontSize: '14px'}}/> : <IoHeartOutline style={{fontSize: '14px'}}/>}
                  {' '}
                  <p style={{fontSize: '12px', color: '#000'}}>{props.reactions?.length }</p>
                  {props.reactions?.length > 1 ? <p style={{color: '#000000'}}>Likes</p> : <p style={{fontSize: '12px', color: '#000000'}}>Like</p>}
                  {/* Like(s) */}
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '2px'}}>
                  {/* <i className="fas fa-flag" style={{ color: '#000000' }} /> */}
                  <MdOutlineOutlinedFlag style={{color: '#000000'}} />
                  {' '}
                  <p style={{fontSize: '12px', color: '#000000'}}>Flag This Comment</p>

                  {' '}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {/* <div className="left">
          {props.authUserId === props.userId ||
          props.userData.username === 'olusolavesti' ? (
            <Postlink
              toolTip="Delete Comment"
              deleteComment={props.deleteComment}
              commentId={props.id}
            >
              <i
                className="fas fa-trash-alt"
                style={{
                  color: '#CCCCCC',
                }}
              />
            </Postlink>
          ) : (
            <p></p>
          )}
        </div> */}
      </div>
    </div>
  );
};

export const Postlink = props => {
  return (
    <div
      className="d-flex justify-content-center align-items-center single-vesti-post-link"
      style={{
        height: '40px',
        width: '40px',
        backgroundColor: '#F8F6F5',
        borderRadius: '100%',
      }}
      onClick={() => props.deleteComment(props.commentId)}
    >
      {props.children}
      <span className="tooltiptext">{props.toolTip}</span>
    </div>
  );
};
