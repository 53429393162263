import React from 'react';
import { Success } from 'components/common/success/success';
import { Platformbutton } from 'components/common/button/button';
import newsuccess from 'assets/newsuccess.svg';
import '../visatype.scss';

export const UploadSuccess = props => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '85%',
        gap: '10px',
      }}
      className="uploadSuccessPage"
    >
      <div
        style={{
          width: '70%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={newsuccess}
          alt=""
          style={{ width: '80%', height: '12rem' }}
        />

        <div style={{ marginBottom: '2rem' }}></div>
        <p style={{ color: '#66AA48', fontSize: '18px', fontWeight: '600' }}>
          Submitted for Review
        </p>
        <div style={{ marginBottom: '0.5rem' }}></div>

        {props.paymentProgress == 100 ? (
          <p
            style={{
              color: '#66AA48',
              fontSize: '14px',
              color: '#14290A',
              textAlign: 'center',
            }}
          >
            You have successfully submitted your documents for review. Review
            will take 24-48 hours after which a Vesti team member will reach out
            to you to continue the process. You can reach out to{' '}
            <span
              style={{ color: '#66AA48', fontSize: '14px', fontWeight: '600' }}
            >
              info@wevesti.com
            </span>{' '}
            for further assistance.
          </p>
        ) : (
          <p
            style={{
              color: '#66AA48',
              fontSize: '14px',
              color: '#14290A',
              textAlign: 'center',
            }}
          >
            You have successfully submitted your documents for review. Review
            will only start when you have fully completed payment for this form.
            You can reach out to{' '}
            <span
              style={{ color: '#66AA48', fontSize: '14px', fontWeight: '600' }}
            >
              info@wevesti.com
            </span>{' '}
            for further assistance.
          </p>
        )}

        <div
          style={{
            background: '#66AA48',
            color: 'white',
            padding: '12px',
            textAlign: 'center',
            borderRadius: '8px',
            width: '60%',
            marginTop: '0.5rem',
            cursor: 'pointer',
          }}
          onClick={() => {
            props.setShowSubmmittedForm(true);
          }}
        >
          Click to see uploaded files
        </div>
      </div>
    </div>
  );
};
