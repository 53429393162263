import React, { useEffect, useState } from 'react';
import '../Admin.css';
import './newsavings.scss';
// import Loader from 'components/Loader';
import api from 'appRedux/api';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import {
  getCurrency,
  objectValuesStringify,
  removeCommaAmount,
} from 'helpers/utils';
import { Platformbutton } from 'components/common/button/button';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Success } from 'components/common/success/success';
import errorsvg from '../../../assets/errorsvg.svg';
import wallet from '../../../assets/radios/radio_wallets.svg';
import high from '../../../assets/highyield.svg';
import medium from '../../../assets/mediumsecure.svg';
import fixed from '../../../assets/fixedsavings.svg';
import flexible from '../../../assets/flexiblesavings.svg';
import highshadow from '../../../assets/highyieldshadow.svg';
import mediumshadow from '../../../assets/mediumsecureshadow.svg';
import newcard from '../../../assets/radios/radio_newcards.svg';
import Visa from 'assets/visa.svg';
import Mastercard from 'assets/Mastercardsafelock.svg';
import Verve from 'assets/verve.svg';
import terms from 'assets/t&c.svg';
import {
  IconOption,
  IconSingleValue,
  Singleselect,
} from 'components/common/inputs/singleselect';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Transreview } from 'components/common/transactionreview/review';
import { Form } from 'antd';
import ModernDatepicker from 'react-modern-datepicker';
import { RadioOption } from 'components/common/radiobutton/radiobutton';
import { Amountinputcurrency } from 'components/common/inputs/amountinput';
import { ShortinfoSavings } from 'components/common/shortinfo/shortinfo';
import infob from '../../../assets/info.svg';
import { Littlebalance } from 'components/bank/littlebalance/littlebalance';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import { SavingsTypeCardSmall } from 'components/savings/singlesavings';
import Loader from 'components/Loader';
import { PaystackButton } from 'react-paystack';
// import { AlertInfo } from 'components/common/alertboxes/alertboxes';
import moment from 'moment';
import { Newprompt } from 'components/common/prompt/prompt';
import { MdCancel } from 'react-icons/md';
import { Comptransaction } from 'components/common/completetransaction/comptransaction';
import DividedPinInput from 'components/common/DividedPinInput';
import { fetchTransactionOTP } from 'appRedux/actions/wallets';

const NewSavings = ({ close, user }) => {
  // console.log('User: ', user);
  const [amount, handleAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [transMessage, setTransMessage] = useState('');
  const [planName, setPlanName] = useState('');
  const [planDetails, setPlanDetails] = useState([]);
  const [step, setStep] = useState(null);
  const [accessCode, setAccessCode] = useState('');
  const [reference, setReference] = useState('');
  const [cardOptions, setCardOptions] = useState([]);
  const [cardId, setCardId] = useState('');
  const [interest, setInterest] = useState(null);
  const [pin, setPin] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    currency: '',
    targetAmount: '',
    frequency: '',
    amount: '',
    maturityTime: '',
    startDate: moment().format('YYYY-M-D'),
    endDate: '',
    paymentChannel: '',
    autoTopUp: '',
    planCategoryId: '',
    planId: '',
    planType: '',
    cardId: '',
  });
  const dispatch = useDispatch();

  const allWallet = useSelector(state => state.wallets.wallets, _.isEqual);
  const userData = useSelector(state => state.auth.userData, _.isEqual);
  // const publicKey = 'pk_test_2aa8ac09a6a34eb6c01d1b27b088e9d78bd50426';
  const publicKey = 'pk_live_5ac54092a585eaedb83ec09a1f8896928ffdca87';
  // console.log(userData);

  const defaultWallet = allWallet?.find(item => item.default === true);

  const formatNumberInput = value => {
    // Remove existing commas and convert to string
    const stringValue = value.toString().replace(/,/g, '');

    // Use a regular expression to add commas for thousands separators
    const formattedValue = stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedValue;
  };

  var setInput = e => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'targetAmount' || name === 'amount') {
      value = formatNumberInput(value);
    }

    setFormData({ ...formData, [name]: value });
    // console.log(formData);
  };

  const CalculateInterest = async () => {
    const url = '/safe-lock/yield-calculator';
    const strippedTargetAmount = formData.targetAmount.replace(/,/g, '');
    const data = {
      planCategoryId: formData.planCategoryId,
      targetAmount: parseFloat(strippedTargetAmount) * 100,
      startDate: moment().toISOString(),
      endDate: formData.endDate?.value,
      frequency: formData.frequency,
    };

    setLoading(true);

    try {
      const res = await api.post(url, data);
      // console.log('Interest:', res.data?.data);
      setInterest(res.data?.data);
    } catch (error) {
      console.error('Error calculating interest:', error);
    } finally {
      setLoading(false);
    }
  };

  // console.log('Step:: ', step);

  useEffect(() => {
    if (step === 6) {
      CalculateInterest();
      dispatch(fetchTransactionOTP());
    }
  }, [step]);

  const fetchPlans = async () => {
    const url = '/safe-lock/plans';
    setLoading(true);
    try {
      const res = await api.get(url);
      // console.log('Savings Plans: ', res);
      setPlanDetails(res.data?.data);
      setLoading(false);
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPlans();
    getCards();
  }, []);

  const handleCardClick = (name, value) => {
    // Update the formData state based on the card's information
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (planDetails?.length > 0) {
      setStep(1);
    } else setStep(0);
  }, [planDetails]);

  var handleSelect = (name, value) => {
    setFormData({ ...formData, [name]: value?.value });
    // console.log(formData);
  };
  // console.log('formData:: ', formData);

  const config = {
    reference,
    email: userData?.email,
    amount: 10000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: publicKey,
  };

  const chargeCard = async () => {
    setLoading(true);
    try {
      // Make a request to your server to obtain the access code
      const response = await api.post('/card/initialize-transaction');
      // console.log('Response : ', response);
      setAccessCode(response.data?.data?.access_code);
      setReference(response.data?.data?.reference);
      // getCards();
      setLoading(false);

      // Once access code is obtained, initiate the payment process
    } catch (error) {
      setLoading(false);
      console.error('Error fetching access code:', error);
    }
  };

  const getCards = async () => {
    try {
      const res = await api.get('/card/saved-cards');
      // console.log(res);
      setCardOptions(
        res.data?.data?.map(item => ({
          value: item.id,
          label: `***** ${item.cardLast4}`,
          image:
            item.cardBrand === 'visa ' || item.cardBrand === 'Visa'
              ? Visa
              : item.cardBrand === 'Mastercard' ||
                item.cardBrand === 'mastercard'
              ? Mastercard
              : Verve,
        })),
      );
    } catch (error) {
      console.error('Error fetching saved cards:', error);
    }
  };

  const handlePaystackSuccessAction = async reference => {
    // console.log('Reference : ', reference);
    setLoading(true);
    // Implementation for whatever you want to do with reference and after success call.
    try {
      const res = await api.post('/card/verify-reference', reference);
      // console.log(res);
      setCardId(res.data?.data?.cardId);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching card:', error);
    }
  };

  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    setAccessCode('');
    setReference('');
    // console.log('closed');
  };

  const componentProps = {
    ...config,
    text: 'Proceed to add card',
    onSuccess: reference => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  // console.log(interest);

  var setTransactionPin = value => {
    setPin(value);
  };

  const handleCreateSavingsPlan = async () => {
    const url = '/safe-lock/create';
    const strippedTargetAmount = formData.targetAmount.replace(/,/g, '');
    const data = {
      name: formData.name,
      currency: formData.currency,
      targetAmount: parseFloat(strippedTargetAmount) * 100,
      frequency: formData.frequency,
      amount: interest?.installmentAmount,
      startDate: formData.startDate?.value,
      startDate: moment().format('YYYY-MM-DD'),
      endDate: formData.endDate?.value,
      paymentChannel: formData.paymentChannel,
      planId: formData.planId,
      planCategoryId: formData.planCategoryId,
      autoTopUp: 'default',
      description: `${formData.name} Savings`,
      cardId: cardId ? cardId : formData.cardId.value?.value,
      transactionOtp: pin,
    };

    // console.log('Data: ', data);
    setLoading(true);
    try {
      const res = await api.post(url, data);
      // console.log({ ...res });
      openNotificationWithIcon(res.data?.message, 'Savings', 'success');
      setTransMessage(res.data?.message);
      setStep(7);
      // close();
    } catch (error) {
      if (error?.data?.errors) {
        setTransMessage(error?.data?.errors);
        openNotificationWithIconErr(
          objectValuesStringify(error?.data?.errors),
          'Savings',
          'error',
        );
        setStep(7);
      } else {
        //console.log('err', error?.data?.message);
        setTransMessage(error?.data?.message || error?.message);
        openNotificationWithIconErr(
          error?.data?.message || error?.message,
          'Savings',
          'error',
        );
        setStep(8);
      }
      //console.log({ ...error });
    } finally {
      setLoading(false);
    }
  };

  var Options = [
    {
      label: '₦ (NGN)',
      value: 'NGN',
    },
    {
      label: '₵ (Cedis) Coming Soon...',
      value: 'GHS',
      isDisabled: true,
    },
    {
      label: '$ (USD) Coming Soon...',
      value: 'USD',
      isDisabled: true,
    },
    {
      label: 'ZMW Coming Soon...',
      value: 'ZMW',
      isDisabled: true,
    },
  ];

  var frequencyOptions = [
    {
      label: 'Daily',
      value: 'daily',
    },
    {
      label: 'Weekly',
      value: 'weekly',
    },
    {
      label: 'Monthly',
      value: 'monthly',
    },
  ];

  var maturityOptions = [
    {
      label: 'Three (3) Months',
      value: '3months',
    },
    {
      label: 'Six (6) Months',
      value: '6months',
    },
    {
      label: 'One (1) Year',
      value: '1year',
    },
    {
      label: 'Set your prefered date',
      value: 'Customised Date',
    },
  ];

  var __renderSteps = () => {
    switch (step) {
      case 0:
        return <Terms step={step} setStep={setStep} close={close} />;
      case 1:
        return (
          <Initial
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            setInput={setInput}
            handleSelect={handleSelect}
            handleCardClick={handleCardClick}
            planDetails={planDetails}
            setPlanDetails={setPlanDetails}
            loading={loading}
            setLoading={setLoading}
            close={close}
          />
        );
      case 2:
        return (
          <TypeSelect
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            setInput={setInput}
            handleSelect={handleSelect}
            handleCardClick={handleCardClick}
            planDetails={planDetails}
            setPlanDetails={setPlanDetails}
            setPlanName={setPlanName}
            close={close}
          />
        );
      case 3:
        return (
          <StepOne
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            Options={Options}
            setInput={setInput}
            amount={amount}
            handleSelect={handleSelect}
            handleAmount={handleAmount}
            loading={loading}
            setLoading={setLoading}
            frequencyOptions={frequencyOptions}
            planName={planName}
            close={close}
          />
        );
      case 4:
        return (
          <StepTwo
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            setInput={setInput}
            handleSelect={handleSelect}
            loading={loading}
            setLoading={setLoading}
            maturityOptions={maturityOptions}
          />
        );
      case 5:
        return (
          <StepThree
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            setInput={setInput}
            handleSelect={handleSelect}
            loading={loading}
            setLoading={setLoading}
            defaultWallet={defaultWallet}
            chargeCard={chargeCard}
            cardId={cardId}
            setCardId={setCardId}
            cardOptions={cardOptions}
            accessCode={accessCode}
            reference={reference}
            componentProps={componentProps}
            handlePaystackSuccessAction={handlePaystackSuccessAction}
            handlePaystackCloseAction={handlePaystackCloseAction}
          />
        );
      case 6:
        return (
          <Finalize
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            setInput={setInput}
            handleSelect={handleSelect}
            loading={loading}
            setLoading={setLoading}
            handleCreateSavingsPlan={handleCreateSavingsPlan}
            close={close}
            defaultWallet={defaultWallet}
            CalculateInterest={CalculateInterest}
            setTransactionpin={setTransactionPin}
            interest={interest}
            dispatch={dispatch}
            setInterest={setInterest}
          />
        );
      case 7:
        return (
          <Success
            title="Successfully Initiated"
            subtitle={transMessage}
            onClick={() => {
              close();
            }}
            button="Okay, Thank you"
          />
        );
      case 8:
        return (
          <Success
            image={errorsvg}
            title={`Failed`}
            subtitle={transMessage}
            onClick={() => setStep(5)}
            button="Try Again"
            type="error"
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="create-savings-box">
      {loading ? <Loader /> : __renderSteps()}
    </div>
  );
};

const Terms = props => {
  return (
    <Newprompt img={terms}>
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-[#14290A] text-xl mb-4 text-center">
          Accept Our Terms & Conditions
        </h1>
        <p className=" text-[#14290A] text-center">
          By pressing accept below you agree to Vesti’s SafeLock{' '}
          <a
            className="text-[#67A948] underline"
            href="https://wevesti.com/tos"
            target="_blank"
          >
            Terms and Conditions
          </a>
          . You can find out more about how we use your data in our{' '}
          <a
            className="text-[#67A948] underline"
            href="https://wevesti.com/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </a>
        </p>
        <span className="mt-4"></span>
        <Platformbutton
          type="normal"
          name="Accept and Continue"
          click={() => props.setStep(1)}
        />
      </div>
    </Newprompt>
  );
};

const Initial = props => {
  // console.log('StepOne: ', props);
  return (
    <>
      {props.loading ? (
        <Loader />
      ) : (
        <Form className="create-savings-form w-100">
          <div className="title">
            <p></p>
            <p>New Savings Target</p>
            <p>Select the type of savings target you prefer</p>
          </div>
          <div className="savingType-container">
            <div className="savingTypesmall-inner">
              {props.planDetails?.map((item, index) => {
                return (
                  <div key={index} style={{ width: '100%' }}>
                    <SavingsTypeCardSmall
                      index={index}
                      backgroundColor={
                        item.title === 'High Yield' ? '#F8FCF9' : '#F6F8FF'
                      }
                      borderColor={
                        item.title === 'High Yield' ? '#67A948' : '#303D94'
                      }
                      topImage={item.title === 'High Yield' ? high : medium}
                      type={item.title}
                      subtitle={
                        item.title === 'High Yield'
                          ? 'Provided by our Banking Partners.'
                          : 'Provided by our Investment Partners.'
                      }
                      downImage={
                        item.title === 'High Yield' ? highshadow : mediumshadow
                      }
                      color={
                        item.title === 'High Yield' ? '#67A948' : '#5261BC'
                      }
                      onClick={() => {
                        props.handleCardClick('planId', item.id);
                        props.setStep(2);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Form>
      )}
    </>
  );
};

const TypeSelect = props => {
  const defaultCategory = props.planDetails?.find(
    item => item.id === props.formData.planId,
  );

  // console.log(defaultCategory);

  useEffect(() => {
    if (defaultCategory?.title.includes('High Yield')) {
      props.setPlanName(defaultCategory?.title);
    }
  }, [defaultCategory?.title]);

  return (
    <Form className="create-savings-form w-100">
      <div className="title">
        <p>Step 1 of 5</p>
        <p>{`${defaultCategory.title} Savings`} </p>
        <p>
          {`Select the type of ${defaultCategory.title} Savings you prefer`}{' '}
        </p>
      </div>
      {defaultCategory.categories.map((item, index) => {
        return (
          <div className="radios mb-3" key={index}>
            <RadioOption
              image={item.title === 'Fixed' ? fixed : flexible}
              changed={value =>
                props.setFormData({
                  ...props.formData,
                  planCategoryId: value,
                  planType: item.title,
                })
              }
              id={item.title === 'Fixed' ? '0' : '1'}
              isSelected={props.formData.planCategoryId === item.id}
              label={`${item.title} savings`}
              sublabel={`Earn ${item?.rate}% interest PA`}
              value={item.id}
            />
          </div>
        );
      })}

      <Platformbutton
        name="Create Savings Plan"
        type="normal"
        click={() => props.setStep(3)}
        disabled={!props.formData.planCategoryId ? true : false}
      />
    </Form>
  );
};

const StepOne = props => {
  // console.log('StepOne: ', props);
  return (
    <Form className="create-savings-form w-100">
      <div className="title">
        <p>Step 2 of 5</p>
        <p>Savings Target</p>
      </div>
      <div className="w-100">
        <Inputfloat
          type="text"
          label="Savings Name"
          name="name"
          placeholder="Enter Name Of Savings"
          value={props.formData.name}
          disabled={false}
          onChange={props.setInput}
        />

        <div className="mt-4">
          <Singleselect
            placeholder="Select Currency"
            value={props.formData.currency.value}
            options={props.Options}
            onChange={value => props.handleSelect('currency', value)}
          />
        </div>

        <div className="mt-4">
          <Amountinputcurrency
            type="text"
            currency={getCurrency(props.formData.currency)}
            name="targetAmount"
            label="Target Amount"
            value={props.formData.targetAmount}
            disabled={false}
            placeholder="Enter target amount"
            onChange={props.setInput}
            pattern="[0-9,.]*"
            autoComplete="false"
          />
        </div>
        <div className="mt-2"></div>
        {parseFloat(props.formData.targetAmount.replace(/,/g, '')) >= 1000 ||
        props.formData.targetAmount === '' ? (
          <div className=""></div>
        ) : (
          <div className="border-2 mt-4 border-red-600 flex justify-center items-center w-full bg-red-300 text-red-600 font-medium rounded-md gap-2 text-sm">
            <MdCancel style={{ color: 'red' }} />
            Amount cannot be less than ₦1,000
          </div>
        )}
        {parseFloat(props.formData.targetAmount.replace(/,/g, '')) * 1 >
          100000000 && (
          <div className="border-2 border-red-600 flex justify-center items-center w-full bg-red-200 text-red-600 font-medium rounded-md gap-2 text-base">
            <MdCancel style={{ color: 'red' }} />
            Amount cannot be greater than ₦100,000,000
          </div>
        )}

        <div className="mt-4">
          <Singleselect
            placeholder="Select Savings frequency"
            value={props.formData.frequency.value}
            options={props.frequencyOptions}
            onChange={value => props.handleSelect('frequency', value)}
          />
        </div>

        {/* {props.planName.includes('High Yield') &&
        props.formData.planType.includes('Fixed') ? (
          <div className="newsavings-backcontinue">
            <Backcontinue goBack={() => props.setStep(2)}>
              <Platformbutton
                name="Proceed"
                type="normal"
                click={() => props.setStep(3)}
                disabled={
                  !props.formData.name ||
                  !props.formData.currency ||
                  !props.formData.frequency === '' ||
                  parseFloat(props.formData.targetAmount.replace(/,/g, '')) <
                    5000000 ||
                  parseFloat(props.formData.targetAmount.replace(/,/g, '')) <
                    parseFloat(props.formData.amount.replace(/,/g, '')) ||
                  !props.formData.targetAmount ||
                  !props.formData.amount
                    ? true
                    : false
                }
              />
            </Backcontinue>
          </div>
        ) : ( */}
        <div className="newsavings-backcontinue">
          <Backcontinue goBack={() => props.setStep(2)}>
            <Platformbutton
              name="Proceed"
              type="normal"
              click={() => props.setStep(4)}
              disabled={
                !props.formData.name ||
                !props.formData.currency ||
                props.formData.frequency === '' ||
                parseFloat(props.formData.targetAmount.replace(/,/g, '')) * 1 >
                  100000000 ||
                parseFloat(props.formData.targetAmount.replace(/,/g, '')) <
                  1000 ||
                !props.formData.targetAmount
                  ? true
                  : false
              }
            />
          </Backcontinue>
        </div>
        {/* )} */}
      </div>
    </Form>
  );
};

const StepTwo = props => {
  const calculateEndDate = (startDate, maturityTime) => {
    const startDateObj = new Date();

    switch (maturityTime) {
      case '3months':
        return new Date(startDateObj.setMonth(startDateObj.getMonth() + 3));
      case '6months':
        return new Date(startDateObj.setMonth(startDateObj.getMonth() + 6));
      case '1year':
        return new Date(
          startDateObj.setFullYear(startDateObj.getFullYear() + 1),
        );
      default:
        return null;
    }
  };

  const handleMaturityTimeChange = value => {
    // console.log('Selected Maturity Time:', value);

    // props.handleSelect('maturityTime', value?.value);
    props.handleSelect('maturityTime', value?.value);

    if (value === '3months' || value === '6months' || value === '1year') {
      const calculatedEndDate = calculateEndDate(
        props.formData.startDate.value,
        value,
      );

      // console.log('Calculated End Date:', calculatedEndDate);

      const formattedEndDate = calculatedEndDate
        ? calculatedEndDate?.toISOString().split('T')[0]
        : '';

      // console.log('Calculated End Date:', formattedEndDate);

      props.setFormData({
        ...props.formData,
        endDate: { ...props.formData.endDate, value: formattedEndDate },
      });
    }
  };

  useEffect(() => {
    if (
      props.formData.maturityTime.value?.value === '3months' ||
      props.formData.maturityTime.value?.value === '6months' ||
      props.formData.maturityTime.value?.value === '1year'
    ) {
      handleMaturityTimeChange(props?.formData?.maturityTime?.value?.value);
    }
  }, [props.formData.maturityTime.value?.value]);

  const parseDate = dateString => {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // Note: month - 1 because months are zero-indexed in JavaScript Date
  };

  const parsedStartDate = parseDate(props.formData.startDate);

  const formattedStartDate = moment(parsedStartDate).format('YYYY-MM-DD');

  return (
    <Form className="create-savings-form w-100 gap-4">
      <div className="title">
        <p>Step 3 of 5</p>
        <p>Savings Maturity Date</p>
      </div>
      <div className="w-100 ">
        <p className="">Start date</p>
        <input
          type="text"
          value={formattedStartDate}
          className="dob"
          readOnly
        />
      </div>

      <div className="mt-4">
        {// props.planName.includes('High Yield') &&
        props.formData.planType?.includes('Fixed') ? (
          <Singleselect
            value={props.formData.maturityTime.value}
            placeholder="Select maturity options"
            options={[
              {
                label: 'Six (6) Months',
                value: '6months',
              },
              {
                label: 'One (1) Year',
                value: '1year',
              },
              {
                label: 'Set your preferred date (Not less than 6months)',
                value: 'Customised Date',
              },
            ]}
            onChange={value =>
              props.setFormData({
                ...props.formData,
                maturityTime: { ...props.formData.maturityTime, value: value },
              })
            }
          />
        ) : (
          <Singleselect
            value={props.formData.maturityTime.value}
            placeholder="Select maturity options"
            options={[
              {
                label: 'Three (3) Months',
                value: '3months',
              },
              {
                label: 'Six (6) Months',
                value: '6months',
              },
              {
                label: 'One (1) Year',
                value: '1year',
              },
              {
                label:
                  'Set your preferred date (Not less than 1month (30Days))',
                value: 'Customised Date',
              },
            ]}
            onChange={value =>
              props.setFormData({
                ...props.formData,
                maturityTime: { ...props.formData.maturityTime, value: value },
              })
            }
          />
        )}
      </div>

      {props.formData.maturityTime.value?.value === 'Customised Date' && (
        <div className="w-100 ">
          <p className="">Maturity Date</p>

          {props.formData.planType.includes('Fixed') ? (
            <ModernDatepicker
              date={props.formData.endDate.value}
              className="dob"
              format={'YYYY-MM-DD'}
              minDate={
                parsedStartDate
                  ? new Date(
                      parsedStartDate?.getFullYear(),
                      parsedStartDate?.getMonth() + 6,
                      parsedStartDate?.getDate(),
                    )
                  : new Date()
              } // Set minDate 6 months after start date
              showBorder
              onChange={date => {
                props.setFormData({
                  ...props.formData,
                  endDate: { ...props.formData.endDate, value: date },
                });
              }}
              placeholder={'Maturity Date'}
              primaryColor={'#67A948'}
            />
          ) : (
            <ModernDatepicker
              date={props.formData.endDate.value}
              className="dob"
              format={'YYYY-MM-DD'}
              minDate={
                parsedStartDate
                  ? new Date(
                      parsedStartDate?.getFullYear(),
                      parsedStartDate?.getMonth() + 1,
                      parsedStartDate?.getDate(),
                    )
                  : new Date()
              }
              showBorder
              onChange={date => {
                props.setFormData({
                  ...props.formData,
                  endDate: { ...props.formData.endDate, value: date },
                });
              }}
              placeholder={'Maturity Date'}
              primaryColor={'#67A948'}
            />
          )}
        </div>
      )}

      {props.formData.endDate &&
        props.formData.maturityTime.value?.value !== 'Customised Date' && (
          <Inputfloat
            type="text"
            label="Maturity Date"
            name="endDate"
            value={props.formData.endDate.value}
            disabled={true}
          />
        )}

      <div className="step2-backcontinue">
        <Backcontinue goBack={() => props.setStep(3)}>
          <Platformbutton
            name="Proceed"
            type="normal"
            click={() => props.setStep(5)}
            disabled={
              // !props.formData.startDate ||
              !props.formData.endDate ? true : false
            }
          />
        </Backcontinue>
      </div>
    </Form>
  );
};

const StepThree = props => {
  return (
    <>
      <Form className="create-savings-form">
        <div className="title">
          <p>Step 4 of 5</p>
          <p>Savings Deposit Channel</p>
        </div>
        <div className="radios">
          {!props.accessCode && (
            <RadioOption
              image={wallet}
              changed={value =>
                props.setFormData({ ...props.formData, paymentChannel: value })
              }
              id="0"
              isSelected={props.formData.paymentChannel === 'wallet'}
              label="Save with your Vesti Wallet"
              sublabel=""
              value="wallet"
            />
          )}

          {props.formData.paymentChannel === 'wallet' && (
            <div className="d-flex justify-content-start w-100">
              <Littlebalance
                type={(props.defaultWallet?.currency).toLowerCase()}
                title={`${props.defaultWallet?.currency} Balance`}
                currency={getCurrency(props.defaultWallet?.currency)}
                amount={props.defaultWallet?.balance / 100}
              />
            </div>
          )}

          <RadioOption
            image={newcard}
            changed={value =>
              props.setFormData({ ...props.formData, paymentChannel: value })
            }
            id="1"
            isSelected={props.formData.paymentChannel === 'card'}
            label="Save with your Debit Card"
            sublabel=""
            value="card"
          />

          {props.formData?.paymentChannel === 'card' && (
            <ShortinfoSavings image={infob}>
              <p>
                You will have to input your card details to process your
                payment. This process is easy and completely seamless.
              </p>
            </ShortinfoSavings>
          )}

          {props.cardOptions?.length > 0 &&
            props.formData.paymentChannel === 'card' && (
              <div className=" addcardsafelock" style={{ width: '100%' }}>
                {props.formData.cardId && (
                  <Platformbutton
                    type="withiconnobg"
                    name="Add a new Card"
                    classname="fas fa-plus"
                    click={() => props.chargeCard()}
                  />
                )}
                <Singleselect
                  value={props.formData.cardId.value}
                  placeholder="Choose Card"
                  options={props.cardOptions}
                  onChange={value =>
                    props.setFormData({
                      ...props.formData,
                      cardId: {
                        ...props.formData.cardId,
                        value: value,
                      },
                    })
                  }
                  components={{
                    SingleValue: IconSingleValue,
                    Option: IconOption,
                  }}
                />
              </div>
            )}
        </div>

        <div className="step3-backcontinue">
          <Backcontinue
            goBack={() =>
              props.formData.paymentChannel === 'card' && props.accessCode
                ? props.handlePaystackCloseAction()
                : props.setStep(4)
            }
          >
            {!props.accessCode && props.formData?.cardId && (
              <Platformbutton
                name="Continue"
                type="normal"
                click={() => props.setStep(6)}
              />
            )}

            {props.accessCode && props.cardId && (
              <Platformbutton
                name="Continue"
                type="normal"
                click={() => props.setStep(6)}
                disabled={!props.formData.paymentChannel ? true : false}
              />
            )}
            {!props.accessCode && !props.formData?.cardId && (
              <Platformbutton
                name={
                  props.formData.paymentChannel === 'card'
                    ? 'Add a new card'
                    : 'Proceed'
                }
                type="normal"
                click={() =>
                  props.formData.paymentChannel === 'card'
                    ? props.chargeCard()
                    : props.setStep(6)
                }
                disabled={!props.formData.paymentChannel ? true : false}
              />
            )}
            {props.accessCode && !props.cardId && (
              <PaystackButton
                className="paystackbutton"
                {...props.componentProps}
              />
            )}
          </Backcontinue>
        </div>
      </Form>
    </>
  );
};

const Finalize = props => {
  // useEffect(() => {
  //   props.dispatch(fetchTransactionOTP());
  // }, []);

  return (
    <div className="create-savings-form ">
      <div className="title">
        <p>Step 5 of 5</p>
      </div>
      <div className="topup-transreview">
        <Transreview
          data={[
            { title: 'Name', value: props.formData.name },
            {
              title: 'Target Amount',
              value: props.formData.targetAmount,
            },
            {
              title: 'Interest Amount',
              value: `${
                props.loading
                  ? '...loading'
                  : props.interest
                  ? removeCommaAmount(
                      props.interest?.savingsInterest / 100,
                    ).toLocaleString('en-US')
                  : '0'
              }`,
            },
            {
              title: 'Amount',
              value: `${
                props.loading
                  ? '...loading'
                  : props.interest
                  ? removeCommaAmount(
                      props.interest?.installmentAmount / 100,
                    ).toLocaleString('en-US')
                  : '0'
              }`,
            },
            {
              title: 'Currency',
              value: props.formData?.currency,
            },
            {
              title: 'Start Date',
              value: moment().format('YYYY-M-D'),
              // value: props.formData?.startDate?.value,
            },
            {
              title: 'Maturity Date',
              value: props.formData?.endDate?.value,
            },
            {
              title: 'Payment Method',
              value: props.formData.paymentChannel,
            },
          ]}
        />
      </div>
      <div className="mb-2"></div>
      {props.formData?.paymentChannel === 'wallet' ? (
        <>
          {/* <Comptransaction
            fetcher={fetchTransactionOTP}
            setPin={props.setTransactionPin}
            loading={props.loading}
            goBack={() => props.setStep(5)}
            btn="Create Savings Plan"
            onFinish={props.handleCreateSavingsPlan}
            len={4}
            lower={true}
          ></Comptransaction> */}
          <p className="mb-2">Enter Transaction Code sent to your mail</p>
          <div className="pincode-input-container" style={{ width: '100%' }}>
            <DividedPinInput onChange={props.setTransactionpin} />
          </div>
          <Backcontinue goBack={() => props.setStep(5)}>
            <Platformbutton
              name="Create Savings Plan"
              type="normal"
              click={() => props.handleCreateSavingsPlan()}
              disabled={props.loading ? true : false}
            />
          </Backcontinue>
          <p style={{ textAlign: 'center' }}>
            Didn't get the code?{' '}
            <span
              style={{ cursor: 'pointer', color: 'green' }}
              onClick={() => props?.dispatch(props?.fetchTransactionOTP())}
            >
              Resend
            </span>
          </p>
        </>
      ) : (
        <Backcontinue goBack={() => props.setStep(5)}>
          <Platformbutton
            name="Create Savings Plan"
            type="normal"
            click={() => props.handleCreateSavingsPlan()}
            disabled={props.loading ? true : false}
          />
        </Backcontinue>
      )}
    </div>
  );
};

export default NewSavings;
