import React, { useEffect, useState } from 'react';
import { Titlesubtitle } from '../../common/titlesubtitle/titlesubtitle';
import { Littlebalance } from '../littlebalance/littlebalance';
import './ngnwithdrawal.scss';
import {
  Success,
  SuccessCopy,
  Transuccess,
} from '../../common/success/success';
import errorsvg from 'assets/newerror.svg';
import { Comptransaction } from '../../common/completetransaction/comptransaction';
import { Singleselect } from 'components/common/inputs/singleselect';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { RadioOption } from 'components/common/radiobutton/radiobutton';
import Loader from 'components/Loader';
import { useAmount } from 'helpers/hooks';
import { Amountinputcurrency } from 'components/common/inputs/amountinput';
import { AlertError } from 'components/common/alertboxes/alertboxes';
import { Platformbutton } from 'components/common/button/button';
import { Transreview } from 'components/common/transactionreview/review';
import { SUCCESSFUL_TRANSACTION } from 'appRedux/constants';
import { getProfile } from 'appRedux/actions/auth';
import Error from 'assets/newerror.svg';
import dummyAvatar from 'assets/dummy-avatar.png';
import {
  addNigerianBank,
  getNairaBanks,
  getUserNairaBanks,
  verifyUserNairaAccount,
  withdrawNGN,
  withdrawNGNTwo,
  withdrawNGNEmail,
} from 'appRedux/actions/nairatrans';
import { connect, useDispatch } from 'react-redux';
import { Shortinfo } from 'components/common/shortinfo/shortinfo';
import {
  getCurrency,
  removeCommaAmount,
  virtualBankFullName,
} from 'helpers/utils';
import { openReward } from 'appRedux/actions/domore';
import { Smallempty } from 'components/common/empty/empty';
import api from 'appRedux/api';
// eslint-disable-next-line
import { radio_ach, radio_users, radio_mail } from 'assets/assets';
import Inputfloat from 'components/common/inputs/inputfloat';
// import VestiToVesti from '../transfer/vestiToVesti';
import { transferNGNCash } from 'appRedux/actions/nairatrans';
import DividedPinInput from 'components/common/DividedPinInput';
import { Form } from 'antd';
import { openNotificationWithIconErr } from 'appRedux/actions/Common';
import { fetchTransactionOTP } from 'appRedux/actions/wallets';
// import { navigate } from '@reach/router';
const Ngnwithdrawal = props => {
  // console.log('Ngn Props : ', props);
  const { amount, handleAmount } = useAmount(0);
  const [withdrawalCharges, setWithdrawalCharges] = useState(1);
  const [loading, setLoading] = useState(true);

  const [state, setState] = useState({
    currency: 'NGN',
    type: '',
  });
  const [extAccount, setExtaccount] = useState({
    number: '',
    bank: '',
    name: '',
  });
  const [pin, setPin] = useState('');
  const [currBank, setCurr] = useState('');
  const [email, setEmail] = useState('');
  const [selected, setSelected] = useState('');
  // const [step, setStep] = useState(0);
  const [recipient, setRecipient] = useState(null);

  var dispatch = useDispatch();

  const setStep = value => {
    dispatch({ type: SUCCESSFUL_TRANSACTION, payload: value });
  };

  var setTransactionPin = value => {
    setPin(value);
  };

  const startSubmit = () => {
    setState({ submitting: true });
  };

  const stopSubmit = () => {
    setState({ submitting: false });
  };

  const handleTransferCash = () => {
    const { userName, currency } = state;
    setLoading(true);
    props.transferNGNCash({
      startSubmit,
      amount,
      userId: recipient?.id,
      userName,
      currency: 'NGN',
      charges: currency === 'NGN' ? 0 : 0,
      transactionOtp: pin,
      stopSubmit,
    });
    props.refetch();
    props.getProfile();
    // setState({ ...state, amount: 0 });
  };

  var handleSelect = value => {
    setSelected(value);
    // setCurr(value)
    setCurr(props.userNgnBanks[value.key]);
  };

  const fetchRecipient = () => {
    setLoading(true);
    api
      .get(`/customer/search?username=${state?.userName}`)
      .then(res => {
        setLoading(false);
        setRecipient(res?.data?.data[0]);
        // console.log("Recipients", res);
      })
      .catch(
        err =>
          openNotificationWithIconErr(
            `Verification`,
            `${err?.message}`,
            'error',
          ),
        setLoading(false),
      );
  };

  useEffect(() => {
    api
      .get(`/app-config/list?source=vesti`)
      .then(res => {
        // console.log(res.data?.data?.data[0]?.ngnWithdrawalCharges);

        setWithdrawalCharges(
          res.data?.data?.data[0]?.ngnWithdrawalCharges / 100,
        );
        //  setCarFundCharges(res.data.data.cardFundingCharges / 10);
        setLoading(false);
      })
      .catch(err => err);
  }, []);

  var transData = () => {
    switch (state.type) {
      case 'BANK':
        return {
          beneficiary: true,
          bankId: currBank.id,
          amount: removeCommaAmount(amount) * 100,
          currency: 'NGN',
          transactionOtp: pin,
        };
      case 'OTHER':
        return {
          type: 'external',
          beneficiary: false,
          receipientFirstName: extAccount.name.includes('(')
            ? virtualBankFullName(extAccount.name).split(' ')[0]
            : extAccount.name.split(' ')[0],
          receipientLastName: extAccount.name.includes('(')
            ? virtualBankFullName(extAccount.name).split(' ')[1]
            : extAccount.name.split(' ')[2]
            ? extAccount.name.split(' ')[2]
            : extAccount.name.split(' ')[1],
          accountNumber: extAccount.number,
          amount: removeCommaAmount(amount) * 100,
          currency: 'NGN',
          bankCode: extAccount.bank.value,
          transactionOtp: pin,
          description: 'withdrawal',
        };
      case 'EMAIL':
        return {
          email: email,
          amount: removeCommaAmount(amount) * 100,
          currency: 'NGN',
          transactionOtp: pin,
        };
      default:
        return {};
    }
  };

  const Finalize = e => {
    var data = transData();
    props.withdrawNGNTwo(
      data,
      value => {
        props.refetch();
        setStep(value);
      },
      setStep,
    );
  };

  // withdraw to email
  const Finish = e => {
    var data = transData();
    props.withdrawNGNEmail(
      data,
      value => {
        props.refetch();
        setStep(value);
      },
      setStep,
    );
  };

  useEffect(() => {
    props.getUserNairaBanks();
    props.getNairaBanks();
    // eslint-disable-next-line
  }, []);

  const _render = () => {
    switch (props.step) {
      case 0:
        return (
          <Enteramount
            amount={amount}
            handleAmount={handleAmount}
            setStep={setStep}
            user={props.user}
            balance={props.balance}
            closeModal={props.closeModal}
          />
        );
      case 1:
        return (
          <Choosetype
            state={state}
            setState={setState}
            setStep={setStep}
            user={props.user}
            balance={props.balance}
            closeModal={props.closeModal}
          />
        );
      case 2:
        return (
          <Sendbeneficiary
            balance={props.balance}
            setStep={setStep}
            step={props.step}
            amount={props.amount}
            userNgnBanks={props.userNgnBanks}
            state={state}
            Finalize={Finalize}
            selected={selected}
            handleSelect={handleSelect}
            user={props.user}
            closeModal={props.closeModal}
            addBen={props.openAddbank}
            loading={props.loading}
          />
        );
      case 3:
        return (
          <SendToAccount
            setStep={setStep}
            extAccount={extAccount}
            setExtaccount={setExtaccount}
            verifyAccount={props.verifyAccount}
            verifyUserNairaAccount={props.verifyUserNairaAccount}
            loading={props.loading}
            ngnBanks={props.ngnBanks}
          />
        );
      case 4:
        return (
          <div className="ngnwithdrawal__review">
            <Transreview
              data={[
                { title: 'Transaction Type', value: 'Withdrawal' },
                {
                  title: 'Amount',
                  value: `₦${removeCommaAmount(amount).toLocaleString(
                    'en-US',
                  )}`,
                },
                {
                  title: 'Fee',
                  value: loading ? <Loader /> : ` ₦ ${withdrawalCharges}`,
                },
                {
                  title: 'Recipient Name',
                  value: currBank ? currBank.accountName : extAccount.name,
                },
                {
                  title: 'Recipient Account',
                  value: currBank
                    ? currBank.accountNumberDisplay
                    : extAccount.number,
                },
                {
                  title: 'Recipient Bank',
                  value: currBank ? currBank.bankName : extAccount.bank.label,
                },
              ]}
            />
            <div className="mb-2"></div>
            <Comptransaction
              fetcher={fetchTransactionOTP}
              setPin={setTransactionPin}
              loading={props.transLoading}
              // loading={props.loading}
              goBack={() => setStep(state.type === 'OTHER' ? 3 : 2)}
              btn={`Initiate Withdrawal`}
              onFinish={Finalize}
              len={4}
              lower={true}
            >
              <Backcontinue
                goBack={() => setStep(state.type === 'OTHER' ? 3 : 2)}
              >
                <Platformbutton
                  name="Inititate Withdrawal"
                  type="normal"
                  click={() => Finalize()}
                  // disabled={props.transLoading ? true : false}
                  disabled={props.loading ? true : false}
                />
              </Backcontinue>
            </Comptransaction>
            <p style={{ textAlign: 'center' }}>
              Didn't get the code?{' '}
              <span
                style={{ cursor: 'pointer', color: 'green' }}
                onClick={() => dispatch(fetchTransactionOTP())}
              >
                Resend
              </span>
            </p>
          </div>
        );

      case 5:
        return (
          <Success
            title="Withdrawal Successful"
            subtitle={props.transMessage}
            button="Okay, Thank You"
            onClick={() => {
              props.refetch();
              props.onClick();
              dispatch(openReward());
            }}
            // secondBtntext={ 'Add as Beneficairy'}
          />
        );
      case 6:
        return (
          <Success
            title="Withdrawal Successful"
            subtitle={props.transMessage}
            button="Okay, Thank You"
            onClick={() => {
              props.refetch();
              props.onClick();
              // props.closeModal();
              dispatch(openReward());
            }}
            secondBtntext={'Add as Beneficairy'}
            secondBtn={() =>
              props.addNigerianBank(
                {
                  firstName: extAccount.name.includes('(')
                    ? virtualBankFullName(extAccount.name).split(' ')[0]
                    : extAccount.name.split(' ')[0],
                  lastName: extAccount.name.includes('(')
                    ? virtualBankFullName(extAccount.name).split(' ')[1]
                    : extAccount.name.split(' ')[2]
                    ? extAccount.name.split(' ')[2]
                    : extAccount.name.split(' ')[1],
                  accountNumber: extAccount.number,
                  currency: 'NGN',
                  bankCode: extAccount.bank?.value,
                  bankName: extAccount.bank?.label,
                },
                props.closeModal,
              )
            }
          />
        );
      case 7:
        return (
          <Success
            image={errorsvg}
            type="error"
            title="Withdrawal Failed"
            subtitle={props.transMessage}
            button="Try Again"
            onClick={() => {
              props.refetch();
              // dispatch(openReward())
              setStep(1);
            }}
          />
        );

      case 8:
        return (
          <Success
            image={errorsvg}
            type="error"
            title="Transfer Failed"
            subtitle={props.transMessage}
            button="Try Again"
            onClick={() => {
              props.refetch();
              setStep(1);
            }}
          />
        );

      case 9:
        return (
          <SendToMail
            step={props.step}
            setStep={setStep}
            email={email}
            setEmail={setEmail}
            amount={props.amount}
            balance={props.balance}
          />
        );
      case 10:
        return (
          <div className="ngnwithdrawal__review">
            <Transreview
              data={[
                { title: 'Transaction Type', value: 'Withdrawal' },
                {
                  title: 'Amount',
                  value: `₦${removeCommaAmount(amount).toLocaleString(
                    'en-US',
                  )}`,
                },
                { title: 'Fee', value: ` ₦ 100` },
                { title: 'Recipient', value: email },
              ]}
            />
            {/* {console.log('Loading state', props.transLoading)} */}
            <div className="mb-2"></div>
            <Comptransaction
              fetcher={fetchTransactionOTP}
              setPin={setTransactionPin}
              loading={props.transLoading}
              goBack={() => setStep(8)}
              btn={`Initiate Withdrawal`}
              onFinish={Finish}
              len={4}
              lower={true}
            >
              <Backcontinue goBack={() => setStep(8)}>
                <Platformbutton
                  name="Inititate Withdrawal"
                  type="normal"
                  click={() => Finish()}
                  // disabled={props.transLoading ? true : false}
                  disabled={props.transLoading}
                />
              </Backcontinue>
            </Comptransaction>
            <p style={{ textAlign: 'center' }}>
              Didn't get the code?{' '}
              <span
                style={{ cursor: 'pointer', color: 'green' }}
                onClick={() => dispatch(fetchTransactionOTP())}
              >
                Resend
              </span>
            </p>
          </div>
        );

      case 11:
        return (
          <SuccessCopy
            title={props.transMessage}
            subtitle={`You have successfully transferred the sum of NGN${amount} to ${email}, click to copy the code and send to the recipient to claim .`}
            button="Okay, Thank You"
            link={props.transSucData}
            sharenum
            onClick={() => {
              props.refetch();
              // props.closeModal();
              props.onClick();
              dispatch(openReward());
            }}
          />
        );

      case 12:
        return (
          <Recipient
            balance={props.balance}
            setStep={setStep}
            step={props.step}
            state={state}
            setState={setState}
            amount={amount}
            currency={props.currency}
            closeModal={props.closeModal}
            recipient={recipient}
            setRecipient={setRecipient}
            fetchRecipient={fetchRecipient}
            loading={loading}
            setLoading={setLoading}
          />
        );
      case 13:
        return (
          <Finalise
            balance={props.balance}
            setStep={setStep}
            step={props.step}
            loading={props.transLoading}
            handleTransferCash={handleTransferCash}
            setTransactionpin={setTransactionPin}
            amount={amount}
            currency={props.currency}
            recipient={recipient}
            dispatch={dispatch}
            closeModal={props.closeModal}
          />
        );
      case 14:
        return (
          <Transuccess
            url={
              recipient?.profilePictureURL
                ? recipient?.profilePictureURL
                : dummyAvatar
            }
            title={`Successful Transfer to ${recipient?.firstName}`}
            button="Okay, Thank You!!!"
            subtitle={` Your transfer of ${props.currency.slice(
              0,
              3,
            )} ${amount?.toLocaleString('en-us')}  to ${recipient?.firstName} ${
              recipient?.lastName
            } was successful.`}
            onClick={() => {
              props.onClick();
            }}
          />
        );
      case 15:
        return (
          <Success
            image={Error}
            type="error"
            button="Let Me Try Again"
            title="Transaction Failed"
            subtitle={` Your transfer of ${props.currency.slice(
              0,
              3,
            )} ${amount?.toLocaleString('en-us')} to ${recipient?.firstName} ${
              recipient?.lastName
            } failed, ${props.transMessage}.`}
            onClick={() => setStep(13)}
          />
        );

      default:
        return <></>;
    }
  };

  if (props.loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  } else {
    return _render();
  }
};

const Enteramount = props => {
  // console.log('Naira Amount :: ', props);
  return (
    <section className="ngnwithdrawal">
      <Titlesubtitle
        steps={`Step 1`}
        title="Amount to send"
        subtitle="Enter amount to send (minimum of ₦200)"
      />
      <Littlebalance
        title="Naira Balance"
        currency={'₦'}
        amount={props.balance}
      />
      <div className="ngnwithdrawal__col mb-4">
        <Amountinputcurrency
          type="text"
          currency="₦"
          name="amount"
          label="Amount"
          value={
            props.amount === 'NaN' ? 0 : props.amount.toLocaleString('en-US')
          }
          disabled={false}
          placeholder="Enter amount to deposit"
          onChange={props.handleAmount}
          pattern="[0-9,.]*"
        >
          <Shortinfo subject="Amount should be greater than 200" />
        </Amountinputcurrency>
      </div>

      {removeCommaAmount(props.amount) > 0 &&
        (removeCommaAmount(props.amount) > props.balance ||
          removeCommaAmount(props.amount) < 200) && (
          <AlertError
            body={
              removeCommaAmount(props.amount) < 200
                ? `Amount should be greater than 200 Naira.`
                : `You do not have upto ₦${props.amount} in your Vesti Naira wallet, please deposit into your account.`
            }
          />
        )}

      <div className="mb-2"></div>

      <Platformbutton
        name="Continue"
        type="normal"
        disabled={
          removeCommaAmount(props.amount) > props.balance ||
          removeCommaAmount(props.amount) < 200 ||
          props.amount === 'NaN'
            ? true
            : false
        }
        click={() => props.setStep(1)}
      />
    </section>
  );
};

const Choosetype = props => {
  var __renderBtn = () => {
    switch (props.state.type) {
      case 'VESTI':
        return (
          <Platformbutton
            name="Continue"
            type="normal"
            click={() => props.setStep(12)}
            // click={() => props.setStep(14)}
          />
        );
      case 'BANK':
        return (
          <Platformbutton
            name="Continue"
            type="normal"
            click={() => props.setStep(2)}
          />
        );
      case 'OTHER':
        return (
          <Platformbutton
            name="Continue"
            type="normal"
            click={() => props.setStep(3)}
          />
        );
      case 'EMAIL':
        return (
          <Platformbutton
            name="Continue"
            type="normal"
            click={() => props.setStep(9)}
          />
        );
      default:
        return <></>;
    }
  };
  return (
    <section className="ngnwithdrawal">
      <Titlesubtitle
        title="Type of Transfer"
        subtitle="Select type of money transfer"
      />
      <Littlebalance
        title="Naira Balance"
        currency={'₦'}
        amount={props.balance}
      />
      <div className="ngnwithdrawal__col --list">
        <RadioOption
          image={radio_users}
          changed={value => props.setState({ ...props.state, type: value })}
          id="0"
          isSelected={props.state.type === 'VESTI'}
          label="Send to a Vesti user"
          sublabel="Send money to a Vesti user if you know their username"
          value="VESTI"
        />
        <RadioOption
          image={radio_ach}
          changed={value => props.setState({ ...props.state, type: value })}
          id="1"
          isSelected={props.state.type === 'BANK'}
          label="Send to a beneficiary"
          sublabel="Add a beneficiary once and send money to them many times."
          value="BANK"
        />
        <RadioOption
          image={radio_ach}
          changed={value => props.setState({ ...props.state, type: value })}
          id="2"
          isSelected={props.state.type === 'OTHER'}
          label="Send one time payment"
          sublabel="You can send one time payments to any bank in Nigeria."
          value="OTHER"
        />
        {/* <RadioOption
          image={radio_mail}
          changed={value => props.setState({ ...props.state, type: value })}
          id="3"
          isSelected={props.state.type === 'EMAIL'}
          label="Send using mail"
          sublabel="Send money to a non-Vesti user by mail."
          value="EMAIL"
        /> */}
      </div>
      <div className="mb-4"></div>

      <Backcontinue goBack={() => props.setStep(0)}>
        {__renderBtn()}
      </Backcontinue>
    </section>
  );
};

const Sendbeneficiary = props => {
  return (
    <section className="ngnwithdrawal">
      <Titlesubtitle
        steps={`Step 1 of 2`}
        title="Withdrawal"
        subtitle="Withdraw from your Vesti wallet."
      />
      <div className="ngnwithdrawal__col">
        {props.userNgnBanks.length > 0 ? (
          <div className="ngnwithdrawal__smallcol">
            <p>Beneficiaries</p>
            <Singleselect
              value={props.selected}
              options={(props.userNgnBanks ?? []).map((item, index) => ({
                key: index,
                label:
                  item.accountNumberDisplay +
                  ' ' +
                  item.accountName +
                  ' ' +
                  item.bankName,
                value: item.id,
              }))}
              placeholder="Select your beneficiary"
              onChange={props.handleSelect}
            />
            <div className="mb-2"></div>
            <Platformbutton
              type="withiconnobg"
              name="Add Beneficiary"
              classname="fas fa-plus"
              click={() => props.addBen()}
            />
          </div>
        ) : (
          <Smallempty
            name="Add Beneficiary"
            type="normal"
            title="Empty"
            subtitle="You are yet to add any beneficiary account(s), when you do they will all appear here."
            click={() => props.addBen()}
          />
        )}
      </div>

      <div className="mb-2"></div>
      {props.userNgnBanks.length > 0 && (
        <Backcontinue goBack={() => props.setStep(1)}>
          <Platformbutton
            name="Continue"
            type="normal"
            disabled={props.selected === '' ? true : false}
            click={() => props.setStep(4)}
          />
        </Backcontinue>
      )}
    </section>
  );
};

const SendToAccount = props => {
  // eslint-disable-next-line
  const [account, setAccount] = useState();

  return (
    <section className="ngnwithdrawal">
      <Titlesubtitle
        steps={`Step 2 of 3`}
        title="Enter Account"
        subtitle="Withdraw from your Vesti wallet."
      />
      <div className="ngnwithdrawal__col">
        {props.ngnBanks?.length > 0 ? (
          <div className="ngnwithdrawal__smallcol">
            <p>Select Bank Name</p>
            <Singleselect
              value={props.extAccount.bank}
              options={props.ngnBanks}
              placeholder="Select Bank Name"
              onChange={value =>
                props.setExtaccount({ ...props.extAccount, bank: value })
              }
            />
          </div>
        ) : (
          <Smallempty
            name="Empty"
            type="normal"
            title="Empty"
            subtitle="No Banks to display."
            click={() => props.setStep(1)}
          />
        )}
        <Inputfloat
          type="number"
          label="ACCOUNT NUMBER"
          name="account number"
          value={props.extAccount.number}
          placeholder="e.g 12345678901"
          disabled={false}
          onChange={e =>
            props.setExtaccount({ ...props.extAccount, number: e.target.value })
          }
        />
        {props.extAccount.name ? (
          <Inputfloat
            type="text"
            label="ACCOUNT NAME"
            name="account name"
            value={props.extAccount.name}
            placeholder=""
            disabled={true}
            // onChange={(e)=>props.setExtaccount({...props.extAccount, number:e.target.value})}
          />
        ) : (
          <></>
        )}
      </div>

      <div className="mb-2"></div>

      {props.verifyAccount === false && (
        <AlertError
          body={`We could not verify the ${props.extAccount.bank.label} account with ${props.extAccount.number} account number, cross check and try again.`}
        />
      )}
      {props.ngnBanks?.length > 0 && (
        <Backcontinue goBack={() => props.setStep(1)}>
          <Platformbutton
            name={props.extAccount.name ? 'Continue to PIN' : 'Verify Account'}
            type="normal"
            disabled={
              !props.extAccount.number || !props.extAccount.bank ? true : false
            }
            click={() =>
              props.extAccount.name
                ? props.setStep(4)
                : props.verifyUserNairaAccount(
                    {
                      account: props.extAccount.number,
                      code: props.extAccount.bank.value,
                    },
                    data => {
                      setAccount(data);

                      props.setExtaccount({
                        ...props.extAccount,
                        name: data?.account_name,
                      });
                    },
                  )
            }
          />
        </Backcontinue>
      )}
    </section>
  );
};

const SendToMail = props => {
  // eslint-disable-next-line

  return (
    <section className="ngnwithdrawal">
      <Titlesubtitle
        steps={`Step 2 of 3`}
        title="Email"
        subtitle="Enter the email of the recipient."
      />
      <div className="ngnwithdrawal__col">
        <Inputfloat
          type="email"
          label="Email"
          name="recipient email"
          value={props.email}
          placeholder="johndoe@gmail.com"
          disabled={false}
          onChange={e => props.setEmail(e.target.value)}
        />
      </div>

      <div className="mb-2"></div>

      <Backcontinue goBack={() => props.setStep(1)}>
        <Platformbutton
          name={props.email ? 'Continue to PIN' : 'Continue '}
          type="normal"
          disabled={!props.email ? true : false}
          click={() => (props.email ? props.setStep(10) : (() => {})())}
        />
      </Backcontinue>
    </section>
  );
};

const Recipient = props => {
  // eslint-disable-next-line
  // console.log('Recipient: ', props);
  return (
    <section className="ngnwithdrawal">
      <Titlesubtitle
        steps={`Step 2 of 4`}
        title="Input User Details"
        subtitle="Input details of the user you want to send money to."
      />
      <div className="ngnwithdrawal__col">
        <Inputfloat
          type="text"
          label="USER NAME"
          name="username"
          value={props.state.username}
          placeholder="Enter Recipient vesti username"
          disabled={false}
          onChange={e =>
            props.setState({ ...props.state, userName: e.target.value })
          }
        />

        {props.recipient && (
          <Inputfloat
            type="text"
            label="RECEPIENT NAME"
            name="recipient name"
            value={props.recipient?.firstName + ' ' + props.recipient?.lastName}
            placeholder=""
            disabled={true}
          />
        )}
      </div>

      <div className="mb-2"></div>

      <Backcontinue goBack={() => props.setStep(1)}>
        <Platformbutton
          name={
            props.loading
              ? 'Verifying...'
              : props.recipient
              ? 'Proceed'
              : 'Verify User'
          }
          type="normal"
          disabled={!props.state?.userName ? true : false}
          click={() =>
            props.recipient
              ? props.setStep(13)
              : props.fetchRecipient(props.state?.userName)
          }
        />
      </Backcontinue>
    </section>
  );
};

const Finalise = props => {
  // console.log('Finalize props :', props);
  useEffect(() => {
    props.dispatch(fetchTransactionOTP());
  }, []);

  return (
    <Form onFinish={() => props.handleTransferCash()} style={{ width: '100%' }}>
      <div className="recipient__form">
        <Transreview
          data={[
            { title: 'Transaction Type', value: 'Transfer' },
            {
              title: 'Amount',
              value: `${getCurrency(
                props.currency,
              )}${props.amount?.toLocaleString('en-US')}`,
            },

            { title: 'Currency', value: props.currency },
            {
              title: 'User Fullname',
              value: `${props.recipient?.firstName} ${props.recipient?.lastName}`,
            },
            {
              title: 'Fee',
              value: props.currency === 'NGN' ? `0.00` : '0.00',
            },
          ]}
        />
        <div className="w-100 flex-fill pt-4" style={{ width: '100%' }}>
          <p className="mb-2">Enter Transaction Code sent to your mail</p>
          <DividedPinInput onChange={props.setTransactionpin} />
        </div>
        {props.loading ? (
          <Loader />
        ) : (
          <>
            <Backcontinue
              back="Go Back"
              goBack={
                () => props.setStep(12)
                // props.step <= 2 ? props.setStep(0) : props.setStep(props.step - 1)
              }
            >
              <Platformbutton name="Finalize" type="submit" />
            </Backcontinue>

            <p style={{ textAlign: 'center' }}>
              Didn't get the code?{' '}
              <span
                style={{ cursor: 'pointer', color: 'green' }}
                onClick={() => props.dispatch(fetchTransactionOTP())}
              >
                Resend
              </span>
            </p>
          </>
        )}
      </div>
    </Form>
  );
};

const mapStateToProps = ({ transactions, profile }) => {
  const {
    transMessage,
    transSucData,
    transLoading,
    verifyAccount,
    ngnBanks,
    userNgnBanks,
    loading,
    step,
  } = transactions;
  const { profiles } = profile;
  return {
    transMessage,
    loading,
    transLoading,
    verifyAccount,
    ngnBanks,
    userNgnBanks,
    transSucData,
    step,
    profiles,
  };
};

const mapDispatchToProps = {
  withdrawNGN,
  getNairaBanks,
  getUserNairaBanks,
  verifyUserNairaAccount,
  withdrawNGNTwo,
  withdrawNGNEmail,
  addNigerianBank,
  transferNGNCash,
  getProfile,
  fetchTransactionOTP,
  // fetchProfiles,
  // fetchSingleProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ngnwithdrawal);
