import React, { useState, useEffect } from 'react';
import api from 'appRedux/api';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import { errorMessage } from 'helpers/utils';
import './security.scss';
import { Twofa } from './2fa';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { Platformbutton } from 'components/common/button/button';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Comptransaction } from 'components/common/completetransaction/comptransaction';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { connect, useDispatch } from 'react-redux';
import { updatePasswordInApp, updateTransPIN } from 'appRedux/actions/profile';
import { AlertError } from 'components/common/alertboxes/alertboxes';
import { Passwordcheck } from 'components/auth/register/registerfeatures';
import { fetchTransactionOTP } from 'appRedux/actions/wallets';

const Security = props => {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    modal: false,
  });
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    newTransactionPin: '',
    oldTransactionPin: '',
  });
  const [pins, setPin] = useState({
    transactionPin: '',
    confirmTransactionPin: '',
    password: '',
    modal: false,
  });

  var setInput = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value }, [inputs]);
  };

  // eslint-disable-next-line
  var setNewPin = e => {
    // console.log(e.target.value)
    var pinRegex = RegExp(/^(\d{4}|\d{6})$/);
    if (pinRegex.test(e.target.value)) {
      setPin({ ...pins, [e.target.name]: e.target.value }, [pins]);
      setError({ ...error, [e.target.name]: '' });
    } else {
      setPin({ ...pins, [e.target.name]: e.target.value }, [pins]);
      setError({ ...error, [e.target.name]: 'invalid pin format' });
    }
  };

  var setPassword = e => {
    var value = e.target.value;
    setPin({ ...pins, password: value });
  };

  var updatePassword = e => {
    e.preventDefault();
    setLoading(true);
    props.updatePasswordInApp(
      {
        oldPassword: inputs.oldPassword,
        password: inputs.newPassword,
        confirmPassword: inputs.newPassword,
      },
      () => setInputs({ ...inputs, modal: false }),
    );
    setLoading(false);
  };

  var updatePin = e => {
    e.preventDefault();
    props.updateTransPIN(
      {
        transactionPin: pins.transactionPin,
        confirmTransactionPin: pins.transactionPin,
        password: pins.password,
      },
      () => setPin({ ...pins, modal: false }),
    );
  };

  var setTwofa = () => {
    setCheck(!check);
    // console.log(check);
    var url = '/customer/activate-and-deactivate-2fa';
    api
      // .post(url, { factorAuth: check ? true : false })
      .post(url, { factorAuth: check ? false : true })
      .then(res => {
        openNotificationWithIcon(res.data.message, '2-FA', 'success');
      })
      .catch(() => {
        openNotificationWithIconErr(errorMessage(error), '2-FA', 'error');
      });
  };

  useEffect(() => {
    var value = props.userdata.factorAuth ? true : false;
    setCheck(value);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="security">
      <Simplemodal
        onClick={() => setPin({ ...pins, modal: false })}
        open={pins.modal}
      >
        <Titlesubtitle
          title="Update Transaction PIN"
          subtitle="Enter your new PIN and your account password."
        />
        <form className="securityform" onSubmit={e => updatePin(e)}>
          <Comptransaction
            fetcher={fetchTransactionOTP}
            setPin={value => setPin({ ...pins, transactionPin: value })}
            len={4}
            open={true}
            lower={true}
            pin={pins.transactionPin}
            title={`Enter your new Four (4) digits transaction PIN `}
          >
            <></>
          </Comptransaction>
          <p style={{ textAlign: 'center' }}>
            Didn't get the code?{' '}
            <span
              style={{ cursor: 'pointer', color: 'green' }}
              onClick={() => dispatch(fetchTransactionOTP())}
            >
              Resend
            </span>
          </p>
          <div className="mb-4"></div>
          {pins.transactionPin.length >= 4 ? (
            <Inputfloat
              type="password"
              label="AccountPassword"
              name="password"
              value={pins.password}
              disabled={false}
              placeholder="Account password"
              onChange={setPassword}
            />
          ) : (
            <></>
          )}

          {/* <div className="mb-4"></div> */}
          <div className="mb-2"></div>
          <Platformbutton
            disabled={
              pins.transactionPin.length >= 4 && pins.password ? false : true
            }
            name="Update Transaction Pin"
            type="submit"
          />
        </form>
      </Simplemodal>
      <Simplemodal
        onClick={() => setInputs({ ...inputs, modal: false })}
        open={inputs.modal}
      >
        <Titlesubtitle
          title="Update Account Password"
          subtitle="Fill in these fields to update your password"
        />
        <div className="mb-2"></div>
        <form className="securityform" onSubmit={e => updatePassword(e)}>
          <Inputfloat
            type="password"
            label="Password"
            name="oldPassword"
            value={inputs.oldPassword}
            placeholder="Enter your old password"
            disabled={false}
            onChange={setInput}
          />
          <Inputfloat
            type="password"
            label="Password"
            name="newPassword"
            value={inputs.newPassword}
            placeholder="Enter your new password"
            disabled={false}
            onChange={setInput}
          />
          <span className="flex flex-wrap gap-[10px] mt-2">
            <Passwordcheck
              name="8 Characters"
              check={inputs.newPassword.match(/^.{8,}$/)}
            />
            <Passwordcheck
              name="A number"
              check={inputs.newPassword.match(/\d/)}
            />
            <Passwordcheck
              name="A lowercase letter"
              check={inputs.newPassword.match(/[a-z]/)}
            />
            <Passwordcheck
              name="An uppercase letter"
              check={inputs.newPassword.match(/[A-Z]/)}
            />
            <Passwordcheck
              name="A Special character"
              check={inputs.newPassword.match(/[^\w_]/)}
            />
          </span>

          <div className="mb-4"></div>
          <Inputfloat
            type="password"
            label="Confirm Password"
            name="confirmPassword"
            value={inputs.confirmPassword}
            placeholder="Confirm your password"
            disabled={false}
            onChange={setInput}
          />
          <div className="mb-2"></div>
          {inputs.newPassword && inputs.confirmPassword
            ? inputs.newPassword !== inputs.confirmPassword && (
                <AlertError
                  body={'New password and confirm password do not match.'}
                />
              )
            : ''}
          <div className="mb-4"></div>
          <div className="updateprofile-btn-box">
            <Platformbutton
              type="submit"
              name="Update Password"
              disabled={
                !inputs.newPassword ||
                !inputs.confirmPassword ||
                inputs.newPassword !== inputs.confirmPassword ||
                inputs.newPassword === inputs.oldPassword ||
                loading === true
              }
            />
          </div>
        </form>
      </Simplemodal>
      <div className="security">
        <Titlesubtitle title="Security" />
        <div className="security__bottom">
          <Titleinfo
            title="Account Password"
            link="Update Account Password"
            click={() => setInputs({ ...inputs, modal: true })}
          />

          {/* <Titleinfo
            title="Transaction PIN"
            link="Update Transaction PIN"
            click={() => setPin({ ...pins, modal: true })}
          /> */}

          <Twofa
            check={check}
            setCheck={setCheck}
            factorAuth={props.userdata.factorAuth}
            setTwofa={setTwofa}
          />
        </div>
      </div>
    </div>
  );
};

const Titleinfo = props => {
  return (
    <div className="titleinfo">
      <span>
        <p>{props.title}</p>
        <p>Edit {props.title}</p>
      </span>

      <p className="titleinfo__link" onClick={() => props.click()}>
        {props.link} <i className="fas fa-arrow-right"></i>{' '}
      </p>
    </div>
  );
};

const mapStateToProps = ({ profile }) => {
  const { message, loading } = profile;
  return {
    message,
    loading,
  };
};

const mapDispatchToProps = {
  updateTransPIN,
  updatePasswordInApp,
};

export default connect(mapStateToProps, mapDispatchToProps)(Security);
