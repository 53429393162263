import { saveUserData } from 'appRedux/actions/auth';
import { convertAction, saveWalletData } from 'appRedux/actions/wallets';
import api from 'appRedux/api';
// import { update } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { currencies } from './data';
import { getCurrencyCode, removeCommaAmount } from './utils';
import _ from 'lodash';

export const useStep = number => {
  const [step, setStep] = useState(number);
  const previousStep = () => setStep(step - 1);
  const nextStep = () => setStep(step + 1);
  return { step, previousStep, nextStep, setStep };
};

export const useUserData = () => {
  const dispatch = useDispatch();
  const { isLoading, data: userData, refetch } = useQuery(
    'userData',
    // {refetchOnWindowFocus:true},
    async () =>
      await api.get('/authentication/profile').then(res => {
        dispatch(saveUserData(res.data.data.user));
        dispatch(saveWalletData(res.data.data.wallets));
        return res.data.data.user;
      }),
  );
  // localStorage.setItem('userData', userData);
  return { isLoading, userData, refetch };
};

export const useFounders = (id, stripeAccountStatus) => {
  // alert('hhiii')
  const {
    isLoading: foundersLoad,
    data: balance,
    refetch: foundersRefetch,
    isPreviousData: isPreviousFoundersData,
  } = useQuery(
    'Founders balance',

    async () =>
      stripeAccountStatus === 'VERIFIED' &&
      (await api
        .get(
          `/stripe/fetchSingleFinancialAccount/${id ||
            JSON.parse(localStorage.getItem('userData')).id}`,
        )
        .then(res => {
          // console.log(res.data.data.balance.cash.usd)
          return res.data.data.balance.cash.usd;
        })),
  );
  return { foundersLoad, balance, foundersRefetch, isPreviousFoundersData };
};

export const useRates = () => {
  const userData = useSelector(
    state => (state.auth.userData ? state.auth.userData : []),
    _.isEqual,
  );

  // console.log("I Ran")

  // Check if userData is not an empty array or object
  const hasUserData = userData && !_.isEmpty(userData);

  const country = hasUserData
    ? userData?.country
      ? userData?.country
      : userData?.address?.country
    : null;

  const { isLoading, data: pricesData, refetch } = useQuery(
    'Merchant Prices',
    async () =>
      hasUserData && country
        ? await api
            .get(`/vesti-fx/lists?currency=${getCurrencyCode(country)}`)
            .then(res => res.data)
        : null,
    {
      enabled: hasUserData && !!country, // Ensure the query runs only when necessary
    },
  );

  return { isLoading, pricesData, refetch };
};

export const useSilaData = () => {
  const { isLoading, data: userSila } = useQuery(
    'userSila',
    async () =>
      await api.get('/sila/get_single_wallet').then(res => res.data?.data[1]),
  );
  // localStorage.setItem('userData', userData);
  return { isLoading, userSila };
};

export const parseDate = (input, format) => {
  var addT = input.replace(' ', 'T');
  var replaceZone = addT.replace('+00:00', '');
  var replaceDec = replaceZone.slice(0, -5);
  var replaceEmp = replaceDec.concat(' ');
  var final = replaceEmp.replace(' ', 'Z');

  // console.log(finall)

  return final;
};

export const openMail = value => {
  window.location.href = `mailto:${value}`;
};

export const useFoundersData = id => {
  const {
    isLoading: foundersLoad,
    data: balance,
    refetch: foundersRefetch,
  } = useQuery(
    'Founders balance',

    async () =>
      await api
        .get(`/stripe/fetchSingleFinancialAccount/${id}`)
        .then(res => res.data.balance.cash.usd),
  );
  // localStorage.setItem('userData', userData);
  return { foundersLoad, balance, foundersRefetch };
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}
export default usePrevious;

export const useAmount = (value, balance, rate) => {
  const [amount, setAmount] = useState(value);
  // const prevAmount = usePrevious(amount)
  const handleAmount = e => {
    var newValue = parseFloat(
      e.target.value
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        .replace(/,/g, ''),
    ).toLocaleString('en-us');
    // setAmount(rate ? newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/,/g, '') * rate > balance? prevAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/,/g, '').toLocaleString('en-US') :newValue:newValue)
    rate
      ? removeCommaAmount(newValue) * rate > balance
        ? console.log('')
        : setAmount(newValue)
      : setAmount(newValue);
  };
  return { amount, setAmount, handleAmount };
};

export const useCardData = () => {
  const { isLoading, data: cardData, refetch } = useQuery(
    'cardData',
    // {refetchOnWindowFocus:true},
    async () =>
      await api.get('/vc/4/card/balance-update').then(res => res.data.data),
  );
  // localStorage.setItem('userData', userData);
  return { isLoading, cardData, refetch };
};

export const useCurrency = value => {
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState({
    active: false,
    value: '',
    primary: value,
  });
  const handleCurrency = val => {
    //console.log(val)
    setCurrency({
      ...currency,
      value: val,
      active: false,
      // , primary:val
    });
    dispatch(
      convertAction({
        convFrom: currencies.filter(item => item.currency === val)[0],
      }),
    );
  };
  const handleActive = val => {
    setCurrency({
      ...currency,
      active: val,
    });
  };
  return { currency, setCurrency, handleCurrency, handleActive };
};

export const useUserWallets = () => {
  const dispatch = useDispatch();
  const { isLoading, data: walletData, walletRefetch } = useQuery(
    'userData',
    async () =>
      await api.get('/wallet/all').then(res => {
        dispatch(saveWalletData(res.data.data.wallets));
        return res.data.data.wallets;
      }),
  );

  return { isLoading, walletData, walletRefetch };
};
