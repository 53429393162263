import React, { useEffect, useState } from 'react';
import '../../../containers/Admin/Admin.css';
import tooltip from 'assets/TourGuide/Tooltip.svg';
import '../Intro/intro.scss';
import { LiaTimesSolid } from 'react-icons/lia';
import setVpin from 'assets/TourGuide/Cards/TourImg/setPinVvc.svg';
import fund from 'assets/TourGuide/Cards/TourImg/fundVvc.svg';
import fund1 from 'assets/TourGuide/Cards/TourImg/fund1.svg';
import amount from 'assets/TourGuide/Cards/TourImg/amountFund.svg';
import transpin from 'assets/TourGuide/Cards/TourImg/transPinToFund.svg';
import mkTrans from 'assets/TourGuide/Cards/TourImg/makeAVcardTrans.svg';
import { navigate } from '@reach/router';

export const MakeTransactionTour = props => {
  const [step, setStep] = useState(0);

  switch (step) {
    case 0:
      return (
        <div style={{ height: '100%', width: '100%' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div className="  w-full h-full">
              <div className="myWalletTour">
                <div className="myWalletTour__wallet">
                  <img src={fund} alt="" style={{ marginTop: '14rem' }} />
                </div>

                <div className="TourArticleCContainer">
                  <img
                    className="TourArticleCContainer__tooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Fund Your Virtual Card
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Click on the “Fund” Button to initiate the process
                            of funding your card
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 2} of 6`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(1);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 1:
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div
              className="  w-full h-full"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="">
                {/* <div
                    className="myWalletTour__wallet"
                    style={{ background: 'white' }}
                  >
                    design a card here
                  </div> */}
                <img
                  src={fund1}
                  alt=""
                  style={{ width: '25rem', height: '20rem' }}
                />

                <div className="TourArticleCContainer">
                  <img
                    className="TourArticleCContainer__tooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Fund Your Virtual Card{' '}
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Select the channel you would prefer to use to fund
                            your virtual card
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 2} of 6`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>

                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(2);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    case 2:
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div
              className="  w-full h-full"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="">
                {/* <div
                    className="myWalletTour__wallet"
                    style={{ background: 'white' }}
                  >
                    design a card here
                  </div> */}
                <img
                  src={amount}
                  alt=""
                  style={{ width: '23rem', height: '25rem' }}
                />

                <div className="TourArticleCContainer">
                  <img
                    className="TourArticleCContainer__tooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Fund Your Virtual Card{' '}
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Input the amount you want to fund your card with. We
                            help you convert it so you can see exactly how much
                            you will be getting.
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 2} of 6`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>

                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>

                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(3);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 3:
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div
              className="  w-full h-full"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="">
                {/* <div
                    className="myWalletTour__wallet"
                    style={{ background: 'white' }}
                  >
                    design a card here
                  </div> */}
                <img
                  src={transpin}
                  alt=""
                  style={{ width: '20rem', height: '15rem' }}
                />

                <div className="TourArticleCContainer">
                  <img
                    className="TourArticleCContainer__tooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Fund Your Virtual Card{' '}
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Lastly, review the transaction and input your
                            4-digit PIN to confirm your transaction. Ensure all
                            details are correct because this action can not be
                            reversed.
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 2} of 6`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>

                          <li className="PathwayTourArticleCContainer__inactive"></li>

                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(4);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 4:
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', height: '100%' }}></div>

            <div
              className="  w-full h-full"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="">
                {/* <div
                    className="myWalletTour__wallet"
                    style={{ background: 'white' }}
                  >
                    design a card here
                  </div> */}
                <img
                  src={mkTrans}
                  alt=""
                  style={{ width: '25rem', marginLeft: '60%' }}
                />

                <div
                  className="TourArticleCContainer"
                  style={{ marginLeft: '30%' }}
                >
                  <img
                    className="TourArticleCContainer__addWallettooltip"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Make a Virtual Card Transaction{' '}
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            When you want to make a transaction with your
                            Virtual Card, all you have to do is copy all the
                            details listed above and paste them accordingly
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 2} of 6`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>
                        </ul>

                        <div
                          onClick={() => {
                            props.setStep(0);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Back
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    default:
  }
};
