import React, { useState, useEffect } from 'react';
import Layout from 'components/common/DashboardLayout';
import { Profileside } from 'components/profile/profileside';
import Accountdetails from 'components/profile/accountdetails';
import Security from 'components/profile/security';
// import { useUserData } from 'helpers/hooks';
import { fetchUserData } from 'appRedux/actions/profile';
import { Addbank } from 'components/profile/addbank';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import Loader from 'components/Loader';
import './userprofile.scss';
import Kycustomer from 'components/profile/kycustomer';
import Membership from 'components/profile/membership/membership';
import { Stepback } from 'components/common/stepback/stepback';
import { navigate } from '@reach/router';
import Referral from 'components/profile/referral';
import { useSelector } from 'react-redux';
import { fetchAllCountries, fetchAllStates } from 'appRedux/actions/Common';
import { connect } from 'react-redux';
// import { Titlesubtitle } from "components/common/titlesubtitle/titlesubtitle";

const Userprofile = props => {
  // const { userData, refetch } = useUserData();
  const [step, setStep] = useState(0);
  const [show, setShow] = useState(true);

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const { search } = useLocation();
  const values = queryString.parse(search);
  var tab = values.tab;

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  const userData = useSelector(state => state.auth?.userData);

  //   console.log(useSelector(state => state.auth.authUser))
  React.useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);
  useEffect(() => {
    props.fetchAllCountries();
    props.fetchAllStates({ country: 'nigeria' });
    if (tab === 'kyc') {
      props.fetchUserData();
    } else if (tab === 'addbeneficiary') {
      props.fetchUserData();
    }
    // eslint-disable-next-line
  }, []);

  if (!userData) {
    return <Loader />;
  } else {
    if (dimensions.width < 1100) {
      return (
        <Layout>
          <div className="userprofile">
            <div className="userprofile__inner">
              {show === true && !tab && (
                <div className="userprofile__left">
                  <Profileside
                    setStep={setStep}
                    setShow={setShow}
                    value={tab}
                  />
                </div>
              )}
              {(show === false || tab) && (
                <div className="userprofile__right">
                  <Stepback
                    onClick={() => {
                      setShow(true);
                      navigate('/myprofile');
                    }}
                  />
                  <Right
                    userdata={userData}
                    // refetch={refetch}
                    refetch={() => props.fetchUserData()}
                    step={step}
                    countries={props.countries}
                    state={props.states}
                    tab={tab}
                  />
                </div>
              )}
            </div>
          </div>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <div className=" userprofile">
            <div className="userprofile__inner">
              {dimensions.width > 1100 && (
                <div className="userprofile__left">
                  <Profileside
                    setStep={setStep}
                    value={tab ? tab : 'account'}
                  />
                </div>
              )}

              <div className="userprofile__right">
                <Right
                  userdata={userData}
                  // refetch={refetch}
                  refetch={() => props.fetchUserData()}
                  step={step}
                  countries={props.countries}
                  state={props.states}
                  tab={tab}
                />
              </div>
            </div>
          </div>
        </Layout>
      );
    }
  }
};

const Right = props => {
  switch (props.tab) {
    case 'account':
      return (
        <Accountdetails userdata={props.userdata} countries={props.countries} />
      );
    case 'security':
      return <Security userdata={props.userdata} />;

    case 'addbeneficiary':
      return <Addbank userdata={props.userdata} />;
    case 'kyc':
      return (
        <Kycustomer
          state={props.state}
          refetch={props.refetch}
          userdata={props.userdata}
          verifiedKyc={props.userdata.verifiedKyc}
        />
      );

    case 'referral':
      return <Referral userdata={props.userdata} />;
    case 'membership':
      return <Membership />;
    default:
      return (
        <Accountdetails userdata={props.userdata} countries={props.countries} />
      );
  }
};

const mapStateToProps = ({ common }) => {
  const { countries, states } = common;
  return {
    countries,
    states,
  };
};

const mapDispatchToProps = {
  fetchAllCountries,
  fetchAllStates,
  fetchUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Userprofile);
