import React, { useState, useEffect } from 'react';
// import { boy1, boy2, moneydp } from 'assets/chats';
import {
  ChatsFeeds,
  // Cohortgroup1,
  // groupcohort2,
  // groupcohort3,
  // feedschatnav,
  // othergroupNav,
  // UscohortGroup,
  // USCohort5,
} from 'assets/feedPageAssets';
import {
  // openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import { Link } from '@reach/router';
import { FaArrowRight, FaLock } from 'react-icons/fa';
import { Simplemodal } from 'components/common/simplifiedmodal';
// import { useUserData } from 'helpers/hooks';
import { useSelector } from 'react-redux';
import api from 'appRedux/api';
import Loader from 'components/Loader';
import { socialProof } from 'assets/feedPageAssets';
import './feedsChat.css';
import { Largemodal } from 'components/common/simplifiedmodal';
import PathwaysubSteps from '../Pathways/pathwaysubSteps';

const FeedsChat = props => {
  const [showModal, setShowModal] = useState(false);
  const [redirectshowModal, setRedirectShowModal] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [isloading, setIsloading] = useState(false);

  const userD = useSelector(state => state.auth?.userData);
  //     useEffect(async() => {
  //      await fetchUserData();

  //       }, []);

  // console.log(userD?.id);

  // eslint-disable-next-line
  const handleUnsubcribedUser = () => {
    openNotificationWithIconErr(
      'Unsusbcribed',
      'This group is for premium users only. Kindly upgrade plan to gain full access',
      'error',
    );
  };

  useEffect(() => {
    setIsloading(true);
    const fetchData = async () => {
      try {
        const response = await api.get(`/group/all-groups`);
        const data = await response.data.data.data;
        // console.log(data);
        setGroupData(data);

        setIsloading(false);
      } catch (error) {
        // Handle error
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Largemodal
        onClick={() => setRedirectShowModal(false)}
        open={redirectshowModal}
      >
        <PathwaysubSteps />
      </Largemodal>

      <Largemodal onClick={() => setShowModal(false)} open={showModal}>
        {isloading ? (
          <Loader />
        ) : (
          <div className=" myFeedsChatAstn gap-4">
            {groupData?.map((group, index) => (
              <>
                <div
                  key={index}
                  className="myFeedsChatAstnContainer"
                  // onClick={() => {
                  //   setShowModal(false);
                  // }}
                >
                  <img
                    className="w-full"
                    src={group.image}
                    style={{ objectFit: 'center' }}
                    alt="groupDp"
                  />
                  <div className="p-2 ">
                    <div className="flex justify-between items-center">
                      <p
                        style={{
                          color: '#1781BA',
                          fontSize: '18px',
                          fontWeight: '500',
                        }}
                      >
                        {group.name}
                      </p>

                      {/* {console.log(group.image)}
                      {console.log(group)} */}
                      <div className="flex items-center gap-2">
                        <p>
                          {group.type === 'FREE' ||
                          (props.isactive && props.plantype === group.type) ||
                          props.plantype === 'PREMIUM' ? (
                            ''
                          ) : (
                            <div>
                              <FaLock style={{ color: '#3F6C51' }} />
                            </div>
                          )}
                        </p>

                        <p
                          style={{
                            background: '#C0E9FF',
                            color: '#1781BA',
                            fontSize: '12px',
                            padding: '1px 4px 1px 4px',
                            border: '1px solid #1781BA',
                            borderRadius: '5px',
                          }}
                        >
                          {group.type}
                        </p>
                      </div>
                    </div>
                    <p className="" style={{ color: '#3F6C51' }}>
                      {group.description}
                    </p>

                    <img
                      className="absolute bottom-0 left-0 mx-2 mb-2"
                      style={{ width: '8rem' }}
                      src={socialProof}
                      alt=""
                    />
                    <div className="absolute bottom-0 right-0 mx-2 mb-2">
                      {group.type === 'FREE' ||
                      (props.isactive && props.plantype === group.type) ||
                      props.plantype === 'PREMIUM' ? (
                        <div>
                          <Link
                            onClick={() => {
                              setShowModal(false);
                            }}
                            to={`/group/${group.id}`}
                            key={group.id}
                            className="flex items-center gap-1"
                            style={{ color: '#1781BA', fontWeight: '600' }}
                          >
                            Join Now <FaArrowRight />
                          </Link>
                        </div>
                      ) : (
                        <div>
                          <p
                            // onClick={handleUnsubcribedUser}
                            onClick={() => {
                              setRedirectShowModal(true);
                              // setShowModal(false);
                            }}
                            className="flex items-center gap-1"
                            style={{
                              color: '#1781BA',
                              fontWeight: '600',
                              cursor: 'pointer',
                            }}
                          >
                            Join Now <FaArrowRight />
                          </p>
                        </div>
                      )}
                      {/* <Link
                        to={`/group/${group.id}`}
                        key={group.id}
                        className="flex items-center gap-1"
                        style={{ color: '#1781BA', fontWeight: '600' }}
                      >
                        Join Now <FaArrowRight />
                      </Link> */}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        )}
      </Largemodal>
      <div
        className="py-6 rounded-md"
        style={{
          background: '#F6F7F9',
          width: '100%',
          height: '95vh',
          position: 'relative',
        }}
      >
        <div
          className=""
          style={{
            background: '#F6F7F9',
            width: '100%',
            overflowY: 'scroll',
            height: '100%',
          }}
        >
          <div style={{ position: 'relative' }}>
            <p
              className="rounded-md"
              style={{
                background: '#F6F7F9',
                color: '#000',
                fontSize: '22px',
                fontWeight: '800',
                position: 'absolute',
                width: '100%',
                paddingLeft: '1.5rem',
              }}
            >
              Chat is coming
            </p>
            <div
              className="rounded-md"
              style={{
                position: 'absolute',
                width: '100%',
                top: '0px',
                bottom: '0px',
                background: '#000000',
                opacity: '0.35',
              }}
            ></div>
            {/* <img src={feedschatnav} alt='Chats' /> */}
            <img className="px-4 py-2" src={ChatsFeeds} alt="" />
          </div>

          <div className="px-4" style={{ marginTop: '2rem' }}>
            <div
              style={{
                marginBottom: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p style={{ fontWeight: '700' }}>Other Groups</p>
              <p
                style={{
                  display: 'flex',
                  gap: '2',
                  alignItems: 'center',
                  fontSize: '14px',
                  color: '#66A848',
                  cursor: 'pointer',
                }}
                onClick={() => setShowModal(true)}
              >
                See All <FaArrowRight />
              </p>
              {/* <img src={othergroupNav} alt=''/> */}
            </div>

            <>
              {/* https://res.cloudinary.com/wevestiservice/image/upload/v1692189804/1000_group_edhiua.png */}
              <div
                className="myFeedsChatAstnContainer"
                onClick={() => setShowModal(false)}
              >
                <img
                  className="w-full"
                  src={groupData[0]?.image}
                  alt="groupDp"
                />
                <div className="p-2">
                  <div className="flex justify-between items-center">
                    <p
                      style={{
                        color: '#1781BA',
                        fontSize: '18px',
                        fontWeight: '500',
                      }}
                    >
                      {groupData[0]?.name}
                    </p>

                    <div className="flex items-center gap-2">
                      <p>
                        {groupData[0]?.type === 'FREE' ||
                        (props.isactive &&
                          props.plantype === groupData[0]?.type) ||
                        props.plantype === 'PREMIUM' ? (
                          ''
                        ) : (
                          <div>
                            <FaLock style={{ color: '#3F6C51' }} />
                          </div>
                        )}
                      </p>

                      <p
                        style={{
                          background: '#C0E9FF',
                          color: '#1781BA',
                          fontSize: '12px',
                          padding: '1px 4px 1px 4px',
                          border: '1px solid #1781BA',
                          borderRadius: '5px',
                        }}
                      >
                        {groupData[0]?.type}
                      </p>
                    </div>
                  </div>
                  <p className="" style={{ color: '#3F6C51' }}>
                    {groupData[0]?.description}
                  </p>

                  <div
                    className=" flex"
                    style={{
                      justifyContent: 'space-between',
                      marginTop: '2rem',
                    }}
                  >
                    <img style={{ width: '8rem' }} src={socialProof} alt="" />
                    <Link
                      to={`/group/${groupData[0]?.id}`}
                      key={groupData[0]?.id}
                      className="flex items-center gap-1  "
                      style={{ color: '#1781BA', fontWeight: '600' }}
                    >
                      Join Now <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedsChat;
