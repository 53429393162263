import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { stripeTreasury } from 'appRedux/actions/transactions';
import VestiLogo from 'assets/Vesti_logo.svg';
import '../Admin.css';
import { Feespayment } from 'components/bank/feespayment/feespayment';
import { closeFees } from 'appRedux/actions/domore';

export const Loneview = () => {
  const dispatch = useDispatch();
  return (
    <section className="mainsuccess">
      <div className="mainsuccess__box">
        <img
          className="mainsuccess__logo__loan"
          src={VestiLogo}
          alt="Vesti logo"
        />
        <Feespayment close={() => dispatch(closeFees())} />
      </div>
    </section>
  );
};

const mapStateToProps = ({ transactions }) => {
  const { message, loading } = transactions;

  return {
    message,
    loading,
  };
};
const mapDispatchToProps = {
  stripeTreasury,
};

export default connect(mapStateToProps, mapDispatchToProps)(Loneview);
