import React, { useState, useRef, useEffect } from 'react';
import icon from 'assets/viasForm/chats/attach-circle.svg';
import postBtn from 'assets/viasForm/chats/ic_sharp-send.svg';
import { CiEdit } from 'react-icons/ci';
import { LiaTimesSolid } from 'react-icons/lia';
import { BsReply } from 'react-icons/bs';
import { IoImageOutline, IoFileTrayFullOutline } from 'react-icons/io5';
import './chat.scss';
import { IoDocumentTextOutline } from 'react-icons/io5';

export const PostQuestion = props => {
  const [inputText, setInputText] = useState('');
  const inputRef = useRef(null);
  const now = new Date();

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const day = now.getDate();
  const month = months[now.getMonth()];
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');

  const formattedDate = `${day} ${month} at ${hours}:${minutes}`;

  const setInput = e => {
    props.setInputValue(e.target.value);
    // props.extractUrlFromSentence(e.target.value);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter' && props.inputValue) {
      event.preventDefault();
      props.selectedFile?.length > 0
        ? props.handleImgUpload()
        : props.handleSendMessage();
    }
  };

  return (
    <div
      className=""
      style={{
        display: 'flex',
        // alignItems: 'center',
        height: '100%',
        gap: '20px',
      }}
    >
      <IoImageOutline
        style={{ fontSize: '22px', cursor: 'pointer' }}
        onClick={props.handleIconClick}
      />
      <input
        type="file"
        // accept=".png, .jpg, .svg, .jpeg, .pdf, .doc"
        accept=".png, .jpg, .svg, .jpeg"
        ref={props.ImgInputRef}
        style={{ display: 'none' }}
        onChange={props.handleFileChange}
        multiple
      />
      <IoFileTrayFullOutline
        style={{ fontSize: '22px', cursor: 'pointer' }}
        onClick={props.handleFileIconClick}
      />
      <input
        type="file"
        // accept=".png, .jpg, .svg, .jpeg, .pdf, .doc"
        accept=".pdf, .doc, .docx"
        ref={props.fileInputRef}
        style={{ display: 'none' }}
        onChange={props.handleFileChange}
        multiple
      />
      {/* fileInputRef={}
      handleFileIconClick={props.handleFileIconClick} */}
      {/* <input
        type="text"
        label=""
        placeholder="Enter your prompt here"
        value={props.inputValue}
        onChange={setInput}
        onKeyDown={handleKeyPress}
        style={{
          background: 'white',
          width: '100%',
          border: 'none',
          outline: 'none',
          fontSize: '12px',
        }}
      /> */}

      <textarea
        placeholder="Enter your prompt here"
        value={props.inputValue}
        onChange={setInput}
        onKeyDown={handleKeyPress}
        style={{
          background: '#F7FFF3',
          width: '100%',
          border: 'none',
          outline: 'none',
          fontSize: '12px',
          resize: 'none', // Prevents resizing if desired
          padding: '1px 2px',
        }}
        rows={2} // Sets the height of the textarea
      />
      {/* processing={props.processing}
      setProcessing={props.setProcessing} */}
      {(props.selectedFile?.length > 0 && !props.processing) ||
      (props.inputValue && !props.processing) ? (
        <div
          style={{
            background: '#67A948',
            padding: '8px',
            borderRadius: '6px',
            // cursor: 'pointer',
            cursor: 'pointer',
          }}
          onClick={() => {
            props.selectedFile?.length > 0
              ? props.handleImgUpload()
              : props.handleSendMessage();
          }}
        >
          <img src={postBtn} alt="" />
        </div>
      ) : (
        <div
          style={{
            background: '#67A948',
            padding: '8px',
            borderRadius: '6px',
            // cursor: 'pointer',
            cursor: 'not-allowed',
          }}
          onClick={() => {}}
        >
          <img src={postBtn} alt="" />
        </div>
      )}
    </div>
  );
};

export const ReferenceChat = () => {
  return (
    <div
      style={{
        background: '#fff',
        padding: '20px',
        width: '100%',
        position: 'absolute',
        bottom: '9%',
        left: '0px',
        right: '0px',
        zIndex: '10',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#67A948',
              fontSize: '12px',
              gap: '4px',
            }}
          >
            <CiEdit />
            Edit this message
          </p>

          <p style={{ color: '#1D2939', fontSize: '12px' }}>
            Borem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>
        <>
          <LiaTimesSolid style={{ cursor: 'pointer' }} />
        </>
      </div>
    </div>
  );
};

export const ImageCont = props => {
  return (
    <div
      style={{
        background: '#fff',
        padding: '20px',
        width: '100%',
        position: 'absolute',
        bottom: '9%',
        left: '0px',
        right: '0px',
        zIndex: '10',
        borderTop: '1px solid #66AA48',
      }}
    >
      <div className="imgPreviewContainer">
        {props.selectedFileBinary[0].type == 'application/pdf' ||
        props.selectedFileBinary[0].type == 'application/msword' ||
        props.selectedFileBinary[0].type ==
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
          <>
            {props.selectedFile.map((file, index) => (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                key={index}
              >
                <p
                  className="VisaChatFeature__chatshist__QAContainer"
                  style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                >
                  <IoDocumentTextOutline />
                  {props.selectedFileBinary[0].name}
                </p>
                <span
                  style={{
                    fontSize: '20px',
                    background: 'red',
                    color: '#fff',
                    padding: '0px 6px',
                    borderTopRightRadius: '12px',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    cursor: 'pointer',
                    // borderBottom: '12px',
                  }}
                  onClick={() => props.removeFile(index)}
                >
                  &times;
                </span>
              </div>
            ))}
          </>
        ) : (
          <>
            {' '}
            {props.selectedFile.map((file, index) => (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                key={index}
              >
                <img
                  style={{ width: '5rem', height: '5rem' }}
                  src={file}
                  alt={`preview-${index}`}
                  onClick={() => props.handleViewImage(file)}
                />
                <span
                  style={{
                    fontSize: '20px',
                    background: 'red',
                    color: '#fff',
                    padding: '0px 6px',
                    borderTopRightRadius: '12px',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    cursor: 'pointer',
                    // borderBottom: '12px',
                  }}
                  onClick={() => props.removeFile(index)}
                >
                  &times;
                </span>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export const ReplyChat = props => {
  return (
    <div
      style={{
        background: '#fff',
        padding: '20px',
        width: '100%',
        position: 'absolute',
        bottom: '9%',
        left: '0px',
        right: '0px',
        zIndex: '10',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#67A948',
              fontSize: '12px',
              gap: '4px',
            }}
          >
            <BsReply />
            Replying
          </p>

          <p style={{ color: '#1D2939', fontSize: '12px' }}>
            {props.replyChat}
          </p>
        </div>
        <>
          <LiaTimesSolid
            style={{ cursor: 'pointer' }}
            onClick={() => {
              props.setReplyChat('');
            }}
          />
        </>
      </div>
    </div>
  );
};
