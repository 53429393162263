import React, { useState } from 'react';
import { fellngsGf, emojihappy } from 'assets/feedPageAssets';
import { FaTimes } from "react-icons/fa";
import './EmojiPicker.scss'
import { BsEmojiSmile } from "react-icons/bs";

const EmojiPicker = ({ onSelect }) => {
  const [showPicker, setShowPicker] = useState(false);

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const closePicker = () => {
    setShowPicker(false);
  };

  const emojis = [
    '😀', '😃', '😄', '😁', '😆', '😅', '🤣', '😂',
    '🙂', '🙃', '😉', '😊', '😇', '🥰', '😍', '🤩', 
    '😝', '🤓', '😡', '🥳', '🫣', '🤗', '🤫', '🙄', 
    '🤕', '🤮', '👏', '👍', '👊', '🥹', '🥲', '😎', 
    '😒', '😞', '😔', '🙁', '😣', '😖', '😢', '😭',
    '😳', '🥵', '🥶', '😨', '😓', '🤗', '🤭', '🫢',
    '🫡', '😑', '😬', '😦', '😲', '🤮', '🤑', '😈',
    '💀', '🫶', '🤲', '🙌', '👎', '🤞', '👌', '🤙',
    '💪', '🖕', '🙏', '🫵', '🗣️', '🙅‍♀️', '🙅', '🙆‍♀️',
    '🙆', '🙋‍♀️', '🙋', '🤷‍♀️', '🤷', '💅', '💃', '👩‍🦯',
    '🧑‍🦯', '🧎‍♀️', '🧎', '🏃‍♀️', '🏃', '🌚', '🌝', '🎉',
    '🩷', '💜', '🧡', '💛', '💚', '🩵', '💙', '❤️‍🩹',
    '🖤', '🩶', '🤎', '💔', '❤️‍🔥', '💗', '💝', '💞',
    '📝', '📍', '🖌️', '📈', '📉', '💰', '❌', '💯', 
    '❓', '🚫', '❗️', '📢', '✅', '📌', '💌'
    // Add more emojis as needed
  ];

  return (
    <div style={{position: 'relative'}}>
        
      <span onClick={togglePicker} className='spanImgIcon'><BsEmojiSmile className='emojiIcon' style={{cursor: 'pointer'}}/> </span>
      {showPicker && (
        <>
        <div className='emojiDivContainer'>
            <FaTimes className='emojiCloseButton' onClick={closePicker}/>
          <div className="epickerbg">
          {emojis.map((emoji, index) => (
            <span key={index} className='emojiGanGan' onClick={() => onSelect(emoji)} >
              {emoji}
            </span>
          ))}
          </div>
        </div>
        </>
      )}
    </div>
  );
};

export default EmojiPicker;
