import React from 'react';
import './billPayment.scss';
import '../Admin.css';
import Layout from 'components/common/DashboardLayout';
import ElectricityMain from './Electricity/ElectricityMain';
import Internet from './DataPurchase/Internet';
import BillPaymentTransactionHistory from './BillPaymentTransactionHistory';
import CableMain from './CableTv/CableTv';
import AirtimeMain from './Airtime/Airtime';

const BillPaymentHome = () => {
  return (
    <>
      <Layout>
        <div>
          <div
            className="billpayment-container"
            style={{ height: '95vh', width: '100%', overflow: 'scroll' }}
          >
            <h2 className="page_title mt-3"> Categories</h2>
            <div className="billpayment-container-inner pt-2" >
              <div className="arrangement mt-4">
                <CableMain />
                <ElectricityMain />
                <Internet />
                <AirtimeMain/>
              </div>
            </div>
            <BillPaymentTransactionHistory />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BillPaymentHome;
