import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

const handleHomeRoute = () => {
    window.location.href = 'https://wevesti.com';
  };

function NewHome() {
    return (
        <Router>
          <Route path="/" render={handleHomeRoute} />
        
        </Router>
      );
}

export default NewHome;







