import React from 'react';
import './index.css';
import './newsavings.scss';
import SavingsTabComponent, {
  SavingsCard,
  SavingsYieldCard,
  Singlesavings,
} from 'components/savings/singlesavings';
const ListSavings = ({
  savingsPlans,
  handleShowTopUp,
  handleShowSavings,
  openUpdateModal,
  savingsTotal,
}) => {
  // const savingsPlans = [
  //   {
  //     id: 1111,
  //     name: 'Macbook',
  //     frequencyInDays: 3,
  //     lockedAmount: 15000,
  //     currency: 'USD',
  //     endDate: '2020-02-03',
  //     status: 'active',
  //   },
  //   {
  //     id: 1112,
  //     name: 'JAPA',
  //     frequencyInDays: 3,
  //     lockedAmount: 6000,
  //     currency: 'NGN',
  //     endDate: '2020-02-03',
  //     status: 'completed',
  //   },
  //   {
  //     id: 1113,
  //     name: 'Detty December',
  //     frequencyInDays: 3,
  //     lockedAmount: 5000,
  //     currency: 'NGN',
  //     endDate: '2020-02-03',
  //     status: 'completed',
  //   },
  //   {
  //     id: 1114,
  //     name: 'School Fees',
  //     frequencyInDays: 3,
  //     lockedAmount: 5000,
  //     currency: 'NGN',
  //     endDate: '2020-02-03',
  //     status: 'pending',
  //   },
  //   {
  //     id: 1115,
  //     name: 'Dowry ',
  //     frequencyInDays: 3,
  //     lockedAmount: 5000,
  //     currency: 'NGN',
  //     endDate: '2020-02-03',
  //     status: 'completed',
  //   },
  //   {
  //     id: 1116,
  //     name: 'IPhone 13 Pro',
  //     frequencyInDays: 3,
  //     lockedAmount: 4000,
  //     currency: 'NGN',
  //     endDate: '2020-02-03',
  //     status: 'pending',
  //   },
  //   {
  //     id: 1117,
  //     name: 'IPhone 14 Pro',
  //     frequencyInDays: 3,
  //     lockedAmount: 4000,
  //     currency: 'NGN',
  //     endDate: '2020-02-03',
  //     status: 'active',
  //   },
  //   {
  //     id: 1118,
  //     name: 'Mercedez Benz E350',
  //     frequencyInDays: 3,
  //     lockedAmount: 4000,
  //     currency: 'NGN',
  //     endDate: '2020-02-03',
  //     status: 'active',
  //   },
  // ];

  // console.log('Savings Plans:: ', savingsPlans);

  const tabs = [
    {
      id: 1,
      label: 'All Savings',
      content: (
        <div className="card-container" id="nsavings">
          {savingsPlans.length > 0 &&
            savingsPlans.map((saving, index) => (
              <div key={index}>
                <Singlesavings
                  id={saving.id}
                  name={saving.name}
                  currency={saving.currency}
                  amount={saving.lockedAmount}
                  endDate={saving.endDate}
                  frequency={saving.frequencyInDays}
                  status={saving.status}
                  disbursementStatus={saving.disbursementStatus}
                  onClick={() => {
                    handleShowTopUp(saving);
                  }}
                />
              </div>
            ))}
        </div>
      ),
    },
    {
      id: 2,
      label: 'Ongoing',
      content: (
        <div className="card-container" id="nsavings">
          {savingsPlans.length > 0 ? (
            savingsPlans
              .filter(saving => saving.status === 'active')
              .map((saving, index) => (
                <div key={index}>
                  <Singlesavings
                    id={saving.id}
                    name={saving.name}
                    currency={saving.currency}
                    amount={saving.lockedAmount}
                    endDate={saving.endDate}
                    frequency={saving.frequencyInDays}
                    status={saving.status}
                    disbursementStatus={saving.disbursementStatus}
                    onClick={() => alert('morning')}
                  />
                </div>
              ))
          ) : (
            <div className="empty-box">
              {/* <Empty
                title="No Savings Plan"
                subtitle="Once you create a Saving plan, it will become visible / available here, click the button
            below to create a Saving plan."
              />
              <button
                className="create-savings-plan"
                onClick={props.openUpdateModal}
              >
                Create Savings Plan
              </button> */}
            </div>
          )}
        </div>
      ),
    },
    {
      id: 3,
      label: 'Completed',
      content: (
        <div className="card-container" id="nsavings">
          {savingsPlans.length > 0 ? (
            savingsPlans
              .filter(
                saving =>
                  saving.status === 'completed' ||
                  saving.status === 'cancelled' ||
                  saving.status === 'terminated',
              )
              .map((saving, index) => (
                <div key={index}>
                  <Singlesavings
                    id={saving.id}
                    name={saving.name}
                    currency={saving.currency}
                    amount={saving.lockedAmount}
                    endDate={saving.endDate}
                    frequency={saving.frequencyInDays}
                    status={saving.status}
                    disbursementStatus={saving.disbursementStatus}
                  />
                </div>
              ))
          ) : (
            <div className="empty-box">
              {/* <Empty
                title="No Savings Plan"
                subtitle="Once you create a Saving plan, it will become visible / available here, click the button
            below to create a Saving plan."
              />
              <button
                className="create-savings-plan"
                onClick={props.openUpdateModal}
              >
                Create Savings Plan
              </button> */}
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="savings-main ">
        <SavingsYieldCard savingsTotal={savingsTotal} />
        <SavingsCard
          handleShowSavings={handleShowSavings}
          openUpdateModal={openUpdateModal}
          savingsTotal={savingsTotal}
        />
      </div>
      <SavingsTabComponent tabs={tabs} />
    </>
  );
};

export default ListSavings;
