import React, { useEffect, useState } from 'react';
import './MiaiIndex.scss';
import MiaiSidebar from './miaiSidebar';
import MiaiMain from './miaiMain';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChatData, chatHistoryData } from 'appRedux/actions/webMiai';
import { userSignInMiai } from 'containers/Auth/Login/actions';
import moon from 'assets/miai/moon.png';
import sun from 'assets/miai/whitsun.png';
import { FaBars, FaTimes } from 'react-icons/fa';
import { MiaiNav } from './miaiSidebar';
import { Link } from '@reach/router';
import { FaArrowLeft } from 'react-icons/fa';
import { Simplemodal } from 'components/common/simplifiedmodal';
import '../Admin.css';
import { MiaiSignUp } from './miaiSignUp';
import _ from 'lodash';
import { navigate } from '@reach/router';
import { BsFillPlusCircleFill, BsChatLeftDots } from 'react-icons/bs';
import dummy from 'assets/dummy-avatar.png';
import { fetchUserData } from 'appRedux/actions/profile';

const MiaiIndex = () => {
  const pathname = window.location.pathname;
  const splitLocation = pathname.split('/');

  // const userD = useSelector(state => state.auth?.userData);

  const storedFirstChatId = localStorage.getItem('firstChatId');

  const dispatch = useDispatch();
  const chatData = useSelector(state => state.webMiai.chatData);
  const chatH = useSelector(state => state.webMiai.chats);

  const [display, setDisplay] = useState('');
  const [chatId, setChatId] = useState(storedFirstChatId);
  // const [newchat, setnewchat] = useState(true);

  const [newchat, setnewchat] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [allchatbyId, setAllChatbyId] = useState([]);
  const [clickedBtm, setClickedBtm] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [typing, setTyping] = useState(false);
  const [typewriter, setTypewriter] = useState(false);
  const [show, setShow] = useState(false);
  const [goback, setGoback] = useState(false);
  const [messages, setMessages] = useState([]);
  const [signUpform, setSignUpForm] = useState(false);
  const [newchatId, setnewchatId] = useState('');

  const userData = useSelector(state => state.auth?.userData);

  useEffect(() => {
    dispatch(chatHistoryData(chatId));
  }, [typewriter, chatId]);

  useEffect(() => {
    dispatch(fetchUserData());
  }, []);

  useEffect(() => {
    dispatch(fetchChatData());
  }, [isLoading]);

  useEffect(() => {
    setTyping(true);
    const timeoutId = setTimeout(() => {
      setShowLoader(false);
      setTyping(false);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleChatItemClick = async chatId => {
    dispatch(chatHistoryData(chatId));
  };

  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    const storedMode = localStorage.getItem('darkMode');
    setIsDarkMode(storedMode === 'true');
  }, []);

  // Effect to update the mode in localStorage when it changes
  useEffect(() => {
    localStorage.setItem('darkMode', isDarkMode);
  }, [isDarkMode]);

  // Toggle the mode
  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  const HandlenewClick = () => {
    setShow(false);
    setDisplay('NewChat');
    setnewchat(true);
    setChatId('');
    setClickedBtm(true);
    setMessages(prevMessages => []);
  };

  return (
    <>
      <Simplemodal onClick={() => setSignUpForm(false)} open={signUpform}>
        <MiaiSignUp />
      </Simplemodal>

      <Simplemodal onClick={() => setGoback(false)} open={goback}>
        <div className="mainsuccess__modalcontent">
          <p>
            Are you sure you want to close your Miai ™ session, your progress
            will be saved.
          </p>

          <div className="mainsuccess__modalArticle">
            <div
              className=" mainsuccess__modalRedBtm"
              onClick={() => {
                navigate('/bank');
              }}
            >
              <span style={{ color: 'white' }}>Yes</span>
            </div>

            <p
              onClick={() => {
                setGoback(false);
              }}
              className="mainsuccess__modalBtm"
            >
              Cancel
            </p>
          </div>
        </div>
      </Simplemodal>
      <div
        className={`MiaiIndexContainer__main ${isDarkMode ? 'dark' : 'greyBg'}`}
        style={{
          height: '100lvh',
          margin: '0px',
          padding: '0px',
          overflowY: 'hidden',
          width: '100vw',
        }}
      >
        <div className="MiaiIndexContainer" style={{ overflowY: 'hidden' }}>
          <div
            className={`MiaiIndexContainer__sidebar ${
              isDarkMode ? 'darksidebar' : 'light'
            }`}
          >
            <MiaiSidebar
              display={display}
              setDisplay={setDisplay}
              chatData={chatData}
              chatId={chatId}
              setChatId={setChatId}
              newchat={newchat}
              setnewchat={setnewchat}
              clickedBtm={clickedBtm}
              setClickedBtm={setClickedBtm}
              isLoading={isLoading}
              isDarkMode={isDarkMode}
              handleChatItemClick={handleChatItemClick}
              showLoader={showLoader}
              setShowLoader={setShowLoader}
              typing={typing}
              setTyping={setTyping}
              setTypewriter={setTypewriter}
              messages={messages}
              setMessages={setMessages}
              setShow={setShow}
              setnewchatId={setnewchatId}
              newchatId={newchatId}

              // handlenewClick={handlenewClick}
            />
          </div>

          <div
            className={`MiaiIndexContainer__main ${
              isDarkMode ? 'dark' : 'greyBg'
            }`}
            style={{ position: 'relative' }}
          >
            <div
              className={`MiaiIndexContainer__main__page ${
                isDarkMode ? 'dark' : 'light'
              }`}
            >
              {/* Desktop top nav: this include the toggle switch for light mode and dark mode and also the back arrow button to return back to Vesti dashboard */}
              <div className="DesktopMaiHeadernNav">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  {splitLocation[2] === 'live' ? (
                    <div></div>
                  ) : (
                    <div className="MiaiBacktoVesti">
                      <FaArrowLeft
                        onClick={() => {
                          setGoback(true);
                        }}
                        style={{
                          color: isDarkMode ? 'white' : 'black',
                          fontSize: '20px',
                          cursor: 'pointer',
                        }}
                      />
                      <p style={{ color: isDarkMode ? 'white' : 'black' }}>
                        Return to Vesti Dashboard
                      </p>
                    </div>
                  )}
                  <div>
                    {' '}
                    <img
                      style={{
                        borderRadius: '40%',
                        width: '40px',
                        height: '40px',
                      }}
                      src={
                        userData?.profilePictureURL
                          ? userData?.profilePictureURL
                          : dummy
                      }
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '6px',
                    }}
                  >
                    <p
                      className={` ${
                        isDarkMode ? 'darkModecolor' : 'lightModecolor'
                      }`}
                      style={{ fontSize: '14px', fontWeight: '600' }}
                    >
                      Hello, {userData?.firstName ? userData?.firstName : ''}
                    </p>
                    <p
                      style={{
                        color: '#9EA1AE',
                        fontSize: '14px',
                        fontWeight: '500',
                      }}
                    >
                      Get personalized answers to your migration questions
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <div
                    style={{
                      border: `0.6px solid ${
                        isDarkMode ? '#9EA1AE' : '#D9D9D9'
                      }`,
                      padding: '10px 20px',
                      fontSize: '12px',
                      fontWeight: '600',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      borderRadius: '10px',
                      cursor: 'pointer',
                    }}
                    className={` ${
                      isDarkMode ? 'darkModecolor' : 'lightModecolor'
                    }`}
                    onClick={() => {
                      HandlenewClick();
                    }}
                  >
                    <BsFillPlusCircleFill style={{ fontSize: '16px' }} />
                    Generate new prompt
                  </div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isDarkMode}
                      onChange={toggleDarkMode}
                    />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
              {/* Mobile nav begins here with a back top botton */}

              <div className="MobileMaiHeadernNav">
                {splitLocation[2] === 'live' ? (
                  <div></div>
                ) : (
                  <div
                    className="MiaiBacktoVesti"
                    style={{ marginBottom: '10px' }}
                  >
                    <div
                      onClick={() => {
                        setGoback(true);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <FaArrowLeft
                        style={{
                          color: isDarkMode ? 'white' : 'black',
                          fontSize: '20px',
                        }}
                      />
                    </div>
                    <p
                      style={{
                        color: isDarkMode ? 'white' : 'black',
                        width: '20rem',
                        textAlign: 'center',
                      }}
                    >
                      Return to Vesti Dashboard
                    </p>
                  </div>
                )}

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    onClick={() => {
                      setShow(true);
                    }}
                    style={{
                      border: `1px solid ${isDarkMode ? 'white' : 'black'}`,

                      borderRadius: '6px',
                    }}
                    className="mobilebars"
                  >
                    <FaBars
                      className={`${
                        isDarkMode ? 'darkModecolor' : 'lightModecolor'
                      }`}
                    />
                  </div>
                  {/*  */}
                  <div className="Mheader">
                    {' '}
                    <MiaiNav isDarkMode={isDarkMode} />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '2px',
                    }}
                  >
                    <div
                      style={{
                        border: '0.8px solid #D9D9D9',
                        padding: '5px 8px',
                        fontSize: '12px',
                        fontWeight: '600',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        borderRadius: '6px',
                        cursor: 'pointer',
                        marginRight: '6px',
                      }}
                      className={` ${
                        isDarkMode ? 'darkModecolor' : 'lightModecolor'
                      }`}
                      onClick={() => {
                        HandlenewClick();
                      }}
                    >
                      <BsFillPlusCircleFill style={{ fontSize: '16px' }} />
                    </div>
                    {/* {isDarkMode ? (
                      <p className="sun"></p>
                    ) : (
                      <img src={sun} alt="" className="sun" />
                    )} */}

                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={isDarkMode}
                        onChange={toggleDarkMode}
                      />
                      <span className="slider"></span>
                    </label>

                    {/* {isDarkMode ? (
                      <img src={moon} alt="" className="moon" />
                    ) : (
                      <p className="moon"></p>
                    )} */}
                  </div>
                </div>
              </div>

              {/* Mobile nav ends here with a back top botton */}

              <div className="MobileSidebar">
                {show && (
                  <div
                    className={`MobileSidebar__Mmain  ${
                      isDarkMode ? 'darksidebar' : 'light'
                    }`}
                  >
                    <MiaiSidebar
                      display={display}
                      setDisplay={setDisplay}
                      chatData={chatData}
                      chatId={chatId}
                      setChatId={setChatId}
                      newchat={newchat}
                      setnewchat={setnewchat}
                      clickedBtm={clickedBtm}
                      setClickedBtm={setClickedBtm}
                      isLoading={isLoading}
                      isDarkMode={isDarkMode}
                      handleChatItemClick={handleChatItemClick}
                      showLoader={showLoader}
                      setShowLoader={setShowLoader}
                      typing={typing}
                      setTyping={setTyping}
                      setTypewriter={setTypewriter}
                      messages={messages}
                      setMessages={setMessages}
                      setShow={setShow}
                      setnewchatId={setnewchatId}
                      newchatId={newchatId}
                      // handlenewClick={handlenewClick}
                    />

                    <div
                      className={`${
                        isDarkMode ? 'darkModecolor' : 'lightModecolor'
                      }`}
                      style={{ textAlign: 'right' }}
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <FaTimes
                        className={`${
                          isDarkMode ? 'darkModecolor' : 'lightModecolor'
                        }`}
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* End of mobile View */}

              <MiaiMain
                display={display}
                setDisplay={setDisplay}
                chatId={chatId}
                setChatId={setChatId}
                newchat={newchat}
                setnewchat={setnewchat}
                isLoading={isLoading}
                setIsloading={setIsloading}
                clickedBtm={clickedBtm}
                setClickedBtm={setClickedBtm}
                storedFirstChatId={storedFirstChatId}
                isDarkMode={isDarkMode}
                allchatbyId={chatH}
                showLoader={showLoader}
                typing={typing}
                setTyping={setTyping}
                typewriter={typewriter}
                setTypewriter={setTypewriter}
                messages={messages}
                setMessages={setMessages}
                setSignUpForm={setSignUpForm}
                splitLocation={splitLocation}
                userData={userData}
                chatHistoryData={chatHistoryData}
                setnewchatId={setnewchatId}
                newchatId={newchatId}
                chatData={chatData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MiaiIndex;
