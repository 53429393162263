import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Platformbutton } from 'components/common/button/button';
import { Clipboard } from 'components/common/clipboard/clipboard';
import { Numberedcheck } from 'components/common/listcheck/listcheck';
import { Newprompt } from 'components/common/prompt/prompt';
import { RadioOption } from 'components/common/radiobutton/radiobutton';
import { Shortinfo, Shortinfonew } from 'components/common/shortinfo/shortinfo';
import {
  Smalltitlesubtitle,
  Titlesubtitle,
} from 'components/common/titlesubtitle/titlesubtitle';
import { useAmount, useStep } from 'helpers/hooks';
// import { removeCommaAmount } from "helpers/utils";
import React, { useEffect, useState } from 'react';
import { Equivalence } from '../equivalence';
import { Compeletetrans, Stepone } from './fbo';
import fprompt from 'assets/fboprompt.svg';
import { radio_cardusd, radio_ngnusd } from 'assets/assets';
import './styles.scss';
import { connect } from 'react-redux';
import {
  depositUsdViaAccount,
  depositUsdViaCard,
  depositUsdViaNaira,
  depositUsdViaCedis,
} from 'appRedux/actions/usdtrans';
import {
  formatterUSD,
  getUsdDepositRate,
  removeCommaAmount,
} from 'helpers/utils';
import { Success } from 'components/common/success/success';
import errorsvg from 'assets/newerror.svg';
// import { usdAccountDeposit } from 'helpers/data';
import SimplePlaidLink from 'components/plaidlink/plaidlink.tsx';
import { fetchLinkAccount } from 'appRedux/actions/Common';
// import { radio_plaidusd } from 'assets/assets';
import { AlertError } from 'components/common/alertboxes/alertboxes';
import { Textbal } from '../littlebalance/littlebalance';
import infob from 'assets/infob.svg';
import api from 'appRedux/api';

const Fbodeposit = props => {
  // console.log('FBO PROPS:', props);
  const { amount, handleAmount } = useAmount(0);
  const [selected, setSelect] = useState();
  const [rate, setRate] = useState('');
  const [account, setAccount] = useState();
  const [pin, setPin] = useState();
  const { step, nextStep, previousStep, setStep } = useStep(0);

  useEffect(() => {
    api
      .get(`/vesti-fx/lists?currency=${selected === 'NAIRA' ? 'NGN' : 'GHS'}`)

      .then(res => {
        // console.log(res);
        setRate(res.data);
      })
      .catch(err => err);

    // eslint-disable-next-line
  }, [selected]);

  const __renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <Stepone
            rate={parseFloat(rate?.merchantRate / 100)}
            setRate={setRate}
            type="Deposit"
            balance={props.balance}
            amount={amount}
            handleAmount={handleAmount}
            continue={nextStep}
            fullname={props.fullname}
            closeModal={props.closeModal}
          />
        );
      case 1:
        return (
          <Steptwo
            balance={props.balance}
            rate={parseFloat(rate?.merchantRate / 100)}
            setRate={setRate}
            amount={amount}
            selected={selected}
            fullname={props.fullname}
            setSelect={setSelect}
            setStep={setStep}
            viaCard={props.depositUsdViaCard}
            viaAccount={props.depositUsdViaAccount}
            closeModal={props.closeModal}
            back={previousStep}
            setAccount={setAccount}
            loading={props.transLoading}
            fetchLinkAccount={props.fetchLinkAccount}
            ngnbal={props.ngnbalance}
            ghsbal={props.ghsbalance}
            userWallets={props.userWallets}
          />
        );
      case 2:
        return (
          <Accountdetails
            account={account}
            setStep={setStep}
            userWallets={props.userWallets}
            closeModal={props.closeModal}
            back={previousStep}
          />
        );
      case 4:
        return (
          <Compeletetrans
            rate={parseFloat(rate?.merchantRate / 100)}
            setRate={setRate}
            balance={props.balance}
            selected={selected}
            refetch={props.refetch}
            data={{
              amount: removeCommaAmount(amount).toLocaleString('en-us'),
              charges: (
                getUsdDepositRate(removeCommaAmount(amount)) *
                parseFloat(rate?.merchantRate / 100)
              )
                .toFixed(2)
                .toLocaleString('en-us'),
              amountNGN: formatterUSD
                .format(
                  removeCommaAmount(amount) *
                    parseFloat(rate?.merchantRate / 100),
                )
                .toLocaleString('en-us'),
            }}
            back={() => setStep(1)}
            loading={props.transLoading}
            handlePin={setPin}
            type="deposit"
            finish={() =>
              props.depositUsdViaNaira(
                {
                  amount: removeCommaAmount(amount) * 100,
                  charges: +formatterUSD.format(
                    formatterUSD.format(
                      getUsdDepositRate(removeCommaAmount(amount)),
                    ) * 100,
                  ),
                  currency: 'NGN',
                  transactionOtp: pin,
                },
                value => setStep(value),
              )
            }
            // formatterUSD.format(getUsdAchRate(removeCommaAmount(amount))* 100)
            // +(formatterUSD.format(getUsdDepositRate(removeCommaAmount(amount))*props.rate)* 100)
          />
        );
      case 3:
        return (
          <Aboutnumb
            setStep={setStep}
            closeModal={props.closeModal}
            back={previousStep}
          />
        );
      case 5:
        return (
          <Success
            title="Successful"
            subtitle={props.transMessage}
            button="Okay, thank you"
            onClick={() => {
              props.refetch();
              props.closeModal();
            }}
          />
        );
      case 6:
        return (
          <Success
            image={errorsvg}
            title={`Failed`}
            subtitle={props.transMessage}
            onClick={() => setStep(0)}
            button="Try Again"
            type="error"
          />
        );
      case 7:
        return (
          <Compeletetrans
            rate={parseFloat(rate?.merchantRate / 100)}
            selected={selected}
            balance={props.balance}
            refetch={props.refetch}
            data={{
              amount: removeCommaAmount(amount).toLocaleString('en-us'),
              charges:
                getUsdDepositRate(removeCommaAmount(amount)) *
                parseFloat(rate?.merchantRate / 100)
                  .toFixed(2)
                  .toLocaleString('en-us'),
              amountGHS: formatterUSD
                .format(
                  removeCommaAmount(amount) *
                    parseFloat(rate?.merchantRate / 100),
                )
                .toLocaleString('en-us'),
            }}
            back={() => setStep(1)}
            loading={props.transLoading}
            handlePin={setPin}
            type="deposit"
            finish={() =>
              props.depositUsdViaNaira(
                {
                  amount: removeCommaAmount(amount) * 100,
                  charges: +formatterUSD.format(
                    formatterUSD.format(
                      getUsdDepositRate(removeCommaAmount(amount)),
                    ) * 100,
                  ),
                  currency: 'GHS',
                  transactionOtp: pin,
                },
                value => setStep(value),
              )
            }
          />
        );

      default:
        return <></>;
    }
  };
  return <>{__renderSteps()}</>;
};

const Steptwo = props => {
  // console.log('isCurrencyExists props: ', props);
  const [currencyExists, setCurrencyExists] = useState({});

  useEffect(() => {
    const isCurrencyExists = currencyToCheck =>
      props.userWallets.some(
        item => item.currency.toLowerCase() === currencyToCheck.toLowerCase(),
      );

    const currenciesToCheck = ['NGN', 'GHS']; // Add more currencies

    const currencyResults = {};
    currenciesToCheck.forEach(currency => {
      currencyResults[currency] = isCurrencyExists(currency);
    });

    setCurrencyExists(currencyResults);
  }, [props.userWallets]);

  // console.log(currencyExists);

  var handleLinking = payload => {
    props.fetchLinkAccount(
      '/bank/link_account',
      {
        publicToken: payload.publicToken,
        accountID: payload.accountID,
        amount: removeCommaAmount(props.amount) * 100,
        charges: 50,
        description: 'USD wallet Funding',
      },
      () => props.setStep(5),
      () => props.setStep(6),
    );
  };

  var charge = formatterUSD
    .format(removeCommaAmount(props.amount) * 0.049)
    .toLocaleString('en-US');

  var __rendeBtn = () => {
    switch (props.selected) {
      case 'CARD':
        return (
          <Platformbutton
            type="normal"
            name="Initiate Deposit"
            click={() =>
              props.viaCard(
                '/wallet/global-deposits',
                {
                  amount: removeCommaAmount(props.amount) * 100,
                  // senderName: props.fullname,
                  currency: 'USD',
                  charges: +removeCommaAmount(
                    formatterUSD.format(charge) * 100,
                  ),
                },
                () => props.closeModal(),
                () => props.setStep(6),
              )
            }
            disabled={props.loading ? true : false}
          />
        );
      // ((formatterUSD.format(charge) * 100).toFixed(2))
      case 'NAIRA':
        return (
          <Platformbutton
            type="normal"
            name="Continue"
            disabled={
              props.ngnbal < removeCommaAmount(props.amount) * props.rate
                ? true
                : false
            }
            click={() => props.setStep(4)}
          />
        );
      case 'CEDIS':
        return (
          <Platformbutton
            type="normal"
            name="Continue"
            disabled={
              props.ghsbal < removeCommaAmount(props.amount) * props.rate
                ? true
                : false
            }
            click={() => props.setStep(7)}
          />
        );
      // disabled={(props.ngnbal < removeCommaAmount(props.amount)* props.rate) ? true :false}
      case 'ACCOUNT':
        return (
          <Platformbutton
            type="normal"
            name="Continue"
            click={() =>
              props.viaAccount(
                {
                  amount: removeCommaAmount(props.amount) * 100,
                  senderName: props.fullname,
                  // charges:+(formatterUSD.format(getUsdDepositRate(removeCommaAmount(props.amount))*props.rate)* 100)
                  charges: +removeCommaAmount(
                    formatterUSD.format(charge) * 100,
                  ),
                },
                data => {
                  props.setAccount(data);
                  props.setStep(2);
                },
                () => props.setStep(4),
              )
            }
            disabled={props.loading ? true : false}
          />
        );
      case 'Plaid':
        return <SimplePlaidLink handleLinking={handleLinking} />;
      default:
        return '';
    }
  };
  return (
    <div className="fbo__bigcol">
      <Titlesubtitle
        steps="1 of 1"
        title="Add Money"
        subtitle="Choose from our varieties of options."
      />
      <div className="fbo__top">
        <div className="fbo__col --list">
          <span className="fbo__option">
            <RadioOption
              image={radio_cardusd}
              changed={() => props.setSelect('CARD')}
              id="0"
              isSelected={props.selected === 'CARD'}
              label="Add money using Card"
              sublabel="Add money using either your credit or debit card."
              value="CARD"
            />
            {currencyExists['NGN'] && (
              <RadioOption
                image={radio_ngnusd}
                changed={() => props.setSelect('NAIRA')}
                id="1"
                isSelected={props.selected === 'NAIRA'}
                label="Add money using Naira wallet"
                sublabel="Add money using Naira wallet"
                value="NAIRA"
              />
            )}
            {currencyExists['GHS'] && (
              <RadioOption
                image={radio_ngnusd}
                changed={() => props.setSelect('CEDIS')}
                id="2"
                isSelected={props.selected === 'CEDIS'}
                label="Add money using Cedis wallet"
                sublabel="Add money using Cedis wallet"
                value="CEDIS"
              />
            )}

            {props.selected === 'CARD' && (
              <Shortinfonew image={infob}>
                <p>
                  This option opens a new tab, so allow your browser to open a
                  new tab.
                </p>
              </Shortinfonew>
            )}

            {props.selected === 'CARD' && (
              <Equivalence
                first="total amount "
                second=" "
                amount={''}
                equal={
                  '$' +
                  formatterUSD.format(removeCommaAmount(props.amount)) +
                  ' + $' +
                  formatterUSD
                    .format(removeCommaAmount(props.amount) * 0.049)
                    .toLocaleString('en-US') +
                  ' Charge'
                }
              />
            )}

            {/* Naira deposit */}
            {props.selected === 'NAIRA' && (
              <Equivalence
                first={``}
                second={` `}
                amount={`@${props.rate}, $${removeCommaAmount(
                  props.amount,
                ).toLocaleString('en-us')} `}
                equal={
                  '₦' +
                  formatterUSD
                    .format(removeCommaAmount(props.amount) * props.rate)
                    .toLocaleString('en-US') +
                  ' + ₦' +
                  formatterUSD.format(
                    getUsdDepositRate(removeCommaAmount(props.amount)) *
                      props.rate,
                  ) +
                  ' charge.'
                }
                // (getUsdDepositRate(removeCommaAmount(props.amount)) * props.rate).toFixed(2)
              />
            )}

            {/* cedis deposit */}
            {props.selected === 'CEDIS' && (
              <Equivalence
                first={``}
                second={` `}
                amount={`@${props.rate}, $${removeCommaAmount(
                  props.amount,
                ).toLocaleString('en-us')} `}
                equal={
                  '₵' +
                  formatterUSD
                    .format(removeCommaAmount(props.amount) * props.rate)
                    .toLocaleString('en-US') +
                  ' + ₵' +
                  formatterUSD.format(
                    getUsdDepositRate(removeCommaAmount(props.amount)) *
                      props.rate,
                  ) +
                  ' charge.'
                }
              />
            )}
          </span>
          {/* <RadioOption
            image={radio_plaidusd}
            changed={() => props.setSelect('Plaid')}
            id="4"
            isSelected={props.selected === 'Plaid'}
            label="Add money using an account"
            sublabel="Deposit directly  from your account."
            value="Plaid"
          /> */}

          {/* <Equivalence
            first="Charge of 2.9% of "
            second="you get "
            amount={removeCommaAmount(props.amount).toLocaleString('en-US')}
            equal={
              '$' +
              (removeCommaAmount(props.amount) * 0.029).toLocaleString('en-US')
            }
          /> */}
        </div>
      </div>

      <div className="fbo__scol">
        {props.selected === 'NAIRA' &&
          props.ngnbal <= removeCommaAmount(props.amount) * props.rate && (
            <AlertError
              body={`You do not have up to ${'₦' +
                formatterUSD
                  .format(removeCommaAmount(props.amount) * props.rate)
                  .toLocaleString('en-US')} in your Naira wallet.`}
            />
          )}

        {props.selected === 'CEDIS' &&
          props.ngnbal <= removeCommaAmount(props.amount) * props.rate && (
            <AlertError
              body={`You do not have up to ${'₵' +
                formatterUSD
                  .format(removeCommaAmount(props.amount) * props.rate)
                  .toLocaleString('en-US')} in your Cedis wallet.`}
            />
          )}
        {props.selected && (
          <Backcontinue goBack={() => props.back()}>
            {__rendeBtn()}
          </Backcontinue>
        )}
        {props.selected === 'NAIRA' && (
          <Textbal
            wallet="Naira Wallet"
            bal={formatterUSD.format(props.ngnbal)}
            currency="₦"
          />
        )}
        {props.selected === 'CEDIS' && (
          <Textbal
            wallet="Cedis Wallet"
            bal={formatterUSD.format(props.ghsbal)}
            currency="₵"
          />
        )}
      </div>
    </div>
  );
};

const Accountdetails = props => {
  var data = [
    {
      name: 'Account Number',
      value: props.account.accountNumber,
    },
    {
      name: ' Bank Name',
      value: props.account.bankName,
    },
    {
      name: 'Routing Number',
      value: props.account.routingNumber,
    },
    {
      name: 'Address',
      value: props.account.address,
    },
    {
      name: 'Transaction Reference',
      value: props.account.trxRef,
    },
  ];

  return (
    <Newprompt img={fprompt}>
      <div className="fbo__bigccol">
        <Titlesubtitle
          title="Account Details"
          subtitle="Attached below are the details of your Founders account."
        />
        <div className="fbo__row">
          {data.map((item, index) => (
            <Clipboard key={index} title={`${item.name}`} link={item.value} />
          ))}
        </div>

        <div className="fbo__ccol">
          <Shortinfo subject="This is Eligible for FDIC insurance up to $250K through our bank partners." />
          <Platformbutton
            type="slant"
            name="What are these numbers ?"
            click={() => props.setStep(3)}
          />
        </div>

        {/* <div className="mb-2"></div>  */}
        <Platformbutton
          type="normal"
          name="Back"
          click={() => props.setStep(1)}
        />
      </div>
    </Newprompt>
  );
};

const Aboutnumb = props => {
  var data = [
    {
      name: 'Account Number',
      value: '123456789011231231231323',
    },
    {
      name: 'Routing Number',
      value: '123456789011231231231323',
    },
  ];

  return (
    <Newprompt img={fprompt}>
      <Titlesubtitle
        title="Bank Transfers"
        subtitle="How to transfer money from another bank into the account details."
      />

      <div className="fbo__col">
        <Numberedcheck
          index={1}
          body="Find the transfers section of the bank’s mobile app or website."
        />
        <Numberedcheck
          index={2}
          body="You will need to add the bank details as an external
                    account, enter your routing and account number "
        >
          <div className="fbo__list">
            {data.map((item, index) => (
              <Smalltitlesubtitle
                key={index}
                title={item.name}
                subtitle={item.value}
              />
            ))}
          </div>
        </Numberedcheck>
        <Numberedcheck
          index={3}
          body="Select the Checking account option as
                    the account type"
        />
      </div>

      <Backcontinue goBack={() => props.setStep(2)}>
        <Platformbutton
          type="normal"
          name="Done"
          click={() => props.closeModal()}
        />
      </Backcontinue>
    </Newprompt>
  );
};

const mapStateToProps = ({ transactions }) => {
  const { transLoading, transMessage } = transactions;
  return {
    transLoading,
    transMessage,
  };
};

const mapDispatchToProps = {
  depositUsdViaAccount,
  depositUsdViaCard,
  fetchLinkAccount,
  depositUsdViaNaira,
  depositUsdViaCedis,
};

export default connect(mapStateToProps, mapDispatchToProps)(Fbodeposit);
