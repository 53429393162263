import api from 'appRedux/api';
import {
  ADD_MONEY,
  CONVERT_FROM,
  FOUNDERS_WALLET,
  SEND_MONEY,
  USD_WALLET,
  WALLET_DATA,
  WALLET_ACTION,
  WALLET_TRANSACTION,
  FETCH_OTP,
} from 'appRedux/constants';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from './Common';

export const walletAction = value => ({
  type: FOUNDERS_WALLET,
  payload: value,
});

export const walletUsdAction = value => ({
  type: USD_WALLET,
  payload: value,
});

export const addMoneyAction = value => ({
  type: ADD_MONEY,
  payload: value,
});

export const sendMoneyAction = value => ({
  type: SEND_MONEY,
  payload: value,
});

export const convertAction = value => ({
  type: CONVERT_FROM,
  payload: value,
});

export const saveWalletData = value => ({
  type: WALLET_DATA,
  payload: { wallets: value },
});

// wallet thunk functions
export const addNewWallet = (payload, cb) => {
  return dispatch => {
    dispatch({ type: WALLET_ACTION, payload: { wLoading: true } });
    const url = '/wallet/create';
    api
      .post(url, payload, 'wallet')
      .then(res => {
        // console.log('New wallet: ', res);
        dispatch({
          type: WALLET_ACTION,
          payload: { wLoading: false, wMessage: res.data?.message },
        });
        cb(1);
      })
      .catch(error => {
        dispatch({
          type: WALLET_ACTION,
          payload: { wLoading: false, wMessage: error.data?.message },
        });
        cb(2);
      });
  };
};

// new local card deposits

export const localDepositViaCard = (payld, cb) => {
  //console.log(payld);
  return dispatch => {
    dispatch({ type: WALLET_TRANSACTION, payload: { transLoading: true } });
    const url = '/wallet/card/deposit';
    const payload = {
      ...payld,
    };
    api
      .post(url, payload)
      .then(res => {
        openNotificationWithIcon(
          'Payment link succesfully generated.',
          'Deposit',
          'success',
        );
        dispatch({
          type: WALLET_TRANSACTION,
          payload: { transLoading: false, transMessage: res.data.message },
        });
        setTimeout(() => {
          window.open(res.data.data.link);
          cb();
        }, 200);
      })
      .catch(error => {
        dispatch({
          type: WALLET_TRANSACTION,
          payload: {
            transLoading: false,
            transMessage: error.data.message || error.message,
          },
        });
        openNotificationWithIconErr(error.data.message, 'Deposit', 'error');
      });
  };
};

// other currencies deposit
export const localDepositViaCardOther = (payld, cb) => {
  //console.log(payld);
  return dispatch => {
    dispatch({ type: WALLET_TRANSACTION, payload: { transLoading: true } });
    const url = 'wallet/global-deposits';
    const payload = {
      ...payld,
    };
    api
      .post(url, payload)
      .then(res => {
        openNotificationWithIcon(
          'Payment link succesfully generated.',
          'Deposit',
          'success',
        );
        dispatch({
          type: WALLET_TRANSACTION,
          payload: { transLoading: false, transMessage: res.data.message },
        });
        setTimeout(() => {
          window.open(res.data?.data?.url);
          cb();
        }, 200);
      })
      .catch(error => {
        dispatch({
          type: WALLET_TRANSACTION,
          payload: {
            transLoading: false,
            transMessage: error.data.message || error.message,
          },
        });
        openNotificationWithIconErr(error.data.message, 'Deposit', 'error');
      });
  };
};

export const fetchTransactionOTP = (payload, cb) => {
  return dispatch => {
    dispatch({ type: FETCH_OTP, payload: { oLoading: true } });
    const url = '/customer/send-transaction-otp';
    api
      .post(url, payload)
      .then(res => {
        // console.log('OTP Message: ', res);
        dispatch({
          type: FETCH_OTP,
          payload: { oLoading: false, oMessage: res.data?.message },
        });
        openNotificationWithIcon(
          res?.data?.message,
          'Transaction OTP',
          `success`,
        );
      })
      .catch(error => {
        dispatch({
          type: FETCH_OTP,
          payload: { oLoading: false, oMessage: error.data?.message },
        });
        openNotificationWithIconErr(
          error?.data?.message,
          'Transaction OTP',
          `error`,
        );
      });
  };
};
