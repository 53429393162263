import React, { useState } from 'react';
import { RiEmotionHappyLine } from "react-icons/ri"
// import { fellngsGf, emojihappy } from 'assets/feedPageAssets';
import { FaTimes } from 'react-icons/fa';
import './emojiPicker.scss'

const EmojiSec = ({ onSelect }) => {
  const [showPicker, setShowPicker] = useState(false);

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const emojis = [
    '😀', '😃', '😄', '😁', '😆', '😅', '🤣', '😂',
    '🙂', '🙃', '😉', '😊', '😇', '🥰', '😍', '🤩', 
    '😝', '🤓', '😡', '🥳', '🫣', '🤗', '🤫', '🙄', 
    '🤕', '🤮', '👏', '👍', '👊', '🥹', '🥲', '😎', 
    '😒', '😞', '😔', '🙁', '😣', '😖', '😢', '😭',
    '😳', '🥵', '🥶', '😨', '😓', '🤗', '🤭', '🫢',
    '🫡', '😑', '😬', '😦', '😲', '🤮', '🤑', '😈',
    '💀', '🫶', '🤲', '🙌', '👎', '🤞', '👌', '🤙',
    '💪', '🖕', '🙏', '🫵', '🗣️', '🙅‍♀️', '🙅', '🙆‍♀️',
    '🙆', '🙋‍♀️', '🙋', '🤷‍♀️', '🤷', '💅', '💃', '👩‍🦯',
    '🧑‍🦯', '🧎‍♀️', '🧎', '🏃‍♀️', '🏃', '🌚', '🌝', '🎉',
    '🩷', '💜', '🧡', '💛', '💚', '🩵', '💙', '❤️‍🩹',
    '🖤', '🩶', '🤎', '💔', '❤️‍🔥', '💗', '💝', '💞',
    '❌', '💯', '❓', '🚫', '❗️', '📢', '✅', '📌' 
    // Add more emojis as needed
  ];

  return (
    <div>
        <RiEmotionHappyLine 
        onClick={togglePicker}
        style={{marginRight:'0px', width: '22px', height: '22px'}}/>
      {/* <button onClick={togglePicker} className=''> <img src={emojihappy} alt='imgIcon' style={{marginRight:'0px'}}/></button> */}
      {showPicker && (
        <>
       
        <div className="emojiPickerBG absolute " style={{width:'35rem', right:'20%', top:'20%'}}>
          {emojis.map((emoji, index) => (
            <span key={index} className='cursor-pointer mx-6' onClick={() => onSelect(emoji)} style={{fontSize:'20px'}}>
              {emoji}
            </span>
          ))}
        </div>
        </>
      )}
    </div>
  );
};

export default EmojiSec;






// import emojiIcon from '../../../../assets/newFeedsImg/firstSecImg/emojihappy.png'
