import React, { useState, useEffect } from 'react';
import { BsFillPlusCircleFill, BsChatLeftDots } from 'react-icons/bs';
import './MiaiIndex.scss';
import Faqicon from 'assets/miai/message-search.svg';
import termsIcon from 'assets/miai/message-shield.svg';
import privacyIcon from 'assets/miai/message-lock.svg';
import MiaiAvatar from 'assets/miai/Miai.svg';

const MiaiSidebar = props => {
  const sidebarChatData = props.chatData?.data?.data.map(item => item);

  const sidebarrChatData = props.chatData?.data?.data || [];
  const firstChatId = sidebarrChatData?.length > 0 ? sidebarChatData[0].id : '';

  useEffect(() => {
    if (firstChatId && props.display !== 'NewChat') {
      props.setChatId(firstChatId);
    }
  }, [firstChatId]);

  localStorage.setItem('firstChatId', firstChatId);

  const HandlenewClick = () => {
    props.setShow(false);
    props.setDisplay('NewChat');
    props.setnewchat(true);
    props.setChatId('');
    props.setClickedBtm(true);
    props.setMessages(prevMessages => []);
  };

  const handleLoader = () => {
    props.setTyping(true);
    setTimeout(() => {
      props.setTyping(false);
    }, 2000);
  };

  useEffect(() => {
    if (props.newchat) {
      props.setChatId(firstChatId);
    }
  }, [sidebarrChatData || props.isLoading]);

  return (
    <div className="MiaiSidebar">
      <div className="MiaiSidebar__top">
        <MiaiNav isDarkMode={props.isDarkMode} />
      </div>
      <div className="MiaiSidebar__ChatTitlesContainer">
        <div
          className={`MiaiSidebar__top__Header ${
            props.isDarkMode ? 'darkModecolor' : 'lightModecolor'
          }`}
          onClick={HandlenewClick}
        >
          <BsFillPlusCircleFill />
          <p
            className={` ${
              props.isDarkMode ? 'darkModecolor' : 'lightModecolor'
            }`}
            style={{ fontSize: '13px', fontWeight: '600' }}
          >
            Generate new prompt
          </p>
        </div>{' '}
        <ul>
          {sidebarChatData &&
            sidebarChatData.map((data, index) => {
              // Set a maximum width for the title
              const maxTitleWidth = 30;

              const truncatedTitle =
                data.title?.length > maxTitleWidth
                  ? `${data.title.substring(0, maxTitleWidth)}...`
                  : data.title;

              return (
                <li
                  className={`${
                    props.chatId === data.id
                      ? `activeLi`
                      : props.isDarkMode
                      ? 'notactiveLi darkModecolor'
                      : 'notactiveLi lightModecolor'
                  }`}
                  onClick={() => {
                    props.setChatId(data.id);
                    props.setnewchat(false);
                    props.setClickedBtm(true);
                    props.setDisplay('history');
                    localStorage.setItem('clickedBtm', props.clickedBtm);
                    localStorage.setItem('chatId', data.id);
                    props.handleChatItemClick(data.id);
                    props.setTypewriter(false);
                    props.setShow(false);
                    props.setMessages([]);
                    handleLoader();
                  }}
                  key={index}
                >
                  <BsChatLeftDots />
                  {truncatedTitle}
                </li>
              );
            })}
        </ul>
      </div>

      <div className="MiaiSidebar__Bottom">
        <div className="MiaiSidebar__Bottom__helpContainer">
          {/* <p>HELP</p> */}
          <ul>
            <li className="helpcolor">Help</li>
            {/* <li
              className={` ${
                props.isDarkMode ? 'darkModecolor' : 'lightModecolor'
              }`}
            >
              <img
                src={Faqicon}
                alt=""
                className={` ${props.isDarkMode ? 'iconbg' : ''}`}
              />
              FAQs
            </li> */}
            <li
              onClick={() => {
                window.open('https://wevesti.com/miai/tos', '_blank');
              }}
              style={{ cursor: 'pointer' }}
              className={` ${
                props.isDarkMode ? 'darkModecolor' : 'lightModecolor'
              }`}
            >
              <img
                src={termsIcon}
                alt=""
                className={` ${props.isDarkMode ? 'iconbg' : ''}`}
              />
              Terms of Use
            </li>
            <li
              onClick={() => {
                window.open(
                  'https://wevesti.com/miai/privacy-policy',
                  '_blank',
                );
              }}
              style={{ cursor: 'pointer' }}
              className={` ${
                props.isDarkMode ? 'darkModecolor' : 'lightModecolor'
              }`}
            >
              <img
                src={privacyIcon}
                alt=""
                className={` ${props.isDarkMode ? 'iconbg' : ''}`}
              />
              Privacy Policy
            </li>
          </ul>
        </div>

        <div className="MiaiSidebar__Bottom__BottomContainer">
          <p>Upgrade to Miai ™ Plus now for advanced features</p>
          {/* <p>$25 per month</p> */}
          <p>Coming soon</p>
        </div>
      </div>
    </div>
  );
};

export default MiaiSidebar;

// ${
//       props.isDarkMode ? 'darksidebar' : 'lightModecolor'
//     }

export const MiaiNav = props => {
  return (
    <nav className={`MiaiSidebar__top__nav`}>
      {/* <img src={MiaiAvatar} alt="" /> */}

      <div
        style={{
          background: '#518C36',
          width: '30px',
          height: '30px',
          // padding: '10px 10px 0px 10px',
          borderRadius: '50%',
        }}
      >
        <img
          style={{ width: '100%', height: '100%' }}
          src={MiaiAvatar}
          alt=""
        />
      </div>

      <div
        // className="MiaiSidebar__top__nav__text"
        className={`MiaiSidebar__top__nav__text
            `}
      >
        <p
          className={`MiaiSidebar__top__nav ${
            props.isDarkMode ? 'darkModecolor' : 'lightModecolor'
          }`}
        >
          Miai ™ (Pronounced Maya)
          {/* Miai
          <sup style={{ fontSize: '14px', marginLeft: '-2px' }}>
            &trade;
          </sup>{' '}
          (Pronounced Maya) */}
        </p>
        <p
          className={`MiaiSidebar__top__nav ${
            props.isDarkMode ? 'darkModecolor' : 'lightModecolor'
          }`}
        >
          Your AI migration assistant.
        </p>
      </div>
    </nav>
  );
};
