import {
  FETCH_SUBMITTED_VISA_FORM,
  UPDATE_UPLOADED_FILES,
  VISA_TYPE_VALUES,
  HANDLE_COMPLETED_SECTION,
  H1B_SECTION_RES,
} from 'appRedux/constants';
import api from 'appRedux/api';
import pathwayconfig from 'config.js';

// import { useSelector } from 'react-redux';

// useEffect(() => {
//   const createForm = async () => {
//     var url =
//       'https://pathwayapi.wevesti.com/api/v1/pathway/submit-visa-documents';
// const formData = new FormData();

// formData.append('email', userData?.email);
// formData.append('userId', userData?.id);
// formData.append('visaType', props.vType);

// try {
//   const response = await fetch(url, {
//     method: 'POST',
//     body: formData,
//   });
//   const data = await response.json();
//   //  setStep(7);
// } catch (error) {
//   console.error('Error creating file:', error);
// }
//   };
// }, []);
// formData.append('firstName', userData?.firstName);

export const createForm = async (id, email, vType, firstName) => {
  // const url = '/customer/set-transaction-pin';
  const url = `${pathwayconfig.baseURL}/pathway/submit-visa-documents`;
  const formData = new FormData();

  formData.append('firstName', firstName);
  formData.append('email', email);
  formData.append('userId', id);
  formData.append('visaType', vType);

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });
    const data = await response.json();
    window.location.reload();
    //  setStep(7);
  } catch (error) {
    console.error('Error creating file:', error);
  }
};

export const fetchViasForms = (id, email, visatype, firstName) => {
  // console.log(email, 'email');

  return dispatch => {
    dispatch({
      type: FETCH_SUBMITTED_VISA_FORM,
      payload: { submittedForms: '', loading: true },
    });
    const url = `${pathwayconfig.baseURL}/pathway/fetch-single-visa-documents?email=${email}&visaType=${visatype}`;
    api
      .get(url)
      .then(res => {
        dispatch({
          type: FETCH_SUBMITTED_VISA_FORM,
          payload: { loading: false, submittedForms: res.data },
        });
        // cb();
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 404' && email) {
          dispatch(() => {
            dispatch(createForm(id, email, visatype, firstName));
          });
        }

        dispatch({
          type: FETCH_SUBMITTED_VISA_FORM,
          payload: { loading: false, message: error },
        });
      });
  };
};

export const fetchAllViasForms = id => {
  // const userD = useSelector(state => state.auth?.userData);

  return dispatch => {
    dispatch({
      type: FETCH_SUBMITTED_VISA_FORM,
      payload: { submittedForms: '', loading: true },
    });
    const url = `${pathwayconfig.baseURL}/pathway/fetch-single-visa-documents?userId=${id}`;
    api
      .get(url)
      .then(res => {
        console.log(res);
        dispatch({
          type: FETCH_SUBMITTED_VISA_FORM,
          payload: { loading: false, submittedForms: res.data },
        });
        // cb();
      })
      .catch(error => {
        dispatch({
          type: FETCH_SUBMITTED_VISA_FORM,
          payload: { loading: false, message: error },
        });
      });
  };
};

export const handlevisaTypes = (visaType, visaAbbr) => ({
  type: VISA_TYPE_VALUES,
  payload: { visaType, visaAbbr },
});

export const updateUploadedFiles = files => ({
  type: UPDATE_UPLOADED_FILES,
  payload: files,
});

export const handleSections = (requiredSections, optionalSections) => ({
  type: HANDLE_COMPLETED_SECTION,
  payload: { required: requiredSections, optional: optionalSections },
});

export const handleH1bFormRes = (degree, fillingCompany) => ({
  type: H1B_SECTION_RES,
  payload: { degree, fillingCompany },
});
