import React, { useEffect, useState } from 'react';
import '../../../containers/Admin/Admin.css';
import introcards from 'assets/TourGuide/Cards/IntrocardTour.svg';
import tooltip from 'assets/TourGuide/Tooltip.svg';
import { cardTourFeatures } from 'TourGuide/data';
import './intro.scss';
import { LiaTimesSolid } from 'react-icons/lia';
import { AddVcard } from '../AddVcard/AddVcard';
import { FundCardTour } from '../Fundcard/Fund';
import { MakeTransactionTour } from '../MakeTransaction/MakeTransaction';
import { RequestGGcard } from '../RequestPhysicalcard/RequestPhysicalcard';
import { navigate } from '@reach/router';

export const IntroCardGuide = props => {
  const [step, setStep] = useState(0);

  // const handleFeatureClick = link => {
  //   setStep(parseInt(link, 10) - 1);
  // };

  const handleFeatureClick = link => {
    if (link === '2') {
      navigate(`/bank`);
    }
    if (link === '5') {
      // navigate(`/cardsdetails`);
      setStep(parseInt(link, 10) - 1);
    } else {
      // Set the step based on the link
      setStep(parseInt(link, 10) - 1);
    }
  };

  switch (step) {
    case 0:
      return (
        <Intro setStep={setStep} handleFeatureClick={handleFeatureClick} />
      );
    case 1:
      return <p></p>;
    case 2:
      return <AddVcard setStep={setStep} />;
    case 3:
      return <FundStep1 setStep={setStep} />;
    case 4:
      return <MakeTransactionTour setStep={setStep} />;
    case 5:
      return <RequestGGcard setStep={setStep} />;
    default:
  }
};

const Intro = props => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '20%', height: '100%' }}></div>

        <div className="  w-full h-full">
          <div className="myWalletTour" style={{ marginTop: '10rem' }}>
            <img src={introcards} alt="" style={{ width: '100%' }} />
            {/* <div className="tourWalletBg"></div> */}
            <div className="TourArticleCContainer">
              <img
                className="TourArticleCContainer__tooltip"
                src={tooltip}
                alt=""
              />

              <div className="TourArticle">
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                        Cards that work everywhere!{' '}
                      </h1>
                      <p style={{ color: '#2B5219', fontSize: '14px' }}>
                        Select the wallet feature you want to learn about{' '}
                      </p>
                    </div>
                    <p
                      style={{
                        background: '#E2E2EA',
                        padding: '6px',
                        borderRadius: '6px',
                      }}
                    >
                      <LiaTimesSolid />
                    </p>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop: '30px',
                    }}
                  >
                    {cardTourFeatures.map(feature => {
                      return (
                        <div
                          key={feature.link}
                          className=""
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '5px',
                            cursor: 'pointer',
                          }}
                          onClick={() => props.handleFeatureClick(feature.link)}
                        >
                          <img className="tourIcons" src={feature.img} alt="" />
                          <p className="BankTourFeaturetitle">
                            {feature.title}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FundStep1 = props => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '20%', height: '100%' }}></div>

        <div className="  w-full h-full">
          <div className="myWalletTour">
            <div className="myWalletTour__wallet">
              <img src={introcards} alt="" style={{ marginTop: '10rem' }} />
            </div>

            <div className="TourArticleCContainer">
              <img
                className="TourArticleCContainer__tooltip"
                src={tooltip}
                alt=""
              />

              <div className="TourArticle">
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                        Fund Your Virtual Card
                      </h1>
                      <p style={{ color: '#2B5219', fontSize: '14px' }}>
                        Tap on any of the cards you have created to see more
                        information about it.
                      </p>
                    </div>
                    <p
                      style={{
                        background: '#E2E2EA',
                        padding: '6px',
                        borderRadius: '6px',
                      }}
                    >
                      <LiaTimesSolid />
                    </p>
                  </div>

                  <div
                    style={{
                      marginTop: '30px',
                    }}
                  >
                    <h1>Video</h1>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop: '30px',
                    }}
                  >
                    <p style={{ color: '#67a948' }}>{` 1 of 5`} </p>

                    <ul className="PathwayTourArticleCContainer__tracker">
                      <li className="PathwayTourArticleCContainer__active"></li>
                      <li className="PathwayTourArticleCContainer__inactive"></li>
                      <li className="PathwayTourArticleCContainer__inactive"></li>
                      <li className="PathwayTourArticleCContainer__inactive"></li>
                      <li className="PathwayTourArticleCContainer__inactive"></li>
                    </ul>

                    <div
                      onClick={() => {
                        navigate(`/cardsdetails`);
                      }}
                      className="PathwayTourArticleCContainer__next"
                    >
                      Next
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
