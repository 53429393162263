import React from 'react';
import './stepback.scss';

export const Stepback = props => {
  return (
    <span className="stepback-container" onClick={() => props.onClick()}>
      <i style={{ color: '#67a948' }} className="fas fa-arrow-left"></i>
      <p> Go Back </p>
    </span>
  );
};
