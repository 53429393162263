import { sendDeletionCode, deleteProfile } from 'appRedux/actions/profile';
import { Platformbutton } from 'components/common/button/button';
import { Success } from 'components/common/success/success';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import errorsvg from 'assets/errorsvg.svg';
import speaker from 'assets/speaker.svg';
import 'components/referal/referal.scss';
import { Newprompt } from 'components/common/prompt/prompt';
import {
  ExtraReferralbox,
  Referall,
  Referralboxx,
} from 'components/referal/referal';
import { Backk } from 'components/common/back/back';
import { transStatus } from 'helpers/utils';
import moment from 'moment';
import api from 'appRedux/api';
import Loader from 'components/Loader';
import { message, Table } from 'antd';
import { ShareSocial } from 'react-share-social';

const ReferCallers = props => {
  const [step, setStep] = useState(0);
  const [total, setTotal] = useState(0);
  const [refCode, setRefCode] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const userData = useSelector(state => state.auth?.userData);

  // console.log(userData);

  const generateRefCode = async () => {
    const data = { email: userData.email };
    const url = `/pathway/generate-clarity-coupon`;

    setLoading(true);
    try {
      const res = await api.post(url, data, 'pathway');
      // console.log(res);
      setRefCode(res.data?.clarityCouponHistory?.link);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchReferrals = async () => {
    const email = userData.email;
    const url = `/pathway/fetch-clarity-coupon?email=${email}`;

    setLoading(true);
    try {
      const res = await api.get(url, '', 'pathway');
      console.log(res);
      setReferrals(res.data);
      setTotal(res.data?.clarityCouponHistory?.pagination.total);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const requestBonus = async () => {
    const url = `/referral/request-bonus`;
    const data = { userId: userData.id };
    // console.log(data);
    setLoading(true);
    try {
      const res = await api.post(url, data, 'pathway');
      // console.log(res);
      setStep(2);
    } catch (error) {
      setStep(3);
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    generateRefCode();
    fetchReferrals();
  }, []);

  // console.log(referrals);
  // console.log(refCode);

  const columns = [
    // {
    //   title: 'S/N',
    //   dataIndex: 'serial',
    //   render: (text, record, rowIndex) => rowIndex + 1,
    //   width: 20,
    // },
    {
      title: 'Fullname',
      dataIndex: 'fullname',
      render: (text, record) => (
        <div>{`${record.referee?.firstName} ${record.referee?.lastName}`}</div>
      ),
      width: 80,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text, record) => <div>{record.referee?.email}</div>,
      width: 80,
    },
    {
      title: 'Date',
      index: 'createdAt',
      render: (text, record) => {
        return moment(record.createdAt).format('lll');
      },
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 60,
      render: (text, record) => {
        let color;
        let statusText;

        if (record?.hasBeenPaid) {
          color = '#117439';
          statusText = 'Success';
        } else {
          color = '#f39c0a';
          statusText = 'Pending';
        }

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                display: 'inline-block',
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: color,
                marginRight: '4px', // Adjust spacing as needed
              }}
            />
            <span>{statusText}</span>
          </div>
        );
      },
    },
  ];

  const style = {
    root: {
      background: '#3e6f26',
      borderRadius: 3,
      border: 0,
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    copyContainer: {
      display: 'none',
      border: '1px solid #3e6f26',
      background: '#3e6f26',
    },
    iconContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    title: {
      color: 'white',
      // fontStyle: 'italic',
    },
  };

  var _renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <Newprompt img={speaker}>
            <div className="innerprompt">
              <div className="innerprompt-top">
                <p>Earn $100</p>
                <p>Earn $100 when you refer 5 people to book a clarity call</p>
              </div>

              {loading ? (
                <Loader />
              ) : (
                <>
                  <Referall link={refCode} />
                  <ShareSocial
                    title={'Share your Referal Link'}
                    url={refCode}
                    socialTypes={['facebook', 'twitter', 'reddit', 'linkedin']}
                    style={style}
                  />
                </>
              )}

              <Referralboxx
                totalReferred={referrals?.totalReferred}
                ReferralAmount={referrals?.totalBonus / 100}
                onClick={() => setStep(1)}
              />

              <Platformbutton
                type="refusers"
                name="Claim Referal Bonus"
                click={() => requestBonus()}
              />
            </div>
          </Newprompt>
        );
      case 1:
        return (
          <>
            <Backk title="Referral List" click={() => setStep(0)} />
            <div className="mt-4"></div>
            <div className="innerprompt">
              <ExtraReferralbox
                totalReferred={referrals?.totalReferred}
                ReferralAmount={referrals?.totalBonus / 100}
                onClick={() => requestBonus()}
              />
              <div className="innerprompt-top">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={referrals?.clarityCouponHistory?.data}
                  rowkey="id"
                  pagination={{
                    size: 'small',
                    current: pageNumber,
                    total: total,
                    pageSize: 5,
                    onChange: page => {
                      setPageNumber(page);
                    },
                  }}
                  scroll={{
                    x: 1000,
                    y: 500,
                  }}
                ></Table>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <Success
            title="Successful"
            subtitle={
              'Your request has been submitted and your Referral Reward will be remitted to your Vesti wallet upon approval within 24-48 hours.'
            }
            onClick={() => props.closeModal()}
            button="Okay, Thank you."
          />
        );

      case 3:
        return (
          <Success
            image={errorsvg}
            type="error"
            title="Failed"
            subtitle={props.message}
            button="Try Again"
            onClick={() => setStep(1)}
          />
        );
      default:
        return <>default</>;
    }
  };

  return <>{loading ? <Loader /> : _renderSteps()}</>;
};

const Trow = props => {
  // eslint-disable-next-line
  const newDate = moment(props.date).format('ll');

  return (
    <tr onClick={() => props.click(props.index)}>
      <td>{props.index + 1}</td>
      <td className={`amount`}>{props.name}</td>
      <td>{newDate}</td>
      <td>
        <div className={`status`}>
          <p className={`statusIcon --${transStatus(props.status)} `}></p>{' '}
          {transStatus(props.status)}
        </div>
      </td>
    </tr>
  );
};

const mapStateToProps = ({ profile }) => {
  const { singleProfile, loading, message } = profile;
  return {
    singleProfile,
    profile,
    loading,
    message,
  };
};

export default connect(mapStateToProps, {
  sendDeletionCode,
  deleteProfile,
})(ReferCallers);
