import React from "react";
import reward from "assets/rewardpop.svg"
import { Newprompt } from "components/common/prompt/prompt";
import { Referal } from "../../referal/referal";
import "./vestireward.scss"
import { Rewards } from "helpers/data";
import { Numberedcheck } from "../listcheck/listcheck";
import { AlertInfo } from "../alertboxes/alertboxes";
export const Vestireward = (props)=> {

    var link = localStorage.getItem('userData')
    return(
        <Newprompt img={reward}>
            {/* <div className="vestireward__header">
                <p>Claim {props.amount}</p>
                {
                    props.children
                }
            </div> */}
            <div className="vestireward__top">
                {props.children}
                <div className="mb-2"></div>
                {
                    Rewards?.map((item,index)=> (
                        <Numberedcheck
                            key={index+1}
                            index={index}
                            body={item}
                        />
                    ))
                }
                <div className="mb-4"></div>
                <Referal
                    link={JSON.parse(link)?.ReferalLink}
                />
                <AlertInfo
                    body="You can refer as many users as possible but you are eligible to claim the price for 5 users only. If you feel you can do up to 500 referrals per week, send an email to help@wevesti.com to get started"
                />
            </div>
            {/* <Referal
                link={JSON.parse(link).ReferalLink}
            /> */}
        </Newprompt>
    )
}