import React, { useState } from 'react';
import { Titlesubtitle } from '../../common/titlesubtitle/titlesubtitle';
import avi from '../../../assets/avi.png';
import errorsvg from '../../../assets/errorsvg.svg';
import { Platformbutton } from 'components/common/button/button';
import { connect } from 'react-redux';
import '../../../index.css';
import { Success } from 'components/common/success/success';
import { claimNGNEmail } from 'appRedux/actions/nairatrans';
import Inputfloat from 'components/common/inputs/inputfloat';

const NgnClaim = props => {
  const [code, setCode] = useState('');
  const [step, setStep] = useState(0);
  // console.log(props)

  var setInput = e => {
    setCode(e.target.value);
  };

  const transactionData = {
    transferId: props.userData.hasPendingTransfer?.transferId,
    amount: props.userData.hasPendingTransfer?.amount,
    currency: props.userData.hasPendingTransfer?.currency,
    senderId: props.userData.hasPendingTransfer?.senderId,
    receiverId: props.userData.hasPendingTransfer?.receiverId,
    transferCode: code,
  };

  const Claim = () => {
    var data = transactionData;
    props.claimNGNEmail(data, value => {
      props.refetch();
      setStep(value);
    });
  };

  const _render = () => {
    switch (step) {
      case 0:
        return (
          <>
            <Titlesubtitle
              title="PENDING"
              subtitle="Enter your transfer code to finalize this transaction."
            />
            <EmailCard
              title={`${
                props.userData.hasPendingTransfer?.senderName
              } sent you ₦${(
                props.userData.hasPendingTransfer?.amount / 100
              ).toLocaleString('en-us')}`}
              content={`${
                props.userData.hasPendingTransfer?.senderName
              } sent you a sum of ₦${(
                props.userData.hasPendingTransfer?.amount / 100
              ).toLocaleString('en-us')}
                            enter the code to claim your cash.`}
            />
            <div className="sendemail-pin">
              <Inputfloat
                type="text"
                name="transferCode"
                label="Transfer Code"
                value={code}
                disabled={false}
                placeholder="Transfer Code"
                onChange={setInput}
              />
            </div>
            <Platformbutton
              disabled={!code || props.transLoading ? true : false}
              type="normal"
              name={`Redeem ₦${props.userData.hasPendingTransfer?.amount /
                100}`}
              click={() => Claim()}
            />
          </>
        );
      case 1:
        return (
          <Success
            title="Claim Successful"
            subtitle={props.transMessage}
            button="Okay, Thank You"
            onClick={() => {
              props.refetch();
              props.closeModal();
            }}
          />
        );
      case 2:
        return (
          <Success
            image={errorsvg}
            type="error"
            title="Claim Failed"
            subtitle={props.transMessage}
            button="Try Again"
            onClick={() => {
              props.refetch();
              setStep(0);
            }}
          />
        );
      default:
        return <></>;
    }
  };

  return _render();
};

const mapStateToProps = ({ transactions }) => {
  const { transMessage, transLoading, loading, transSucData } = transactions;
  return {
    transMessage,
    loading,
    transLoading,
    transSucData,
  };
};

const mapDispatchToProps = { claimNGNEmail };

export default connect(mapStateToProps, mapDispatchToProps)(NgnClaim);

const EmailCard = props => {
  return (
    <div className="email_card">
      <img src={avi} alt="" />
      <div className="email_cardcontent">
        <p className="email_cardtitle">{props.title}</p>
        <p className="email_cardbody">{props.content}</p>
      </div>
    </div>
  );
};
