import { RadioOption } from 'components/common/radiobutton/radiobutton';
import { useAmount, useStep } from 'helpers/hooks';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  fetchAllCountries,
  fetchAllStates,
  handleError,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import './styles.scss';
import { Amountinputcurrency } from 'components/common/inputs/amountinput';
import Inputfloat from 'components/common/inputs/inputfloat';
import {
  IconOption,
  IconSingleValue,
  Singleselect,
} from 'components/common/inputs/singleselect';
import { Platformbutton } from 'components/common/button/button';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import {
  Shortinfo,
  ShortinfoSavings,
  Shortinfonew,
} from 'components/common/shortinfo/shortinfo';
import {
  removeCommaAmount,
  __renderRouting,
  getUsdAchRate,
  formatterUSD,
  getSelectedAccount,
  getCurrency,
  errorMessage,
} from 'helpers/utils';
import { Compeletetrans, Usdbalance } from './fbo';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Success, Transuccess } from 'components/common/success/success';
import errorsvg from 'assets/newerror.svg';
import { usdAccountCodes } from 'helpers/data';
import {
  verifyUserRouting,
  withdrawFboUSD,
  addUsdBeneficiary,
  fetchUsdBeneficiary,
} from 'appRedux/actions/usdtrans';
import {
  AlertError,
  AlertInfo,
  AlertSuccess,
} from 'components/common/alertboxes/alertboxes';
// import { Checkbox } from 'components/common/inputs/checkbox';
import { VERIFY_USDACCOUNT } from 'appRedux/constants';
import { Equivalence } from '../equivalence';
import { radio_achusd, radio_usersusd } from 'assets/assets';
import Error from 'assets/newerror.svg';
import dummyAvatar from 'assets/dummy-avatar.png';
import { Littlebalance } from '../littlebalance/littlebalance';
// import { navigate } from '@reach/router';
import infob from 'assets/infob.svg';
import Toggle from 'react-toggle';
import { Smallempty } from 'components/common/empty/empty';
import { transferNGNCash } from 'appRedux/actions/nairatrans';
import api from 'appRedux/api';
import { Form } from 'antd';
import { Transreview } from 'components/common/transactionreview/review';
import DividedPinInput from 'components/common/DividedPinInput';
import Loader from 'components/Loader';
import { navigate } from '@reach/router';
import _ from 'underscore';
import { fetchTransactionOTP } from 'appRedux/actions/wallets';
// this is an ACH withdrawal

const Fbowithdrawal = props => {
  const dispatch = useDispatch();
  // console.log(props);
  const [state, setState] = useState({
    routing_number: '',
    account_number: '',
    billing_address: '',
    country: '',
    account_holder_name: '',
    postalCode: '',
    district: '',
    city: '',
    bank_name: '',
    bank_city: '',
    bank_address: '',
    bank_state: '',
    bank_zip: '',
    description: '',
    type: '',
    userName: '',
    userId: '',
  });
  const [loading, setLoading] = useState(false);
  const [recipient, setRecipient] = useState(null);
  const [pin, setPin] = useState('');
  const [transMessage, setTransMessage] = useState('');
  const { amount, handleAmount } = useAmount(0);
  const { step, nextStep, previousStep, setStep } = useStep(0);

  var setInput = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  var handleSelect = (name, value) => {
    setState({ ...state, [name]: value });
  };
  const fetchRecipient = () => {
    setLoading(true);
    api
      .get(`/customer/search?username=${state?.userName}`)
      .then(res => {
        setRecipient(res?.data?.data[0]);
        // console.log(res);
        setLoading(false);
      })
      .catch(
        err =>
          openNotificationWithIconErr(
            `Verification`,
            `${err?.message}`,
            'error',
          ),
        setLoading(false),
      );
  };

  var setTransactionPin = value => {
    setPin(value);
  };

  const startSubmit = () => {
    setState({ submitting: true });
  };
  const stopSubmit = () => {
    setState({ submitting: false });
  };

  const handleTransferCash = () => {
    setLoading(true);
    const url = '/wallet/vesti-to-vesti-transfer';
    const payload = {
      amount: removeCommaAmount(amount) * 100,
      userId: recipient?.id,
      currency: 'USD',
      transactionOtp: pin,
    };

    api
      .post(url, payload)
      .then(({ data }) => {
        if (data.data) {
          navigate('/bank');
          // Show success card
          setStep(11);
        } else {
          handleError(_.isEmpty(data.message) ? 'Network Error' : data.message);
          // Show success card
          setStep(11);
        }
        props.refetch();
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (error.data?.errors) {
          openNotificationWithIconErr(
            errorMessage(error),
            'Cash Transfer',
            'error',
          );
          setStep(12);
        } else {
          openNotificationWithIconErr(
            errorMessage(error),
            'Cash Transfer',
            'error',
          );
          setTransMessage(errorMessage(error));
          // Show error card
          setStep(12);
        }
      })
      .finally(() => stopSubmit());
  };

  useEffect(() => {
    props.fetchAllCountries();
    // eslint-disable-next-line
  }, []);

  var handleSelectBene = value => {
    // var data = props.usdAccounts.filter(item => item.value === value.value)
    var data = props.usdAccounts[value];
    getSelectedAccount(setState, data, props.countries, props.states);
  };

  useEffect(() => {
    state.country &&
      props.fetchAllStates({
        country: state.country && state.country.label.toLowerCase(),
      });
    // eslint-disable-next-line
  }, [state.country]);

  var __renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <Choosetype
            balance={props.balance}
            continue={nextStep}
            closeModal={props.closeModal}
            step={step}
            setStep={setStep}
          />
        );
      case 1:
        return (
          <Withstepone
            balance={props.balance}
            state={state}
            setState={setState}
            amount={amount}
            handleAmount={handleAmount}
            continue={nextStep}
            back={previousStep}
            setInput={setInput}
            handleSelect={handleSelect}
          />
        );
      case 2:
        return (
          <Withsteptwo
            handleSelectBene={handleSelectBene}
            usdAccounts={props.usdAccounts}
            balance={props.balance}
            state={state}
            setState={setState}
            amount={amount}
            handleAmount={handleAmount}
            verifyAccount={props.verifyAccount}
            continue={nextStep}
            back={previousStep}
            setInput={setInput}
            handleSelect={handleSelect}
            verifyUserRouting={props.verifyUserRouting}
            verLoading={props.verLoading}
          />
        );
      case 3:
        return (
          <Bankaddress
            balance={props.balance}
            states={props.states}
            countries={props.countries}
            state={state}
            setState={setState}
            amount={amount}
            handleAmount={handleAmount}
            continue={nextStep}
            back={previousStep}
            setInput={setInput}
            handleSelect={handleSelect}
          />
        );
      case 4:
        return (
          <Locationaddress
            balance={props.balance}
            states={props.states}
            countries={props.countries}
            state={state}
            setState={setState}
            amount={amount}
            handleAmount={handleAmount}
            continue={nextStep}
            back={previousStep}
            setInput={setInput}
            handleSelect={handleSelect}
          />
        );
      case 5:
        return (
          <Compeletetrans
            balance={props.balance}
            data={{
              ...state,
              fee:
                state?.type === 'ROUTING_NUMBER'
                  ? formatterUSD.format(
                      getUsdAchRate(removeCommaAmount(amount)),
                    )
                  : '35',
              total:
                state?.type === 'ROUTING_NUMBER'
                  ? (
                      removeCommaAmount(amount) +
                      parseFloat(getUsdAchRate(removeCommaAmount(amount)))
                    )
                      .toFixed(2)
                      .toLocaleString('en-us')
                  : (removeCommaAmount(amount) + 35)
                      .toFixed(2)
                      .toLocaleString('en-us'),
              amount: removeCommaAmount(amount).toLocaleString('en-us'),
              amountInCents: removeCommaAmount(amount) * 100,
              country: state.country.value,
              bank_state: state.bank_state.value,
              city: state.city.value,
            }}
            back={previousStep}
            loading={props.transLoading}
            handlePin={setPin}
            finish={() =>
              props.withdrawFboUSD(
                {
                  ...state,
                  amountInCents: removeCommaAmount(amount) * 100,
                  country: state.country.value,
                  bank_state: state.bank_state.value,
                  city: state.city.value,
                  category: state?.type === 'ROUTING_NUMBER' ? 'ACH' : 'WIRE',
                  charges: +(
                    formatterUSD.format(
                      getUsdAchRate(removeCommaAmount(amount)),
                    ) * 100
                  ),
                  transactionOtp: pin,
                },
                value => setStep(value),
              )
            }
            // finish ={()=> props.addUsdBeneficiary(
            //     {...state, country:state.country.value, bank_state:state.bank_state.value,
            //     city:state.city.value,type:'ACH'}
            // )}
            // finish={()=> console.log( {...state, amountInCents:(removeCommaAmount(amount))*100, country:state.country.value, bank_state:state.bank_state.value,
            //  city:state.city.value, charges:parseInt(getUsdWireRate(removeCommaAmount(amount))), transactionOtp:pin})}
          />
        );
      case 6:
        return (
          <Success
            title="Transaction Initiated"
            subtitle={props.transMessage}
            button="Save Beneficiary"
            onClick={() =>
              props.addUsdBeneficiary({
                ...state,
                country: state.country.value,
                bank_state: state.bank_state.value,
                city: state.city.value,
                type: 'ACH',
              })
            }
            secondBtnText="Okay, thank you"
            secondBtn={() => props.closeModal()}
          />
        );
      case 7:
        return (
          <Success
            image={errorsvg}
            title={`Failed`}
            subtitle={props.transMessage}
            onClick={() => setStep(1)}
            button="Try Again"
            type="error"
          />
        );
      case 8:
        return (
          <Enteramount
            amount={amount}
            handleAmount={handleAmount}
            step={step}
            setStep={setStep}
            user={props.user}
            balance={props.balance}
            closeModal={props.closeModal}
          />
        );
      case 9:
        return (
          <Recipient
            balance={props.balance}
            setStep={setStep}
            step={step}
            state={state}
            setState={setState}
            amount={amount}
            handleAmount={handleAmount}
            recipient={recipient}
            setRecipient={setRecipient}
            fetchRecipient={fetchRecipient}
            loading={loading}
            setLoading={setLoading}
          />
        );
      case 10:
        return (
          <Finalize
            setStep={setStep}
            step={step}
            state={state}
            setState={setState}
            // loading={props.transLoading}
            loading={loading}
            handleTransferCash={handleTransferCash}
            setTransactionpin={setTransactionPin}
            amount={amount}
            dispatch={dispatch}
            handleAmount={handleAmount}
            currency={props.currency}
            recipient={recipient}
            closeModal={props.closeModal}
          />
        );

      case 11:
        return (
          <Transuccess
            url={
              recipient?.profilePictureURL
                ? recipient?.profilePictureURL
                : dummyAvatar
            }
            title={`Successful Transfer to ${recipient?.firstName}`}
            button="Okay, Thank You!!!"
            subtitle={` Your transfer of ${props.currency?.slice(
              0,
              3,
            )} ${amount?.toLocaleString('en-us')}  to ${recipient?.firstName} ${
              recipient?.lastName
            } was successful.`}
            onClick={() => {
              props.onClick();
              // props.refetch();
            }}
          />
        );
      case 12:
        return (
          <Success
            image={Error}
            type="error"
            button="Let Me Try Again"
            title="Transaction Failed"
            subtitle={` Your transfer of ${props.currency.slice(
              0,
              3,
            )} ${amount?.toLocaleString('en-us')} to ${recipient?.firstName} ${
              recipient?.lastName
            } failed, ${transMessage}.`}
            onClick={() => setStep(8)}
          />
        );

      default:
        return <></>;
    }
  };
  useEffect(() => {
    props.fetchUsdBeneficiary();
    // eslint-disable-next-line
  }, []);
  return __renderSteps();
};

const Choosetype = props => {
  // console.log(props);
  const [type, setType] = useState();
  var __renderBtn = () => {
    switch (type) {
      case 'VESTI':
        return (
          <Platformbutton
            name="Continue"
            type="normal"
            click={() => props.setStep(8)}
            // click={() => props.setStep(11)}
          />
        );
      case 'EXTERNAL':
        return (
          <Platformbutton
            name="Continue"
            type="normal"
            click={() => props.continue()}
          />
        );
      default:
        return <></>;
    }
  };
  return (
    <section className="fbo">
      <Titlesubtitle
        // title="Type of Send"
        title="Send Money (Personal USD)"
        subtitle="Select type of money transfer"
      />

      <Littlebalance
        title="USD Balance"
        type="usd"
        currency={'$'}
        amount={props.balance}
      />
      <div className="fbo__col">
        <RadioOption
          image={radio_achusd}
          changed={value => setType(value)}
          id="2"
          isSelected={type === 'EXTERNAL'}
          label="Send/Wire to an account"
          sublabel="You can send money to an external USD account."
          value="EXTERNAL"
        />
        <RadioOption
          image={radio_usersusd}
          changed={value => setType(value)}
          id="0"
          isSelected={type === 'VESTI'}
          label="Send to a Vesti user"
          sublabel="Send money to a Vesti user if you know their name."
          value="VESTI"
        />
      </div>
      <div className="mb-4"></div>

      {type && __renderBtn()}
    </section>
  );
};

const Withstepone = props => {
  return (
    <div className="fbo__bigcol">
      <Titlesubtitle
        steps="1 of 5"
        title="Send Money"
        subtitle="Choose from our varities of options."
      />

      <div className="fbo__top">
        <Usdbalance balance={props.balance} />
        <div className="fbo__col --list">
          {usdAccountCodes.map((item, index) => ( 
            <div className="fbo__scol">
              <RadioOption
                key={index}
                changed={() =>
                  props.setState({ ...props.state, type: item.value })
                }
                id={index}
                isSelected={props.state.type === item.value}
                label={item.title}
                sublabel={item.subtitle}
                value={item.value}
              />
              {((props.state.type === 'BIC' && item.value === 'BIC') ||
                (props.state.type === 'SWIFT' && item.value === 'SWIFT')) && (
                <AlertInfo body="This feature is currently not available at the moment." />
              )}
            </div>
          ))}
        </div>
      </div>

      <Backcontinue goBack={() => props.back()}>
        <Platformbutton
          type="normal"
          name="Continue"
          disabled={
            (props.state.type && props.state.type === 'ROUTING_NUMBER') ||
            (props.state.type && props.state.type === 'SAME_DAY_ROUTING_NUMBER')
              ? false
              : true
          }
          click={() => props.continue()}
        />
      </Backcontinue>
    </div>
  );
};

const Withsteptwo = props => {
  const { account_number, account_holder_name, routing_number } = props.state;
  const [check, setCheck] = useState(false);
  const [toggle, setToggle] = useState(false);
  var amount = removeCommaAmount(props.amount);
  var error = amount > 5000 || amount > props.balance;
  var dispatch = useDispatch();
  return (
    <div className="fbo__bigcol">
      <Titlesubtitle
        steps="2 of 5"
        title="Send money"
        subtitle="Fill in these fields to carry out an ACH transfer."
      />

      <div className="fbo__top">
        <Usdbalance balance={props.balance} />
        <div className="fbo__col">
          <Amountinputcurrency
            type="text"
            currency="$"
            name="amount"
            label="Amount in USD"
            value={
              props.amount === 'NaN' ? 0 : props.amount.toLocaleString('en-US')
            }
            disabled={false}
            placeholder="Enter amount to deposit"
            onChange={props.handleAmount}
            pattern="[0-9,.]*"
            invalid={removeCommaAmount(props.amount) > 5000 && 'true'}
            error={
              removeCommaAmount(props.amount) > 5000 &&
              'Amount should be less than $5,000'
            }
          >
            <div className="mb-2"></div>
            {removeCommaAmount(props.amount) > 0 && (
              <Equivalence
                first={`Includes 1.3% of $${removeCommaAmount(
                  props.amount,
                ).toLocaleString('en-us')}`}
                second={` `}
                amount={' '}
                equal={
                  'Total = $' +
                  formatterUSD.format(
                    (
                      getUsdAchRate(removeCommaAmount(props.amount)) +
                      removeCommaAmount(props.amount)
                    ).toFixed(2),
                  )
                }
              />
            )}
          </Amountinputcurrency>
          <div className="fbo__scol">
            {toggle &&
              (props.usdAccounts?.length > 0 ? (
                <Singleselect
                  options={(props.usdAccounts ?? []).map((item, id) => ({
                    id: id,
                    label:
                      item.accountHolderName?.split(' ')[0] +
                      ' - ' +
                      item?.bankName,
                    value: item.accountHolderName,
                  }))}
                  onChange={value => props.handleSelectBene(value.id)}
                  placeholder="Select Beneficiary"
                />
              ) : (
                <Smallempty subtitle="No Saved Beneficiaries" />
              ))}
            <span className="fbo__inputrow --btwn">
              <p>I have saved beneficiaries</p>
              <Toggle
                defaultChecked={toggle}
                icons={false}
                onChange={() => setToggle(!toggle)}
              />
            </span>
          </div>
          <span className="fbo__inputrow">
            <Inputfloat
              type="number"
              name="account_number"
              label="Account Number"
              value={props.state.account_number}
              disabled={false}
              placeholder="Account Number"
              onChange={props.setInput}
              error={
                props.state.account_number?.length >= 5 &&
                props.state.account_number?.length < 8 &&
                'Account Number is too short'
              }
            />
            <Inputfloat
              type="number"
              name="routing_number"
              label={__renderRouting(props.state.type)}
              value={props.state.routing_number}
              disabled={false}
              placeholder="Routing Number"
              onChange={e => {
                // props.setInput(e)
                props.setState({
                  ...props.state,
                  routing_number: e.target.value,
                  bank_name: '',
                });
                dispatch({
                  type: VERIFY_USDACCOUNT,
                  payload: { verLoading: false },
                });
              }}
            />
          </span>
          <Inputfloat
            type="text"
            name={'account_holder_name'}
            label={'account holder name'}
            value={props.state.account_holder_name}
            disabled={false}
            placeholder="Account Holder Name"
            onChange={props.setInput}
          />
        </div>
        <div className="mb-4"></div>

        {/* <Checkbox
          check={check}
          setCheck={setCheck}
          name="usdcheck"
          title="I do not want to verify routing number, Vesti will not be liable for any issue(s) after transfering your funds."
        /> */}
        <div className="mb-2"></div>
        {props.verifyAccount === false ? (
          <AlertError
            body={`We could not verify the routing number ${props.state.routing_number} , cross check and try again.`}
          />
        ) : (
          check === false &&
          props.state.bank_name && (
            <AlertSuccess
              body={`The routing number ${routing_number} belongs to ${props.state.bank_name}, click button below to continue`}
            />
          )
        )}
        {/* <div className="mb-2"></div> */}
        <ShortinfoSavings image={infob}>
          <p>
            Please be advised that a deduction of $5 will occur in the event of
            a transaction failure due to incorrect account details. Kindly
            review and confirm the accuracy of the provided information.Also,
            note that We do not process ACH on weekends.
          </p>
        </ShortinfoSavings>
        <div className="mb-2"></div>
        {error && (
          <AlertError
            body={
              amount > 5000
                ? `Amount should not be more than $5,000 `
                : `You have do not have up to $${props.amount} in your USD wallet,please
                    fund your account to continue.`
            }
          />
        )}

        <Backcontinue goBack={props.back}>
          {/* {check === false && !props.state.bank_name ? (
            <Platformbutton
              name="Verify Routing Number"
              type="normal"
              disabled={
                !account_number ||
                !account_holder_name ||
                !routing_number ||
                !props.amount ||
                props.state.bank_name ||
                props.verLoading
                  ? true
                  : false
              }
              click={() =>
                props.verifyUserRouting({ routing: routing_number }, value =>
                  props.setState({ ...props.state, bank_name: value }),
                )
              }
            />
          ) : ( */}
          <Platformbutton
            name="Continue"
            type="normal"
            disabled={
              !account_number ||
              !account_holder_name ||
              !routing_number ||
              !props.amount ||
              (props.state.account_number?.length >= 5 &&
                props.state.account_number?.length < 8) ||
              amount === 0 ||
              amount > 5000 ||
              amount > props.balance
                ? true
                : false
            }
            click={() => props.continue()}
          />
          {/* )} */}
        </Backcontinue>
      </div>
    </div>
  );
};

const Bankaddress = props => {
  const {
    bank_address,
    country,
    bank_state,
    bank_name,
    bank_city,
    bank_zip,
  } = props.state;

  return (
    <div className="fbo__bigcol">
      <Titlesubtitle
        steps="3 of 5"
        title="Bank address"
        subtitle="Fill in these fields to carry out an ACH transfer."
      />

      <div className="fbo__col">
        <Inputfloat
          type="text"
          name="bank_address"
          label="Bank Address"
          value={props.state.bank_address}
          disabled={false}
          placeholder="Bank Address"
          onChange={props.setInput}
        >
          {' '}
          <Shortinfo
            image={infob}
            subject="Format should be : 3, furo ezimora"
          />{' '}
        </Inputfloat>
        <Singleselect
          value={props.state.country}
          options={props.countries.filter(
            item => item.label.toLowerCase() === 'united states',
          )}
          // options={props.countries}
          onChange={value => props.handleSelect('country', value)}
          placeholder="Select Country"
          components={{ SingleValue: IconSingleValue, Option: IconOption }}
        />
        <span className="fbo__inputrow">
          <Inputfloat
            type="text"
            name="bank_name"
            label="Bank Name"
            value={props.state.bank_name}
            disabled={false}
            placeholder="Bank Name"
            onChange={props.setInput}
          />
          <Inputfloat
            type="text"
            name="bank_city"
            label="Bank City"
            value={props.state.bank_city}
            disabled={false}
            placeholder="Bank City"
            onChange={props.setInput}
          />
        </span>
        <span className="fbo__inputrow">
          <Singleselect
            value={props.state.bank_state}
            options={props.states}
            onChange={value => props.handleSelect('bank_state', value)}
            placeholder="Select Bank State"
          />
          <Inputfloat
            type="text"
            name="bank_zip"
            label="Bank ZIp Code"
            value={props.state.bank_zip}
            disabled={false}
            placeholder="Bank Zip Code"
            onChange={props.setInput}
          />
        </span>
      </div>

      <Backcontinue goBack={() => props.back()}>
        <Platformbutton
          name="Continue"
          type="normal"
          disabled={
            !bank_address ||
            !country ||
            !bank_state ||
            !bank_name ||
            !bank_city ||
            !bank_zip
              ? true
              : false
          }
          click={() => props.continue()}
        />
      </Backcontinue>
    </div>
  );
};

const Locationaddress = props => {
  const {
    billing_address,
    district,
    city,
    postalCode,
    description,
  } = props.state;
  return (
    <div className="fbo__bigcol">
      <Titlesubtitle
        steps="4 of 5"
        title="Recipient details"
        subtitle="Fill in these fields to carry out an ACH transfer."
      />

      <div className="fbo__col">
        <Inputfloat
          type="text"
          name="billing_address"
          label="Recipient Address"
          value={props.state.billing_address}
          disabled={false}
          placeholder="Recipient Address"
          onChange={props.setInput}
        >
          {' '}
          <Shortinfo
            image={infob}
            subject="Format should be : 3, furo ezimora"
          />{' '}
        </Inputfloat>

        <span className="fbo__inputrow">
          <Inputfloat
            type="text"
            name="district"
            label="Recipient District"
            value={props.state.district}
            disabled={false}
            placeholder="Recipient District"
            onChange={props.setInput}
          />
          <Singleselect
            value={props.state.city}
            options={props.states}
            label="Recipient City"
            onChange={value => props.handleSelect('city', value)}
            placeholder="Recipient City"
          />
        </span>
        <Inputfloat
          type="text"
          name="postalCode"
          label="Recipient Zip Code"
          value={props.state.postalCode}
          disabled={false}
          placeholder="Recipient Zip Code"
          onChange={props.setInput}
        />

        <Inputfloat
          type="text"
          name="description"
          label="Description"
          value={props.state.description}
          disabled={false}
          placeholder="Transaction Description"
          onChange={props.setInput}
        />
      </div>

      <Backcontinue goBack={() => props.back()}>
        <Platformbutton
          name="Continue"
          type="normal"
          disabled={
            !billing_address ||
            !district ||
            !city ||
            !postalCode ||
            !description
              ? true
              : false
          }
          click={() => props.continue()}
        />
      </Backcontinue>
    </div>
  );
};

const Enteramount = props => {
  return (
    <section className="ngnwithdrawal">
      <Titlesubtitle
        steps={`Step 1`}
        title="Amount to send"
        subtitle="Enter amount to send"
      />
      <div className="ngnwithdrawal__col mb-4">
        <Usdbalance balance={props.balance} />
        <Amountinputcurrency
          type="text"
          currency="$"
          name="amount"
          label="Amount"
          value={
            props.amount === 'NaN' ? 0 : props.amount?.toLocaleString('en-US')
          }
          disabled={false}
          placeholder="Enter amount to transfer"
          onChange={props.handleAmount}
          pattern="[0-9,.]*"
        >
          <Shortinfo subject="Minimun amount allowed is $1" />
        </Amountinputcurrency>
      </div>

      {removeCommaAmount(props.amount) > 0 &&
        (removeCommaAmount(props.amount) > props.balance ||
          removeCommaAmount(props.amount) < 1) && (
          <AlertError
            body={
              removeCommaAmount(props.amount) < 5
                ? `Amount should be greater than $5.`
                : `You do not have upto $${props.amount} in your Vesti Naira wallet, please deposit into your account.`
            }
          />
        )}

      <div className="mb-2"></div>

      <Backcontinue goBack={() => props.setStep(0)}>
        <Platformbutton
          name="Continue"
          type="normal"
          disabled={
            removeCommaAmount(props.amount) > props.balance ||
            removeCommaAmount(props.amount) < 1 ||
            props.amount === 'NaN'
              ? true
              : false
          }
          click={() => props.setStep(9)}
        />
      </Backcontinue>
    </section>
  );
};

const Recipient = props => {
  // eslint-disable-next-line
  return (
    <section className="ngnwithdrawal">
      <Titlesubtitle
        steps={`Step 2 of 4`}
        title="Input User Details"
        subtitle="Input details of the user you want to send money to."
      />
      <div className="ngnwithdrawal__col">
        <Inputfloat
          type="text"
          label="USER NAME"
          name="username"
          value={props.state.username}
          placeholder="Enter Recipient vesti username"
          disabled={false}
          onChange={e =>
            props.setState({ ...props.state, userName: e.target.value })
          }
        />
        {props.loading && <Loader />}{' '}
        {props.recipient ? (
          <Inputfloat
            type="text"
            label="RECEPIENT NAME"
            name="recipient name"
            value={props.recipient?.firstName + ' ' + props.recipient?.lastName}
            placeholder=""
            disabled={true}
          />
        ) : (
          <></>
        )}
      </div>

      <div className="mb-2"></div>

      <Backcontinue goBack={() => props.setStep(8)}>
        <Platformbutton
          name={props.recipient ? 'Proceed' : 'Verify User'}
          type="normal"
          disabled={!props.state?.userName ? true : false}
          click={() =>
            props.recipient
              ? props.setStep(10)
              : props.fetchRecipient(props.state?.userName)
          }
        />
      </Backcontinue>
    </section>
  );
};

const Finalize = props => {
  useEffect(() => {
    props.dispatch(fetchTransactionOTP());
  }, []);
  return (
    <Form onFinish={() => props.handleTransferCash()} style={{ width: '100%' }}>
      <div className="recipient__form">
        <Transreview
          data={[
            { title: 'Transaction Type', value: 'Transfer' },
            {
              title: 'Amount',
              value: `${getCurrency(
                props.currency,
              )}${props.amount?.toLocaleString('en-US')}`,
            },

            { title: 'Currency', value: props.currency },
            {
              title: 'User Fullname',
              value: `${props.recipient?.firstName} ${props.recipient?.lastName}`,
            },
            {
              title: 'Fee',
              value: props.currency === 'NGN' ? `0.00` : '0.00',
            },
          ]}
        />
        <div className="w-100 flex-fill pt-4" style={{ width: '100%' }}>
          <p className="mb-2">Enter transaction code sent to your mail</p>
          <DividedPinInput onChange={props.setTransactionpin} />
        </div>
        {props.loading ? (
          <Loader />
        ) : (
          <>
            <Backcontinue
              back="Go Back"
              goBack={
                () => props.setStep(9)
                // props.step <= 2 ? props.setStep(0) : props.setStep(props.step - 1)
              }
            >
              <Platformbutton name="Finalize" type="submit" />
            </Backcontinue>
            <p style={{ textAlign: 'center' }}>
              Didn't get the code?{' '}
              <span
                style={{ cursor: 'pointer', color: 'green' }}
                onClick={() => props?.dispatch(fetchTransactionOTP())}
              >
                Resend
              </span>
            </p>
          </>
        )}
      </div>
    </Form>
  );
};

const mapStateToProps = ({ transactions, common }) => {
  const {
    transMessage,
    transLoading,
    verifyAccount,
    verLoading,
    usdAccounts,
  } = transactions;
  const { states, countries } = common;
  return {
    transMessage,
    transLoading,
    states,
    countries,
    verifyAccount,
    verLoading,
    usdAccounts,
  };
};

const mapDispatchToProps = {
  fetchAllStates,
  fetchAllCountries,
  withdrawFboUSD,
  verifyUserRouting,
  addUsdBeneficiary,
  fetchUsdBeneficiary,
  transferNGNCash,
};

export default connect(mapStateToProps, mapDispatchToProps)(Fbowithdrawal);
