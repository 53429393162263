import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import api from 'appRedux/api';
import { fetchVestiPosts } from 'appRedux/actions/posts';
import '../Admin.css';
// eslint-disable-next-line
import { useQuery } from 'react-query';
import { errorMessage } from 'helpers/utils';
// eslint-disable-next-line
import axios from 'axios';
import { useUserData } from 'helpers/hooks';
import { Singlepost } from './singlepost';
import { navigate } from '@reach/router';
import { useState, useRef } from 'react';
import { Newpost } from 'components/common/notifybox/newpost';
import { Createpost } from 'components/feeds/createpost';

const FeedsGroup = props => {
  // eslint-disable-next-line
  const [profile, setProfile] = useState(false);
  // eslint-disable-next-line
  const [member, setMinder] = useState(true);
  // eslint-disable-next-line
  const [group, setGroup] = useState(false);
  const [post, setPost] = useState({
    title: 'moveMeIn12Months',
    description: '',
    image: '',
    button: 'Post',
  });

  // eslint-disable-next-line
  const [profiledetails, setDetails] = useState({
    image: '',
    name: '',
    username: '',
    email: '',
    userId: '',
  });

  const setInput = e => {
    var value = e.target.value;
    setPost({ ...post, description: value });
  };

  const insertEmoji = emoji => {
    const updatedDescription = post.description + emoji;
    setPost({ ...post, description: updatedDescription });
  };

  const setImage = e => {
    var value = e.target.files[0];
    // console.log(value);
    setPost({ ...post, image: value });
  };

  var Remove = () => {
    setPost({ ...post, image: '' });
  };

  const dispatch = useDispatch();
  const posts = useSelector(state => state.posts.data);

  var goTofull = id => {
    navigate(`/fullpost/${id}`);
  };

  const { userData } = useUserData();

  useEffect(() => {
    dispatch(fetchVestiPosts());
  }, []);
  // console.log(post.description)

  const createPost = async (e, selectedFileBinary, setInputModal) => {
    e.preventDefault();
    setPost({ ...post, button: 'Creating Post...' });
    var formData = new FormData();

    // formData.append('title', post.title);
    formData.append('action', 'message');
    // formData.append('actionId', props.defaultGroupId);
    formData.append('message', post.description);
    formData.append('images', selectedFileBinary[0]);
    formData.append(
      'images',
      selectedFileBinary[1] ? selectedFileBinary[1] : null,
    );
    formData.append(
      'images',
      selectedFileBinary[2] ? selectedFileBinary[2] : null,
    );
    formData.append(
      'images',
      selectedFileBinary[3] ? selectedFileBinary[3] : null,
    );
    console.log(formData);
    if (
      userData.firstName === null ||
      userData.firstName === '' ||
      userData.lastName === null ||
      userData.lastName === '' ||
      userData.username?.includes('null')
    ) {
      // console.log(userData.verifiedKyc);
      openNotificationWithIconErr(
        'Post',
        'Only Users with full profile details can post, go to your profile and update your profile.',
        'error',
      );
      setPost({ ...post, button: 'Post' });
      setInputModal(false);
    } else {
      api
        .post(`/group/posts-and-comments`, formData)
        .then(res => {
          openNotificationWithIcon(
            'You have successfully created a post',
            'Success',
            'success',
          );
          setInputModal(false);
          dispatch(fetchVestiPosts());
          setPost({ description: '', image: '', button: 'Create Post' });
        })
        .catch(err => {
          openNotificationWithIconErr(errorMessage(err), 'Post', 'error');
          setPost({ description: '', image: '', button: 'Create Post' });
        });
    }
  };
  // eslint-disable-next-line
  const closeProfile = () => {
    setProfile(false);
  };
  // eslint-disable-next-line
  var closeMinder = () => {
    setMinder(false);
    localStorage.setItem('memberReminder', 'close');
  };
  // eslint-disable-next-line
  const closeGroup = () => {
    setGroup(false);
  };

  // eslint-disable-next-line
  var filt = {
    address: 'England',
    name: 'Mark',
  };
  // eslint-disable-next-line
  var exclusive = [
    '0-1 Candidate US',
    'Business Class',
    'First Class',
    '1000 Students',
  ];
  // eslint-disable-next-line
  var onScroll = () => {
    const scrollTop = scrollRef.current.scrollTop;
    setScroll(scrollTop);
  };

  const [scroll, setScroll] = useState();
  const scrollRef = useRef(null);
  // eslint-disable-next-line
  var myReminder = localStorage.getItem('memberReminder');
  return (
    <div className="px-1 " style={{ paddingTop: '1rem' }}>
      <div className="">
        <Createpost
          name={userData.firstName}
          post={post}
          insertEmoji={insertEmoji}
          setInput={setInput}
          setImage={setImage}
          createPost={createPost}
          picture={userData.profilePictureURL}
          remove={Remove}
        />
      </div>

      <div className="" style={{ position: 'relative', marginTop: '2rem' }}>
        {(scroll > 200) & (scroll < 30000) ? (
          <Newpost />
        ) : (
          <div
            style={{
              height: '30px',
              padding: '20px',
              position: 'sticky',
              top: '20%',
            }}
          ></div>
        )}
        {posts.map((post, index) => {
          const {
            // comments,
            createdAt,
            // groupId,
            id,
            images,
            description,
            reactions,
            // replies,
            // senderId,
            // updatedAt,
            // userComments,
            userId,
            title,
            date,
            hasLiked,
            // likes,
            shares,
            postPictureUrl,
          } = post;

          if (post?.creator?.username?.includes('null')) {
            return '';
          } else {
            return (
              <Singlepost
                key={id}
                id={id}
                username={post?.creator?.username}
                firstname={post?.creator?.firstName}
                lastname={post?.creator?.lastName}
                fullname={
                  post?.creator?.firstName + ' ' + post?.creator?.lastName
                }
                email={post?.creator?.email}
                userId={userId}
                userData={userData}
                title={title}
                images={images}
                description={description}
                userComments={post?.comments}
                profileUrl={post?.creator?.profilePictureURL}
                createdAt={createdAt}
                date={date}
                fetchVestiPosts={fetchVestiPosts}
                hasLiked={hasLiked}
                likes={reactions}
                shares={shares}
                setProfile={setProfile}
                setDetails={setDetails}
                postPictureUrl={postPictureUrl}
                onClick={goTofull}
                groupId={props.defaultGroupId}
                insertEmoji={insertEmoji}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default FeedsGroup;
