import React from 'react'

export const About = () => {
  return (
    <div style={{margin: '0px', padding: '0% 7%'}}>
      <p>Move Abroad in 12 Months is a supportive community of individuals who share the dream of starting a new chapter 
        in their lives by moving abroad. Whether you're seeking new opportunities, a change of scenery, or simply an 
        adventure of a lifetime, our group is here to guide and motivate you through the process of making your dream of 
        living abroad a reality.</p>
    </div>
  )
}
