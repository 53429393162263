import React, { useEffect, useState, useRef } from 'react';
import './MiaiIndex.scss';
import MiaiAvatar from 'assets/miai/Miai.svg';
import Vloader from 'assets/vestiLoader/vestiLoader.gif';
import { useSelector } from 'react-redux';
import dummy from 'assets/dummy-avatar.png';
import copyIcon from 'assets/miai/copy.svg';
import shareIcon from 'assets/miai/share.svg';
import { openShare } from 'appRedux/actions/Alertbox';
import { useDispatch } from 'react-redux';
import copy from 'copy-to-clipboard';
import { MiaiClipboard } from 'components/common/clipboard/miaiclipboard';
import repeat from 'assets/miai/repeat.svg';
import dislike from 'assets/miai/dislike.svg';
import like from 'assets/miai/like.svg';
import api from 'appRedux/api';
import Typing from 'react-typing-animation';
import IGLogo from 'assets/miai/iGtransparent.jpeg';
import twitterXlogo from 'assets/miai/twitterxicon.png';
import Linkedinlogo from 'assets/miai/linkedinIcon.png';
import FormattedTextComponent from './FormattedText';
import TelegramLogo from 'assets/miai/Telegram-icon.png';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { MdCancel } from 'react-icons/md';
import { JustLoader } from 'components/Loader/Loader';

const ChatHistory = props => {
  const userData = useSelector(state => state.auth?.userData);
  const [Copystate, setIsCopystate] = useState('Copy');
  const [regenerated, setRegenerated] = useState('');
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
  const [messages, setMessages] = useState([]);
  const [share, setShare] = useState(false);
  const [id, setId] = useState('');
  const [socialname, setSocialname] = useState('');
  const [limitModal, setLimitModal] = useState(false);
  const [lastIndex, setLastIndex] = useState(null);

  useEffect(() => {
    if (props.allchatbyId) {
      setMessages(props.allchatbyId);
      setLastIndex(props.allchatbyId?.length - 1);
    }
  }, [props.allchatbyId, props.typing]);

  const handleShareClick = index => {
    setId(messages[index]?.message);
    setShare(true);

    // After 10 seconds, set 'share' back to false
    setTimeout(() => {
      setShare(false);
    }, 5000);
  };

  const handleCopyClick = () => {
    setIsCopystate('Copied');

    setTimeout(() => {
      setIsCopystate('Copy');
    }, 3000);
  };

  console.log(props.messages);

  return (
    <div>
      <Simplemodal onClick={() => setLimitModal(false)} open={limitModal}>
        <div className="" style={{ fontSize: '15px', fontWeight: '500' }}>
          Response regeneration exhausted. Ask Miai ™ another question
        </div>
      </Simplemodal>

      {/* ... (loader UI) */}
      {!props.showLoader && (
        <>
          {props.chatId && props.allchatbyId && !props.newchat ? (
            // {props.chatId && props.allchatbyId ? (
            <div>
              {props.allchatbyId.map((item, index) => {
                if (index % 2 === 0) {
                  return (
                    <div className="ChatHistory__QestionContainer" key={index}>
                      <div className="ChatHistory__QA">
                        <p
                          className={` ${
                            // props.isDarkMode
                            //   ? 'darkModecolor'
                            //   :
                            'lightModecolor'
                          }`}
                        >
                          {item.message}
                        </p>
                      </div>

                      <div className="ChatHistory__absolute">
                        <img
                          style={{
                            borderRadius: '40%',
                          }}
                          className="ChatHistory__absolute__img"
                          src={
                            userData?.profilePictureURL
                              ? userData?.profilePictureURL
                              : dummy
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  );
                } else {
                  const correspondingQuestion =
                    props.allchatbyId[index - 1]?.message;
                  return (
                    <div key={index}>
                      <div className="ChatHistory__AnswerContainer">
                        <div className="ChatHistory__AnswerContainer__absolute">
                          <div
                            style={{
                              background: '#518C36',
                              width: '40px',
                              height: '40px',
                              borderRadius: '50%',
                            }}
                          >
                            <img
                              style={{ width: '100%', height: '100%' }}
                              src={MiaiAvatar}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="ChatHistory__AnswerContainer__Ans">
                          <FormattedTextComponent text={item.message} />
                          <p style={{ color: '#dadbe0' }}>
                            {selectedMessageIndex === index && (
                              <Typing
                                speed={50}
                                onFinishedTyping={() => props.setTyping(false)}
                              >
                                {regenerated}
                              </Typing>
                            )}
                          </p>

                          <div className="ChatHistory__AnswerContainer__absoluteIcons">
                            <div style={{ display: 'flex', gap: '10px' }}>
                              <Postlink toolTip={`${Copystate}`} type="save">
                                <div onClick={handleCopyClick}>
                                  <MiaiClipboard link={item.message} />
                                </div>
                              </Postlink>
                              <Postlink
                                toolTip="Share"
                                type="share"
                                share={share}
                                click={handleShareClick}
                                index={index}
                                myId={item.message}
                                id={id}
                                socialname={socialname}
                                setSocialname={setSocialname}
                                //             console.log(index);

                                // console.log(props.chatId);
                                chatId={props.chatId}
                              >
                                <img
                                  onMouseEnter={() => setSocialname('')}
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    cursor: 'pointer',
                                  }}
                                  src={shareIcon}
                                  alt=""
                                />
                              </Postlink>

                              <Postlink
                                toolTip="Regenerate"
                                type="Regenerate"
                                click={() => {
                                  props.regenerateCounter === 3
                                    ? setLimitModal(true)
                                    : props.setRenBtmClicked(true);
                                  props.regenerateCounter === 3
                                    ? props.setInput('')
                                    : props.setInput(correspondingQuestion);
                                }}
                              >
                                <img
                                  onMouseEnter={() => setSocialname('')}
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    cursor: 'pointer',
                                  }}
                                  src={repeat}
                                  alt=""
                                />
                              </Postlink>
                              {/* <div onClick={}>Regenerate</div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          ) : (
            <div></div>
          )}

          {props.typewriter ? (
            <>
              {props.messages.map((message, index) =>
                message.isUser ? (
                  <div
                    key={index}
                    className={`${'ChatHistory__QestionContainer'}`}
                  >
                    <div className="ChatHistory__QA">
                      <p
                        className={` 
                      lightModecolor
                        `}
                      >
                        {message.text}
                      </p>
                    </div>

                    <div className="ChatHistory__absolute">
                      <img
                        style={{
                          borderRadius: '40%',
                        }}
                        className="ChatHistory__absolute__img"
                        src={
                          userData?.profilePictureURL
                            ? userData?.profilePictureURL
                            : dummy
                        }
                        alt=""
                      />
                    </div>
                  </div>
                ) : (
                  <div key={index} className="ChatHistory__AnswerContainer">
                    <div className="ChatHistory__AnswerContainer__absolute">
                      <div
                        style={{
                          background: '#518C36',
                          width: '40px',
                          height: '40px',
                          borderRadius: '50%',
                        }}
                      >
                        <img
                          style={{ width: '100%', height: '100%' }}
                          src={MiaiAvatar}
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="ChatHistory__AnswerContainer__Ans">
                      {/* {props.typing ? (
                        <p style={{ fontSize: '16px', color: '#dadbe0' }}>
                          {props.generateChatbotResponse(message.text)}
                        </p>
                      ) : (
                        <div>
                          <FormattedTextComponent text={props.answer} />
                        </div>
                      )} */}

                      <div>
                        <p style={{ fontSize: '16px', color: '#dadbe0' }}>
                          {props.generateChatbotResponse(message?.text)}
                        </p>
                      </div>
                      {/* 
                      <div>
                        <FormattedTextComponent text={message?.text} />
                      </div> */}

                      <div className="ChatHistory__AnswerContainer__absoluteIcons">
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <Postlink toolTip="copy" type="save">
                            <div
                              onClick={() => {
                                handleCopyClick();
                              }}
                            >
                              <MiaiClipboard link={props.answer} />
                            </div>
                          </Postlink>
                          <Postlink
                            toolTip="Share"
                            type="share"
                            share={share}
                            click={handleShareClick}
                            index={lastIndex + 2}
                            myId={'last message'}
                            id={'last message'}
                            socialname={socialname}
                            setSocialname={setSocialname}
                            chatId={props.chatId}
                          >
                            <img
                              onMouseEnter={() => setSocialname('')}
                              style={{
                                width: '20px',
                                height: '20px',
                                cursor: 'pointer',
                              }}
                              src={shareIcon}
                              alt=""
                            />
                          </Postlink>
                          <Postlink
                            toolTip="Regenerate"
                            type="Regenerate"
                            click={() => {
                              // props.setRenBtmClicked(true);
                              props.regenerateCounter === 3
                                ? setLimitModal(true)
                                : props.setRenBtmClicked(true);
                              props.regenerateCounter === 3
                                ? props.setInput('')
                                : props.setInput(
                                    props.messages[index - 1]?.text,
                                  );
                            }}
                          >
                            <img
                              onMouseEnter={() => setSocialname('')}
                              style={{
                                width: '20px',
                                height: '20px',
                                cursor: 'pointer',
                              }}
                              src={repeat}
                              alt=""
                            />
                          </Postlink>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              )}
            </>
          ) : (
            <> </>
          )}
        </>
      )}

      <div>{props.isLoading ? <JustLoader /> : <></>}</div>

      <div>
        {!props.answer &&
        props.errorMsg ===
          'Oops, Miai ran into an error and we are working on it' ? (
          <p
            style={{
              border: '1px solid red',
              padding: '10px',
              borderRadius: '8px',
              color: 'red',
              fontWeight: '500',
              display: 'flex',
              background: '#FFF0F1',
              alignItems: 'center',
              gap: '4px',
              width: '350px',
              justifyContent: 'center',
              fontSize: '12px',
            }}
          >
            <MdCancel style={{ color: 'red' }} />

            {props.errorMsg}
          </p>
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
};

export default ChatHistory;

export const Postlink = props => {
  return (
    <div
      className="tooltipIcon"
      onClick={() => (props.type === 'save' ? '' : props.click(props.index))}

      // onClick={() => (props.type === 'save' ? '' : props.click(props.postId))}
    >
      {props.toolTip === 'Share' && props.share && props.id === props.myId ? (
        <div
          className="SocialshareIcons"
          style={{ position: 'absolute', top: '-30px' }}
        >
          <div style={{ display: 'flex', gap: '2px' }}>
            {/* <a
              href={`https://twitter.com/intent/tweet?url=http://localhost:3000/miai/${
                props.chatId
              }/${props.index}&text=${encodeURIComponent(`${props.myId}`)}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                width: '30px',
                height: '30px',
                cursor: 'pointer',
                borderRadius: '10px',
              }}
            > */}
            <a
              href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                `${props.myId}`,
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                width: '30px',
                height: '30px',
                cursor: 'pointer',
                borderRadius: '10px',
              }}
            >
              <img
                onMouseEnter={() => props.setSocialname('on Twitter')}
                style={{
                  width: '20px',
                  height: '20px',
                  cursor: 'pointer',
                  borderRadius: '10px',
                }}
                src={twitterXlogo}
                alt=""
              />
            </a>

            {/* <a
              href={`https://t.me/share/url?url=http://localhost:3000/miai/${
                props.chatId
              }/${props.index}&text=${encodeURIComponent(`${props.myId}`)}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                width: '30px',
                height: '30px',
                cursor: 'pointer',
                borderRadius: '10px',
              }}
            >
              <img
                onMouseEnter={() => props.setSocialname('on Telegram')}
                style={{
                  width: '25px',
                  height: '25px',
                  cursor: 'pointer',
                  marginLeft: '-5px',
                }}
                src={TelegramLogo}
                alt=""
              />
            </a> */}
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <div style={{ marginBottom: '10px' }}>{props.children}</div>
      <div className="tooltiptext">
        {props.toolTip} {props.socialname ? props.socialname : ''}
      </div>
    </div>
  );
};
