import React from 'react'
import searchIcon from '../../../../assets/newFeedsImg/secondSecImg/searchIcon.png'
import elipsis from '../../../../assets/newFeedsImg/secondSecImg/elipsis.png'
import arrowDownIcon from '../../../../assets/newFeedsImg/secondSecImg/angle-down-circle.png'
import './newfeeds.scss'
// import { chatData } from './data'

export const Chatsv2 = () => {
  return (
    <div className='chats'>
    <div className='chat-header'>
      <h4>Chats</h4>
      <div className='icons'>
        <img src={searchIcon} alt='' />
        <img src={elipsis} alt='' />
        <img src={arrowDownIcon} alt='' />
      </div>
    </div>
    {/* <div className='chat-space'>
      {chatData.map(({img, name, message, emoj, pin, doubleTick, emoji}, index) => {
        return <div className='allPart'>
          <img src={img} alt=""/>
          <div className='name-chat'>
            <p>{name}</p>
            <div className='chatContent'>
              <img src={emoji} alt=""/>
              <p>{message}</p>
              <img src={emoj} alt=""/>
              <div className='emojis'>
                <img src={pin} alt=""/>
                <img src={doubleTick} alt=""/>
              </div>
            </div>
          </div>
        </div>
      })}
    </div> */}
  </div>
  )
}
