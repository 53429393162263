import React, { useEffect, useState } from 'react';
import '../mytransactions.scss';
// import deposit from "assets/deposit_tr.svg"
// import withdraw from "assets/withdraw_tr.svg"
import { Empty } from 'components/common/empty/empty';
import Loader from 'components/Loader';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { transColor, transStatus } from 'helpers/utils';
// import moment from 'moment';
import { fetchFinTransactions } from 'appRedux/actions/usdtrans';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { connect } from 'react-redux';
// import Newpagination from "../pagination/newpagination";
const Myfintransactions = props => {
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  const [active, setActive] = useState(0);
  const [open, setOpen] = useState(false);

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  var handleOpen = value => {
    setActive(value);
    dimensions.width < 1050 && setOpen(true);
  };

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);

  useEffect(() => {
    props.fetchFinTransactions({ id: props.id });
    // eslint-disable-next-line
  }, [props.balance, page]);

  if (props.transLoading) {
    return (
      <div className="mytransactions">
        <Titlesubtitle
          title="Founders Account Transactions"
          subtitle="See all transactions you've carried out on your Founders account."
        />
        <div className="mytransactions__centercont">
          <Loader />
        </div>
      </div>
    );
  } else if (props.finTransactions && props.finTransactions.length > 0) {
    return (
      <div className="mytransactions">
        <Simplemodal onClick={() => setOpen(false)} open={open}>
          <FTransdetail
            data={
              (props.finTransactions ?? []).filter(
                item => item.amount / 100 !== 0,
              )[active]
            }
          />
        </Simplemodal>
        <Titlesubtitle
          title="Founders Account Transactions"
          subtitle="See all transactions you've carried out on your Founders account."
        />

        <div className="mytransactions__inner">
          <div className="mytransactions__tablecont">
            <div className="mytransactions__tabbox">
              <table className="mytransactions__table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Type</th>
                    <th>Currency</th>
                    <th>Amount</th>
                    <th>Description</th>
                    {/* <th>Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  {(props.finTransactions ?? [])
                    .filter(item => item.amount / 100 !== 0)
                    .map((item, index) => (
                      <Trow
                        key={index}
                        index={index}
                        type={item.flow_type}
                        currency={item.currency}
                        description={item.description}
                        status={item.status}
                        amount={item.amount}
                        click={handleOpen}
                      />
                    ))}
                </tbody>
              </table>
            </div>
            {dimensions.width > 1200 && (
              <FTransdetail
                data={
                  (props.finTransactions ?? []).filter(
                    item => item.amount / 100 !== 0,
                  )[active]
                }
              />
            )}
          </div>

          {/* <Newpagination
                            className="pagination-bar"
                            currentPage={page}
                            totalCount={data.paginationMeta.totalObjects}
                            pageSize={15}
                            onPageChange={page => setPage(page)}
                        /> */}
        </div>
      </div>
    );
  } else {
    return (
      <div className="mytransactions">
        <Titlesubtitle
          title="Founders Account Transactions"
          subtitle="See all transactions you've carried out on your Founders account."
        />
        <div className="mytransactions__centercont">
          <Empty
            title="No Transactions"
            subtitle="You are yet to carry out any transactions, when you do they'll appear here, click the buttons above to carry out transactions."
          />
        </div>
      </div>
    );
  }
};

const Trow = props => {
  // var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', };
  // const newDate = new Date(props.date).toLocaleDateString("en-US", options);
  var type = props.type === 'received_credit' ? 'Deposit' : 'Withdrawal';
  return (
    <tr onClick={() => props.click(props.index)}>
      <td>{props.index}</td>
      <td>{type}</td>
      <td>USD</td>
      <td
        className={`amount${
          type.includes('Withdrawal')
            ? '-red'
            : type.includes('Deposit')
            ? '-green'
            : '-red'
        }`}
      >
        {(props.amount / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </td>
      <td>{props.description}</td>
      {/* <td>
                <div className={`status ${props.status === "SUCCESS" ? ' success' : props.status === "PENDING" ? " pending" : " failed"}`}>
                    {props.status}
                </div>
            </td> */}
    </tr>
  );
};

const FTransdetail = props => {
  var type =
    props.data.flow_type === 'received_credit' ? 'Deposit' : 'Withdrawal';
  return (
    <section className="transdetail">
      <span className={`transdetail__top ${transColor(type)}`}>
        <p>{type}</p>
        <p> USD {(props.data?.amount / 100).toLocaleString('en-us')}</p>
        {/* <p>{ moment(props.data.created).format("YYYY-MM-DD HH:mm:ss")}</p> */}
      </span>
      <div className="transdetail__list">
        {[
          { title: 'status', value: props.data.status },
          { title: 'Currency', value: 'usd' },
          // {title:'date',value:moment(new Date(props.data.created), 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss")},
          { title: 'Type', value: type },
          { title: 'ID', value: props.data.id },
        ].map((item, index) => (
          <span key={index} className="transdetail__item">
            <p>{item.title}</p>
            <p className={item.title === 'status' ? `status` : ''}>
              {item.title === 'status' && (
                <p className={`statusIcon --${transStatus(item.value)} `}></p>
              )}{' '}
              {item.title === 'status' ? transStatus(item.value) : item.value}
            </p>
          </span>
        ))}
      </div>
    </section>
  );
};

const mapStateToProps = ({ transactions }) => {
  const { finTransactions, transLoading } = transactions;
  return {
    finTransactions,
    transLoading,
  };
};

export default connect(mapStateToProps, {
  fetchFinTransactions,
})(Myfintransactions);
