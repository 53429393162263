import React from 'react';
import Layout from 'components/common/DashboardLayout';
import api from 'appRedux/api';
import { useState, useEffect } from 'react';
import './aboutpathway.scss';
import { useLocation } from '@reach/router';
import { Singlepathway } from 'components/pathway/singlepathway';
import { Empty } from 'components/common/empty/empty';
import Loader from 'components/Loader';
import dummy from 'assets/dummy-avatar.png';
import { stripeUsdViaCardPathway } from 'appRedux/actions/transactions';
import { connect } from 'react-redux';
import { userId } from './index';
import { openStagesBox, openDescBox } from 'appRedux/actions/pathway';
import { Simplemodal, Largemodal } from 'components/common/simplifiedmodal';
import { Newprompt } from 'components/common/prompt/prompt';
import {
  Smalltitle,
  Titlesubtitle,
} from 'components/common/titlesubtitle/titlesubtitle';
import subs from 'assets/subscription.svg';
import { errorMessage, shuffleArray } from 'helpers/utils';
import sevisserv from 'assets/sevisserv.png';
import usdepartment from 'assets/usdepartment.png';
import tef from 'assets/tefnew.png';
import ielts from 'assets/ielts.png';
import wesservice from 'assets/wesnew.png';
import ukvisa from 'assets/ukvisa.png';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import { Success } from 'components/common/success/success';
import { useUserData } from 'helpers/hooks';
import { Growthcommunity } from 'containers/Admin/Pathways/growthcommunity';
import { navigate } from '@reach/router';
import { Platformbutton } from 'components/common/button/button';
import {
  Pathsubscribers,
  Userusingpath,
} from 'components/pathway/pathwayscomp';
import {
  webinarData,
  blogData,
  testimonialData,
  userDatas,
  Webinarcard,
  BlogCard,
  Testimonycard,
} from 'containers/Admin/Pathways/index';
import PathwaysubSteps from './pathwaysubSteps';
import { fetchProfiles } from 'appRedux/actions/profile';
import { FcCheckmark } from 'react-icons/fc';
// import { AiOutlineClockCircle } from 'react-icons/ai';
// import { IoMdNotifications } from 'react-icons/io';
// import { MdGroup } from 'react-icons/md';

import { TourGuidemodal } from 'components/common/simplifiedmodal';
import { useSelector } from 'react-redux';
import { IntroPathwayGuide } from 'TourGuide/PathwayTourGuide/Intro/Intro';
import { PathaysProcedures } from 'TourGuide/PathwayTourGuide/JoinPathway/PathwayProcedures';

const Aboutpathway = props => {
  const { state } = useLocation();
  const {
    id = '',
    description = '',
    logo = '',
    title = '',
    creator = '',
    pathwayCost = '',
    stagesObject = '',
    country = '',
    month = '',
    pathwayVideo = '',
  } = state || {};
  const [loading, setLoading] = useState(true);
  const [myPathway, setMyPathway] = useState({});
  const [myPathways, setMyPathways] = useState({});
  const [subscribe, setSub] = useState({
    value: false,
    step: 0,
  });
  const [active, setActive] = useState(0);
  const [modal, setModal] = useState(false);
  const [profile, setProfile] = useState([]);
  const { userData } = useUserData();
  const [success, setSuccess] = useState(false);
  const [groupData, setGroupData] = useState([]);

  // testing

  const adminUsername = userData.username;

  const pathwayProgress = myPathway?.pathwayProgress;

  // console.log('pathwayProgress', pathwayProgress);

  const residenceCountry = userData?.country;

  const merchantData = [
    {
      name: 'SEVIS FEE',
      description: 'Easily Pay your SEVIS fees without hassle',
      price: 'SEVIS',
      image: sevisserv,
    },

    {
      name: 'WES FEE',
      description: ' Easily Pay your WES fees without hassle',
      price: 'WES',
      image: wesservice,
    },

    {
      name: 'US VISA',
      description: 'Easily Pay your USA VISA without hassle',
      price: 'USVISADS160',
      image: usdepartment,
    },

    {
      name: 'IELTS',
      description: 'Easily Pay your IELTS without hassle',
      price: 'IELTS',
      image: ielts,
    },

    {
      name: 'TEF',
      description: 'Easily Pay your TEF without hassle',
      price: 'TEF',
      image: tef,
    },

    {
      name: 'UK STUDENT',
      description: 'Easily Pay your UK VISA without hassle',
      price: 'UKSTUDENTVISA',
      image: ukvisa,
    },

    // ...
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/group/all-groups`);
        const data = response.data.data.data;
        setGroupData(data);
      } catch (error) {
        // Handle error
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const getRandomItemstestimonialData = () => {
    const shuffled = testimonialData.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  };

  const getRandomAlltestimonialData = () => {
    const shuffled = testimonialData.sort(() => 0.5 - Math.random());
    return shuffled;
  };

  const getRandomItemsgroupData = () => {
    const shuffled = groupData.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  };

  const getRandomItemsMerchantData = () => {
    const shuffled = merchantData.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 4);
  };

  const getRandomItemswebinarData = () => {
    const shuffled = webinarData.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  };

  const getRandomAllwebinarData = () => {
    const shuffled = webinarData.sort(() => 0.5 - Math.random());
    return shuffled;
  };

  const [isPathwayPopupOpen, setPathwayPopupOpen] = useState(false);

  const [isGroupPopupOpen, setGroupPopupOpen] = useState(false);

  const [isTestimonialPopupOpen, setTestimonialPopupOpen] = useState(false);

  const [isBlogPopupOpen, setBlogPopupOpen] = useState(false);

  const [isWebinarPopupOpen, setWebinarPopupOpen] = useState(false);

  const [isMerchantPopupOpen, setMerchantPopupOpen] = useState(false);

  const handleSeeAllGroupClick = () => {
    setGroupPopupOpen(true);
  };

  // eslint-disable-next-line
  const handleCloseGroupPopup = () => {
    setGroupPopupOpen(false);
  };

  const handleSeeAllTestimonialClick = () => {
    setTestimonialPopupOpen(true);
  };

  // eslint-disable-next-line
  const handleCloseTestimonialPopup = () => {
    setTestimonialPopupOpen(false);
  };

  const handleSeeAllBlogClick = () => {
    setBlogPopupOpen(true);
  };

  const handleCloseBlogPopup = () => {
    setBlogPopupOpen(false);
  };

  const handleSeeAllWebinarClick = () => {
    setWebinarPopupOpen(true);
  };

  // eslint-disable-next-line
  const handleCloseWebinarPopup = () => {
    setWebinarPopupOpen(false);
  };

  const handleSeeAllMerchantClick = () => {
    setMerchantPopupOpen(true);
  };

  const handleCloseMerchantPopup = () => {
    setMerchantPopupOpen(false);
  };

  // Get a random selection of 4 items from myData

  const getAllItemsgroupData = () => {
    const shuffled = groupData.sort(() => 0.5 - Math.random());
    return shuffled;
  };

  const getRandomItemsblogData = () => {
    const shuffled = blogData.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  };

  const getRandomAllblogData = () => {
    const shuffled = blogData.sort(() => 0.5 - Math.random());
    return shuffled;
  };

  const getRandomAllMerchantData = () => {
    const shuffled = merchantData.sort(() => 0.5 - Math.random());
    return shuffled;
  };

  var filt = {
    address: 'England',
    name: 'Mark',
  };

  var close = () => {
    props.closeModal();
    props.refetch();
  };

  // console.log('pathway UserId', userId);
  const payload = {
    pathwayTemplateId: props.id,
    userId: userId,
  };

  var subscribeToPathway = () => {
    api
      .post(`/pathway/subscribe`, payload, 'pathway')
      .then(res => {
        openNotificationWithIcon(
          res.message,
          'Pathway Subscription',
          'success',
        );
        setSub({ ...subscribe, step: 1 });
      })
      .catch(err => {
        openNotificationWithIconErr(
          errorMessage(err),
          'Pathway Subscription',
          'error',
        );

        setTimeout(() => {
          setSub({ ...subscribe, value: false });
        }, 1500);
      });
  };

  var closeSub = () => {
    setSub({ step: 0, value: false });
  };
  useEffect(() => {
    props.fetchProfiles();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    // alert(props.id)
    setLoading(true);
    api
      .get(
        `/pathway/active/search?limit=100&pathwayId=${props.id}`,
        null,
        'pathway',
      )
      .then(res => {
        setMyPathway(res.data?.data?.data[0]);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    api
      .get(`/pathway/active/search`, null, 'pathway')
      .then(res => {
        setLoading(false);
        setMyPathways(res.data?.data?.data);
        setSuccess(true);
      })
      .catch(err => err);
  }, []);

  const [subPathwayObject, setSubPathwayObject] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [modaltest, setModalTest] = useState(false);
  const [pathwayIds, setPathwayIds] = useState([]);
  const [joinPathway, setJoinPathway] = useState(false);
  const [planType, setPlanType] = useState('');

  useEffect(() => {
    api
      .get(`pathway/plan-and-join`)
      .then(res => {
        const myObject =
          res.data.data.plan === null ? null : res.data.data.subscriptions;
        setSubPathwayObject(myObject);
        setPlanType(res.data?.data?.plan?.type);
        setIsActive(
          res.data.data.plan === null ? false : res.data.data.plan.active,
        );
        setPathwayIds(res.data.data.plan.totalPathways);
      })
      .catch(err => err);
  }, [myPathway]);

  const isTemplateIdIncluded = templateIdToCheck => {
    if (pathwayIds) {
      return pathwayIds.includes(templateIdToCheck);
    } else {
      return false;
    }
  };

  var HandleJoinPathway = async () => {
    const payload = {
      pathwayTemplateId: props.id,
    };

    try {
      const res = await api.post(`pathway/join`, payload);
      navigate(`/pathwaysjourney/${props.id}`, {
        state: {
          id,
          title,
          logo,
          description,
          stagesObject,
          creator,
          pathwayCost,
          country,
          month,
          pathwayVideo,
        },
      });
      //  navigate(`/pathwaysjourney/${props.id}`, { state: props.state });
      openNotificationWithIcon(res.message, 'Join Pathway', 'success');
    } catch (error) {
      const err = error?.data?.message || error.message;
      openNotificationWithIconErr(err, 'Subscription Payment', 'error');
      setJoinPathway(false);
      setModalTest(true);
    }
  };

  const ispathwaytemplateIncluded = isTemplateIdIncluded(props.id);

  localStorage.setItem('pathwayTemplateId', props.id);

  const data = [
    {
      firstName: 'Adewole',
      lastName: 'Philemon',
      email: 'aphilemon.aa@gmail.com',
      username: 'blanconero',
    },
    {
      firstName: 'Seyi',
      lastName: 'Otun',
      email: 'seyifotun@gmail.com',
      username: 'seyiotun',
    },
    {
      firstName: 'Afolayan',
      lastName: 'Samuel ',
      email: 'toluafo67@gmail.com',
      username: 'teetat',
    },
  ];

  const [monthsRemaining, setMonthsRemaining] = useState(0);

  const [daySpent, setDaySpent] = useState(0);

  const [seePercentages, setPercentage] = useState(0);

  const createdAt = '2023-06-01T00:00:00.000Z';

  useEffect(() => {
    const startDate = new Date(createdAt);
    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 12); // Add 8 months to the start date

    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    let counter = 0;

    let percentage = 0;

    let newPercentage = 0;

    for (
      let currentDate = new Date(startDate);
      currentDate <= endDate;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      counter++;

      percentage++;

      if (counter > 365) {
        counter = 0;
      }
    }

    newPercentage = percentage - 365;

    if (newPercentage > 100) {
      newPercentage = 100;
    }

    setDaySpent(counter);
    setPercentage(newPercentage);

    const updateRemainingMonths = () => {
      const currentDate = new Date();
      const timeDiff = Math.abs(currentDate.getTime() - endDate.getTime());
      const months = Math.ceil(timeDiff / (1000 * 60 * 60 * 24 * 30));
      const remainingMonths = months > 0 ? months : 0;
      setMonthsRemaining(remainingMonths);
    };

    updateRemainingMonths();

    const interval = setInterval(() => {
      updateRemainingMonths();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [createdAt]);

  var shuffled =
    props.profiles.data && shuffleArray(props.profiles.data).slice(0, 3);

  const IsPathwayTourTrue = useSelector(
    state => state.Tourguide.openCardTourModal,
  );
  const [pathwayTour, setPathwayTour] = useState(IsPathwayTourTrue);

  // console.log("asdsad" +JSON.stringify(myPathway))
  if (loading || !props.profiles) {
    return (
      <Layout>
        <div className="aboutpathway">
          <div className="aboutpathway__inner">
            <div className="aboutpathway__content">
              <Loader />
            </div>
          </div>
        </div>
      </Layout>
    );
  } else if (props.id === ':id') {
    return (
      <Layout>
        <div className="about-pathway-container">
          <div className="aboutpathway-inner">
            <Empty
              title="Pathway does not exist"
              subtitle="The Pathway you are looking for does not exist."
              link={() => navigate('/pathways')}
              name="Back to Pathways"
            />
          </div>
        </div>
      </Layout>
    );
  } else {
    const percentage = pathwayProgress?.percentage;

    const monthPathway = myPathway?.month;

    // console.log('monthPathway', myPathway);

    const day = pathwayProgress?.day;

    if (monthPathway && day) {
      localStorage.setItem('creditMonth', monthPathway);

      localStorage.setItem('creditDay', day);
    }

    return (
      <Layout>
        {IsPathwayTourTrue && (
          <TourGuidemodal
            onClick={() => setPathwayTour(false)}
            open={pathwayTour}
          >
            <PathaysProcedures />
          </TourGuidemodal>
        )}

        <Simplemodal
          onClick={() => setSub({ step: 0, value: false })}
          open={subscribe.value}
        >
          <Subscription
            title={title}
            cost={myPathway.pathwayCost}
            onClick={subscribeToPathway}
            subscribe={subscribe}
            closeSub={closeSub}
          />
        </Simplemodal>

        <Largemodal onClick={() => setModalTest(false)} open={modaltest}>
          <PathwaysubSteps />
        </Largemodal>

        <Simplemodal onClick={() => setJoinPathway(false)} open={joinPathway}>
          <div className="pathwayMod">
            <p className="pathwaytitle__title">Pathway Subscription Benefits</p>
            <ul className="mb-10">
              <li>
                <FcCheckmark className="icon" /> Access to exclusive content
                tailored to your interests.
              </li>

              <li>
                <FcCheckmark className="icon" />
                Stay updated with the latest trends and development.
              </li>
              <li>
                <FcCheckmark className="icon" />
                Recieve notifications about new learning materials.
              </li>
              <li>
                <FcCheckmark className="icon" /> Join a community of like-minded
                leaners.
              </li>
            </ul>
            <Platformbutton
              type="normal"
              name="Join"
              click={() => HandleJoinPathway()}
            />
          </div>
        </Simplemodal>

        <Simplemodal onClick={() => setModal(false)} open={modal}>
          <Userusingpath
            id={active}
            data={props.profiles.data ? profile : data}
          />
        </Simplemodal>
        <div className="aboutpathway">
          <div
            className="back_container_pathway"
            onClick={() => navigate(`/pathways`)}
          >
            <div className="icon-and-titles">
              <i className="fas fa-long-arrow-alt-left"></i> {title}
            </div>
          </div>
          <div className="aboutpathway__inner">
            {props.desc && (
              <div className="pathwayheader">
                <div className="pathwaytitle">
                  <Titlesubtitle
                    title={
                      residenceCountry
                        ? `Your Journey from ${residenceCountry} to ${country}`
                        : `Your Journey to ${country}`
                    }
                    subtitle={``}
                  />
                </div>
                <div className="seemorepathway">
                  <p>
                    <a href="/pathways"> See other Pathways </a>{' '}
                    <i className="fas fa-arrow-right" />
                  </p>
                </div>
              </div>
            )}
            <div className="innercore">
              <div className="leftpathway-container">
                {props.desc && (
                  <div
                    className={`description ${props.desc ? ' active' : ' '}`}
                  >
                    <div className="aboutpathwayimgbox">
                      <video
                        className="video-wrapper"
                        controls
                        width="100%"
                        height="100%"
                        // style={{ borderRadius: '20px', width: '100vw' }}
                      >
                        <source src={`${pathwayVideo}`} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                )}

                {props.stages && (
                  <div className="content-stages-bottom">
                    {myPathway.pathways.length < 1 ? (
                      <Empty
                        title="No Stages"
                        subtitle="No Stages for this Pathway, if stages were available, it will be visible here."
                      />
                    ) : (
                      <>
                        {myPathway.map(
                          ({ title: stageTitle, description, action }) => {
                            return (
                              <Singlepathway
                                pathway={title}
                                key={props.id}
                                id={props.id}
                                number={null}
                                title={stageTitle}
                                description={description}
                                actions={action}
                                logo={logo}
                              />
                            );
                          },
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>

              <div className="rightpathway-container">
                {/* estimated time */}
                <div className="progress-pathway-container-inner2">
                  <div className="estimated-time">
                    <b className="circle-green"> </b> ESTIMATED TIME
                  </div>

                  <div className="spent-time-value">
                    {`${myPathway.duration}  ${myPathway.unit}`}
                  </div>
                </div>
                {/* progress bar */}
                <div className="pathway-container-progress-right">
                  <div className="percentage-container-progress">
                    <div className="estimated-time">
                      <b className="circle-green"> </b> PROGRESS
                    </div>

                    {/* <div className="progress-percentage">{percentage}%</div> */}
                    <div className="progress-percentage">0%</div>

                    <div className="text-percentage-sub">
                      The best time to start your migration journey is now
                    </div>
                  </div>
                </div>
                {/* time spent */}
                <div className="progress-pathway-container-inner2">
                  <div className="estimated-time">
                    <b className="circle-green"></b> TIME SPENT
                  </div>
                  <div className="spent-time-value">
                    {day ? day : '0'} Day(s)
                  </div>
                </div>
              </div>
            </div>
            {props.desc && (
              <div className="aboutpathwayclaim">
                <div className="progressdetailsbutton progressoverview">
                  <Platformbutton
                    type="overview"
                    name="Overview"
                    click={() => navigate(`/pathways`)}
                  />
                </div>

                <div className="progressdetailsbutton progressprocedure">
                  {adminUsername === 'Duke' ||
                  adminUsername === 'adefisolabamisile' ||
                  adminUsername === 'olusolavesti' ? (
                    <Platformbutton
                      type="procedures"
                      name="Procedures"
                      click={() =>
                        navigate(`/pathwaysjourney/${id}`, {
                          state: {
                            id,
                            title,
                            logo,
                            description,
                            stagesObject,
                            creator,
                            pathwayCost,
                            country,
                            month,
                            pathwayVideo,
                          },
                        })
                      }
                    />
                  ) : subPathwayObject === null || isActive === false ? (
                    <Platformbutton
                      type="procedures"
                      name="Procedures"
                      click={() => setModalTest(true)}
                    />
                  ) : (
                    <Platformbutton
                      type="procedures"
                      name="Procedures"
                      click={() =>
                        !ispathwaytemplateIncluded
                          ? setJoinPathway(true)
                          : navigate(`/pathwaysjourney/${id}`, {
                              state: {
                                id,
                                title,
                                logo,
                                description,
                                stagesObject,
                                creator,
                                pathwayCost,
                                country,
                                month,
                                pathwayVideo,
                              },
                            })
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* pathways description */}
        <div className="section-pathway aboutpathway__bottom">
          <Smalltitle title={title} />

          <p className="aboutpathway__bottomcontent">{myPathway.description}</p>
        </div>

        <div className="overall">
          <div className="daysbutton_container">
            <div className="left_daysbutton">
              <Pathsubscribers
                data={props.profiles.data ? shuffled : data}
                click={value => {
                  setActive(value);
                  setProfile(shuffled.filter(item => item.id === value)[0]);
                  setModal(true);
                }}
              />
            </div>
          </div>
          <div className="seemorepathway-button">
            <Platformbutton
              type="secondary"
              name="See other Pathways"
              click={() => navigate('/pathways')}
            />
          </div>
        </div>

        {/* growth communities section */}
        <section className="sectionpathway ">
          <div className="about-sectionpathway">
            <div className="titlesubtitle-left">
              <Titlesubtitle
                title="Growth Communities"
                subtitle="Join and start your migration journey"
              />
            </div>

            <div className="seemore-right ml-auto">
              <p>
                <a href="#" onClick={handleSeeAllGroupClick}>
                  {' '}
                  See All{' '}
                </a>{' '}
                <i className="fas fa-arrow-right" />
              </p>
            </div>
          </div>
          <div className="growthcom__inner">
            <div className="growthcom__content">
              {(getRandomItemsgroupData() ?? []).map((data, index) => (
                <Growthcommunity
                  image={data.profileUrl}
                  description={data.description}
                  id={data.id}
                  key={index}
                  name={data.name}
                  username={data.username}
                  type={data.type}
                  isActive={isActive}
                  planType={planType}
                />
              ))}
            </div>
          </div>
          {/* props.isactive && props.plantype === group.type) || props.plantype ===
          'PREMIUM' */}
          <div className="seemore-down">
            <Platformbutton
              name="See All"
              type="secondary"
              click={handleSeeAllGroupClick}
            />
          </div>
          {isGroupPopupOpen && (
            <Largemodal
              open={isGroupPopupOpen}
              onClick={() => setGroupPopupOpen(false)}
            >
              <>
                <Titlesubtitle
                  title="Growth Communities"
                  subtitle="Join and start your migration journey"
                />

                <div className="growthmodal">
                  {(getAllItemsgroupData() ?? []).map((data, index) => (
                    <Growthcommunity
                      image={data.profileUrl}
                      description={data.description}
                      id={data.id}
                      key={index}
                      name={data.name}
                      username={data.username}
                      type={data.type}
                      isActive={isActive}
                      planType={planType}
                    />
                  ))}
                </div>
              </>
            </Largemodal>
          )}
        </section>

        {/* webinar card section */}
        <section className="sectionpathway about-sectionpathway">
          <div className="d-flex justify-content-between  margin">
            <div className="titlesubtitle-left">
              <Titlesubtitle
                title="Immigration Webinar"
                subtitle="Register and start your migration journey"
              />
            </div>

            <div className="seemore-right ml-auto">
              <p>
                <a href="#" onClick={handleSeeAllWebinarClick}>
                  {' '}
                  See All{' '}
                </a>{' '}
                <i className="fas fa-arrow-right" />
              </p>
            </div>
          </div>

          <div className="webinar__inner">
            <div className="webinar__content ">
              {loading && <Loader />}
              {success && (
                <>
                  {getRandomItemswebinarData().map(
                    ({ id, title, featuring, date, type, image, link }) => (
                      <Webinarcard
                        key={id}
                        {...{
                          id,
                          title,
                          featuring,
                          date,
                          type,
                          image,
                          link,
                        }}
                      />
                    ),
                  )}
                </>
              )}
            </div>
          </div>

          {/* Render the Immigration Blog popup if isWebinarPopupOpen is true */}
          {isWebinarPopupOpen && (
            <Largemodal
              open={isWebinarPopupOpen}
              onClick={() => setWebinarPopupOpen(false)}
            >
              <>
                <Titlesubtitle
                  title="Immigration Webinar"
                  subtitle="Register and start your migration journey"
                />
                <div className="webinarmodal ">
                  {loading && <Loader />}
                  {success && (
                    <>
                      {getRandomAllwebinarData().map(
                        ({ id, title, featuring, date, type, image, link }) => (
                          <Webinarcard
                            key={id}
                            {...{
                              id,
                              title,
                              featuring,
                              date,
                              type,
                              image,
                              link,
                            }}
                          />
                        ),
                      )}
                    </>
                  )}
                </div>
              </>
            </Largemodal>
          )}
        </section>

        {/* Immigration Blog section */}
        <section className="sectionpathway about-sectionpathway">
          <div className="d-flex justify-content-between margin">
            <div className="titlesubtitle-left">
              <Titlesubtitle
                title="Immigration Blog"
                subtitle="Catch up on what you have missed."
              />
            </div>

            <div className="seemore-right ml-auto">
              <p>
                {/* https://vesti.substack.com/ */}
                <a href="#" onClick={handleSeeAllBlogClick}>
                  {' '}
                  See More{' '}
                </a>
                <i className="fas fa-arrow-right" />
              </p>
            </div>
          </div>
          <div
            className="services-grid"
            style={{ gap: '30px', marginBottom: '50px' }}
          >
            {loading && <Loader />}
            {success && (
              <>
                {getRandomItemsblogData().map(
                  ({ id, title, image, description, date, category, url }) => (
                    <BlogCard
                      key={id}
                      {...{
                        title,
                        image,
                        description,
                        date,
                        category,
                        url,
                      }}
                    />
                  ),
                )}
              </>
            )}
          </div>

          {/* Render the Immigration Blog see all popup if isPopupOpen is true  */}
          {isBlogPopupOpen && (
            <Largemodal open={isBlogPopupOpen} onClick={handleCloseBlogPopup}>
              <Titlesubtitle
                title="Immigration Blog"
                subtitle="Catch up on what you have missed."
              />
              <div className="services-grid">
                {loading && <Loader />}
                {success && (
                  <>
                    {getRandomAllblogData().map(
                      ({
                        id,
                        title,
                        image,
                        description,
                        date,
                        category,
                        url,
                      }) => (
                        <BlogCard
                          key={id}
                          {...{
                            title,
                            image,
                            description,
                            date,
                            category,
                            url,
                          }}
                        />
                      ),
                    )}
                  </>
                )}
              </div>
            </Largemodal>
          )}
        </section>

        {/* migration fees section */}
        <section className="sectionpathway about-sectionpathway">
          <div className="d-flex justify-content-between margin">
            <div className="titlesubtitle-left">
              <Titlesubtitle
                title="Migration Fees"
                subtitle="Vesti provides numerous services."
              />
            </div>

            <div className="seemore-right ml-auto">
              <p>
                {/* /merchants */}
                <a href="#" onClick={handleSeeAllMerchantClick}>
                  {' '}
                  See All{' '}
                </a>{' '}
                <i className="fas fa-arrow-right" />
              </p>
            </div>
          </div>

          <div className="merchant-scroll">
            <div className="d-flex">
              {getRandomItemsMerchantData().map((item, index) => (
                <div
                  // onClick={ navigate(`/merchants?price=${item.price}`)}
                  key={index}
                  className="p-3 d-flex flex-column justify-content-center align-items-center mr-3 merchant-card"
                  style={{
                    background: '#FFFFFF',
                    border: '1px solid #EAEAEA',
                    boxShadow: '0px 0px 5px #F5F5F5',
                    borderRadius: '10px',
                    height: '250px',
                    minWidth: '235px',
                  }}
                >
                  <a href={`/merchants?price=${item.price}`}>
                    <img
                      src={item.image}
                      alt="profile"
                      style={{
                        height: '60px',
                        width: '60px',
                        borderRadius: '50%',
                      }}
                      className="d-block bg-dark mb-1"
                    />
                  </a>

                  <div className="text-uppercase mb-3">{item.name}</div>
                  <p className="text-center mb-3">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="merchant-scroll">
            <div className="d-flex">
              {/* Render the popup if isPopupOpen is true */}
              {isMerchantPopupOpen && (
                <Largemodal
                  open={isMerchantPopupOpen}
                  onClick={handleCloseMerchantPopup}
                >
                  <div className="services-grids">
                    <>
                      <Titlesubtitle
                        title="Migration Fees"
                        subtitle="Vesti provides numerous services."
                      />
                      {getRandomAllMerchantData().map((item, index) => (
                        <div
                          // onClick={ navigate(`/merchants?price=${item.price}`)}
                          key={index}
                          className="p-3 d-flex flex-column justify-content-center align-items-center mr-3 merchant-random"
                          style={{
                            background: '#FFFFFF',
                            border: '1px solid #EAEAEA',
                            boxShadow: '0px 0px 5px #F5F5F5',
                            borderRadius: '10px',
                            height: '250px',
                            minWidth: '235px',
                          }}
                        >
                          <a href={`/merchants?price=${item.price}`}>
                            <img
                              src={item.image}
                              alt="profile"
                              style={{
                                height: '60px',
                                width: '60px',
                                borderRadius: '50%',
                              }}
                              className="d-block bg-dark mb-1"
                            />
                          </a>

                          <div className="text-uppercase mb-3">{item.name}</div>
                          <p className="text-center mb-3">{item.description}</p>
                        </div>
                      ))}
                    </>
                  </div>
                </Largemodal>
              )}
            </div>
          </div>
        </section>

        {/* testimonials section */}
        <section className="sectionpathway about-sectionpathway">
          <div className="d-flex justify-content-between margin">
            <div className="titlesubtitle-left">
              <Titlesubtitle
                title="Testimonials"
                subtitle="Check out success stories from users who moved abroad."
              />
            </div>

            <div
              className="seemore-right ml-auto"
              style={{ marginLeft: 'auto' }}
            >
              <p>
                <a href="#" onClick={handleSeeAllTestimonialClick}>
                  {' '}
                  See More{' '}
                </a>{' '}
                <i className="fas fa-arrow-right" />
              </p>
            </div>
          </div>

          <div className="testimony__inner">
            <div
              className="testimony__content widthcardscroll"
              style={{ overflowY: 'auto' }}
            >
              {loading && <Loader />}
              {success && (
                <>
                  {getRandomItemstestimonialData().map(
                    ({ id, name, testimony, description, date }) => (
                      <SingleTestimonyCard
                        key={id}
                        {...{
                          id,
                          name,
                          testimony,
                          description,
                          date,
                        }}
                      />
                    ),
                  )}
                </>
              )}
            </div>
          </div>
          {/* Render the popup if isPopupOpen is true */}
          {isTestimonialPopupOpen && (
            <Largemodal
              open={isTestimonialPopupOpen}
              onClick={() => setTestimonialPopupOpen(false)}
            >
              <>
                <Titlesubtitle
                  title="Testimonials"
                  subtitle="Check out success stories from users who moved abroad."
                />
                <div className="testimonymodal">
                  {loading && <Loader />}
                  {success && (
                    <>
                      {getRandomAlltestimonialData().map(
                        ({ id, name, testimony, description, date }) => (
                          <SingleTestimonyCard
                            key={id}
                            {...{
                              id,
                              name,
                              testimony,
                              description,
                              date,
                            }}
                          />
                        ),
                      )}
                    </>
                  )}
                </div>
              </>
            </Largemodal>
          )}
        </section>
      </Layout>
    );
  }
};

const Subscription = props => {
  const [check, setCheck] = useState(false);

  switch (props.subscribe.step) {
    case 0:
      return (
        <Newprompt img={subs}>
          <Titlesubtitle
            title="Claim your 30days subscription"
            subtitle={`You are about to subscribe for this ${props.title}, it is free for thirty days after that, you will be debited $${props.cost} from your Vesti wallet, click this check to accept our terms and condition`}
          />

          <div className="pathwaysubscription">
            <label className="form-control">
              <input
                type="checkbox"
                name="checkbox"
                onChange={() => setCheck(!check)}
                checked={check}
              />
              I agree to Vesti’s terms & conditions.
            </label>
            <button
              className="pathwaysubscription__button"
              onClick={() => props.onClick()}
            >
              Yes, I Want to Subscribe
            </button>
          </div>
        </Newprompt>
      );
    case 1:
      return (
        <Success
          title="Subscription Successful"
          subtitle="You just claimed your fee trial subscription for this pathway."
          button="Okay, Thank You."
          onClick={() => props.closeSub()}
        />
      );
    default:
      return <>Nothing to see </>;
  }
};

export const SingleTestimonyCard = props => {
  return (
    <>
      <div className="testimonialcard">
        <div className="testimonialcard__top">
          <img
            className="img-fluid"
            src={props.image ? props.image : dummy}
            alt="image"
          />
          <p className="title">
            {props.name}
            <span className="text-muted">{props.date}</span>
          </p>
        </div>
        <p className="testimonialcard__text">{props.testimony}</p>
      </div>
    </>
  );
};

const mapStateToProps = ({ pathway, profile }) => {
  const { desc, stages } = pathway;
  const { profiles } = profile;
  return {
    desc,
    stages,
    profiles,
  };
};

export default connect(mapStateToProps, {
  openDescBox,
  openStagesBox,
  fetchProfiles,
  stripeUsdViaCardPathway,
})(Aboutpathway);
