import {
  OPEN_ABOUT,
  OPEN_FEEDS,
  OPEN_MEMBERS,
  OPEN_EVENTS,
  FETCH_ALL_GROUPS,
  FETCH_FEEDS_CHATS,
  SET_ACTIVE_CHAT,
  SEND_MESSAGE,
  RECIEVE_MESSAGE,
} from 'appRedux/constants';
import api from 'appRedux/api';

export const openAboutBox = () => ({
  type: OPEN_ABOUT,
});

export const openFeedsBox = () => ({
  type: OPEN_FEEDS,
});

export const openMembersBox = value => ({
  type: OPEN_MEMBERS,
});

export const openEventsBox = () => ({
  type: OPEN_EVENTS,
});

export const fetchGroups = () => async dispatch => {
  try {
    const response = await api.get('/group/all-groups');
    const data = response.data.data.data;

    dispatch({
      type: FETCH_ALL_GROUPS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};





export const fetchFeedsChat = () => async dispatch => {
  try {
    const response = await api.get('/group/all-groups');
    const data = response.data.data.data;

    dispatch({
      type: FETCH_FEEDS_CHATS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};



// chats
export const setActiveChat = (userId) => ({
  type: SET_ACTIVE_CHAT,
  payload: userId,
});

export const sendMessage = (message) => ({
  type: SEND_MESSAGE,
  payload: message,
});

export const recieveMessage = (message) => ({
  type: RECIEVE_MESSAGE,
  payload: message,
});
