import {
  NGN_BANKS,
  VERIFY_NGNACCOUNT,
  WALLET_TRANS,
  WALLET_TRANSACTION,
  SUCCESSFUL_TRANSACTION,
  FETCH_START,
  FETCH_SUCCESS,
  USER_NGN_BANKS,
} from 'appRedux/constants';
import api from 'appRedux/api';
import { errorMessage, removeCommaAmount } from 'helpers/utils';
import {
  handleError,
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from './Common';
import { navigate } from '@reach/router';
import _ from 'lodash';

// deposit
export const depositViaPays = (payld, cb) => {
  return dispatch => {
    dispatch({ type: WALLET_TRANSACTION, payload: { transLoading: true } });
    const url = '/wallet/card/deposit';
    const payload = {
      ...payld,
      currency: 'NGN',
      // redirectURL: 'https://app.wevesti.com/bank',
    };
    api
      .post(url, payload)
      .then(res => {
        openNotificationWithIcon(
          'Payment link succesfully generated.',
          'Deposit',
          'success',
        );
        dispatch({
          type: WALLET_TRANSACTION,
          payload: { transLoading: false, transMessage: res.data.message },
        });
        setTimeout(() => {
          window.open(res.data.data.authorization_url);
          // window.open(res.data.data.paymentURL);
          cb();
        }, 200);
      })
      .catch(error => {
        dispatch({
          type: WALLET_TRANSACTION,
          payload: {
            transLoading: false,
            transMessage: error.data.message || error.message,
          },
        });
        openNotificationWithIconErr(error.data.message, 'Deposit', 'error');
      });
  };
};

export const depositViaCard = (payld, cb) => {
  return dispatch => {
    dispatch({ type: WALLET_TRANSACTION, payload: { transLoading: true } });
    // const url = 'paystack/wallet/pay';
    const url = '/wallet/card/deposit';
    const payload = {
      ...payld,
      // redirectURL: 'https://app.wevesti.com/bank',
    };
    api
      .post(url, payload)
      .then(res => {
        openNotificationWithIcon(
          'Payment link succesfully generated.',
          'Deposit',
          'success',
        );
        dispatch({
          type: WALLET_TRANSACTION,
          payload: { transLoading: false, transMessage: res.data.message },
        });
        setTimeout(() => {
          window.open(res.data.data.paymentURL);
          cb();
        }, 200);
      })
      .catch(error => {
        dispatch({
          type: WALLET_TRANSACTION,
          payload: {
            transLoading: false,
            transMessage: error.data.message || error.message,
          },
        });
        openNotificationWithIconErr(error.data.message, 'Deposit', 'error');
      });
  };
};

// withdrawal
export const withdrawNGN = (payload, cb) => {
  // console.log('I am NOT /new');
  return dispatch => {
    dispatch({ type: WALLET_TRANS, payload: { transLoading: true } });
    const url = '/wallet/withdraw';
    api
      .post(url, payload)
      .then(res => {
        openNotificationWithIcon(
          'A transaction has been initiated',
          'Withdrawal',
          'success',
        );
        dispatch({
          type: WALLET_TRANS,
          payload: { transLoading: false, transMessage: res.data.message },
        });
        setTimeout(() => {
          payload?.type === 'external' ? cb(6) : cb(5);
        }, 100);
      })
      .catch(error => {
        dispatch({
          type: WALLET_TRANS,
          payload: {
            transLoading: false,
            transMessage: error.data.message || error.message,
          },
        });
        openNotificationWithIconErr(error.data.message, 'Withdrawal', 'error');
        setTimeout(() => {
          cb(7);
        }, 100);
      });
  };
};

//Withdrawal to one time account
export const withdrawNGNTwo = (payload, cb) => {
  return dispatch => {
    dispatch({ type: WALLET_TRANS, payload: { transLoading: true } });
    const url = '/wallet/withdraw';
    api
      .post(url, payload)
      .then(res => {
        openNotificationWithIcon(
          'A transaction has been initiated',
          'Withdrawal',
          'success',
        );
        dispatch({
          type: WALLET_TRANS,
          payload: { transLoading: false, transMessage: res.data.message },
        });
        navigate('/bank');
        setTimeout(() => {
          payload?.type === 'external' ? cb(6) : cb(5);
        }, 100);
      })
      .catch(error => {
        dispatch({
          type: WALLET_TRANS,
          payload: {
            transLoading: false,
            transMessage: error.data.message || error.message,
          },
        });
        openNotificationWithIconErr(error.data.message, 'Withdrawal', 'error');
        setTimeout(() => {
          cb(7);
        }, 100);
      });
  };
  // console.log('I am /new');
};

// transfer vesti to vesti
export const transferNGNCash = ({
  amount,
  userId,
  currency,
  transactionOtp,
  stopSubmit,
}) => dispatch => {
  dispatch({ type: FETCH_START });
  const amountInCentsOrKobo = removeCommaAmount(amount) * 100;
  const url = '/wallet/vesti-to-vesti-transfer';
  const payload = {
    amount: amountInCentsOrKobo,
    currency,
    userId,
    transactionOtp,
  };
  dispatch({ type: WALLET_TRANSACTION, payload: { transLoading: true } });
  api
    .post(url, payload)
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: FETCH_SUCCESS, payload: true });
        dispatch({
          type: WALLET_TRANS,
          payload: { transMessage: data.message, transLoading: false },
        });
        navigate('/bank');
        //  show success card
        dispatch({ type: SUCCESSFUL_TRANSACTION, payload: 14 });
      } else {
        dispatch(
          handleError(_.isEmpty(data.message) ? 'Network Error' : data.message),
        );
        //  show success card
        dispatch({ type: SUCCESSFUL_TRANSACTION, payload: 14 });
      }
    })
    .catch(error => {
      if (error.data?.errors) {
        openNotificationWithIconErr(
          errorMessage(error),
          'Cash Transfer',
          'error',
        );
        dispatch({
          type: WALLET_TRANS,
          payload: { transMessage: error.data.message, transLoading: false },
        });
      } else {
        openNotificationWithIconErr(
          errorMessage(error),
          'Cash Transfer',
          'error',
        );
        dispatch({
          type: WALLET_TRANS,
          payload: { transMessage: errorMessage(error), transLoading: false },
        });
        //  show error card
        dispatch({ type: SUCCESSFUL_TRANSACTION, payload: 15 });
      }
    })
    .finally(() => stopSubmit());
};

// Verify account
export const verifyUserNairaAccount = (payload, cb, cbb) => {
  return dispatch => {
    cbb && cbb(true);

    api
      .post(`/bank/verify-bank-details`, {
        bankCode: payload.code,
        accountNumber: payload.account,
      })
      .then(res => {
        // console.log('Verify Response: ', res);
        dispatch({ type: VERIFY_NGNACCOUNT, payload: { verifyAccount: true } });
        setTimeout(() => {
          cb(res.data?.data);
          cbb && cbb(false);
        }, 100);
        dispatch({ type: WALLET_TRANS, payload: { loading: false } });
      })
      .catch(error => {
        dispatch({
          type: VERIFY_NGNACCOUNT,
          payload: { verifyAccount: false },
        });
        dispatch({ type: WALLET_TRANS, payload: { loading: false } });
        cbb && cbb(false);
      });
  };
};


// get banks

export const getNairaBanks = () => {
  return dispatch => {
    dispatch({ type: WALLET_TRANS, payload: { loading: true } });
    api
      // .get(`/supported-banks/list?currency=NGN`)
      .get(`/bank/provider-banks?currency=NGN`)
      .then(res => {
        dispatch({
          type: NGN_BANKS,
          payload: {
            ngnBanks: res.data?.data
              // .filter(
              //   item => !item.name.includes(['Opay', 'Kuda', 'VFD', 'Palmpay']),
              // )
              .map(item => ({
                value: item.code,
                label: item.name,
              })),
          },
        });
        dispatch({ type: WALLET_TRANS, payload: { loading: false } });
      })
      .catch(error =>
        dispatch({ type: WALLET_TRANS, payload: { loading: false } }),
      );
  };
};

export const getUserNairaBanks = () => {
  return dispatch => {
    dispatch({ type: WALLET_TRANS, payload: { loading: true } });
    api
      .get(`bank/user/beneficiaries?currency=NGN`)
      .then(res => {
        dispatch({
          type: USER_NGN_BANKS,
          payload: { userNgnBanks: res.data?.data?.data },
        });
        dispatch({ type: WALLET_TRANS, payload: { loading: false } });
      })
      .catch(error => {
        dispatch({ type: WALLET_TRANS, payload: { loading: false } });
      });
  };
};

// add nigerian bank
export const addNigerianBank = (payload, cb) => {
  return dispatch => {
    dispatch({ type: WALLET_TRANS, payload: { loading: true } });
    api
      .post('/bank/save-beneficiary', {
        bankCode: payload.bankCode,
        bankName: payload.bankName,
        currency: 'NGN',
        accountNumber: payload.accountNumber,
        firstName: payload.firstName,
        lastName: payload.lastName,
      })
      .then(res => {
        openNotificationWithIcon(
          'Add Beneficiary',
          res.data.message,
          'success',
        );
        dispatch({ type: WALLET_TRANS, payload: { loading: false } });
        cb();
      })
      .catch(error => {
        dispatch({ type: WALLET_TRANS, payload: { loading: false } });
        openNotificationWithIconErr(errorMessage(error), 'Error', 'error');
      });
  };
};

// transfer via email

export const withdrawNGNEmail = (payload, cb) => {
  return dispatch => {
    dispatch({ type: WALLET_TRANS, payload: { transLoading: true } });
    const url = '/wallet/transfer/transferMoneyToNonExistingUserEmail';
    api
      .post(url, payload)
      .then(res => {
        openNotificationWithIcon(res.data.message, 'Transfer', 'success');
        dispatch({
          type: WALLET_TRANS,
          payload: {
            transLoading: false,
            transMessage: res.data.message,
            transSucData: res.data.transferCode,
          },
        });
        setTimeout(() => {
          cb(11);
        }, 100);
      })
      .catch(error => {
        dispatch({
          type: WALLET_TRANS,
          payload: {
            transLoading: false,
            transMessage: error.data.message || error.message,
          },
        });
        openNotificationWithIconErr(error.data.message, 'Transfer', 'error');
        setTimeout(() => {
          cb(8);
        }, 100);
      });
  };
};

// verify & claim transfer via email

export const claimNGNEmail = (payload, cb) => {
  return dispatch => {
    dispatch({ type: WALLET_TRANS, payload: { transLoading: true } });
    const url = '/wallet/transfer/verifyCodeSendAdminNotification';
    api
      .post(url, payload)
      .then(res => {
        openNotificationWithIcon(res.data.message, 'Transfer', 'success');
        dispatch({
          type: WALLET_TRANS,
          payload: { transLoading: false, transMessage: res.data.message },
        });
        setTimeout(() => {
          cb(1);
        }, 100);
      })
      .catch(error => {
        dispatch({
          type: WALLET_TRANS,
          payload: {
            transLoading: false,
            transMessage: error.data.message || error.message,
          },
        });
        openNotificationWithIconErr(error.data.message, 'Transfer', 'error');
        setTimeout(() => {
          cb(2);
        }, 100);
      });
  };
};
