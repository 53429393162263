import React from 'react';

import { useState, useEffect } from 'react';
import groupmembergrowth from 'assets/pathways/groupusergrowth.svg';
import '../Admin.css';
import { navigate } from '@reach/router';
import { openUpgradeBox } from 'appRedux/actions/update';
import { useDispatch, useSelector } from 'react-redux';
import { shuffleArray } from 'helpers/utils';
import { Platformbutton } from 'components/common/button/button';
import { GrowthMembers } from 'components/pathway/pathwayscomp';
import { closeLoan } from 'appRedux/actions/domore';
import { useUserData } from 'helpers/hooks';
import { Largemodal } from 'components/common/simplifiedmodal';
import PathwaysubSteps from '../Pathways/pathwaysubSteps';
import api from 'appRedux/api';
import { updatePrevLocation } from 'appRedux/actions/location';

export const Growthcommunity = props => {
  const dispatch = useDispatch();
  const { userData } = useUserData();
  dispatch(closeLoan(true));
  const [openModal, setOpenModal] = useState(false);

  const name = props.name;
  function getNewTypeByName(name) {
    if (name === 'Business Class') {
      return 'BUSINESS';
    } else if (name === '1000 Students') {
      return 'FREE';
    } else if (name === '0-1 Candidate US') {
      return 'PREMIUM';
    } else if (name === 'French TEF Group') {
      return 'PREMIUM';
    } else if (name === 'IELTs Prep Group') {
      return 'PREMIUM';
    } else if (name === 'First Class') {
      return 'PREMIUM';
    } else {
      // Return a default value if the name doesn't match any condition
      return 'FREE';
    }
  }

  // Assigning the returned value to a variable

  const newType = getNewTypeByName(props.name);

  var openUpdateModal = () => {
    newType === 'FREE' ||
    (props.isActive && props.planType === newType) ||
    props.planType === 'PREMIUM' ||
    props.userData?.email === 'bigduke2045@gamil.com' ||
    props.userData?.email === 'philip@wevesti.com' ||
    props.userData?.email === 'femi@wevesti.com' ||
    props.userData?.email === 'obodoezemaryjane@yahoo.com' ||
    props.userD?.email === 'anita@wevesti.com'
      ? navigate(`/group/${props.id}`)
      : setOpenModal(true);
  };

  return (
    <>
      <Largemodal onClick={() => setOpenModal(false)} open={openModal}>
        <PathwaysubSteps />
      </Largemodal>

      <div
        className={`growthcom domorecard ${
          newType === 'FREE'
            ? 'color-free'
            : newType === 'PREMIUM'
            ? 'color-premium'
            : newType === 'BUSINESS'
            ? 'color-business'
            : ''
        }`}
        key={props.name}
        onClick={() => {
          // newType.includes('vesti_user')
          //   ?
          openUpdateModal();
          // : setOpenModal(true);

          // navigate(`/group/${props.id}`);
        }}
      >
        {/* {typeof props.loan} */}

        <div className="growthcom-title">
          <div className="left-title-growth">
            <p className=""> {props.name}</p>
          </div>

          <div className="right-title-growth">
            <p className="">{newType}</p>
          </div>
        </div>
        {/* <p>{props.data.subtitle}</p> */}

        <div className="main-body">
          <p>{props.description}</p>
        </div>

        <div className="joinnow-container">
          <div className="webinar-left-title">
            {/* <div style={{ display: 'flex' }}> */}
            <div>
              <img
                height="40%"
                width="80%"
                src={groupmembergrowth}
                alt="do more"
              />
            </div>
            <div className="group-member-growth">
              <p>5K+ Members</p>
            </div>
            {/* </div> */}
          </div>
          <div className="join-group-container">
            <p className="join-now-group">
              Join Now <i className="fas fa-arrow-right" />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export const NewGrowthcommunity = props => {
  const dispatch = useDispatch();
  const { userData } = useUserData();
  dispatch(closeLoan(true));
  const [openModal, setOpenModal] = useState(false);

  const [isActive, setIsActive] = useState(false);
  const [planType, setPlanType] = useState('');

  useEffect(() => {
    api
      .get(`pathway/plan-and-join`)
      .then(res => {
        setPlanType(res.data?.data?.plan?.type);
        setIsActive(
          res.data.data.plan === null ? false : res.data.data.plan.active,
        );
      })
      .catch(err => err);
  }, []);
  // console.log('props TYPE', props.type);

  const name = props.name;
  function getNewTypeByName(name) {
    if (name === 'Business Class') {
      return 'BUSINESS';
    } else if (name === '1000 Students') {
      return 'FREE';
    } else if (name === '0-1 Candidate US') {
      return 'PREMIUM';
    } else if (name === 'French TEF Group') {
      return 'PREMIUM';
    } else if (name === 'IELTs Prep Group') {
      return 'PREMIUM';
    } else if (name === 'First Class') {
      return 'PREMIUM';
    } else {
      // Return a default value if the name doesn't match any condition
      return 'FREE';
    }
  }

  // Assigning the returned value to a variable

  const newType = getNewTypeByName(props.name);

  // console.log(newType);
  // console.log('props', props);

  {
    /* ? dispatch(openUpgradeBox()) */
  }
  const handleClick = id => {
    dispatch(updatePrevLocation(props.previousPath));
    navigate(`/group/${id}`);
  };

  var openUpdateModal = id => {
    newType === 'FREE' ||
    (isActive && planType === newType) ||
    planType === 'PREMIUM'
      ? handleClick(id)
      : setOpenModal(true);
  };

  const maxTitleWidth = 220;

  const truncatedTitle = description =>
    description.length > maxTitleWidth
      ? `${description.substring(0, maxTitleWidth)}...`
      : description;

  return (
    <>
      <Largemodal onClick={() => setOpenModal(false)} open={openModal}>
        <PathwaysubSteps />
      </Largemodal>

      {/* <div
        style={{ background: '#ECF8FF', padding: '12px' }}
       
        key={props.name}
        onClick={() => {
   
          openUpdateModal();
     
        }}
      > */}
      <div
        style={{ display: 'flex ', gap: '10px', width: '100%' }}
        className="myGroupGrid"
      >
        {props.allGroups.map(data => {
          return (
            <div
              style={{ background: '#ECF8FF', padding: '12px', width: '100%' }}
              key={data.name}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  marginBottom: '0.75rem',
                }}
              >
                <p
                  style={{
                    color: '#1481BA',
                    fontSize: '15px',
                    fontWeight: '700',
                  }}
                >
                  {data.name}
                </p>
                <p
                  style={{
                    color: '#1481BA',
                    fontSize: '12px',
                    fontWeight: '700',
                    border: '1px solid #1481BA',
                    background: '#C0E9FF',
                    padding: '4px 12px',
                    borderRadius: '6px',
                  }}
                >
                  {data.type}
                </p>
              </div>

              <p style={{ color: '#1481BA', fontSize: '15px' }}>
                {truncatedTitle(data.description)}
              </p>

              <div
                style={{
                  marginTop: '0.75rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    // marginTop: '0.75rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                  }}
                >
                  <img
                    src={groupmembergrowth}
                    alt=""
                    style={{ height: '40%', width: '5rem' }}
                  />
                  <p style={{ fontSize: '13px' }}>5K+ Members</p>
                </div>

                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#1481BA',
                    cursor: 'pointer',
                    display: 'flex',
                    gap: '4px',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    openUpdateModal(data.id);
                  }}
                >
                  Join now <i className="fas fa-arrow-right" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export const Growthprogresscommunity = props => {
  const dispatch = useDispatch();
  /* eslint-disable no-unused-vars */
  const [active, setActive] = useState(0);
  /* eslint-disable no-unused-vars */
  const [modal, setModal] = useState(false);
  /* eslint-disable no-unused-vars */
  const [profile, setProfile] = useState([]);

  const data = [
    {
      firstName: 'Adewole',
      lastName: 'Philemon',
      email: 'aphilemon.aa@gmail.com',
      username: 'blanconero',
    },
    {
      firstName: 'Seyi',
      lastName: 'Otun',
      email: 'seyifotun@gmail.com',
      username: 'seyiotun',
    },
    {
      firstName: 'Afolayan',
      lastName: 'Samuel ',
      email: 'toluafo67@gmail.com',
      username: 'teetat',
    },
  ];

  var shuffled =
    props.profiles.data && shuffleArray(props.profiles.data).slice(0, 3);

  // console.log('props TYPE', props.type);
  const newType = 'PAID'; //ECONOMY, PREMIUM, BUSINESS
  // console.log('props', props);
  return (
    <div
      className={`growth-progress ${
        newType === 'FREE'
          ? 'color-free'
          : newType === 'PREMIUM'
          ? 'color-premium'
          : newType === 'BUSINESS'
          ? 'color-business'
          : ''
      }`}
      key={props.id}
      onClick={() => {
        newType.includes('vesti_user')
          ? dispatch(openUpgradeBox())
          : navigate(`/group/${props.id}`);
      }}
    >
      {/* {typeof props.loan} */}

      <div className="">
        <div
          className="growth-title"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div className="growth-title-progress">{props.name}</div>

          <div className="growth-plan-progress"> {newType} </div>
        </div>

        <div className="groupmember-percentage">
          <GrowthMembers
            data={props.profiles.data ? shuffled : data}
            click={value => {
              setActive(value);
              setProfile(shuffled.filter(item => item.id === value)[0]);
              setModal(true);
            }}
          />
        </div>

        <div className="join-nowbtn">
          <Platformbutton
            type="normal"
            name="Join Now"
            click={() => props.openStagesBox()}
          />
        </div>
      </div>
    </div>
  );
};
