import React, { useState, useEffect } from 'react';
import GGpending from './GGpending';
import GGTpin from './GGTpin';
import ActivatedUsercard from './ActivatedUsercard';
import './allcards.scss';
import { useSelector, useDispatch } from 'react-redux';
import { fetchGGTransactionsSuccess } from 'appRedux/actions/GGTransaction';
import Lazyloader from './lazyloader';

const PhysicalcardPage = props => {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { transactions } = useSelector(state => state.GGtransactions);

  useEffect(() => {
    dispatch(fetchGGTransactionsSuccess());

    setLoading(true);

    // After the specified duration, set loading back to false
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [dispatch]);

  if (loading) {
    return <Lazyloader />;
  } else {
    switch (props.physicalcardStatus) {
      case 'PENDING':
        return <GGpending />;

      case 'Delivered':
        return (
          <>
            {transactions?.length > 0 ? (
              <ActivatedUsercard
                total={total}
                setTotal={setTotal}
                // loading={loading}
                // setLoading={setLoading}
                transactions={transactions}
              />
            ) : (
              <GGTpin temporaryPin={props.temporaryPin} />
            )}
          </>
        );

      case 'DELIVERED':
        return (
          <>
            {transactions?.length > 0 ? (
              <ActivatedUsercard
                total={total}
                setTotal={setTotal}
                // loading={loading}
                // setLoading={setLoading}
                transactions={transactions}
              />
            ) : (
              <GGTpin temporaryPin={props.temporaryPin} />
            )}
          </>
        );
      default:
        return <GGpending />;
    }
  }
};

export default PhysicalcardPage;
