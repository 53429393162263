import React, { useState, useEffect } from 'react';
import Layout from 'components/common/DashboardLayout';
import { navigate } from '@reach/router';
import { FaArrowLeftLong } from 'react-icons/fa6';
import './uscis.scss';
import icons from 'assets/icons/usa.svg';
import uscisimg from 'assets/icons/uscisLogo.svg';
import { Platformbutton } from 'components/common/button/button';
import api from 'appRedux/api';
import { useParams } from '@reach/router';
import { useSelector } from 'react-redux';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { ShareMessage } from 'components/referal/referal';
import pathwayconfig from 'config.js';

export const CaseStatusCheck = () => {
  const [status, setStatus] = useState(null);
  const userD = useSelector(state => state.auth?.userData);

  const { caseControlNumber } = useParams();
  const [openShare, setOpenShare] = useState(false);

  const fetchStatus = async () => {
    try {
      const res = await api.get(
        `${pathwayconfig.baseURL}/pathway/get-foia-case-status?caseControlNumber=${caseControlNumber}&requestNumber=${caseControlNumber}&userId=${userD.id}`,
      );
      setStatus(
        res.data?.caseStatus?.CaseStatusResponse?.detailsEng?.actionCodeDesc,
      );

      //   setStatus(res.data?.caseHistory);
    } catch (error) {
      setStatus(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchStatus();
  }, [userD]);

  const responseText = status;

  // Function to dangerously set inner HTML while applying custom styles
  const createMarkup = () => {
    // Wrap the entire link with a span with the green color style
    const formattedText = responseText?.replace(
      /<a(.*?)>(.*?)<\/a>/g,
      (match, p1, p2) => {
        return `<span style="color: green;"><a${p1}>${p2}</a></span>`;
      },
    );
    return { __html: formattedText };
  };
  return (
    <Layout>
      <div style={{ height: '85vh' }} className="caseStatusPage">
        <Simplemodal
          onClick={() => {
            setOpenShare(false);
          }}
          open={openShare}
        >
          <ShareMessage
            title="Share Case Status"
            link={responseText}
            id={'#'}
            shares={'#'}
            shareNum={'#'}
          />
        </Simplemodal>
        <div
          onClick={() => {
            navigate('/services');
            //   props.setStep(0);
          }}
          style={{
            display: 'flex',
            color: '#67A948',
            alignItems: 'center',
            gap: '5px',
            fontWeight: '600',
            cursor: 'pointer',
          }}
        >
          <FaArrowLeftLong /> Case Status History
        </div>

        <div className="caseStudies">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <img src={uscisimg} alt="" style={{ width: '16rem' }} />
            <h1 style={{ fontSize: '20px', fontWeight: '600' }}>Case Status</h1>

            <div
              dangerouslySetInnerHTML={createMarkup()}
              style={{ fontSize: '14px', color: '#07000D', lineHeight: '24px' }}
            />
            {/* </p> */}

            <p
              style={{
                fontSize: '14px',
                color: '#07000D',
                lineHeight: '24px',
              }}
            >
              If you have any questions about your petition or the approval
              notice, please contact USCIS customer service at 1-800-375-5283
            </p>
          </div>

          <div className="mb-10"></div>

          <Platformbutton
            type="normal"
            name="Share status"
            click={() => {
              setOpenShare(true);
            }}
            disabled={false}
          />
          <div className="mb-4"></div>
          <Platformbutton
            type="secondary"
            name="Check history"
            click={() => {
              navigate('/casestatus-check');
            }}
            disabled={false}
          />
        </div>
      </div>
    </Layout>
  );
};
