import React from 'react';
import tick from '../../../assets/chats/tick-circle.svg';
import { Platformbutton } from 'components/common/button/button';
import './subFeature.scss';

const SubscriptionFeatures = props => {
  switch (props.membership) {
    case 'ECONOMY':
      return (
        <div className="subFeature">
          <p className="subFeature__maintitle">Plan details</p>

          <article>
            <p className="subFeature__title">
              Economy <span>Free</span>
            </p>

            <ul>
              <li>
                <img src={tick} alt="" />
                Free Immigration Account{' '}
              </li>
              <li>
                <img src={tick} alt="" />
                Free Feed on Advice
              </li>
              <li>
                <img src={tick} alt="" />
                Join Free Groups
              </li>
              <li>
                <img src={tick} alt="" />
                Access to Free Webinars
              </li>
              <li>
                <img src={tick} alt="" />
                Access to some Webinar Replay
              </li>
              <li>
                <img src={tick} alt="" />
                Normal Priority Email Support
              </li>
            </ul>
          </article>

          <div className="subFeature__button">
            <div className="subFeature__button__right">
              {' '}
              <Platformbutton
                name="Back"
                type="secondary"
                click={() => {
                  props.setOpnSubFeature(false);
                }}
              />
            </div>
            <Platformbutton
              type="normal"
              name="Continue"
              click={() => {
                props.setOpnSub(true);
              }}
            />
          </div>
        </div>
      );

    case 'BUSINESS':
      return (
        <div className="subFeature">
          <p className="subFeature__maintitle">Plan details</p>

          <article>
            <p className="subFeature__title">
              Business Class <span>${props.price}</span>
            </p>

            <ul>
              <li>
                <img src={tick} alt="" />
                Everything in Economy Plan
              </li>
              <li>
                <img src={tick} alt="" />
                Subscribe to up to 1 Pathway
              </li>
              <li>
                <img src={tick} alt="" />
                Exclusive Support with a Mentor
              </li>
              <li>
                <img src={tick} alt="" />
                Discount on Trainings & Events{' '}
              </li>
              <li>
                <img src={tick} alt="" />
                Discount on Extended Clarity Sessions{' '}
              </li>
              <li>
                <img src={tick} alt="" />
                20% OFF IELTS & French Coaching Class{' '}
              </li>
            </ul>
          </article>

          <div className="subFeature__button">
            <div className="subFeature__button__right">
              {' '}
              <Platformbutton
                name="Back"
                type="secondary"
                click={() => {
                  props.setOpnSubFeature(false);
                }}
              />
            </div>
            <Platformbutton
              type="normal"
              name="Continue"
              click={() => {
                props.setOpnSub(true);
              }}
            />
          </div>
        </div>
      );
    case 'PREMIUM':
      return (
        <div className="subFeature">
          <p className="subFeature__maintitle">Plan details</p>

          <article>
            <p className="subFeature__title">
              First Class <span>${props.price}</span>
            </p>

            <ul>
              <li>
                <img src={tick} alt="" />
                Everything in Economy Plan{' '}
              </li>
              <li>
                <img src={tick} alt="" />
                Subscribe to up to 3 Pathways{' '}
              </li>
              <li>
                <img src={tick} alt="" />
                Exclusive Support with a Mentor{' '}
              </li>
              <li>
                <img src={tick} alt="" />
                Discount on Trainings & Events{' '}
              </li>
              <li>
                <img src={tick} alt="" />
                Discount on Extended Clarity Sessions{' '}
              </li>
              <li>
                <img src={tick} alt="" />
                20% OFF IELTS & French Coaching Class{' '}
              </li>
            </ul>
          </article>

          <div className="subFeature__button">
            <div className="subFeature__button__right">
              {' '}
              <Platformbutton
                name="Back"
                type="secondary"
                click={() => {
                  props.setOpnSubFeature(false);
                }}
              />
            </div>
            <Platformbutton
              type="normal"
              name="Continue"
              click={() => {
                props.setOpnSub(true);
              }}
            />
          </div>
        </div>
      );

    default:
      return (
        <div className="subFeature">
          <p className="subFeature__maintitle">Plan details</p>

          <article>
            <p className="subFeature__title">
              Economy <span>Free</span>
            </p>

            <ul>
              <li>
                <img src={tick} alt="" />
                Free Immigration Account{' '}
              </li>
              <li>
                <img src={tick} alt="" />
                Free Feed on Advice
              </li>
              <li>
                <img src={tick} alt="" />
                Join Free Groups
              </li>
              <li>
                <img src={tick} alt="" />
                Access to Free Webinars
              </li>
              <li>
                <img src={tick} alt="" />
                Access to some Webinar Replay
              </li>
              <li>
                <img src={tick} alt="" />
                Normal Priority Email Support
              </li>
            </ul>
          </article>

          <div className="subFeature__button">
            <div className="subFeature__button__right">
              {' '}
              <Platformbutton
                name="Back"
                type="secondary"
                click={() => {
                  props.setOpnSubFeature(false);
                }}
              />
            </div>
            <Platformbutton
              type="normal"
              name="Continue"
              click={() => {
                props.setOpnSub(true);
              }}
            />
          </div>
        </div>
      );
  }
};

export default SubscriptionFeatures;
