import React, { Component } from 'react';
import './MiaiLoader.css';

class MiaiLoader extends Component {
  render() {
    const { spinner } = this.props;
    return (
      <div>
        {spinner === true ? (
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          <div className="Mspinnercont">
            <div className="Mspinner">
              <div className="Mbounce1" />
              <div className="Mbounce2" />
              <div className="Mbounce3" />
            </div>
            {/* <p>Loading, please wait.</p> */}
          </div>
        )}
      </div>
    );
  }
}

export default MiaiLoader;
