import React, { useEffect, useState } from 'react';
import './mytransactions.scss';
import { Empty } from 'components/common/empty/empty';
import Loader from 'components/Loader';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import Newpagination from './pagination/newpagination';
import { getCurrency, transColor, transSign, transStatus } from 'helpers/utils';
import moment from 'moment';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { fetchWalletTransactions } from 'appRedux/actions/transactions';
import { connect } from 'react-redux';
import api from 'appRedux/api';

// import { Platformbutton } from "components/common/button/button";
const Mytransactions = props => {
  // const printRef = React.useRef();
  // console.log(props);
  const [page, setPage] = useState(1);
  const [active, setActive] = useState(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [total, setTotal] = useState(0);
  // const [receipt, showReceipt]= useState(false)

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  var handleOpen = value => {
    setActive(value);
    dimensions.width < 1050 && setOpen(true);
  };
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  // var showReceipt = false
  React.useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);

  var fetchTransactions = () => {
    setLoading(true);
    api
      .get(`/transactions/user?page=${page}&page_limit=15`)
      .then(res => {
        // console.log(res);
        setLoading(false);
        setTransactions(
          res.data?.data.filter(item => {
            if (item.type === 'WITHDRAWAL_FROM_WALLET') {
              return true;
            } else {
              return !item.status.includes('PENDING');
            }
          }),
        );
        setTotal(res.data?.pagination?.total);
        // console.log(res.data?.pagination.totalPages);
        // console.log('Transactions :', res);
      })
      .catch(error => {
        setLoading(false);
        // console.log(error);
        //  setStep(0);
      });
  };

  useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line
  }, [props.balance, page, props.currency]);

  // useEffect(() => {
  //   props.fetchWalletTransactions(
  //     `/user/transactions?page=${page}&page_limit=15`,
  //   );
  //   // eslint-disable-next-line
  // }, [props.balance, page, props.currency]);

  // if (props.transLoading) {
  if (loading) {
    return (
      <div className="mytransactions">
        <Titlesubtitle
          title="My Transactions"
          subtitle="See all transactions you've carried out on Vesti."
        />
        <div className="mytransactions__centercont">
          <Loader />
        </div>
      </div>
    );
    // } else if (props.trans?.data?.data?.length > 0) {
  } else if (transactions?.length > 0) {
    return (
      <div className="mytransactions">
        <Simplemodal onClick={() => setOpen(false)} open={open}>
          {/* <Transdetail data={props.trans.data?.data[active]} /> */}
          <Transdetail data={transactions[active]} />
        </Simplemodal>
        <Titlesubtitle
          title="My Transactions"
          subtitle="See all transactions you've carried out on Vesti."
        />

        <div className="mytransactions__inner">
          <div className="mytransactions__tablecont">
            <div className="mytransactions__tabbox">
              <table className="mytransactions__table">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Type</th>
                    <th>Currency</th>
                    <th>Amount</th>
                    <th>Charges</th>
                    <th>Date & Time</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {(props.trans.data?.data ?? []).map((item, index) => ( */}
                  {(transactions ?? []).map((item, index) => (
                    <Trow
                      key={index}
                      index={index}
                      type={item.type}
                      currency={item.currency}
                      date={item.createdAt}
                      status={item.status}
                      amount={item.amount}
                      charges={item.charges}
                      click={handleOpen}
                      transaction={transactions[active]}
                    />
                  ))}
                </tbody>
              </table>
            </div>

            {/* {showReceipt && (
              <div className="transbox">
                <div ref={printRef} className="transbox --body">
                  <p className="transbox__signature">app.wevesti.com</p>
                  <Transdetail data={props.trans.data[active]} />
                </div>
              </div>
            )} */}
            {/* <div className="transbox">
              <div ref={printRef} className="transbox --body">
                {receipt && (
                  <p className="transbox__signature">app.wevesti.com</p>
                )}
                <Transdetail data={props.trans.data[active]} />
              </div>
              <Platformbutton
                name="Download"
                type="normal"
                click={() =>
                  handleDownloadPdf(
                    printRef.current,
                    `${props.trans.data[active]?.type.replace(/_/g, ' ') +
                      '_' +
                      moment(
                        props.trans.data[active]?.createdAt,
                        'YYYY-MM-DD HH:mm:ss',
                      ).format('YYYY-MM-DD HH:mm:ss')}`,
                    value => showReceipt(value),
                  )
                }
              />
            </div> */}
            {dimensions.width > 1200 && (
              // <Transdetail data={props.trans.data?.data[active]} />
              <Transdetail data={transactions[active]} />
            )}
          </div>

          <Newpagination
            className="pagination-bar"
            currentPage={page}
            // totalCount={props.trans.data.pagination.totalObjects}
            totalCount={total}
            pageSize={15}
            onPageChange={page => setPage(page)}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="mytransactions">
        <Titlesubtitle
          title="My Transactions"
          subtitle="See all transactions you've carried out on Vesti."
        />
        <div className="mytransactions__centercont">
          <Empty
            title="No Transactions"
            subtitle="You are yet to carry out any transactions, when you do they'll appear here, click the buttons above to carry out transactions."
          />
        </div>
      </div>
    );
  }
};

const Trow = props => {
  // eslint-disable-next-line
  const newDate = moment(props.date, 'YYYY-MM-DD HH:mm:ss')
    .add(1, 'hour')
    .format('YYYY-MM-DD HH:mm:ss');
  var tr_type = props.type.replace(/_/g, ' ');

  return (
    <tr onClick={() => props.click(props.index)}>
      <td>{props.index + 1}</td>
      <td>
        {tr_type === 'TRANSFER'
          ? tr_type.toLowerCase() + ' to ' + props.transaction?.receiverName
          : // .
            // toLowerCase()
            tr_type}
      </td>
      <td>{props.currency.split('_')[0]}</td>
      <td className={`amount${transColor(tr_type)}`}>
        {transSign(tr_type)}

        {(props.amount / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </td>
      <td>
        {(props.charges / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </td>
      <td>{newDate}</td>
      <td>
        <div className={`status`}>
          <p className={`statusIcon --${transStatus(props.status)} `}></p>{' '}
          {transStatus(props.status)}
        </div>
      </td>
    </tr>
  );
};

const Transdetail = props => {
  // console.log(props);
  var tr_type = props.data?.type.replace(/_/g, ' ');
  return (
    <section className="transdetail">
      <span className={`transdetail__top ${transColor(tr_type)}`}>
        {/* <p>{tr_type}</p> */}
        <p>
          {tr_type === 'TRANSFER'
            ? tr_type.toLowerCase() +
              ' to ' +
              props.data?.receiverName.toLowerCase()
            : tr_type}
        </p>

        <p>
          {' '}
          {getCurrency(props.data.currency)}{' '}
          {(props.data.amount / 100).toLocaleString('en-us')}
        </p>
        <p>
          {moment(props.data.createdAt, 'YYYY-MM-DD HH:mm:ss').format(
            'YYYY-MM-DD HH:mm:ss',
          )}
        </p>
      </span>
      <div className="transdetail__list">
        {(props.data?.receiverId
          ? [
              { title: 'status', value: props.data.status },
              { title: 'Currency', value: props.data?.currency.split('_')[0] },
              {
                title: 'Previous Balance',
                value: (props.data?.previousBalance / 100).toLocaleString(
                  'en-us',
                ),
              },
              {
                title: 'Current Balance',
                value: (props.data?.currentBalance / 100).toLocaleString(
                  'en-us',
                ),
              },
              {
                title: 'Charges',
                value: (props.data?.charges / 100).toLocaleString('en-us'),
              },
              {
                title: 'date',
                value: moment(
                  props.data?.createdAt,
                  'YYYY-MM-DD HH:mm:ss',
                ).format('YYYY-MM-DD HH:mm:ss A'),
              },
              { title: 'Type', value: props.data?.type.split('_')[0] },
              { title: 'Receiver', value: props.data?.receiver?.firstName },
              { title: 'ID', value: props.data?.id },
              // {title:'Fee',value:20.00}
            ]
          : [
              { title: 'status', value: props.data?.status },
              { title: 'Currency', value: props.data?.currency.split('_')[0] },
              {
                title: 'Previous Balance',
                value: `${getCurrency(props.data.currency)}${(
                  props.data?.previousBalance / 100
                ).toLocaleString('en-us')}`,
              },
              {
                title: 'Current Balance',
                value: `${getCurrency(props.data.currency)}${(
                  props.data?.currentBalance / 100
                ).toLocaleString('en-us')}`,
              },
              {
                title: 'Charges',
                value: (props.data?.charges / 100).toLocaleString('en-us'),
              },
              {
                title: 'date',
                value: moment(
                  props.data?.createdAt,
                  'YYYY-MM-DD HH:mm:ss',
                ).format('YYYY-MM-DD HH:mm:ss'),
              },
              { title: 'Type', value: props.data?.type.replace(/_/g, ' ') },
              { title: 'ID', value: props.data?.id },
              // {title:'Fee',value:props.data?.charge}
            ]
        ).map((item, index) => (
          <span key={index} className="transdetail__item">
            <p>{item.title}</p>
            <p className={item.title === 'status' ? `status` : ''}>
              {item.title === 'status' && (
                <p className={`statusIcon --${transStatus(item.value)} `}></p>
              )}{' '}
              {item.title === 'status' ? transStatus(item.value) : item.value}
            </p>
          </span>
        ))}
      </div>
    </section>
  );
};

const mapStateToProps = ({ transactions }) => {
  const { transactions: trans, transLoading } = transactions;
  return {
    trans,
    transLoading,
  };
};

export default connect(mapStateToProps, {
  fetchWalletTransactions,
})(Mytransactions);
