import {
  ADD_MONEY,
  FOUNDERS_WALLET,
  SEND_MONEY,
  USD_WALLET,
  CONVERT_FROM,
  WALLET_DATA,
  WALLET_ACTION,
  FETCH_OTP,
} from 'appRedux/constants';

const INIT_STATE = {
  action: '',
  open: false,
  usdOpen: false,
  addMoney: '',
  sendMoney: '',
  addWallet: false,
  convFrom: '',
  wLoading: false,
  wMessage: '',
  balances: [
    { currency: 'ngn', balance: 1000000 },
    { currency: 'usd', balance: 1000 },
    { currency: 'ghc', balance: 100 },
    { currency: 'zmw', balance: 500 },
  ],
  wallet: [
    {
      name: 'Naira Balance',
      amount: 10000,
      currency: 'ngn',
      // accountNumber:props.accountNumber,
      curr: '₦',
      value: 'naira',
    },
    {
      name: 'Kwacha Balance',
      amount: 10000,
      currency: 'zmw',
      // accountNumber:props.accountNumber,
      curr: 'zmw',
      value: 'kwacha',
    },
    {
      name: 'Pounds Balance',
      amount: 100,
      currency: 'gbp',
      // accountNumber:props.accountNumber,
      curr: '£',
      value: 'pounds',
    },
    {
      name: 'Cedis Balance',
      amount: 10000,
      currency: 'ghc',
      // accountNumber:props.accountNumber,
      curr: 'GH¢',
      value: 'cedis',
    },
    {
      name: 'Personal USD Balance',
      curr: '$',
      currency: 'usd',
      amount: 200,
      value: 'pusd',
    },
    {
      name: 'Business USD Balance',
      amount: 100,
      currency: '$',
      value: 'busd',
    },
  ],
  wallets: [],
  oLoading: false,
  oMessage: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FOUNDERS_WALLET:
    case USD_WALLET:
    case ADD_MONEY:
    case SEND_MONEY:
    case CONVERT_FROM:
    case WALLET_DATA:
    case WALLET_ACTION:
    case FETCH_OTP:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
