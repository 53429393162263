import React, { useState } from 'react';
import { Depositmodes } from './depositmodes';
// import { Currencyamount } from "components/currencyandamount/currencyamount";
import api from 'appRedux/api';
import { errorMessage, removeCommaAmount } from 'helpers/utils';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import { Referal } from 'components/referal/referal';
import { Titlesubtitle } from '../common/titlesubtitle/titlesubtitle';
// import Singleinputlabelcol from "components/common/inputs/singleinputlabelcol";
import Inputfloat from 'components/common/inputs/inputfloat';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Platformbutton } from 'components/common/button/button';
import errorsvg from 'assets/error-2.svg';
import { Success } from 'components/common/success/success';
import { Amountinputcurrency } from 'components/common/inputs/amountinput';
import { useAmount } from 'helpers/hooks';
import { depositViaPays } from 'appRedux/actions/nairatrans';
import { connect, useSelector } from 'react-redux';
import cardimg from '../../assets/ngnwithdraw/card-add.png'
import './deposit.css'
import _ from 'lodash';
// import { fontStyle } from 'html2canvas/dist/types/css/property-descriptors/font-style';

const Depositwallet = props => {
  const data = useSelector(state => state.wallets.wallets, _.isEqual);
  const foundObject = data.find(obj => obj.bank !== null);
  const accountNumber = foundObject?.bank?.number;
  // console.log(foundObject?.bank?.number);
  const [step, setStep] = useState(0);
  const { amount, handleAmount } = useAmount(0);
  const [state, setState] = useState({
    amount: '',
    description: '',
  });
  const [message, setMessage] = useState();
  const [option, setOption] = useState('');
  const setDepositOption = value => {
    // setOption(e.target.value)
    setOption(value);
  };
  var setPayToNgn = () => {
    setStep(1);
  };
  var depositProvidus = () => {
    setStep(2);
  };

  var setAmount = e => {
    // var name = e.target.name;
    var value = parseFloat(
      e.target.value
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        .replace(/,/g, ''),
    ).toLocaleString('en-US');
    e.target.validity.valid && setState({ ...state, amount: value });
  };

  var depositLink = () => {
    const payload = {
      amount: amount.replace(/,/g, '') * 100,
      // currency:'NGN_KOBO',
      description: state.description,
    };

    api
      .post('mono/direct-payment/payment', payload)
      .then(({ data }) => {
        openNotificationWithIcon(
          'Generated payment link',
          'Deposit',
          'success',
        );
        // console.log('Response Data', data);
        setTimeout(() => {
          window.open(data.link, '_blank');
          props.cb();
        }, 500);
        setTimeout(() => {
          initReference();
        }, 120000);
      })
      .catch(error => {
        //console.log('error', error.message);
        setMessage(error.data.message);
        if (error.data) {
          openNotificationWithIconErr(errorMessage(error), 'Deposit', 'error');
        } else {
          openNotificationWithIconErr(errorMessage(error), 'Deposit', 'error');
        }
      });
  };

  var initReference = () => {
    api
      .post('mono/direct-payment/verify')
      .then(({ data }) => {})
      .catch(() => {});
  };
  const myData = localStorage.getItem('myuserData');

  switch (step) {
    case 0:
      return (
        <>
          <Titlesubtitle
            steps={` Step 1 of ${ props.currency === "NGN_KOBO" ? 3:2}`}
            // title={`Add cash into your ${props.name} wallet`}
            title={`Add Money `}
            subtitle="Enter the amount of cash you want to add."
          />
          <Amountinputcurrency
            type="text"
            currency="₦"
            name="amount"
            label="Amount"
            value={amount === 'NaN' ? 500 : amount.toLocaleString('en-US')}
            disabled={false}
            placeholder="Enter amount to add"
            onChange={handleAmount}
            pattern="[0-9,.]*"
          />

          
            {/* <div className='card-section'>
            <img className='cardSectionimg' src={cardimg} alt=''/>
            <div >
            <p className=''>Card</p>
            <p>Add money with a debit card</p>
            </div>
            </div> */}
            <div className='mt-4'>
            <Platformbutton
              name="Continue"
              type="normal"
              disabled={removeCommaAmount(amount) < 500}
              click={() => setPayToNgn()}
            />
            </div>
          
        </>
      );

    case 1:
      return (
        <Depositmodes
          option={option}
          depositPaystack={props.depositViaPays}
          depositProvidus={depositProvidus}
          setDepositOption={setDepositOption}
          setStep={setStep}
          amount={amount}
          cb={props.cb}
          userData={props.userData}
        />
      );
    case 2:
      return (
        <Referal
          title={
            accountNumber && accountNumber !== 'No Account'
              ? 'Copy Your Providus Account Number'
              : 'Click Button Below To Request for An Account number'
          }
          name="Account number"
          link={
            accountNumber && accountNumber !== 'No Account'
              ? accountNumber
              : false
          }
        >
          {accountNumber && accountNumber !== 'No Account' ? (
            <p className="referal__inner__extra">
              This is a <strong>PROVIDUS Bank</strong> account number, with
              account name
              <strong>
                {JSON.parse(myData).firstName +
                  ' ' +
                  JSON.parse(myData).lastName}
              </strong>
              , you can transfer money from any source to{' '}
              <strong> {accountNumber} </strong>, select{' '}
              <strong> PROVIDUS Bank </strong> as destination and{' '}
              <strong> FUNDS </strong>
              will be deposited into your <strong>Vesti Wallet.</strong>
            </p>
          ) : (
            <>
              {' '}
              <span className="mb-4 mt-4"></span>{' '}
              <Platformbutton
                type="normal"
                name="Request for Account"
                click={() => props.request()}
              />{' '}
              <span className="mb-2"></span>
            </>
          )}
        </Referal>
      );
    case 4:
      return (
        <>
          <Titlesubtitle
            steps={` Step 3 of 3`}
            title={`Transaction Description`}
            subtitle="Select wallet and enter the amount you want to fund with."
          />
          <Inputfloat
            type="text"
            name="amountCents"
            label="Amount"
            value={
              state.amount === 'NaN'
                ? 500
                : state.amount.toLocaleString('en-US')
            }
            disabled={false}
            placeholder="Enter amount to deposit"
            onChange={setAmount}
            pattern="[0-9,.]*"
            // error={parseInt(props.state.amount.replace(/,/g, '')) <= 500 && 'amount should not be less than 6'}
          />
          <span className="mt-2"></span>
          <Inputfloat
            type="text"
            label="DEPOSIT DESCRIPTION"
            name="description"
            placeholder="Enter transaction description"
            value={state.description}
            disabled={false}
            onChange={e => setState({ ...state, description: e.target.value })}
          />
          <span className="mt-4"></span>
          <Backcontinue goBack={() => setStep(1)}>
            <button
              className="depositoptions__btn"
              disabled={state.description ? false : true}
              onClick={() => depositLink()}
            >
              Initiate Deposit <i className="fas fa-arrow-right"></i>
            </button>
          </Backcontinue>
        </>
      );
    case 5:
      return (
        <Success
          image={errorsvg}
          type="error"
          title="Waitlisting Failed"
          subtitle={message}
          button="Try Again"
          onClick={() => setStep(1)}
        />
      );
    default:
      return <> </>;
  }
};

export const Providusaccount = props => {
  const data = useSelector(state => state.wallets.wallets, _.isEqual)[0];
  return (
    <Referal
      title={
        data.bank.number && data.bank.number !== 'No Account'
          ? 'Copy Your Providus Account Number'
          : 'Click Button Below To Request for An Account number'
      }
      name="Account number"
      link={
        data.bank.number && data.bank.number !== 'No Account'
          ? data.bank.number
          : false
      }
    >
      {data.bank.number && data.bank.number !== 'No Account' ? (
        <p className="referal__inner__extra">
          This is a <strong>PROVIDUS Bank</strong> account number, with account
          name {" "}<strong>{data.bank.accountHolderName.toUpperCase()}</strong>, you
          can transfer money from any source to{' '}
          <strong> {data.bank.number} </strong>, select{' '}
          <strong> PROVIDUS Bank </strong> as destination and{' '}
          <strong> FUNDS </strong>
          will be deposited into your <strong>Vesti Wallet.</strong>
        </p>
      ) : (
        <>
          {' '}
          <span className="mb-4 mt-4"></span>{' '}
          <Platformbutton
            type="normal"
            name="Request for Account"
            click={() => props.request()}
          />{' '}
          <span className="mb-2"></span>
        </>
      )}
    </Referal>
  );
};

const mapStateToProps = ({ transactions }) => {
  const { transLoading, transMessage } = transactions;
  return {
    transLoading,
    transMessage,
  };
};

const mapDispatchToProps = {
  depositViaPays,
};

export default connect(mapStateToProps, mapDispatchToProps)(Depositwallet);
