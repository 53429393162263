import React from 'react';
import vesti from '../../../assets/vestilogowhite.png';
import facebook from '../../../assets/facebookTransparent.svg';
import ig from '../../../assets/Instagram.svg';
import link from '../../../assets/LinkedIn.svg';
import twitter from '../../../assets/Twitter.svg';
import { Link } from '@reach/router';
import './Ovfooter.scss';

function Ovfooter() {
  return (
    <footer className="ovfooter-container">
      <div className="ovfooter-inner">
        <div className="ovfooter-inner top">
          <div className="ovfooter-top">
            <div className="brandLogo">
              <img className="white-logo" src={vesti} alt="Colored logo" />
              <div className="social-media-links">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://twitter.com/vestiofficial"
                >
                  <img
                    className=".img-fluid mw-100 h-auto"
                    src={twitter}
                    alt="twitter"
                  />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/company/vestiofficial/mycompany/"
                >
                  <img
                    className=".img-fluid mw-100 h-auto"
                    src={link}
                    alt="linkedin"
                  />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://web.facebook.com/vesticash"
                >
                  <img
                    className=".img-fluid mw-100 h-auto"
                    src={facebook}
                    alt="fb"
                  />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/vestiofficial/"
                >
                  <img
                    className=".img-fluid mw-100 h-auto"
                    src={ig}
                    alt="insta"
                  />
                </a>
              </div>
            </div>
            <div className="right">
              <ul className="products">
                <li>PRODUCTS</li>
                <li>
                  {' '}
                  <Link className="ovfooter-link" to="/vesticard">
                    GlobalGeng card
                  </Link>
                </li>
                <li>
                  {' '}
                  <Link className="ovfooter-link" to="/bank">
                    Wallets
                  </Link>
                </li>
                <li>
                  {' '}
                  <Link className="ovfooter-link" to="/vesticard">
                    Founders' card
                  </Link>
                </li>
                <li>
                  {' '}
                  <Link className="ovfooter-link" to="/pathways">
                    Pathways
                  </Link>
                </li>
              </ul>
              <ul className="company">
                <li>COMPANY</li>
                <li>
                  <Link className="ovfooter-link" to="/team">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link className="ovfooter-link" to="/careers">
                    Careers
                  </Link>
                </li>
              </ul>

              <ul className="legal">
                <li>LEGAL</li>
                <li>
                  <Link className="ovfooter-link" to="/aml">
                    AML
                  </Link>
                </li>
                <li>
                  <Link className="ovfooter-link" to="/faq">
                    FAQs
                  </Link>
                </li>
                <li>
                  <Link className="ovfooter-link" to="/disclosure">
                    Disclosures
                  </Link>
                </li>
                <li>
                  <Link className="ovfooter-link" to="/privacy">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
              <ul className="contact">
                <li>CONTACT</li>
                <li>help@wevesti.com</li>
              </ul>

              <ul className="address">
                <li>ADDRESS</li>
                <li>
                  <strong>LAGOS (NIGERIA)</strong>
                </li>
                <li>30 Furo Ezimora street, Lekki Phase 1</li>
                <li>
                  {' '}
                  <strong>DALLAS TEXAS (USA)</strong>
                </li>
                <li>1301, S Bowen rd, Ste 450, Arlington, Tx 76013.</li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className="ovfooter-inner bottom">
          <p className="copyright-text ">Copyrights &copy; VESTI 2023.</p>
        </div>
      </div>
    </footer>
  );
}

export default Ovfooter;
