import React from 'react';
import './radiobutton.scss';

const RadioButton = props => {
  return (
    <div className="RadioButton">
      <input
        id={props.id}
        onChange={props.changed}
        value={props.value}
        type="radio"
        checked={props.isSelected}
      />
      <label htmlFor={props.id}></label>
      <div className="paymentoption-detail">
        <p>{props.label}</p>
        <p>{props.sublabel}</p>
      </div>
    </div>
  );
};

export default RadioButton;

export const RadioOption = props => {
  return (
    <div
      className={`radiooption ${props.isSelected && ' --active'} `}
      onClick={() => props.changed(props.value)}
    >
      <div className="radiooption__left">
        {props.image && <img src={props.image} alt={props.label} />}
        <span className="radiooption__titlesub">
          <p>{props.label}</p>
          <p>{props.sublabel}</p>
        </span>
      </div>

      <input
        id={props.id}
        value={props.value}
        type="radio"
        checked={props.isSelected}
      />
      <label htmlFor={props.id}></label>
    </div>
  );
};

export const VerySmallRadioOption = props => {
  return (
    <div
      className={`verysmallradiooption ${props.isSelected && ' --active'} `}
      onClick={() => props.changed(props.value)}
    >
      <div className="verySmallradiooption__left">
        {props.image && <img src={props.image} alt={props.label} />}
        <span className="verySmallradiooption__titlesub">
          <p>{props.label}</p>
          <p>{props.sublabel}</p>
        </span>
      </div>

      <input
        id={props.id}
        value={props.value}
        type="radio"
        checked={props.isSelected}
      />
      <label htmlFor={props.id}></label>
    </div>
  );
};

export const SavingsRadioOption = props => {
  return (
    <div
      className={`savingsradiooption ${props.isSelected && ' --active'} `}
      onClick={() => props.changed(props.value)}
    >
      <div className="radiooption__left">
        {props.image && <img src={props.image} alt={props.label} />}
        <span className="radiooption__titlesub">
          <p>{props.label}</p>
          <p>{props.sublabel}</p>
        </span>
      </div>

      <input
        id={props.id}
        value={props.value}
        type="radio"
        checked={props.isSelected}
      />
      <label htmlFor={props.id}></label>
    </div>
  );
};

export const SmallRadioOption = props => {
  return (
    <div
      className={`radiooption ${props.isSelected && ' --active'} `}
      onClick={() => props.changed(props.value)}
    >
      <div className="radiooption__left">
        {props.image && <img src={props.image} alt={props.label} />}
        <span className="radiooption__titlesub">
          {/* <p style={{ fontSize: '16px' }}>{props.label}</p> */}
          <p style={{ fontSize: '16px', fontWeight: '500', color: '#AEB4BE' }}>
            {props.sublabel}
          </p>
        </span>
      </div>

      <input
        id={props.id}
        value={props.value}
        type="radio"
        checked={props.isSelected}
      />
      <label htmlFor={props.id}></label>
    </div>
  );
};

export const SubLabelRadioOption = props => {
  return (
    <div
      className={`radiooption ${props.isSelected && ' --active'} `}
      onClick={() => props.changed(props.value)}
    >
      <div className="radiooption__left">
        {props.image && <img src={props.image} alt={props.label} />}
        <span className="radiooption__titlesub">
          {/* <p style={{ fontSize: '16px' }}>{props.label}</p> */}
          <p style={{ fontSize: '16px', fontWeight: '600' }}>
            {props.sublabel}
          </p>
        </span>
      </div>

      <input
        id={props.id}
        value={props.value}
        type="radio"
        checked={props.isSelected}
      />
      <label htmlFor={props.id}></label>
    </div>
  );
};

export const NewVerySmallRadioOption = props => {
  return (
    <div
      className={`Newverysmallradiooption ${props.isSelected && ' --active'} `}
      onClick={() => props.changed(props.value)}
    >
      <input
        id={props.id}
        value={props.value}
        type="radio"
        checked={props.isSelected}
      />
      <label htmlFor={props.id}></label>

      <div className="NewverySmallradiooption__left">
        {props.image && <img src={props.image} alt={props.label} />}
        <span className="NewverySmallradiooption__titlesub">
          <p>{props.label}</p>
          <p>{props.sublabel}</p>
        </span>
      </div>
    </div>
  );
};
