import React, { useState, useEffect } from 'react';
import './transactionHistory.scss';
import memberIcon from '../../../assets/membership/history.svg';
import MemberTransaction from './membershipTransactions';
const TransactionHistory = () => {
  return (
    <div className="">
      {' '}
      <div className="Profilemembership__plan">
        <div className="Profilemembership__bottom">
          <img src={memberIcon} alt="" />

          <article>
            <p className="Profilemembership__bottom__title">Payment History</p>
            <p className="Profilemembership__bottom__subtitle">
              You can see your complete payment history and download invoices
              for your own records.
            </p>
          </article>
        </div>

        <MemberTransaction />
      </div>
    </div>
  );
};

export default TransactionHistory;
