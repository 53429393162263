import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Platformbutton } from 'components/common/button/button';
import Inputfloat from 'components/common/inputs/inputfloat';
import { RadioOption } from 'components/common/radiobutton/radiobutton';
// import { Shortinfo } from "components/common/shortinfo/shortinfo";
import { Success } from 'components/common/success/success';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import React, { useState, useEffect } from 'react';
import './feespayment.scss';
// import axios from 'axios';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import { Phonenumberdef } from 'components/common/inputs/phoneinput';
import { Singleselect } from 'components/common/inputs/singleselect';
import ModernDatepicker from 'react-modern-datepicker';
import moment from 'moment';
import { navigate } from '@reach/router';
import { openLink } from 'helpers/utils';
import { useAmount } from 'helpers/hooks';
import errorsvg from 'assets/newerror.svg';
// import { useLocation } from '@reach/router';

import { Amountinputcurrency } from 'components/common/inputs/amountinput';
import api from 'appRedux/api';
import Loader from 'components/Loader';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { allCountries } from 'helpers/countryregion';
import { getCurrencyCode, getCurrencyImg, getPhoneCode } from 'helpers/utils';

// const config = {
//   cors: 'https://cors-anywhere.herokuapp.com/', // <optional> doesn't display the cors error
//   formUrl:
//     'https://docs.google.com/forms/d/e/1FAIpQLSdBwhCT4Jri04k-20OJ8eopUrYfClEKNF7c8ZloLJUA6tPb6w/formResponse',
// };

export const Feespayment = props => {
  // const myData = localStorage.getItem('userData');
  const myData = useSelector(state => state.auth.userData, _.isEqual);

  const [errorMsg, setErrorMsg] = useState('Error');
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { amount, handleAmount } = useAmount(0);
  const [state, setState] = useState({
    firstName: myData ? myData?.firstName : '',
    userId: myData ? myData?.id : '',
    lastName: myData ? myData?.lastName : '',
    email: '',
    // country: '',
    phoneNumber: '',
    schoolName: '',
    countryTwo: '',
    program: '',
    start: { name: 'date', value: '' },
    end: { name: 'date_e', value: '' },
    amount: 0,
    score: '',
    sponsorAmount: null,
    haveAdmission: null,
    haveSponsor: null,
  });

  var setInput = e => {
    var value = e.target.value;
    var name = e.target.name;
    var select = state[name];
    setState({ ...state, [name]: { ...select, value: value } });
  };

  var foDate = (formData, code, date) => {
    var newD = moment(date)
      .format('MM-DD-YYYY')
      .split('T')[0];

    var year = newD.split('-')[2];
    var day = newD.split('-')[1];
    var month = newD.split('-')[0];
    var arr = [year, day, month];
    var arrT = ['year', 'day', 'month'];
    for (var i = 0; i <= 2; i++) {
      formData.append(`entry.${code}_${arrT[i]}`, arr[i]);
    }
  };

  var submit = async () => {
    const data = {
      userId: state.userId,
      firstName: state.firstName.value
        ? state.firstName.value
        : state.firstName,
      lastName: state.lastName.value ? state.lastName.value : state.lastName,
      email: state.email.value ? state.email.value : myData?.email,
      // checks
      country: myData?.country,

      // checks end
      phoneNumber: state.phoneNumber?.value,
      haveAdmission: state.haveAdmission?.value?.value === 'Yes' ? true : false,
      program: state.program.value?.value,
      country2: state.countryTwo?.value?.value,
      schoolName: state.schoolName?.value,
      startDate: state.start?.value,
      endDate: state.end?.value,
      haveSponsor: state.haveSponsor?.value === 'Yes' ? true : false,
      sponsorAmount: state.sponsorAmount?.value
        ? parseInt(state.sponsorAmount?.value.replace(/,/g, ''), 10)
        : state.sponsorAmount?.value,
      amount: amount ? parseInt(amount.replace(/,/g, ''), 10) : amount,
      score: state.score?.value,
    };

    const UnauthData = {
      firstName: state.firstName.value
        ? state.firstName.value
        : state.firstName,
      lastName: state.lastName.value ? state.lastName.value : state.lastName,
      email: state.email.value ? state.email.value : myData?.email,
      // checks
      country: state.residentCountry,
      residentCountry: state.residentCountry,

      // ? state?.residentCountry
      // : state?.residentCountry,
      currency: state.currency,
      currencyFlag: state.currencyFlag,
      // checks end
      phoneNumber: state.phoneNumber?.value,
      haveAdmission: state.haveAdmission?.value?.value === 'Yes' ? true : false,
      program: state.program.value?.value,
      country2: state.countryTwo?.value?.value,
      schoolName: state.schoolName?.value,
      startDate: state.start?.value,
      endDate: state.end?.value,
      haveSponsor: state.haveSponsor?.value === 'Yes' ? true : false,
      sponsorAmount: state.sponsorAmount?.value
        ? parseInt(state.sponsorAmount?.value.replace(/,/g, ''), 10)
        : state.sponsorAmount?.value,
      amount: amount ? parseInt(amount.replace(/,/g, ''), 10) : amount,
      score: state.score?.value,
    };

    // if (data.email) {
    // setLoading(true);
    try {
      const res = await api.post(
        `/loan-form/create`,
        myData?.id ? data : UnauthData,
      );
      openNotificationWithIcon(
        `${res.data.message}`,
        'Loan Application',
        'success',
      );
      setLoading(false);
      setStep(4);
      // console.log(res);
    } catch (error) {
      setErrorMsg(error?.data?.message);
      // console.log(error);
      setLoading(false);
      // openNotificationWithIconErr(`${error.data?.data?.message}`, 'Loan Application', 'error');
      openNotificationWithIconErr(`Error`, 'Loan Application', 'error');
      setStep(5);
    }

    // }
    // else {
    //   openNotificationWithIconErr(
    //     'Please Input a valid email address at step 1',
    //   );
    // }
  };

  var callB = () => {
    navigate('/credithistory');
    props.close();
  };

  const __renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <Firststep
            data={myData}
            state={state}
            setState={setState}
            setStep={setStep}
            setInput={setInput}
          />
        );
      case 1:
        return (
          <Secondstep
            state={state}
            setState={setState}
            setStep={setStep}
            setInput={setInput}
          />
        );
      case 2:
        return (
          <Thirdstep
            state={state}
            setState={setState}
            setStep={setStep}
            setInput={setInput}
            submit={submit}
            amount={amount}
            handleAmount={handleAmount}
          />
        );
      case 3:
        return (
          <Fourthstep
            state={state}
            setState={setState}
            setStep={setStep}
            setInput={setInput}
            submit={submit}
            amount={amount}
            handleAmount={handleAmount}
          />
        );
      case 4:
        return (
          <Success
            title="Thank You"
            subtitle="Your response was submitted successfully, a member of our team will reach out to you soon, Kindly note that this is a prequalification and doesn’t mean your loan has been approved."
            onClick={() => {
              state.score.value === 'Yes'
                ? callB()
                : myData?.firstName
                ? props.close()
                : navigate('/');
            }}
          />
        );
      case 5:
        return (
          <Success
            image={errorsvg}
            type="error"
            title="Submission Failed"
            subtitle={errorMsg}
            button="Try again"
            onClick={() => {
              setStep(3);
            }}
          />
        );
      default:
        return <>Default</>;
    }
  };

  return <>{loading ? <Loader /> : __renderSteps()}</>;
};

const Firststep = props => {
  const [style, setStyle] = useState({ marginTop: '-7rem', width: '100%' });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setStyle({ marginTop: '-5rem', width: '100%' });
      } else {
        // Reset the style for screens smaller than 700px
        setStyle({ marginTop: '3rem', width: '100%' });
      }
    };

    // Initial call to set the style based on the window width
    handleResize();

    // Add an event listener to update the style when the window is resized
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className="feespayment">
      <Titlesubtitle
        steps="Step 1 of 4"
        title="Student Loan Application"
        subtitle="Only apply if you have a valid university admission offer"
      />
      <div className="feespayment__col">
        <div className="feespayment__row">
          <Inputfloat
            label="Firstname"
            type="text"
            name="firstName"
            value={props.data?.firstName}
            placeholder="First name"
            disabled={!props.data?.firstName || !props.data ? false : true}
            onChange={props.setInput}
          />
          <Inputfloat
            label="Lastname"
            type="text"
            name="lastName"
            value={props.data?.lastName}
            placeholder="Last name"
            disabled={!props.data?.lastName || !props.data ? false : true}
            onChange={props.setInput}
          />
        </div>
        <Phonenumberdef
          value={props.state.phoneNumber.value}
          // countries ={['NG']}
          default="NG"
          setValue={value =>
            props.setState({
              ...props.state,
              phoneNumber: { ...props.state.phoneNumber, value: value },
            })
          }
        />

        {/* testinggg */}
        <Inputfloat
          label="Email"
          type="text"
          name="email"
          value={
            props.data?.email ? props.data?.email : props.state.email.value
          }
          placeholder="Email"
          disabled={!props.data?.email || !props.data ? false : true}
          onChange={props.setInput}
        />

        {props.data?.id ? (
          <div></div>
        ) : (
          <>
            <Singleselect
              label="Country"
              value={props.state.country?.value?.value}
              options={allCountries.map(country => ({
                value: country.countryShortCode,
                label: country.countryName,
              }))}
              onChange={value =>
                props.setState({
                  ...props.state,

                  residentCountry: value.label,
                  currencyFlag: getCurrencyCode(value.label),
                  currency: getCurrencyCode(value.label),
                })
              }
              placeholder=" Resident Country"
            />
          </>
        )}
      </div>

      <div style={style}>
        <Platformbutton
          name="Continue"
          type="normal"
          disabled={
            !props.state.firstName ||
            !props.state.lastName ||
            // !props.state.email ||
            !props.state.phoneNumber
              ? true
              : false
          }
          click={() => props.setStep(1)}
        />
      </div>
    </div>
  );
};

const Secondstep = props => {
  return (
    <div className="feespayment">
      <Titlesubtitle
        steps="Step 2 of 4"
        title="School Details"
        subtitle="Enter information about your program/education."
      />
      <>
        <div className="feespayment__col --list">
          <div className="feespayment__smallcol --left">
            <Inputfloat
              label="School"
              type="text"
              name="schoolName"
              value={props.state.schoolName.value}
              placeholder="School Name"
              onChange={props.setInput}
            />
            <Platformbutton
              type="withiconnobg"
              name="See Available Schools"
              classname="fas fa-chevron-right"
              click={() => openLink('https://wevesti.com/schools_we_support')}
            />
          </div>
          <div className="feespayment__row">
            <Singleselect
              value={props.state.countryTwo?.value}
              options={[
                { label: 'United States', value: 'United States' },
                { label: 'Canada', value: 'Canada' },
                { label: 'UK (Coming Soon)', value: 'UK' },
              ]}
              placeholder="School Country"
              onChange={value =>
                props.setState({
                  ...props.state,
                  countryTwo: { ...props.state.countryTwo, value: value },
                })
              }
            />
            <Singleselect
              value={props.state.haveAdmission?.value}
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
              placeholder="Have you gotten admission"
              onChange={value =>
                props.setState({
                  ...props.state,
                  haveAdmission: { ...props.state.haveAdmission, value: value },
                })
              }
            />
          </div>

          <Singleselect
            value={props.state.program.value}
            options={[
              {
                label: 'Undergraduate (Bachelors degree)',
                value: 'Undergraduate (Bachelors degree)',
              },
              {
                label: 'Postgraduate (Masters, MBA)',
                value: 'Postgraduate (Masters, MBA)',
              },
              { label: 'Postgraduate (Phd)', value: 'Postgraduate (Phd)' },
            ]}
            placeholder="Program of Application?"
            onChange={value =>
              props.setState({
                ...props.state,
                program: { ...props.state.program, value: value },
              })
            }
          />

          <div className="feespayment__row">
            <div className="feespayment__smallcol">
              <p className="mb-2">Program start date</p>
              <ModernDatepicker
                date={props.state.start.value}
                className="dob"
                format={'MM-DD-YYYY'}
                showBorder
                onChange={date => {
                  props.setState({
                    ...props.state,
                    start: { ...props.state.start, value: date },
                  });
                }}
                placeholder={'Start Date'}
                primaryColor={'#67A948'}
              />
            </div>
            <div className="feespayment__smallcol">
              <p className="mb-2">Program end date</p>
              <ModernDatepicker
                date={props.state.end.value}
                className="dob"
                format={'MM-DD-YYYY'}
                showBorder
                onChange={date =>
                  props.setState({
                    ...props.state,
                    end: { ...props.state.end, value: date },
                  })
                }
                placeholder={'End Date'}
                primaryColor={'#67A948'}
              />
            </div>
          </div>
        </div>
      </>
      <span className="mb-2"></span>
      {/* <Shortinfo
                subject="This total sum includes a platform fee of 10% capped at $40 (i.e if your payment 10% is more than $40, you are only charged $40. But if its lesser than $40 you are charged 10%)"
            /> */}
      <Backcontinue goBack={() => props.setStep(0)}>
        <Platformbutton
          name="Continue"
          type="normal"
          disabled={
            !props.state.haveAdmission?.value ||
            !props.state.program.value ||
            !props.state.end.value ||
            !props.state.start.value ||
            !props.state.schoolName.value
              ? true
              : false
          }
          click={() => props.setStep(2)}
        />
      </Backcontinue>
    </div>
  );
};

const Thirdstep = props => {
  const { amount, handleAmount } = useAmount(0);
  return (
    <div className="feespayment">
      <Titlesubtitle
        steps="Step 3 of 4"
        title="Sponsorship"
        subtitle="Information about sponsorship"
      />
      <div className="feespayment__col">
        <div className="feespayment__smallcol">
          <p>Do you have a sponsor ?</p>
          <>
            <RadioOption
              changed={value =>
                props.setState({
                  ...props.state,
                  haveSponsor: { ...props.state.haveSponsor, value: value },
                })
              }
              id="0"
              isSelected={props.state.haveSponsor?.value === 'Yes'}
              label="Yes"
              value="Yes"
            />
            <div className="mb-2"></div>
            <RadioOption
              changed={value =>
                props.setState({
                  ...props.state,
                  haveSponsor: { ...props.state.haveSponsor, value: value },
                })
              }
              id="1"
              isSelected={props.state.haveSponsor?.value === 'No'}
              label="No"
              value="No"
            />
          </>
        </div>
        {props.state.haveSponsor?.value === 'Yes' && (
          <>
            <p>Sponsor amount</p>
            <Amountinputcurrency
              type="text"
              currency="$"
              name="amount"
              label={`Enter amount in $`}
              value={amount === 'NaN' ? 0 : amount?.toLocaleString('en-US')}
              disabled={false}
              placeholder={`Enter amount in $`}
              onChange={handleAmount}
              pattern="[0-9,.]*"
            />
          </>
        )}
      </div>

      <Backcontinue goBack={() => props.setStep(1)}>
        <Platformbutton
          name="Continue"
          type="normal"
          disabled={!props.state.haveSponsor?.value ? true : false}
          click={() => {
            props.setState({
              ...props.state,
              sponsorAmount: { ...props.state.sponsorAmount, value: amount },
            });
            props.setStep(3);
          }}
        />
      </Backcontinue>
    </div>
  );
};

// eslint-disable-next-line
const Fourthstep = props => {
  return (
    <div className="feespayment">
      <div className="feespayment">
        <Titlesubtitle
          steps="Step 4 of 4"
          title="Loan Amount"
          subtitle="How much do you need ?"
        />
        <div className="feespayment__col">
          <Amountinputcurrency
            type="text"
            currency="$"
            name="amount"
            label={`Enter amount in $`}
            value={
              props.amount === 'NaN' ? 0 : props.amount.toLocaleString('en-US')
            }
            disabled={false}
            placeholder={`Enter amount in $`}
            onChange={props.handleAmount}
            pattern="[0-9,.]*"
          />
          <div className="feespayment__smallcol">
            <p>Would you like to get your credit score?</p>
            <>
              <RadioOption
                changed={value =>
                  props.setState({
                    ...props.state,
                    score: { ...props.state.score, value: value },
                  })
                }
                id="0"
                isSelected={props.state.score.value === 'Yes'}
                label="Yes"
                value="Yes"
              />
              <div className="mb-2"></div>
              <RadioOption
                changed={value =>
                  props.setState({
                    ...props.state,
                    score: { ...props.state.score, value: value },
                  })
                }
                id="1"
                isSelected={props.state.score.value === 'No'}
                label="No"
                value="No"
              />
            </>
          </div>
        </div>

        <Backcontinue goBack={() => props.setStep(2)}>
          <Platformbutton
            name="Submit"
            type="normal"
            disabled={!props.state.score.value || !props.amount ? true : false}
            click={() => props.submit()}
          />
        </Backcontinue>
      </div>
    </div>
  );
};
