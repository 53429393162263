import {
  // Campaign,
  Exclusive,
  Platinum,
  Standard,
} from '../../../assets/assets';

export const subscriptionPlan = [
  {
    id: '1',
    plan: null,
    type: 'Economy',
    img: Standard,
    subtitle: 'Ready to move',
    monthlyprice: 'FREE',
    yearlyprice: 'FREE',
    dataplanType: null,
    features: [
      'Free Immigration Account',
      'Free Feed on Advice',
      'Join Free Groups',
      'Access to Free Webinars',
      'Access to some Webinar Replay',
      'Normal Priority Email Support ',
    ],
  },

  {
    id: '2',
    type: 'Business Class',
    plan: 'BUSINESS',
    img: Exclusive,
    subtitle: 'Quick mover',
    monthlyprice: 'businessMembershipMonthly',
    yearlyprice: 'businessMembershipYearly',
    dataplanType: 'EXCLUSIVE_USER',

    features: [
      'Everything in Economy Plan',
      'Subscribe to up to 1 Pathway',
      'Exclusive Support with a Mentor',
      'Discount on Trainings & Events',
      'Discount on Extended Clarity Sessions',
      '20% OFF IELTS &  French Coaching Class ',
    ],
  },

  {
    id: '3',
    type: 'First Class',
    plan: 'PREMIUM',
    img: Platinum,
    subtitle: 'Serious mover',
    monthlyprice: 'platinumMembershipMonthly',
    yearlyprice: 'platinumMembershipYearly',
    dataplanType: 'PLATINUM_USER',
    features: [
      'Everything in Economy Plan',
      'Subscribe to up to 3 Pathways',
      'Exclusive Support with a Mentor',
      'Discount on Trainings & Events',
      'Discount on Extended Clarity Sessions',
      '20% OFF IELTS &  French Coaching Class ',
    ],
  },
];
