import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import api from 'appRedux/api';
import CheckoutForm from './checkoutForm';

export const ClarityBooking = props => {
  const paymentIntent = props.urlparams?.get('payment_intent');
  const paymentIntentSecret = props.urlparams?.get('reference');
  const email = props.urlparams?.get('email');
  const fullname = props.urlparams?.get('fullname');
  const [rate, setRate] = useState();
  const [open, setOpen] = useState(false);

  const [url, setUrl] = useState(null);

  //   const publicKey = 'pk_test_2aa8ac09a6a34eb6c01d1b27b088e9d78bd50426';
  const publicKey = 'pk_live_5ac54092a585eaedb83ec09a1f8896928ffdca87';
  const stripePromise = loadStripe(
    'pk_live_51LAeheHyH0mfw5s2RLpmmI03SP6hhu2aiDRBOwMHGji06PcEYEWx062vdabvuqaAsYZzajFcAdDoDLv3zpnahEkT007kY09NQG',
  );

  return (
    <div className="p-8">
      <>
        {/* <h1 className="text-center py-2 text-lg font-medium">
          Clarity Call Payment
        </h1> */}
        {props.clientSecret && stripePromise && (
          <Elements
            stripe={stripePromise}
            options={{ clientSecret: props.clientSecret }}
          >
            <CheckoutForm
              visaType={props.visaType}
              fullname={`${props.userD?.firstName} ${props.userD?.lastName}`}
              email={props.userD?.email}
              Reference={props.reference}
              redirctUrl={url}
              setStep={props.setStep}
              setOpenTestModal={props.setOpenTestModal}
              test={props.test}
              isSuccessful={props.isSuccessful}
              setIsSuccessful={props.setIsSuccessful}
              handleStripeSuccessAction={props.handleStripeSuccessAction}
            />
          </Elements>
        )}
      </>
    </div>
  );
};
