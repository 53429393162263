import React, {useEffect, useState} from 'react'
import { IGData } from './data'
import { useSelector, useDispatch } from 'react-redux'
import { fetchVestiPosts } from 'appRedux/actions/posts'

export const IG = () => {
    const dispatch = useDispatch()
const [data, setData] = useState([])


    const feedsPost = useSelector(state => state.posts.data)

    useEffect(() => {
        dispatch(fetchVestiPosts)
      }, [feedsPost]) 
    console.log(feedsPost);

    return (
        <>
        {feedsPost?.map(({creator, description, id, images, likes, reactions, title, updatedAt, userId, comments}, index) => {
        return <div key={index} className='IG-sec'>
            <div className='nameCard'>
                <img src={creator.profilePictureURL} alt="" className='feeds-Profile'/>
                <div className='nameTime'>
                    <p>{creator.firstName}</p>
                    <p>{creator.lastName}</p>
                    <p>{creator.updatedAt}</p>
                </div>
            </div>
            {/* <img src={images
                
            } alt=""/> */}

            <div className={`image-container ${images && images.length === 1 ? 'single' : images && images.length === 2 ? 'double' : images && images.length === 3 ? 'triple' : 'Quadruple' }`}>
                {images && images.map((imageSrc, imgIndex) => (
                <img src={imageSrc} 
                alt="" 
                key={imgIndex}
                className={images.length === 3 && imgIndex === 0 ? 'large' : images.length === 3 ? 'small' : ''} 
                />
                ))}
            </div>

            {/* <div className={` ${images.length > 2 ? 'grid-container' : images.length === 2 ? 'double' : 'single' }`}>
            {images.map((image, index) => (
                <img src={image.img} alt="" className={`item${index + 1}`} key={index} />
            ))}
            </div> */}
           
            {/* <div className={` ${ Object.keys(backgroundImg).length > 2 ? 'grid-container' : Object.keys(backgroundImg).length === 2 ? 'double' : 'single'}`} >
                {backgroundImg.map(({img}, index)=>{
                
                    return <img  src={img} alt="" className={`item${index + 1}`}  key={index} />
                })}
            </div>
            
            <div className='allIconText'>
                <div className='icon-text'>
                    <img src={likesIcon} alt=""/>
                    <p>{likes}</p>
                </div>
                <div className='icon-text'>
                    <img src={commentIcon} alt=""/>
                    <p>{comments}</p>
                </div>
                <div className='icon-text'>
                    <img src={shareIcon} alt=""/>
                    <p>{shares}</p>
                </div>
                <div className='icon-text'>
                    <img src={saveIcon} alt=""/>
                    <p>{save}</p>
                </div>
            </div>
            <p className='details'>{description}</p>
            <div className='addCommentArea'>
                <img src={addComImg} alt=""/>
                <div className='addComInputCont'>
                <input type="text" placeholder='Add your comment' className='addComInput' />
                    <img src={linksquare} alt="" className='linksquare'/>
                    <img src={happyEmoji} alt="" className='happyEmoIcon'/>
                    <img src={galleryIcon} alt="" className='galleryIcon'/>
                </div>
            </div> */}
        </div>
        })}
            

          
        </>
        
    )
}
