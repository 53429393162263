import React from 'react';
import './singlepathway.scss';
import 'containers/Admin/Admin.css';
import { navigate } from '@reach/router';
import share from 'assets/pathways/share.svg';
// import { pathwayService } from 'appRedux/microservice';

export const Singlepathway = props => {
  var actions = props.actions;

  // const [isShareSupported, setIsShareSupported] = useState(0);

  // useEffect(() => {
  //   setIsShareSupported(navigator.share !== undefined);
  // }, []);

  const handleShareClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: props.title,
          text: props.description,
        })
        .then(() => {
          //console.log('Successfully shared');
        })
        .catch(error => {
          console.error('Error sharing:', error);
        });
    }
  };

  return (
    <div className="singlepathway-container">
      <div
        key={props.id}
        onClick={props.tick}
        className="singlepathway-number"
        style={{ cursor: 'pointer' }}
      >
        <p>{props.number}</p>
      </div>
      <div className="singlepathway-inner">
        <div className="pathwaybox-container" style={{ display: 'flex' }}>
          <div className="singlepathwayimageleft">
            <img src={props.logo} alt="content" />
          </div>
          <div className="singlepathwayright">
            <div
              className="title-container"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '20px',
                alignItems: 'baseline',
              }}
            >
              <div className="stagetitle-container">
                <p>
                  {' '}
                  {props.title}{' '}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
              </div>

              <div
                className="stagedays-container"
                style={{ marginLeft: 'auto' }}
              >
                <p className="stagedays"> 4 DAYS </p>
              </div>
            </div>

            <p className="long-text-description-four"> {props.description}</p>

            <div
              className=""
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                {props.nolink === true ? (
                  ''
                ) : (
                  <p
                    onClick={() =>
                      props.click
                        ? props.click(props.title)
                        : navigate(`/actions/${props.id}`, {
                            state: {
                              id: props.id,
                              actions,

                              pathway: props.pathway,
                              title: props.title,
                              description: props.description,
                              logo: props.logo,
                            },
                          })
                    }
                    href="/"
                    className="singlepathway-inner link"
                  >
                    {' '}
                    {props.type === 'payment'
                      ? props.linkTitle
                        ? props.linkTitle
                        : props.title
                      : 'Learn more  '}
                    {'   '} &nbsp; &nbsp;
                    <i className="fas fa-long-arrow-alt-right"></i>{' '}
                  </p>
                )}
              </div>

              <div className="share-stagesicon" style={{ marginLeft: 'auto' }}>
                {/* {isShareSupported && ( */}
                <img
                  src={share}
                  alt="content"
                  onClick={handleShareClick}
                  style={{ cursor: 'pointer' }}
                />
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
