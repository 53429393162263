import React, { useState } from 'react';
import cards from 'assets/homeIcon/cards.svg';
import bgcards from 'assets/homeIcon/bgcards.svg';
import pathwayIcon from 'assets/homeIcon/pathway.svg';
import groups from 'assets/homeIcon/community.svg';
import flight from 'assets/homeIcon/fight.svg';
import bgPathway from 'assets/homeIcon/bgpathway.svg';
import bgGroup from 'assets/homeIcon/bgcommunity.svg';
import bgflight from 'assets/homeIcon/bgflight.svg';
import newIcon from 'assets/homeIcon/newIcon.svg';
// import cardsparkle from 'assets/cardsparkles.svg';
// import cardsparkleblue from 'assets/cardsparkleblue.svg';
import './quickfeature.scss';
// import { useDispatch } from 'react-redux';
// import { handleFees, openVirtual } from 'appRedux/actions/domore';
import { Smalltitle } from 'components/common/titlesubtitle/titlesubtitle';
import { BsArrowRight } from 'react-icons/bs';
import { navigate } from '@reach/router';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { SelectcurrencyFlight } from 'components/selectCurrency/selectcurrency';

export const Quickfeature = props => {
  // const dispatch = useDispatch();
  const [CurrencyOpt, setCurrencyOpt] = useState(false);
  return (
    <>
      <Simplemodal onClick={() => setCurrencyOpt(false)} open={CurrencyOpt}>
        <SelectcurrencyFlight />
      </Simplemodal>{' '}
      <section className="newft">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginBottom: '50px',
          }}
        >
          <Smalltitle title="Quick actions" />
          <div className="newft__seeall">
            <p
              style={{ color: '#67A948' }}
              onClick={() => {
                navigate('/services');
              }}
            >
              See all
            </p>
            <BsArrowRight />
          </div>
        </div>
        <div className="newft__bottom">
          <div className="quickaction">
            <div
              className="quickaction__vesticard"
              style={{
                background: ' #f7fff3',
                border: '0.5px solid #97e373',
              }}
              onClick={() => {
                navigate('/cards');
              }}
            >
              <img
                src={cards}
                alt=""
                className="quickaction__vesticard__cards"
                style={{
                  background: ' #dfffd0',
                }}
              />
              <p>Vesti Cards</p>
              <img
                src={bgcards}
                alt=""
                className="quickaction__vesticard__bgcards"
              />
            </div>
            {/*  */}
            <div
              className="quickaction__vesticard"
              style={{
                background: ' #F6F8FF',
                border: '0.5px solid #7F8CE5',
              }}
              onClick={() => {
                navigate('/pathways');
              }}
            >
              <img
                src={pathwayIcon}
                alt=""
                className="quickaction__vesticard__cards"
                style={{
                  background: ' #DFE3FF',
                }}
              />
              <p>Move Abroad</p>
              <img
                src={bgPathway}
                alt=""
                className="quickaction__vesticard__bgcards"
              />
            </div>

            <div
              className="quickaction__vesticard"
              style={{ background: ' #FFFAEB', border: '0.5px solid #FEC84B' }}
              onClick={() => {
                navigate('/feeds');
              }}
            >
              <img
                src={groups}
                alt=""
                className="quickaction__vesticard__cards"
                style={{ background: '#FEF0C7' }}
              />
              <p>Join Groups</p>
              <img
                src={bgGroup}
                alt=""
                className="quickaction__vesticard__bgcards"
              />
              <img
                src={newIcon}
                alt=""
                className="quickaction__vesticard__newIcon"
              />
            </div>

            <div
              className="quickaction__vesticard"
              style={{ background: ' #FBF3FF', border: '0.5px solid #E5AEFF' }}
              onClick={() => {
                setCurrencyOpt(true);
                // navigate('/bookflight');
              }}
            >
              <img
                src={flight}
                alt=""
                className="quickaction__vesticard__cards"
                style={{
                  background: ' #F0D1FF',
                }}
              />
              <p>Book Flights</p>
              <img
                src={bgflight}
                alt=""
                className="quickaction__vesticard__bgcards"
              />
              <img
                src={newIcon}
                alt=""
                className="quickaction__vesticard__newIcon"
              />
            </div>

            {/* <div>
            <img src={pathwayIcon} alt="" />
            <p>Move Abroad</p>
          </div> */}

            {/* <div>
            <img src={cards} alt="" />
            <p>Vesti Cards</p>
          </div>

          <div>
            <img src={cards} alt="" />
            <p>Vesti Cards</p>
          </div> */}
          </div>
          {/* <div className="newft__bottom__cardrow">
          <SingleCard
            title="Need a Loan for School?"
            subtitle="Apply now"
            image={cardsparkle}
            background="#14290A"
            onClick={() => dispatch(handleFees())}
          />
          <SingleCard
            title="Create a Virtual Dollar Card"
            subtitle=""
            image={cardsparkleblue}
            background="#060E42"
            onClick={() => dispatch(openVirtual('mono'))}
          />
        </div> */}
        </div>
      </section>
    </>
  );
};
