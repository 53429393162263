import React from 'react';
import memberIcon from '../../../assets/membership/cross.svg';

const CancelSubscription = props => {
  return (
    <div>
      {' '}
      <div className="Profilemembership__plan Profilemembership__plan__cancelComponent">
        <div className="Profilemembership__bottom">
          <img src={memberIcon} alt="" />

          <article>
            <p className="Profilemembership__bottom__title">Cancellation</p>
            <p className="Profilemembership__bottom__subtitle">
              You will still have access to paid features till the end of your
              subscription
            </p>
          </article>
        </div>
        <div
          className="Profilemembership__cancel"
          onClick={() => {
            props.setCancelSubModal(true);
          }}
        >
          Cancel Trial
        </div>
      </div>
    </div>
  );
};

export default CancelSubscription;
