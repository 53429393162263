import React from 'react';
import Embed from 'react-embed';
import { useState } from 'react';
import { Modal, Button } from 'antd';

export const EmbeddedCalendyPage = () => {
  const url = 'https://calendly.com/vesti/';

  return (
    <div style={{ height: '100svh' }}>
      {/* <div>
        <Button
          type="primary"
          onClick={() =>
            openModal('https://calendly.com/vesti/founderpaid?month=2024-05')
          }
        >
          Open Modal
        </Button>
       
      </div> */}
      <iframe
        src={url}
        style={{ width: '100%', border: 0, height: '100%' }}
      ></iframe>
    </div>
  );
};
