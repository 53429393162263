import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const MainLazyloader = () => {
  return (
    <div style={{ width: '100%', height: '400px' }}>
      {' '}
      <div className="Profilemembership__plan">
        <div className="Profilemembership__top">
          <div className="Profilemembership__top__col">
            {}
            <p className="Profilemembership__top__title">
              <Skeleton width={100} duration={1} />{' '}
              <span>
                <Skeleton width={100} duration={1} />
              </span>
            </p>
            <p className="Profilemembership__top__planDetails">
              <Skeleton width={100} duration={1} />{' '}
            </p>
          </div>
          {/* <p className="Profilemembership__subscribe">
            <Skeleton width={100} duration={1} />
          </p> */}
        </div>

        <div className="Profilemembership__bottom">
          <article>
            <p className="Profilemembership__bottom__title">
              <Skeleton width={300} height={20} duration={1} />
            </p>
            <p className="Profilemembership__bottom__subtitle">
              <Skeleton width={350} height={25} duration={1} />
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};

const Lazyloader = () => {
  return (
    <div style={{ width: '40%', height: '400px' }}>
      {' '}
      <div className="Profilemembership__plan">
        <div className="Profilemembership__top">
          <div className="Profilemembership__top__col">
            {}
            <p className="Profilemembership__top__title">
              <Skeleton width={100} duration={1} />{' '}
              <span>
                <Skeleton width={100} duration={1} />
              </span>
            </p>
            <p className="Profilemembership__top__planDetails">
              <Skeleton width={100} duration={1} />{' '}
            </p>
          </div>
          {/* <p className="Profilemembership__subscribe">
            <Skeleton width={100} duration={1} />
          </p> */}
        </div>

        <div className="Profilemembership__bottom">
          <article>
            <p className="Profilemembership__bottom__title">
              <Skeleton width={300} height={20} duration={1} />
            </p>
            <p className="Profilemembership__bottom__subtitle">
              <Skeleton width={350} height={25} duration={1} />
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Lazyloader;
