import React, { useEffect, useState } from 'react';
import { Indicators } from 'components/common/indicators';
import { authWords } from 'helpers/data';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Platformbutton } from 'components/common/button/button';
import DividedPinInput from 'components/common/DividedPinInput';
import { AlertError } from 'components/common/alertboxes/alertboxes';
import VestiLogo from 'assets/vesti-colored.png';
import arrowPointer from 'assets/newOnboarding/Ornament.svg';

export const Registerleft = () => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    const authInterval = setInterval(() => {
      setActive(prevState => (prevState === 2 ? 0 : prevState + 1));
    }, 10000);
    return () => {
      clearInterval(authInterval);
    };
  }, []);
  return (
    <section
      className="hidden lg:flex flex-col w-full relative overflow-hidden"
      style={{ height: '100vh', background: '#060E42', width: '40vw' }}
    >
      <img
        style={{ height: '70vh', objectFit: 'cover' }}
        src={authWords[active].image}
        alt="register"
      />

      <div
        style={{
          borderTop: '3px solid #67A948',
          padding: '25px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <img
          style={{ width: '100px', paddingBottom: '20px' }}
          src={VestiLogo}
          alt="Vesti logo"
        />

        <p
          className=" text-white "
          style={{ fontWeight: '700', fontSize: '20px' }}
        >
          {authWords[active].title}
        </p>

        <p
          className=" text-white"
          style={{
            fontWeight: '300',
            fontSize: '14px',
            width: '80%',
            marginBottom: '8px',
          }}
        >
          {authWords[active].subtitle}
        </p>

        <Indicators data={[0, 1, 2]} active={active} click={setActive} />
      </div>
    </section>
  );
};

export const VerifyReg = props => {
  const [pin, setPin] = useState('');

  useEffect(() => {
    props.inner && props.id && props.resend();
    // eslint-disable-next-line
  }, [props.id]);
  return (
    <div
      className={`w-full h-full justify-center relative ${!props.inner &&
        'md:w-[500px]'} flex flex-col flex-start items-center `}
    >
      <img
        src={arrowPointer}
        alt=""
        style={{ position: 'absolute', top: '10%', left: '2%' }}
      />
      {/* <Titlesubtitle
        title="Verify Email"
        subtitle={`Enter the Five (5) digits code we sent to ${props.title}`}
      /> */}
      <div style={{ marginBottom: '4rem' }}>
        <p
          style={{
            fontSize: '22px',
            fontWeight: '700',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '5rem',
          }}
        >
          Verify Your Email
        </p>

        <p
          style={{
            color: '#111827',
            fontSize: '14px',
            fontWeight: '300',
            marginBottom: '20px',
          }}
        >
          Input the code sent to your registered email address to verify your
          email.
        </p>
      </div>

      <div
        className="w-full flex flex-col items-center gap-[5px] text-vesti-900 font-[700] text-[1rem] mb-4"
        style={{ width: '100%' }}
      >
        {/* <p>Enter Five(5) digits code </p> */}
        <DividedPinInput onChange={setPin} len={6} open={props.open} />

        <p
          style={{
            color: '#14290A',
            fontWeight: '600',
            fonntSize: '14px',
            paddingTop: '15px',
            cursor: 'pointer',
          }}
          onClick={() => {
            props.resend();
          }}
        >
          Didn’t get code ?{' '}
          <span style={{ color: '#67A948' }}>Resend here</span>
        </p>

        {/* <Platformbutton
          type="link"
          text="Didn't get the link ? "
          title="Resend"
          click={() => props.resend()}
        /> */}
      </div>
      <div className="w-full flex flex-col gap-[10px] items-center">
        {props.authErr && <AlertError body={props.authErr} />}
        <div className="w-full flex flex-col gap-[10px] items-center">
          <Platformbutton
            name={props.btn ? props.btn : 'Verify'}
            type="normal"
            click={() => props.verify(pin)}
            disabled={props.loading || pin.length < 6 ? true : false}
          />
          <p
            onClick={() => {
              props.setStep(props.step - 1);
            }}
            style={{
              color: '#67A948',
              fontWeight: '600',
              fontSize: '18px',
              cursor: 'pointer',
            }}
          >
            Go back
          </p>
        </div>
      </div>
    </div>
  );
};

export const Passwordcheck = props => {
  return (
    <p
      className={`${
        props.check
          ? 'text-white bg-vesti-800'
          : 'bg-grey-200 border-dashed border-1 border-grey-500 text-grey-700'
      } w-fit rounded-[30px] text-[.8em] font-[500]  my-0 py-[8px] px-[10px]`}
    >
      {props.name}
    </p>
  );
};
