import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Inputfloat from 'components/common/inputs/inputfloat';
import {
  CustomizedSingleselect,
  Singleselect,
} from 'components/common/inputs/singleselect';
import '../EligibilityTest/eligibility.scss';
import { Platformbutton } from 'components/common/button/button';
import {
  RadioOption,
  VerySmallRadioOption,
  NewVerySmallRadioOption,
} from 'components/common/radiobutton/radiobutton';
import successPng from 'assets/physicalCard/newsuccess.png';
import NotEligIcon from 'assets/chats/notElig.svg';
import errorsvg from 'assets/newerror.svg';
import { fetchAllCountries } from 'appRedux/actions/Common';
import { useSelector } from 'react-redux';
import api from 'appRedux/api';
import { fetchUserData } from 'appRedux/actions/profile';
import { radio_card, radio_ngn } from 'assets/assets';
import CheckedIndicator from 'assets/physicalCard/Indicator.png';
import PendingIndicator from 'assets/physicalCard/pendingIndicator.png';
import './index.scss';
import { ClarityBooking } from './clarityBooking';
import { useParams } from '@reach/router';
import { PaystackButton } from 'react-paystack';
import { JustLoader } from 'components/Loader/Loader';
import { navigate } from '@reach/router';
import pathwayconfig from 'config.js';

export const IndexClarityBookings = props => {
  const publicKey = 'pk_live_5ac54092a585eaedb83ec09a1f8896928ffdca87';

  const urlparams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();

  const countries = useSelector(state => state.common?.countries);
  const userD = useSelector(state => state.auth?.userData);

  const paymentIntent = urlparams?.get('payment_intent');
  const paymentIntentSecret = urlparams?.get('reference');
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [step, setStep] = useState(2);
  const [accessCode, setAccessCode] = useState('');
  const [reference, setReference] = useState('');
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [url, setUrl] = useState(null);

  const [rate, setRate] = useState(null);

  var gender = [{ opt: 'Male' }, { opt: 'Female' }];
  var expertise = [
    { opt: 'Arts' },
    { opt: 'Sciences, Education, Business or Athletics' },
    { opt: 'Motion pictures or Television' },
  ];
  var visaType = [
    { opt: '0-1' },
    { opt: 'EB-1' },
    { opt: 'EB-2 NIW' },
    { opt: 'H1B' },
  ];

  const [state, setState] = useState({
    fullName: `${userD?.firstName} ${userD?.lastName}`,
    email: userD?.email,
    gender: '',
    expertise: '',
    visatype: '',
    country: '',
  });

  const [test, setTest] = useState({
    callType: '',
    currency: '',
  });

  const [amount, setAmount] = useState(0); // Initial amount is 0

  useEffect(() => {
    const fetchpriceData = async () => {
      try {
        const response = await api.get(
          `/vesti-fx/lists?currency=${test?.currency ? test?.currency : 'USD'}`,
        );
        setRate(response.data?.usdConversionRate);
      } catch (error) {
        console.log(error);
      }
    };

    fetchpriceData();
  }, [test.currency]);

  const handleOptionChange = value => {
    setTest({ ...test, callType: value });
    console.log('type', value);

    if (value == 'inPerson') {
      setAmount(99.0);
    }
    if (value == 'preRecorded') {
      setAmount(39.99);
    }
    if (value == 'miai') {
      setAmount(19.99);
    }
  };

  const setoptionQ2 = value => {
    setTest({ ...test, currency: value });
  };

  var handleSelect = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const chargeCard = async () => {
    const myamount = Math.round(amount * 100);
    const data = {
      email: userD?.email,
      fullname: `${userD?.firstName} ${userD?.lastName}`,
      paymentOption: 'card',
      currency: test.currency,
      amount: test.currency == 'NGN' ? amount * 100 * rate : myamount,
      clarityCallName: `${props.visaType ? props.visaType : state.visatype}`,
    };
    try {
      // Make a request to your server to obtain the access code
      const response = await api.post(
        `${pathwayconfig.baseURL}/pathway/pay-for-clarity-call-initiate`,
        data,
      );
      // console.log('Response : ', response);
      setAccessCode(response.data?.data?.access_code);
      setReference(response.data?.reference);
      //   setLoading(false);
      setStep(4);
      // Once access code is obtained, initiate the payment process
    } catch (error) {
      //   setLoading(false);
      console.error('Error fetching access code:', error);
    }
  };

  const handlePaystackSuccessAction = async reference => {
    const data = {
      email: userD?.email,
      fullname: `${userD?.firstName} ${userD?.lastName}`,
      paymentOption: 'card',
      currency: test.currency,
      clarityCallName: `${props.visaType}`,
      amount: amount * 100 * rate,
      // referalCode: formData?.referalCode,
      reference: reference.reference,
    };
    // console.log('Data : ', data);
    try {
      const res = await api.post(
        `${pathwayconfig.baseURL}/pathway/verify-reference`,
        data,
      );
      // console.log(res);
      setUrl(res.data?.link);

      // setTimeout(() => {
      //   window.open(res.data?.clarityCallUrl);
      // }, 100);
      if (test.callType == 'miai') {
        setStep(7);
        props.handleSubmit('Yes', 'No');
      }
      if (test.callType == 'preRecorded') {
        setStep(7);
        props.handleSubmit('No', 'Yes');
      }
      if (test.callType == 'inPerson') {
        setStep(6);
      }
      // test.callType == 'inPerson' ? setStep(6) : setStep(7);

      //   setLoading(false);
    } catch (error) {
      //   setLoading(false);
      console.error('Error fetching card:', error);
    }
  };

  const handleStripeSuccessAction = async ref => {
    const data = {
      email: userD?.email,
      fullname: `${userD?.firstName} ${userD?.lastName}`,
      paymentOption: 'card',
      currency: test.currency,
      clarityCallName: `${props.visaType}`,
      amount: amount * 100,
      // referalCode: formData?.referalCode,
      reference: ref,
    };
    // console.log('Data : ', data);
    try {
      const res = await api.post(
        `${pathwayconfig.baseURL}/pathway/verify-reference`,
        data,
      );
      // console.log('handleStripeSucess Action', res.data);
      setUrl(res.data?.link);
      setIsSuccessful(true);

      if (test.callType == 'miai') {
        props.handleSubmit('Yes', 'No');
      }
      if (test.callType == 'preRecorded') {
        props.handleSubmit('Yes', 'No');
      }

      // test.callType == 'inPerson' ? setStep(6) : setStep(7);

      //   setLoading(false);
    } catch (error) {
      //   setLoading(false);
      console.error('Error fetching card:', error);
    }
  };

  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    setAccessCode('');
    setReference('');
    console.log('closed');
  };

  const config = {
    reference,
    email: userD?.email,
    amount: amount * 100 * rate,
    publicKey: publicKey,
  };

  const componentProps = {
    ...config,
    text: 'Continue',
    onSuccess: reference => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  const chargeCardDollar = async () => {
    setLoading(true);
    const data = {
      fullname: `${userD?.firstName} ${userD?.lastName}`,
      paymentOption: 'card',
      email: userD?.email,
      currency: test.currency,
      amount: Math.round(amount * 100),
      clarityCallName: `${props.visaType ? props.visaType : state.visatype}`,
    };

    try {
      const res = await api.post(
        `${pathwayconfig.baseURL}/pathway/pay-for-clarity-call-initiate`,
        data,
      );

      setClientSecret(res.data?.data?.client_secret);
      setReference(res.data?.reference);
      console.log('Client Secret', res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error initializing transaction:', error);
    }
  };

  useEffect(() => {
    if (test.currency === 'USD') {
      chargeCardDollar();
    }
  }, [test.currency]);

  useEffect(() => {
    if (paymentIntent) {
      handleStripeSuccessAction();
    }
  }, [paymentIntent]);

  switch (step) {
    case 1:
      return (
        <div>
          <p style={{ color: '#67A948', fontSize: '13px', fontWeight: '600' }}>
            Step {step} of 3
          </p>
          <p
            style={{
              color: '#14290A',
              fontSize: '22px',
              fontWeight: '600',
              marginTop: '20px',
              marginBottom: '10px',
            }}
          >
            Personal Info
          </p>
          <p style={{ color: '#2B5219', fontSize: '12px', fontWeight: '600' }}>
            We need to collect this info to personalize your quiz for you.
          </p>

          <div className="testSectionOne ">
            <Inputfloat
              type="text"
              name="fullName"
              label="Full Name"
              value={`${userD?.firstName} ${userD?.lastName}`}
              disabled={true}
              placeholder="Enter your Full Name"
              onChange={e => setState({ ...state, fullName: e.target.value })}

              //   onChange={setInput}
            />
            <Inputfloat
              type="text"
              name="email"
              label="Email"
              value={userD?.email}
              disabled={true}
              placeholder="Enter your Email"
              onChange={e => setState({ ...state, email: e.target.value })}
              //   onChange={setInput}
            />

            <Singleselect
              value={state.gender}
              options={gender.map((op, index) => ({
                key: index,
                label: op.opt,
                value: op.opt,
              }))}
              placeholder={`${state.gender ? state.gender : 'Select Gender'}`}
              onChange={selectedValue => {
                handleSelect('gender', selectedValue.value);
              }}
              name="gender"
            />

            <Singleselect
              value={state.expertise}
              options={expertise.map((op, index) => ({
                key: index,
                label: op.opt,
                value: op.opt,
              }))}
              placeholder={`${
                state.expertise ? state.expertise : 'Select Expertise'
              }`}
              onChange={selectedValue => {
                handleSelect('expertise', selectedValue.value);
              }}
              name="expertise"
            />

            <Singleselect
              value={props.visaType ? props.visaType : state.visatype}
              options={
                props.visaType
                  ? ''
                  : visaType.map((op, index) => ({
                      key: index,
                      label: op.opt,
                      value: op.opt,
                    }))
              }
              placeholder={`${
                state.visatype
                  ? `${state.visatype} Visa`
                  : props.visaType
                  ? props.visaType
                  : 'Select VISA Type'
              }`}
              onChange={selectedValue => {
                handleSelect('visatype', selectedValue.value);
              }}
              name="visatype"
              disabled={props.visaType ? true : false}
            />

            <Singleselect
              value={state.country}
              options={countries}
              placeholder={`${
                state.country ? state.country : 'Select Country of Residence'
              }`}
              onChange={selectedValue => {
                handleSelect('country', selectedValue.label);
              }}
              name="country"
            />
          </div>

          <div className="flexButton" style={{ width: '100%' }}>
            <div style={{ width: '100%' }}>
              {' '}
              <Platformbutton
                type="secondary"
                name="Cancel"
                click={() => {
                  props.setOpenTestModal(false);
                }}
                classname="fas fa-arrow-right"
              />
            </div>
            <div style={{ width: '100%' }}>
              <Platformbutton
                type="normal"
                name="Continue"
                click={() => {
                  setStep(2);
                }}
                classname="fas fa-arrow-right"
                disabled={
                  !state.email ||
                  !state.gender ||
                  !state.fullName ||
                  !state.expertise ||
                  !state.country ||
                  (!state.visatype && !props.visaType)
                    ? true
                    : false
                }
              />
            </div>
          </div>
        </div>
      );

    case 2:
      return (
        <div className="textcontnew">
          <p style={{ color: '#67A948', fontSize: '13px', fontWeight: '600' }}>
            Step {step} of 3
          </p>
          <p
            style={{
              color: '#14290A',
              fontSize: '22px',
              fontWeight: '600',
              marginTop: '20px',
              marginBottom: '10px',
            }}
          >
            Select Clarity Call Type
          </p>
          <p style={{ color: '#2B5219', fontSize: '12px', fontWeight: '600' }}>
            Select from the list below which clarity call type you’d prefer
          </p>

          <div
            className="mt-4"
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            <RadioOption
              image={radio_card}
              changed={handleOptionChange}
              id="1"
              isSelected={test.callType === 'inPerson'}
              label={
                <p style={{ fontSize: '18px', fontWeight: '600' }}>
                  One on One Call{' '}
                  <span style={{ color: '#67A948' }}>($99.00)</span>{' '}
                </p>
              }
              sublabel={
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: '300',
                    lineHeight: '20px',
                  }}
                >
                  Meet one-on-one with a Vesti team member. You’ll get to
                  receive Vesti’s annual community membership worth
                  <span style={{ color: '#67A948' }}>$299.</span>{' '}
                </p>
              }
              value="inPerson"
            />
            <RadioOption
              image={radio_card}
              changed={handleOptionChange}
              id="2"
              isSelected={test.callType === 'preRecorded'}
              label={
                <p style={{ fontSize: '18px', fontWeight: '600' }}>
                  Pre-recorded Call{' '}
                  <span style={{ color: '#67A948' }}>($39.99)</span>{' '}
                </p>
              }
              sublabel={
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: '300',
                    lineHeight: '20px',
                  }}
                >
                  Gain valuable insights from a pre-recorded session with a
                  Vesti expert, offering you expert guidance and support.
                </p>
              }
              value="preRecorded"
            />
            <div className="miaicoingsoonCla">
              <p className="miaicoingsoonClaText">Coming soon</p>
              <RadioOption
                image={radio_card}
                changed={handleOptionChange}
                id="3"
                isSelected={test.callType === 'miai'}
                label={
                  <p
                    style={{
                      fontSize: '18px',
                      fontWeight: '600',
                    }}
                  >
                    Miai Migration Assistant{' '}
                    <div
                      style={{ marginTop: '6px' }}
                      className="smallAItextHidden "
                    />{' '}
                    AI <span style={{ color: '#67A948' }}>($19.99)</span>{' '}
                  </p>
                }
                sublabel={
                  <p
                    style={{
                      fontSize: '14px',
                      fontWeight: '300',
                      lineHeight: '20px',
                    }}
                  >
                    Gain valuable insights from a pre-recorded session with our
                    Migration Assistant AI, offering you guidance and support.
                  </p>
                }
                value="miai"
              />
            </div>
          </div>

          <div className="flexButton" style={{ width: '100%' }}>
            <div style={{ width: '100%' }}>
              {' '}
              <Platformbutton
                type="secondary"
                name="Go Back"
                click={() => {
                  setStep(1);
                }}
                classname="fas fa-arrow-right"
              />
            </div>
            <div style={{ width: '100%' }}>
              <Platformbutton
                type="normal"
                name="Continue"
                click={() => {
                  setStep(3);
                }}
                disabled={
                  !test.callType || test.callType === 'miai' ? true : false
                }
                classname="fas fa-arrow-right"
              />
            </div>
          </div>
        </div>
      );
    case 3:
      return (
        <div className="textcontnew">
          <p style={{ color: '#67A948', fontSize: '13px', fontWeight: '600' }}>
            Step {step} of 3
          </p>
          <p
            style={{
              color: '#14290A',
              fontSize: '22px',
              fontWeight: '600',
              marginTop: '20px',
              marginBottom: '10px',
            }}
          >
            Book an {props.visaType ? props.visaType : state.visatype} Visa
            Clarity Call Session
          </p>
          <p style={{ color: '#2B5219', fontSize: '12px', fontWeight: '600' }}>
            Fill in the details below to book and confirm your session.
            {/* {state.visatype} VISA */}
          </p>
          <div className="mt-4 mb-4">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '90%',
                justifyContent: 'center',
                gap: '20px',
                padding: '10px',
              }}
            >
              <div className="CCSingleprogress">
                {/* <img src={PendingIndicator} alt="" /> */}
                <img src={CheckedIndicator} alt="" />
                <p>Personal Info</p>
              </div>

              <div className="CCPendingLines"></div>

              <div
                className="CCSingleprogress"
                style={{ position: 'relative' }}
              >
                {/* <div
                  style={{
                    padding: '4px 12px',
                    border: '1px solid #98A2B3',
                    borderRadius: '100%',
                    background: '#F7F8FA',
                  }}
                >
                  2{' '}
                </div> */}
                <img src={PendingIndicator} alt="" />
                <p>Payment Information</p>
              </div>

              <div className="CCPendingLines"></div>

              <div className="CCSingleprogress">
                <div
                  style={{
                    padding: '4px 12px',
                    border: '1px solid #98A2B3',
                    borderRadius: '100%',
                    background: '#F7F8FA',
                  }}
                >
                  3{' '}
                </div>
                {/* <img src={PendingIndicator} alt="" /> */}
                <p>Clarity Call Booking</p>
              </div>
            </div>

            {/* Input Personal details */}
            <div style={{ marginTop: '2.5rem' }}>
              {' '}
              <p
                style={{
                  color: '#67A948',
                  fontSize: '16px',
                  fontWeight: '600',
                }}
              >
                Personal Details
              </p>
              <div
                className="radioFlexEligibilityTest"
                style={{ marginTop: '10px' }}
              >
                <div className="radioFlexEligibilityTest">
                  <Inputfloat
                    type="text"
                    name="fullName"
                    label="Full Name"
                    value={`${userD?.firstName} ${userD?.lastName}`}
                    disabled={true}
                    placeholder="Enter your Full Name"
                    onChange={e =>
                      setState({ ...state, fullName: e.target.value })
                    }

                    //   onChange={setInput}
                  />

                  <Inputfloat
                    type="text"
                    name="email"
                    label="Email"
                    value={userD?.email}
                    disabled={true}
                    placeholder="Enter your Email"
                    onChange={e =>
                      setState({ ...state, email: e.target.value })
                    }
                    //   onChange={setInput}
                  />
                </div>
              </div>
            </div>

            {/* Select Currency here ...  */}
            <div>
              {' '}
              <p
                style={{
                  color: '#67A948',
                  fontSize: '16px',
                  fontWeight: '600',
                }}
              >
                Payment Method
              </p>
              <div
                className="radioFlexEligibilityTest"
                style={{ marginTop: '2px' }}
              >
                <div className="radioFlexEligibilityTest">
                  <RadioOption
                    image={radio_card}
                    changed={setoptionQ2}
                    id="3"
                    isSelected={test.currency === 'NGN'}
                    label={
                      <p
                        style={{
                          fontSize: '12px',
                          fontWeight: '600',
                          color: '#2B5219',
                          paddingTop: '15px',
                          lineHeight: '20px',
                        }}
                      >
                        Pay in Naira (NGN)
                      </p>
                    }
                    sublabel={''}
                    value="NGN"
                  />

                  <RadioOption
                    image={radio_card}
                    changed={setoptionQ2}
                    id="4"
                    isSelected={test.currency === 'USD'}
                    label={
                      <p
                        style={{
                          fontSize: '12px',
                          fontWeight: '600',
                          color: '#2B5219',
                          paddingTop: '15px',
                          lineHeight: '20px',
                        }}
                      >
                        Pay in Dollars (USD)
                      </p>
                    }
                    sublabel={''}
                    value="USD"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flexButton" style={{ width: '100%' }}>
            {loading ? (
              <></>
            ) : (
              <div style={{ width: '100%' }}>
                {' '}
                <Platformbutton
                  type="secondary"
                  name="Go Back"
                  click={() => {
                    setStep(2);
                  }}
                  classname="fas fa-arrow-right"
                />
              </div>
            )}

            {loading ? (
              <div style={{ width: '100%' }}>
                <JustLoader />
              </div>
            ) : test.currency === 'NGN' ? (
              <PaystackButton
                className="platform platform_mainbtn"
                {...componentProps}
              />
            ) : (
              <div style={{ width: '100%' }}>
                <Platformbutton
                  type="normal"
                  name="Continue"
                  click={() => {
                    chargeCard();
                  }}
                  disabled={!test.currency ? true : false}
                  classname="fas fa-arrow-right"
                />{' '}
              </div>
            )}
          </div>
        </div>
      );
    case 4:
      return (
        <div className="">
          <ClarityBooking
            visaType={state.visatype ? state.visatype : props.visaType}
            urlparams={urlparams}
            reference={reference}
            accessCode={accessCode}
            setReference={setReference}
            setAccessCode={setAccessCode}
            chargeCard={chargeCard}
            currency={test.currency}
            loading={loading}
            setLoading={setLoading}
            clientSecret={clientSecret}
            userD={userD}
            amount={amount}
            setStep={setStep}
            rate={rate}
            setOpenTestModal={props.setOpenTestModal}
            test={test}
            isSuccessful={isSuccessful}
            setIsSuccessful={setIsSuccessful}
            handleStripeSuccessAction={handleStripeSuccessAction}
          />
        </div>
      );
    case 5:
      return (
        <div className="textcontnew">
          {' '}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <img src={NotEligIcon} alt="" />
          </div>
          <div className="">
            <p
              style={{
                color: '#62943B',
                fontSize: '20px',
                fontWeight: '600',
                textAlign: 'center',
              }}
            >
              You’re not eligible
            </p>
            <p
              style={{
                color: '#2B5219',
                fontSize: '13px',
                textAlign: 'center',
              }}
            >
              {' '}
              Sorry, you are not eligible to use this Visa Pathway, not to
              worry, our team just sent you an email on how you can qualify in
              3-6months.
              <span style={{ color: '#67A948' }}>
                {' '}
                We encourage you to proceed to document uploads. A member of our
                team will be in touch.
              </span>
            </p>
          </div>
          <div style={{ marginBottom: '10px' }}></div>
          <div style={{ width: '100%' }}>
            <Platformbutton
              type="normal"
              name={'Okay, thank you'}
              click={() => {
                props.setOpenTestModal();
              }}
            />
          </div>
        </div>
      );
    case 6:
      return (
        <div className="" style={{ width: '80vw', height: '80vh' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'center',
              // gap: '20px',
              // padding: '10px',
            }}
          >
            <p
              style={{
                color: '#67A948',
                fontSize: '13px',
                fontWeight: '600',
                marginBottom: '10px',
              }}
            >
              Step 3 of 3
            </p>
            <p
              style={{
                color: '#14290A',
                fontSize: '22px',
                fontWeight: '600',
                marginTop: '20px',
                marginBottom: '10px',
              }}
            >
              Book an {props.visaType ? props.visaType : state.visatype} Visa
              Clarity Call Session
            </p>
            <p
              style={{ color: '#2B5219', fontSize: '12px', fontWeight: '600' }}
            >
              Fill in the details below to book and confirm your session.
              {/* {state.visatype} VISA */}
            </p>
          </div>
          <div className="mt-4 mb-4">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'center',
                gap: '20px',
                padding: '10px',
              }}
            >
              <div className="CCSingleprogress">
                {/* <img src={PendingIndicator} alt="" /> */}
                <img src={CheckedIndicator} alt="" />
                <p>Personal Info</p>
              </div>

              <div className="CCPendingLines"></div>

              <div
                className="CCSingleprogress"
                style={{ position: 'relative' }}
              >
                <img src={CheckedIndicator} alt="" />

                <p>Payment Information</p>
              </div>

              <div className="CCPendingLines"></div>

              <div className="CCSingleprogress">
                {/* <div
                  style={{
                    padding: '4px 12px',
                    border: '1px solid #98A2B3',
                    borderRadius: '100%',
                    background: '#F7F8FA',
                  }}
                >
                  3{' '}
                </div> */}
                <img src={PendingIndicator} alt="" />
                {/* <img src={PendingIndicator} alt="" /> */}
                <p>Clarity Call Booking</p>
              </div>
            </div>

            <div style={{ marginTop: '2.5rem' }}>
              <iframe
                src={url}
                style={{ width: '100%', border: 0, height: '60vh' }}
              ></iframe>
            </div>
          </div>
        </div>
      );
    case 7:
      return (
        <div className="textcontnew">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <img src={successPng} alt="" />
          </div>

          <div className="">
            <p
              style={{
                color: '#62943B',
                fontSize: '20px',
                fontWeight: '600',
                textAlign: 'center',
              }}
            >
              Payment Successful
            </p>
            <p
              style={{
                color: '#2B5219',
                fontSize: '13px',
                textAlign: 'center',
              }}
            >
              You have successfully paid for your U.S{' '}
              {props.visaType ? props.visaType : state.visatype} Visa clarity
              call video. You can access your clarity call video when you start
              filling U.S {props.visaType ? props.visaType : state.visatype}{' '}
              Visa Requirement Documents
            </p>
          </div>
          <div style={{ marginBottom: '10px' }}></div>

          <div style={{ width: '100%' }}>
            <Platformbutton
              type="normal"
              name={'Take me to video'}
              click={() => {
                navigate(
                  `/pathways/${
                    props.visaType == 'EB-2 NIW' ? 'EB-2NIW' : props.visaType
                  }visa`,
                );
              }}
            />
          </div>
        </div>
      );
    default:
      return <div></div>;
  }
};
