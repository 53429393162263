import React, { useState, useEffect } from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import './index.scss';
import { LargemodalCustomCancel } from 'components/common/simplifiedmodal';
import CheckedIndicator from 'assets/physicalCard/Indicator.png';
import PendingIndicator from 'assets/physicalCard/pendingIndicator.png';
import { LiaTimesSolid } from 'react-icons/lia';
import successPng from 'assets/physicalCard/newsuccess.png';
import { Platformbutton } from 'components/common/button/button';
import { navigate } from '@reach/router';

export default function CheckoutForm({
  visaType,
  fullname,
  email,
  Reference,
  redirctUrl,
  setStep,
  setOpenTestModal,
  test,
  isSuccessful,
  setIsSuccessful,
  handleStripeSuccessAction,
}) {
  const [openClarityModal, setOpenClarityModal] = useState(false);

  const [isStripeLoaded, setIsStripeLoaded] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const checkStripeLoaded = () => {
      if (stripe && elements) {
        setIsStripeLoaded(true);
      }
    };

    checkStripeLoaded();
  }, [stripe, elements]);

  const handleSubmit = async e => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      // confirmParams: {
      //   return_url: `${window.location.origin}/pathways/visa?reference=${Reference}`,
      // },

      redirect: 'if_required', // Use 'if_required' to prevent unnecessary redirects
    });

    if (paymentIntent) {
      // Handle successful payment intent

      if (paymentIntent.status == 'succeeded') {
        handleStripeSuccessAction(Reference);
        //  setIsSuccessful(true);
        setMessage('Payment succeeded!');
      }
      // switch (paymentIntent.status) {
      //   case 'succeeded':
      //     setIsSuccessful(true);
      //     setMessage('Payment succeeded!');
      //     console.log('Payment succeeded!');

      //   case 'processing':
      //     setMessage('Your payment is processing.');
      //     console.log('your payment is processing');

      //   case 'requires_payment_method':
      //     setMessage('Payment failed. Please try another payment method.');
      //     console.log('your payment is requires_payment_method');

      //   default:
      //     setMessage('An unexpected error occurred.');
      // }
    }
    if (error?.type === 'card_error' || error?.type === 'validation_error') {
      console.log(error);
      setMessage(error.message);
    } else {
      console.log(error);
      setMessage('An unexpected error occured.');
    }

    setIsProcessing(false);
  };

  return (
    <>
      {/* <LargemodalCustomCancel
        open={openClarityModal}
        onClick={() => setOpenClarityModal(false)}
      >
      
      </LargemodalCustomCancel> */}

      {isSuccessful ? (
        test.callType == 'inPerson' ? (
          <div className="" style={{ width: '80vw', height: '80vh' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'center',
                // gap: '20px',
                // padding: '10px',
              }}
            >
              <p
                style={{
                  color: '#67A948',
                  fontSize: '13px',
                  fontWeight: '600',
                  marginBottom: '10px',
                }}
              >
                Step 3 of 3
              </p>
              <p
                style={{
                  color: '#14290A',
                  fontSize: '22px',
                  fontWeight: '600',
                  marginTop: '20px',
                  marginBottom: '10px',
                }}
              >
                Book an {visaType} Visa Clarity Call Session
              </p>
              <p
                style={{
                  color: '#2B5219',
                  fontSize: '12px',
                  fontWeight: '600',
                }}
              >
                Fill in the details below to book and confirm your session.
                {/* {state.visatype} VISA */}
              </p>
            </div>
            <div className="mt-4 mb-4">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'center',
                  gap: '20px',
                  padding: '10px',
                }}
              >
                <div className="CCSingleprogress">
                  {/* <img src={PendingIndicator} alt="" /> */}
                  <img src={CheckedIndicator} alt="" />
                  <p>Personal Info</p>
                </div>

                <div className="CCPendingLines"></div>

                <div
                  className="CCSingleprogress"
                  style={{ position: 'relative' }}
                >
                  <img src={CheckedIndicator} alt="" />

                  <p>Payment Information</p>
                </div>

                <div className="CCPendingLines"></div>

                <div className="CCSingleprogress">
                  <img src={PendingIndicator} alt="" />
                  <p>Clarity Call Booking</p>
                </div>
              </div>

              <div style={{ marginTop: '2.5rem' }}>
                <iframe
                  src={'https://calendly.com/vesti/general-clarity-session-u-p'}
                  style={{
                    width: '100%',
                    border: 0,
                    height: '60vh',
                  }}
                ></iframe>
              </div>
            </div>
          </div>
        ) : (
          <div className="textcontnew">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <img src={successPng} alt="" />
            </div>

            <div className="">
              <p
                style={{
                  color: '#62943B',
                  fontSize: '20px',
                  fontWeight: '600',
                  textAlign: 'center',
                }}
              >
                Payment Successful
              </p>
              <p
                style={{
                  color: '#2B5219',
                  fontSize: '13px',
                  textAlign: 'center',
                }}
              >
                You have successfully paid for your U.S {visaType} Visa clarity
                call video. You can access your clarity call video when you
                start filling U.S {visaType} Visa Requirement Documents
              </p>
            </div>
            <div style={{ marginBottom: '10px' }}></div>

            <div style={{ width: '100%' }}>
              <Platformbutton
                type="normal"
                name={'Take me to video'}
                click={() => {
                  navigate(
                    `/pathways/${
                      visaType == 'EB-2 NIW' ? 'EB-2NIW' : visaType
                    }visa`,
                  );
                  //  setStep(6);
                }}
              />
            </div>
          </div>
        )
      ) : (
        <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" />
          <button disabled={isProcessing || !stripe || !elements} id="submit">
            <span id="button-text">
              {isProcessing ? 'Processing ... ' : 'Pay now'}
            </span>
          </button>
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </form>
      )}
    </>
  );
}
