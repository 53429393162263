import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IoImageOutline } from "react-icons/io5";
import { BiMoviePlay } from "react-icons/bi";
import { LiaPollSolid } from "react-icons/lia";
import Cropper from 'react-easy-crop';
import getCroppedImg from './getCroppedImg';
import dummyAvatar from '../../assets/dummy-avatar.png';
import EmojiPicker from 'containers/Admin/NewDashboard/EmojiPicker';
import './NewUpload.scss';

export const NewUpload = (props) => {
    const [selectedFile, setSelectedFile] = useState([]);
    const [selectedFileBinary, setSelectedFileBinary] = useState([]);
    // const [localDescription, setLocalDescription] = useState(props.post.description || '');
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixelsArray, setCroppedAreaPixelsArray] = useState([]);
    const [croppingIndex, setCroppingIndex] = useState(null);
    const fileInputRef = useRef(null); 
    const [isCreatingPost, setIsCreatingPost] = useState(false)

    const isClickedRef = useRef(false);



    // clear content after closing modal
    useEffect(() => {
        console.log('Effect triggered:', props.open);
        if (!props.open) {
            // Reset the state when the modal is closed
            setSelectedFile([]);
            setSelectedFileBinary([]);
            // setPostDescription('');
            setCroppedAreaPixelsArray([]);
            props.setInput({ ...props.post.description === '' });
        }
    }, [props.open]);



    const handleFileChange = (event) => {
        if (event.target.files.length === 0) {
            return; // Exit if no files are selected (cancel action)
        }

        const files = Array.from(event.target.files).slice(0, 3); // Limit to 3 files
        const fileURLs = files.map(file => URL.createObjectURL(file));
        setSelectedFile(fileURLs);
        setSelectedFileBinary(files);
        setCroppedAreaPixelsArray(Array.from({ length: files.length }, () => null)); // Reset cropped areas
    };

    const handleIconClick = () => {
        fileInputRef.current.click();
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        const newCroppedAreaPixelsArray = [...croppedAreaPixelsArray];
        newCroppedAreaPixelsArray[croppingIndex] = croppedAreaPixels;
        setCroppedAreaPixelsArray(newCroppedAreaPixelsArray);
    }, [croppedAreaPixelsArray, croppingIndex]);

    const handleCrop = async (index) => {
        const croppedImage = await getCroppedImg(selectedFile[index], croppedAreaPixelsArray[index]);
        const newFile = new File([croppedImage], selectedFileBinary[index].name, { type: selectedFileBinary[index].type });
        const newSelectedFileBinary = [...selectedFileBinary];
        newSelectedFileBinary[index] = newFile;
        setSelectedFileBinary(newSelectedFileBinary);
        const newSelectedFile = [...selectedFile];
        newSelectedFile[index] = URL.createObjectURL(croppedImage);
        setSelectedFile(newSelectedFile);
        setCroppingIndex(null);
    };

    const handlePost = async (e) => {
        e.preventDefault();
        if (isClickedRef.current || isCreatingPost) return;

    // Set creating post state to true
    setIsCreatingPost(true);
    isClickedRef.current = true;

    try {
        await props.createPost(e, selectedFileBinary, props.setInputModal);
    } catch (error) {
        console.error('Error creating post:', error);
    } finally {
        // Once post creation is done, reset the state
        setIsCreatingPost(false);
        isClickedRef.current = false;
    }
    };

    // remove previewed image
    const handleRemoveImage = (index) => {
        const newSelectedFile = selectedFile.filter((_, i) => i !== index);
        const newSelectedFileBinary = selectedFileBinary.filter((_, i) => i !== index);
        const newCroppedAreaPixelsArray = croppedAreaPixelsArray.filter((_, i) => i !== index);
    
        setSelectedFile(newSelectedFile);
        setSelectedFileBinary(newSelectedFileBinary);
        setCroppedAreaPixelsArray(newCroppedAreaPixelsArray);
    };
    

    return (
        <div className='newUploadContainer' >
        <div className='modalImgName'>
            <img className='newUploadImg' src={props.profilpicture ? props.profilpicture : dummyAvatar} alt=""/>
            <h4 className='uploadName'>
            {`${props.firstname} ${props.lastname}`}
            </h4>
        </div>
        <textarea name="" 
        className='largeModalText' 
        placeholder='What do you want to talk about?' 
        // value={postDescription}
        // onChange={(e) => setPostDescription(e.target.value)}
        value={props.post.description}
        onChange={props.setInput}
        >

        </textarea>

        {selectedFile.length > 0 && (
            <div className='imgPreviewDiv'>
            {selectedFile.map((image, index) => (
                <div key={index} style={{ width: '135px', height: '135px', position: 'relative' }}>
                <img
                    src={image}
                    alt={`preview ${index}`}
                    className='imgPreview'
                    style={{
                    display: croppingIndex === index ? 'none' : 'block'
                    }}
                    onClick={() => setCroppingIndex(index)}
                />
                {croppingIndex === index && (
                    <div className="crop-container" style={{ width: '135px', height: '135px', position: 'absolute', top: 0, left: 0}}>
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                        cropShape="rect"
                        showGrid={false}
                        style={{
                        containerStyle: { width: '135px', height: '135px' },
                        cropAreaStyle: { borderRadius: '5px' }
                        }}
                    />
                    <button onClick={() => handleCrop(index)} style={{ position: 'absolute', bottom: '10px', right: '10px' }}>Crop</button>
                    </div>
                )}
                 <button 
                    onClick={() => handleRemoveImage(index)} 
                    style={{ position: 'absolute', top: '5px', right: '5px', background: '#67A948', color: '#ffffff', padding: '8px 4px', fontSize: '10px', border: 'none', borderRadius: '50%', cursor: 'pointer' }}
                >
                    X
                </button>
                </div>
            ))}
            </div>
        )}

        <div className='iconsFlex' style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
            <div className='icons'>
            <IoImageOutline
                className='imgIcon'
                onClick={handleIconClick}
                />
            <input
                type="file"
                accept='.png, .jpg, .svg, .jpeg'
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                multiple
            />
            <p>Image</p>
        </div>
        
        <div className='icons'>
            <div className="uploadEmojiDiv">
                <EmojiPicker onSelect={props.insertEmoji} />
            </div>
            <p className='feelings'>Feelings</p>
            </div>
        </div>

        {props.post.description === '' && selectedFile.length === 0 ? (
            <div className='largeModalPost' style={{ cursor: 'not-allowed', backgroundColor: '#4C5366' }}>Make a post</div>
        ) : (
            <div className='largeModalPost' 
        style={{ 
            cursor: isCreatingPost ? 'not-allowed' : 'pointer', 
            backgroundColor: isCreatingPost || isClickedRef.current ? '#4C5366' : '#67A948',
            pointerEvents: isCreatingPost || isClickedRef.current ? 'none' : 'auto' 
        }} 
        onClick={!isCreatingPost && !isClickedRef.current ? handlePost : null}
    >
        {isCreatingPost ? 'Creating post...' : 'Make a post'}
            </div>
        )}

        </div>
    );
};


