import React, { useState } from 'react';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Phonenumber } from 'components/common/inputs/phoneinput';
import { RadioOption } from 'components/common/radiobutton/radiobutton';
import { Platformbutton } from 'components/common/button/button';
import MiaiAvatar from 'assets/miai/Miai.svg';
import { MiaiNav } from './miaiSidebar';
import { PiGearThin } from 'react-icons/pi';
import './MiaiIndex.scss';
import MiaiLogin from 'containers/Auth/Login/MiaiLogin';
import MiaiSignup from 'containers/Auth/Signup/MiaiSignup';

export const MiaiSignUp = () => {
  const [step, setStep] = useState(0);

  const [option, setOption] = useState('signIn');

  const setPaymentOption = value => {
    setOption(value);
  };

  switch (step) {
    case 0:
      return (
        <SelectOption
          setStep={setStep}
          option={option}
          setPaymentOption={setPaymentOption}
        />
      );
    case 1:
      return <MiaiSignup setStep={setStep} />;
    case 2:
      return <MiaiLogin setStep={setStep} />;
    default:
      break;
  }
};

const SelectOption = props => {
  return (
    <div className="MiaiSignUp">
      {/* <RadioOption
        image={''}
        changed={props.setPaymentOption}
        id="2"
        isSelected={props.option === 'Sign-up'}
        label="Get started"
        sublabel="Click 'Continue' to provide some details and start interacting with Miai"
        value="Sign-up"
      />

      <div className="mb-4"></div>

      <RadioOption
        image={''}
        changed={props.setPaymentOption}
        id="1"
        isSelected={props.option === 'Sign-in'}
        label="Sign in"
        sublabel="Click 'Continue' to sign in seamlessly and start interacting with Miai"
        value="Sign-in"
      />

      <div className="mb-4"></div> */}

      <MiaiNav />
      <div className="mb-4"></div>

      <div
        className="miaiFlexButton "
        // style={{ width: '100%', display: 'flex', gap: '5px' }}
      >
        <Platformbutton
          type="normal"
          name={'Sign up'}
          click={() => {
            props.setStep(1);
          }}
        />

        <div className=" w-full miaiFlexButton__secondary">
          <Platformbutton
            type="secondary"
            name={'Sign in'}
            click={() => {
              props.setStep(2);
            }}
          />
        </div>
      </div>

      {/* <div className="mb-4"></div> */}

      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          color: '#67a948',
          fontWeight: '600',
          cursor: 'pointer',
        }}
      >
        {' '}
        Continue with Vesti account
      </div> */}
    </div>
  );
};

const SignUp = props => {
  return (
    <div>
      <div className="signInTitleSubtitle">
        <h1 className="signInTitleSubtitle__title">Get started</h1>
        <p className="signInTitleSubtitle__subtile">
          Please fill the form below to start interacting with Miai ™
        </p>
      </div>

      <div>
        {/* <PiGearThin className="gearIcon1" /> */}
        {/* <PiGearThin className="gearIcon2" /> */}
        {/* <PiGearThin className="gearIcon3" /> */}
      </div>

      <div className="mb-4"></div>

      <div>
        <Inputfloat
          label="Full Name"
          type="text"
          name="fullName"
          value={''}
          disabled={false}
          placeholder="Enter your Full Name"
          onChange={() => {}}
          invalid={'true'}
          error={''}
        >
          <div className="mb-[10px]"></div>
        </Inputfloat>
        <div style={{ marginBottom: '10px' }}></div>
        <Inputfloat
          label="Email"
          type="text"
          name="email"
          value={''}
          disabled={false}
          placeholder="Enter your Email"
          onChange={() => {}}
          invalid={'true'}
          error={''}
        >
          <div className="mb-[10px]"></div>
        </Inputfloat>
        <div style={{ marginBottom: '10px' }}></div>

        <Inputfloat
          label="Country"
          type="text"
          name="country"
          value={''}
          disabled={false}
          placeholder="Select Country"
          onChange={() => {}}
          invalid={'true'}
          error={''}
        >
          <div className="mb-[10px]"></div>
        </Inputfloat>

        <div style={{ marginBottom: '10px' }}></div>

        <Phonenumber value={'123'} country={'NG'} setValue={() => {}} />
        <div style={{ marginBottom: '20px' }}></div>

        <div style={{ marginBottom: '10px' }}></div>

        <Inputfloat
          label="Password"
          type="text"
          name="password"
          value={''}
          disabled={false}
          placeholder="Enter Password"
          onChange={() => {}}
          invalid={'true'}
          error={''}
        >
          <div className="mb-[10px]"></div>
        </Inputfloat>
      </div>

      <div className="mb-4"></div>

      <div style={{ width: '100%', display: 'flex', gap: '5px' }}>
        <Platformbutton
          type="secondary"
          name={'Go back'}
          click={() => {
            props.setStep(0);
          }}
        />

        <div className="mb-4"></div>

        <Platformbutton
          type="normal"
          name={'Continue'}
          click={() => {
            props.setStep(1);
          }}
        />
      </div>
    </div>
  );
};

const SignIn = props => {
  return (
    <div>
      <div className="signInTitleSubtitle">
        <h1 className="signInTitleSubtitle__title">Log in</h1>
        <p className="signInTitleSubtitle__subtile">
          Log in to start interacting with Miai ™
        </p>
      </div>

      <div>
        {/* <PiGearThin className="gearIcon1" /> */}
        {/* <PiGearThin className="gearIcon2" /> */}
        {/* <PiGearThin className="gearIcon3" /> */}
      </div>

      <div className="mb-4"></div>

      <div>
        <Inputfloat
          label="Email"
          type="text"
          name="email"
          value={''}
          disabled={false}
          placeholder="Enter your Email"
          onChange={() => {}}
          invalid={'true'}
          error={''}
        >
          <div className="mb-[10px]"></div>
        </Inputfloat>

        <div style={{ marginBottom: '10px' }}></div>

        <Inputfloat
          label="Password"
          type="text"
          name="password"
          value={''}
          disabled={false}
          placeholder="Enter your Password"
          onChange={() => {}}
          invalid={'true'}
          error={''}
        >
          <div className="mb-[10px]"></div>
        </Inputfloat>
      </div>

      <div className="mb-4"></div>

      <div style={{ width: '100%', display: 'flex', gap: '5px' }}>
        <Platformbutton
          type="secondary"
          name={'Go back'}
          click={() => {
            props.setStep(0);
          }}
        />

        <div className="mb-4"></div>

        <Platformbutton
          type="normal"
          name={'Continue'}
          click={() => {
            props.setStep(2);
          }}
        />
      </div>
    </div>
  );
};
