import React from 'react';
import Logo from 'assets/vestilogowhite.png';
import './Invoice.scss';

export const InvoiceComponent = React.forwardRef((props, ref) => {
  //  setInvoiceDetails({
  //    accountName: res.data?.data?.account_name,
  //    amountdue: res.data?.data?.amount_due,
  //    customerEmail: res.data?.data?.customer_email,
  //    customerName: res.data?.data?.customer_name,
  //    number: res.data?.data?.number,
  //  });
  //  <InvoiceComponent
  //    ref={componentRef}
  //    invoiceDetails={invoiceDetails}
  //  />;
  const today = new Date();

  // Formatting the date (optional)
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const formattedDate = today.toLocaleDateString(undefined, options);

  return (
    <div ref={ref} style={{ height: '100svh' }}>
      <>
        <div className="visaInvoiceNav">
          <p
            style={{
              color: 'white',
              fontSize: '30px',
              fontWeight: '600',
              letterSpacing: '1px',
            }}
          >
            Invoice
          </p>
          <img style={{ width: '6rem' }} src={Logo} alt="" />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '70%',
          }}
        >
          <div>
            <div className="invoiceHeader">
              <div>
                <p
                  style={{
                    color: '#98A2B3',
                    fontWeight: '500',
                    fontSize: '15px',
                    paddingBottom: '0.5rem',
                  }}
                >
                  Invoice to
                </p>
                <p style={{ color: '#14290A', fontWeight: '600' }}>
                  {props.invoiceDetails.customerName}
                </p>
              </div>

              <div>
                <p
                  style={{
                    color: '#98A2B3',
                    fontWeight: '500',
                    fontSize: '15px',
                    paddingBottom: '0.5rem',
                  }}
                >
                  Invoice No.
                </p>
                <p style={{ color: '#14290A', fontWeight: '600' }}>
                  {props.invoiceDetails.number}
                </p>
              </div>

              <div>
                <p
                  style={{
                    color: '#98A2B3',
                    fontWeight: '500',
                    fontSize: '15px',
                    paddingBottom: '0.5rem',
                  }}
                >
                  Date
                </p>
                <p style={{ color: '#14290A', fontWeight: '600' }}>
                  {formattedDate}
                </p>
              </div>
            </div>

            <div className="invoiceTable">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  background: '#DFFFD0',
                  padding: '15px 30px',
                  width: '100%',
                }}
              >
                <p style={{ width: '100%', fontWeight: '600' }}>Item</p>
                <p
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontWeight: '600',
                  }}
                >
                  Price
                </p>
                <p
                  style={{
                    width: '100%',
                    textAlign: 'end',
                    fontWeight: '600',
                  }}
                >
                  Total amount
                </p>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  border: '1px solid #DFFFD0',
                  padding: '15px 30px',
                  width: '100%',
                }}
              >
                <p style={{ width: '100%' }}>{props.vType} Visa Form</p>
                <p style={{ width: '100%', textAlign: 'center' }}>
                  {props.currency === 'USD'
                    ? `$${props.amount}`
                    : `₦${props.amount}`}
                </p>
                <p style={{ width: '100%', textAlign: 'end' }}>
                  {' '}
                  {props.currency === 'USD'
                    ? `$${props.amount}`
                    : `₦${props.amount}`}{' '}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Your component content */}

        <div
          style={{
            padding: '20px 40px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '6px',
            }}
          >
            <p
              style={{ color: '#14290A', fontWeight: '600', fontSize: '15px' }}
            >
              Payment Info.
            </p>
            <p
              style={{ color: '#98A2B3', fontWeight: '600', fontSize: '15px' }}
            >
              Account No.
              <span
                style={{
                  color: '#14290A',
                  fontWeight: '600',
                  fontSize: '15px',
                }}
              >
                {props.currency === 'USD'
                  ? `${props.usdAccountNum}`
                  : `${props.ngnAccountNum}`}
              </span>
            </p>
            {props.currency === 'USD' ? (
              <p
                style={{
                  color: '#98A2B3',
                  fontWeight: '600',
                  fontSize: '15px',
                }}
              >
                Routing No.{' '}
                <span
                  style={{
                    color: '#14290A',
                    fontWeight: '600',
                    fontSize: '15px',
                  }}
                >
                  {props.routingNum}
                </span>
              </p>
            ) : (
              <></>
            )}

            <p
              style={{ color: '#98A2B3', fontWeight: '600', fontSize: '15px' }}
            >
              A/C Name{' '}
              <span
                style={{
                  color: '#14290A',
                  fontWeight: '600',
                  fontSize: '15px',
                }}
              >
                {props.accountName
                  ? props.accountName
                  : props.invoiceDetails.accountName}
              </span>
            </p>
            <p
              style={{ color: '#98A2B3', fontWeight: '600', fontSize: '15px' }}
            >
              Bank Name{' '}
              <span
                style={{
                  color: '#14290A',
                  fontWeight: '600',
                  fontSize: '15px',
                }}
              >
                {props.bankname
                  ? props.bankname
                  : props.invoiceDetails.bankName}
              </span>
            </p>
            {/* <p>
              Bank details <span>1234567890</span>
            </p> */}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '6px',
            }}
          >
            <p
              style={{ color: '#14290A', fontWeight: '600', fontSize: '15px' }}
            >
              Payment Details
            </p>
            <p
              style={{ color: '#98A2B3', fontWeight: '600', fontSize: '15px' }}
            >
              Sub total{' '}
              <span
                style={{
                  color: '#14290A',
                  fontWeight: '600',
                  fontSize: '15px',
                }}
              >
                {props.currency === 'USD'
                  ? `$ ${props.amount}`
                  : `N ${props.amount}`}{' '}
              </span>
            </p>
            <p
              style={{ color: '#98A2B3', fontWeight: '600', fontSize: '15px' }}
            >
              Discount{' '}
              <span
                style={{
                  color: '#14290A',
                  fontWeight: '600',
                  fontSize: '15px',
                }}
              >
                ---
              </span>
            </p>
            <p
              style={{ color: '#98A2B3', fontWeight: '600', fontSize: '15px' }}
            >
              Tax{' '}
              <span
                style={{
                  color: '#14290A',
                  fontWeight: '600',
                  fontSize: '15px',
                }}
              >
                ---
              </span>
            </p>
            <p
              style={{ color: '#98A2B3', fontWeight: '600', fontSize: '15px' }}
            >
              Total amount{' '}
              <span
                style={{
                  color: '#14290A',
                  fontWeight: '600',
                  fontSize: '15px',
                }}
              >
                {props.currency === 'USD'
                  ? `$ ${props.amount}`
                  : `N ${props.amount}`}
              </span>
            </p>
          </div>
        </div>
      </>
    </div>
  );
});
