import React, { useEffect, useState } from 'react';
import '../../../containers/Admin/Admin.css';
import proceduresBtn from 'assets/TourGuide/Pathways/proceduresBtn.svg';
import proceduresStep from 'assets/TourGuide/Pathways/proceduresStep.svg';
import { navigate } from '@reach/router';
import membership from 'assets/TourGuide/Pathways/membership.svg';
import tooltip from 'assets/TourGuide/Tooltip.svg';
import '../Intro/Intro.scss';
import { LiaTimesSolid } from 'react-icons/lia';
import pathwayOpt from 'assets/TourGuide/Pathways/membershipPaymentopt.svg';
import walletType from 'assets/TourGuide/Pathways/membershipwalletType.svg';
import trans from 'assets/TourGuide/Pathways/membershipTransPin.svg';
import { closeCardTourModal } from 'appRedux/actions/Tourguide';
import { useDispatch } from 'react-redux';

export const PathaysProcedures = props => {
  const [step, setStep] = useState(0);

  const dispatch = useDispatch();

  const handleCloseTourGuide = () => {
    dispatch(closeCardTourModal());
  };

  switch (step) {
    case 0:
      return (
        <div style={{ height: '100%', width: '100%' }}>
          <div style={{ display: 'flex' }}>
            {/* <div style={{ width: '20%', height: '100%' }}></div> */}

            <div className="  w-full h-full">
              <div className="myWalletTour" style={{ marginTop: '4rem' }}>
                <img
                  src={proceduresBtn}
                  alt=""
                  style={{ width: '25rem', margin: '3rem auto 0px auto' }}
                />
                {/* <div className="tourWalletBg"></div> */}
                <div className="PathwayTourArticleCContainer">
                  <img
                    className="PathwayTourArticleCContainer__tooltipUp"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Join a pathway
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Click on the toggle to the procedure option to view
                            the list of procedures for that pathway.
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            handleCloseTourGuide();
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 2} of 7`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>

                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(1);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    case 1:
      return (
        <div style={{ height: '100%', width: '100%' }}>
          <div style={{ display: 'flex' }}>
            {/* <div style={{ width: '20%', height: '100%' }}></div> */}

            <div className="  w-full h-full">
              <div className="myWalletTour" style={{ marginTop: '2rem' }}>
                <img
                  src={proceduresStep}
                  alt=""
                  style={{ width: '25rem', margin: '3rem auto 0px auto' }}
                />
                {/* <div className="tourWalletBg"></div> */}
                <div className="PathwayTourArticleCContainer">
                  <img
                    className="PathwayTourArticleCContainer__tooltipUp"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Join a pathway
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Click on the join buttton to join that pathway
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            handleCloseTourGuide();
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 2} of 7`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>

                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(2);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 2:
      return (
        <div style={{ height: '100%', width: '100%' }}>
          <div style={{ display: 'flex' }}>
            {/* <div style={{ width: '20%', height: '100%' }}></div> */}

            <div className="  w-full h-full">
              <div className="myWalletTour">
                <img
                  src={membership}
                  alt=""
                  style={{ width: '20rem', margin: '3rem auto 0px auto' }}
                />
                {/* <div className="tourWalletBg"></div> */}
                <div className="PathwayTourArticleCContainer">
                  <img
                    className="PathwayTourArticleCContainer__tooltipUp"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Join a pathway
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Select a membership plan to subscribe to{' '}
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            handleCloseTourGuide();
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 2} of 7`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>

                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(3);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 3:
      return (
        <div style={{ height: '100%', width: '100%' }}>
          <div style={{ display: 'flex' }}>
            {/* <div style={{ width: '20%', height: '100%' }}></div> */}

            <div className="  w-full h-full">
              <div className="myWalletTour">
                <img
                  src={pathwayOpt}
                  alt=""
                  style={{ width: '20rem', margin: '3rem auto 0px auto' }}
                />
                {/* <div className="tourWalletBg"></div> */}
                <div className="PathwayTourArticleCContainer">
                  <img
                    className="PathwayTourArticleCContainer__tooltipUp"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Join a pathway
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Select your preferred means of making payment from
                            the options provided. Vesti Wallet is recommended
                            for faster processing{' '}
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            handleCloseTourGuide();
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 2} of 7`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>

                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(4);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 4:
      return (
        <div style={{ height: '100%', width: '100%' }}>
          <div style={{ display: 'flex' }}>
            {/* <div style={{ width: '20%', height: '100%' }}></div> */}

            <div className="  w-full h-full">
              <div className="myWalletTour">
                <img
                  src={walletType}
                  alt=""
                  style={{ width: '20rem', margin: '3rem auto 0px auto' }}
                />
                {/* <div className="tourWalletBg"></div> */}
                <div className="PathwayTourArticleCContainer">
                  <img
                    className="PathwayTourArticleCContainer__tooltipUp"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Join a pathway
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Select which wallet/currency you want to pay with
                            and click on the continue button .
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            handleCloseTourGuide();
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 2} of 7`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>

                          <li className="PathwayTourArticleCContainer__inactive"></li>
                        </ul>

                        <div
                          onClick={() => {
                            setStep(5);
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Next
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 5:
      return (
        <div style={{ height: '100%', width: '100%' }}>
          <div style={{ display: 'flex' }}>
            {/* <div style={{ width: '20%', height: '100%' }}></div> */}

            <div className="  w-full h-full">
              <div className="myWalletTour">
                <img
                  src={trans}
                  alt=""
                  style={{ width: '20rem', margin: '3rem auto 0px auto' }}
                />
                {/* <div className="tourWalletBg"></div> */}
                <div className="PathwayTourArticleCContainer">
                  <img
                    className="PathwayTourArticleCContainer__tooltipUp"
                    src={tooltip}
                    alt=""
                  />

                  <div className="TourArticle">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: '22px', fontWeight: '500' }}>
                            Join a pathway
                          </h1>
                          <p style={{ color: '#2B5219', fontSize: '14px' }}>
                            Input your 4-Digit transaction PIN to subscribe and
                            confirm your payment.
                          </p>
                        </div>
                        <p
                          style={{
                            background: '#E2E2EA',
                            padding: '6px',
                            borderRadius: '6px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            handleCloseTourGuide();
                          }}
                        >
                          <LiaTimesSolid />
                        </p>
                      </div>

                      <div
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <h1>Video</h1>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ color: '#67a948' }}>
                          {`${step + 2} of 7`}{' '}
                        </p>

                        <ul className="PathwayTourArticleCContainer__tracker">
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__inactive"></li>
                          <li className="PathwayTourArticleCContainer__active"></li>
                        </ul>

                        <div
                          onClick={() => {
                            navigate('/pathways');
                          }}
                          className="PathwayTourArticleCContainer__next"
                        >
                          Back
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    default:
  }
};
