import React from "react"
import "./singleuser.scss"


export const Singleuser = (props) => {
    return (
        <div className="singleuser" onClick={props.onClick}>
            <div className="singleuser__inner">
                <div className="singleuser__left">
                    <img src={props.image} alt="user profile"/>
                </div>
                <div className="singleuser__right">
                    <p>{props.firstname} {props.lastname}</p>
                    <p>Proceed to send cash <i className="fas fa-arrow-right"></i></p>
                </div>
            </div>
        </div>
    )
}