import React from 'react';
import memberIcon from '../../../../assets/membership/memberIcon.svg';
import detailIcon from '../../../../assets/membership/seeDetails.svg';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const LoadingPlan = props => {
  return (
    <div>
      {' '}
      <div className="Profilemembership__plan">
        <div className="Profilemembership__top">
          <div className="Profilemembership__top__col">
            {}
            <p className="Profilemembership__top__title">
              <Skeleton width={100} duration={1} />{' '}
              <span>
                <Skeleton width={100} duration={1} />
              </span>
            </p>
            <p
              className="Profilemembership__top__planDetails"
              onClick={() => {
                props.setOpnSubFeature(true);
              }}
            >
              <Skeleton width={100} duration={1} />{' '}
              <img src={detailIcon} alt="" />
            </p>
          </div>
          <p
            className="Profilemembership__subscribe"
            onClick={() => {
              props.setOpnSub(true);
            }}
          >
            <Skeleton width={100} duration={1} />
          </p>
        </div>

        <div className="Profilemembership__bottom">
          <img src={memberIcon} alt="" />

          <article>
            <p className="Profilemembership__bottom__title">
              <Skeleton width={300} height={20} duration={1} />
            </p>
            <p className="Profilemembership__bottom__subtitle">
              <Skeleton width={500} height={30} duration={1} />
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default LoadingPlan;
