import React from 'react';
import { currencies } from 'helpers/data';
import { useCurrency } from 'helpers/hooks';

export const Selectcurrency = props => {
  const { currency, handleCurrency, handleActive } = useCurrency('ngn');
  const ref = React.useRef(null);

  React.useEffect(() => {
    handleCurrency(currencies[0].currency);
    // document.addEventListener('click', ()=> {
    //     handleActive(false)
    // }, true);
    // return () => {
    //     document.removeEventListener('click',  ()=> {
    //         handleActive(false)
    //     }, true);
    // };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="relative w-[100px] z-10" ref={ref}>
      {/* { currency.value} */}
      <div
        className="rounded-[30px] bg-white flex items-center flex-row gap-[20px] px-[10px] py-[8px] border-solid border-1 border-vesti-900"
        onClick={() => handleActive(!currency.active)}
      >
        <span className="w-fit flex flex-row gap-[5px] items-center">
          <img
            src={
              currencies.filter(item =>
                currency.value === ''
                  ? item.currency === currency.primary
                  : item.currency === currency.value,
              )[0]?.icon
            }
            alt="currency"
            className="relative w-[20px]"
          />
          <p className="font-sans text-[1em] font-bold text-vesti-902 uppercase">
            {' '}
            {
              currencies.filter(item =>
                currency.value === ''
                  ? item.currency === currency.primary
                  : item.currency === currency.value,
              )[0]?.currency
            }{' '}
          </p>
        </span>
        <i
          className={`fas ${
            currency.active ? 'fa-chevron-up' : 'fa-chevron-down'
          } text-[1em] font-600 text-vesti-900`}
        ></i>
      </div>
      {currency.active && (
        <div className="z-10 flex flex-col gap-[5px] absolute top-[50px] rounded-[15px] w-[120px] h-[fit-content] origin-center bg-white border-solid border-1 border-vesti-900">
          {currencies
            .filter(item =>
              currency.value === ''
                ? item.currency !== currency.primary
                : item.currency !== currency.value,
            )
            .map((item, index) => (
              <span
                className="w-fit flex flex-row gap-[5px] items-center py-[5px] px-[10px]"
                onClick={() => handleCurrency(item.currency)}
              >
                <img
                  src={item.icon}
                  alt="currency"
                  className="relative w-[20px]"
                />
                <p className="font-sans text-[1em] font-bold text-vesti-902 uppercase">
                  {' '}
                  {item.currency}{' '}
                </p>
              </span>
            ))}
        </div>
      )}
    </div>
  );
};
