import React, { useState } from 'react';
import { connect } from 'react-redux';
import { stripeTreasury } from 'appRedux/actions/transactions';
import VestiLogo from 'assets/Vesti_logo.svg';
// import VestiLogo from 'assets/xmasLogo/xmasLogo.svg';

import '../Admin.css';
import USDPartnerIframeWidget from 'components/usdPartnership';
// import { Partnership } from 'components/partnership';
import airplane from 'assets/flight/airplane.png';
import { BiArrowBack } from 'react-icons/bi';
// import { Simplemodal } from 'components/common/simplifiedmodal';
// import { Link } from '@material-ui/core';
import { navigate } from '@reach/router';

export const USDBookflight = () => {
  const [showModal, setShowModal] = useState(false);

  const checkPreviousRoute = () => {
    const previousRoute = document.referrer;

    // Check if the previous route is the sign-up route
    if (previousRoute.includes('/bank')) {
      // User came from the sign-up route

      return true;
    } else {
      // console.log(previousRoute);
      // User came from a different route or directly (e.g., a new tab)
      return false;
    }
  };

  // Example of usage
  const cameFromBankRoute = checkPreviousRoute();

  const NotificationModal = () => {
    return (
      <div className="mainsuccess__modalcontent">
        <p>Are you sure you want to go back? your progress will be lost</p>

        <div className="mainsuccess__modalArticle">
          {cameFromBankRoute ? (
            <div
              onClick={() => {
                navigate('/bank');
              }}
              className=" mainsuccess__modalRedBtm"
            >
              <span>Yes</span>
            </div>
          ) : (
            <a
              href="http://wevesti.com/flight"
              className=" mainsuccess__modalRedBtm"
            >
              <span>Yes</span>
            </a>
          )}

          <p
            onClick={() => {
              setShowModal(false);
            }}
            className="mainsuccess__modalBtm"
          >
            Cancel
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* <Simplemodal onClick={() => setShowModal(false)} open={showModal}>
        <div className="">
          You're about to leave this page
          <div>
            <a href="http://wevesti.com/flight" className="">
              <span>Proceed</span>
            </a>

            <p onClick={() => setShowModal(false)} className="">
              <span>Cancel</span>
            </p>
          </div>
        </div>
      </Simplemodal> */}
      <section className="mainsuccess ">
        <div className="submain">
          <p
            onClick={() => {
              setShowModal(true);
            }}
            className="mainsuccess__goback"
          >
            <span>
              <BiArrowBack />
            </span>
          </p>

          {showModal ? (
            <div className="mainsuccess__modal">
              <NotificationModal />
            </div>
          ) : (
            <p></p>
          )}

          {/* <a href="http://wevesti.com/flight" className="mainsuccess__goback">
          <span>
            <BiArrowBack />
          </span>
        </a> */}

          {/* <div className="mainsuccess__side">
        {' '}
        <img className="mainsuccess__logo" src={vwhite} alt="Vesti logo" />
        <div className="mainsuccess__article"></div>
      </div> */}
          <div className="mainsucess__gradientBg"></div>
          <div class="wrapper ">
            <div>
              <span class="dot"></span>
              <img className="idot" src={airplane} alt="Vesti logo" />
              {/* <p className="pdot">vesti</p> */}
            </div>
            <div>
              <span class="dot"></span>
            </div>
            <div>
              <span class="dot"></span>
            </div>
            <div>
              <span class="dot"></span>
            </div>
            <div>
              <span class="dot"></span>
            </div>
            <div>
              <span class="dot"></span>
            </div>
            <div>
              <span class="dot"></span>

              {/* <p className="pdot">vesti</p> */}
            </div>
            <div>
              <span class="dot"></span>
            </div>
            <div>
              <span class="dot"></span>
            </div>
            <div>
              <span class="dot"></span>
            </div>
            <div>
              <span class="dot"></span>
              <img className="idot" src={airplane} alt="Vesti logo" />{' '}
            </div>
            <div>
              <span class="dot"></span>
            </div>
            <div>
              <span class="dot"></span>
            </div>
            <div>
              <span class="dot"></span>
            </div>
            <div>
              <span class="dot"></span>
              <img className="idot" src={airplane} alt="Vesti logo" />
              {/* <p className="pdot">vesti</p> */}
            </div>
          </div>
          <div className="mainsuccess__main">
            {/* <img className="mainsuccess__logo" src={VestiLogo} alt="Vesti logo" />{' '} */}
            <div className="mainsuccess__box">
              <img className="plane" src={airplane} alt="Flight" />
              <img
                className="mainsuccess__logo"
                src={VestiLogo}
                alt="Vesti logo"
              />
              <div className="mainsuccess__big">
                <USDPartnerIframeWidget />
              </div>
            </div>
          </div>
          {/* <div className="mainsuccess__box">
        <div className="mainsuccess__big">
          <Partnership />
        </div>
      </div> */}
          {/* <img className="mainsuccess__logo" src={VestiLogo} alt="Vesti logo" /> */}
        </div>
      </section>
    </>
  );
};

const mapStateToProps = ({ transactions }) => {
  const { message, loading } = transactions;

  return {
    message,
    loading,
  };
};
const mapDispatchToProps = {
  stripeTreasury,
};

export default connect(mapStateToProps, mapDispatchToProps)(USDBookflight);
