import { PROOF_OF_FUNDS } from "appRedux/constants";


const initialState = {
 chosenAccount: '',
 newBankToOpen: '',
 amount: '',
 loanDuration: '',
 existingBankNameToDeposit: '',
 existingAccountNumberToDeposit: '',


  completedBankAccountOpeningForm: null,
 validIdentitydocument: null,
 utilityBill: null,
 visaProcessingDocumentOrAdmissonLetter: null,
};






export default (state = initialState, action) => {
   switch (action.type) {
     case PROOF_OF_FUNDS:
       return {
           ...state,
           chosenAccount: action.payload.chosenAccount,
           newBankToOpen: action.payload.newBankToOpen,
           amount: action.payload.amount,
           loanDuration: action.payload.loanDuration,
           existingBankNameToDeposit: action.payload.existingBankNameToDeposit,
           existingAccountNumberToDeposit: action.payload.existingAccountNumberToDeposit,


           completedBankAccountOpeningForm: action.payload.completedBankAccountOpeningForm,
           validIdentitydocument: action.payload.validIdentitydocument,
           utilityBill: action.payload.utilityBill,
           visaProcessingDocumentOrAdmissonLetter: action.payload.visaProcessingDocumentOrAdmissonLetter,
         };
     default:
       return state;
   }
 };

















