import React from 'react';
import './Ovbenefits.scss';
import flash from '../../../assets/flash.svg';
// import handslock from '../../../assets/handslock.svg';
import snap from '../../../assets/snap.svg';
import justice from '../../../assets/justice.svg';

const Ovbenefits = () => {
  return (
    <div>
      <div className="ovbenefits-container">
        <div className="ovbenefits-inner">
          <div className="ovbenefits-inner top">
            <p>
              Benefits of <span>O-1 Visa</span>
            </p>
            <p>
              Unlock new possibilities with an O-1 visa for extraordinary
              individuals.
            </p>
          </div>
          <div className=" ovbenefits-inner bottom">
            <div className="card-container">
              <SingleCard
                image={snap}
                title="Recognition"
                text="Gain recognition for your extraordinary abilities and stand out in the US workforce with an O-1 visa."
              />

              <SingleCard
                image={flash}
                title="Flexibility"
                text="Enjoy the flexibility to work and live in the US for an extended period of time with an O-1 visa."
              />

              <SingleCard
                image={justice}
                title="Opportunities "
                text="Expand your career and personal opportunities with the ability to live and work in the US on an O-1 visa."
              />

              {/* <SingleCard
                image={handslock}
                title="Secure"
                text="We use state-of-the-art data encryption when handling financial details."
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ovbenefits;

const SingleCard = props => {
  return (
    <>
      <div className={`card `}>
        <div className="card-content">
          <img className="img-fluid" src={props.image} alt="" />
          <div className="card-body">
            <h5 className="card-title">{props.title}</h5>
            <p className="card-text">{props.text}</p>
          </div>
        </div>
      </div>
    </>
  );
};
