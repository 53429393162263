import {
  OPEN_ABOUT,
  OPEN_FEEDS,
  OPEN_MEMBERS,
  OPEN_EVENTS,
  FETCH_ALL_GROUPS,
  SET_ACTIVE_CHAT,
  SEND_MESSAGE,
  RECIEVE_MESSAGE,
} from 'appRedux/constants';

const INIT_STATE = {
  about: true,
  feedss: false,
  members: false,
  events: false,
  groups: [],
  activeChat: null,
  messages: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_ABOUT: {
      return { feedss: false, members: false, events: false, about: true };
    }
    case OPEN_FEEDS: {
      return { feedss: true, members: false, events: false, about: false };
    }

    case OPEN_MEMBERS: {
      return { feedss: false, members: true, events: false, about: false };
    }

    case OPEN_EVENTS: {
      return { feedss: false, members: false, events: true, about: false };
    }
    case FETCH_ALL_GROUPS: {
      return { ...state, groups: action.payload };
    }

    case SET_ACTIVE_CHAT: {
      return { ...state, activeChat: action.payload,}
    }
    case SEND_MESSAGE: {
      const { userId, message } = action.payload;
      return { ...state, messages: { ...state.messages, [userId]: [...(state.messages[userId] || []), message], }, };
    }
    case RECIEVE_MESSAGE: {
      const { userId: recvUserId, message: recvMessage } = action.payload;
      return { ...state, messages: { ...state.messages, [recvUserId]: [...(state.messages[recvUserId] || []), recvMessage], }, };
    }

    default:
      return state;
  }
};
