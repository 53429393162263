import {
  OPEN_CARD_TOUR_MODAL,
  CLOSE_CARD_TOUR_MODAL,
} from 'appRedux/constants';

export const openCardTourModal = () => ({
  type: OPEN_CARD_TOUR_MODAL,
});

export const closeCardTourModal = () => ({
  type: CLOSE_CARD_TOUR_MODAL,
});
