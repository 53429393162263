import React, { useState, useEffect } from 'react';
import './eligibility.scss';
import eligibleIcon from 'assets/viasForm/EligibilityTest/eligibilityProfile.svg';
import left from 'assets/viasForm/EligibilityTest/Left.svg';
import right from 'assets/viasForm/EligibilityTest/Right.svg';
import {
  SimpleCancelmodal,
  LargemodalCustomCancel,
} from 'components/common/simplifiedmodal';
import { TestFlow } from './testFlow';
import { fetchAllCountries } from 'appRedux/actions/Common';
import { LiaTimesSolid } from 'react-icons/lia';

export const EligibilityTest = () => {
  const [openTestModal, setOpenTestModal] = useState(false);
  const urlparams = new URLSearchParams(window.location.search);
  const paymentIntent = urlparams?.get('payment_intent');
  useEffect(() => {
    fetchAllCountries();
  }, []);

  useEffect(() => {
    if (paymentIntent) {
      setOpenTestModal(true);
    }
  }, [paymentIntent]);
  return (
    <>
      <LargemodalCustomCancel
        open={openTestModal}
        onClick={() => setOpenTestModal(true)}
      >
        <div className="textcontnew">
          <LiaTimesSolid
            onClick={() => setOpenTestModal(false)}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              fontSize: '24px',
              cursor: 'pointer',
            }}
          />
          <TestFlow
            setOpenTestModal={setOpenTestModal}
            paymentIntent={paymentIntent}
          />
        </div>
      </LargemodalCustomCancel>
      {/* New */}
      <div className="EligibilitySection">
        <img src={left} alt="" style={{ position: 'absolute', left: '0px' }} />
        <img
          src={right}
          alt=""
          style={{ position: 'absolute', right: '0px' }}
        />

        <div className="EligibilitySection__firstcont">
          <img
            src={eligibleIcon}
            alt=""
            style={{ width: '50px', height: '50px' }}
          />

          <div>
            <p style={{ fontSize: '20px', fontWeight: 'bold', color: 'white' }}>
              Take an eligibility test
            </p>
            <p style={{ fontSize: '12px', color: 'white', fontWeight: '500' }}>
              Take a test to check how prepared you are to start your petition
              process.
            </p>{' '}
          </div>
        </div>

        <p
          style={{
            color: '#67A948',
            background: '#F7FFF3',
            fontSize: '13px',
            padding: '10px 24px',
            borderRadius: '8px',
            zIndex: '10',
            cursor: 'pointer',
            fontWeight: '500',
          }}
          onClick={() => {
            setOpenTestModal(true);
          }}
        >
          Take the Test
        </p>
      </div>
    </>
  );
};
