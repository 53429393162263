import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Inputfloat from 'components/common/inputs/inputfloat';
import {
  CustomizedSingleselect,
  Singleselect,
} from 'components/common/inputs/singleselect';
import './eligibility.scss';
import { Platformbutton } from 'components/common/button/button';
import {
  RadioOption,
  NewVerySmallRadioOption,
} from 'components/common/radiobutton/radiobutton';
import successPng from 'assets/physicalCard/newsuccess.png';
import NotEligIcon from 'assets/chats/notElig.svg';
import errorsvg from 'assets/newerror.svg';
import { fetchAllCountries } from 'appRedux/actions/Common';
import { useSelector } from 'react-redux';
import { getScore } from './score';
import api from 'appRedux/api';
import { fetchUserData } from 'appRedux/actions/profile';
import { IndexClarityBookings } from '../ClaritySession';
import pathwayconfig from 'config.js';

export const TestFlow = props => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const countries = useSelector(state => state.common?.countries);
  const userD = useSelector(state => state.auth?.userData);

  const [score, setScore] = useState(0);

  var gender = [{ opt: 'Male' }, { opt: 'Female' }];
  var expertise = [
    { opt: 'Arts' },
    { opt: 'Sciences, Education, Business or Athletics' },
    { opt: 'Motion pictures or Television' },
    { opt: 'Health and Social Services' },
  ];

  var visaType = [
    { opt: '0-1' },
    { opt: 'EB-1' },
    { opt: 'EB-2 NIW' },
    { opt: 'Canadian Care Giver' },
    // { opt: 'H1B' },
  ];

  const [state, setState] = useState({
    fullName: `${userD?.firstName} ${userD?.lastName}`,
    email: userD?.email,
    gender: '',
    expertise: '',
    visatype: '',
    country: '',
  });

  const [test, setTest] = useState({
    ExpertiseofNationalInterest: '',
    majorprizes: '',
    authoredanybooks: '',
    previouslyservedasajudge: '',
    advanceddegree: '',
    referencelettersfromcriticalpeople: '',
  });

  const [canadiancareTest, setCanadiancareTest] = useState({
    certifications: '',
    medicalProfessional: '',
    twoYearsPlusExperience: '',
    languageProficiency: '',
    criminalRecord: '',
  });

  const setQ1option = value => {
    setTest({ ...test, ExpertiseofNationalInterest: value });
  };

  const setQ2option = value => {
    setTest({ ...test, majorprizes: value });
  };

  const setQ3option = value => {
    setTest({ ...test, authoredanybooks: value });
  };

  const setQ4option = value => {
    setTest({ ...test, previouslyservedasajudge: value });
  };
  const setQ5option = value => {
    setTest({ ...test, advanceddegree: value });
  };
  const setQ6option = value => {
    setTest({ ...test, referencelettersfromcriticalpeople: value });
  };

  //  Canadian Care Giver

  const setQ7option = value => {
    setCanadiancareTest({ ...canadiancareTest, certifications: value });
  };

  const setQ8option = value => {
    setCanadiancareTest({ ...canadiancareTest, medicalProfessional: value });
  };

  const setQ9option = value => {
    setCanadiancareTest({ ...canadiancareTest, twoYearsPlusExperience: value });
  };

  const setQ10option = value => {
    setCanadiancareTest({ ...canadiancareTest, languageProficiency: value });
  };

  const setQ11option = value => {
    setCanadiancareTest({ ...canadiancareTest, criminalRecord: value });
  };

  const yesScoreCount = Object.values(test).filter(value => value === 'YES')
    .length;

  const yesScoreCountCareGiver = Object.values(canadiancareTest).filter(
    value => value === 'YES',
  ).length;

  var handleSelect = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const visaType0_1 = userD?.visaEligibility?.filter(
    visa => visa.visaType === '0-1',
  );
  const visaTypeEB_1 = userD?.visaEligibility?.filter(
    visa => visa.visaType === 'EB-1',
  );
  const visaTypeEB_2 = userD?.visaEligibility?.filter(
    visa => visa.visaType === 'EB-2 NIW',
  );
  const visaTypeCanadian_carevisa = userD?.visaEligibility?.filter(
    visa => visa.visaType === 'Canadian Care Giver',
  );
  // console.log(props.visaType ? props.visaType : state.visatype);

  const handleSubmit = async (miaiVideoAccess, preRecordedAccess) => {
    const Eligibilityscore = getScore(yesScoreCount);
    const EligibilityScoreForCareGiver = getScore(
      canadiancareTest.criminalRecord == 'YES'
        ? yesScoreCountCareGiver - 1
        : yesScoreCountCareGiver + 1,
    );

    let payload;

    if (props.visaType == '0-1' || state.visatype == '0-1') {
      payload = {
        score: Eligibilityscore,
        visaType: props.visaType ? props.visaType : state.visatype,
        email: userD?.email,
        fullName: `${userD?.firstName} ${userD?.lastName}`,
        hasClarityMiai: visaType0_1 ? visaType0_1[0]?.hasClarityMiai : false,
        hasClarityPrerecorded: visaType0_1
          ? visaType0_1[0]?.hasClarityPrerecorded
          : false,
      };
    }

    if (props.visaType == 'EB-1' || state.visatype == 'EB-1') {
      payload = {
        score: Eligibilityscore,
        visaType: props.visaType ? props.visaType : state.visatype,
        email: userD?.email,
        fullName: `${userD?.firstName} ${userD?.lastName}`,
        hasClarityMiai: visaType0_1 ? visaTypeEB_1[0]?.hasClarityMiai : false,
        hasClarityPrerecorded: visaType0_1
          ? visaTypeEB_1[0]?.hasClarityPrerecorded
          : false,
      };
    }

    if (props.visaType == 'EB-2 NIW' || state.visatype == 'EB-2 NIW') {
      payload = {
        score: Eligibilityscore,
        visaType: props.visaType ? props.visaType : state.visatype,
        email: userD?.email,
        fullName: `${userD?.firstName} ${userD?.lastName}`,
        hasClarityMiai: visaType0_1 ? visaTypeEB_2[0]?.hasClarityMiai : false,
        hasClarityPrerecorded: visaType0_1
          ? visaTypeEB_2[0]?.hasClarityPrerecorded
          : false,
      };
    }

    if (
      props.visaType == 'Canadian Care Giver' ||
      state.visatype == 'Canadian Care Giver'
    ) {
      payload = {
        score: EligibilityScoreForCareGiver,
        visaType: props.visaType ? props.visaType : state.visatype,
        email: userD?.email,
        fullName: `${userD?.firstName} ${userD?.lastName}`,
        hasClarityMiai: false,
        hasClarityPrerecorded: false,
      };
    }

    const Fullpayload = {
      score: Eligibilityscore,
      visaType: props.visaType ? props.visaType : state.visatype,
      email: userD?.email,
      fullName: `${userD?.firstName} ${userD?.lastName}`,
      hasClarityMiai: miaiVideoAccess == 'Yes' ? true : false,
      hasClarityPrerecorded: preRecordedAccess == 'Yes' ? true : false,
    };

    const url = `${pathwayconfig.baseURL}/pathway/visa-eligibility-test`;

    try {
      const res = await api.post(
        url,
        miaiVideoAccess || preRecordedAccess ? Fullpayload : payload,
      );
      if (
        !miaiVideoAccess &&
        !preRecordedAccess &&
        state.visatype == 'Canadian Care Giver'
      ) {
        yesScoreCountCareGiver > 2 ? setStep(9) : setStep(5);
      } else if (
        !miaiVideoAccess &&
        !preRecordedAccess &&
        state.visatype !== 'Canadian Care Giver'
      ) {
        yesScoreCount > 2 ? setStep(4) : setStep(5);
      }

      dispatch(fetchUserData());
    } catch (error) {
      //  setStep(2);
      dispatch(fetchUserData());
      console.log(error);
    }
  };

  switch (step) {
    case 1:
      return (
        <div className="textcontnew">
          <p style={{ color: '#67A948', fontSize: '13px', fontWeight: '600' }}>
            Step {step} of 3
          </p>
          <p
            style={{
              color: '#14290A',
              fontSize: '22px',
              fontWeight: '600',
              marginTop: '20px',
              marginBottom: '10px',
            }}
          >
            Personal Info
          </p>
          <p style={{ color: '#2B5219', fontSize: '12px', fontWeight: '600' }}>
            We need to collect this info to personalize your quiz for you.
          </p>

          <div className="testSectionOne ">
            <Inputfloat
              type="text"
              name="fullName"
              label="Full Name"
              value={`${userD?.firstName} ${userD?.lastName}`}
              disabled={true}
              placeholder="Enter your Full Name"
              onChange={e => setState({ ...state, fullName: e.target.value })}

              //   onChange={setInput}
            />
            <Inputfloat
              type="text"
              name="email"
              label="Email"
              value={userD?.email}
              disabled={true}
              placeholder="Enter your Email"
              onChange={e => setState({ ...state, email: e.target.value })}
              //   onChange={setInput}
            />

            <Singleselect
              value={state.gender}
              options={gender.map((op, index) => ({
                key: index,
                label: op.opt,
                value: op.opt,
              }))}
              placeholder={`${state.gender ? state.gender : 'Select Gender'}`}
              onChange={selectedValue => {
                handleSelect('gender', selectedValue.value);
              }}
              name="gender"
            />

            <Singleselect
              value={state.expertise}
              options={expertise.map((op, index) => ({
                key: index,
                label: op.opt,
                value: op.opt,
              }))}
              placeholder={`${
                state.expertise ? state.expertise : 'Select Expertise'
              }`}
              onChange={selectedValue => {
                handleSelect('expertise', selectedValue.value);
              }}
              name="expertise"
            />

            <Singleselect
              value={
                props.visaType
                  ? `${props.visaType == 'Canadian Care Giver' ? '' : 'U.S'} ${
                      props.visaType
                    } Visa`
                  : state.visatype
              }
              options={
                props.visaType
                  ? ''
                  : visaType.map((op, index) => ({
                      key: index,
                      label: `${op.opt == 'Canadian Care Giver' ? '' : 'U.S'} ${
                        op.opt
                      } Visa`,
                      value: op.opt,
                    }))
              }
              placeholder={`${
                state.visatype
                  ? `${state.visatype == 'Canadian Care Giver' ? '' : 'U.S'} ${
                      state.visatype
                    } Visa`
                  : props.visaType
                  ? `${props.visaType == 'Canadian Care Giver' ? '' : 'U.S'} ${
                      props.visaType
                    } Visa`
                  : 'Select VISA Type'
              }`}
              onChange={selectedValue => {
                handleSelect('visatype', selectedValue.value);
              }}
              name="visatype"
              disabled={props.visaType ? true : false}
            />

            <Singleselect
              value={state.country}
              options={countries}
              placeholder={`${
                state.country ? state.country : 'Select Country of Residence'
              }`}
              onChange={selectedValue => {
                handleSelect('country', selectedValue.label);
              }}
              name="country"
            />
          </div>

          <div className="flexButton" style={{ width: '100%' }}>
            <div style={{ width: '100%' }}>
              {' '}
              <Platformbutton
                type="secondary"
                name="Cancel"
                click={() => {
                  props.setOpenTestModal(false);
                }}
                classname="fas fa-arrow-right"
              />
            </div>
            <div style={{ width: '100%' }}>
              <Platformbutton
                type="normal"
                name="Continue"
                click={() => {
                  state.visatype == 'Canadian Care Giver'
                    ? setStep(7)
                    : setStep(2);
                }}
                classname="fas fa-arrow-right"
                disabled={
                  !state.email ||
                  !state.gender ||
                  !state.fullName ||
                  !state.expertise ||
                  !state.country ||
                  (!state.visatype && !props.visaType)
                    ? true
                    : false
                }
              />
            </div>
          </div>
        </div>
      );

    case 2:
      return (
        <div className="textcontnew">
          <p style={{ color: '#67A948', fontSize: '13px', fontWeight: '600' }}>
            Step {step} of 3
          </p>
          <p
            style={{
              color: '#14290A',
              fontSize: '22px',
              fontWeight: '600',
              marginTop: '20px',
              marginBottom: '10px',
            }}
          >
            Take the Quiz
          </p>
          <p
            style={{
              color: '#2B5219',
              fontSize: '12px',
              fontWeight: '600',
            }}
            className="eligsubtitle"
          >
            Answer the following questions to see if you qualify for the{' '}
            {state.visatype} VISA
          </p>

          <div className="mt-4">
            <p className="eligibilityPtag">
              Do you consider your area of Expertise of National Interest to the
              United States?
            </p>
            <div className="radioFlexEligibilityTest">
              <NewVerySmallRadioOption
                image={''}
                changed={setQ1option}
                id="1"
                isSelected={test.ExpertiseofNationalInterest === 'YES'}
                label="YES"
                sublabel=""
                value="YES"
              />

              <NewVerySmallRadioOption
                image={''}
                changed={setQ1option}
                id="2"
                isSelected={test.ExpertiseofNationalInterest === 'NO'}
                label="NO"
                sublabel=""
                value="NO"
              />
            </div>
          </div>

          <div className="mt-4">
            <p className="eligibilityPtag">
              Have you received any major prizes, scholarships, funding,
              fellowships, grants or awards in your field?
            </p>
            <div className="radioFlexEligibilityTest">
              <NewVerySmallRadioOption
                image={''}
                changed={setQ2option}
                id="3"
                isSelected={test.majorprizes === 'YES'}
                label="YES"
                sublabel=""
                value="YES"
              />

              <NewVerySmallRadioOption
                image={''}
                changed={setQ2option}
                id="4"
                isSelected={test.majorprizes === 'NO'}
                label="NO"
                sublabel=""
                value="NO"
              />
            </div>
          </div>

          <div className="mt-4">
            <p className="eligibilityPtag">
              Have you authored any books, articles, or other scholarly work
              about your field?
            </p>
            <div className="radioFlexEligibilityTest">
              <NewVerySmallRadioOption
                image={''}
                changed={setQ3option}
                id="5"
                isSelected={test.authoredanybooks === 'YES'}
                label="YES"
                sublabel=""
                value="YES"
              />

              <NewVerySmallRadioOption
                image={''}
                changed={setQ3option}
                id="6"
                isSelected={test.authoredanybooks === 'NO'}
                label="NO"
                sublabel=""
                value="NO"
              />
            </div>
          </div>

          <div className="mt-4">
            <p className="eligibilityPtag">
              Have you previously served as a judge in your field or related
              fields?
            </p>
            <div className="radioFlexEligibilityTest">
              <NewVerySmallRadioOption
                image={''}
                changed={setQ4option}
                id="7"
                isSelected={test.previouslyservedasajudge === 'YES'}
                label="YES"
                sublabel=""
                value="YES"
              />

              <NewVerySmallRadioOption
                image={''}
                changed={setQ4option}
                id="8"
                isSelected={test.previouslyservedasajudge === 'NO'}
                label="NO"
                sublabel=""
                value="NO"
              />
            </div>
          </div>

          <div className="flexButton" style={{ width: '100%' }}>
            <div style={{ width: '100%' }}>
              {' '}
              <Platformbutton
                type="secondary"
                name="Go Back"
                click={() => {
                  setStep(1);
                }}
                classname="fas fa-arrow-right"
              />
            </div>
            <div style={{ width: '100%' }}>
              <Platformbutton
                type="normal"
                name="Continue"
                click={() => {
                  setStep(3);
                }}
                disabled={
                  !test.ExpertiseofNationalInterest ||
                  !test.majorprizes ||
                  !test.authoredanybooks ||
                  !test.previouslyservedasajudge
                    ? true
                    : false
                }
                classname="fas fa-arrow-right"
              />
            </div>
          </div>
        </div>
      );
    case 3:
      return (
        <div className="textcontnew">
          <p style={{ color: '#67A948', fontSize: '13px', fontWeight: '600' }}>
            Step {step} of 3
          </p>
          <p
            style={{
              color: '#14290A',
              fontSize: '22px',
              fontWeight: '600',
              marginTop: '20px',
              marginBottom: '10px',
            }}
          >
            Take the Quiz
          </p>
          <p style={{ color: '#2B5219', fontSize: '12px', fontWeight: '600' }}>
            Answer the following questions to see if you qualify for the{' '}
            {state.visatype} VISA
          </p>

          <div className="mt-4">
            <p className="eligibilityPtag">
              Do you have an advanced degree e.g (Masters or PHD), Answer yes if
              you have a Bachelors degree with at least 5+ years of experience.?
            </p>
            <div className="radioFlexEligibilityTest">
              <div className="radioFlexEligibilityTest">
                <NewVerySmallRadioOption
                  image={''}
                  changed={setQ5option}
                  id="9"
                  isSelected={test.advanceddegree === 'YES'}
                  label="YES"
                  sublabel=""
                  value="YES"
                />

                <NewVerySmallRadioOption
                  image={''}
                  changed={setQ5option}
                  id="10"
                  isSelected={test.advanceddegree === 'NO'}
                  label="NO"
                  sublabel=""
                  value="NO"
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <p className="eligibilityPtag">
              Are you able to obtain reference letters from critical people in
              your field of study. (This has to be someone in your field, a
              professor, colleague, or industry professional)?
            </p>
            <div className="radioFlexEligibilityTest">
              <div className="radioFlexEligibilityTest">
                <NewVerySmallRadioOption
                  image={''}
                  changed={setQ6option}
                  id="11"
                  isSelected={test.referencelettersfromcriticalpeople === 'YES'}
                  label="YES"
                  sublabel=""
                  value="YES"
                />

                <NewVerySmallRadioOption
                  image={''}
                  changed={setQ6option}
                  id="12"
                  isSelected={test.referencelettersfromcriticalpeople === 'NO'}
                  label="NO"
                  sublabel=""
                  value="NO"
                />
              </div>
            </div>
          </div>

          <div className="flexButton" style={{ width: '100%' }}>
            <div style={{ width: '100%' }}>
              {' '}
              <Platformbutton
                type="secondary"
                name="Go Back"
                click={() => {
                  setStep(2);
                }}
                classname="fas fa-arrow-right"
              />
            </div>
            <div style={{ width: '100%' }}>
              <Platformbutton
                type="normal"
                name="Continue"
                click={() => {
                  handleSubmit();
                }}
                disabled={
                  !test.advanceddegree ||
                  !test.referencelettersfromcriticalpeople
                    ? true
                    : false
                }
                classname="fas fa-arrow-right"
              />
            </div>
          </div>
        </div>
      );
    case 4:
      return (
        <div className="textcontnew">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <img src={successPng} alt="" />
          </div>

          <div className="">
            <p
              style={{
                color: '#62943B',
                fontSize: '20px',
                fontWeight: '600',
                textAlign: 'center',
              }}
            >
              Congratulations
            </p>
            <p
              style={{
                color: '#2B5219',
                fontSize: '13px',
                textAlign: 'center',
              }}
            >
              <span style={{ color: '#67A948' }}>
                Congratulations, You scored {getScore(yesScoreCount)}%
              </span>{' '}
              and are a Fit for the U.S {state.visatype} Visa. Click on the
              button below to book a call with our experts to learn more.
            </p>
          </div>
          <div style={{ marginBottom: '10px' }}></div>

          <div style={{ width: '100%' }}>
            <Platformbutton
              type="normal"
              name={'Book a call'}
              // click={() => {}}
              // name={'Okay, thank you'}
              click={() => {
                setStep(6);
                // props.setOpenTestModal();
              }}
            />
          </div>
        </div>
      );
    case 5:
      return (
        <div className="textcontnew">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <img src={NotEligIcon} alt="" />
          </div>

          <div className="">
            <p
              style={{
                color: '#62943B',
                fontSize: '20px',
                fontWeight: '600',
                textAlign: 'center',
              }}
            >
              You’re not eligible
            </p>
            <p
              style={{
                color: '#2B5219',
                fontSize: '13px',
                textAlign: 'center',
              }}
            >
              {' '}
              Sorry, you are not eligible to use this Visa Pathway, not to
              worry, our team just sent you an email on how you can qualify in
              3-6months.
              <span style={{ color: '#67A948' }}>
                {' '}
                We encourage you to proceed to document uploads. A member of our
                team will be in touch.
              </span>
            </p>
          </div>
          <div style={{ marginBottom: '10px' }}></div>

          <div style={{ width: '100%' }}>
            <Platformbutton
              type="normal"
              name={'Okay, thank you'}
              click={() => {
                props.setOpenTestModal();
              }}
            />
          </div>
        </div>
      );

    case 6:
      return (
        <div style={{ width: '100%' }}>
          <IndexClarityBookings
            step={step}
            setStep={setStep}
            visaType={props.visaType ? props.visaType : state.visatype}
            email={userD?.email}
            fullName={`${userD?.firstName} ${userD?.lastName}`}
            setOpenTestModal={props.setOpenTestModal}
            handleSubmit={handleSubmit}
          />
        </div>
      );
    case 7:
      return (
        <div className="textcontnew">
          <p style={{ color: '#67A948', fontSize: '13px', fontWeight: '600' }}>
            Step 2 of 3
          </p>
          <p
            style={{
              color: '#14290A',
              fontSize: '22px',
              fontWeight: '600',
              marginTop: '20px',
              marginBottom: '10px',
            }}
          >
            Take the Quiz
          </p>
          <p
            style={{
              color: '#2B5219',
              fontSize: '12px',
              fontWeight: '600',
            }}
            className="eligsubtitle"
          >
            Answer the following questions to see if you qualify for the{' '}
            {state.visatype} VISA
          </p>

          <div className="mt-4">
            <p className="eligibilityPtag">
              Do you have any relevant certifications? (e.g., PSW, CNA, HCA,
              etc.)
            </p>
            <div className="radioFlexEligibilityTest">
              <NewVerySmallRadioOption
                image={''}
                changed={setQ7option}
                id="13"
                isSelected={canadiancareTest.certifications === 'YES'}
                label="YES"
                sublabel=""
                value="YES"
              />

              <NewVerySmallRadioOption
                image={''}
                changed={setQ7option}
                id="14"
                isSelected={canadiancareTest.certifications === 'NO'}
                label="NO"
                sublabel=""
                value="NO"
              />
            </div>
          </div>

          <div className="mt-4">
            <p className="eligibilityPtag">
              Are you a medical professional or expert?
            </p>
            <div className="radioFlexEligibilityTest">
              <NewVerySmallRadioOption
                image={''}
                changed={setQ8option}
                id="15"
                isSelected={canadiancareTest.medicalProfessional === 'YES'}
                label="YES"
                sublabel=""
                value="YES"
              />

              <NewVerySmallRadioOption
                image={''}
                changed={setQ8option}
                id="16"
                isSelected={canadiancareTest.medicalProfessional === 'NO'}
                label="NO"
                sublabel=""
                value="NO"
              />
            </div>
          </div>

          <div className="mt-4">
            <p className="eligibilityPtag">
              Do you have up to 2 years of experience as a caregiver or medical
              personnel?
            </p>
            <div className="radioFlexEligibilityTest">
              <NewVerySmallRadioOption
                image={''}
                changed={setQ9option}
                id="17"
                isSelected={canadiancareTest.twoYearsPlusExperience === 'YES'}
                label="YES"
                sublabel=""
                value="YES"
              />

              <NewVerySmallRadioOption
                image={''}
                changed={setQ9option}
                id="18"
                isSelected={canadiancareTest.twoYearsPlusExperience === 'NO'}
                label="NO"
                sublabel=""
                value="NO"
              />
            </div>
          </div>

          <div className="flexButton" style={{ width: '100%' }}>
            <div style={{ width: '100%' }}>
              {' '}
              <Platformbutton
                type="secondary"
                name="Go Back"
                click={() => {
                  setStep(1);
                }}
                classname="fas fa-arrow-right"
              />
            </div>
            <div style={{ width: '100%' }}>
              <Platformbutton
                type="normal"
                name="Continue"
                click={() => {
                  setStep(8);
                }}
                disabled={
                  !canadiancareTest.certifications ||
                  !canadiancareTest.medicalProfessional ||
                  !canadiancareTest.twoYearsPlusExperience
                    ? true
                    : false
                }
                classname="fas fa-arrow-right"
              />
            </div>
          </div>
        </div>
      );
    case 8:
      return (
        <div className="textcontnew">
          {/*
          '', languageProficiency: '', criminalRecord: '', */}
          <p style={{ color: '#67A948', fontSize: '13px', fontWeight: '600' }}>
            Step 2 of 3
          </p>
          <p
            style={{
              color: '#14290A',
              fontSize: '22px',
              fontWeight: '600',
              marginTop: '20px',
              marginBottom: '10px',
            }}
          >
            Take the Quiz
          </p>
          <p
            style={{
              color: '#2B5219',
              fontSize: '12px',
              fontWeight: '600',
            }}
            className="eligsubtitle"
          >
            Answer the following questions to see if you qualify for the{' '}
            {state.visatype} VISA
          </p>
          <div className="mt-4">
            <p className="eligibilityPtag">
              Do you have a valid language proficiency test result (e.g, IELTS,
              TEF)?
            </p>
            <div className="radioFlexEligibilityTest">
              <NewVerySmallRadioOption
                image={''}
                changed={setQ10option}
                id="19"
                isSelected={canadiancareTest.languageProficiency === 'YES'}
                label="YES"
                sublabel=""
                value="YES"
              />

              <NewVerySmallRadioOption
                image={''}
                changed={setQ10option}
                id="20"
                isSelected={canadiancareTest.languageProficiency === 'NO'}
                label="NO"
                sublabel=""
                value="NO"
              />
            </div>
          </div>
          <div className="mt-4">
            <p className="eligibilityPtag">Do you have any criminal record?</p>
            <div className="radioFlexEligibilityTest">
              <NewVerySmallRadioOption
                image={''}
                changed={setQ11option}
                id="21"
                isSelected={canadiancareTest.criminalRecord === 'YES'}
                label="YES"
                sublabel=""
                value="YES"
              />

              <NewVerySmallRadioOption
                image={''}
                changed={setQ11option}
                id="22"
                isSelected={canadiancareTest.criminalRecord === 'NO'}
                label="NO"
                sublabel=""
                value="NO"
              />
            </div>
          </div>

          <div className="flexButton" style={{ width: '100%' }}>
            <div style={{ width: '100%' }}>
              {' '}
              <Platformbutton
                type="secondary"
                name="Go Back"
                click={() => {
                  setStep(7);
                }}
                classname="fas fa-arrow-right"
              />
            </div>
            <div style={{ width: '100%' }}>
              <Platformbutton
                type="normal"
                name="Continue"
                click={() => {
                  handleSubmit();
                }}
                disabled={
                  !canadiancareTest.languageProficiency ||
                  !canadiancareTest.criminalRecord
                    ? true
                    : false
                }
                classname="fas fa-arrow-right"
              />
            </div>
          </div>
        </div>
      );

    case 9:
      return (
        <div className="textcontnew">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <img src={successPng} alt="" />
          </div>

          <div className="">
            <p
              style={{
                color: '#62943B',
                fontSize: '20px',
                fontWeight: '600',
                textAlign: 'center',
              }}
            >
              Congratulations
            </p>
            <p
              style={{
                color: '#2B5219',
                fontSize: '13px',
                textAlign: 'center',
              }}
            >
              <span style={{ color: '#67A948' }}>
                Congratulations, You scored{' '}
                {getScore(
                  canadiancareTest.criminalRecord == 'YES'
                    ? yesScoreCountCareGiver - 1
                    : yesScoreCountCareGiver + 1,
                )}
                %
              </span>{' '}
              and are a Fit for the U.S {state.visatype} Visa. Click on the
              button below to book a call with our experts to learn more.
            </p>
          </div>
          <div style={{ marginBottom: '10px' }}></div>

          <div style={{ width: '100%' }}>
            <Platformbutton
              type="normal"
              name={'Okay, thank you'}
              // click={() => {}}
              // name={'Okay, thank you'}
              click={() => {
                props.setOpenTestModal(false);
                // props.setOpenTestModal();
              }}
            />
          </div>
        </div>
      );
    default:
      return <div></div>;
  }
};
