// import React, { useEffect, useState } from 'react';
import React, { useEffect, useState } from 'react';
import './PageLayout.css';
import { useStep } from 'helpers/hooks';
// import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
// import flags from 'react-phone-number-input/flags'
import './input.scss';
import { Form, Formik, useFormikContext } from 'formik';
import { connect, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import api from 'appRedux/api';
import {
  fetchAllCountries,
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import { errorMessage } from 'helpers/utils';
import Loader from 'components/Loader';
import { Singleselect } from 'components/common/inputs/singleselect';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Success } from 'components/common/success/success';
import { opennReg, signUpUserP2V2 } from 'appRedux/actions/auth';
import { Comptransaction } from 'components/common/completetransaction/comptransaction';
import { navigate } from '@reach/router';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Platformbutton } from 'components/common/button/button';
import Inputfloat from 'components/common/inputs/inputfloat';
// import { SecReg } from 'containers/Auth/Signup/register';
import { allCountries } from 'helpers/countryregion';
import { Phonenumberdef } from 'components/common/inputs/phoneinput';

const PageLayout = ({
  children,
  openReg,
  fetchAllCountries,
  fetchLoading,
  countries,
  loading,
  authMessage,
  signUpUserP2V2,
}) => {
  // const { userData, isLoading } = useUserData();
  // const [openReg, setOpenReg] = useState(true);
  // eslint-disable-next-line
  const { step, previousStep, nextStep, setStep } = useStep(0);
  // eslint-disable-next-line
  const [refresh, setRefresh] = useState('');
  // eslint-disable-next-line
  const [data, setData] = useState({
    email: '',
    phoneNumber: '',
  });
  // const [countries, setCountries]= useState('')
  // eslint-disable-next-line
  const [err, setErr] = useState('');
  // eslint-disable-next-line
  const [btn, setButton] = useState('');
  var location = window.location.href;
  // eslint-disable-next-line
  var sendCode = (values, resend = '') => {
    setButton('Sending Code...');
    api
      .post('twillo/phone/twilloSendCode', {
        phoneNumber: values.phoneNumber,
        email: JSON.parse(localStorage.getItem('userData')).email,
      })
      .then(res => {
        openNotificationWithIcon(
          res.data.message,
          'Phone Verification',
          'success',
        );

        resend === '' &&
          setTimeout(() => {
            nextStep();
          }, 500);
        setButton('');
      })
      .catch(err => {
        openNotificationWithIconErr(
          err.data.message,
          'Phone Verification',
          'error',
        );
        setErr(err.data.message);
        setButton('');
      });
  };

  var setOpenReg = value => {
    dispatch(opennReg(value));
  };
  useEffect(() => {
    fetchAllCountries();
    // eslint-disable-next-line
  }, []);
  const dispatch = useDispatch();
  const _renderSteps = step => {
    // eslint-disable-next-line
    const stepMeta = { step, previousStep, nextStep };
    switch (step) {
      case 0:
        return (
          <Step1
            {...stepMeta}
            countries={countries}
            err={err}
            btn={btn}
            sendCode={sendCode}
            setStep={setStep}
          />
        );
      // case 1:
      //   return <Step3 {...stepMeta} sendCode={sendCode}/>;
      case 1:
        return <Step2 {...stepMeta} />;
      // case 0:
      //   return <SecReg nextStep={nextStep} countries={countries} setData={setData} data={data} authMessage={authMessage} cb={(value)=>setStep(value)} signUpUserP2V2={signUpUserP2V2}/>
      case 2:
        return (
          <Success
            title="Account Updated Successfully 👍🏽"
            subtitle="Perfect!, You have successfully updated your Vesti profile, now let us setup your account by clicking the button below."
            onClick={() => {
              // dispatch(openAction())
              setOpenReg(false);
              setTimeout(() => {
                // dispatch(openAction())
                navigate('/myprofile?tab=kyc');
                window.location.reload();
              }, 100);

              // nextStep()
            }}
            button="Set Up Account"
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <Simplemodal
        open={location.split('/')[3] === 'auth' ? false : openReg}
        onClick={() => {
          setOpenReg(false);
          window.location.reload();
        }}
      >
        {fetchLoading ? (
          <Loader />
        ) : (
          <Formik
            initialValues={{ phoneNumber: '', country: 'USA', kycPicture: '' }}
            onSubmit={(values, { setSubmitting }) => {
              // console.log(values);
              const formData = new FormData();
              // eslint-disable-next-line
              for (let key in values) {
                // console.log(key);
                if (key === 'country') {
                  formData.append(key, values[key].value);
                } else {
                  formData.append(key, values[key]);
                }
              }
              api
                // .post('/auth/second-phase-register', formData)
                .post('/customer/update-profile', formData)
                .then(
                  res =>
                    setTimeout(() => {
                      var data = localStorage.getItem('userData');
                      data = data ? JSON.parse(data) : [];
                      data['firstName'] = values.firstName.trim();
                      data['lastName'] = values.lastName.trim();
                      data['phoneNumber'] = values.phoneNumber;

                      localStorage.setItem('userData', JSON.stringify(data));
                    }, 200),
                  setRefresh(`refresh ${values.firstName}`),
                  openNotificationWithIcon(
                    'Account Details Updated Successfully',
                    'Account Details',
                    'success',
                  ),
                  setSubmitting(false),
                  nextStep(),
                )
                .catch(
                  err =>
                    openNotificationWithIconErr(
                      errorMessage(err) ?? 'Could not Update User',
                      'Update Error',
                      'error',
                    ),
                  setSubmitting(false),
                );
            }}
          >
            <Form>{_renderSteps(step)}</Form>
          </Formik>
        )}
      </Simplemodal>
      {children}
    </>
  );
};

// eslint-disable-next-line
const Step1 = ({ nextStep, countries, err, btn, sendCode, setStep }) => {
  const { setFieldValue, values, handleChange } = useFormikContext();
  const [error, setError] = useState(false);

  return (
    <>
      <Titlesubtitle
        steps="Step 1 of 2"
        title="Almost There"
        subtitle="You are almost there, fill in the form below to
        update your profile information."
      />
      <form>
        <div className="row">
          <div className="col-12 col-sm-6 mb-2">
            <Inputfloat
              type="text"
              name="firstName"
              label="First Name"
              placeholder="e.g John"
              value={values.firstName}
              onChange={value => handleChange(value)}
            />
          </div>

          <div className="col-12 col-sm-6 mb-2">
            <Inputfloat
              type="text"
              name="lastName"
              label="Last Name"
              placeholder="e.g Doe"
              value={values.lastName}
              onChange={value => handleChange(value)}
            />
          </div>
        </div>

        <div className="mb-2"></div>
        <div className="mb-2">
          <Singleselect
            label=""
            // className="form-control"
            placeholder="Select Your Country"
            value={values.country}
            options={countries}
            onChange={country => setFieldValue('country', country)}
          />
        </div>
        <div className="mb-4"></div>
        <div className="mb-2">
          {/* <PhoneInput */}
          {/* {values.country} */}
          {/* <PhoneInput
          className="my-input-class"
          inputclassName="my-input-class"
          placeholder="Phone Number"
          international
          countryCallingCodeEditable={false}
          defaultCountry = { values.country ? values.country.alt :''}
          value={values.phoneNumber}
          flags={flags}
          onChange={phone => setFieldValue('phoneNumber', phone)}
          
        /> */}
          <Phonenumberdef
            value={values.phoneNumber}
            country={values.country}
            default={values.country?.value}
            setValue={phone => setFieldValue('phoneNumber', phone)}
          />
          {/* <Phonenumber
            value={values.phoneNumber}
            country={values.country}
            setValue={phone => setFieldValue('phoneNumber', phone)
              // props.setState({ ...props.state, phoneNumber: value })
            }
          /> */}
        </div>

        <div className="mb-4"></div>

        {error && (
          <p className={`checkver ${error ? ' --error' : ' --success'}`}>
            Some fields are Empty
          </p>
        )}
        {err && (
          <p className={`checkver ${err ? ' --error' : ' --success'}`}>{err}</p>
        )}

        <a
          href="/"
          onClick={e => {
            e.preventDefault();
            // sendCode(values)
            values.firstName === '' ||
            values.lastName === '' ||
            values.phoneNumber === ''
              ? setError(true)
              : setStep(1);
          }}
          className={`default-btn ${btn ? ' --disable' : ''}`}
        >
          Continue <i className="fas fa-arrow-right-long"></i>
        </a>
      </form>
    </>
  );
};
// eslint-disable-next-line
const Step2 = ({ previousStep }) => {
  const { submitForm, setFieldValue, isSubmitting } = useFormikContext();
  // eslint-disable-next-line
  const { data } = useQuery('countryFlags', () =>
    api
      .get('https://restcountries.com/v3.1/all')
      // .get('/country/flags')
      // .then(res => res.data)
      // .then(res => res.data.data)
      .then(
        res => res.data,
        // console.log('my ountries',res.data[0].name.common)
      )
      .catch(err => err),
  );
  const valid_countries = [
    'estonia',
    'britain',
    'germany',
    'canada',
    'usa',
    'united states',
    'united kingdom',
  ];
  // const countries =
  //   data ? data.filter(country =>
  //     valid_countries.includes(country.name.common.toLowerCase()),
  //   ) ?? valid_countries :['germany']
  const countries =
    allCountries.filter(country =>
      valid_countries.includes(country.countryName.toLowerCase()),
    ) ?? valid_countries;
  // console.log(countries)
  const [selectedCountries, setCountry] = useState([]);
  // add country to selected countries
  const addCountry = country => {
    console.log('Here', selectedCountries);
    if (selectedCountries.length < 3) {
      // setCountry([...selectedCountries, country.name.common]);
      setCountry([...selectedCountries, country.countryName]);
    } else {
      openNotificationWithIconErr(
        'You can only pick up to three countries',
        'Countries Selected',
      );
    }
  };
  // remove country from selected countries
  const removeCountry = country => {
    // setCountry(selectedCountries.filter(c => c !== country.name.common));
    setCountry(selectedCountries.filter(c => c !== country.countryName));
  };
  // const includesCountry = country => selectedCountries.includes(country.name.common);
  const includesCountry = country =>
    selectedCountries.includes(country.countryName);
  useEffect(() => {
    setFieldValue('countryofChoice', selectedCountries);
  }, [selectedCountries, setFieldValue]);

  if (isSubmitting) return <Loader />;
  return (
    <>
      {/* <h4>Select your top three countries</h4>
      <p style={{ fontWeight: 300, maxWidth: '500px' }}>
        Select your top three countries to which you would love to migrate into,
        so as to enable us to tailor results according to your prefferences.
      </p> */}

      <Titlesubtitle
        steps="Step 2 of 2"
        title="Countries you'd like to immigrate to"
        // subtitle="Select up to 3 countries to which you'd like to immigrate."
        subtitle="(Vesti is a finance platform for immigrants, if you are interested in emigrating which country would you choose) Skip if you are here only for banking."
      />
      <div className="py-3">
        <div className="row">
          {countries.length > 1 ? (
            countries.map(country => (
              <div
                // key={country.name.common}
                key={country.countryName}
                style={{ height: '50px', cursor: 'pointer' }}
                className="col-12 col-sm-6 col-md-4 mb-3"
                onClick={() =>
                  includesCountry(country)
                    ? removeCountry(country)
                    : addCountry(country)
                }
              >
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: includesCountry(country)
                      ? '#F7FFF3'
                      : '#F9F8F9',
                    border: includesCountry(country)
                      ? '0.7px solid #7EC65C'
                      : '1px solid #EAEAEA',
                    borderRadius: '7.5px',
                  }}
                  className="d-flex align-items-center p-2"
                >
                  <span
                    style={{
                      height: '40px',
                      width: '40px',
                      borderRadius: '50vh',
                      overflow: 'hidden',
                      marginRight: '5px',
                    }}
                  >
                    {/* <img
                    src={country.flags.svg}
                    alt={country.name.common}
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover',
                    }}
                  /> */}
                  </span>
                  {/* {country.name.common} */}
                  {country.countryName}
                </div>
              </div>
            ))
          ) : (
            <Loader />
          )}
        </div>
      </div>
      <div>
        <a
          href="/"
          onClick={e => {
            e.preventDefault();
            previousStep();
          }}
          className="default-btn-outline mr-3"
        >
          Back
        </a>
        <a
          href="/"
          onClick={e => {
            e.preventDefault();
            submitForm();
          }}
          className="default-btn"
        >
          {selectedCountries.length > 0 ? 'Finish & Submit' : 'Skip'}
        </a>
      </div>
    </>
  );
};
// eslint-disable-next-line
const Step3 = ({ nextStep, previousStep, sendCode }) => {
  // eslint-disable-next-line
  const { setFieldValue, values, handleChange } = useFormikContext();
  const [code, setCode] = useState('');
  const [err, setErr] = useState('');
  const [btn, setButton] = useState('');
  var verifyPhone = () => {
    setButton('Verifying...');
    api
      .post('twillo/phone/twilloVerifyCode', {
        code: code,
        phoneNumber: values.phoneNumber,
        email: JSON.parse(localStorage.getItem('userData')).email,
      })
      .then(res => {
        openNotificationWithIcon(
          res.data.message,
          'Phone Verification',
          'success',
        );
        setButton('');
        setTimeout(() => {
          nextStep();
        }, 500);
      })
      .catch(err => {
        openNotificationWithIconErr(
          err.data.message,
          'Phone Verification',
          'error',
        );
        setErr(err.data.message);
        setButton('');
      });
  };

  return (
    <>
      <Titlesubtitle
        steps="Step 2 of 3"
        title="Phone Number Verification"
        subtitle={`You are almost there, enter the code sent to ${values.phoneNumber}`}
      />
      <form>
        <Comptransaction
          setPin={setCode}
          onFinish={verifyPhone}
          len={6}
          open={true}
          lower={true}
          pin={code}
          title={`Enter Six(6) Digit Code sent to ${values.phoneNumber} `}
        >
          <p className="resend" onClick={() => sendCode(values, 'resend')}>
            didn't get the code ? <strong>resend code</strong>
          </p>
          {err && (
            <p className={`checkver ${err ? ' --error' : ' --success'}`}>
              {err}
            </p>
          )}
          <Backcontinue
            text="Continue"
            goBack={() => previousStep()}
            // continue = {goContinue}
          >
            {/* <button className="backcontinue__continue" type="submit" disabled={code.length === 6 ? false :true}
                >{btn ? btn : 'Verify Phone Number'}
              </button> */}
            <Platformbutton
              disabled={code.length === 6 ? false : true}
              type="submit"
              name={btn ? btn : 'Verify Phone Number'}
            />
          </Backcontinue>
        </Comptransaction>
      </form>
    </>
  );
};

const mapStateToProps = ({ auth, common }) => {
  const { openReg } = auth;
  const { countries, loading, fetchLoading } = common;

  return {
    openReg,
    countries,
    fetchLoading,
    loading,
  };
};

const mapDispatchToProps = {
  fetchAllCountries,
  signUpUserP2V2,
};
export default connect(mapStateToProps, mapDispatchToProps)(PageLayout);
