import React, { useEffect, useState } from 'react';
import './transactionHistory.scss';
import { Empty } from 'components/common/empty/empty';
import Loader from 'components/Loader';
// import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
// import Newpagination from 'components/bank/pagination/newpagination';
// eslint-disable-next-line
import { getCurrency, transColor, transSign, transStatus } from 'helpers/utils';
import moment from 'moment';
// import { Simplemodal } from 'components/common/simplifiedmodal';
import { fetchMembershipTransactions } from 'appRedux/actions/transactions';
import { connect } from 'react-redux';

const MemberTransaction = props => {
  // const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [active, setActive] = useState(0);
  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  // const [receipt, showReceipt]= useState(false)

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  var handleOpen = value => {
    setActive(value);
    dimensions.width < 1050 && setOpen(true);
  };
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  // var showReceipt = false
  React.useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);

  useEffect(() => {
    props.fetchMembershipTransactions(`/transactions/user`);
  }, []);

  const mySubscriptionHistory = props.trans?.data?.filter(
    item => item.type === 'PATHWAY_SUBSCRIPTION',
  );

  if (props.transLoading) {
    return (
      <div className="membershiptransactions">
        <div className="membershiptransactions__centercont">
          <Loader />
        </div>
      </div>
    );
  } else if (mySubscriptionHistory?.length > 0) {
    return (
      <div className="membershiptransactions">
        <div className="membershiptransactions__inner">
          <div className="membershiptransactions__tablecont">
            <div className="membershiptransactions__tabbox">
              <table className="membershiptransactions__table">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Plan</th>
                    <th>Amount</th>
                    <th>Date & Time</th>
                    <th>Status</th>
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  {mySubscriptionHistory.map((item, index) => (
                    <Trow
                      key={index}
                      index={index}
                      type={item.type}
                      date={item.createdAt}
                      amount={item.amount}
                      status={item.status}
                      click={handleOpen}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="membershiptransactions">
        <div className="membershiptransactions__centercont">
          <Empty
            title="No Transactions"
            subtitle="You are yet to carry out any transactions, when you do they'll appear here, click the buttons above to carry out transactions."
          />
        </div>
      </div>
    );
  }
};

const Trow = props => {
  const newDate = moment(props.date, 'YYYY-MM-DD HH:mm:ss').format(
    'YYYY-MM-DD HH:mm:ss',
  );
  // eslint-disable-next-line
  var tr_type = props.type.replace(/_/g, ' ');

  return (
    <tr onClick={() => props.click(props.index)}>
      <td>{props.index + 1}</td>
      <td>{props.type}</td>
      <td>
        ${' '}
        {props.amount / (100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </td>
      <td>{newDate}</td>
      <td>
        <div className={`status`}>
          <p className={`statusIcon --${transStatus(props.status)} `}></p>{' '}
          {transStatus(props.status)}
        </div>
      </td>{' '}
      {/* <td>{props.index + 1}</td>
      <td>{`${
        props.type === null ? `Pathway Subscription` : tr_type.toLowerCase()
      }`}</td>
      <td>
        ${(props.amount / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </td>
      <td>{newDate}</td>
      <td>
        <div className={`status`}>
          <p className={`statusIcon --${transStatus(props.status)} `}></p>{' '}
          {transStatus(props.status)}
        </div>
      </td> */}
      <td>Download</td>
    </tr>
  );
};

const mapStateToProps = ({ transactions }) => {
  const { transactions: trans, transLoading } = transactions;
  return {
    trans,
    transLoading,
  };
};

export default connect(mapStateToProps, {
  fetchMembershipTransactions,
})(MemberTransaction);
