import React, { useState, useRef, useEffect } from 'react';
import {
  CanadaCohort5,
  socialProof,
  GeneralGroupImg,
} from 'assets/feedPageAssets/index';
import '../Admin.css';
import './groupPathway.scss';
import { About, Feeds, Members, Events } from './generalgroupIndex.js';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Link } from '@reach/router';
import { FaArrowRight, FaLock, FaUnlock } from 'react-icons/fa';
import { BiSolidMessageRoundedDots } from 'react-icons/bi';
import { ChatsFeeds } from 'assets/feedPageAssets';
import './index.css';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { useSelector } from 'react-redux';
import Loader from 'components/Loader/Loader';
import api from 'appRedux/api';
import { Largemodal } from 'components/common/simplifiedmodal';
import PathwaysubSteps from '../Pathways/pathwaysubSteps';

// import { openReward } from 'appRedux/actions/domore';
// import {Singleprovider} from "./singleprovider"
// TimeAgo.addDefaultLocale(en)
// TimeAgo.addLocale(ru)

const GroupPathway = props => {
  const [showModal, setShowModal] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [redirectshowModal, setRedirectShowModal] = useState(false);
  const [about, setAbout] = useState(false);
  const [feeds, setFeeds] = useState(true);
  const [members, setMembers] = useState(false);
  const [events, setEvents] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [defaultGrpId, setDefaultGrpId] = useState('');

  const userD = useSelector(state => state.auth?.userData);

  const handleAbout = () => {
    setAbout(true);
    setEvents(false);
    setMembers(false);
    setFeeds(false);
  };

  const handleFeeds = () => {
    setAbout(false);
    setEvents(false);
    setMembers(false);
    setFeeds(true);
  };

  const handleEvent = () => {
    setAbout(false);
    setEvents(true);
    setMembers(false);
    setFeeds(false);
  };

  const handleMembers = () => {
    setAbout(false);
    setEvents(false);
    setMembers(true);
    setFeeds(false);
  };

  useEffect(() => {
    setIsloading(true);
    const fetchData = async () => {
      try {
        const response = await api.get(`/group/all-groups`);
        const data = await response.data.data.data;
        setGroupData(data);
        // console.log(data);
        setIsloading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const defaultGroup = groupData.find(group => group.default === true);

  // Get the ID of the default group
  const defaultGroupId = defaultGroup ? defaultGroup.id : null;

  var onScroll = () => {
    const scrollTop = scrollRef.current.scrollTop;
    setScroll(scrollTop);
  };
  const [scroll, setScroll] = useState();
  const scrollRef = useRef(null);

  return (
    <section>
      <Largemodal
        onClick={() => setRedirectShowModal(false)}
        open={redirectshowModal}
      >
        <PathwaysubSteps />
      </Largemodal>

      <Simplemodal onClick={() => setShowModal(false)} open={showModal}>
        <div style={{ position: 'relative' }}>
          <p
            className="rounded-md py-4"
            style={{
              background: '#F6F7F9',
              color: '#000',
              fontSize: '26px',
              fontWeight: '800',
              position: 'absolute',
              width: '100%',
              paddingLeft: '1.5rem',
            }}
          >
            Chat is coming
          </p>
          <div
            className="rounded-md"
            style={{
              position: 'absolute',
              width: '100%',
              top: '0px',
              bottom: '0px',
              background: '#000000',
              opacity: '0.35',
            }}
          ></div>
          {/* <img src={feedschatnav} alt='Chats' /> */}
          <img className="px-4 py-2" src={ChatsFeeds} />
        </div>
      </Simplemodal>

      <Simplemodal
        onClick={() => setShowGroupModal(false)}
        open={showGroupModal}
      >
        {isloading ? (
          <Loader />
        ) : (
          <div className=" myFeedsChatAstn gap-4">
            {groupData?.map(group => (
              <>
                <div className="myFeedsChatAstnContainer">
                  <img
                    className="w-full"
                    src={group.image}
                    style={{
                      objectFit: 'center',
                    }}
                    alt="groupDp"
                  />
                  <div className="p-2 ">
                    <div className="flex justify-between items-center">
                      <p
                        style={{
                          color: '#1781BA',
                          fontSize: '18px',
                          fontWeight: '500',
                        }}
                      >
                        {group.name}
                      </p>

                      <div className="flex items-center gap-2">
                        <p>
                          {group.type === 'FREE' ||
                          (props.isactive && props.plantype === group.type) ||
                          props.plantype === 'PREMIUM' ? (
                            ''
                          ) : (
                            <div>
                              <FaLock
                                style={{
                                  color: '#3F6C51',
                                }}
                              />
                            </div>
                          )}
                        </p>

                        <p
                          style={{
                            background: '#C0E9FF',
                            color: '#1781BA',
                            fontSize: '12px',
                            padding: '1px 4px 1px 4px',
                            border: '1px solid #1781BA',
                            borderRadius: '5px',
                          }}
                        >
                          {group.type}
                        </p>
                      </div>
                    </div>
                    <p className="" style={{ color: '#3F6C51' }}>
                      {group.description}
                    </p>

                    <img
                      className="absolute bottom-0 left-0 mx-2 mb-2"
                      style={{ width: '8rem' }}
                      src={socialProof}
                    />
                    <div className="absolute bottom-0 right-0 mx-2 mb-2">
                      {group.type === 'FREE' ||
                      (props.isactive && props.plantype === group.type) ||
                      props.plantype === 'PREMIUM' ? (
                        <div>
                          <Link
                            onClick={() => {
                              setShowModal(false);
                            }}
                            to={`/group/${group.id}`}
                            key={group.id}
                            className="flex items-center gap-1"
                            style={{
                              color: '#1781BA',
                              fontWeight: '600',
                            }}
                          >
                            Join Now <FaArrowRight />
                          </Link>
                        </div>
                      ) : (
                        <div>
                          <p
                            // onClick={handleUnsubcribedUser}
                            onClick={() => {
                              setRedirectShowModal(true);
                              // setShowModal(false);
                            }}
                            className="flex items-center gap-1"
                            style={{
                              color: '#1781BA',
                              fontWeight: '600',
                              cursor: 'pointer',
                            }}
                          >
                            Join Now <FaArrowRight />
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        )}
      </Simplemodal>
      <div
        className="pt-2 px-4 w-full "
        style={{
          overflowX: 'hidden',
          height: '100vh',
        }}
      >
        <div>
          <div className=" bg-white">
            <img src={GeneralGroupImg} className="w-full" alt="" />

            <>
              <article className="groupPathwayArticleAstn cohort">
                <div>
                  <div className="flex justify-between">
                    <h2
                      style={{
                        fontSize: '24px',
                        fontWeight: '600',
                      }}
                    >
                      Move Abroad in 12 Months
                    </h2>
                    <p
                      className="flex flex-col justify-center myfeedspageactionmenubtm"
                      style={{
                        width: '40px',
                        height: '40px',
                        fontSize: '20px',
                        background: '#66A848',
                        color: '#fff',
                        // zIndex: '50',
                        borderRadius: '100%',
                      }}
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      <BiSolidMessageRoundedDots className="mx-auto" />
                    </p>
                  </div>

                  <p
                    className=""
                    style={{
                      color: '#4C5366',
                      fontWeight: '300',
                      fontSize: '14px',
                    }}
                  >
                    Growth Community
                  </p>
                  <div className="">
                    <img src={socialProof} alt="" />
                    <p
                      className="groupPathwayArticleAstn_text my-2 flex gap-2 items-center"
                      style={{ fontSize: '14px' }}
                      onClick={() => {
                        setShowGroupModal(true);
                      }}
                    >
                      See other groups <FaArrowRight />
                    </p>
                  </div>
                </div>
              </article>

              <div className="mygroupFeedminiNavAstn border bg-white border-red-600 w-full rounded-md py-6  d-flex justify-between items-center ">
                <p
                  className={`${
                    about
                      ? 'bg-white flex flex-col cursor-pointer'
                      : 'cursor-pointer'
                  }`}
                  onClick={handleAbout}
                >
                  About{' '}
                  <>
                    {about ? (
                      <span className="bg-vesti-700 px-6 py-1 rounded-lg mt-"></span>
                    ) : (
                      ''
                    )}
                  </>
                </p>

                <p
                  className={`${
                    feeds
                      ? 'bg-white flex flex-col cursor-pointer'
                      : 'cursor-pointer'
                  }`}
                  onClick={handleFeeds}
                >
                  Feeds{' '}
                  <>
                    {feeds ? (
                      <span className="bg-vesti-700 px-6 py-1 rounded-lg mt-1"></span>
                    ) : (
                      ''
                    )}
                  </>
                </p>
                <p
                  className={`${
                    members
                      ? 'bg-white flex flex-col cursor-pointer'
                      : 'cursor-pointer'
                  }`}
                  onClick={handleMembers}
                >
                  Members{' '}
                  <>
                    {members ? (
                      <span className="bg-vesti-700 px-6 py-1 rounded-lg"></span>
                    ) : (
                      ''
                    )}
                  </>
                </p>
                <p
                  className={`${
                    events
                      ? 'bg-white flex flex-col cursor-pointer'
                      : 'cursor-pointer'
                  }`}
                  onClick={handleEvent}
                >
                  Webinars{' '}
                  <>
                    {events ? (
                      <span className="bg-vesti-700 px-6 py-1 rounded-lg"></span>
                    ) : (
                      ''
                    )}
                  </>
                </p>
              </div>
            </>
          </div>

          {about && <About />}
          {feeds && <Feeds defaultGroupId={defaultGroupId} />}
          {members && <Members />}
          {events && <Events />}
        </div>
      </div>
    </section>
  );
};

export default GroupPathway;
