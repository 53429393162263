import React, { useState } from 'react';
import { Platformbutton } from 'components/common/button/button';
import Inputfloat from 'components/common/inputs/inputfloat';
import {
  CustomizedSingleselect,
  Singleselect,
} from 'components/common/inputs/singleselect';
import { Comptransaction } from 'components/common/completetransaction/comptransaction';
import './register.scss';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';

export const TransactionPin = props => {
  const [pins, setPin] = useState({
    transactionPin: '',
    confirmTransactionPin: '',
    password: localStorage.getItem('myonboarding_password'),
    // modal: false,
  });

  var uploadTransPin = async () => {
    var data = {
      transactionPin: pins.transactionPin,
      confirmTransactionPin: pins.confirmTransactionPin,
      password: localStorage.getItem('myonboarding_password'),
    };

    const token = localStorage.getItem('token'); // Replace with your token access method

    const headers = {
      Authorization: `Bearer ${token}`, // Include token in Authorization header
      'Content-Type': 'application/json', // Add Content-Type for JSON data
    };

    try {
      const response = await fetch(
        'https://api.wevesti.com/api/v1/customer/update-transaction-pin',
        {
          method: 'POST',
          headers,
          body: JSON.stringify(data),
        },
      );

      console.log(response);

      props.setStep(5);
    } catch (error) {
      console.log(error);
      props.setStep(5);
      // openNotificationWithIconErr(error.data.message, 'Register', 'error');
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        alignItems: 'center',
      }}
    >
      <p
        style={{
          fontSize: '22px',
          fontWeight: '700',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '5rem',
        }}
      >
        Set up your transaction PIN
      </p>
      <p
        style={{
          color: '#111827',
          fontSize: '14px',
          fontWeight: '300',
          marginBottom: '20px',
          textAlign: 'center',
        }}
      >
        To carry out transactions on Vesti you will need to create a four (4)
        <br />
        digits PIN code.
      </p>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 'fit-content',
          gap: '5px',
        }}
      >
        <Comptransaction
          setPin={value => setPin({ ...pins, transactionPin: value })}
          len={4}
          open={false}
          lower={true}
          pin={pins.transactionPin}
          title={`Enter Your Desired Transaction PIN`}
        >
          <></>
        </Comptransaction>

        <div className="tranOnboardingMobile"></div>
        <Comptransaction
          setPin={value => setPin({ ...pins, confirmTransactionPin: value })}
          len={4}
          open={false}
          lower={true}
          pin={pins.confirmTransactionPin}
          title={`Confirm Transaction PIN`}
        >
          <></>
        </Comptransaction>

        <div className="tranOnboardingMobile"></div>

        {pins.transactionPin === pins.confirmTransactionPin ||
        (pins.transactionPin && !pins.confirmTransactionPin) ? (
          <div></div>
        ) : (
          <div style={{ color: 'red', marginTop: '5px' }}>PIN mismatch</div>
        )}

        <div className="mt-4"></div>
        <Platformbutton
          name="Verify"
          type="normal"
          disabled={
            !pins.transactionPin ||
            !pins.confirmTransactionPin ||
            pins.transactionPin !== pins.confirmTransactionPin
              ? true
              : false
          }
          click={() => {
            uploadTransPin();
          }}
        />
      </div>
    </div>
  );
};
