import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useStep } from 'helpers/hooks';
import { Success } from 'components/common/success/success';
import errorsvg from 'assets/newerror.svg';
import pending from 'assets/pendingreview.svg';
import doc from 'assets/kycothers.svg';
import './Kyc.scss';
import api from 'appRedux/api';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { fetchUserKycStatus } from 'appRedux/actions/Common';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import { Platformbutton } from 'components/common/button/button';
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KYC);

const Kycothers = props => {
  const { step, setStep } = useStep(0);

  const stripeKey = process.env.REACT_APP_STRIPE_KYC;

  const stripePromise = stripeKey ? loadStripe(stripeKey) : null;

  var __renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <div className="kycothers__initiate">
            <Titlesubtitle
              title="KYC Verification"
              subtitle="To avoid disruption of service, please kindly go ahead and Click the button to start your KYC Verification process."
            />
            <div className="kycothers__content">
              <img src={doc} alt="document" />
              <span>
                <p>Verify Account</p>
                <p>Click the button below to verify </p>
              </span>
              <VerifyButton
                stripePromise={stripePromise}
                error={() => setStep(2)}
                success={() => setStep(3)}
              />
            </div>
          </div>
        );
      case 1:
        return (
          <Success
            type="success"
            subtitle="Your KYC Document has been approved successfully."
          />
        );
      case 2:
        return (
          <Success
            image={errorsvg}
            type="error"
            title="Failed"
            subtitle={
              'Your kyc doc failed our verification process, click button below to try again.'
            }
            button="Try Again"
            onClick={() => setStep(0)}
          />
        );
      case 3:
        return (
          <Success
            image={pending}
            title="Pending"
            subtitle={`Thanks for submitting your identity document, your document is being reviewed. We are processing your verification. 
                    check back in 1-3mins, to know your status.
                `}
          />
        );

      default:
        return <></>;
    }
  };

  var handleStatus = value => {
    switch (props.userdata?.verifiedKyc) {
      case 'PENDING':
        return setStep(3);
      case 'APPROVED':
        return setStep(1);
      case 'true':
        return setStep(1);
      case 'DISAPPROVED':
        return setStep(2);
      case 'false':
        return setStep(2);
      case 'REQUIRES_INPUT':
        return setStep(2);
      default:
        return setStep(0);
    }
  };

  useEffect(() => {
    props.userdata.stripeVerificationToken &&
      props.userdata.verifiedKyc !== 'APPROVED' &&
      props.fetchUserKycStatus(handleStatus);
    props.userdata.verifiedKyc === 'APPROVED' && setStep(1);
  }, []);

  useEffect(
    () =>
      props.userdata.stripeVerificationToken &&
      props.userdata.verifiedKyc !== 'APPROVED'
        ? props.fetchUserKycStatus()
        : () => {},

    [],
  );

  return (
    <section className="kycothers">
      {props.kycStatus === false ? (
        <>
          {step !== 0 && (
            <Titlesubtitle
              title="KYC Verification"
              subtitle="Your KYC Verification status is give below."
            />
          )}
          {__renderSteps()}{' '}
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
};

const handleStripeVerification = async () => {
  const url = `/identity-verification/confirm-other-verification-token`;
  try {
    const res = await api.post(url);
  } catch (error) {}
};

const VerifyButton = props => {
  const [stripe, setStripe] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // console.log(stripe);
  useEffect(() => {
    const initializeStripe = async () => {
      setStripe(await props.stripePromise);
    };

    initializeStripe();
  }, [props.stripePromise]);

  const handleClick = async event => {
    setIsLoading(true);

    if (!stripe) {
      setIsLoading(false);
      return;
    }

    try {
      const response = await api.get(
        '/identity-verification/other-verification-token',
      );
      // console.log(response);
      const session = await response.data.data;

      const data = await stripe.verifyIdentity(session.client_secret);

      if (data.error) {
        setIsLoading(false);
        props.error();
      } else {
        handleStripeVerification();
        // console.log('.........', props);
        setIsLoading(false);
        props.success();
      }
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
      // Handle the error here
    }
  };

  return (
    <div>
      <Platformbutton
        name="Verify"
        type="normal"
        disabled={isLoading}
        click={() => {
          handleClick();
        }}
      />
      {isLoading && <Loader />}
    </div>
  );
};

const mapStateToProps = ({ common }) => {
  const { kycStatus } = common;
  return {
    kycStatus,
  };
};

const mapDispatchToProps = {
  fetchUserKycStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Kycothers);
