import React, { useEffect, useState } from 'react';
import { Platformbutton } from 'components/common/button/button';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Registerleft } from 'components/auth/register/registerfeatures';
import { Formik } from 'formik';
import { SignInSchema } from 'helpers/schema';
import { navigate } from '@reach/router';
import { connect, useDispatch } from 'react-redux';
import { Simplemodal } from 'components/common/simplifiedmodal';
import Twofalogin from 'components/auth/twofa/twofa';
import { userSignIn } from './actions';
import { AlertError } from 'components/common/alertboxes/alertboxes';
import { userSignOut, verify2faSignin } from 'appRedux/actions/auth';
import { AUTH_LOAD } from 'appRedux/constants';
import VestiLogo from 'assets/Vesti_logo.svg';
import arrowPointer from 'assets/newOnboarding/Ornament.svg';

// import VestiLogo from 'assets/xmasLogo/xmasLogo.svg';

const Signinv2 = props => {
  const [modal, setModal] = useState(false);
  const [state, setState] = useState({
    data: '',
    token: '',
    code: '',
  });

  var handle2fa = (value, token) => {
    setModal(true);
    setState({ ...state, data: value, token: token });
  };
  // eslint-disable-next-line

  // var from = document.referrer
  //   ? document.referrer.includes('/auth?merchant=')
  //     ? `\merchants?merchant=${document.referrer.split('=')[1]}`
  //     : props.location.state?.from
  //     ? props.location.state.from
  //     : props.location.state.from
  //   : '/bank';

  let from;

  if (document.referrer && document.referrer.includes('/auth?merchant=')) {
    from = `\merchants?merchant=${document.referrer.split('=')[1]}`;
  } else if (!props.location.state) {
    from = '/bank';
  } else if (props.location.state?.from) {
    from = props.location.state.from;
  }
  // switch (true) {
  //   case document.referrer &&
  //     document.referrer.includes('/auth?merchant='):
  //     from = `\merchants?merchant=${
  //       document.referrer.split('=')[1]
  //     }`;

  //   case !props.location.state:
  //     from = '/bank';

  //   default:
  //     from = props.location.state.from;
  // }

  const dispatch = useDispatch();

  useEffect(() => {
    props.userSignOut();
  }, []);

  const storedUserData = localStorage.getItem('myuserData');
  const userData = JSON.parse(storedUserData);

  // console.log(props.location.state);
  // console.log(from);

  return (
    <div className="h-[100vh] grid grid-cols-1 lg:grid-cols-3  lg:gap-[0px]">
      <Simplemodal
        closable="yes"
        onClick={() => {
          // this.closeModal()
          // {()=>{}}{}
        }}
        open={modal}
      >
        <Twofalogin
          from={from}
          firstName={userData?.firstName}
          lastName={userData?.lastName}
          email={userData?.email}
          phone={userData?.phoneNumber}
          picture={userData?.profilePictureURL}
          pin={state.code}
          setCode={value => setState({ ...state, code: value })}
          Finalize={() =>
            props.verify2faSignin(
              { userId: userData.id, code: state.code },
              from,
              state.token,
            )
          }
          token={state.token}
        />
      </Simplemodal>
      <Registerleft />
      <div className="col-span-2 relative p-[30px] flex flex-col gap-[30px] items-center justify-center w-[100vw] lg:w-full h-[100vh] ">
        <img
          src={arrowPointer}
          alt=""
          style={{ position: 'absolute', top: '10%', left: '18%' }}
        />

        <div
          className="w-full md:w-[500px] flex flex-col flex-start gap-[10px]"
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          {/* <img
            className="w-[100px] md:w-[100px]"
            src={VestiLogo}
            alt="Vesti logo"
          />
          <Titlesubtitle
            title="Welcome Back"
            subtitle="Welcome Back, move anywhere on the planet and move your money"
          /> */}
          <p
            style={{
              fontSize: '22px',
              fontWeight: '700',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Welcome Back
          </p>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={SignInSchema}
            onSubmit={values => {
              var data = {
                username: values.email,
                password: values.password,
              };
              props.userSignIn(
                JSON.stringify(data, null, 2),
                from,
                (value, token) => handle2fa(value, token),
              );
            }}
          >
            {formik => {
              // eslint-disable-next-line
              const {
                errors,
                touched,
                // isValid,
                values,
                handleChange,
                handleSubmit,
              } = formik;
              return (
                <form
                  onSubmit={handleSubmit}
                  className="w-full flex flex-col gap-[10px] lg:w-[450px]"
                >
                  <div className="w-full flex flex-col gap-[10px]">
                    <Inputfloat
                      label="email"
                      type="email"
                      name="email"
                      value={values.email}
                      disabled={false}
                      placeholder="ajitd@gmail.com"
                      onChange={handleChange}
                      invalid={errors.email && touched.email && 'true'}
                      error={errors.email && touched.email ? errors.email : ''}
                    />

                    <Inputfloat
                      label="Password"
                      type="password"
                      name="password"
                      value={values.password}
                      disabled={false}
                      placeholder="Enter your password"
                      onChange={handleChange}
                      invalid={errors.password && touched.password && 'true'}
                      error={
                        errors.password && touched.password
                          ? errors.password
                          : ''
                      }
                    />
                    {/* <p style={{}}>
                      Forgot Password?{' '}
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/auth/forgot-password')}
                      >
                        Reset here
                      </span>{' '}
                    </p> */}
                    <Platformbutton
                      type="link"
                      text="Forgot Password? "
                      title=" Reset here"
                      click={() => navigate('/auth/forgot-password')}
                    />
                    {/* <div className="flex flex-col gap-[5px]"></div> */}
                  </div>
                  <div className="flex flex-col gap-[5px]">
                    {props.authErr && (
                      <AlertError
                        body={
                          props.authErr
                            ? props.authErr
                            : 'Invalid login credentials (email address / Password)'
                        }
                      />
                    )}
                    <div className="flex flex-col gap-[10px] items-center w-full">
                      <Platformbutton
                        name="Continue"
                        type="submit"
                        disabled={props.loading ? true : false}
                      />
                      <Platformbutton
                        type="link"
                        text=" Are you new here? "
                        title="  Create account"
                        click={() => {
                          dispatch({
                            type: AUTH_LOAD,
                            payload: { authErr: '', authMessage: '' },
                          });
                          navigate('/register');
                        }}
                      />
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>

        <div
          style={{
            position: 'absolute',
            bottom: '4%',
            fontSize: '12px',
            color: '#A0AEC0',
          }}
        >
          © 2024 Vesti . Alrights reserved.{' '}
          <span
            style={{ color: '#111827', cursor: 'pointer', marginLeft: '4px' }}
            onClick={() => {
              window.open('https://wevesti.com/tos', '_blank');
            }}
          >
            Terms & Conditions
          </span>{' '}
          <span
            style={{ color: '#111827', cursor: 'pointer', marginLeft: '4px' }}
            onClick={() => {
              window.open('https://wevesti.com/privacy-policy', '_blank');
            }}
          >
            Privacy Policy
          </span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, common }) => {
  const { authMessage, authLoad, authErr } = auth;
  const { loading } = common;
  return {
    loading,
    authMessage,
    authLoad,
    authErr,
  };
};

const mapDispatchToProps = {
  userSignIn,
  verify2faSignin,
  userSignOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(Signinv2);
