import React from "react";
import './alertboxes.scss'
import infobox from "assets/infobox.svg"
export const AlertSuccess = (props)=> {
    return (
        <div className="alertboxes --success">
        <i className="fas fa-check-double"></i>
        <p>
            {props.body}
        </p>
    </div>
    )
}

export const AlertError = (props)=> {
    return (
        <div className="alertboxes --error">
            <i className="fas fa-times-circle"></i>
            <p>
                {props.body}
            </p>
        </div>
    )
}

export const AlertWarning = ()=> {
    return (
        <div>
            
        </div>
    )
}

export const AlertInfo = (props)=> {
    return (
        <div className="alertboxes --infobox">
            <img src={infobox} alt="info" />
            <p>{props.body}</p>
        </div>
    )
}