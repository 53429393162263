import React, { useState, useRef } from 'react';
import './ApplicationDetails.scss';
import Layout from 'components/common/DashboardLayout';
import Arrow from 'assets/studentloans/arrow-left.svg';
import { navigate } from '@reach/router';
import { Platformbutton } from 'components/common/button/button';
import Inputfloat from 'components/common/inputs/inputfloat';
import { connect } from 'react-redux';
import { fetchAllCountries } from 'appRedux/actions/Common';
import { RadioOption } from 'components/common/radiobutton/radiobutton';
import { MdKeyboardArrowDown } from 'react-icons/md';
import InfoCircle from 'assets/studentloans/info-circle.svg';
import DocumentUpload from 'assets/studentloans/document-upload.svg';
import GroupCheck from 'assets/studentloans/Group-Check.svg';
import ArrowUp from 'assets/studentloans/arrow-up.svg';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { SchoolsData } from 'helpers/data';
import { useSelector } from 'react-redux';
import api from 'appRedux/api';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import Success from 'assets/studentloans/success.gif';
import { SyncLoader } from "react-spinners";

const ApplicationDetails = props => {
  const [loading, setLoading] = useState(false)
  const [seeOption, setSeeOption] = useState(false);
  const [optionTwoTab, setOptionTwoTab] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userSelection, setUserSelection] = useState(null);
  const [studyProgram, setStudyProgram] = useState('Study Program*');
  const [admissionStatus, setAdmissionStatus] = useState('Admission Status*');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState('');

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSchoolSelect = selectedSchool => {
    handleChange({
      target: {
        name: 'school',
        type: 'text',
        value: selectedSchool,
      },
    });
    setIsDropdownOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openMoodal = () => {
    setIsModalOpen(true);
  };

  const handleUserSelection = selection => {
    setUserSelection(selection);
  };

  const setApplicationOption = value => {
    setStudyProgram(value);
    setSeeOption(false);
  };

  const setApplicationOptionTwo = value => {
    setAdmissionStatus(value);
    setOptionTwoTab(false);
  };

  const [currency, setCurrency] = useState('USD');
  const [loanId] = useState('422f3a0d-f450-4521-a259-882d07bb15e8');
  const appliedBySelf = useSelector(state => state.StudentLoan.appliedBySelf);
  const appliedByName = useSelector(state => state.StudentLoan.appliedByName);
  const appliedByEmail = useSelector(state => state.StudentLoan.appliedByEmail);
  const appliedByPhoneNumber = useSelector(
    state => state.StudentLoan.appliedByPhoneNumber,
  );

  const [form, setForm] = useState({
    amount: '',
    school: '',
    country: '',
    courseOfStudy: '',
    admissionLetter: null,
    cv: null,
    passportDataPage: null,
    utilityBill: null,
  });

  const fileInputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleChange = e => {
    const { name, type, value, files } = e.target;
    const inputValue = type === 'file' ? files[0] : value;
    setForm({
      ...form,
      [name]: inputValue,
    });
  };

  const handleBrowseClick = index => {
    if (fileInputRefs[index].current) {
      fileInputRefs[index].current.click();
    }
  };

  const handleRemoveFile = field => {
    setForm({
      ...form,
      [field]: null,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const formData = new FormData();
      const loanDetails = {
        school: form.school,
        country: form.country,
        studyProgram: studyProgram,
        courseOfStudy: form.courseOfStudy,
        admissionStatus: admissionStatus,
      };

      formData.append('amount', form.amount);
      formData.append('loanDetails[school]', loanDetails.school);
      formData.append('loanDetails[country]', loanDetails.country);
      formData.append('loanDetails[studyProgram]', loanDetails.studyProgram);
      formData.append('loanDetails[courseOfStudy]', loanDetails.courseOfStudy);
      formData.append(
        'loanDetails[admissionStatus]',
        loanDetails.admissionStatus,
      );
      formData.append('currency', currency);
      formData.append('loanId', loanId);
      formData.append('appliedBySelf', appliedBySelf);
      formData.append('appliedByName', appliedByName);
      formData.append('appliedByEmail', appliedByEmail);
      formData.append('appliedByPhoneNumber', appliedByPhoneNumber);
      formData.append('cv', form.cv);
      formData.append('admissionLetter', form.admissionLetter);
      formData.append('passportDataPage', form.passportDataPage);
      formData.append('utilityBill', form.utilityBill);

      const response = await api.post(
        `/migration-loan/apply-for-international-student-loan`,
        formData,
      );
      openMoodal();
      openNotificationWithIcon(response?.data?.message, 'success');
    } catch (error) {
      console.log(error);
      openNotificationWithIconErr(error?.data?.message, 'Error');
    } finally {
      setLoading(false)
    }
  };

  return (
    <Layout>
      <div className="student-loan">
        <div className="layout-container">
          <div className="ty-loan-container__header">
            <img
              src={Arrow}
              alt="Arrow"
              onClick={() =>
                navigate(
                  '/loandetails/422f3a0d-f450-4521-a259-882d07bb15e8/International Student Loan',
                )
              }
            />
            <p className="ty-loan-container__header__title">
              International Student Loan
            </p>
          </div>

          <div className="ty-loan-container__header__applieddy">
            <p>Enter Application Details</p>
          </div>

          <div>
            <form
              // onSubmit={handleSubmit}
              className="w-full flex flex-col"
            >
              <div className="w-full flex flex-col">
                <Inputfloat
                  label="school"
                  type="text"
                  name="school"
                  value={form.school}
                  disabled={false}
                  placeholder="School*"
                  onChange={handleChange}
                >
                  <div className="relative">
                    <div
                      className="flex gap-2 mt-2 cursor-pointer"
                      onClick={toggleDropdown}
                    >
                      <img src={InfoCircle} alt="info" />
                      <p style={{ color: '#67A948' }} className="underline">
                        Schools We Support
                      </p>
                    </div>

                    {isDropdownOpen && (
                      <ul
                        style={{ height: 160 }}
                        className="absolute z-10 bg-white border border-gray-300 w-full mt-2 overflow-auto"
                      >
                        {SchoolsData.map(school => (
                          <li
                            key={school.id}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleSchoolSelect(school.name)}
                          >
                            {school.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </Inputfloat>

                <span className="flex flex-col items-center justify-center lg:flex-row space__itemn space__UPAP">
                  <Inputfloat
                    label="country"
                    type="text"
                    name="country"
                    value={form.country}
                    disabled={false}
                    placeholder="Country*"
                    onChange={handleChange}
                    
                  />
                    <div
                    onClick={() => {
                      setSeeOption(!seeOption);
                    }}
                    className="flex items-center justify-between border px-2 py-3 rounded-lg application__Optionap"
                  >
                    <p>{studyProgram}</p>
                    <MdKeyboardArrowDown color="#CCCCCC" size={24} />
                  </div>
                </span>



                {seeOption && (
                  <div className="mt-2">
                    <RadioOption
                      changed={setApplicationOption}
                      id="1"
                      isSelected={studyProgram === 'MBA'}
                      label="MBA"
                      sublabel=""
                      value="MBA"
                    />

                    <RadioOption
                      changed={setApplicationOption}
                      id="2"
                      isSelected={studyProgram === 'STEM'}
                      label="STEM"
                      sublabel=""
                      value="STEM"
                    />
                  </div>
                )}
              

                <span className="flex flex-col items-center justify-center lg:flex-row space__itemn space__UPAP">
                  <Inputfloat
                    label="courseOfStudy"
                    type="text"
                    name="courseOfStudy"
                    value={form.courseOfStudy}
                    disabled={false}
                    placeholder="Course of Study*"
                    onChange={handleChange}
                  />

                  <div
                    onClick={() => {
                      setOptionTwoTab(!optionTwoTab);
                    }}
                    className=" flex items-center justify-between border px-2 py-3 rounded-lg application__Optionap"
                  >
                    <p className="text-sm">{admissionStatus}</p>
                    <MdKeyboardArrowDown color="#CCCCCC" size={24} />
                  </div>
                </span>

                {optionTwoTab && (
                  <div className="mt-2">
                    <RadioOption
                      changed={setApplicationOptionTwo}
                      id="1"
                      isSelected={
                        admissionStatus ===
                        'I have received admission to the selected school'
                      }
                      label="I have received admission to the selected school"
                      sublabel=""
                      value="I have received admission to the selected school"
                    />

                    <RadioOption
                      changed={setApplicationOptionTwo}
                      id="2"
                      isSelected={
                        admissionStatus ===
                        'I applied to the selected school but have not received admission'
                      }
                      label="I applied to the selected school but have not received admission"
                      sublabel=""
                      value="I applied to the selected school but have not received admission"
                    />
                    <RadioOption
                      changed={setApplicationOptionTwo}
                      id="3"
                      isSelected={
                        admissionStatus ===
                        'I have not yet applied to the selected school'
                      }
                      label="I have not yet applied to the selected school"
                      sublabel=""
                      value="I have not yet applied to the selected school"
                    />
                  </div>
                )}

                {(admissionStatus ===
                  'I have received admission to the selected school' ||
                  admissionStatus ===
                    'I have not yet applied to the selected school') && (
                  // upload four
                  <div className="mt-4 application-details">
                    <div className="upload-grid">
                      {/* 0  */}
                      <div className="upload-box">
                        <img src={DocumentUpload} alt="document-upload" />

                        {form.admissionLetter ? (
                          <p>{form.admissionLetter.name}</p>
                        ) : (
                          <p>Upload Admission Letter</p>
                        )}

                        <p>(Doc and PDF only | 2MB max size)</p>

                        {form.admissionLetter ? (
                          <p
                            className="remove-text"
                            onClick={() => handleRemoveFile('admissionLetter')}
                          >
                            Remove File
                          </p>
                        ) : (
                          <p
                            className="browse-text"
                            onClick={() => handleBrowseClick(0)}
                          >
                            Browse File
                          </p>
                        )}

                        <input
                          type="file"
                          ref={fileInputRefs[0]}
                          onChange={handleChange}
                          name="admissionLetter"
                          accept=".doc,.docx,.pdf"
                          style={{ display: 'none' }}
                        />
                      </div>

                      {/* 1  */}
                      <div className="upload-box">
                        <img src={DocumentUpload} alt="document-upload" />

                        {form.cv ? <p>{form.cv.name}</p> : <p>Upload CV</p>}

                        <p>(Doc and PDF only | 2MB max size)</p>

                        {form.cv ? (
                          <p
                            className="remove-text"
                            onClick={() => handleRemoveFile('cv')}
                          >
                            Remove File
                          </p>
                        ) : (
                          <p
                            className="browse-text"
                            onClick={() => handleBrowseClick(1)}
                          >
                            Browse File
                          </p>
                        )}

                        <input
                          type="file"
                          ref={fileInputRefs[1]}
                          onChange={handleChange}
                          name="cv"
                          accept=".doc,.docx,.pdf"
                          style={{ display: 'none' }}
                        />
                      </div>

                      {/* 2  */}
                      <div className="upload-box">
                        <img src={DocumentUpload} alt="document-upload" />

                        {form.passportDataPage ? (
                          <p>{form.passportDataPage.name}</p>
                        ) : (
                          <p>Upload Passport Data Page</p>
                        )}

                        <p>(Doc and PDF only | 2MB max size)</p>

                        {form.passportDataPage ? (
                          <p
                            className="remove-text"
                            onClick={() => handleRemoveFile('passportDataPage')}
                          >
                            Remove File
                          </p>
                        ) : (
                          <p
                            className="browse-text"
                            onClick={() => handleBrowseClick(2)}
                          >
                            Browse File
                          </p>
                        )}

                        <input
                          type="file"
                          ref={fileInputRefs[2]}
                          onChange={handleChange}
                          name="passportDataPage"
                          accept=".doc,.docx,.pdf"
                          style={{ display: 'none' }}
                        />
                      </div>

                      {/* 3  */}
                      <div className="upload-box">
                        <img src={DocumentUpload} alt="document-upload" />

                        {form.utilityBill ? (
                          <p>{form.utilityBill.name}</p>
                        ) : (
                          <p>Upload Utility Bill</p>
                        )}

                        <p>(Doc and PDF only | 2MB max size)</p>

                        {form.utilityBill ? (
                          <p
                            className="remove-text"
                            onClick={() => handleRemoveFile('utilityBill')}
                          >
                            Remove File
                          </p>
                        ) : (
                          <p
                            className="browse-text"
                            onClick={() => handleBrowseClick(3)}
                          >
                            Browse File
                          </p>
                        )}

                        <input
                          type="file"
                          ref={fileInputRefs[3]}
                          onChange={handleChange}
                          name="utilityBill"
                          accept=".doc,.docx,.pdf"
                          style={{ display: 'none' }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div>
                  {admissionStatus ===
                    'I applied to the selected school but have not received admission' && (
                    <>
                      {userSelection === null && (
                        <div className="containerfg">
                          <p className="question">
                            Would you like the Vesti Education Pathways team to
                            assist with your school application for a fee?
                          </p>

                          <div className="option-group">
                            <img
                              src={GroupCheck}
                              alt="CheckBox"
                              className="checkbox"
                            />
                            <p className="option-text">
                              Tailored application guidance
                            </p>
                          </div>
                          <div className="option-group">
                            <img
                              src={GroupCheck}
                              alt="CheckBox"
                              className="checkbox"
                            />
                            <p className="option-text">
                              Guaranteed support until admission
                            </p>
                          </div>

                          <div className="options cursor-pointer">
                            <p
                              onClick={() => handleUserSelection('No')}
                              className={`option ${
                                userSelection === 'No' ? 'active' : ''
                              }`}
                            >
                              No
                            </p>
                            <p
                              onClick={() => handleUserSelection('Yes')}
                              className={`option ${
                                userSelection === 'Yes' ? 'active' : ''
                              }`}
                            >
                              Yes
                            </p>
                          </div>
                        </div>
                      )}

                      {userSelection === 'No' && (
                        <div className="mt-4 containerthree cursor-pointer">
                          {/* 1 box  */}
                          <div className="file-box">
                            <img src={DocumentUpload} alt="document-upload" />

                            {form.cv ? <p>{form.cv.name}</p> : <p>Upload CV</p>}

                            <p>(Doc and PDF only | 2MB max size)</p>

                            {form.cv ? (
                              <p
                                className="file-actions"
                                onClick={() => handleRemoveFile('cv')}
                              >
                                Remove File
                              </p>
                            ) : (
                              <p
                                className="file-actions"
                                onClick={() => handleBrowseClick(1)}
                              >
                                Browse File
                              </p>
                            )}

                            <input
                              type="file"
                              ref={fileInputRefs[1]}
                              onChange={handleChange}
                              name="cv"
                              accept=".doc,.docx,.pdf"
                              style={{ display: 'none' }}
                            />
                          </div>

                          <div className="file-group">
                            {/* 2 box  */}
                            <div className="file-box">
                              <img src={DocumentUpload} alt="document-upload" />

                              {form.passportDataPage ? (
                                <p>{form.passportDataPage.name}</p>
                              ) : (
                                <p>Upload Passport Data Page</p>
                              )}

                              <p>(Doc and PDF only | 2MB max size)</p>

                              {form.passportDataPage ? (
                                <p
                                  className="file-actions"
                                  onClick={() =>
                                    handleRemoveFile('passportDataPage')
                                  }
                                >
                                  Remove File
                                </p>
                              ) : (
                                <p
                                  className="file-actions"
                                  onClick={() => handleBrowseClick(2)}
                                >
                                  Browse File
                                </p>
                              )}

                              <input
                                type="file"
                                ref={fileInputRefs[2]}
                                onChange={handleChange}
                                name="passportDataPage"
                                accept=".doc,.docx,.pdf"
                                style={{ display: 'none' }}
                              />
                            </div>

                            {/* 3 box  */}
                            <div className="file-box">
                              <img src={DocumentUpload} alt="document-upload" />

                              {form.utilityBill ? (
                                <p>{form.utilityBill.name}</p>
                              ) : (
                                <p>Upload Utility Bill</p>
                              )}

                              <p>(Doc and PDF only | 2MB max size)</p>

                              {form.utilityBill ? (
                                <p
                                  className="file-actions"
                                  onClick={() =>
                                    handleRemoveFile('utilityBill')
                                  }
                                >
                                  Remove File
                                </p>
                              ) : (
                                <p
                                  className="file-actions"
                                  onClick={() => handleBrowseClick(3)}
                                >
                                  Browse File
                                </p>
                              )}

                              <input
                                type="file"
                                ref={fileInputRefs[3]}
                                onChange={handleChange}
                                name="utilityBill"
                                accept=".doc,.docx,.pdf"
                                style={{ display: 'none' }}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {userSelection === 'Yes' && (
                        <div className="mt-4">
                          <div className="containerzx">
                            <p className="description">
                              To start your school application with Vesti
                              Education Pathways team, book a clarity call with
                              our expert for <span>$99</span>
                            </p>
                            <div className="call-to-action">
                              <p className="call-text">Book clarity call</p>
                              <img
                                src={ArrowUp}
                                alt=""
                                className="arrow-icon"
                              />
                            </div>
                          </div>

                          <div className="mt-4 containerthree cursor-pointer">
                            {/* 1 box  */}
                            <div className="file-box">
                              <img src={DocumentUpload} alt="document-upload" />

                              {form.cv ? (
                                <p>{form.cv.name}</p>
                              ) : (
                                <p>Upload CV</p>
                              )}

                              <p>(Doc and PDF only | 2MB max size)</p>

                              {form.cv ? (
                                <p
                                  className="file-actions"
                                  onClick={() => handleRemoveFile('cv')}
                                >
                                  Remove File
                                </p>
                              ) : (
                                <p
                                  className="file-actions"
                                  onClick={() => handleBrowseClick(1)}
                                >
                                  Browse File
                                </p>
                              )}

                              <input
                                type="file"
                                ref={fileInputRefs[1]}
                                onChange={handleChange}
                                name="cv"
                                accept=".doc,.docx,.pdf"
                                style={{ display: 'none' }}
                              />
                            </div>

                            <div className="file-group">
                              {/* 2 box  */}
                              <div className="file-box">
                                <img
                                  src={DocumentUpload}
                                  alt="document-upload"
                                />

                                {form.passportDataPage ? (
                                  <p>{form.passportDataPage.name}</p>
                                ) : (
                                  <p>Upload Passport Data Page</p>
                                )}

                                <p>(Doc and PDF only | 2MB max size)</p>

                                {form.passportDataPage ? (
                                  <p
                                    className="file-actions"
                                    onClick={() =>
                                      handleRemoveFile('passportDataPage')
                                    }
                                  >
                                    Remove File
                                  </p>
                                ) : (
                                  <p
                                    className="file-actions"
                                    onClick={() => handleBrowseClick(2)}
                                  >
                                    Browse File
                                  </p>
                                )}

                                <input
                                  type="file"
                                  ref={fileInputRefs[2]}
                                  onChange={handleChange}
                                  name="passportDataPage"
                                  accept=".doc,.docx,.pdf"
                                  style={{ display: 'none' }}
                                />
                              </div>

                              {/* 3 box  */}
                              <div className="file-box">
                                <img
                                  src={DocumentUpload}
                                  alt="document-upload"
                                />

                                {form.utilityBill ? (
                                  <p>{form.utilityBill.name}</p>
                                ) : (
                                  <p>Upload Utility Bill</p>
                                )}

                                <p>(Doc and PDF only | 2MB max size)</p>

                                {form.utilityBill ? (
                                  <p
                                    className="file-actions"
                                    onClick={() =>
                                      handleRemoveFile('utilityBill')
                                    }
                                  >
                                    Remove File
                                  </p>
                                ) : (
                                  <p
                                    className="file-actions"
                                    onClick={() => handleBrowseClick(3)}
                                  >
                                    Browse File
                                  </p>
                                )}

                                <input
                                  type="file"
                                  ref={fileInputRefs[3]}
                                  onChange={handleChange}
                                  name="utilityBill"
                                  accept=".doc,.docx,.pdf"
                                  style={{ display: 'none' }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>

                <Inputfloat
                  label="amount"
                  type="text"
                  name="amount"
                  value={form.amount}
                  disabled={false}
                  placeholder="Loan between 200000 - 10000000"
                  onChange={handleChange}
                />
              </div>

              <div className="button-containeryuup">
                <Platformbutton
                  name={loading ? <SyncLoader size="0.8rem" color="#ffffff" /> : "Proceed to apply" }
                  type="normal"
                  click={() => handleSubmit()}
                  disabled={!form.school || !currency}
                />
              </div>
            </form>
          </div>
        </div>

        <Simplemodal open={isModalOpen} onClick={closeModal}>
          <div className="flex flex-col items-center justify-center">
            <div className="mb-4">
              <img
                src={Success}
                alt="success-img"
                style={{
                  width: '60px',
                  height: '60px',
                  objectFit: 'cover',
                }}
              />
            </div>

            <p className="text-[#62943B] font-semibold text-[20px]">
              Application Received!
            </p>
            <p className="text-center text-[18px] text-[#2B5219] font-light p-2 mb-2">
              We have received your loan application and will get back to you
              with the status within 48 to 72 hours.
            </p>
            <Platformbutton
              name="Return to Home"
              type="medium"
              // disabled={!isChecked}
              click={() => navigate('/bank')}
            />
          </div>
        </Simplemodal>
      </div>
    </Layout>
  );
};

const mapStateToProps = ({ common, auth }) => {
  const { countries } = common;
  const { authMessage, authLoad, authErr } = auth;
  return {
    countries,
    authMessage,
    authLoad,
    authErr,
  };
};

const mapDispatchToProps = {
  fetchAllCountries,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetails);
