import React, { useState, useEffect } from 'react';
import cancelImg from '../../../assets/membership/vcardprompt.svg';
import { Platformbutton } from 'components/common/button/button';
import './cancelModal.scss';
import cancelIcon from '../../../assets/membership/cancelIcon.svg';
import api from 'appRedux/api';
import { objectValuesStringify } from 'helpers/utils';
import { openNotificationWithIcon } from 'appRedux/actions/Common';
import { openNotificationWithIconErr } from 'appRedux/actions/Common';

const CancelModal = props => {
  const handleCancelSub = async () => {
    const url = `/membership/cancel-and-activate`;
    props.setIsSuccess(false);
    try {
      const res = await api.post(url);
      const { data } = res;
      // console.log(data);
      openNotificationWithIcon(
        'Cancel Subscription',
        'Subscription access updated successfully',
        'success',
      );
      props.setIsSuccess(true);
      props.setCancelSubModal(false);
    } catch (error) {
      // console.log(error);
      props.setIsSuccess(true);

      //  if (error?.data?.errors) {
      //    openNotificationWithIconErr(
      //      objectValuesStringify(error?.data?.errors),
      //      'Subscription Payment',
      //      'error',
      //      setErrorMsg(error?.data?.errors),
      //    );
      //  } else {
      //    const err = error?.data?.message || error.message;
      //    openNotificationWithIconErr(err, 'Subscription Payment', 'error');
      //    setErrorMsg(err);
      //  }
      //  setStep(5);
      // setLoading(false);
    }
  };

  return (
    <div className="cancelModal">
      <img src={cancelImg} alt="" />
      <div className="cancelModal__article">
        <article>
          <p className="cancelModal__title">We're sad to see you go...</p>

          <p className="cancelModal__subtitle">
            Your subscription will expire on {props.renewDate}. You will still
            have access to subscribed content till then.
          </p>

          <p className="cancelModal__subtitle">After the expiry date:</p>

          <ul>
            <li>
              {' '}
              <img src={cancelIcon} alt="" /> You will no longer be charged
              monthly or annually
            </li>
            <li>
              <img src={cancelIcon} alt="" />
              You will lose access to all subscribed pathways and other
              privileges.
            </li>
          </ul>

          <p className="cancelModal__subtitle">
            At the end of your plan, your account will be restricted to Economy
            Plan features
          </p>
        </article>
        <div className="subFeature__button">
          <Platformbutton
            name="Keep Subscription"
            type="secondary"
            click={() => {
              props.setCancelSubModal(false);
            }}
          />

          <Platformbutton
            type="normal"
            name="Cancel Subscription"
            click={() => {
              handleCancelSub();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CancelModal;
