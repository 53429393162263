import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import Layout from 'components/common/DashboardLayout';
import Arrow from 'assets/studentloans/arrow-left.svg';
import { Link } from '@reach/router';
import { RadioOption } from 'components/common/radiobutton/radiobutton';
import api from 'appRedux/api';

const ApplyForMigrationLoan = () => {
  const [option, setOption] = useState('');
  const setApplicationOption = value => {
    setOption(value);
  };

  const [checkCreditData, setCheckCreditData] = useState([]);

  useEffect(() => {
    checkCreditHistory();
  }, []);

  const checkCreditHistory = async () => {
    try {
      const res = await api.get(`/credit-score/history`);
      const data = res.data.data;
      setCheckCreditData(data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <div className="student-loan">
        <div className="layout-container">
          <div className="ty-loan-container__header">
            <img
              src={Arrow}
              alt="Arrow"
              onClick={() =>
                navigate(
                  'loandetails/4416815b-a666-44df-ba11-1e9aac8404dc/Migration Support Loan',
                )
              }
            />
            <p className="ty-loan-container__header__title">
              Migration support Loan
            </p>
          </div>
          <div className="ty-loan-container__header__applied">
            <div className="ty-loan-container__header__mpower__titttle">
              <p className="mb-3">
                Have you applied for a migration loan in the past three months?
              </p>
              {/* <Link to="/creditscore?loan=migration_support_loan"> */}
              <Link
                to={
                  checkCreditData?.creditHistory === null &&
                  checkCreditData?.creditHistoryCount >= 0
                    ? '/creditscore?loan=migration_support_loan'
                    : '/migrationloanapplication'
                }
              >
                <RadioOption
                  changed={setApplicationOption}
                  id="1"
                  isSelected={option === 'Yes'}
                  label="Yes"
                  sublabel=""
                  value="Yes"
                />
              </Link>

              <Link
                to={
                  checkCreditData?.creditHistory === null &&
                  checkCreditData?.creditHistoryCount >= 0
                    ? '/creditscore?loan=migration_support_loan'
                    : '/migrationloanapplication'
                }
              >
                <RadioOption
                  changed={setApplicationOption}
                  id="2"
                  isSelected={option === 'No'}
                  label="No"
                  sublabel=""
                  value="No"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ApplyForMigrationLoan;
