import React, { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { Usernotification } from './usernotification';
import {
  SimpleCancelmodal,
  LargemodalCustomCancel,
} from 'components/common/simplifiedmodal';
import { StvPreviewImg } from './usernotification';

export const DocFolds = props => {
  const [openImg, setOpenImg] = useState(false);
  const [openFiles, setOpenFiles] = useState(false);
  const [openLinks, setOpenLinks] = useState(false);

  const handleImg = () => [setOpenImg(!openImg)];
  const handleFile = () => [setOpenFiles(!openFiles)];
  const handleLink = () => [setOpenLinks(!openLinks)];

  const myFileMessages = useSelector(state => state.webVisaChat?.filesList);
  const myImageMessages = useSelector(state => state.webVisaChat?.imageList);
  const myListMessages = useSelector(state => state.webVisaChat?.linkList);

  const images = myImageMessages?.filter(image => {
    return image.image !== null;
  });

  const files = myFileMessages?.filter(file => {
    return file.file !== null;
  });

  const links = myListMessages?.filter(link => {
    return link.link !== null;
  });
  const [imgurl, setImgurl] = useState('');
  //  showImgPreview = { showImgPreview };
  //  setShowImgPreview = { setShowImgPreview };
  return (
    <div style={{ width: '100%' }}>
      <SimpleCancelmodal
        open={props.showUserNot}
        onClick={() => props.setShowUserNot(false)}
      >
        <Usernotification
          myUrl={props.myUrl}
          setShowUserNot={props.setShowUserNot}
        />
      </SimpleCancelmodal>

      <SimpleCancelmodal
        open={props.showImgPreview}
        onClick={() => props.setShowImgPreview(false)}
      >
        <StvPreviewImg
          myUrl={imgurl}
          setShowImgPreview={props.setShowImgPreview}
        />
      </SimpleCancelmodal>

      <div
        style={{
          border: '1px solid #EAECF0',
          padding: '10px',
          borderRadius: '6px',
          fontSize: '13px',
          fontWeight: '600',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => {
          handleFile();
        }}
      >
        Shared Files ({files?.length})
        {openFiles ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </div>
      {openFiles && (
        <div
          style={{
            border: '1px solid #EAECF0',
            padding: '10px',
            borderRadius: '6px',
            overflowX: 'hidden',
            height: '200px',
            overflowY: 'auto',
          }}
        >
          {files?.length < 1 ? (
            <p
              style={{
                fontSize: '14px',
                // color: '#66A848',
                // cursor: 'pointer',
                width: '80%',
              }}
            >
              No files shared yet
            </p>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
                width: '100%',
              }}
            >
              {files.map(({ file }) => {
                return (
                  <p
                    onClick={() => {
                      props.setShowUserNot(true);
                      props.setmyUrl(file);
                    }}
                    style={{
                      fontSize: '10px',
                      color: '#66A848',
                      cursor: 'pointer',
                      width: '80%',
                      textDecoration: 'underline',
                    }}
                  >
                    {file.replace(
                      'https://migrationloan.s3.amazonaws.com/loans/',
                      '',
                    )}
                  </p>
                );
              })}
            </div>
          )}
        </div>
      )}
      <div
        style={{
          border: '1px solid #EAECF0',
          padding: '10px',
          borderRadius: '6px',
          fontSize: '13px',
          fontWeight: '600',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => {
          handleImg();
        }}
      >
        {/* <div></div> */}
        Shared Images ({images?.length})
        {openImg ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </div>
      {openImg && (
        <div
          style={{
            border: '1px solid #EAECF0',
            padding: '10px',
            borderRadius: '6px',
            overflowX: 'hidden',
            height: '200px',
            overflowY: 'auto',
          }}
        >
          {images?.length < 1 ? (
            <p
              style={{
                fontSize: '14px',
                // color: '#66A848',
                // cursor: 'pointer',
                width: '80%',
              }}
            >
              No shared images yet
            </p>
          ) : (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gap: '4px',
                width: '100%',
              }}
            >
              {images.map(({ image }) => {
                return (
                  <div
                    onClick={() => {
                      props.setShowImgPreview(true);
                      setImgurl(image);
                    }}
                    style={{
                      fontSize: '10px',
                      color: '#66A848',
                      cursor: 'pointer',
                    }}
                  >
                    <img
                      src={image}
                      alt=""
                      style={{
                        height: '5rem',
                        width: '100%',
                        objectFit: 'cover',
                      }}
                    />
                    {/* {image} */}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
      <div
        style={{
          border: '1px solid #EAECF0',
          padding: '10px',
          borderRadius: '6px',
          fontSize: '13px',
          fontWeight: '600',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => {
          handleLink();
        }}
      >
        Shared Links ({links?.length})
        {openLinks ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </div>
      {openLinks && (
        <div
          style={{
            border: '1px solid #EAECF0',
            padding: '10px',
            borderRadius: '6px',
            overflowX: 'hidden',
            height: '200px',
            overflowY: 'auto',
          }}
        >
          {links?.length < 1 ? (
            <p
              style={{
                fontSize: '14px',
                // color: '#66A848',
                // cursor: 'pointer',
                width: '80%',
              }}
            >
              No shared URLs yet
            </p>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
                width: '100%',
              }}
            >
              {links.map(({ link }) => {
                return (
                  <p
                    onClick={() => {
                      props.setShowUserNot(true);
                      props.setmyUrl(link);
                    }}
                    style={{
                      fontSize: '10px',
                      color: '#66A848',
                      cursor: 'pointer',
                      width: '80%',
                      textDecoration: 'underline',
                    }}
                  >
                    {link}
                  </p>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
