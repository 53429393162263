import React from 'react';
// import avi from '../../../assets/avi.png';
import Ajayi from '../../../assets/Ajayi.jpeg';
import Atti from '../../../assets/Atti.jpeg';
import Ojji from '../../../assets/Ojji.jpeg';
// import arrowslant from '../../../assets/arrowslant.svg';
import './Ovtestimony.scss';

const Ovtestimony = () => {
  return (
    <div className="ovtestimony-container">
      <div className="ovtestimony-container-inner">
        <div className="ovtestimony-top">
          <p>
            What our users are <span>saying</span>
          </p>
          <p>Don't take our words for it, see what our users are saying.</p>
        </div>
        <div className="ovtestimony-bottom">
          <TestimonyCard
            name="Bestie Atti"
            title="Founder & CEO, CorporateBestie"
            location='Virginia Beach'
            image={Atti}
            message="Working with Vesti to migrate to the US via the EB-1 route was very excellent.
            During our first meeting and after reviewing my case, they suggested the most effective and efficient pathway for my family, business and I. The team was also very responsive, transparent, kind and supportive throughout the process. I highly recommend using Vesti."
            backgroundColor="#F7FFF3"
          />
          <TestimonyCard
            name="Stephen Ojji"
            title="Founder and CEO, NoCopyCopy"
            location='Houston, Texas'
            image={Ojji}
            message="The team at Vesti were very professional and showed great support offering sevices in helping me get the O-1 Visa. Kudos to Olu and the team!"
            backgroundColor="#F6F7FF"
          />
          <TestimonyCard
            name="Adeshina Ajayi"
            title="Digital Focus LLC "
            location='Chicago, Illinois'
            image={Ajayi}
            message="Few months back, I got on a clarity call with Vesti team where he introduced me to the US entrepreneur visa (0-1) and how I can be qualified for it. 
            After the call, I got on board and the experienced attorney  team at VESTI worked tirelessly on my case to ensure approval. Right now, I have my US 0-1 visa which covers my spouse and kids."
            backgroundColor="#FBF3FF"
          />
        </div>
        {/* <div className="__link">
          <a href="/">
            More Testimonials &nbsp; <img src={arrowslant} alt="svg" />
          </a>
        </div> */}
      </div>
    </div>
  );
};

const TestimonyCard = props => {
  return (
    <div
      className="userfeedbackbox "
      style={{ backgroundColor: props.backgroundColor }}
    >
      <div className="avatar">
        <img className="rounded-image" src={props.image} alt="avatar" />
        <div className="feedback-header">
          <h5 className="userName"> {props.name}</h5>
          <h5>{props.title}</h5>
          <h5>{props.location}</h5>
        </div>
      </div>
      <div className="userfeedback">
        <p>{props.message}</p>
      </div>
    </div>
  );
};

export default Ovtestimony;
