import React, { useState, useEffect } from 'react';
import checkIcon from 'assets/viasForm/circle-check.svg';
import indicator from 'assets/viasForm/Indicator.svg';
import uploadIcon from 'assets/viasForm/uploadIcon.svg';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { RiDeleteBinLine } from 'react-icons/ri';
import '../section.scss';
import { Fileupload } from 'components/common/fileupload/fileupload';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import api from 'appRedux/api';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchViasForms } from 'appRedux/actions/visaForm';
import {
  Largemodal,
  Simplemodal,
  SimpleCancelmodal,
} from 'components/common/simplifiedmodal';
import { openNotificationWithIcon } from 'appRedux/actions/Common';
import { updateUploadedFiles, handleSections } from 'appRedux/actions/visaForm';
import errIcon from 'assets/viasForm/uploadError.svg';
import { fetchViasForms } from 'appRedux/actions/visaForm';
import pathwayconfig from 'config.js';

const renderTitle = index => {
  switch (index) {
    case 0:
      return (
        <p
          style={{
            fontSize: '14px',
            fontWeight: '500',
            color: '#14290A',
            paddingBottom: '0.5rem',
          }}
        >
          Updated Resume
        </p>
      );
    case 1:
      return (
        <p
          style={{
            fontSize: '14px',
            fontWeight: '500',
            color: '#14290A',
            paddingBottom: '0.5rem',
            paddingTop: '0.75rem',
          }}
        >
          Data Page of Unexpired Passport
        </p>
      );
    case 2:
      return (
        <p
          style={{
            fontSize: '14px',
            fontWeight: '500',
            color: '#14290A',
            paddingBottom: '0.5rem',
          }}
        >
          Bachelor's Degree Academic Transcript
        </p>
      );
    case 3:
      return (
        <p
          style={{
            fontSize: '14px',
            fontWeight: '500',
            color: '#14290A',
            paddingBottom: '0.5rem',
          }}
        >
          Please upload Credential Evaluation for your Bachelors degree to show
          U.S equivalency (e.g From WES, IQAS, etc)
        </p>
      );
    case 4:
      return (
        <p
          style={{
            fontSize: '14px',
            fontWeight: '500',
            color: '#14290A',
            paddingBottom: '0.5rem',
            paddingTop: '0.75rem',
          }}
        >
          Other Degree certificate(s) and transcripts (if any) e.g Masters, PHD,
          PGD etc
        </p>
      );
    case 5:
      return (
        <p
          style={{
            fontSize: '14px',
            fontWeight: '500',
            color: '#14290A',
            paddingBottom: '0.5rem',
          }}
        >
          High School or SSCE Certificate.
        </p>
      );
    case 6:
      return (
        <p
          style={{
            fontSize: '14px',
            fontWeight: '500',
            color: '#14290A',
            paddingBottom: '0.5rem',
            paddingTop: '0.75rem',
          }}
        >
          Upload Birth Certificate
        </p>
      );
    case 7:
      return (
        <p
          style={{
            fontSize: '14px',
            fontWeight: '500',
            color: '#14290A',
            paddingBottom: '0.5rem',
          }}
        >
          Upload professional certifications, awards, memberships, letters of
          commendations, media features and other relevant documents. (Combine
          into one document and upload)
        </p>
      );

    default:
  }
};

export const Section1 = props => {
  const [isToggle, setIstoggle] = useState(false);
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState({
    0: 1,
    1: 1,
    2: 1,
    2: 2,
    3: 1,
    3: 2,
    3: 3,
    4: 1,
  });
  const [viewFile, setViewFile] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]);
  const [numPages, setNumPages] = useState({
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
  });
  const [pdfData, setPdfData] = useState(['', '', '', '', '', '', '', '']);
  const [myIndex, setMyIndex] = useState(0);

  const [submittedPdfData, setSubmittedPdfData] = useState([
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ]);
  const [myDict, setMyDict] = useState();

  useEffect(() => {
    if (Array.isArray(props.Forms)) {
      const newData = props.Forms?.map(data => ({
        resumeCredentialscv: data.resumeCredentialscv,
        dataPageOfPassport: data.dataPageOfPassport,
        academicTranscripts: data.academicTranscripts,
        credentialsEvaluationDocuments: data.credentialsEvaluationDocuments,
        resumeCredentialsotherCertificates:
          data.resumeCredentialsotherCertificates,
        highSchoolCertificates: data.highSchoolCertificates,
        birthCertificates: data.birthCertificates,
        professionCertificates: data.professionCertificates,
      }));
      setMyDict(newData);

      setPdfData([
        newData[0]?.resumeCredentialscv ? newData[0]?.resumeCredentialscv : '',
        newData[0]?.dataPageOfPassport ? newData[0]?.dataPageOfPassport : '',
        newData[0]?.academicTranscripts ? newData[0]?.academicTranscripts : '',
        newData[0]?.credentialsEvaluationDocuments
          ? newData[0]?.credentialsEvaluationDocuments
          : '',
        newData[0]?.resumeCredentialsotherCertificates
          ? newData[0]?.resumeCredentialsotherCertificates
          : '',
        newData[0]?.highSchoolCertificates
          ? newData[0]?.highSchoolCertificates
          : '',
        newData[0]?.birthCertificates ? newData[0]?.birthCertificates : '',
        newData[0]?.professionCertificates
          ? newData[0]?.professionCertificates
          : '',
      ]);

      setSubmittedPdfData([
        newData[0]?.resumeCredentialscv ? newData[0]?.resumeCredentialscv : '',
        newData[0]?.dataPageOfPassport ? newData[0]?.dataPageOfPassport : '',
        newData[0]?.academicTranscripts ? newData[0]?.academicTranscripts : '',
        newData[0]?.credentialsEvaluationDocuments
          ? newData[0]?.credentialsEvaluationDocuments
          : '',
        newData[0]?.resumeCredentialsotherCertificates
          ? newData[0]?.resumeCredentialsotherCertificates
          : '',
        newData[0]?.highSchoolCertificates
          ? newData[0]?.highSchoolCertificates
          : '',
        newData[0]?.birthCertificates ? newData[0]?.birthCertificates : '',
        newData[0]?.professionCertificates
          ? newData[0]?.professionCertificates
          : '',
      ]);
    }
  }, [props.Forms]);

  const fileNames = pdfData.map(url => url.split('/').pop());
  const nonEmptyLength = pdfData.filter(item => item !== '').length;

  const noOfSubmittedData = submittedPdfData.filter(item => item !== '').length;

  const [errorMsgs, setErrorMsgs] = useState(['', '', '', '', '', '', '', '']);

  const onDocumentLoadSuccess = ({ numPages }, index) => {
    setNumPages(prevNumPages => ({
      ...prevNumPages,
      [index]: numPages,
    }));
  };

  const [progress, setProgress] = useState(0);
  const [uploadedDoc, setUploadedDoc] = useState(false);
  const [triggerwarning, setTriggerWarning] = useState(false);
  const [buttontext, setButtonText] = useState('Submit');

  useEffect(() => {
    if (uploadedDoc) {
      const interval = setInterval(() => {
        setUploadedDoc(true);

        if (progress < 100) {
          setProgress(prevProgress => prevProgress + 1);
        } else {
          clearInterval(interval);
          setUploadedDoc(false);

          setViewFile(true);

          setProgress(0);
        }
      }, 70);
      return () => clearInterval(interval);
    } else {
      return;
    }
  }, [progress, uploadedDoc, props.loading]);

  const updateApplication = async selectedFileObject => {
    setSelectedFiles([{}, {}, {}, {}, {}, {}, {}, {}]);
    // setSelectedFiles([{}, {}, {}, {}, {}, {}, {}, {}]);
    // props.setLoading(true);
    setButtonText('Saving...');

    // console.log(selectedFileObject);
    // console.log(selectedFileObject);

    var url = `${pathwayconfig.baseURL}/pathway/update-visa-documents`;

    const formData = new FormData();
    if (selectedFileObject?.resumeCredentialscv) {
      formData.append(
        'resumeCredentialscv',
        selectedFileObject?.resumeCredentialscv,
      );
    }

    if (selectedFileObject?.dataPageOfPassport) {
      formData.append(
        'dataPageOfPassport',
        selectedFileObject?.dataPageOfPassport,
      );
    }

    if (selectedFileObject?.academicTranscripts) {
      formData.append(
        'academicTranscripts',
        selectedFileObject?.academicTranscripts,
      );
    }

    if (selectedFileObject?.credentialsEvaluationDocuments) {
      formData.append(
        'credentialsEvaluationDocuments',
        selectedFileObject?.credentialsEvaluationDocuments,
      );
    }

    if (selectedFileObject?.resumeCredentialsotherCertificates) {
      formData.append(
        'resumeCredentialsotherCertificates',
        selectedFileObject?.resumeCredentialsotherCertificates,
      );
    }

    if (selectedFileObject?.highSchoolCertificates) {
      formData.append(
        'highSchoolCertificates',
        selectedFileObject?.highSchoolCertificates,
      );
    }

    if (selectedFileObject?.birthCertificates) {
      formData.append(
        'birthCertificates',
        selectedFileObject?.birthCertificates,
      );
    }

    if (selectedFileObject?.professionCertificates) {
      formData.append(
        'professionCertificates',
        selectedFileObject?.professionCertificates,
      );
    }

    if (props.Forms[0]?.fullname) {
      formData.append('fullname', props.Forms[0]?.fullname);
    }

    formData.append('email', props.userD?.email);
    formData.append('firstName', props.userD?.firstName);
    //  formData.append('fullame', `${}`);
    formData.append('id', props.Forms[0]?.id);
    formData.append('userId', props.userD?.id);
    // formData.append('submitted', true);
    // formData.append('afterSubmittionStatus', 'completed_stage2');

    // formData.append('hasWatchedPathwayVideo', true);
    // formData.append('formStage', 2);

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        body: formData,
      });
      const data = await response.json();
      // props.setLoading(false);

      dispatch(
        fetchViasForms(
          props.userD?.id,
          props.userD?.email,
          'H1B',
          props.userD?.firstName,
        ),
      );
      dispatch(updateUploadedFiles(null));

      setButtonText('Submit');
      openNotificationWithIcon('', 'Saved');
    } catch (error) {
      console.error('Error uploading file:', error);
      dispatch(
        fetchViasForms(
          props.userD?.id,
          props.userD?.email,
          'H1B',
          props.userD?.firstName,
        ),
      );
      setButtonText('Submit');
      // props.setLoading(true);
    }
  };

  const finalSubmitApplication = async () => {
    setSelectedFiles([{}, {}, {}, {}, {}, {}, {}, {}]);
    // props.setLoading(true);
    setButtonText('Saving...');

    var url = `${pathwayconfig.baseURL}/pathway/update-visa-documents`;

    const formData = new FormData();

    formData.append('email', props.userD?.email);
    formData.append('id', props.Forms[0]?.id);
    formData.append('userId', props.userD?.id);
    formData.append('submitted', true);
    formData.append('afterSubmittionStatus', 'completed_stage2');

    // formData.append('hasWatchedPathwayVideo', true);
    // formData.append('formStage', 2);

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        body: formData,
      });
      const data = await response.json();
      // props.setLoading(false);

      dispatch(
        fetchViasForms(
          props.userD?.id,
          props.userD?.email,
          'H1B',
          props.userD?.firstName,
        ),
      );
      dispatch(updateUploadedFiles(null));

      setButtonText('Submit');
      openNotificationWithIcon('', 'Saved');
    } catch (error) {
      console.error('Error uploading file:', error);
      dispatch(
        fetchViasForms(
          props.userD?.id,
          props.userD?.email,
          'H1B',
          props.userD?.firstName,
        ),
      );
      setButtonText('Submit');
      // props.setLoading(true);
    }
  };

  const onFileLoad = (event, index) => {
    const file = event.target.files[0];

    // Check if file size exceeds the limit (1010 KB)
    if (file.size / 1024 > 10240) {
      // Reset selectedFiles[index] and pdfData[index] to empty state
      setSelectedFiles(prevSelectedFiles => {
        const newSelectedFiles = [...prevSelectedFiles];
        newSelectedFiles[index] = {};
        return newSelectedFiles;
      });

      setPdfData(prevPdfData => {
        const newPdfData = [...prevPdfData];
        newPdfData[index] = '';

        const newErrorMsgs = [...errorMsgs];
        newErrorMsgs[index] = 'File size exceeds the limit (10 MB)';
        setErrorMsgs(newErrorMsgs);

        // Clear the error message after 4 seconds
        setTimeout(() => {
          newErrorMsgs[index] = '';
          setErrorMsgs(newErrorMsgs);
        }, 2000);

        return newPdfData;
      });

      // Display error message (you can modify this part based on your UI)
      // setErrorMsg('File size exceeds the limit (1010 KB)');
      console.error('File size exceeds the limit (1010 KB)');
      return;
    }
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles[index] = file;
    setSelectedFiles(newSelectedFiles);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const newPdfData = [...pdfData];
      newPdfData[index] = reader.result;
      setPdfData(newPdfData);

      const selectedFileObject = {
        [index === 0
          ? 'resumeCredentialscv'
          : index === 1
          ? 'dataPageOfPassport'
          : index === 2
          ? 'academicTranscripts'
          : index === 3
          ? 'credentialsEvaluationDocuments'
          : index === 4
          ? 'resumeCredentialsotherCertificates'
          : index === 5
          ? 'highSchoolCertificates'
          : index === 6
          ? 'birthCertificates'
          : 'professionCertificates']: file,
      };

      // Dispatch the action to update the Redux store with the selected file object
      dispatch(updateUploadedFiles(selectedFileObject));
      console.log(selectedFileObject);
      updateApplication(selectedFileObject);
    };
  };

  const optionalSection = useSelector(
    state => state.visaForm.mysections.optional,
  );
  const requiredSection = useSelector(
    state => state.visaForm.mysections.required,
  );

  const getItem = async () => {
    if (myDict && myDict[0]) {
      // props.setLoading(true);

      const firstItem = myDict[0];
      const keyValuePairs = Object.entries(firstItem);
      // Now keyValuePairs is an array of arrays, each inner array representing a key-value pair
      // Accessing the first key-value pair
      const firstKeyValuePair = keyValuePairs[myIndex];
      const key = firstKeyValuePair[0]; // The key
      const value = firstKeyValuePair[1]; // The value

      const url = `${pathwayconfig.baseURL}/pathway/delete-file-visa-documents`;

      const payload = {
        formId: props.Forms[0]?.id,
        item: key,
      };

      api
        .post(url, payload)
        .then(res => {
          console.log(res);

          dispatch(
            props.fetchViasForms(
              props.userD?.id,
              props.userD?.email,
              'H1B',
              props.userD?.firstName,
            ),
          );

          openNotificationWithIcon('Visa Form', 'Saved');
        })
        .catch(error => {
          console.error('Error deleting file:', error);
          // props.setLoading(false);
          //  openNotificationWithIconErr(error.data.message, 'Register', 'error');
        });
    }
  };

  useEffect(() => {
    const submitteddataIsallFilled = submittedPdfData?.every(
      data => data !== '',
    );

    if (submitteddataIsallFilled) {
      dispatch(handleSections({ section12: 'done' }, { ...optionalSection }));
    } else {
      delete requiredSection.section12;

      dispatch(handleSections({ ...requiredSection }, { ...optionalSection }));
    }
  }, [submittedPdfData, props.loading]);

  return (
    <div>
      <Largemodal open={viewFile} onClick={() => setViewFile(false)}>
        <div>
          <Document
            file={pdfData[myIndex]}
            onLoadSuccess={({ numPages }) =>
              onDocumentLoadSuccess({ numPages }, myIndex)
            }
          >
            <Page pageNumber={pageNumber[myIndex]} />
          </Document>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <button
              onClick={() => {
                setPageNumber(prevPageNumber => ({
                  ...prevPageNumber,
                  [myIndex]: Math.max(prevPageNumber[myIndex] - 1, 1),
                }));
              }}
              disabled={pageNumber[myIndex] <= 1}
              style={{
                color: '#67A948',
                fontSize: '13px',
                fontWeight: '600',
              }}
            >
              Back
            </button>

            <p>
              Page {pageNumber[myIndex]} of {numPages[myIndex]}
            </p>

            <button
              onClick={() => {
                setPageNumber(prevPageNumber => ({
                  ...prevPageNumber,
                  [myIndex]: Math.min(
                    prevPageNumber[myIndex] + 1,
                    numPages[myIndex],
                  ),
                }));
              }}
              disabled={pageNumber[myIndex] >= numPages[myIndex]}
              style={{
                color: '#67A948',
                fontSize: '13px',
                fontWeight: '600',
              }}
            >
              Next
            </button>
          </div>
        </div>
      </Largemodal>

      <SimpleCancelmodal
        open={triggerwarning}
        onClick={() => setTriggerWarning(false)}
      >
        <div style={{ marginTop: '20px' }}>
          You are permanently deleting this document and would not be able to
          retrieve it once deleted
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
              marginTop: '14px',
            }}
          >
            <div
              onClick={() => {
                setSelectedFiles(prevSelectedFiles => {
                  const newSelectedFiles = [...prevSelectedFiles];
                  newSelectedFiles[myIndex] = {};
                  return newSelectedFiles;
                });
                setPdfData(prevPdfData => {
                  const newPdfData = [...prevPdfData];
                  newPdfData[myIndex] = '';
                  return newPdfData;
                });
                setTriggerWarning(false);
                getItem();
              }}
              style={{
                background: '#67A948',
                color: 'white',
                fontWeight: '600',
                fontSize: '13px',
                padding: '12px 0px',
                width: '100%',
                textAlign: 'center',
                borderRadius: '10px',
                cursor: 'pointer',
              }}
            >
              Continue
            </div>

            <div
              style={{
                background: 'red',
                color: 'white',
                fontWeight: '600',
                fontSize: '13px',
                padding: '12px 0px',
                width: '100%',
                textAlign: 'center',
                borderRadius: '10px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setTriggerWarning(false);
              }}
            >
              Cancel
            </div>
          </div>
        </div>
      </SimpleCancelmodal>

      <div
        className=""
        style={{
          position: 'relative',
          height: '1400px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
          }}
        >
          {/* <div> */}
          {[0, 1, 2, 3, 4, 5, 6, 7].map(index =>
            pdfData[index] === '' ? (
              <div key={index}>
                {renderTitle(index)}

                {index == 0 ? (
                  <input
                    type="file"
                    id={`fileInput${index}`}
                    accept=".doc, .docx"
                    onChange={event => {
                      onFileLoad(event, index);
                    }}
                    style={{
                      display: 'none',
                    }}
                  />
                ) : (
                  <input
                    type="file"
                    id={`fileInput${index}`}
                    accept=".pdf, .doc, .docx"
                    onChange={event => {
                      onFileLoad(event, index);
                    }}
                    style={{
                      display: 'none',
                    }}
                  />
                )}

                <label
                  htmlFor={`fileInput${index}`}
                  style={{
                    display: 'inline-block',
                    textAlign: 'center',
                  }}
                  className="visaUploadInputs"
                >
                  <img
                    src={uploadIcon}
                    alt=""
                    style={{
                      margin: '0px auto',
                    }}
                  />
                  Click to upload
                  {index == 0 ? (
                    <p
                      style={{
                        color: '#14290A',
                        display: 'block',
                        textAlign: 'center',
                      }}
                    >
                      {' '}
                      Upload Doc only (Max. File size: 10 MB)
                    </p>
                  ) : (
                    <p
                      style={{
                        color: '#14290A',
                        display: 'block',
                        textAlign: 'center',
                      }}
                    >
                      {' '}
                      Upload PDF only (Max. File size: 10 MB)
                    </p>
                  )}
                </label>

                {errorMsgs[index] ? (
                  <div className="visaFileLimitError">
                    {' '}
                    <img src={errIcon} alt="" /> {errorMsgs[index]}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div>
                <>{renderTitle(index)}</>
                <div
                  className="visaFormSectionContainer__subsections"
                  key={index}
                >
                  <div className="visaFormSectionContainer__subsectionsFlex">
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                      }}
                    >
                      <IoDocumentTextOutline />
                      <div>
                        <p
                          style={{
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#14290A',
                          }}
                        >
                          {selectedFiles[index]?.name
                            ? selectedFiles[index]?.name
                            : pdfData[index]?.split('/').pop()}
                        </p>
                        <>
                          {selectedFiles[index]?.size ? (
                            <p
                              style={{
                                color: '#98A2B3',
                                fontSize: '13px',
                                margin: '12px 0px',
                              }}
                            >
                              {' '}
                              {Math.round(selectedFiles[index]?.size / 1024)} KB
                            </p>
                          ) : (
                            <div
                              style={{
                                marginTop: '12px',
                              }}
                            >
                              <a
                                href={`${pdfData[index]}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: '#67A948',
                                  fontSize: '13px',
                                  fontWeight: '600',
                                  cursor: 'pointer',
                                }}
                              >
                                Click to view
                              </a>
                            </div>
                          )}
                        </>

                        {(uploadedDoc && myIndex === index) ||
                        !selectedFiles[index]?.size ? (
                          <p> </p>
                        ) : (
                          <p
                            style={{
                              color: '#67A948',
                              fontSize: '13px',
                              fontWeight: '600',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setMyIndex(index);
                              setUploadedDoc(true);
                            }}
                          >
                            Click to view
                          </p>
                        )}
                      </div>
                    </div>

                    {props.Forms[0]?.submitted ? (
                      <></>
                    ) : (
                      <>
                        {selectedFiles[index]?.name ? (
                          <RiDeleteBinLine
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setSelectedFiles(prevSelectedFiles => {
                                const newSelectedFiles = [...prevSelectedFiles];
                                newSelectedFiles[index] = {};
                                return newSelectedFiles;
                              });
                              setPdfData(prevPdfData => {
                                const newPdfData = [...prevPdfData];
                                newPdfData[index] = '';
                                return newPdfData;
                              });
                            }}
                          />
                        ) : (
                          <RiDeleteBinLine
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setMyIndex(index);

                              setTriggerWarning(true);
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>

                  {uploadedDoc && myIndex === index ? (
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          backgroundColor: '#f0f0f0',
                          borderRadius: '4px',
                          marginBottom: '8px',
                          height: '7px',
                        }}
                      >
                        <div
                          style={{
                            width: `${progress}%`,
                            height: '100%',
                            backgroundColor: '#67A948',
                            borderRadius: '4px',
                            transition: 'width 0.1s ease-in-out',
                          }}
                        />
                      </div>
                      <p
                        style={{
                          fontSize: '14px',
                        }}
                      >{`${progress}%`}</p>
                    </div>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
            ),
          )}
          {/* </div> */}

          <div
            style={{
              margin: '1rem 0px 0.25rem 0px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div></div>

            {props.Forms[0]?.afterSubmittionStatus == 'completed_stage2' ? (
              <></>
            ) : (
              <>
                {' '}
                {!props.Forms[0]?.resumeCredentialscv ||
                !props.Forms[0]?.dataPageOfPassport ||
                !props.Forms[0]?.academicTranscripts ||
                !props.Forms[0]?.credentialsEvaluationDocuments ||
                !props.Forms[0]?.resumeCredentialsotherCertificates ||
                !props.Forms[0]?.highSchoolCertificates ||
                !props.Forms[0]?.birthCertificates ||
                !props.Forms[0]?.professionCertificates ? (
                  <p
                    style={{
                      background: '#4C5366',
                      padding: '15px 25px',
                      color: 'white',
                      fontSize: '14px',
                      fontWeight: '600',
                      marginTop: '2rem',
                      borderRadius: '10px',
                      cursor: 'not-allowed',
                    }}
                    onClick={() => {}}
                  >
                    {buttontext}
                  </p>
                ) : (
                  <p
                    style={{
                      background: 'green',
                      padding: '15px 25px',
                      color: 'white',
                      fontSize: '14px',
                      fontWeight: '600',
                      marginTop: '2rem',
                      borderRadius: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      finalSubmitApplication();
                      // props.Forms[0]?.id
                      //   ?
                      //   : submitApplication();
                    }}
                  >
                    {buttontext}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
