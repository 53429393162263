import { Platformbutton } from "components/common/button/button"
import { Amountinputcurrency } from "components/common/inputs/amountinput"
import { removeCommaAmount, returnAmount } from "helpers/utils"
import React from "react"
import { useSelector } from "react-redux";
import _ from 'lodash';
import "./currencyamount.scss"
import { AlertError } from "components/common/alertboxes/alertboxes";


export const Currencyamount = (props) => {

    const allWallet = useSelector(state=> state.wallets.wallets, _.isEqual)

    var handleAmount = (e)=> {
        var newValue = parseFloat(e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/,/g, '')).toLocaleString('en-US')
        props.onAmountChange(newValue)
    }
    return (
        <div className="currencyamount">
            <div  className="currencyamount__inputs">
                <span>
                    <select className="currencyamount__inputs__select" id="contentselect" value={props.currency} onChange={(e) => props.handleCurrencyChange(e.target)}>
                        {/* <option value="USD_CENTS">($) USD</option>
                        <option value="NGN_KOBO">(₦) NGN</option> */}
                        <option value="">Choose Wallet</option>
                        {
                            allWallet.map(item => (
                                <option value={item.currency}>{item.name.toLocaleUpperCase()}</option>  
                            ))
                        }
                    </select>
                    <Amountinputcurrency 
                         type="text"
                        //  currency= { props.currency === 'USD_CENTS' ? '$' :'₦'}
                        // currency= { props.currency}
                         name="amount"
                         label="Amount in USD"
                         value={ returnAmount(props.amount)}
                         disabled={false}
                        //  placeholder="Enter amount to deposit"
                         onChange={ (e) => handleAmount(e)}
                         pattern="[0-9,.]*"
                    />
                    {/* <input type="number" name = "amount" placeholder="Enter Amount" value={props.amount} onChange={ (e) => props.onAmountChange(e.target.value)}/> */}

                </span>
            </div>

            {/* <button className="currencyamount__btn" disabled={props.currency === "USD_CENTS" ? removeCommaAmount(props.amount ) < 1 ? true : false : removeCommaAmount(props.amount ) < 100 ? true : false} onClick={()=> props.onClick()}>
                {props.btn} <i className="fas fa-arrow-right"></i>
            </button> */}
            <div className="mb-4"></div>
            {props.selected ? (props.selected.balance/100) < removeCommaAmount(props.amount) && <AlertError body={` You do not have up to ${props.selected.currency} ${props.amount} in your wallet`} /> : <></>}
            <div className="mb-4"></div>
            <Platformbutton
                name={props.btn} type="normal"
                disabled={ props.currency && (removeCommaAmount(props.amount) > 1) ? false: true } 
                click={()=> props.onClick()}
            />
        </div>
    )
}