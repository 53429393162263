import { PREV_LOCATION, FROM_LOCATION_AUTH } from 'appRedux/constants';

const INIT_STATE = {
  prevpathname: '',
  fromLocation: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PREV_LOCATION:
      return {
        ...state,
        prevpathname: action.payload,
      };
    case FROM_LOCATION_AUTH:
      return {
        ...state,
        fromLocation: action.payload,
      };
    default:
      return state;
  }
};
